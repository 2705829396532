import { Component } from 'react';
import { Table, Input, Button, Form, Radio, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  STR_SEN_3,
  STR_EMAIL_INPUT,
  STR_EMAIL_SELECT,
} from '../../../../common/Terms';
import { ReactComponent as UpdateIcon } from '../../../../assets/icon/edit.svg';
import { ReactComponent as Cancle } from '../../../../assets/icon/cancle.svg';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../../../common/Color';

@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class StaffUpsertContainer extends Component {
  render() {
    const customerCdList = [
      {
        sysCdNm: '운송담당자',
        sysCd: 'IACCU004',
      },
      {
        sysCdNm: '정산담당자',
        sysCd: 'IACCU005',
      },
    ];
    const { accStore, commonStore } = this.props;
    return (
      <div
        style={{
          padding: '20px',
          width: '48%',
          overflow: 'hidden',
          boxShadow: '4px 4px 10px rgba(5,5,5,0.1)',
          borderRadius: '10px',
          marginRight: '1%',
          height: '90vh',
          position: 'relative',
          top: '5vh',
        }}
      >
        {accStore.selectedStaff == null ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              positive
              onClick={() => {
                this.props.fnStaffInsertModalOpen();
              }}
            >
              담당자 추가
            </Button>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                width: '130px',
                backgroundColor: 'red',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '700',
                borderRadius: '5px',
                padding: '10px 25px',
                float: 'right',
                marginRight: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.fnDeleteStaffModalOpen();
              }}
            >
              <Cancle
                style={{ marginRight: '6px' }}
                width={12}
                height={13}
                fill={COLOR_WHITE}
              />
              삭제하기
            </div>
            <div
              style={{
                width: '130px',
                backgroundColor: COLOR_GREEN_PRIMARY,
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '700',
                borderRadius: '5px',
                padding: '10px 25px',
                float: 'right',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.fnUpdateStaffModalOpen();
              }}
            >
              <UpdateIcon style={{ marginRight: '6px' }} />
              수정하기
            </div>
          </div>
        )}
        {accStore.selectedStaff == null ? (
          <Table style={{ border: '0px', marginTop: '55px' }}>
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell colSpan="2" style={{ background: '#fff' }}>
                  신규 담당자 추가
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {/* <Table.Row>
                <Table.Cell collapsing>담당자 구분</Table.Cell>
                <Table.Cell>
                  {customerCdList.length > 0
                    ? customerCdList.map((data, i) => (
                        <Radio
                          //defaultChecked
                          style={{ width: '20%', float: 'left' }}
                          label={data.sysCdNm}
                          name="customerCdGroup"
                          value={data.sysCd}
                          checked={accStore.customerCd == data.sysCd}
                          onChange={this.props.handleChangeCustomerCd}
                        />
                      ))
                    : null}
                </Table.Cell>
              </Table.Row> */}
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>담당자
                  아이디
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="담당자 아이디 기입"
                    value={accStore.staffId || ''}
                    onChange={e => {
                      accStore.setStaffId(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>비밀번호
                </Table.Cell>
                <Table.Cell>
                  <Form>
                    <Form.Field>
                      <input
                        placeholder={STR_SEN_3}
                        onChange={e => accStore.setStaffPw(e.target.value)}
                        value={accStore.staffPw || ''}
                        type="password"
                      />
                    </Form.Field>
                  </Form>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>담당자
                  이름
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="담당자 이름 기입"
                    value={accStore.staffNm || ''}
                    onChange={e => {
                      accStore.setStaffNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>담당자
                  연락처
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="담당자 연락처 기입"
                    value={accStore.staffHpNo || ''}
                    onChange={e => {
                      accStore.setStaffHpNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>담당자 이메일</Table.Cell>
                <Table.Cell>
                  <Form.Field>
                    <Input
                      style={{ width: '30%' }}
                      placeholder={STR_EMAIL_INPUT}
                      onChange={e => accStore.setEmail(e.target.value)}
                      value={accStore.email || ''}
                      type="text"
                    />
                    <span style={{ fontSize: '18pt', lineHeight: '30px' }}>
                      {' '}
                      @{' '}
                    </span>
                    <Input
                      style={{ width: '30%' }}
                      placeholder={STR_EMAIL_INPUT}
                      onChange={e => accStore.setEmailCd(e.target.value)}
                      value={accStore.emailCd || ''}
                      type="text"
                      readOnly={this.props.eamilCdInput}
                    />
                    {commonStore.emailCdList.length > 0 ? (
                      <Select
                        style={{ width: '30%', marginLeft: '2%' }}
                        placeholder={STR_EMAIL_SELECT}
                        options={commonStore.emailCdList}
                        onChange={(e, data) => this.props.fnSetEmailCd(e, data)}
                      />
                    ) : null}
                  </Form.Field>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Table style={{ border: '0px', marginTop: '58px' }}>
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell colSpan="2" style={{ background: '#fff' }}>
                  담당자 정보 수정
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {/* {accStore.selectedStaff.customerCd.substring(5, 8) ==
              '003' ? null : (
                <Table.Row>
                  <Table.Cell collapsing>담당자 구분</Table.Cell>
                  <Table.Cell>
                    {customerCdList.length > 0
                      ? customerCdList.map((data, i) => (
                          <Radio
                            //defaultChecked
                            style={{ width: '20%', float: 'left' }}
                            label={data.sysCdNm}
                            name="customerCdGroup"
                            value={data.sysCd}
                            checked={accStore.customerCd == data.sysCd}
                            onChange={this.props.handleChangeCustomerCd}
                          />
                        ))
                      : null}
                  </Table.Cell>
                </Table.Row>
              )} */}

              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>담당자
                  아이디
                </Table.Cell>
                <Table.Cell>
                  <p>{accStore.staffId}</p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>비밀번호
                </Table.Cell>
                <Table.Cell>
                  <Form>
                    <Form.Field>
                      <input
                        placeholder={STR_SEN_3}
                        onChange={e => accStore.setStaffPw(e.target.value)}
                        value={accStore.staffPw || ''}
                        type="password"
                      />
                    </Form.Field>
                  </Form>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>담당자
                  이름
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="담당자 이름 기입"
                    value={accStore.staffNm || ''}
                    onChange={e => {
                      accStore.setStaffNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>담당자
                  연락처
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="담당자 연락처 기입"
                    value={accStore.staffHpNo || ''}
                    onChange={e => {
                      accStore.setStaffHpNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>담당자 이메일</Table.Cell>
                <Table.Cell>
                  <Form.Field>
                    <Input
                      style={{ width: '30%' }}
                      placeholder={STR_EMAIL_INPUT}
                      onChange={e => accStore.setEmail(e.target.value)}
                      value={accStore.email || ''}
                      type="text"
                    />
                    <span style={{ fontSize: '18pt', lineHeight: '30px' }}>
                      {' '}
                      @{' '}
                    </span>
                    <Input
                      style={{ width: '30%' }}
                      placeholder={STR_EMAIL_INPUT}
                      onChange={e => accStore.setEmailCd(e.target.value)}
                      value={accStore.emailCd || ''}
                      type="text"
                      readOnly={this.props.eamilCdInput}
                    />
                    {commonStore.emailCdList.length > 0 ? (
                      <Select
                        style={{ width: '30%', marginLeft: '2%' }}
                        placeholder={STR_EMAIL_SELECT}
                        options={commonStore.emailCdList}
                        value={accStore.emailCd}
                        onChange={(e, data) => this.props.fnSetEmailCd(e, data)}
                      />
                    ) : null}
                  </Form.Field>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default StaffUpsertContainer;
