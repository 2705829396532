import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../../common/Color';
import moment from 'moment';

@inject(stores => ({
  notiStore: stores.NotiStore,
}))
@observer
class NotiList extends Component {
  state = {
    column: null,
    direction: null,
  };

  handleSort = clickedColumn => {
    const { column, direction } = this.state;
    const { notiStore } = this.props;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
      });
      let listItem = _.sortBy(notiStore.notiList, [clickedColumn]);
      notiStore.setNotiList(listItem);

      return;
    }

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
    let listItem = [...notiStore.notiList];
    notiStore.setNotiList(listItem.reverse());
  };
  render() {
    const { notiStore } = this.props;
    return (
      <Table sortable style={{ border: '0px' }}>
        <Table.Header style={{ border: '0px' }}>
          <Table.Row>
            <Table.HeaderCell
              style={{
                width: '15%',
                borderBottom: '1px solid #CED4DA',
                background: '#fff',
              }}
              sorted={
                this.state.column === 'notiNo' ? this.state.direction : null
              }
              onClick={() => this.handleSort('notiNo')}
            >
              번호
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                width: '60%',
                borderBottom: '1px solid #CED4DA',
                background: '#fff',
                borderLeft: '0px',
              }}
              sorted={
                this.state.column === 'notiTitle' ? this.state.direction : null
              }
              onClick={() => this.handleSort('notiTitle')}
            >
              제목
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                width: '25%',
                borderBottom: '1px solid #CED4DA',
                background: '#fff',
                borderLeft: '0px',
              }}
              sorted={
                this.state.column === 'registDt' ? this.state.direction : null
              }
              onClick={() => this.handleSort('registDt')}
            >
              날짜
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {notiStore.notiList == null || notiStore.notiList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                등록된 공지사항이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {notiStore.notiList.map((data, i) => (
              <React.Fragment>
                <Table.Row onClick={() => this.props.fnViewOpen(data)}>
                  <Table.Cell>{data.notiNo}</Table.Cell>
                  <Table.Cell>{data.notiTitle}</Table.Cell>
                  <Table.Cell>
                    {moment(data.registDt).format('YYYY-MM-DD')}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default NotiList;
