import { apiAddress } from '../ApiPlatform';

export const API_INSERT_FREIGHT = apiAddress + '/freight/insertFreight';
export const API_SELECT_FREIGHT_LIST =
  apiAddress + '/freight/selectFreightList';

export const API_SELECT_FREIGHT_DIV_LIST =
  apiAddress + '/freight/selectFreightDivList';

export const API_SELECT_LOGIS_FEE = apiAddress + '/freight/selectLogisFee';

export const API_SELECT_FREIGHT_LIST_WITH_KEYWORD =
  apiAddress + '/freight/selectFreightListWithKeyword';

export const API_SELECT_FREIGHT_DIV_LIST_WITH_KEYWORD =
  apiAddress + '/freight/selectFreightDivListWithKeyword';

export const API_INSERT_ORDER_INFO = apiAddress + '/order/insertOrder';
export const API_INSERT_PAYMENT_INFO = apiAddress + '/payment/insertPayment';

export const API_UPDAGTE_FREIGHT_LOADING_DATE =
  apiAddress + '/freight/setUpdateDt';

export const API_UPDAGTE_FREIGHT_LOADING_DATE_MULTI =
  apiAddress + '/freight/setUpdateDtMulti';

export const API_UPDATE_FREIGHT = apiAddress + '/freight/updateFreight';
export const API_DELETE_FREIGHT = apiAddress + '/freight/deleteFreight';
