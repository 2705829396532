import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { isCustomer, isEmp } from '../../Util/CommonUtil';
@inject(stores => ({
  commonStore: stores.CommonStore,
  accStore: stores.AccStore,
  empStore: stores.EmpStore,
  prvStore: stores.PrivilegeStore,
}))
@observer
class AdminSideBar extends Component {
  fnLogout = () => {
    const { empStore, prvStore } = this.props;
    empStore.initForm();
    prvStore.initAuthMenuList();
    sessionStorage.removeItem('empId');
    sessionStorage.removeItem('empNo');
    sessionStorage.removeItem('initpwcheck');
    sessionStorage.removeItem('authId');
    this.props.history.push('/empLogin');
  };

  fnPush = (path, eventKey) => {
    this.props.onSelect(eventKey);
    if (path != null) {
      this.props.onToggleClose();
      this.props.history.push(path);
    }
  };

  render() {
    const { commonStore, prvStore } = this.props;
    return (
      <SideNav
        onSelect={selected => this.props.onSelect(selected)}
        onToggle={expanded => {
          this.props.onToggle();
        }}
        expanded={this.props.expanded}
      >
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected={this.props.selected}>
          {
            //this.state.menuList
            prvStore.authMenuList.map((data, i) => (
              <NavItem
                key={data.eventKey}
                eventKey={data.eventKey}
                active={this.props.selected == data.eventKey ? true : false}
                onClick={() => this.fnPush(data.menuPath, data.eventKey)}
              >
                <NavIcon>
                  <i className={data.icon} style={{ fontSize: '1.75em' }} />
                </NavIcon>
                <NavText>{data.menuNm}</NavText>

                {data.submenu == undefined
                  ? null
                  : data.submenu.map((data, i) => (
                      <NavItem
                        key={data.eventKey}
                        eventKey={data.eventKey}
                        active={
                          this.props.selected == data.eventKey ? true : false
                        }
                        onClick={() =>
                          data.menuPath == null
                            ? null
                            : this.fnPush(data.menuPath, data.eventKey)
                        }
                      >
                        <NavIcon>
                          <i
                            className={data.icon}
                            style={{
                              fontSize: '1.2em',
                              float: 'left',
                              marginRight: '10px',
                              marginTop: '8px',
                            }}
                          />
                        </NavIcon>
                        <NavText>{data.menuNm}</NavText>
                      </NavItem>
                    ))}
              </NavItem>
            ))
          }

          <NavItem
            onClick={() => this.fnLogout()}
            eventKey="/"
            style={{
              position: 'absolute',
              bottom: 0,
              width: this.props.expanded ? '240px' : '64px',
            }}
          >
            <NavIcon>
              <i
                className="fas fa-sign-out-alt"
                style={{ fontSize: '1.75em' }}
              />
            </NavIcon>
            <NavText>로그아웃</NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  }
}

export default withRouter(AdminSideBar);
