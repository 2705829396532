import { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  armStore: stores.AlarmTalkStore,
}))
@observer
class AlarmTalkDetailContainer extends Component {
  render() {
    const { armStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {armStore.selectedAlarmTalk == null ? null : (
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>발송대상</Table.Cell>
                <Table.Cell>{armStore.selectedAlarmTalk.rcvId}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>연락처</Table.Cell>
                <Table.Cell>{armStore.selectedAlarmTalk.rcvHpNo}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>발송성공여부</Table.Cell>
                <Table.Cell>
                  {armStore.selectedAlarmTalk.resultCode == '0000' ? 'Y' : 'N'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>템플릿코드</Table.Cell>
                <Table.Cell>{armStore.selectedAlarmTalk.tmpCode}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>내용</Table.Cell>
                <Table.Cell>{armStore.selectedAlarmTalk.tmpContent}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>오류내용</Table.Cell>
                <Table.Cell>{armStore.selectedAlarmTalk.errorText}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default AlarmTalkDetailContainer;
