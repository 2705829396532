import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class DepositStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }
  @observable totalBalance = null;

  @observable depositHisList = null;
  @observable depositHisDivList = null;
  @observable balance = null;
  @observable content = null;

  @observable searchDriverInfo = null;

  @action
  setTotalBalance = value => {
    this.totalBalance = value;
  };

  @action
  inittTotalBalance = () => {
    this.totalBalance = null;
  };

  @action
  setDepositHisList = value => {
    this.depositHisList = value;
  };

  @action
  initDepositHisList = () => {
    this.depositHisList = [];
  };

  @action
  setDepositHisDivList = value => {
    this.depositHisDivList = value;
  };

  @action
  initDepositHisDivList = () => {
    this.depositHisDivList = [];
  };

  @action
  setBalance = value => {
    this.balance = value;
  };

  @action
  initBalance = () => {
    this.balance = null;
  };

  @action
  setSearchDriverInfo = value => {
    this.searchDriverInfo = value;
  };

  @action
  initSearchDriverInfo = () => {
    this.searchDriverInfo = null;
  };

  @action
  setContent = value => {
    this.content = value;
  };

  @action
  initContent = () => {
    this.content = null;
  };
}

export default DepositStore;
