import React, { Component } from 'react';
import { Table, TextArea, Button, Form, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR06,
  COLOR_GRAY_GR07,
} from '../../../../common/Color';
import moment from 'moment';
@inject(stores => ({
  queStore: stores.QuestionStore,
}))
@observer
class QuestionDetailContainer extends Component {
  render() {
    const { queStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {queStore.selectedQuestion == null ? null : (
          <div style={{ marginTop: '40px', paddingLeft: '38px' }}>
            <p
              style={{
                fontSize: '18px',
                fontWeight: '700',
                color: COLOR_BLACK,
              }}
            >
              {queStore.selectedQuestion.questionTitle}
            </p>
            <div style={{ marginTp: '11px', marginBottom: '56px' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: COLOR_GRAY_GR06,
                }}
              >
                {moment(queStore.selectedQuestion.registDt).format(
                  'YYYY-MM-DD HH:mm',
                )}
              </p>
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '500',
                color: COLOR_GRAY_GR07,
              }}
            >
              {queStore.selectedQuestion.questionContent}
            </div>
            <div style={{ marginTop: '20px' }}>
              <img
                id="addfile1"
                style={{ width: '50%', marginTop: '20px' }}
                src={queStore.selectedQuestion.addfile1FilePath}
              />
            </div>
            <div>
              <img
                id="addfile2"
                style={{ width: '50%' }}
                src={queStore.selectedQuestion.addfile2FilePath}
              />
            </div>
            {queStore.selectedQuestion.replyContent != null ? (
              <div
                style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: COLOR_GRAY_GR07,
                  marginTop: '20px',
                }}
              >
                <p style={{ fontWeight: '700' }}>Teamtrucker 답변</p>
                {queStore.selectedQuestion.replyContent}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default QuestionDetailContainer;
