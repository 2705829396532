import LoginContainer from './LoginContainer';
import SelectStaffContainer from './SelectStaffContainer';
import EmpLogin from './EmpLogin';
import SearchAccContainer from './SearchAccContainer';
import SearchAccIdReturn from './SeachAccIdReturn';
import SearchNewPwSetting from './SeachNewPwSetting';
export const LoginIndex = {
  LoginContainer,
  SelectStaffContainer,
  EmpLogin,
  SearchAccContainer,
  SearchAccIdReturn,
  SearchNewPwSetting
};
