import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../common/Color';
import { STR_SEARCH_ID_RESULT, STR_MOVE_MAIN } from '../../common/Terms';
import MainFooter from '../../Component/Common/MainFooter';

@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class SearchAccIdReturn extends React.Component {
  fnMoveToMain = () => {
    const { accStore } = this.props;
    accStore.initSearchCustomerIdList();
    accStore.initHpNo();
    accStore.initCustomerNm();
    accStore.initCustomerCd();
    accStore.initEmail();
    this.props.history.push('/');
  };

  render() {
    const { accStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 60px)',
            background: '#000',
          }}
        >
          <div style={{ textAlign: 'center', paddingTop: '100px' }}>
            <img
              style={{ width: '20%', marginBottom: '32px' }}
              src={'./image/logoDarktheme.png'}
            />
          </div>
          <div
            style={{
              width: '40%',
              margin: '0 auto ',
            }}
          >
            <h3
              style={{
                color: COLOR_GREEN_PRIMARY,
                textAlign: 'center',
                fontSize: '16pt',
                marginBottom: '40px',
              }}
            >
              {STR_SEARCH_ID_RESULT}
            </h3>
            {accStore.searchCustomerIdList.map((data, i) => (
              <React.Fragment>
                <div
                  style={{
                    fontSize: '16pt',
                  }}
                >
                  <div
                    style={{
                      color: COLOR_GREEN_PRIMARY,
                      float: 'left',
                      width: '50%',
                      marginBottom: '20px',
                      textAlign: 'left',
                    }}
                  >
                    아이디 : {data.customerId}
                  </div>
                  <div
                    style={{
                      color: COLOR_GREEN_PRIMARY,
                      marginBottom: '20px',
                      width: '50%',
                      overflow: 'hidden',
                      textAlign: 'right',
                    }}
                  >
                    가입일 : {moment(data.registDt).format('YYYY-MM-DD')}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                this.fnMoveToMain();
              }}
              style={{
                width: '40%',
                height: '60px',
                margin: '0 auto',
                marginTop: '33px',
                marginBottom: '33px',
                background: COLOR_GREEN_PRIMARY,
                textAlign: 'center',
                fontSize: '20px',
                color: COLOR_WHITE,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: '0' }}>{STR_MOVE_MAIN}</p>
            </div>
          </div>
        </div>
        <MainFooter />
      </React.Fragment>
    );
  }
}

export default SearchAccIdReturn;
