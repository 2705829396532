import { apiAddress } from '../ApiPlatform';

export const API_INSERT_WORKBENCH_FREIGHT =
  apiAddress + '/work/insertWorkFreight';

export const API_SELECT_WORKBENCH_FREIGHT_LIST =
  apiAddress + '/work/selectFreightList';

export const API_UPDATE_WORKBENCH_FREIGHT =
  apiAddress + '/work/updateWorkFreight';

export const API_UPDATE_WORKBENCH_FREIGHT_COMP_NO =
  apiAddress + '/work/updateWorkFreightCompNo';

export const API_SELECT_WORKBENCH_FREIGHT_STATEMENT_LIST =
  apiAddress + '/work/selectFreightStatementList';
