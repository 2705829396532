import React, { Component, Fragment } from 'react';
import { Form, Modal, Select, TextArea } from 'semantic-ui-react';
import OnClickInput from '../../Component/Common/OnClickInput';
import CommonInput from '../../Component/Common/CommonInput';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../common/Color';
import { inject, observer } from 'mobx-react';
import {
  STR_DEPARTURE_ADD_SEARCH,
  STR_DEPARTURE_DETAIL_ADD_SEARCH,
  STR_ARRIVE_ADD_SEARCH,
  STR_ARRIVE_DETAIL_ADD_SEARCH,
  STR_SEN_4,
  STR_SEN_5,
  STR_SEN_8,
  STR_ORDERER_NM,
  STR_ORDERER_HP,
  STR_SEN_35,
} from '../../common/Terms';
import CommonAlert from '../../Component/Common/CommonAlert';
import CommonAlertWithCallback from '../../Component/Common/CommonAlertWithCallback';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import moment from 'moment';
@inject(stores => ({
  quoteStore: stores.QuoteStore,
  vcStore: stores.VcStore,
  commonStore: stores.CommonStore,
}))
@observer
class FreightRequesForm extends Component {
  componentDidMount() {
    const { vcStore, quoteStore } = this.props;
    if (
      vcStore.vcWeightCdList.length == 0 &&
      quoteStore.freightModalForPC == true
    ) {
      this.props.fnSelectVcWeightCd();
    }
  }

  render() {
    const { quoteStore, vcStore, commonStore } = this.props;
    return (
      <Fragment>
        <p
          style={{
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '34.75px',
            paddingTop: '27px',
          }}
        >
          배차 신청하기
        </p>
        <Form>
          <Form.Field>
            <label>
              <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지 주소
            </label>
            <OnClickInput
              readOnly={true}
              placeholder={STR_DEPARTURE_ADD_SEARCH}
              onClick={this.props.fnOpenDepartureModal}
              defaultValue={quoteStore.loadingBasicAdd}
            />
            <CommonInput
              placeholder={STR_DEPARTURE_DETAIL_ADD_SEARCH}
              defaultValue={quoteStore.loadingDetailAdd}
              onChange={this.props.fnSetDepartureDetailAdd}
            />
          </Form.Field>
          <Form.Field>
            <label>
              <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지 주소
            </label>
            <OnClickInput
              readOnly={true}
              placeholder={STR_ARRIVE_ADD_SEARCH}
              onClick={this.props.fnOpenArriveModal}
              defaultValue={quoteStore.unloadingBasicAdd}
            />
            <CommonInput
              placeholder={STR_ARRIVE_DETAIL_ADD_SEARCH}
              defaultValue={quoteStore.unloadingDetailAdd}
              onChange={this.props.fnSetArriveDetailAdd}
            />
          </Form.Field>

          <Form.Field style={{ marginBottom: '20px' }}>
            <label
              style={{
                height: '23Px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량 선택
            </label>
            <Select
              style={{
                maxWidth: '190px',
                minWidth: '100px',
                float: 'left',
                marginRight: '9px',
              }}
              placeholder={STR_SEN_4}
              options={vcStore.vcWeightCdList}
              onChange={(e, data) => this.props.fnSetVcWeightCd(e, data)}
              value={vcStore.vcWeightCd}
            />
            <Select
              style={{
                maxWidth: '190px',
                minWidth: '100px',
              }}
              placeholder={STR_SEN_5}
              options={vcStore.vcTypCdList}
              onChange={(e, { value }) => this.props.fnSetVcTypCd(e, value)}
              value={vcStore.vcTypCd}
            />
          </Form.Field>
          <Form.Field>
            <label>상차 일시</label>
            <DatePicker
              locale="ko"
              selected={quoteStore.loadingDate}
              onChange={date => {
                if (date < moment()) {
                  commonStore.commonAlertOpen(STR_SEN_35);
                  return false;
                } else {
                  quoteStore.setLoadingDate(date);
                }
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="시간"
              dateFormat="yyyy-MM-dd HH:mm aa"
            />
          </Form.Field>

          <Form.Field>
            <label>
              <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>화물정보
            </label>
            <TextArea
              value={quoteStore.cargoDispathInfo || ''}
              style={{ resize: 'none' }}
              placeholder={STR_SEN_8}
              rows={3}
              onChange={e => {
                quoteStore.setCargoDispathInfo(e.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>
              <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>주문자 정보
            </label>
            <CommonInput
              placeholder={STR_ORDERER_NM}
              defaultValue={quoteStore.ordererNm}
              onChange={this.props.fnSetOrdererNm}
            />
            <CommonInput
              placeholder={STR_ORDERER_HP}
              defaultValue={quoteStore.ordererHpNo}
              onChange={this.props.fnSetOrdererHpNo}
            />
          </Form.Field>
          <Form.Field>
            <label>하차지 담당자</label>
            <CommonInput
              placeholder={'담당자이름'}
              defaultValue={quoteStore.unloadingStaffNm}
              onChange={this.props.fnSetUnloadingStaffNm}
            />
            <CommonInput
              placeholder={'담당자 연락처'}
              defaultValue={quoteStore.unloadingStaffHpNo}
              onChange={this.props.fnSetUnloadingStaffHpNo}
            />
          </Form.Field>
        </Form>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: COLOR_GREEN_PRIMARY,
              borderRadius: '5px',
              padding: '10px 25px',
              color: COLOR_WHITE,
              border: '1px solid',
              fontSize: '14px',
              fontWeight: '700',
              background: COLOR_GREEN_PRIMARY,
              marginTop: '24px',
              marginBottom: '24px',
              cursor: 'pointer',
            }}
            onClick={() => {
              this.props.fnInsertFreightMini();
            }}
          >
            배차 신청하기
          </div>
        </div>
        <CommonAlert />
      </Fragment>
    );
  }
}

export default FreightRequesForm;
