import { Component, Fragment } from 'react';
import axios from 'axios';
import { Select, Form, Input, Button, Dropdown } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  API_SELECT_FREIGHT_DIV_LIST,
  API_SELECT_FREIGHT_DIV_LIST_WITH_KEYWORD,
} from '../../../common/ApiAddress/FreightAPI';
import { API_SELECT_CUSTOMER_SEARCH_LIST } from '../../../common/ApiAddress/AccountAPI';
import FreightList from '../../../Component/Management/Feright/FreightList';
import {
  isCustomer,
  isCustomerMaster,
  isEmp,
  isLogisManager,
} from '../../../Util/CommonUtil';
import SearchInput from '../../../Component/Management/Feright/SearchInput';
@inject(stores => ({
  orderStore: stores.OrderStore,
}))
@observer
class FreightDivList extends Component {
  componentDidMount() {
    this.fnSelectFreightDivList();
  }

  componentDidUpdate(prevProps) {
    const { orderStore } = this.props;
    if (prevProps.pathname !== this.props.pathname) {
      orderStore.initFreightList();
      this.fnSelectFreightDivList();
    }
  }

  //운송현황 불러오기(운송상태별)
  fnSelectFreightDivList = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    if (isEmp()) {
      params.registId = 'emp';
    }
    if (isCustomerMaster()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
      params.groupNo = sessionStorage.getItem('groupNo');
    }
    if (isLogisManager()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
      params.groupNo = sessionStorage.getItem('groupNo');
    }

    if (isCustomer()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
    }

    params.startCnt =
      orderStore.freightListCurrentPageNum == 1
        ? 0
        : 50 * (Number(orderStore.freightListCurrentPageNum) - 1) + 1;

    if (orderStore.freightListTotalPageNum == 0) {
      params.endCnt = 50;
    } else if (
      orderStore.freightListTotalPageNum > 0 &&
      orderStore.freightListTotalCount -
        50 * orderStore.freightListCurrentPageNum >=
        0
    ) {
      params.endCnt = 50;
    } else if (
      orderStore.freightListTotalPageNum > 0 &&
      orderStore.freightListTotalCount -
        50 * orderStore.freightListCurrentPageNum <
        0
    ) {
      params.endCnt =
        orderStore.freightListTotalCount -
        50 * (orderStore.freightListCurrentPageNum - 1);
    }

    let path = this.props.pathname;
    let pathSplit = path.split('/');
    let pathItem = pathSplit[pathSplit.length - 1];

    if (pathItem == 'reserved') {
      params.freightCd = 'IFRST001';
    }
    if (pathItem == 'rescomplete') {
      params.freightCd = 'IFRST003';
    }
    if (pathItem == 'transporting') {
      params.freightCd = 'IFRST004';
    }
    if (pathItem == 'transcomplete') {
      params.freightCd = 'IFRST005';
    }
    if (pathItem == 'transdraw') {
      params.freightCd = 'IFRST006';
    }
    if (pathItem == 'transcancle') {
      params.freightCd = 'IFRST007';
    }
    axios({
      url: API_SELECT_FREIGHT_DIV_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let pageNum = Math.ceil(response.data.totalPageNum / 50);
            orderStore.setFreightList(response.data.data);
            orderStore.setFreightListTotalCount(response.data.totalPageNum);
            orderStore.setFreightListTotalPageNum(pageNum);
            that.props.fnDetailViewOpen(response.data.data[0]);
          } else {
            orderStore.initFreightList();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //운송내역 검색 리스트 불러오기
  fnSelectFreightDivListWithKeyword = searchKeyword => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.startCnt = 0;
    params.endCnt = 100;
    params.searchKeyword = searchKeyword;
    params.searchTyp = this.props.searchTyp;

    let path = this.props.pathname;
    let pathSplit = path.split('/');
    let pathItem = pathSplit[pathSplit.length - 1];
    if (pathItem == 'reserved') {
      params.freightCd = 'IFRST001';
    }
    if (pathItem == 'rescomplete') {
      params.freightCd = 'IFRST003';
    }
    if (pathItem == 'transporting') {
      params.freightCd = 'IFRST004';
    }
    if (pathItem == 'transcomplete') {
      params.freightCd = 'IFRST005';
    }

    if (isEmp()) {
      params.registId = 'emp';
    }
    if (isCustomerMaster()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
      params.groupNo = sessionStorage.getItem('groupNo');
    }
    if (isLogisManager()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
      params.groupNo = sessionStorage.getItem('groupNo');
    }

    console.log(params);
    axios({
      url: API_SELECT_FREIGHT_DIV_LIST_WITH_KEYWORD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          orderStore.setFreightList(response.data.data);
        } else {
          orderStore.initFreightList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //자동검색 화주 리스트
  getCustomerList = (searchKeyword, searchTyp) => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    params.searchKeyword = searchKeyword;
    params.searchTyp = searchTyp;

    if (isEmp()) {
      params.registId = 'emp';
    }

    if (isCustomerMaster()) {
      params.groupNo = sessionStorage.getItem('groupNo');
    }

    axios({
      url: API_SELECT_CUSTOMER_SEARCH_LIST,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          this.props.fnSetResultList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnDirectListMove = pageNum => {
    const { orderStore } = this.props;
    orderStore.initFreightList();
    orderStore.setFreightListCurrentPageNum(pageNum);
    this.fnSelectFreightDivList();
  };

  render() {
    const { orderStore } = this.props;
    const searchTypList =
      isCustomerMaster() || isEmp()
        ? [
            { text: '운송번호', value: 'N' },
            { text: '아이디', value: 'A' },
          ]
        : [{ text: '운송번호', value: 'N' }];
    return (
      <Fragment>
        <div>
          <Form style={{ width: '100%', marginTop: '10px' }}>
            <Form.Field style={{ width: '100%' }}>
              {/* <Select
                style={{
                  minWidth: '122px',
                  maxWidth: '122px',
                  marginRight: '10px',
                  float: 'left',
                }}
                placeholder={'검색조건 선택'}
                options={searchTypList}
                onChange={(e, { value }) =>
                  this.props.fnOnchangeSearchTyp(value)
                }
                value={this.props.searchTyp}
              /> */}

              {this.props.searchTyp == 'N' || this.props.searchTyp == null ? (
                <Fragment>
                  <Input
                    label={
                      <Dropdown
                        style={{
                          minWidth: '120px',
                          maxWidth: '120px',
                          //float: 'left',
                        }}
                        value={this.props.searchTyp}
                        placeholder={'검색조건 선택'}
                        options={searchTypList}
                        onChange={(e, { value }) =>
                          this.props.fnOnchangeSearchTyp(value)
                        }
                      />
                    }
                    icon="search"
                    placeholder="검색어 입력"
                    style={{ width: '475px' }}
                    onKeyPress={e => {
                      if (e.charCode == 13) {
                        this.fnSelectFreightDivListWithKeyword(e.target.value);
                      }
                    }}
                  />
                </Fragment>
              ) : (
                <SearchInput
                  fnOnchangeSearchTyp={this.props.fnOnchangeSearchTyp}
                  searchTypList={searchTypList}
                  searchTyp={this.props.searchTyp}
                  resultList={this.props.resultList}
                  getCustomerList={this.getCustomerList}
                  fnSelectFreightListWithKeyword={
                    this.fnSelectFreightDivListWithKeyword
                  }
                />
              )}
            </Form.Field>
            {this.props.pathname == '/freight/reserved' ? (
              <div
                style={{
                  display: 'center',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button
                  onClick={e => {
                    this.props.fnUpdateLoadingDateAllModalOpen();
                  }}
                  color="teal"
                >
                  모두 상단으로 끌어올리기
                </Button>
              </div>
            ) : null}
          </Form>
        </div>
        <FreightList
          fnDetailViewOpen={this.props.fnDetailViewOpen}
          freightList={orderStore.freightList}
          freightListCurrentPageNum={orderStore.freightListCurrentPageNum}
          freightListTotalPageNum={orderStore.freightListTotalPageNum}
          fnDirectListMove={this.fnDirectListMove}
        />
      </Fragment>
    );
  }
}

export default FreightDivList;
