import { Component } from 'react';
import { Table, Input, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class APVDetail extends Component {
  render() {
    const { accStore, vcStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {accStore.selectedCustomer != null ? (
          <div>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    심사대기 회원정보
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>회원명</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedCustomer.customerNm}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>아이디</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedCustomer.customerId}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>신청구분</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedCustomer.customerCdNm}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>전화번호</Table.Cell>
                  <Table.Cell>{accStore.selectedCustomer.hpNo}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>이메일</Table.Cell>
                  <Table.Cell>{accStore.selectedCustomer.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>계좌번호</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedCustomer.accCdNm} :{' '}
                    {accStore.selectedCustomer.accNo}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>사업자 등록번호</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ width: '300px', height: '300px' }}>
                    <img
                      id="my-img"
                      style={{ width: '100%' }}
                      // src={accStore.selectedCustomer.compRegFilePath}
                    />
                    <Button
                      positive
                      disabled={this.props.isAuth == true ? true : false}
                      onClick={() => {
                        this.props.fnAuthModalOpen();
                      }}
                    >
                      사업자등록번호 인증
                    </Button>
                  </Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                  <Table.Cell>
                    <Input
                      style={{ width: '100%' }}
                      placeholder="사업자 등록번호 기입"
                      value={accStore.compRegNo}
                      onChange={e => {
                        accStore.setCompRegNo(e.target.value);
                      }}
                    />
                  </Table.Cell>
                </Table.Row> */}
              </Table.Body>
            </Table>
            <div>
              <Button
                disabled={!this.props.isAuth}
                positive
                onClick={() => {
                  this.props.fnUpdateInfoWithApproved();
                }}
              >
                승인하기
              </Button>
            </div>
            <div style={{ width: '100%', height: '50px' }} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default APVDetail;
