import React, { Component } from 'react';
import { Button, Modal, Input } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  favoStore: stores.FavoriteStore,
}))
@observer
class FavoriteCateInsertModal extends Component {
  render() {
    const { favoStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={this.props.insertCateModal}
        onClose={() => this.props.fnCloseInsertCateModal()}
        style={{ height: '210px' }}
      >
        <Modal.Header>새 분류 추가</Modal.Header>
        <Modal.Content>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '20%', float: 'left' }}>
              <label>분류명</label>
            </div>
            <div style={{ width: '80%', float: 'left' }}>
              <Input
                style={{ width: '80%' }}
                placeholder={'분류명을 입력해주세요.'}
                onChange={e => favoStore.setCateNm(e.target.value)}
                value={favoStore.cateNm || ''}
              />
            </div>
          </div>
        </Modal.Content>
        <div style={{ float: 'right', marginTop: '20px', marginRight: '20px' }}>
          <Button negative onClick={() => this.props.fnCloseInsertCateModal()}>
            취소
          </Button>
          <Button positive onClick={() => this.props.fnInsertFavoCate()}>
            분류 생성
          </Button>
        </div>
      </Modal>
    );
  }
}

export default FavoriteCateInsertModal;
