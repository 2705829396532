import React, { Component } from 'react';
import { Table, TextArea, Button, Form, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  queStore: stores.QuestionStore,
}))
@observer
class QuestionReplyContainer extends Component {
  render() {
    const { queStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {queStore.selectedQuestion == null ? null : (
          <React.Fragment>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    {queStore.selectedQuestion.questionTitle}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>문의내용</Table.Cell>
                  <Table.Cell>
                    {queStore.selectedQuestion.questionContent}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>답변작성</Table.Cell>
                  <Table.Cell>
                    <Form.Field>
                      <TextArea
                        style={{ resize: 'none', width: '100%' }}
                        placeholder="답변작성하기"
                        rows={3}
                        value={queStore.replyContent || ''}
                        onChange={e => {
                          console.log(e.target.value);
                          queStore.setReplyContent(e.target.value);
                        }}
                      />
                    </Form.Field>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>첨부이미지</Table.Cell>
                  <Table.Cell>
                    <div>
                      <img
                        style={{ width: '50%' }}
                        src={queStore.selectedQuestion.addfile1FilePath}
                      />
                    </div>
                    <div>
                      <img
                        style={{ width: '50%' }}
                        src={queStore.selectedQuestion.addfile2FilePath}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <div>
              <Button
                positive
                onClick={() => {
                  this.props.fnUpDateReply();
                }}
              >
                답변작성 완료
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default QuestionReplyContainer;
