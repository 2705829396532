import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class NotiStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable notiList = [];
  @observable selectedNoti = null;

  @observable notiTitle = null;
  @observable notiContent = null;
  @observable notiCd = null;

  @action
  setNotiList = value => {
    this.notiList = value;
  };

  @action
  initNotiList = () => {
    this.notiList = [];
  };

  @action
  setSelectedNoti = value => {
    this.selectedNoti = value;
  };

  @action
  initSelectedNoti = () => {
    this.selectedNoti = null;
  };

  @action
  setNotiTitle = value => {
    this.notiTitle = value;
  };

  @action
  initNotiTitle = () => {
    this.notiTitle = null;
  };

  @action
  setNotiContent = value => {
    this.notiContent = value;
  };

  @action
  initNotiContent = () => {
    this.notiContent = null;
  };

  @action
  setNotiCd = value => {
    this.notiCd = value;
  };

  @action
  initNotiCd = () => {
    this.notiCd = null;
  };

  @action
  initForm = () => {
    this.initNotiTitle();
    this.initNotiContent();
    this.initNotiCd();
    this.initSelectedNoti();
  };
}

export default NotiStore;
