import { apiAddress } from '../ApiPlatform';

//공통
export const API_ADMIN_LOGIN = apiAddress + 'customer/login';
export const API_SELECT_STAFF_LIST = apiAddress + 'customer/admin/staffList';
export const API_SELECT_STAFF_LOGIN = apiAddress + 'customer/admin/staffLogin';

//트럭커 관련 API
export const API_SELECT_DRIVER_LIST = apiAddress + 'driver/admin/driverList';
export const API_SELECT_APV_LIST_DRIVER = apiAddress + 'driver/admin/apvList';
export const API_UPDATE_INFO_WITH_APPROVED_DRIVER =
  apiAddress + 'driver/admin/approve';

//화주 관련 API
export const API_SELECT_CUSTOMER_LIST =
  apiAddress + 'customer/admin/customerList';
export const API_SELECT_CUSTOMER_APV_LIST =
  apiAddress + 'customer/admin/apvList';
export const API_UPDATE_INFO_WITH_APPROVED_CUSTOMER =
  apiAddress + 'customer/admin/approve';
export const API_INSERT_CUSTOMER = apiAddress + 'customer/regist';

//담당자
export const API_INSERT_STAFF = apiAddress + 'customer/admin/insertStaff';
export const API_UPDATE_STAFF = apiAddress + 'customer/admin/updateStaff';
export const API_DELETE_STAFF = apiAddress + 'customer/admin/deleteStaff';

//사업자등록조회
export const API_SELECT_NTS_BUSINESSMAN =
  'http://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=PRsNDBYd89XvCc%2Fc0pROwqzJ6rY5cKkpUMhJgv52%2BsIRlozMMCDjsH44abFKAs4Sc599UC77kJ42tn7K9uda3A%3D%3D';

//nice accessToken 발급
export const API_GENERATE_NICD_ACCESS_TOKEN = apiAddress + 'req/niceAccessAuth';

export const API_SELECT_CUSTOMER_SEARCH_LIST =
  apiAddress + 'customer/admin/customerSearchList';

export const API_SELECT_DRIVER_SEARCH_LIST =
  apiAddress + 'driver/admin/driverSearchList';

export const API_SELECT_CUSTOMER_SEARCH_LIST_WITH_KEYWORD =
  apiAddress + 'customer/admin/customerSearchListWithKeyword';

export const API_SELECT_DRIVER_SEARCH_LIST_WITH_KEYWORD =
  apiAddress + 'driver/admin/driverSearchListWithKeyword';

export const API_CHECK_DUPLICATE_CUSTOMER_ID =
  apiAddress + 'customer/admin/customerIdDupCheck';

export const API_UPDATE_CUSTOMER_PW = apiAddress + 'customer/updatePw';

export const API_SEARCH_CUSTOMER_ID =
  apiAddress + 'customer/admin/searchCustomerId';

export const API_SEARCH_CUSTOMER_PW_ISMATCH =
  apiAddress + 'customer/admin/searchCustomerIsMatch';

export const API_UPDATE_CUSTOMER_NEW_PW =
  apiAddress + 'customer/admin/updateNewPw';

export const API_UPDATE_CORPORATION_NEW_PW = apiAddress + 'mail/setNewPw';

export const API_SELECT_GROUP_LOGIS_STAFF_LIST =
  apiAddress + 'customer/admin/selectLogisStaffList';
