import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import NotiManagementContainer from './Notification/NotiManagementContainer';
import QuestionManagementContainer from './Question/QuestionManagementContainer';
import ConfigContainer from './Config/ConfigContainer';
import CommonAlert from '../../../Component/Common/CommonAlert';
@inject(stores => ({
  commonStore: stores.CommonStore,
}))
@observer
class SettingManagementContainer extends Component {
  handleItemClick = (name, path) => {
    const { commonStore } = this.props;
    this.setState({
      activeItem: name,
      searchTyp: null,
      searchKeyword: null,
      resultList: [],
    });

    let prevPath = this.props.location.pathname;
    let pathSplit = prevPath.split('/');

    if (name == pathSplit[pathSplit.length - 1]) {
      return false;
    } else {
      this.props.history.push(path);
      commonStore.setPath(path);
    }
  };

  render() {
    const { commonStore } = this.props;
    let currentPage;
    switch (this.props.location.pathname) {
      case '/setting/notice':
        currentPage = <NotiManagementContainer />;
        break;
      case '/setting/question':
        currentPage = <QuestionManagementContainer />;
        break;
      case '/setting/config':
        currentPage = <ConfigContainer />;
        break;
    }

    const panes = [
      {
        menuItem: {
          content: '공지사항',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {currentPage}
          </Tab.Pane>
        ),
        pane: { name: 'notice', path: '/setting/notice' },
      },
      {
        menuItem: {
          content: '문의사항',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {currentPage}
          </Tab.Pane>
        ),
        pane: { name: 'question', path: '/setting/question' },
      },
      {
        menuItem: {
          content: '비밀번호 변경',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {currentPage}
          </Tab.Pane>
        ),
        pane: { name: 'config', path: '/setting/config' },
      },
      {
        menuItem: {
          content: '이용약관',
          color: 'green',
          fontSize: '15px',
        },
        // render: () => (
        //   <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
        //     {currentPage}
        //   </Tab.Pane>
        // ),
        //pane: { name: 'config', path: '/setting/config' },
      },
    ];

    //운송현황 , 운송상태별 리스트
    return (
      <React.Fragment>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          onTabChange={(e, data) => {
            if (data.panes[data.activeIndex].pane == undefined) {
              commonStore.commonAlertOpen('준비중입니다.');
              return false;
            } else {
              this.handleItemClick(
                data.panes[data.activeIndex].pane.name,
                data.panes[data.activeIndex].pane.path,
              );
            }
          }}
          style={{ color: 'red' }}
        />
        <CommonAlert />
      </React.Fragment>
    );
  }
}

export default withRouter(SettingManagementContainer);
