import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
@inject(stores => ({
  accStore: stores.AccStore,
}))
class NiceAuthForWebView extends React.Component {
  componentDidMount() {
    this.fnConnetCheckPlus();
  }
  //nice 모듈 연결
  fnConnetCheckPlus = () => {
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'checkplus_main',
      method: 'post',
    }).then(function (response) {
      document.getElementById('hideNice').innerHTML = response.data;
      if (response.status == 200) {
        that.fnPopup(response.data);
      }
    });
  };

  //팝업 오픈
  fnPopup = () => {
    document.form_chk.action =
      'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
    document.form_chk.target = '_top';
    document.form_chk.submit();
  };

  render() {
    return (
      <React.Fragment>
        <div id="hideNice" style={{ display: 'none' }} />
      </React.Fragment>
    );
  }
}

export default NiceAuthForWebView;
