import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class AlarmTalkStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }
  //템플릿 관련
  @observable alarmTalkTemplateList = [];
  @observable selectedTemplate = null;
  @observable tmpTitle = null;
  @observable tmpContent = null;
  @observable useYn = false;

  @observable alarmTalkList = [];
  @observable selectedAlarmTalk = null;

  @observable mergeKey = null;

  @action
  setAlarmTalkTemplateList = value => {
    this.alarmTalkTemplateList = value;
  };

  @action
  initAlarmTalkTemplateList = () => {
    this.alarmTalkTemplateList = [];
  };

  @action
  setSelectedTemplate = value => {
    this.selectedTemplate = value;
  };

  @action
  initSelectedTemplate = () => {
    this.selectedTemplate = null;
  };

  @action
  setTmpTitle = value => {
    this.tmpTitle = value;
  };

  @action
  initTmpTitle = () => {
    this.tmpTitle = null;
  };

  @action
  setTmpContent = value => {
    this.tmpContent = value;
  };

  @action
  initTmpContent = () => {
    this.tmpContent = null;
  };

  @action
  setUseYnTrue = () => {
    this.useYn = true;
  };

  @action
  setUseYnFalse = () => {
    this.useYn = false;
  };

  @action
  setMergeKey = value => {
    this.mergeKey = value;
  };

  @action
  initMergeKey = () => {
    this.mergeKey = null;
  };

  @action
  initForm = () => {
    this.initTmpTitle();
    this.initTmpContent();
    this.setUseYnFalse();
    this.initMergeKey();
  };

  @action
  setAlarmTalkList = value => {
    this.alarmTalkList = value;
  };

  @action
  initAlarmTalkList = () => {
    this.alarmTalkList = [];
  };

  @action
  setSelectedAlarmTalk = value => {
    this.selectedAlarmTalk = value;
  };

  @action
  initSelectedAlarmTalk = () => {
    this.selectedAlarmTalk = null;
  };
}

export default AlarmTalkStore;
