import CommonStore from './CommonStore';
import AccStore from './AccStore';
import VcStore from './VcStore';
import OrderStore from './OrderStore';
import NotiStore from './NotiStore';
import FreightFeeStore from './FreightFeeStore';
import QuestionStore from './QuestionStore';
import EmpStore from './EmpStore';
import MenuStore from './MenuStore';
import PrivilegeStore from './PrivilegeStore';
import PaymentStore from './PaymentStore';
import MessageStore from './MessageStore';
import AlarmTalkStore from './AlarmTalkStore';
import FavoriteStore from './FavoriteStore';
import DepositStore from './DepositStore';
import AdsStore from './AdsStore';
import QuoteStore from './QuoteStore';
import CompStore from './CompStore';
import WorkbenchOrderStore from './WorkbenchOrderStore';
import WorkbenchDriverStore from './WorkbenchDriverStore';

class RootStore {
  constructor() {
    this.CommonStore = new CommonStore(this);
    this.AccStore = new AccStore(this);
    this.VcStore = new VcStore(this);
    this.OrderStore = new OrderStore(this);
    this.NotiStore = new NotiStore(this);
    this.FreightFeeStore = new FreightFeeStore(this);
    this.QuestionStore = new QuestionStore(this);
    this.EmpStore = new EmpStore(this);
    this.MenuStore = new MenuStore(this);
    this.PrivilegeStore = new PrivilegeStore(this);
    this.PaymentStore = new PaymentStore(this);
    this.MessageStore = new MessageStore(this);
    this.AlarmTalkStore = new AlarmTalkStore(this);
    this.FavoriteStore = new FavoriteStore(this);
    this.DepositStore = new DepositStore(this);
    this.AdsStore = new AdsStore(this);
    this.QuoteStore = new QuoteStore(this);
    this.CompStore = new CompStore(this);
    this.WorkbenchOrderStore = new WorkbenchOrderStore(this);
    this.WorkbenchDriverStore = new WorkbenchDriverStore(this);
  }
}

export default RootStore;
