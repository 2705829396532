import React, { Component } from 'react';
import { Button, Modal, Form, TextArea, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { ReactComponent as Cancle } from '../../assets/icon/cancle.svg';
import {
  COLOR_GRAY_GR01,
  COLOR_WHITE,
  COLOR_GREEN_PRIMARY,
} from '../../common/Color';
import CommonInput from '../../Component/Common/CommonInput';
import OnClickInput from '../../Component/Common/OnClickInput';
import {
  STR_DEPARTURE_ADD_SEARCH,
  STR_DEPARTURE_DETAIL_ADD_SEARCH,
  STR_ARRIVE_ADD_SEARCH,
  STR_ARRIVE_DETAIL_ADD_SEARCH,
  STR_SEN_4,
  STR_SEN_5,
  STR_SEN_8,
  STR_ORDERER_NM,
  STR_ORDERER_HP,
} from '../../common/Terms';
@inject(stores => ({
  quoteStore: stores.QuoteStore,
  vcStore: stores.VcStore,
  commonStore: stores.CommonStore,
}))
@observer
class MobileFreightModal extends Component {
  render() {
    const { quoteStore, vcStore } = this.props;
    return (
      <Modal
        open={quoteStore.mobileFreightModal}
        onClose={() => quoteStore.setMobileFreightModalClose()}
        style={{
          width: '90%',
          //background: COLOR_GRAY_GR01,
        }}
      >
        <Modal.Content>
          <div>
            <div
              style={{
                width: '50%',
                height: '35px',
                float: 'left',
                fontSize: '22px',
                fontWeight: '700',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              배차 신청하기
            </div>
            <div
              style={{
                width: '50%',
                height: '35px',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
              onClick={() => quoteStore.setMobileFreightModalClose()}
            >
              <Cancle width={12} height={13} />
            </div>

            <Form>
              <Form.Field>
                <label>상차지 주소</label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_DEPARTURE_ADD_SEARCH}
                  onClick={this.props.fnOpenDepartureModal}
                  defaultValue={quoteStore.loadingBasicAdd}
                />
                <CommonInput
                  placeholder={STR_DEPARTURE_DETAIL_ADD_SEARCH}
                  defaultValue={quoteStore.loadingDetailAdd}
                  onChange={this.props.fnSetDepartureDetailAdd}
                />
              </Form.Field>
              <Form.Field>
                <label>하차지 주소</label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_ARRIVE_ADD_SEARCH}
                  onClick={this.props.fnOpenArriveModal}
                  defaultValue={quoteStore.unloadingBasicAdd}
                />
                <CommonInput
                  placeholder={STR_ARRIVE_DETAIL_ADD_SEARCH}
                  defaultValue={quoteStore.unloadingDetailAdd}
                  onChange={this.props.fnSetArriveDetailAdd}
                />
              </Form.Field>

              <Form.Field style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  차량 선택
                </label>
                <Select
                  placeholder={STR_SEN_4}
                  options={vcStore.vcWeightCdList}
                  onChange={(e, data) => this.props.fnSetVcWeightCd(e, data)}
                  value={vcStore.vcWeightCd}
                />
              </Form.Field>
              <Form.Field style={{ marginBottom: '20px' }}>
                <Select
                  placeholder={STR_SEN_5}
                  options={vcStore.vcTypCdList}
                  onChange={(e, { value }) => this.props.fnSetVcTypCd(e, value)}
                  value={vcStore.vcTypCd}
                />
              </Form.Field>

              <Form.Field>
                <label>화물정보</label>
                <TextArea
                  value={quoteStore.cargoDispathInfo || ''}
                  style={{ resize: 'none' }}
                  placeholder={STR_SEN_8}
                  rows={3}
                  onChange={e => {
                    quoteStore.setCargoDispathInfo(e.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>주문자
                  정보
                </label>
                <CommonInput
                  placeholder={STR_ORDERER_NM}
                  defaultValue={quoteStore.ordererNm}
                  onChange={this.props.fnSetOrdererNm}
                />
                <CommonInput
                  placeholder={STR_ORDERER_HP}
                  defaultValue={quoteStore.ordererHpNo}
                  onChange={this.props.fnSetOrdererHpNo}
                />
              </Form.Field>
              <Form.Field>
                <label>하차지 담당자</label>
                <CommonInput
                  placeholder={'담당자이름'}
                  defaultValue={quoteStore.unloadingStaffNm}
                  onChange={this.props.fnSetUnloadingStaffNm}
                />
                <CommonInput
                  placeholder={'담당자 연락처'}
                  defaultValue={quoteStore.unloadingStaffHpNo}
                  onChange={this.props.fnSetUnloadingStaffHpNo}
                />
              </Form.Field>
            </Form>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderColor: COLOR_GREEN_PRIMARY,
                  borderRadius: '5px',
                  padding: '10px 25px',
                  color: COLOR_WHITE,
                  border: '1px solid',
                  fontSize: '14px',
                  fontWeight: '700',
                  background: COLOR_GREEN_PRIMARY,
                  marginTop: '24px',
                  marginBottom: '24px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.props.fnInsertFreightMini();
                }}
              >
                배차 신청하기
              </div>
            </div>
          </div>
        </Modal.Content>
        {/* <div style={{ float: 'right', marginTop: '20px', marginRight: '20px' }}>
          <Button positive>신청하기</Button>
        </div> */}
      </Modal>
    );
  }
}

export default MobileFreightModal;
