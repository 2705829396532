import { Component } from 'react';
import { Table, Input, Button, Select, Form, Radio } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { STR_SEN_3 } from '../../../../common/Terms';

@inject(stores => ({
  menuStore: stores.MenuStore,
}))
@observer
class MenuUpsertContainer extends Component {
  render() {
    const deptList = [
      { text: '상위메뉴', value: 1 },
      { text: '하위메뉴', value: 2 },
    ];
    const { menuStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {menuStore.selectedMenu == null ? (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">신규 메뉴 추가</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>메뉴 이름</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="메뉴명 입력"
                    value={menuStore.menuNm || ''}
                    onChange={e => {
                      menuStore.setMenuNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>경로(url)</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="경로 입력"
                    value={menuStore.menuPath || ''}
                    onChange={e => {
                      menuStore.setMenuPath(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>메뉴구분</Table.Cell>
                <Table.Cell>
                  <Select
                    placeholder={'메뉴구분 선택'}
                    options={deptList}
                    onChange={(e, { value }) => {
                      if (value == 1) {
                        menuStore.setDepth(value);
                      } else {
                        menuStore.setDepth(value);
                        this.props.fnSelectPrimaryMenuList();
                      }
                    }}
                    value={menuStore.depth}
                  />
                </Table.Cell>
              </Table.Row>
              {menuStore.depth == 2 ? (
                <Table.Row>
                  <Table.Cell collapsing>상위메뉴선택</Table.Cell>
                  <Table.Cell>
                    <Select
                      placeholder={'상웨메뉴 선택 선택'}
                      options={menuStore.primaryMenuList}
                      onChange={(e, { value }) => menuStore.setPrimaryNo(value)}
                      defaultValue={menuStore.primaryNo}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              <Table.Row>
                <Table.Cell collapsing>순서</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="메뉴 순서 기입"
                    value={menuStore.sortNo || ''}
                    onChange={e => {
                      menuStore.setSortNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>활성화키</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="활성화키 기입"
                    value={menuStore.eventKey || ''}
                    onChange={e => {
                      menuStore.setEventKey(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>아이콘</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="아이콘 클래스 기입"
                    value={menuStore.icon || ''}
                    onChange={e => {
                      menuStore.setIcon(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">메뉴 수정</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>메뉴 이름</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="메뉴명 입력"
                    value={menuStore.menuNm || ''}
                    onChange={e => {
                      menuStore.setMenuNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>경로(url)</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="경로 입력"
                    value={menuStore.menuPath || ''}
                    onChange={e => {
                      menuStore.setMenuPath(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>메뉴구분</Table.Cell>
                <Table.Cell>
                  <Select
                    disabled={true}
                    placeholder={'메뉴구분 선택'}
                    options={deptList}
                    onChange={(e, { value }) => {
                      if (value == 1) {
                        menuStore.setDepth(value);
                      } else {
                        menuStore.setDepth(value);
                        this.props.fnSelectPrimaryMenuList();
                      }
                    }}
                    value={menuStore.depth}
                  />
                </Table.Cell>
              </Table.Row>
              {menuStore.depth == 2 ? (
                <Table.Row>
                  <Table.Cell collapsing>상위메뉴선택</Table.Cell>
                  <Table.Cell>
                    <Select
                      placeholder={'상웨메뉴 선택 선택'}
                      options={menuStore.primaryMenuList}
                      onChange={(e, { value }) => [
                        console.log(value),
                        menuStore.setPrimaryNo(value),
                      ]}
                      value={menuStore.primaryNo}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              <Table.Row>
                <Table.Cell collapsing>순서</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="메뉴 순서 기입"
                    value={menuStore.sortNo || ''}
                    onChange={e => {
                      menuStore.setSortNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>활성화키</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="활성화키 기입"
                    value={menuStore.eventKey || ''}
                    onChange={e => {
                      menuStore.setEventKey(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>아이콘</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="아이콘 클래스 기입"
                    value={menuStore.icon || ''}
                    onChange={e => {
                      menuStore.setIcon(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>사용여부</Table.Cell>
                <Table.Cell>
                  <Form>
                    <Radio
                      toggle
                      defaultChecked={menuStore.useYn}
                      onChange={e => {
                        if (menuStore.useYn == true) {
                          menuStore.setUseYnFalse();
                        } else {
                          menuStore.setUseYnTrue();
                        }
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
        {menuStore.selectedMenu == null ? (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnInsertMenu();
              }}
            >
              저장하기
            </Button>
          </div>
        ) : (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnUpdateMenu();
              }}
            >
              수정하기
            </Button>
          </div>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default MenuUpsertContainer;
