import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { STR_ALERT, STR_OK } from '../../common/Terms';
import Agreements from '../../Component/Common/Agreements';
import Privacy from '../../Component/Common/Privacy';
import LocationAgreement from '../../Component/Common/LocationAgreement';
@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class AgreementModal extends Component {
  render() {
    const { accStore } = this.props;
    return (
      <Modal
        closeIcon
        closeOnDimmerClick={false}
        open={accStore.agreementModal.isOpen}
        onClose={() => accStore.agreementModalClose()}
        style={{
          height: '710px',
          width: '65%',
          position: 'fixed',
          top: '100px',
        }}
      >
        <Modal.Header>
          {accStore.agreementModal.typeNum == 1
            ? '서비스 이용약관'
            : accStore.agreementModal.typeNum == 2
            ? '개인(신용)정보의 수집, 이용에 관한사항'
            : accStore.agreementModal.typeNum == 3
            ? '위치기반 서비스 이용약관'
            : null}
        </Modal.Header>
        <Modal.Content>
          <div style={{ height: '710px' }}>
            {accStore.agreementModal.typeNum == 1 ? (
              <Agreements />
            ) : accStore.agreementModal.typeNum == 2 ? (
              <Privacy />
            ) : accStore.agreementModal.typeNum == 3 ? (
              <LocationAgreement />
            ) : null}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default AgreementModal;
