import AgreementContainer from './AgreementContainer';
import RegistContainer from './RegistContainer';
import RegistReturn from './RegistReturn';
import NiceAuthInfo from './NiceAuthInfo';
export const RegistIndex = {
  AgreementContainer,
  RegistContainer,
  RegistReturn,
  NiceAuthInfo,
};
