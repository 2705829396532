import { Component } from 'react';
import { Table, Input, Button, Form, Radio, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  STR_SEN_3,
  STR_EMAIL_INPUT,
  STR_EMAIL_SELECT,
} from '../../../common/Terms';
import { ReactComponent as UpdateIcon } from '../../../assets/icon/edit.svg';
import { ReactComponent as Cancle } from '../../../assets/icon/cancle.svg';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../../common/Color';

@inject(stores => ({
  wborderStore: stores.WorkbenchOrderStore,
}))
@observer
class WorkBenchOrderForm extends Component {
  render() {
    const { wborderStore } = this.props;
    return (
      <div
        style={{
          width: '100%',
          marginRight: '1%',
          padding: '10px',
        }}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'right',
          }}
        >
          <Button
            style={{ marginRight: '10px' }}
            positive
            onClick={() => {
              this.props.fnInsertFreight();
            }}
          >
            오더 입력
          </Button>
        </div>

        <Table style={{ border: '0px', marginTop: '55px' }}>
          <Table.Header style={{ border: '0px' }}>
            <Table.Row>
              <Table.HeaderCell colSpan="2" style={{ background: '#fff' }}>
                오더 자동 입력 폼
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>발주처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="발주처"
                  value={wborderStore.client || ''}
                  onChange={e => {
                    wborderStore.setClient(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>발주처
                담당자
              </Table.Cell>
              <Table.Cell>
                <Form>
                  <Form.Field>
                    <input
                      placeholder="발주처 담당자"
                      onChange={e =>
                        wborderStore.setClientStaff(e.target.value)
                      }
                      value={wborderStore.clientStaff || ''}
                    />
                  </Form.Field>
                </Form>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>발주처
                연락처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="발주처 연락처"
                  value={wborderStore.clientStaffHpNo || ''}
                  onChange={e => {
                    wborderStore.setClientStaffHpNo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지
                상호명
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차지 상호명"
                  value={wborderStore.loadingCompNm || ''}
                  onChange={e => {
                    wborderStore.setLoadingCompNm(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지 주소
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차지 주소"
                  value={wborderStore.loadingAdd || ''}
                  onChange={e => {
                    wborderStore.setLoadingAdd(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지
                담당자
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차지 담당자"
                  value={wborderStore.loadingStaff || ''}
                  onChange={e => {
                    wborderStore.setLoadingStaff(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지
                연락처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차지 연락처"
                  value={wborderStore.loadingStaffHpNo || ''}
                  onChange={e => {
                    wborderStore.setLoadingStaffHpNo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지
                상호명
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차지 상호명"
                  value={wborderStore.unloadingCompNm || ''}
                  onChange={e => {
                    wborderStore.setUnloadingCompNm(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지 주소
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차지 주소"
                  value={wborderStore.unloadingAdd || ''}
                  onChange={e => {
                    wborderStore.setUnloadingAdd(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지
                담당자
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차지 담당자"
                  value={wborderStore.unloadingStaff || ''}
                  onChange={e => {
                    wborderStore.setUnloadingStaff(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지
                연락처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차지 연락처"
                  value={wborderStore.unloadingStaffHpNo || ''}
                  onChange={e => {
                    wborderStore.setUnloadingStaffHpNo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>톤수
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차량 중량"
                  value={wborderStore.vcWeight || ''}
                  onChange={e => {
                    wborderStore.setVcWeight(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량 종류
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차량 종류"
                  value={wborderStore.vcTyp || ''}
                  onChange={e => {
                    wborderStore.setVcTyp(e.target.value);
                  }}
                  setVcTyp
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>화물정보
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="화묾정보"
                  value={wborderStore.freightInfo || ''}
                  onChange={e => {
                    wborderStore.setFreightInfo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>중량
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="중량"
                  value={wborderStore.freightWeight || ''}
                  onChange={e => {
                    wborderStore.setFreightWeight(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>특이사항
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="특이사항"
                  value={wborderStore.freightMemo || ''}
                  onChange={e => {
                    wborderStore.setFreightMemo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차일자
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차일자"
                  value={wborderStore.loadingDate || ''}
                  onChange={e => {
                    wborderStore.setLoadingDate(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차시간
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차시간"
                  value={wborderStore.loadingTime || ''}
                  onChange={e => {
                    wborderStore.setLoadingTime(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차일자
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차일자"
                  value={wborderStore.unloadingDate || ''}
                  onChange={e => {
                    wborderStore.setUnloadingDate(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차시간
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차시간"
                  value={wborderStore.unloadingTime || ''}
                  onChange={e => {
                    wborderStore.setUnloadingTime(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>결제방법
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="결제방법"
                  value={wborderStore.payCd || ''}
                  onChange={e => {
                    wborderStore.setPayCd(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default WorkBenchOrderForm;
