import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Select,
  Modal,
  Radio,
  Popup,
  Icon,
  Input,
  TextArea,
} from 'semantic-ui-react';
import DaumPostcode from 'react-daum-postcode';
import 'react-datepicker/dist/react-datepicker.css';
import {
  API_SELECT_COMCD,
  API_SELECT_VC_TYP_CD,
} from '../../../common/ApiAddress/CommonAPI';
import {
  API_DELETE_FAVORITE,
  API_UPDATE_FAVORITE,
} from '../../../common/ApiAddress/FavoriteAPI';
import { API_SELECT_GROUP_LOGIS_STAFF_LIST } from '../../../common/ApiAddress/AccountAPI';

import {
  STR_DEPARTURE_ADD_SEARCH,
  STR_DEPARTURE_DETAIL_ADD_SEARCH,
  STR_ARRIVE_ADD_SEARCH,
  STR_ARRIVE_DETAIL_ADD_SEARCH,
  STR_SEN_4,
  STR_SEN_5,
  STR_WAYPOINTS_ADD,
  STR_ADD_SEARCH,
  STR_DETAIL_ADD,
  STR_WAYPOINT_ADD_SEARCH_1,
  STR_WAYPOINT_ADD_SEARCH_2,
  STR_WAYPOINT_ADD_SEARCH_3,
  STR_SEN_8,
  STR_CAR_CNT,
  STR_SEN_9,
  STR_SELECT_LOADING_WAY,
  STR_SELECT_UNLOADING_WAY,
  STR_SEN_10,
  STR_SEN_12,
  STR_SEN_14,
  STR_SEN_15,
  STR_SEN_16,
  STR_SEN_17,
} from '../../../common/Terms';
import OnClickInput from '../../../Component/Common/OnClickInput';
import CommonInput from '../../../Component/Common/CommonInput';
import SelectLoadingModal from '../../../Component/Order/SelectLoadingModal';
import SelectUnLoadingModal from '../../../Component/Order/SelectUnLoadingModal';
import CommonAlert from '../../../Component/Common/CommonAlert';
import { ReactComponent as PlusWaypoint } from '../../../assets/icon/control_point.svg';
import { ReactComponent as MinusWaypoint } from '../../../assets/icon/remove_circle_outline.svg';
import { ReactComponent as UpdateIcon } from '../../../assets/icon/edit.svg';
import { ReactComponent as Cancle } from '../../../assets/icon/cancle.svg';
import {
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';
import FavoriteDeleteModal from '../../../Component/Management/Favorite/FavoriteDeleteModal';
import ConfirmModalForUpdate from '../../../Component/Common/ConfirmModalForUpdate';
@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  favoStore: stores.FavoriteStore,
  orderStore: stores.OrderStore,
}))
@observer
class FavoriteUpdateContainer extends Component {
  state = {
    //위경도
    lat: null,
    lng: null,
    //모달
    addDeparture: false,
    addArrival: false,
    addWaypoint1: false,
    addWaypoint2: false,
    addWaypoint3: false,
    loadingModal: false,
    unLoadingModal: false,

    //출발지,도착지, 경유지 위경도
    departureX: null,
    departureY: null,
    arrivalX: null,
    arrivalY: null,
    coordsWaypoints: [],

    //출발지, 도착지 법정코드
    startBcode: null,
    endBcode: null,
    wayPoint1Bcode: null,
    wayPoint2Bcode: null,
    wayPoint3Bcode: null,

    //입력form관련
    wayPointsNo: 1,
    editablePrice: true,

    //map
    defaultZoom: 10,

    //logisStaffInfoFlag
    logisStaffFlag: true,

    //Modal
    deleteModal: false,
  };

  componentDidMount() {
    const { favoStore, vcStore } = this.props;
    this.fnSelectLogisCd();
    this.fnSelectLogisStaffList();
    this.fnSelectVcWeightCd();
    let sysCd = favoStore.selectedFavo.vcWeightCd;
    let sysCdIdx = sysCd.substring(5, 8);
    this.fnSelectVcTypCd(sysCdIdx);
    this.fnSelectLoadingCd();
    let loadingCoord = favoStore.selectedFavo.loadingCoord;
    let loadingCoordSpl = loadingCoord.split(',');
    let unloadingCoord = favoStore.selectedFavo.unloadingCoord;
    let unloadingCoordSpl = unloadingCoord.split(',');
    let coordsWaypoints = [];
    if (favoStore.selectedFavo.waypoint1Coord != null) {
      let waypoint1Coord = favoStore.selectedFavo.waypoint1Coord;
      let waypoint1CoordSpl = waypoint1Coord.split(',');
      waypoint1Coord = {
        wayPointX: waypoint1CoordSpl[0],
        wayPointY: waypoint1CoordSpl[1],
      };
      coordsWaypoints.push(waypoint1Coord);
    }
    if (favoStore.selectedFavo.waypoint2Coord != null) {
      let waypoint2Coord = favoStore.selectedFavo.waypoint2Coord;
      let waypoint2CoordSpl = waypoint2Coord.split(',');
      waypoint2Coord = {
        wayPointX: waypoint2CoordSpl[0],
        wayPointY: waypoint2CoordSpl[1],
      };
      coordsWaypoints.push(waypoint2Coord);
    }
    if (favoStore.selectedFavo.waypoint3Coord != null) {
      let waypoint3Coord = favoStore.selectedFavo.waypoint3Coord;
      let waypoint3CoordSpl = waypoint3Coord.split(',');
      waypoint3Coord = {
        wayPointX: waypoint3CoordSpl[0],
        wayPointY: waypoint3CoordSpl[1],
      };
      coordsWaypoints.push(waypoint3Coord);
    }
    this.setState({
      wayPointsNo: favoStore.wayPointsList.length + 1,
      departureX: loadingCoordSpl[0],
      departureY: loadingCoordSpl[1],
      arrivalX: unloadingCoordSpl[0],
      arrivalY: unloadingCoordSpl[1],
      coordsWaypoints: coordsWaypoints,
      startBcode: favoStore.selectedFavo.loadingBcode,
      endBcode: favoStore.selectedFavo.unloadingBcode,
      wayPoint1Bcode: favoStore.selectedFavo.waypoint1Bcode,
      wayPoint2Bcode: favoStore.selectedFavo.waypoint2Bcode,
      wayPoint3Bcode: favoStore.selectedFavo.waypoint3Bcode,
    });
    setTimeout(this.fnGetPath, 500);
  }

  componentWillUnmount() {
    const { favoStore, vcStore } = this.props;
    //favoStore.initFavoForm();
    vcStore.initForOrder();
  }

  //화물구분 불러오기
  fnSelectLogisCd = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRDI';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let logisCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              logisCdList.push(params);
            }
            favoStore.setLogisCdList(logisCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량적재무게 타입 불러오기
  fnSelectVcWeightCd = () => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRWE';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let weightCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              params.sysCdIdx = response.data.data[i].sysCdIdx;
              weightCdList.push(params);
            }
            vcStore.setVcWeightCdList(weightCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량구분 타입 불러오기
  fnSelectVcTypCd = value => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRTY';
    if (value == '001') {
      params.sysCdIdx = value;
    } else if (value == '002') {
      params.sysCdIdx = value;
    } else {
      params.sysCdIdx = '';
    }
    axios({
      url: API_SELECT_VC_TYP_CD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let typCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              params.sysCdIdx = response.data.data[i].sysCdIdx;
              typCdList.push(params);
            }
            vcStore.setVcTypCdList(typCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //그룹 내 스태프 리스트 불러오기
  fnSelectLogisStaffList = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.groupNo = sessionStorage.getItem('groupNo');

    axios({
      url: API_SELECT_GROUP_LOGIS_STAFF_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let logistStaffList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = {
                customerNm: response.data.data[i].customerNm,
                hpNo: response.data.data[i].hpNo,
              };
              params.text = response.data.data[i].customerNm;
              logistStaffList.push(params);
            }
            let params = {};
            params.value = 'insert';
            params.text = '직접입력';
            logistStaffList.push(params);
            commonStore.setLogisStaffList(logistStaffList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량중량구분 관련 함수들
  fnSetVcWeightCd = (e, data) => {
    const { vcStore, favoStore } = this.props;
    vcStore.setVcWeightCd(data.value);
    vcStore.initVcTypCd();
    let sysCdIdx = data.value.substring(5, 8);
    this.fnSelectVcTypCd(sysCdIdx);

    //화물중량 자동세팅
    if (data.value == 'IFRWE001') {
      favoStore.setFreightWeight('330');
    }
    if (data.value == 'IFRWE002') {
      favoStore.setFreightWeight('550');
    }
    if (data.value == 'IFRWE003') {
      favoStore.setFreightWeight('1100');
    }
    if (data.value == 'IFRWE004') {
      favoStore.setFreightWeight('1540');
    }
    if (data.value == 'IFRWE005') {
      favoStore.setFreightWeight('2750');
    }
    if (data.value == 'IFRWE006') {
      favoStore.setFreightWeight('3850');
    }
    if (data.value == 'IFRWE007') {
      favoStore.setFreightWeight('3850');
    }
    if (data.value == 'IFRWE008') {
      favoStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE009') {
      favoStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE010') {
      favoStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE011') {
      favoStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE012') {
      favoStore.setFreightWeight('8800');
    }
    if (data.value == 'IFRWE013') {
      favoStore.setFreightWeight('11000');
    }
    if (data.value == 'IFRWE014') {
      favoStore.setFreightWeight('15400');
    }
    if (data.value == 'IFRWE015') {
      favoStore.setFreightWeight('19800');
    }
    if (data.value == 'IFRWE016') {
      favoStore.setFreightWeight('27500');
    }
  };
  //차량구분 선택함수
  fnSetVcTypCd = value => {
    const { vcStore } = this.props;
    vcStore.setVcTypCd(value);
  };

  //상차지 주소 검색 완료
  fnHandleCompleteDeparture = data => {
    const { favoStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addDeparture: !this.state.addDeparture,
      startBcode: data.bcode,
    });
    favoStore.setDepartureAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'D');
  };

  //하차지 주소 검색 완료
  fnHandleCompleteArrive = data => {
    const { favoStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addArrival: !this.state.addArrival,
      endBcode: data.bcode,
    });
    favoStore.setArriveAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'A');
  };

  fnGetcoords = (value, typ, index) => {
    const { favoStore } = this.props;
    let that = this;
    axios({
      url: 'https://teamtrucker.co.kr/naver/geocode',
      method: 'get',
      params: {
        query: value,
      },
    })
      .then(function (response) {
        if (typ === 'D') {
          //상차지
          that.setState({
            departureX: response.data.items.addresses[0].x,
            departureY: response.data.items.addresses[0].y,
          });
          favoStore.setDepartureCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          //경유지
        } else if (typ === 'W') {
          let wayPoint = {};
          let wayPointX = response.data.items.addresses[0].x;
          let wayPointY = response.data.items.addresses[0].y;
          wayPoint.wayPointX = wayPointX;
          wayPoint.wayPointY = wayPointY;

          let wayPointList = [...that.state.coordsWaypoints];
          wayPointList.splice(index - 1, 1, wayPoint);
          that.setState({
            coordsWaypoints: wayPointList,
          });
          if (that.state.arrivalX != null) {
            that.fnGetPath();
          }
        } else {
          //하차지
          that.setState({
            arrivalX: response.data.items.addresses[0].x,
            arrivalY: response.data.items.addresses[0].y,
          });
          favoStore.setArriveCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          //경로 구하는 함수로 이동
          that.fnGetPath();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetPath = () => {
    const { favoStore } = this.props;
    let start = this.state.departureX + ',' + this.state.departureY;
    let goal = this.state.arrivalX + ',' + this.state.arrivalY;
    let waypointsList = [...this.state.coordsWaypoints];
    let waypoints = null;
    for (let i = 0; i < waypointsList.length; i++) {
      if (i == 0) {
        waypoints =
          waypointsList[i].wayPointX + ',' + waypointsList[i].wayPointY;
      }
      if (i >= 1) {
        waypoints =
          waypoints +
          '|' +
          waypointsList[i].wayPointX +
          ',' +
          waypointsList[i].wayPointY;
      }
    }
    let that = this;
    axios({
      url: 'https://teamtrucker.co.kr/naver/drive',
      method: 'get',
      params: {
        start: start,
        goal: goal,
        waypoints: waypoints == null ? null : waypoints,
      },
    })
      .then(function (response) {
        let distance = response.data.items[0].summary.distance * 0.001;
        let second = response.data.items[0].summary.duration * 0.001;
        let hour = parseInt(second / 3600);
        let min = parseInt((second % 3600) / 60);

        if (parseInt(distance) < 50) {
          that.setState({
            defaultZoom: 12,
          });
        }

        if (50 < parseInt(distance) && parseInt(distance) < 100) {
          that.setState({
            defaultZoom: 9,
          });
        }
        if (100 < parseInt(distance) && parseInt(distance) < 150) {
          that.setState({
            defaultZoom: 8,
          });
        }
        if (150 < parseInt(distance)) {
          that.setState({
            defaultZoom: 7,
          });
        }

        favoStore.setDistance(distance + 'km');
        if (hour > 0) {
          favoStore.setDuration(hour + '시간' + min + '분');
        } else {
          favoStore.setDuration(min + '분');
        }
        favoStore.setOrderPath(response.data.items[0].path);
      })
      .catch(function (error) {
        toast(error);
        console.log(error);
      });
  };

  fnOpenDepartureModal = () => {
    this.setState({
      addDeparture: !this.state.addDeparture,
    });
  };

  fnOpenArriveModal = () => {
    this.setState({
      addArrival: !this.state.addArrival,
    });
  };

  fnSetDepartureDetailAdd = value => {
    const { favoStore } = this.props;
    favoStore.setDepartureDetailAdd(value);
  };

  fnSetArriveDetailAdd = value => {
    const { favoStore } = this.props;
    favoStore.setArriveDetailAdd(value);
  };

  fnOnchangeLogisCd = value => {
    const { favoStore } = this.props;
    favoStore.setLogisCd(value);
  };

  //경유지 관련 함수
  //경유지 증가
  fnIncreaseWayPoint = () => {
    const { favoStore } = this.props;
    if (this.state.wayPointsNo <= 3) {
      favoStore.setWayPointsList(this.state.wayPointsNo, 'add', {});
      let freightInfo = favoStore.freightInfo;
      let addFreightInfo = null;
      if (freightInfo == null) {
        addFreightInfo = '경유지' + this.state.wayPointsNo + ' 특이사항 : ';
      } else {
        addFreightInfo =
          freightInfo + '\n경유지' + this.state.wayPointsNo + ' 특이사항 : ';
      }
      favoStore.setFreightInfo(addFreightInfo);
      this.setState({
        wayPointsNo: this.state.wayPointsNo + 1,
      });
    } else {
      alert('경유지는 최대 3개까지 추가 가능합니다.');
    }
  };

  //경유지 삭제
  fnDecreaseWayPoint = index => {
    const { favoStore } = this.props;
    favoStore.setWayPointsList(index, 'remove');
    this.setState({
      wayPointsNo: this.state.wayPointsNo - 1,
    });

    //위경도 리스트 삭제
    let spreadCoords = [...this.state.coordsWaypoints];
    spreadCoords.splice(index, 1);
    this.setState({
      coordsWaypoints: spreadCoords,
    });

    if (index == 0) {
      this.setState({
        wayPoint1Bcode: null,
      });
    }
    if (index == 1) {
      this.setState({
        wayPoint2Bcode: null,
      });
    }
    if (index == 2) {
      this.setState({
        wayPoint3Bcode: null,
      });
    }
    setTimeout(this.fnGetPath, 500);
  };

  //경유지 주소 검색 모달 오픈
  fnOpenWayPointModal = index => {
    if (index == 1) {
      this.setState({
        addWaypoint1: !this.state.addWaypoint1,
      });
    } else if (index == 2) {
      this.setState({
        addWaypoint2: !this.state.addWaypoint2,
      });
    } else if (index == 3) {
      this.setState({
        addWaypoint3: !this.state.addWaypoint3,
      });
    }
  };

  //경유지1 주소 검색 완료
  fnHandleCompleteWayPoint1 = data => {
    const { favoStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint1: !this.state.addWaypoint1,
      wayPoint1Bcode: data.bcode,
    });

    favoStore.setWayPointsBasicAdd(1, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 1);
  };

  //경유지2 주소 검색 완료
  fnHandleCompleteWayPoint2 = data => {
    const { favoStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint2: !this.state.addWaypoint2,
      wayPoint2Bcode: data.bcode,
    });

    favoStore.setWayPointsBasicAdd(2, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 2);
  };

  //경유지3 주소 검색 완료
  fnHandleCompleteWayPoint3 = data => {
    const { favoStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint3: !this.state.addWaypoint3,
      wayPoint3Bcode: data.bcode,
    });

    favoStore.setWayPointsBasicAdd(3, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 3);
  };

  //경유지 상세주소
  fnSetWayPointDetailAdd = (index, value) => {
    const { favoStore } = this.props;
    favoStore.setWayPointsDetailAdd(index, value);
  };

  //상하차 방법 불러오기
  fnSelectLoadingCd = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRLO';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let loadingCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              loadingCdList.push(params);
            }
            orderStore.setLoadingCdList(loadingCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOnchangeLoadingCd = value => {
    const { favoStore } = this.props;
    favoStore.setLoadingCd(value);
  };

  fnOnchangeUnLoadingCd = value => {
    const { favoStore } = this.props;
    favoStore.setUnLoadingCd(value);
  };

  //상차방법 모달 열기
  fnSelectLoadingModalOpen = () => {
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };
  //상차방법 모달 닫기
  fnSelectLoadingModalClose = () => {
    const { favoStore } = this.props;
    favoStore.initLoadingCd();
    favoStore.initLoadingNm();
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };
  // 상차방법 선택 후 모달 닫기
  fnSetSelectLoading = value => {
    const { favoStore } = this.props;
    console.log(value);
    favoStore.setLoadingCd(value.value);
    favoStore.setLoadingNm(value.text);
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };

  //하차방법 모달 열기
  fnSelectUnLoadingModalOpen = () => {
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };

  //하차방법 모달 닫기
  fnSelectUnLoadingModalClose = () => {
    const { favoStore } = this.props;
    favoStore.initUnLoadingCd();
    favoStore.initUnLoadingNm();
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };
  // 하차방법선택 후 모달 닫기
  fnSetSelectUnLoading = value => {
    const { favoStore } = this.props;
    favoStore.setUnLoadingCd(value.value);
    favoStore.setUnLoadingNm(value.text);
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };

  //즐겨찾기 내용 수정
  fnUpdateFavorite = () => {
    const { favoStore, vcStore } = this.props;
    let params = {};
    let that = this;
    params.favoNm = favoStore.favoNm;
    params.loadingBasicAdd = favoStore.departureAdd;
    params.loadingDetailAdd = favoStore.departureDetailAdd;
    params.loadingCoord = favoStore.departureCoord;
    params.loadingBcode = this.state.startBcode;
    params.unloadingBasicAdd = favoStore.arriveAdd;
    params.unloadingDetailAdd = favoStore.arriveDetailAdd;
    params.unloadingCoord = favoStore.arriveCoord;
    params.unloadingBcode = this.state.endBcode;

    //경유지
    params.waypoint1BasicAdd =
      favoStore.wayPointsList[0] != undefined
        ? favoStore.wayPointsList[0].basicAdd
        : null;
    params.waypoint1DetailAdd =
      favoStore.wayPointsList[0] != undefined
        ? favoStore.wayPointsList[0].detailAdd
        : null;
    params.waypoint1Coord =
      this.state.coordsWaypoints[0] != undefined
        ? this.state.coordsWaypoints[0].wayPointX +
          ',' +
          this.state.coordsWaypoints[0].wayPointY
        : null;
    params.waypoint1Bcode =
      favoStore.wayPointsList[0] != undefined
        ? this.state.wayPoint1Bcode
        : null;

    params.waypoint2BasicAdd =
      favoStore.wayPointsList[1] != undefined
        ? favoStore.wayPointsList[1].basicAdd
        : null;
    params.waypoint2DetailAdd =
      favoStore.wayPointsList[1] != undefined
        ? favoStore.wayPointsList[1].detailAdd
        : null;
    params.waypoint2Coord =
      this.state.coordsWaypoints[1] != undefined
        ? this.state.coordsWaypoints[1].wayPointX +
          ',' +
          this.state.coordsWaypoints[1].wayPointY
        : null;
    params.waypoint2Bcode =
      favoStore.wayPointsList[1] != undefined
        ? this.state.wayPoint2Bcode
        : null;

    params.waypoint3BasicAdd =
      favoStore.wayPointsList[2] != undefined
        ? favoStore.wayPointsList[2].basicAdd
        : null;
    params.waypoint3DetailAdd =
      favoStore.wayPointsList[2] != undefined
        ? favoStore.wayPointsList[2].detailAdd
        : null;
    params.waypoint3Coord =
      this.state.coordsWaypoints[2] != undefined
        ? this.state.coordsWaypoints[2].wayPointX +
          ',' +
          this.state.coordsWaypoints[2].wayPointY
        : null;

    params.waypoint3Bcode =
      favoStore.wayPointsList[2] != undefined
        ? this.state.wayPoint3Bcode
        : null;

    params.freightInfo = favoStore.freightInfo;
    params.logisCd = favoStore.logisCd;
    params.freightWeight = favoStore.freightWeight;
    params.loadingCd = favoStore.loadingCd;
    params.unloadingCd = favoStore.unloadingCd;
    params.vcCnt = favoStore.vcCnt;
    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;
    params.distance = favoStore.distance;
    params.duration = favoStore.duration;
    params.updateId = sessionStorage.getItem('customerId');
    params.favoNo = favoStore.selectedFavo.favoNo;

    //상차지 담당자
    if (
      favoStore.loadingStaffRank == null ||
      favoStore.loadingStaffRank == ''
    ) {
      params.loadingStaffInfo = favoStore.loadingStaffNm + '|';
    } else {
      params.loadingStaffInfo =
        favoStore.loadingStaffNm + '|' + favoStore.loadingStaffRank;
    }
    params.loadingStaffHpNo = favoStore.loadingStaffHpNo;

    //하차지 담당자
    if (
      favoStore.unloadingStaffRank == null ||
      favoStore.unloadingStaffRank == ''
    ) {
      params.unloadingStaffInfo = favoStore.unloadingStaffNm + '|';
    } else {
      params.unloadingStaffInfo =
        favoStore.unloadingStaffNm + '|' + favoStore.unloadingStaffRank;
    }
    params.unloadingStaffHpNo = favoStore.unloadingStaffHpNo;
    let validation = this.fnValidation();
    if (validation == true) {
      axios({
        url: API_UPDATE_FAVORITE,
        method: 'post',
        data: params,
      })
        .then(response => {
          if (response.data.resultCode == 100) {
            that.props.fnSelectFavoList();
            that.fnUpdateFavoriteModalClose();
            //window.location.reload();
          } else {
            alert('수정실패');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //화물등록 유효성 검사
  fnValidation = () => {
    const { commonStore, favoStore, vcStore } = this.props;

    if (favoStore.logisCd == null || favoStore.logisCd == '') {
      commonStore.commonAlertOpen(STR_SEN_15);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (vcStore.vcWeightCd == null || vcStore.vcWeightCd == '') {
      commonStore.commonAlertOpen(STR_SEN_4);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (vcStore.vcTypCd == null || vcStore.vcTypCd == '') {
      commonStore.commonAlertOpen(STR_SEN_5);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.departureAdd == null || favoStore.departureAdd == '') {
      commonStore.commonAlertOpen(STR_SEN_10);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.arriveAdd == null || favoStore.arriveAdd == '') {
      commonStore.commonAlertOpen(STR_SEN_12);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.departureCoord == null || favoStore.departureCoord == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.arriveCoord == null || favoStore.arriveCoord == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.distance == null || favoStore.distance == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.duration == null || favoStore.duration == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.freightInfo == null || favoStore.freightInfo == '') {
      commonStore.commonAlertOpen(STR_SEN_8);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.loadingCd == null || favoStore.loadingCd == '') {
      commonStore.commonAlertOpen(STR_SEN_16);
      this.fnUpdateFavoriteModalClose();
      return false;
    }

    if (favoStore.unloadingCd == null || favoStore.unloadingCd == '') {
      commonStore.commonAlertOpen(STR_SEN_17);
      this.fnUpdateFavoriteModalClose();
      return false;
    }
    return true;
  };

  //상차지 담당자 선택 handler
  fnSetLodingLogistStaff = (e, data) => {
    const { favoStore } = this.props;
    if (data.value == 'insert') {
      favoStore.initLoadingStaffNm();
      favoStore.initLoadingStaffHpNo();
      favoStore.initLoadingStaffRank();
      this.setState({
        logisStaffFlag: false,
      });
    } else {
      favoStore.setLoadingStaffNm(data.value.customerNm);
      favoStore.setLoadingStaffHpNo(data.value.hpNo);
      this.setState({
        logisStaffFlag: true,
      });
    }
  };

  fnSetFavoNm = value => {
    const { favoStore } = this.props;
    favoStore.setFavoNm(value);
  };

  fnDeleteFavorite = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.updateId = sessionStorage.getItem('customerId');
    params.favoNo = favoStore.selectedFavo.favoNo;
    axios({
      url: API_DELETE_FAVORITE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.props.fnSelectedFavoIndexInit();
          that.props.fnSelectFavoList();
          //window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOpenFavoDeleteModal = () => {
    this.setState({
      deleteModal: true,
    });
  };

  fnCloseFavoDeleteModal = () => {
    this.setState({
      deleteModal: false,
    });
  };

  //RadioGroup Handler
  handleChangeLogis = (e, { value }) => this.fnOnchangeLogisCd(value);
  handleChangeLoading = (e, { value }) => this.fnOnchangeLoadingCd(value);
  handleChangeUnLoading = (e, { value }) => this.fnOnchangeUnLoadingCd(value);

  //즐겨찾기 수정 모달 열기
  fnUpdateFavoriteModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForUpdateOpen('변경된 내용을 저장 하시겠습니까?');
  };

  //즐겨찾기 수정 모달 닫기
  fnUpdateFavoriteModalClose = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForUpdateClose();
  };

  render() {
    const { vcStore, favoStore, commonStore, orderStore } = this.props;
    //회원현황 , 회원승인
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            padding: '10px',
            height: '100%',
          }}
        >
          <h2>즐겨찾기 상세 및 수정</h2>

          <div
            className="orderForm"
            style={{
              overflowX: 'hidden',
              overflowY: 'scroll',
              height: 'calc(100vh - 239px)',
              marginBottom: '20px',
            }}
          >
            <Form>
              <Form.Field style={{ width: '78%' }}>
                <label>즐겨찾기 이름</label>
                <CommonInput
                  placeholder="즐겨찾기 명을 기입하세요."
                  defaultValue={favoStore.favoNm}
                  onChange={this.fnSetFavoNm}
                />
              </Form.Field>

              {/* 상차지 주소 */}
              <Form.Field style={{ width: '78%' }}>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  상차지 주소
                </label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_DEPARTURE_ADD_SEARCH}
                  onClick={this.fnOpenDepartureModal}
                  defaultValue={favoStore.departureAdd}
                />
                <CommonInput
                  placeholder={STR_DEPARTURE_DETAIL_ADD_SEARCH}
                  defaultValue={favoStore.departureDetailAdd}
                  onChange={this.fnSetDepartureDetailAdd}
                />
              </Form.Field>

              {sessionStorage.getItem('customerCd') == 'IACCU002' ? (
                <Form.Field>
                  <label
                    style={{
                      height: '23Px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    상차지 담당자
                  </label>
                  <Input
                    style={{ width: '26%', marginRight: '1.5%' }}
                    placeholder={'담당자 이름'}
                    value={favoStore.loadingStaffNm || ''}
                    onChange={e => favoStore.setLoadingStaffNm(e.target.value)}
                  />
                  <Input
                    style={{ width: '26%', marginRight: '1.5%' }}
                    placeholder={'직급'}
                    value={favoStore.loadingStaffRank || ''}
                    onChange={e =>
                      favoStore.setLoadingStaffRank(e.target.value)
                    }
                  />
                  <Input
                    style={{ width: '26%' }}
                    placeholder={'연락처'}
                    value={favoStore.loadingStaffHpNo || ''}
                    onChange={e =>
                      favoStore.setLoadingStaffHpNo(e.target.value)
                    }
                  />
                </Form.Field>
              ) : (
                <Fragment>
                  {/* <Form.Field>
                    <label
                      style={{
                        height: '23Px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      상차지 담당자
                    </label>
                    <Select
                      key={'lo'}
                      style={{
                        maxWidth: '190px',
                        minWidth: '100px',
                        marginRight: '9px',
                      }}
                      value={
                        favoStore.loadingStaffNm != null &&
                        favoStore.loadingStaffHpNo != null
                          ? {
                              customerNm: favoStore.loadingStaffNm,
                              hpNo: favoStore.loadingStaffHpNo,
                            }
                          : null
                      }
                      placeholder={'딤딩자를 선택해 주세요.'}
                      options={commonStore.logisStaffList}
                      onChange={(e, data) =>
                        this.fnSetLodingLogistStaff(e, data)
                      }
                    />
                  </Form.Field> */}
                  <Form.Field>
                    <label
                      style={{
                        height: '23Px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      상차지 담당자
                    </label>
                    <Input
                      style={{ width: '25%', marginRight: '1.5%' }}
                      placeholder={'담당자 이름'}
                      value={favoStore.loadingStaffNm || ''}
                      onChange={e =>
                        favoStore.setLoadingStaffNm(e.target.value)
                      }
                      //disabled={this.state.logisStaffFlag}
                    />
                    <Input
                      style={{ width: '25%', marginRight: '1.5%' }}
                      placeholder={'직급'}
                      value={favoStore.loadingStaffRank || ''}
                      onChange={e =>
                        favoStore.setLoadingStaffRank(e.target.value)
                      }
                    />
                    <Input
                      style={{ width: '25%' }}
                      placeholder={'연락처'}
                      value={favoStore.loadingStaffHpNo || ''}
                      onChange={e =>
                        favoStore.setLoadingStaffHpNo(e.target.value)
                      }
                      //disabled={this.state.logisStaffFlag}
                    />
                  </Form.Field>
                </Fragment>
              )}

              {/* 경유지 주소 */}

              {favoStore.wayPointsList.length > 0
                ? favoStore.wayPointsList.map((data, i) => (
                    <Form.Field key={i} style={{ width: '78%' }}>
                      <label>
                        {STR_WAYPOINTS_ADD + '주소'}
                        {i + 1}
                        <MinusWaypoint
                          style={{ position: 'absolute', right: '23%' }}
                          onClick={() => this.fnDecreaseWayPoint(i)}
                        />
                      </label>
                      <OnClickInput
                        readOnly={true}
                        placeholder={`${
                          STR_WAYPOINTS_ADD + (i + 1) + STR_ADD_SEARCH
                        }`}
                        onClick={() => this.fnOpenWayPointModal(i + 1)}
                        defaultValue={
                          favoStore.wayPointsList[i].basicAdd == undefined
                            ? null
                            : favoStore.wayPointsList[i].basicAdd
                        }
                      />
                      <Form.Field>
                        <input
                          placeholder={`${
                            STR_WAYPOINTS_ADD + (i + 1) + STR_DETAIL_ADD
                          }`}
                          onChange={e =>
                            this.fnSetWayPointDetailAdd(i + 1, e.target.value)
                          }
                          defaultValue={
                            favoStore.wayPointsList[i].detailAdd == undefined
                              ? null
                              : favoStore.wayPointsList[i].detailAdd
                          }
                        />
                      </Form.Field>
                    </Form.Field>
                  ))
                : null}

              {this.props.wayPointsNo > 3 ? null : (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <span>
                    <PlusWaypoint
                      width={24}
                      height={24}
                      // style={{ position: 'absolute', right: '23%' }}
                      onClick={() => this.fnIncreaseWayPoint()}
                    />
                  </span>
                </div>
              )}

              {/* 하차지 주소 */}
              <Form.Field style={{ width: '78%' }}>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지
                  주소
                </label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_ARRIVE_ADD_SEARCH}
                  onClick={this.fnOpenArriveModal}
                  defaultValue={favoStore.arriveAdd}
                />
                <CommonInput
                  placeholder={STR_ARRIVE_DETAIL_ADD_SEARCH}
                  defaultValue={favoStore.arriveDetailAdd}
                  onChange={this.fnSetArriveDetailAdd}
                />
              </Form.Field>

              <Form.Field>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  하차지 담당자
                </label>
                <Input
                  style={{ width: '25%', marginRight: '1.5%' }}
                  placeholder={'담당자 이름'}
                  value={favoStore.unloadingStaffNm || ''}
                  onChange={e => favoStore.setUnLoadingStaffNm(e.target.value)}
                />
                <Input
                  style={{ width: '25%', marginRight: '1.5%' }}
                  placeholder={'직급'}
                  value={favoStore.unloadingStaffRank || ''}
                  onChange={e =>
                    favoStore.setUnLoadingStaffRank(e.target.value)
                  }
                />
                <Input
                  style={{ width: '25%' }}
                  placeholder={'연락처'}
                  value={favoStore.unloadingStaffHpNo || ''}
                  onChange={e =>
                    favoStore.setUnLoadingStaffHpNo(e.target.value)
                  }
                />
              </Form.Field>

              {/* 차량 선택 부분 */}
              <Form.Field style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  차량 선택
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{ fontSize: '0.65em', width: '200px' }}
                        />
                      }
                      position="right center"
                    >
                      <p>
                        운송 할 화물에 맞게 차량 톤수, 구분, 종류를 선택해
                        주세요.
                      </p>
                    </Popup>
                  </span>
                </label>
                <Select
                  style={{
                    maxWidth: '190px',
                    minWidth: '100px',
                    float: 'left',
                    marginRight: '9px',
                  }}
                  placeholder={STR_SEN_4}
                  options={vcStore.vcWeightCdList}
                  onChange={(e, data) => this.fnSetVcWeightCd(e, data)}
                  value={vcStore.vcWeightCd}
                />
                <Select
                  style={{
                    maxWidth: '190px',
                    minWidth: '100px',
                    marginRight: '9px',
                  }}
                  placeholder={STR_SEN_5}
                  options={vcStore.vcTypCdList}
                  onChange={(e, { value }) => this.fnSetVcTypCd(value)}
                  value={vcStore.vcTypCd}
                />
                <Input
                  disabled={true}
                  min={1}
                  style={{ maxWidth: '90px', minWidth: '90px' }}
                  label={{ basic: true, content: '대' }}
                  labelPosition="right"
                  type="number"
                  placeholder={STR_CAR_CNT}
                  defaultValue={favoStore.vcCnt}
                  onChange={e => favoStore.setVcCnt(e.target.value)}
                />
              </Form.Field>

              <Form.Field style={{ height: '20px', marginBottom: '36px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  화물 운송 구분
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{ fontSize: '0.65em', width: '200px' }}
                        />
                      }
                      position="right center"
                    >
                      <p>일반 : 일반화물</p>
                      <p>긴급 : 긴급화물(가격이 상승 할 수 있습니다.)</p>
                      <p>혼적 : 다른 화물과 함께 운송합니다.</p>
                      <p>왕복 : 왕복 화물 운송 입니다.</p>
                    </Popup>
                  </span>
                </label>
                <div style={{ width: '50%' }}>
                  {favoStore.logisCdList.length > 0
                    ? favoStore.logisCdList.map((data, i) => (
                        <Radio
                          key={data.value}
                          style={{ width: '20%', float: 'left' }}
                          label={data.text}
                          name="logisCdGroup"
                          value={data.value}
                          checked={favoStore.logisCd == data.value}
                          onChange={this.handleChangeLogis}
                        />
                      ))
                    : null}
                </div>
              </Form.Field>

              <Form.Field style={{ width: '78%' }}>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>화물정보
                </label>
                <TextArea
                  value={favoStore.freightInfo || ''}
                  style={{ resize: 'none' }}
                  placeholder={STR_SEN_8}
                  rows={3}
                  onChange={e => {
                    favoStore.setFreightInfo(e.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field style={{ width: '78%' }}>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>화물중량
                </label>
                <Input
                  min={1}
                  label={{ basic: true, content: 'Kg' }}
                  labelPosition="right"
                  type="number"
                  placeholder={STR_SEN_9}
                  defaultValue={favoStore.freightWeight}
                  onChange={e => favoStore.setFreightWeight(e.target.value)}
                />
              </Form.Field>

              {/* <Form.Field style={{ height: '40px', marginBottom: '36px' }}>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차 방법
                </label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_SELECT_LOADING_WAY}
                  onClick={this.fnSelectLoadingModalOpen}
                  defaultValue={favoStore.loadingNm}
                />
              </Form.Field>
               */}

              <Form.Field style={{ height: '20px', marginBottom: '36px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  상차방법
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{
                            fontSize: '0.65em',
                            cursor: 'pointer',
                          }}
                        />
                      }
                      position="bottom center"
                      wide="very"
                    >
                      <p>수작업 : 화주가 수작업으로 물건을 옮깁니다.</p>
                      <p>수해줌 : 기사님이 수작업으로 물건을 옮깁니다.</p>
                      <p style={{ color: COLOR_PRIMARY }}>
                        수해줌 시 추가 비용이 발생 할 수 있습니다.
                      </p>
                    </Popup>
                    {/* <Icon
                      name="question"
                      size="small"
                      circular
                      style={{
                        fontSize: '0.65em',
                        width: '200px',
                        cursor: 'pointer',
                      }}
                    /> */}
                  </span>
                </label>

                {orderStore.loadingCdList.length > 0
                  ? orderStore.loadingCdList.map((data, i) => (
                      <Radio
                        key={data.value}
                        style={{ width: '20%', float: 'left' }}
                        label={data.text}
                        name="loadingCdGroup"
                        value={data.value}
                        checked={favoStore.loadingCd == data.value}
                        onChange={this.handleChangeLoading}
                        defaultValue="IFRLO001"
                      />
                    ))
                  : null}
              </Form.Field>
              {/* <Form.Field style={{ height: '40px', marginBottom: '36px' }}>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차 방법
                </label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_SELECT_UNLOADING_WAY}
                  onClick={this.fnSelectUnLoadingModalOpen}
                  defaultValue={favoStore.unloadingNm}
                />
              </Form.Field>
               */}
              <Form.Field style={{ height: '20px', marginBottom: '36px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  하차방법
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{
                            fontSize: '0.65em',
                            cursor: 'pointer',
                          }}
                        />
                      }
                      position="bottom center"
                      wide="very"
                    >
                      <p>수작업 : 화주가 수작업으로 물건을 옮깁니다.</p>
                      <p>수해줌 : 기사님이 수작업으로 물건을 옮깁니다.</p>
                      <p style={{ color: COLOR_PRIMARY }}>
                        수해줌 시 추가 비용이 발생 할 수 있습니다.
                      </p>
                    </Popup>
                  </span>
                </label>

                {orderStore.loadingCdList.length > 0
                  ? orderStore.loadingCdList.map((data, i) => (
                      <Radio
                        key={data.value}
                        style={{ width: '20%', float: 'left' }}
                        label={data.text}
                        name="unloadingCdGroup"
                        value={data.value}
                        checked={favoStore.unloadingCd == data.value}
                        onChange={this.handleChangeUnLoading}
                        defaultValue="IFRLO001"
                      />
                    ))
                  : null}
              </Form.Field>
            </Form>
          </div>

          <div
            style={{
              width: '200px',
              backgroundColor: COLOR_GREEN_PRIMARY,
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '700',
              borderRadius: '5px',
              padding: '10px 25px',
              float: 'right',
            }}
            onClick={() => {
              this.fnUpdateFavoriteModalOpen();
            }}
          >
            <UpdateIcon style={{ marginRight: '6px' }} />
            수정 완료
          </div>
          <div
            style={{
              width: '200px',
              backgroundColor: 'red',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '700',
              borderRadius: '5px',
              padding: '10px 25px',
              float: 'right',
              marginRight: '10px',
            }}
            onClick={() => {
              this.fnOpenFavoDeleteModal();
            }}
          >
            <Cancle
              style={{ marginRight: '6px' }}
              width={12}
              height={13}
              fill={COLOR_WHITE}
            />
            삭제
          </div>
        </div>

        {/* Modal 영역 */}
        {/* 상차지 검색 모달 */}
        <Modal
          open={this.state.addDeparture}
          onClose={() =>
            this.setState({
              addDeparture: false,
            })
          }
        >
          <Modal.Header>{STR_DEPARTURE_ADD_SEARCH}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteDeparture} />
          </Modal.Content>
        </Modal>
        {/* 허차지 검색 모달 */}
        <Modal
          open={this.state.addArrival}
          onClose={() =>
            this.setState({
              addArrival: false,
            })
          }
        >
          <Modal.Header>{STR_ARRIVE_ADD_SEARCH}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteArrive} />
          </Modal.Content>
        </Modal>

        {/* 경유지 검색 모달 */}
        <Modal
          open={this.state.addWaypoint1}
          onClose={() =>
            this.setState({
              addWaypoint1: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_1}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint1} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.addWaypoint2}
          onClose={() =>
            this.setState({
              addWaypoint2: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_2}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint2} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.addWaypoint3}
          onClose={() =>
            this.setState({
              addWaypoint3: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_3}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint3} />
          </Modal.Content>
        </Modal>
        {/* 경유지 검색 모달 */}
        {/* 상차방법 모달 */}
        <SelectLoadingModal
          open={this.state.loadingModal}
          onClose={this.fnSelectLoadingModalClose}
          modalHeader={STR_SELECT_LOADING_WAY}
          onChange={this.fnSetSelectLoading}
        />
        {/* 하차방법 모달 */}
        <SelectUnLoadingModal
          open={this.state.unLoadingModal}
          onClose={this.fnSelectUnLoadingModalClose}
          modalHeader={STR_SELECT_UNLOADING_WAY}
          onChange={this.fnSetSelectUnLoading}
        />

        <FavoriteDeleteModal
          deleteModal={this.state.deleteModal}
          fnCloseFavoDeleteModal={this.fnCloseFavoDeleteModal}
          fnDeleteFavorite={this.fnDeleteFavorite}
        />

        <CommonAlert />
        <ConfirmModalForUpdate
          fnConfirmNo={this.fnUpdateFavoriteModalClose}
          fnConfirmYes={this.fnUpdateFavorite}
        />
      </React.Fragment>
    );
  }
}

export default FavoriteUpdateContainer;
