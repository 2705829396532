import React, { Fragment, Component } from 'react';
import { Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  COLOR_BLACK,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  workDriverStore: stores.WorkbenchDriverStore,
}))
@observer
class DriverList extends Component {
  render() {
    const { workDriverStore } = this.props;
    return (
      <Fragment>
        <Table style={{ border: '0px' }}>
          {workDriverStore.driverList == null ||
          workDriverStore.driverList.length == 0 ? null : (
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                  }}
                >
                  이름
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    borderLeft: '0px',
                    background: '#fff',
                  }}
                >
                  연락처
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    borderLeft: '0px',
                    background: '#fff',
                  }}
                >
                  차량톤수
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    borderLeft: '0px',
                    background: '#fff',
                  }}
                >
                  차종
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          {workDriverStore.driverList == null ||
          workDriverStore.driverList.length == 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="14" textAlign="center">
                  등록된 트럭커가 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {workDriverStore.driverList.map((data, i) => (
                <React.Fragment key={data.driverNo}>
                  <Table.Row
                    onClick={() => this.props.fnUpdateViewOpen(data, i)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        workDriverStore.selectedDriver != null &&
                        workDriverStore.selectedDriver.driverNo ===
                          data.driverNo
                          ? COLOR_GREEN_PRIMARY
                          : COLOR_WHITE,
                      color:
                        workDriverStore.selectedDriver != null &&
                        workDriverStore.selectedDriver.driverNo ===
                          data.driverNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    <Table.Cell>{data.driverNm}</Table.Cell>
                    <Table.Cell>{data.driverHpNo}</Table.Cell>
                    <Table.Cell>{data.vcWeight}</Table.Cell>
                    <Table.Cell>{data.vcTyp}</Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
      </Fragment>
    );
  }
}

export default DriverList;
