import { Component } from 'react';
import { Table, Input, Button, Form, TextArea, Radio } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  armStore: stores.AlarmTalkStore,
}))
@observer
class MessageTemplateUpsertContainer extends Component {
  render() {
    const { armStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {armStore.selectedTemplate == null ? (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  알림톡 템플릿 작성
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>제목</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="템플릿 제목"
                    value={armStore.tmpTitle || ''}
                    onChange={e => {
                      armStore.setTmpTitle(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>내용</Table.Cell>
                <Table.Cell>
                  <Form>
                    <TextArea
                      value={armStore.tmpContent || ''}
                      style={{ resize: 'none' }}
                      placeholder={'템플릿 내용 작성'}
                      rows={3}
                      onChange={e => {
                        armStore.setTmpContent(e.target.value);
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>치환키</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="치환키 구분자  | "
                    value={armStore.mergeKey || ''}
                    onChange={e => {
                      armStore.setMergeKey(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  {armStore.selectedTemplate.tmpTitle} 수정
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>제목</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="템플릿 제목"
                    value={armStore.tmpTitle || ''}
                    onChange={e => {
                      armStore.setTmpTitle(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>내용</Table.Cell>
                <Table.Cell>
                  <Form>
                    <TextArea
                      value={armStore.tmpContent || ''}
                      style={{ resize: 'none' }}
                      placeholder={'템플릿 내용 작성'}
                      rows={3}
                      onChange={e => {
                        armStore.setTmpContent(e.target.value);
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>치환키</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="치환키 구분자  | "
                    value={armStore.mergeKey || ''}
                    onChange={e => {
                      armStore.setMergeKey(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>사용여부</Table.Cell>
                <Table.Cell>
                  <Form>
                    <Radio
                      toggle
                      checked={armStore.useYn}
                      onChange={e => {
                        if (armStore.useYn == true) {
                          armStore.setUseYnFalse();
                        } else {
                          armStore.setUseYnTrue();
                        }
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
        {armStore.selectedTemplate == null ? (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnUpsertAlarmTalkTemplate();
              }}
            >
              저장하기
            </Button>
          </div>
        ) : (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnUpsertAlarmTalkTemplate();
              }}
            >
              수정하기
            </Button>
          </div>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default MessageTemplateUpsertContainer;
