import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  orderStore: stores.OrderStore,
}))
@observer
class SelectLoadingModal extends Component {
  render() {
    const { orderStore } = this.props;
    return (
      <Modal
        open={this.props.open}
        onClose={() => this.props.onClose()}
        style={{ height: '270px' }}
      >
        <Modal.Header>{this.props.modalHeader}</Modal.Header>
        <Modal.Content style={{ marginTop: '25px' }}>
          {orderStore.loadingCdList.length > 0
            ? orderStore.loadingCdList.map((data, i) => (
                <React.Fragment key={'loding' + i}>
                  <input
                    id={`loadingGroup${i + 1}`}
                    type="radio"
                    name="loadingGroup"
                    value={data.value}
                    checked={orderStore.loadingCd == data.value}
                    style={{
                      width: '20%',
                      float: 'left',
                      height: '40px',
                      display: 'none',
                    }}
                    onChange={e =>
                      this.props.onChange({
                        value: e.target.value,
                        text: data.text,
                      })
                    }
                  />
                  <label
                    style={{
                      width: '19%',
                      float: 'left',
                      borderRadius: '6px',
                      border:
                        orderStore.loadingCd != data.value
                          ? '2px solid'
                          : '2px solid #ff695e',
                      display: 'grid',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '1%',
                    }}
                    htmlFor={`loadingGroup${i + 1}`}
                  >
                    <p
                      style={{
                        textAlign: 'center',
                        color:
                          orderStore.loadingCd != data.value ? null : '#ff695e',
                      }}
                    >
                      {data.text}
                    </p>
                    <img
                      style={{ width: '60px', height: '60px' }}
                      src={`../../image/lodingIcon${i + 1}.png`}
                    />
                  </label>
                </React.Fragment>
              ))
            : null}
        </Modal.Content>
      </Modal>
    );
  }
}

export default SelectLoadingModal;
