import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  mgsStore: stores.MessageStore,
}))
@observer
class MessageTemplateList extends Component {
  render() {
    const { mgsStore } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>그룹키</Table.HeaderCell>
            <Table.HeaderCell>제목</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {mgsStore.msgTemplateList == null ||
        mgsStore.msgTemplateList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                등록된 템플릿이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {mgsStore.msgTemplateList.map((data, i) => (
              <React.Fragment key={data.groupKey}>
                <Table.Row onClick={() => this.props.fnViewOpen(data)}>
                  <Table.Cell>{data.groupKey}</Table.Cell>
                  <Table.Cell>{data.title}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default MessageTemplateList;
