import React, { Component } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class StaffListItem extends React.Component {
  fnMouseOverEvent = i => {
    let element = document.getElementById(`staffItem_${i}`);
    let staffNm = document.getElementById(`staffNm_${i}`);
    element.style.border = '2px solid #fff';
    staffNm.style.color = '#fff';
  };

  fnMouseOutEvent = i => {
    let element = document.getElementById(`staffItem_${i}`);
    let staffNm = document.getElementById(`staffNm_${i}`);
    element.style.border = '0px';
    staffNm.style.color = 'gray';
  };

  render() {
    const { accStore } = this.props;
    return (
      <div
        style={{
          marginRight: '30px',
          minWidth: '100px',
          minHeight: '100px',
          maxWidth: '150px',
          maxHeight: '150px',
          float: 'left',
        }}
        onClick={() => this.props.fnSelectStaff(this.props.data)}
      >
        <div
          id={`staffItem_${this.props.i + 1}`}
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'red',
            minWidth: '150px',
            minHeight: '150px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
          onMouseOver={() => this.fnMouseOverEvent(this.props.i + 1)}
          onMouseOut={() => this.fnMouseOutEvent(this.props.i + 1)}
        >
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {this.props.data.staffNm.substring(0, 1)}
          </p>
        </div>
        <p
          id={`staffNm_${this.props.i + 1}`}
          style={{
            textAlign: 'center',
            fontSize: '16px',
            marginTop: '10px',
            color: 'gray',
          }}
        >
          {this.props.data.staffNm}
        </p>
      </div>
    );
  }
}

export default StaffListItem;
