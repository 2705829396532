import { Component } from 'react';
import { Table, Input, Button, Select, Form, Radio } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { STR_SEN_3 } from '../../../../common/Terms';

@inject(stores => ({
  prvStore: stores.PrivilegeStore,
}))
@observer
class PrivilegeUpsertContainer extends Component {
  render() {
    const useYnList = [
      { text: 'Y', value: 'Y' },
      { text: 'N', value: 'N' },
    ];
    const { prvStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {prvStore.selectedAuthMenu == null ? (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">신규 권한 추가</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>조회</Table.Cell>
                <Table.Cell>
                  <Select
                    options={useYnList}
                    onChange={(e, { value }) => {
                      if (value == 'Y') {
                        prvStore.setUseYnSelectTrue();
                      } else {
                        prvStore.setUseYnSelectFalse();
                      }
                    }}
                    value={prvStore.useYnSelect}
                    text={prvStore.useYnSelect ? 'Y' : 'N'}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>입력</Table.Cell>
                <Table.Cell>
                  <Select
                    options={useYnList}
                    onChange={(e, { value }) => {
                      if (value == 'Y') {
                        prvStore.setUseYnInsertTrue();
                      } else {
                        prvStore.setUseYnInsertFalse();
                      }
                    }}
                    value={prvStore.useYnInsert}
                    text={prvStore.useYnInsert ? 'Y' : 'N'}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>수정</Table.Cell>
                <Table.Cell>
                  <Select
                    options={useYnList}
                    onChange={(e, { value }) => {
                      if (value == 'Y') {
                        prvStore.setUseYnUpdateTrue();
                      } else {
                        prvStore.setUseYnUpdateFalse();
                      }
                    }}
                    value={prvStore.useYnUpdate}
                    text={prvStore.useYnUpdate ? 'Y' : 'N'}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>삭제</Table.Cell>
                <Table.Cell>
                  <Select
                    options={useYnList}
                    onChange={(e, { value }) => {
                      if (value == 'Y') {
                        prvStore.setUseYnDeleteTrue();
                      } else {
                        prvStore.setUseYnDeleteFalse();
                      }
                    }}
                    value={prvStore.useYnDelete}
                    text={prvStore.useYnDelete ? 'Y' : 'N'}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">권한수정</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>조회</Table.Cell>
                <Table.Cell>
                  <Select
                    options={useYnList}
                    onChange={(e, { value }) => {
                      if (value == 'Y') {
                        prvStore.setUseYnSelectTrue();
                      } else {
                        prvStore.setUseYnSelectFalse();
                      }
                    }}
                    value={prvStore.useYnSelect}
                    text={prvStore.useYnSelect ? 'Y' : 'N'}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>입력</Table.Cell>
                <Table.Cell>
                  <Select
                    options={useYnList}
                    onChange={(e, { value }) => {
                      if (value == 'Y') {
                        prvStore.setUseYnInsertTrue();
                      } else {
                        prvStore.setUseYnInsertFalse();
                      }
                    }}
                    value={prvStore.useYnInsert}
                    text={prvStore.useYnInsert ? 'Y' : 'N'}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>수정</Table.Cell>
                <Table.Cell>
                  <Select
                    options={useYnList}
                    onChange={(e, { value }) => {
                      if (value == 'Y') {
                        prvStore.setUseYnUpdateTrue();
                      } else {
                        prvStore.setUseYnUpdateFalse();
                      }
                    }}
                    value={prvStore.useYnUpdate}
                    text={prvStore.useYnUpdate ? 'Y' : 'N'}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>삭제</Table.Cell>
                <Table.Cell>
                  <Select
                    options={useYnList}
                    onChange={(e, { value }) => {
                      if (value == 'Y') {
                        prvStore.setUseYnDeleteTrue();
                      } else {
                        prvStore.setUseYnDeleteFalse();
                      }
                    }}
                    value={prvStore.useYnDelete}
                    text={prvStore.useYnDelete ? 'Y' : 'N'}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
        {prvStore.selectedAuthMenu == null ? (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnInsertPrivilege();
              }}
            >
              저장하기
            </Button>
          </div>
        ) : (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnUpdatePrivilege();
              }}
            >
              수정하기
            </Button>
          </div>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default PrivilegeUpsertContainer;
