import React, { Component } from 'react';
import { Button, Modal, Table, Form } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
  COLOR_BLACK,
} from '../../common/Color';
import { STR_OK, STR_CANCEL } from '../../common/Terms';
import {
  API_SELECT_FAVORITE_CATE_LIST,
  API_SELECT_FAVORITE_LIST,
} from '../../common/ApiAddress/FavoriteAPI';
import axios from 'axios';
@inject(stores => ({
  favoStore: stores.FavoriteStore,
}))
@observer
class FavoriteListModal extends Component {
  componentDidMount() {
    this.fnSelecteFavoCateList();
  }

  fnSelecteFavoCateList = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.registId = sessionStorage.getItem('customerId');
    axios({
      url: API_SELECT_FAVORITE_CATE_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          favoStore.initSelectedCate();
          favoStore.setCateList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetSelectedCate = value => {
    const { favoStore } = this.props;
    favoStore.setSelectedCate(value);
    this.fnSelectFavoList();
  };

  fnSelectFavoList = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.registId = sessionStorage.getItem('customerId');
    params.cateNo = favoStore.selectedCate.cateNo;
    axios({
      url: API_SELECT_FAVORITE_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          favoStore.setFavoList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { favoStore } = this.props;
    return (
      <Modal
        size="small"
        open={this.props.favoListModal}
        onClose={() => this.props.fnFavoListModalClose()}
        style={{ height: '510px' }}
        closeOnDimmerClick={false}
      >
        <Modal.Header>즐겨찾기 선택</Modal.Header>
        <Modal.Content style={{ height: '350px' }}>
          <div
            className="favoriteList"
            style={{
              width: '35%',
              height: '350px',
              overflow: 'scroll',
              padding: '10px',
              overflowX: 'hidden',
              float: 'left',
            }}
          >
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>순번</Table.HeaderCell>
                  <Table.HeaderCell>분류명</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {favoStore.cateList == null || favoStore.cateList.length == 0 ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="3" textAlign="center">
                      등록된 분류가 없습니다.
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : (
                <Table.Body>
                  {favoStore.cateList.map((data, i) => (
                    <React.Fragment>
                      <Table.Row onClick={() => this.fnSetSelectedCate(data)}>
                        <Table.Cell
                          style={{
                            backgroundColor:
                              favoStore.selectedCate != null &&
                              favoStore.selectedCate.cateNo === data.cateNo
                                ? COLOR_GREEN_PRIMARY
                                : COLOR_WHITE,
                            color:
                              favoStore.selectedCate != null &&
                              favoStore.selectedCate.cateNo === data.cateNo
                                ? COLOR_WHITE
                                : COLOR_BLACK,
                          }}
                        >
                          {data.cateNo}
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            backgroundColor:
                              favoStore.selectedCate != null &&
                              favoStore.selectedCate.cateNo === data.cateNo
                                ? COLOR_GREEN_PRIMARY
                                : COLOR_WHITE,
                            color:
                              favoStore.selectedCate != null &&
                              favoStore.selectedCate.cateNo === data.cateNo
                                ? COLOR_WHITE
                                : COLOR_BLACK,
                          }}
                        >
                          {data.cateNm}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  ))}
                </Table.Body>
              )}
            </Table>
          </div>
          <div style={{ width: '65%', overflow: 'hidden', padding: '10px' }}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>순번</Table.HeaderCell>
                  <Table.HeaderCell>즐겨찾기 명</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {favoStore.favoList == null || favoStore.favoList.length == 0 ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="3" textAlign="center">
                      등록된 즐겨찾기가 없습니다.
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : (
                <Table.Body>
                  {favoStore.favoList.map((data, i) => (
                    <React.Fragment>
                      <Table.Row
                        onClick={() => favoStore.setSelectedFavo(data)}
                      >
                        <Table.Cell
                          style={{
                            backgroundColor:
                              favoStore.selectedFavo != null &&
                              favoStore.selectedFavo.favoNo === data.favoNo
                                ? COLOR_GREEN_PRIMARY
                                : COLOR_WHITE,
                            color:
                              favoStore.selectedFavo != null &&
                              favoStore.selectedFavo.favoNo === data.favoNo
                                ? COLOR_WHITE
                                : COLOR_BLACK,
                          }}
                        >
                          {data.cateNo}
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            backgroundColor:
                              favoStore.selectedFavo != null &&
                              favoStore.selectedFavo.favoNo === data.favoNo
                                ? COLOR_GREEN_PRIMARY
                                : COLOR_WHITE,
                            color:
                              favoStore.selectedFavo != null &&
                              favoStore.selectedFavo.favoNo === data.favoNo
                                ? COLOR_WHITE
                                : COLOR_BLACK,
                          }}
                        >
                          {data.favoNm}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  ))}
                </Table.Body>
              )}
            </Table>
          </div>
        </Modal.Content>
        <div style={{ float: 'right', marginTop: '30px', marginRight: '20px' }}>
          <Button negative onClick={() => this.props.fnFavoListModalClose()}>
            {STR_CANCEL}
          </Button>
          <Button
            positive
            onClick={() => this.props.fnSetOrderForm()}
            disabled={
              favoStore.selectedCate == null || favoStore.selectedFavo == null
                ? true
                : false
            }
          >
            {STR_OK}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default FavoriteListModal;
