import { Component } from 'react';
import { isLogin } from '../../Util/CommonUtil';
class Error extends Component {
  render() {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div>
          <div
            style={{ width: '100%', textAlign: 'center', marginBottom: '30px' }}
          >
            <i
              className="far fa-window-close"
              color="red"
              style={{ fontSize: '15em' }}
            />
          </div>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
            <p>잘못된 접급입니다.</p>
          </div>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '20px' }}>
            {isLogin() == true ? (
              <a href="/main">
                <p>메인페이지로 이동합니다.</p>
              </a>
            ) : (
              <a href="/">
                <p>메인페이지로 이동합니다.</p>
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
