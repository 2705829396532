import { Component } from 'react';
import { Table, Input, Button, Form, Radio, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  STR_SEN_3,
  STR_EMAIL_INPUT,
  STR_EMAIL_SELECT,
} from '../../../common/Terms';
import { ReactComponent as UpdateIcon } from '../../../assets/icon/edit.svg';
import { ReactComponent as Cancle } from '../../../assets/icon/cancle.svg';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../../common/Color';

@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
  compStore: stores.CompStore,
}))
@observer
class CompUpsertContainer extends Component {
  render() {
    const compCdList = [
      {
        label: '기업',
        value: 'C',
      },
      {
        label: '개인',
        value: 'P',
      },
    ];
    const { accStore, commonStore, compStore } = this.props;
    return (
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
          marginRight: '1%',
          padding: '10px',
        }}
      >
        {compStore.selectedComp == null ? (
          <div
            style={{
              width: '100%',
              textAlign: 'right',
              //marginTop: '10px',
              //paddingRight: '10px',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              positive
              onClick={() => {
                this.props.fnUpsertComp();
              }}
            >
              거래처 추가
            </Button>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                width: '130px',
                backgroundColor: COLOR_GREEN_PRIMARY,
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '700',
                borderRadius: '5px',
                padding: '10px 25px',
                float: 'right',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.fnUpsertComp();
              }}
            >
              <UpdateIcon style={{ marginRight: '6px' }} />
              수정하기
            </div>
          </div>
        )}
        {compStore.selectedComp == null ? (
          <Table style={{ border: '0px', marginTop: '55px' }}>
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell colSpan="2" style={{ background: '#fff' }}>
                  신규 거래처 추가
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>거래처 구분</Table.Cell>
                <Table.Cell>
                  {compCdList.length > 0
                    ? compCdList.map((data, i) => (
                        <Radio
                          //defaultChecked
                          style={{ width: '20%', float: 'left' }}
                          label={data.label}
                          name="compCdGroup"
                          value={data.value}
                          checked={compStore.compCd == data.value}
                          onChange={this.props.handleChangeCompCd}
                        />
                      ))
                    : null}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상호명
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="상호명"
                    value={compStore.compNm || ''}
                    onChange={e => {
                      compStore.setCompNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>등록번호
                </Table.Cell>
                <Table.Cell>
                  <Form>
                    <Form.Field>
                      <input
                        placeholder="등록번호"
                        onChange={e => compStore.setCompBsNo(e.target.value)}
                        value={compStore.compBsNo || ''}
                      />
                    </Form.Field>
                  </Form>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>연락처
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="연락처"
                    value={compStore.compHpNo || ''}
                    onChange={e => {
                      compStore.setCompHpNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>팩스번호
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="팩스번호"
                    value={compStore.compFaxNo || ''}
                    onChange={e => {
                      compStore.setCompFaxNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>대표자명
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="대표자명"
                    value={compStore.representative || ''}
                    onChange={e => {
                      compStore.setRepresentative(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>업태
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="업태"
                    value={compStore.bsTyp || ''}
                    onChange={e => {
                      compStore.setBsTyp(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>종목
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="종목"
                    value={compStore.bsNm || ''}
                    onChange={e => {
                      compStore.setBsNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>주소
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="주소"
                    value={compStore.compAdd || ''}
                    onChange={e => {
                      compStore.setCompAdd(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>이메일
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="이메일"
                    value={compStore.email || ''}
                    onChange={e => {
                      compStore.setEmail(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Table style={{ border: '0px', marginTop: '58px' }}>
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell colSpan="2" style={{ background: '#fff' }}>
                  거래처 정보 수정
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>거래처 구분</Table.Cell>
                <Table.Cell>
                  {compCdList.length > 0
                    ? compCdList.map((data, i) => (
                        <Radio
                          //defaultChecked
                          style={{ width: '20%', float: 'left' }}
                          label={data.label}
                          name="compCdGroup"
                          value={data.value}
                          checked={compStore.compCd == data.value}
                          onChange={this.props.handleChangeCompCd}
                        />
                      ))
                    : null}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상호명
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="상호명"
                    value={compStore.compNm || ''}
                    onChange={e => {
                      compStore.setCompNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>등록번호
                </Table.Cell>
                <Table.Cell>
                  <Form>
                    <Form.Field>
                      <input
                        placeholder="등록번호"
                        onChange={e => compStore.setCompBsNo(e.target.value)}
                        value={compStore.compBsNo || ''}
                      />
                    </Form.Field>
                  </Form>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>연락처
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="연락처"
                    value={compStore.compHpNo || ''}
                    onChange={e => {
                      compStore.setCompHpNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>팩스번호
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="팩스번호"
                    value={compStore.compFaxNo || ''}
                    onChange={e => {
                      compStore.setCompFaxNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>대표자명
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="대표자명"
                    value={compStore.representative || ''}
                    onChange={e => {
                      compStore.setRepresentative(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>업태
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="업태"
                    value={compStore.bsTyp || ''}
                    onChange={e => {
                      compStore.setBsTyp(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>종목
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="종목"
                    value={compStore.bsNm || ''}
                    onChange={e => {
                      compStore.setBsNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>주소
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="주소"
                    value={compStore.compAdd || ''}
                    onChange={e => {
                      compStore.setCompAdd(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>이메일
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="이메일"
                    value={compStore.email || ''}
                    onChange={e => {
                      compStore.setEmail(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default CompUpsertContainer;
