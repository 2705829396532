import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { TextArea } from 'semantic-ui-react';
import { COLOR_GRAY_GR02, COLOR_WHITE } from '../../../common/Color';
import WorkBenchOrderForm from './WorkBenchOrderForm';
import moment from 'moment';
import { API_INSERT_WORKBENCH_FREIGHT } from '../../../common/ApiAddress/WorkBenchFreightAPI';
@inject(stores => ({
  wborderStore: stores.WorkbenchOrderStore,
}))
@observer
class WorkBenchOrderManagementContainer extends Component {
  componentDidMount() {
    const { wborderStore } = this.props;
    wborderStore.initForm();
  }
  fninserText = () => {
    const { wborderStore } = this.props;
    let val = document.getElementById('mmsText').value;
    if (val.length == 0) {
      wborderStore.initForm();
    }
    let spl = val.split('\n');
    let params = {};
    for (let i = 0; i < spl.length; i++) {
      //0 발주처 추출
      if (i == 0) {
        let line_1 = spl[i].split(':');
        if (line_1.length >= 2) {
          params.client = line_1[1].trim();
          wborderStore.setClient(line_1[1].trim());
        }
      }

      //1 발주처 담당자 & 발주처 연락처 추출
      if (i == 1) {
        let line_2 = spl[i].split(':');
        let line_2ValSpl = line_2[1].split('/');
        if (line_2ValSpl.length >= 2) {
          if (line_2ValSpl[0].trim() != 'X') {
            params.clientStaff = line_2ValSpl[0].trim();
            wborderStore.setClientStaff(line_2ValSpl[0].trim());
          } else {
            params.clientStaff = null;
          }

          if (line_2ValSpl[1].trim() != 'X') {
            params.clientStaffHpNo = line_2ValSpl[1].trim();
            wborderStore.setClientStaffHpNo(line_2ValSpl[1].trim());
          } else {
            params.clientStaffHpNo = null;
          }
        }
      }
      // 상차지 상호명 추출
      if (i == 4) {
        if (spl[i].trim() != 'X') {
          params.loadingCompNm = spl[i].trim();
          wborderStore.setLoadingCompNm(spl[i].trim());
        } else {
          params.loadingCompNm = null;
        }
      }

      // 상차지 주소 추출
      if (i == 5) {
        if (spl[i].trim() != 'X') {
          params.loadingAdd = spl[i].trim();
          wborderStore.setLoadingAdd(spl[i].trim());
        } else {
          params.loadingAdd = null;
        }
      }

      // 상차지 담당자 & 연락처 추출
      if (i == 6) {
        let line_6 = spl[i].split(':');
        let line_6ValSpl = line_6[1].split('/');
        if (line_6ValSpl.length >= 2) {
          if (line_6ValSpl[0].trim() != 'X') {
            params.loadingStaff = line_6ValSpl[0].trim();
            wborderStore.setLoadingStaff(line_6ValSpl[0].trim());
          } else {
            params.loadingStaff = null;
          }
          if (line_6ValSpl[1].trim() != 'X') {
            params.loadingStaffHpNo = line_6ValSpl[1].trim();
            wborderStore.setLoadingStaffHpNo(line_6ValSpl[1].trim());
          } else {
            params.loadingStaffHpNo = null;
          }
        }
      }

      //하차지 상호명 추출
      if (i == 9) {
        if (spl[i].trim() != 'X') {
          params.unloadingCompNm = spl[i].trim();
          wborderStore.setUnloadingCompNm(spl[i].trim());
        } else {
          params.unloadingCompNm = null;
        }
      }

      // 하차지 주소 추출
      if (i == 10) {
        if (spl[i].trim() != 'X') {
          params.unloadingAdd = spl[i].trim();
          wborderStore.setUnloadingAdd(spl[i].trim());
        }
      }

      // 하차지 담당자 & 연락처 추출
      if (i == 11) {
        let line_11 = spl[i].split(':');
        let line_11ValSpl = line_11[1].split('/');
        if (line_11ValSpl.length >= 2) {
          if (line_11ValSpl[0].trim() != 'X') {
            params.unloadingStaff = line_11ValSpl[0].trim();
            wborderStore.setUnloadingStaff(line_11ValSpl[0].trim());
          } else {
            params.unloadingStaff = null;
          }
          if (line_11ValSpl[1].trim() != 'X') {
            params.unloadingStaffHpNo = line_11ValSpl[1].trim();
            wborderStore.setUnloadingStaffHpNo(line_11ValSpl[1].trim());
          } else {
            params.unloadingStaffHpNo = null;
          }
        }
      }

      //차량
      if (i == 13) {
        let line_13 = spl[i].split(':');
        let line_13ValSpl = line_13[1].split('/');
        if (line_13ValSpl.length >= 2) {
          if (line_13ValSpl[0].trim() != 'X') {
            params.vcWeight = line_13ValSpl[0].trim();
            wborderStore.setVcWeight(line_13ValSpl[0].trim());
          } else {
            params.vcWeight = null;
          }
          if (line_13ValSpl[1].trim() != 'X') {
            params.vcTyp = line_13ValSpl[1].trim();
            wborderStore.setVcTyp(line_13ValSpl[1].trim());
          } else {
            params.vcTyp = null;
          }
        }
      }

      //물건
      if (i == 14) {
        let line_14 = spl[i].split(':');
        if (line_14[1].trim() != 'X') {
          params.freightInfo = line_14[1].trim();
          wborderStore.setFreightInfo(line_14[1].trim());
        } else {
          params.freightInfo = null;
        }
      }
      //중량
      if (i == 15) {
        let line_15 = spl[i].split(':');
        if (line_15[1].trim() != 'X') {
          params.freightWeight = line_15[1].trim();
          wborderStore.setFreightWeight(line_15[1].trim());
        } else {
          params.freightWeight = null;
        }
      }
      //특이사항
      if (i == 16) {
        let line_16 = spl[i].split(':');
        if (line_16[1].trim() != 'X') {
          params.freightMemo = line_16[1].trim();
          wborderStore.setFreightMemo(line_16[1].trim());
        } else {
          params.freightMemo = null;
        }
      }
      //상차시간
      if (i == 17) {
        let line_17 = spl[i].split(':');
        let line_17ValSpl = line_17[1].split('/');
        if (line_17ValSpl.length >= 2) {
          if (line_17ValSpl[0].trim() != 'X') {
            params.loadingDate = line_17ValSpl[0].trim();
            wborderStore.setLoadingDate(line_17ValSpl[0].trim());
          } else {
            params.loadingDate = null;
          }
          if (line_17ValSpl[1].trim() != 'X') {
            params.loadingTime = line_17ValSpl[1].trim();
            wborderStore.setLoadingTime(line_17ValSpl[1].trim());
          } else {
            params.loadingTime = null;
          }
        }
      }
      //하차시간
      if (i == 18) {
        let line_18 = spl[i].split(':');
        let line_18ValSpl = line_18[1].split('/');
        if (line_18ValSpl.length >= 2) {
          if (line_18ValSpl[0].trim() != 'X') {
            params.unloadingDate = line_18ValSpl[0].trim();
            wborderStore.setUnloadingDate(line_18ValSpl[0].trim());
          } else {
            params.unloadingDate = null;
          }
          if (line_18ValSpl[1].trim() != 'X') {
            params.unloadingTime = line_18ValSpl[1].trim();
            wborderStore.setUnloadingTime(line_18ValSpl[1].trim());
          } else {
            params.unloadingTime = null;
          }
        }
      }
      //결제방법
      if (i == 19) {
        let line_19 = spl[i].split(':');
        if (line_19[1].trim() != 'X') {
          params.payCd = line_19[1].trim();
          wborderStore.setPayCd(line_19[1].trim());
        } else {
          params.payCd = null;
        }
      }
    }
  };

  fnInsertFreight = () => {
    const { wborderStore } = this.props;
    let params = {};
    let that = this;
    params.client = wborderStore.client;
    params.clientStaffNm = wborderStore.clientStaff;
    params.clientStaffHpNo = wborderStore.clientStaffHpNo;
    params.loadingCompNm = wborderStore.loadingCompNm;
    params.loadingAdd = wborderStore.loadingAdd;
    params.loadingStaff = wborderStore.loadingStaff;
    params.loadingStaffHpNo = wborderStore.loadingStaffHpNo;
    params.unloadingCompNm = wborderStore.unloadingCompNm;
    params.unloadingAdd = wborderStore.unloadingAdd;
    params.unloadingStaff = wborderStore.unloadingStaff;
    params.unloadingStaffHpNo = wborderStore.unloadingStaffHpNo;
    params.vcTyp = wborderStore.vcTyp;
    params.vcWeight = wborderStore.vcWeight;
    params.freightInfo = wborderStore.freightInfo;
    params.freightWeight = wborderStore.freightWeight;
    params.freightMemo = wborderStore.freightMemo;
    params.payCd = wborderStore.payCd;

    params.loadingDateStr = wborderStore.loadingDate;
    params.loadingTime = wborderStore.loadingTime;
    params.unloadingDateStr = wborderStore.unloadingDate;
    params.unloadingTime = wborderStore.unloadingTime;

    //날짜 변환 로직
    let now = new Date();
    let year = now.getFullYear();
    let loadingDate = wborderStore.loadingDate;
    let loadingDateMonthSpl = loadingDate.split('월');
    let loadingDateDateSpl = loadingDateMonthSpl[1].split('일');
    let modifyLoadingDate = moment(
      year + '-' + loadingDateMonthSpl[0] + '-' + loadingDateDateSpl[0],
    ).format('YYYY-MM-DD');

    let unloadingDate = wborderStore.unloadingDate;
    let unloadingDateMonthSpl = unloadingDate.split('월');
    let unloadingDateDateSpl = unloadingDateMonthSpl[1].split('일');
    let modifyunLoadingDate = moment(
      year + '-' + unloadingDateMonthSpl[0] + '-' + unloadingDateDateSpl[0],
    ).format('YYYY-MM-DD');

    params.loadingDate = modifyLoadingDate;
    params.unloadingDate = modifyunLoadingDate;
    axios({
      url: API_INSERT_WORKBENCH_FREIGHT,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          //문자전송 추가해야함
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          background: COLOR_GRAY_GR02,
          overflowY: 'scroll',
        }}
      >
        <div style={{ width: '90%', margin: '0 auto' }}>
          <div
            style={{
              width: '49%',
              height: 'calc(100vh - 180px)',
              background: COLOR_WHITE,
              borderRadius: '10px',
              float: 'left',
              marginRight: '2%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <div style={{ marginBottom: '20px' }}>
                <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  메세지 입력창
                </p>
              </div>

              <TextArea
                style={{ width: '500px', height: '500px', resize: 'none' }}
                id="mmsText"
                onChange={() => this.fninserText()}
              ></TextArea>
            </div>
          </div>

          <div
            style={{
              width: '49%',
              height: 'calc(100vh - 180px)',
              background: COLOR_WHITE,
              borderRadius: '10px',
              overflow: 'hidden',
              marginLeft: '10%',
              marginTop: '10px',
              overflowY: 'scroll',
            }}
          >
            <WorkBenchOrderForm fnInsertFreight={this.fnInsertFreight} />
          </div>

          <div style={{ width: '100%', height: '50px' }} />
        </div>
      </div>
    );
  }
}

export default WorkBenchOrderManagementContainer;
