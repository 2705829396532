import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  compStore: stores.CompStore,
}))
@observer
class CompInfo extends Component {
  render() {
    const { compStore } = this.props;
    return (
      <Fragment>
        <tr
          style={{
            width: '690px',
            height: '37px',
          }}
        >
          <td
            rowSpan={5}
            style={{
              border: '1px solid',
              borderLeft: '2px solid',
              textAlign: 'center',
              fontSize: '11pt',
            }}
          >
            공급자
          </td>
          <td
            style={{
              border: '1px solid',
              textAlign: 'center',
              borderLeft: '0px',
              fontSize: '11pt',
            }}
          >
            등록번호
          </td>
          <td
            colSpan={4}
            style={{
              textAlign: 'center',
              fontWeight: '700',
              border: '1px solid',
              borderLeft: '0px',
              fontSize: '12pt',
            }}
          >
            771-86-02308
          </td>
          <td
            rowSpan={5}
            style={{
              border: '1px solid',
              borderLeft: '0px',
              textAlign: 'center',
              fontSize: '12pt',
            }}
          >
            공급받는자
          </td>
          <td
            style={{
              border: '1px solid',
              textAlign: 'center',
              borderLeft: '0px',
              fontSize: '11pt',
            }}
          >
            등록번호
          </td>
          <td
            colSpan={4}
            style={{
              textAlign: 'center',
              fontWeight: '700',
              border: '1px solid',
              borderLeft: '0px',
              fontSize: '12pt',
              borderRight: '2px solid',
            }}
          >
            {compStore.selectedComp.compBsNo || ''}
          </td>
        </tr>

        <tr
          style={{
            width: '690px',
            height: '37px',
          }}
        >
          <td
            style={{
              textAlign: 'center',
              fontSize: '12pt',
              wordBreak: 'keep-all',
              borderBottom: '1px solid',
              borderRight: '1px solid',
            }}
          >
            상호 (법인명)
          </td>
          <td
            colSpan={2}
            style={{
              borderBottom: '1px solid',
              borderRight: '1px solid',
              textAlign: 'center',
              borderLeft: '0px',
              fontSize: '10pt',
            }}
          >
            (주)리크리에이트
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '12pt',
            }}
          >
            성명
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '10pt',
            }}
          >
            박훈진 (인)
          </td>
          <td
            style={{
              textAlign: 'center',
              fontSize: '12pt',
              wordBreak: 'keep-all',
              borderBottom: '1px solid',
              borderRight: '1px solid',
            }}
          >
            상호 (법인명)
          </td>
          <td
            colSpan={2}
            style={{
              borderBottom: '1px solid',
              borderRight: '1px solid',
              textAlign: 'center',
              borderLeft: '0px',
              fontSize: '10pt',
            }}
          >
            {compStore.selectedComp.compNm || ''}
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '12pt',
            }}
          >
            성명
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '2px solid',
              fontSize: '10pt',
            }}
          >
            {compStore.selectedComp.representative + ' (인)' || ''}
          </td>
        </tr>

        <tr
          style={{
            width: '690px',
            height: '37px',
          }}
        >
          <td
            style={{
              textAlign: 'center',
              wordBreak: 'keep-all',
              borderRight: '1px solid',
              borderBottom: '1px solid',
            }}
          >
            사업장 주소
          </td>
          <td
            style={{
              wordBreak: 'keep-all',
              borderRight: '1px solid',
              borderBottom: '1px solid',
              fontSize: '9pt',
            }}
            colSpan={4}
          >
            경기도 수원시 영동구 광교로 107 경기도경제과학진흥원 보육동 208호
          </td>
          <td
            style={{
              textAlign: 'center',
              wordBreak: 'keep-all',
              borderRight: '1px solid',
              borderBottom: '1px solid',
            }}
          >
            사업장 주소
          </td>
          <td
            style={{
              wordBreak: 'keep-all',
              borderRight: '2px solid',
              borderBottom: '1px solid',
              fontSize: '9pt',
            }}
            colSpan={4}
          >
            {compStore.selectedComp.compAdd || ''}
          </td>
        </tr>
        <tr
          style={{
            width: '690px',
            height: '37px',
          }}
        >
          <td
            style={{
              textAlign: 'center',
              fontSize: '12pt',
              wordBreak: 'keep-all',
              borderBottom: '1px solid',
              borderRight: '1px solid',
            }}
          >
            업태
          </td>
          <td
            colSpan={2}
            style={{
              borderBottom: '1px solid',
              borderRight: '1px solid',
              textAlign: 'center',
              borderLeft: '0px',
              fontSize: '9pt',
            }}
          >
            운수업
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '12pt',
            }}
          >
            종목
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '9pt',
              wordBreak: 'keep-all',
            }}
          >
            화물자동차 운송주선업
          </td>
          <td
            style={{
              textAlign: 'center',
              fontSize: '12pt',
              wordBreak: 'keep-all',
              borderBottom: '1px solid',
              borderRight: '1px solid',
            }}
          >
            업태
          </td>
          <td
            colSpan={2}
            style={{
              borderBottom: '1px solid',
              borderRight: '1px solid',
              textAlign: 'center',
              borderLeft: '0px',
              fontSize: '9pt',
              lineHeight: '13px',
            }}
          >
            {compStore.selectedComp.bsTyp || ''}
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '12pt',
            }}
          >
            종목
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '2px solid',
              fontSize: '9pt',
              lineHeight: '13px',
            }}
          >
            {compStore.selectedComp.bsNm || ''}
          </td>
        </tr>

        <tr
          style={{
            width: '690px',
            height: '37px',
          }}
        >
          <td
            style={{
              textAlign: 'center',
              fontSize: '12pt',
              wordBreak: 'keep-all',
              borderBottom: '1px solid',
              borderRight: '1px solid',
            }}
          >
            전화
          </td>
          <td
            colSpan={2}
            style={{
              borderBottom: '1px solid',
              borderRight: '1px solid',
              textAlign: 'center',
              borderLeft: '0px',
              fontSize: '9pt',
            }}
          >
            031-297-2934
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '12pt',
            }}
          >
            팩스
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '9pt',
              wordBreak: 'keep-all',
            }}
          >
            031-292-2934
          </td>
          <td
            style={{
              textAlign: 'center',
              fontSize: '12pt',
              wordBreak: 'keep-all',
              borderBottom: '1px solid',
              borderRight: '1px solid',
            }}
          >
            전화
          </td>
          <td
            colSpan={2}
            style={{
              borderBottom: '1px solid',
              borderRight: '1px solid',
              textAlign: 'center',
              borderLeft: '0px',
              fontSize: '9pt',
            }}
          >
            {compStore.selectedComp.compHpNo || ''}
          </td>
          <td
            style={{
              textAlign: 'center',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              fontSize: '12pt',
            }}
          >
            팩스
          </td>
          <td
            style={{
              textAlign: 'center',
              fontWeight: '700',
              borderBottom: '1px solid',
              borderRight: '2px solid',
              fontSize: '9pt',
            }}
          >
            {compStore.selectedComp.compFaxNo || ''}
          </td>
        </tr>
      </Fragment>
    );
  }
}

export default CompInfo;
