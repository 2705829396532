import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class MessageStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }
  //템플릿 관련
  @observable msgTemplateList = [];
  @observable title = null;
  @observable text = null;
  @observable mergeKey = null;
  @observable selectedTemplate = null;
  @observable sysCdNm = null;
  @observable useYn = false;

  @observable msgList = [];
  @observable selectedMsg = null;

  @action
  setMsgTemplateList = value => {
    this.msgTemplateList = value;
  };

  @action
  initMsgTemplateList = () => {
    this.msgTemplateList = [];
  };

  @action
  setTitle = value => {
    this.title = value;
  };

  @action
  initTitle = () => {
    this.title = null;
  };

  @action
  setText = value => {
    this.text = value;
  };

  @action
  initText = () => {
    this.text = null;
  };

  @action
  setMergeKey = value => {
    this.mergeKey = value;
  };

  @action
  initMergeKey = () => {
    this.mergeKey = null;
  };

  @action
  setSelectedTemplate = value => {
    this.selectedTemplate = value;
  };

  @action
  initSelectedTemplate = () => {
    this.selectedTemplate = null;
  };

  @action
  setSysCdNm = value => {
    this.sysCdNm = value;
  };

  @action
  initSysCdNm = () => {
    this.sysCdNm = null;
  };

  @action
  setUseYnTrue = () => {
    this.useYn = true;
  };

  @action
  setUseYnFalse = () => {
    this.useYn = false;
  };

  @action
  initTemplate = () => {
    this.initTitle();
    this.initText();
    this.initMergeKey();
    this.initSelectedTemplate();
    this.initSysCdNm();
    this.setUseYnFalse();
  };

  @action
  setMsgList = value => {
    this.msgList = value;
  };

  @action
  initMsgList = () => {
    this.msgList = [];
  };

  @action
  setSelectedMsg = value => {
    this.selectedMsg = value;
  };

  @action
  initSelectedMsg = () => {
    this.selectedMsg = null;
  };
}

export default MessageStore;
