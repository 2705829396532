import React from 'react';
import ReactDOM, { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as MobxProvider } from 'mobx-react';
import { HelmetProvider } from 'react-helmet-async';
import RootStore from './store';
import ReactGA from 'react-ga4';

const root = new RootStore();
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTIVD_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <MobxProvider {...root}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </MobxProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
