import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CurrentList from '../../../../Component/Management/Setting/Staff/CurrentList';
import { Button } from 'semantic-ui-react';
@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class StaffList extends Component {
  render() {
    return (
      <div
        style={{
          width: '48%',
          float: 'left',
          padding: '20px',
          boxShadow: '4px 4px 10px rgba(5,5,5,0.1)',
          borderRadius: '10px',
          height: '90%',
          marginTop: '2.5%',
          marginBottom: '2.5%',
          marginRight: '2%',
          marginLeft: '1%',
        }}
      >
        <div style={{ padding: '10px', float: 'right' }}>
          <Button
            color="blue"
            onClick={() => {
              this.props.fnInitForm();
              this.props.fnInsertViewOpen();
            }}
          >
            신규 담당자 추가
          </Button>
        </div>
        <CurrentList fnViewOpen={this.props.fnViewOpen} />
      </div>
    );
  }
}

export default StaffList;
