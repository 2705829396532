import { Component, Fragment } from 'react';
import axios from 'axios';
import { Select, Form, Input } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  STR_CURRENT_PW,
  STR_MODIFY_PW,
  STR_NEW_PW,
  STR_SEN_3,
  STR_SEN_37,
  STR_SEN_38,
  STR_SEN_39,
} from '../../../../common/Terms';
import { API_UPDATE_CUSTOMER_PW } from '../../../../common/ApiAddress/AccountAPI';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../../../common/Color';
import CommonAlert from '../../../../Component/Common/CommonAlert';
@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class ChangePWContainer extends Component {
  state = {
    currentPw: null,
    changedPw: null,
  };

  //비밀번호 변경
  fnUpdateCustomerId = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.customerId = sessionStorage.getItem('customerId');
    params.pw = this.state.currentPw;
    params.changedPw = this.state.changedPw;
    console.log(params);

    if (this.state.changedPw.length < 6) {
      commonStore.commonAlertOpen(STR_SEN_37);
      return false;
    }
    axios({
      url: API_UPDATE_CUSTOMER_PW,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.commonAlertOpen(STR_SEN_38);
          that.setState({
            currentPw: null,
            changedPw: null,
          });
        } else {
          commonStore.commonAlertOpen(STR_SEN_39);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { accStore } = this.props;
    return (
      <div
        style={{
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 70px)',
        }}
      >
        <Form>
          <Form.Field>
            <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
              {STR_CURRENT_PW}
            </label>
            <input
              style={{ height: '60px', fontSize: '20px' }}
              placeholder={STR_SEN_3}
              onChange={e =>
                this.setState({
                  currentPw: e.target.value,
                })
              }
              value={this.state.currentPw || ''}
              type="password"
            />
          </Form.Field>
          <Form.Field>
            <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
              {STR_NEW_PW}
            </label>
            <input
              style={{ height: '60px', fontSize: '20px' }}
              placeholder={STR_SEN_3}
              onChange={e =>
                this.setState({
                  changedPw: e.target.value,
                })
              }
              value={this.state.changedPw || ''}
              type="password"
            />
          </Form.Field>
          <div
            onClick={() => {
              this.fnUpdateCustomerId();
            }}
            style={{
              width: '100%',
              height: '60px',
              marginTop: '33px',
              marginBottom: '33px',
              background: COLOR_GREEN_PRIMARY,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '20px',
              color: COLOR_WHITE,
            }}
          >
            {STR_MODIFY_PW}
          </div>
        </Form>
        <CommonAlert />
      </div>
    );
  }
}

export default ChangePWContainer;
