import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  compStore: stores.CompStore,
  wborderStore: stores.WorkbenchOrderStore,
}))
@observer
class StatementAmount extends Component {
  render() {
    const { compStore, wborderStore } = this.props;

    const numberUnits = [
      '',
      '일',
      '이',
      '삼',
      '사',
      '오',
      '육',
      '칠',
      '팔',
      '구',
    ];
    const tenUnits = ['', '십', '백', '천'];
    const thousandUnits = ['', '만', '억', '조', '경', '해'];

    // 배열 쪼개기
    function chunkAtEnd(value = '', n = 1) {
      const result = [];

      for (let end = value.length; end > 0; end -= n) {
        result.push(value.substring(Math.max(0, end - n), end));
      }

      return result;
    }
    // 모든 숫자 바꾸기
    function formatNumberAll(number) {
      return chunkAtEnd(String(number), 4)
        .reduce((acc, item, index) => {
          if (!Number(item)) {
            return acc;
          }

          let numberUnit = '';

          const zeroNum = item.padStart(4, '0');

          for (let i = 0; i < 4; i++) {
            const number = Number(zeroNum[i]);

            if (number) {
              const unit = tenUnits[3 - i];

              numberUnit += `${
                unit && number === 1 ? '' : numberUnits[number]
              }${unit}`;
            }
          }

          const thousandUnit = thousandUnits[index] ?? '';

          return `${numberUnit + thousandUnit} ${acc}`;
        }, '')
        .trim();
    }

    // -----------------------------------

    function onInput(value) {
      if (value > 0) {
        if (document.getElementById('sumKo') != null) {
          document.getElementById('sumKo').innerHTML = formatNumberAll(
            Number(value).toFixed(0),
          );
        }
      }
    }
    return (
      <Fragment>
        <tr
          style={{
            width: '690px',
            height: '18px',
          }}
        >
          <td
            colSpan={12}
            style={{
              borderLeft: '2px solid',
              borderRight: '2px solid',
            }}
          />
        </tr>
        <tr
          style={{
            width: '690px',
            height: '24px',
          }}
        >
          <td
            colSpan={2}
            style={{
              borderLeft: '2px solid',
              textAlign: 'right',
            }}
          >
            공급가액
          </td>
          <td
            colSpan={4}
            style={{
              textAlign: 'right',
            }}
          >
            {'₩' +
              wborderStore.totalFeeSum
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
              '원 정'}
          </td>
          <td
            style={{
              width: '30px',
            }}
          ></td>
          <td colSpan={2}>부가세</td>
          <td
            colSpan={4}
            style={{
              textAlign: 'right',
              paddingRight: '15px',
              borderRight: '2px solid',
            }}
          >
            {'₩' +
              (Number(wborderStore.totalFeeSum) * 0.1)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
              '원 정'}
          </td>
        </tr>

        <tr
          style={{
            width: '690px',
            height: '24px',
          }}
        >
          <td
            colSpan={2}
            style={{
              borderLeft: '2px solid',
              textAlign: 'right',
            }}
          >
            합계금액
          </td>
          <td
            colSpan={4}
            style={{
              textAlign: 'right',
            }}
          >
            <span id="sumKo">
              {onInput(Number(wborderStore.totalFeeSum) * 1.1)}원
            </span>
          </td>

          <td
            colSpan={6}
            style={{
              paddingRight: '15px',
              borderRight: '2px solid',
            }}
          >
            {'(₩' +
              (Number(wborderStore.totalFeeSum) * 1.1)
                .toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
              ')원 정'}
          </td>
        </tr>
        <tr
          style={{
            width: '690px',
            height: '18px',
          }}
        >
          <td
            colSpan={12}
            style={{
              borderLeft: '2px solid',
              borderRight: '2px solid',
            }}
          />
        </tr>
      </Fragment>
    );
  }
}

export default StatementAmount;
