import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  depoStore: stores.DepositStore,
}))
@observer
class UserInfo extends Component {
  render() {
    const { depoStore } = this.props;
    return (
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>이름(회원번호)</Table.Cell>
            <Table.Cell collapsing>
              {this.props.searchTyp == 'A'
                ? depoStore.searchDriverInfo.nm
                : depoStore.searchDriverInfo.title}
            </Table.Cell>
            <Table.Cell collapsing>아이디</Table.Cell>
            <Table.Cell collapsing>
              {this.props.searchTyp == 'A'
                ? depoStore.searchDriverInfo.title
                : depoStore.searchDriverInfo.accid}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>가상계좌번호</Table.Cell>
            <Table.Cell collapsing>
              {depoStore.searchDriverInfo.vbankAcc}
            </Table.Cell>
            <Table.Cell collapsing>잔액</Table.Cell>
            <Table.Cell collapsing>
              {depoStore.searchDriverInfo.balance == null
                ? 0 + '원'
                : depoStore.searchDriverInfo.balance + '원'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>연락처</Table.Cell>
            <Table.Cell>{depoStore.searchDriverInfo.hp}</Table.Cell>
            <Table.Cell collapsing>이메일</Table.Cell>
            <Table.Cell>{depoStore.searchDriverInfo.email}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default UserInfo;
