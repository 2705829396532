import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CurrentList from '../../../../Component/Management/Group/Emp/CurrentList';
@inject(stores => ({
  empStore: stores.EmpStore,
}))
@observer
class EmpList extends Component {
  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          float: 'left',
          padding: '10px',
        }}
      >
        <CurrentList fnViewOpen={this.props.fnViewOpen} />
      </div>
    );
  }
}

export default EmpList;
