import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

@inject(stores => ({
  queStore: stores.QuestionStore,
}))
@observer
class QuestionList extends Component {
  state = {
    column: null,
    direction: null,
  };

  handleSort = clickedColumn => {
    const { column, direction } = this.state;
    const { queStore } = this.props;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
      });
      let listItem = _.sortBy(queStore.questionList, [clickedColumn]);
      queStore.setQuestionList(listItem);

      return;
    }

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
    let listItem = [...queStore.questionList];
    queStore.setQuestionList(listItem.reverse());
  };

  render() {
    const { queStore } = this.props;
    return (
      <Table sortable style={{ border: '0px' }}>
        <Table.Header style={{ border: '0px' }}>
          <Table.Row>
            <Table.HeaderCell
              style={{
                width: '20%',
                borderBottom: '1px solid #CED4DA',
                background: '#fff',
                borderLeft: '0px',
              }}
              sorted={
                this.state.column === 'questionNo' ? this.state.direction : null
              }
              onClick={() => this.handleSort('questionNo')}
            >
              번호
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                width: '60%',
                borderBottom: '1px solid #CED4DA',
                background: '#fff',
                borderLeft: '0px',
              }}
              sorted={
                this.state.column === 'questionTitle'
                  ? this.state.direction
                  : null
              }
              onClick={() => this.handleSort('questionTitle')}
            >
              제목
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{
                width: '20%',
                borderBottom: '1px solid #CED4DA',
                background: '#fff',
                borderLeft: '0px',
                textAlign: 'center',
              }}
              sorted={
                this.state.column === 'replyYn' ? this.state.direction : null
              }
              onClick={() => this.handleSort('replyYn')}
            >
              답변여부
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {queStore.questionList == null || queStore.questionList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                등록된 문의사항이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {queStore.questionList.map((data, i) => (
              <React.Fragment>
                <Table.Row onClick={() => this.props.fnViewOpen(data)}>
                  <Table.Cell>{data.questionNo}</Table.Cell>
                  <Table.Cell>{data.questionTitle}</Table.Cell>
                  <Table.Cell style={{ textAlign: 'center' }}>
                    {data.replyYn}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default QuestionList;
