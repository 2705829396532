/*global AUTHNICE*/
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Form,
  Select,
  Radio,
  Popup,
  Icon,
  Input,
  TextArea,
} from 'semantic-ui-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import moment from 'moment';
import {
  COLOR_WHITE,
  COLOR_GREEN_PRIMARY,
  COLOR_BLACK,
  COLOR_PRIMARY,
  COLOR_GRAY_GR07,
  COLOR_GRAY_GR05,
} from '../../common/Color';
import 'react-datepicker/dist/react-datepicker.css';
import {
  STR_DEPARTURE_ADD_SEARCH,
  STR_DEPARTURE_DETAIL_ADD_SEARCH,
  STR_ARRIVE_ADD_SEARCH,
  STR_ARRIVE_DETAIL_ADD_SEARCH,
  STR_SEN_4,
  STR_SEN_5,
  STR_ORDER_INFO,
  STR_WAYPOINTS_ADD,
  STR_ADD_SEARCH,
  STR_DETAIL_ADD,
  STR_SEN_7,
  STR_SEN_8,
  STR_CAR_CNT,
  STR_SEN_9,
  STR_SELECT_LOADING_WAY,
  STR_SELECT_UNLOADING_WAY,
  STR_SEN_21,
  STR_SEN_22,
  STR_SEN_35,
  STR_SEN_36,
} from '../../common/Terms';
import OnClickInput from '../../Component/Common/OnClickInput';
import CommonInput from '../../Component/Common/CommonInput';
import { ReactComponent as PlusWaypoint } from '../../assets/icon/control_point.svg';
import { ReactComponent as MinusWaypoint } from '../../assets/icon/remove_circle_outline.svg';
import { ReactComponent as Calulate } from '../../assets/icon/calculate.svg';
import { ReactComponent as Payment } from '../../assets/icon/payment.svg';
import { ReactComponent as UpdateIcon } from '../../assets/icon/edit.svg';
import { ReactComponent as Cancle } from '../../assets/icon/cancle.svg';
@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
  payStore: stores.PaymentStore,
}))
@observer
class OrderForm extends Component {
  //RadioGroup Handler
  handleChangeLogis = (e, { value }) => this.props.fnOnchangeLogisCd(value);
  handleChangeLoadingCd = (e, { value }) =>
    this.props.fnOnchangeLoadingCd(value);
  handleChangeUnLoadingCd = (e, { value }) =>
    this.props.fnOnchangeUnLoadingCd(value);

  render() {
    const { vcStore, orderStore, payStore, commonStore } = this.props;
    registerLocale('ko', ko);
    //회원현황 , 회원승인
    return (
      <React.Fragment>
        <div
          //className="orderForm"
          style={{
            position: 'fixed',
            top: this.props.updateView == undefined ? '5%' : '11%',
            right: 60,
            backgroundColor: COLOR_WHITE,
            width: '460px',
            padding: '20px',
            borderRadius: '10px',
            maxHeight: this.props.updateView == undefined ? '700px' : '650px',
            //overflowY: 'scroll',
          }}
        >
          <div
            style={{
              width: '60%',
              float: 'left',
              marginBottom:
                this.props.updateView == undefined ? '10px' : '12px',
            }}
          >
            <h2 style={{ marginTop: '9px', color: COLOR_BLACK }}>
              {STR_ORDER_INFO}
            </h2>
          </div>
          <div
            style={{
              width: '40%',
              overflow: 'hidden',
              display: this.props.updateView == undefined ? 'block' : 'none',
            }}
          >
            <div
              style={{
                cursor: 'pointer',
                padding: '10px',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '12px',
              }}
              onClick={() => {
                this.props.fnFavoListModalOpen();
              }}
            >
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '700',
                  color: COLOR_GREEN_PRIMARY,
                }}
              >
                즐겨찾기
              </span>
            </div>
          </div>

          <div
            //className="orderForm"
            style={{
              width: '100%',
              overflowX: 'hidden',
              overflowY: 'scroll',
              maxHeight: this.props.updateView == undefined ? '550px' : '480px',
              // this.props.wayPointsNo == 1 ? '550px' : `calc(100vh - 197px)`,
              marginBottom: '20px',
              paddingRight: '20px',
            }}
          >
            <Form>
              {/* 상차지 주소 */}
              <Form.Field>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지
                  주소
                </label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_DEPARTURE_ADD_SEARCH}
                  onClick={this.props.fnOpenDepartureModal}
                  defaultValue={orderStore.departureAdd}
                />
                <CommonInput
                  placeholder={STR_DEPARTURE_DETAIL_ADD_SEARCH}
                  defaultValue={orderStore.departureDetailAdd}
                  onChange={this.props.fnSetDepartureDetailAdd}
                />
              </Form.Field>

              {sessionStorage.getItem('customerCd') == 'IACCU002' ? (
                <Form.Field>
                  <label
                    style={{
                      height: '23Px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    상차지 담당자
                  </label>
                  <Input
                    style={{ width: '31%', marginRight: '1.5%' }}
                    placeholder={'담당자 이름'}
                    value={orderStore.loadingStaffNm || ''}
                    onChange={e => orderStore.setLoadingStaffNm(e.target.value)}
                  />
                  <Input
                    style={{ width: '31%', marginRight: '1.5%' }}
                    placeholder={'직급'}
                    value={orderStore.loadingStaffRank || ''}
                    onChange={e =>
                      orderStore.setLoadingStaffRank(e.target.value)
                    }
                  />
                  <Input
                    style={{ width: '35%' }}
                    placeholder={'연락처'}
                    value={orderStore.loadingStaffHpNo || ''}
                    onChange={e =>
                      orderStore.setLoadingStaffHpNo(e.target.value)
                    }
                  />
                </Form.Field>
              ) : (
                <Fragment>
                  {/* <Form.Field>
                    
                    <Select
                      key={'lo'}
                      style={{
                        maxWidth: '190px',
                        minWidth: '100px',
                        marginRight: '9px',
                      }}
                      defaultValue={
                        orderStore.loadingStaffNm != null &&
                        orderStore.loadingStaffHpNo != null
                          ? {
                              customerNm: orderStore.loadingStaffNm,
                              hpNo: orderStore.loadingStaffHpNo,
                            }
                          : null
                      }
                      placeholder={'담당자를 선택해 주세요.'}
                      options={commonStore.logisStaffList}
                      onChange={(e, data) =>
                        this.props.fnSetLodingLogistStaff(e, data)
                      }
                    />
                  </Form.Field> */}
                  <Form.Field>
                    <label
                      style={{
                        height: '23Px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      상차지 담당자
                    </label>
                    <Input
                      style={{ width: '31%', marginRight: '1.5%' }}
                      placeholder={'담당자 이름'}
                      value={orderStore.loadingStaffNm || ''}
                      onChange={e =>
                        orderStore.setLoadingStaffNm(e.target.value)
                      }
                      //disabled={this.props.logisStaffFlag}
                    />
                    <Input
                      style={{ width: '31%', marginRight: '1.5%' }}
                      placeholder={'직급'}
                      value={orderStore.loadingStaffRank || ''}
                      onChange={e =>
                        orderStore.setLoadingStaffRank(e.target.value)
                      }
                    />
                    <Input
                      style={{ width: '35%' }}
                      placeholder={'연락처'}
                      value={orderStore.loadingStaffHpNo || ''}
                      onChange={e =>
                        orderStore.setLoadingStaffHpNo(e.target.value)
                      }
                      //disabled={this.props.logisStaffFlag}
                    />
                  </Form.Field>
                </Fragment>
              )}

              {/* 경유지 주소 */}

              {orderStore.wayPointsList.length > 0
                ? orderStore.wayPointsList.map((data, i) => (
                    <Form.Field key={i}>
                      <label>
                        {STR_WAYPOINTS_ADD + '주소'}
                        {i + 1}
                        <MinusWaypoint
                          style={{ position: 'absolute', right: 0 }}
                          onClick={() => this.props.fnDecreaseWayPoint(i)}
                        />
                      </label>
                      <OnClickInput
                        readOnly={true}
                        placeholder={`${
                          STR_WAYPOINTS_ADD + (i + 1) + STR_ADD_SEARCH
                        }`}
                        onClick={() => this.props.fnOpenWayPointModal(i + 1)}
                        defaultValue={
                          orderStore.wayPointsList[i].basicAdd == undefined
                            ? null
                            : orderStore.wayPointsList[i].basicAdd
                        }
                      />
                      <Form.Field>
                        <input
                          placeholder={`${
                            STR_WAYPOINTS_ADD + (i + 1) + STR_DETAIL_ADD
                          }`}
                          onChange={e =>
                            this.props.fnSetWayPointDetailAdd(
                              i + 1,
                              e.target.value,
                            )
                          }
                          defaultValue={
                            orderStore.wayPointsList[i].detailAdd == undefined
                              ? null
                              : orderStore.wayPointsList[i].detailAdd
                          }
                        />
                      </Form.Field>
                    </Form.Field>
                  ))
                : null}

              {this.props.wayPointsNo > 3 ? null : (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <PlusWaypoint
                    width={24}
                    height={24}
                    //style={{ position: 'absolute', right: 0 }}
                    onClick={() => this.props.fnIncreaseWayPoint()}
                  />
                </div>
              )}

              {/* 하차지 주소 */}
              <Form.Field>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지
                  주소
                </label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_ARRIVE_ADD_SEARCH}
                  onClick={this.props.fnOpenArriveModal}
                  defaultValue={orderStore.arriveAdd}
                />
                <CommonInput
                  placeholder={STR_ARRIVE_DETAIL_ADD_SEARCH}
                  defaultValue={orderStore.arriveDetailAdd}
                  onChange={this.props.fnSetArriveDetailAdd}
                />
              </Form.Field>

              <Form.Field>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  하차지 담당자
                </label>
                <Input
                  style={{ width: '31%', marginRight: '1.5%' }}
                  placeholder={'담당자 이름'}
                  value={orderStore.unloadingStaffNm || ''}
                  onChange={e => orderStore.setUnLoadingStaffNm(e.target.value)}
                />
                <Input
                  style={{ width: '31%', marginRight: '1.5%' }}
                  placeholder={'직급'}
                  value={orderStore.unloadingStaffRank || ''}
                  onChange={e =>
                    orderStore.setUnLoadingStaffRank(e.target.value)
                  }
                />
                <Input
                  style={{ width: '35%' }}
                  placeholder={'연락처'}
                  value={orderStore.unloadingStaffHpNo || ''}
                  onChange={e =>
                    orderStore.setUnLoadingStaffHpNo(e.target.value)
                  }
                />
              </Form.Field>

              {/* 차량 선택 부분 */}
              <Form.Field style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  차량 선택
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{ fontSize: '0.65em', width: '200px' }}
                        />
                      }
                      position="right center"
                    >
                      <p>
                        운송 할 화물에 맞게 차량 톤수, 구분, 종류를 선택해
                        주세요.
                      </p>
                    </Popup>
                  </span>
                </label>
                <Select
                  style={{
                    maxWidth: '190px',
                    minWidth: '100px',
                    float: 'left',
                    marginRight: '9px',
                  }}
                  placeholder={STR_SEN_4}
                  options={vcStore.vcWeightCdList}
                  onChange={(e, data) => this.props.fnSetVcWeightCd(e, data)}
                  value={vcStore.vcWeightCd}
                />
                <Select
                  style={{
                    maxWidth: '190px',
                    minWidth: '100px',
                  }}
                  placeholder={STR_SEN_5}
                  options={vcStore.vcTypCdList}
                  onChange={(e, { value }) => this.props.fnSetVcTypCd(value)}
                  value={vcStore.vcTypCd}
                />
              </Form.Field>

              <Form.Field style={{ height: '20px', marginBottom: '36px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  화물 운송 구분
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{ fontSize: '0.65em', width: '200px' }}
                        />
                      }
                      position="right center"
                    >
                      <p>일반 : 일반화물</p>
                      <p>긴급 : 긴급화물(가격이 상승 할 수 있습니다.)</p>
                      <p>혼적 : 다른 화물과 함께 운송합니다.</p>
                      <p>왕복 : 왕복 화물 운송 입니다.</p>
                    </Popup>
                  </span>
                </label>

                {orderStore.logisCdList.length > 0
                  ? orderStore.logisCdList.map((data, i) => (
                      <Radio
                        key={data.value}
                        style={{ width: '20%', float: 'left' }}
                        label={data.text}
                        name="logisCdGroup"
                        value={data.value}
                        checked={orderStore.logisCd == data.value}
                        onChange={this.handleChangeLogis}
                        defaultValue="IFRDI001"
                      />
                    ))
                  : null}
              </Form.Field>

              <Form.Field>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>화물정보
                </label>
                <TextArea
                  value={orderStore.freightInfo || ''}
                  style={{ resize: 'none' }}
                  placeholder={STR_SEN_8}
                  rows={3}
                  onChange={e => {
                    orderStore.setFreightInfo(e.target.value);
                  }}
                />
              </Form.Field>

              <Form.Field>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>화물중량
                </label>
                <Input
                  min={1}
                  label={{ basic: true, content: 'Kg' }}
                  labelPosition="right"
                  type="number"
                  placeholder={STR_SEN_9}
                  value={orderStore.freightWeight || ''}
                  onChange={e => orderStore.setFreightWeight(e.target.value)}
                />
              </Form.Field>

              {/* <Form.Field style={{ height: '40px', marginBottom: '36px' }}>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차 방법
                </label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_SELECT_LOADING_WAY}
                  onClick={this.props.fnSelectLoadingModalOpen}
                  defaultValue={orderStore.loadingNm}
                />
              </Form.Field> */}
              <Form.Field style={{ height: '20px', marginBottom: '36px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  상차방법
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{
                            fontSize: '0.65em',
                            cursor: 'pointer',
                          }}
                        />
                      }
                      position="bottom center"
                      wide="very"
                    >
                      <p>수작업 : 화주가 수작업으로 물건을 옮깁니다.</p>
                      <p>수해줌 : 기사님이 수작업으로 물건을 옮깁니다.</p>
                      <p style={{ color: COLOR_PRIMARY }}>
                        수해줌 시 추가 비용이 발생 할 수 있습니다.
                      </p>
                    </Popup>
                    {/* <Icon
                      name="question"
                      size="small"
                      circular
                      style={{
                        fontSize: '0.65em',
                        width: '200px',
                        cursor: 'pointer',
                      }}
                    /> */}
                  </span>
                </label>

                {orderStore.loadingCdList.length > 0
                  ? orderStore.loadingCdList.map((data, i) => (
                      <Radio
                        key={data.value}
                        style={{ width: '20%', float: 'left' }}
                        label={data.text}
                        name="loadingCdGroup"
                        value={data.value}
                        checked={orderStore.loadingCd == data.value}
                        onChange={this.handleChangeLoadingCd}
                        defaultValue="IFRLO001"
                      />
                    ))
                  : null}
              </Form.Field>

              {/* <Form.Field style={{ height: '40px', marginBottom: '36px' }}>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차 방법
                </label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_SELECT_UNLOADING_WAY}
                  onClick={this.props.fnSelectUnLoadingModalOpen}
                  defaultValue={orderStore.unloadingNm}
                />
              </Form.Field> */}

              <Form.Field style={{ height: '20px', marginBottom: '36px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  하차방법
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{
                            fontSize: '0.65em',
                            cursor: 'pointer',
                          }}
                        />
                      }
                      position="bottom center"
                      wide="very"
                    >
                      <p>수작업 : 화주가 수작업으로 물건을 옮깁니다.</p>
                      <p>수해줌 : 기사님이 수작업으로 물건을 옮깁니다.</p>
                      <p style={{ color: COLOR_PRIMARY }}>
                        수해줌 시 추가 비용이 발생 할 수 있습니다.
                      </p>
                    </Popup>
                  </span>
                </label>

                {orderStore.loadingCdList.length > 0
                  ? orderStore.loadingCdList.map((data, i) => (
                      <Radio
                        key={data.value}
                        style={{ width: '20%', float: 'left' }}
                        label={data.text}
                        name="unloadingCdGroup"
                        value={data.value}
                        checked={orderStore.unloadingCd == data.value}
                        onChange={this.handleChangeUnLoadingCd}
                        defaultValue="IFRLO001"
                      />
                    ))
                  : null}
              </Form.Field>

              <Form.Field>
                <label>상차 일시</label>
                <DatePicker
                  locale="ko"
                  selected={orderStore.loadingDate}
                  onChange={date => {
                    if (date < moment()) {
                      commonStore.commonAlertOpen(STR_SEN_35);
                      return false;
                    } else {
                      orderStore.setLoadingDate(date);
                    }
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="시간"
                  dateFormat="yyyy-MM-dd HH:mm aa"
                />
              </Form.Field>
              <Form.Field>
                <label>하차 일시</label>
                <DatePicker
                  locale="ko"
                  selected={orderStore.unloadingDate}
                  onChange={date => {
                    if (date < orderStore.loadingDate) {
                      commonStore.commonAlertOpen(STR_SEN_36);
                      return false;
                    } else {
                      orderStore.setUnLoadingDate(date);
                    }
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="시간"
                  dateFormat="yyyy-MM-dd HH:mm aa"
                />
              </Form.Field>

              {/* <Form.Field>
                <Input
                  min={1}
                  style={{ maxWidth: '152px', minWidth: '100px' }}
                  label={{ basic: true, content: '대' }}
                  labelPosition="right"
                  type="number"
                  placeholder={STR_CAR_CNT}
                  defaultValue={orderStore.vcCnt || ''}
                  onChange={e => orderStore.setVcCnt(e.target.value)}
                />
              </Form.Field> */}

              {/* 결제정보 선택 부분 */}
              <Form.Field style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  결제 선택
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{ fontSize: '0.65em', width: '200px' }}
                        />
                      }
                      position="right center"
                    >
                      <p>결제방법과 수단을 선택해 주세요.</p>
                    </Popup>
                  </span>
                </label>

                <Select
                  style={{
                    maxWidth: '190px',
                    minWidth: '100px',
                    float: 'left',
                    marginRight: '9px',
                    marginBottom: '15px',
                  }}
                  placeholder={STR_SEN_21}
                  options={payStore.paymentToolList}
                  onChange={(e, data) => this.props.fnSetPaymentTo(e, data)}
                  value={payStore.paymentTo}
                  defaultValue="IPYTO003"
                  disabled={true}
                  // disabled={
                  //   orderStore.logisFee == null &&
                  //   this.props.updateView == undefined
                  //     ? false
                  //     : orderStore.logisFee != null &&
                  //       this.props.updateView == true
                  //     ? true
                  //     : true
                  // }
                />
                {payStore.paymentTo == 'IPYTO003' ? null : (
                  <Select
                    style={{
                      maxWidth: '190px',
                      minWidth: '100px',
                    }}
                    placeholder={STR_SEN_22}
                    options={payStore.paymentTypeList}
                    onChange={(e, { value }) =>
                      this.props.fnSetPaymentTy(value)
                    }
                    value={payStore.paymentTy}
                    defaultValue="IPYTY006"
                    // disabled={
                    //   orderStore.logisFee == null &&
                    //   this.props.updateView == undefined
                    //     ? false
                    //     : orderStore.logisFee != null &&
                    //       this.props.updateView == true
                    //     ? true
                    //     : true
                    // }
                  />
                )}
              </Form.Field>

              {/* 화물 운임 */}
              <Form.Field>
                <label>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>화물운임
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{ fontSize: '0.65em', width: '200px' }}
                        />
                      }
                      position="right center"
                    >
                      <p>
                        화물운임은 화물운송 구분 및 상하차 방법과 총 운행
                        거리(km)에 따라 산정됩니다.
                      </p>
                    </Popup>
                  </span>
                </label>
                <Input
                  disabled={true}
                  min={1}
                  label={{ basic: true, content: '원' }}
                  labelPosition="right"
                  type="number"
                  placeholder={STR_SEN_7}
                  value={orderStore.logisFee || ''}
                  onChange={e => orderStore.setLogisFee(e.target.value)}
                />
              </Form.Field>
            </Form>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: this.props.updateView == undefined ? '200px' : '160px',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: COLOR_GREEN_PRIMARY,
                borderRadius: '5px',
                padding:
                  this.props.updateView == undefined ? '10px 25px' : '5px 5px',
                color: COLOR_GREEN_PRIMARY,
                border: '1px solid',
                fontSize: '14px',
                fontWeight: '700',
                marginRight: '12px',
              }}
              onClick={() => {
                this.props.fnCalLogisFee();
              }}
            >
              <Calulate style={{ marginRight: '6px' }} />
              운임계산하기
            </div>
            {this.props.updateView == undefined ? (
              <div
                disabled={orderStore.logisFee == null ? true : false}
                style={{
                  width: '200px',
                  backgroundColor:
                    orderStore.logisFee == null
                      ? 'rgba(120, 201, 57, 0.4)'
                      : COLOR_GREEN_PRIMARY,
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '700',
                  borderRadius: '5px',
                  padding: '10px 25px',
                }}
                onClick={() => {
                  orderStore.logisFee == null
                    ? commonStore.commonAlertOpen('운임계산을 먼저 해주세요.')
                    : this.props.fnInsertFreight();
                }}
              >
                <Payment style={{ marginRight: '6px' }} />
                결제 하기
              </div>
            ) : (
              <Fragment>
                <div
                  // disabled={orderStore.logisFee == null ? true : false}
                  style={{
                    width: '160px',
                    backgroundColor:
                      this.props.logisfeeFlag == false
                        ? COLOR_GRAY_GR05
                        : COLOR_GREEN_PRIMARY,
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: '700',
                    borderRadius: '5px',
                    padding: '5px 5px',
                    marginRight: '12px',
                  }}
                  onClick={() => {
                    this.props.logisfeeFlag == false
                      ? commonStore.commonAlertOpen('운임계산을 먼저 해주세요.')
                      : this.props.fnUpdateFreightModalOpen();
                  }}
                >
                  <UpdateIcon style={{ marginRight: '6px' }} />
                  수정 하기
                </div>
                <div
                  disabled={orderStore.logisFee == null ? true : false}
                  style={{
                    width: '160px',
                    backgroundColor:
                      orderStore.logisFee == null
                        ? 'rgba(120, 201, 57, 0.4)'
                        : COLOR_PRIMARY,
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: '700',
                    borderRadius: '5px',
                    padding: '5px 5px',
                  }}
                  onClick={() => {
                    this.props.fnUpdateFreightCancleModalOpen();
                  }}
                >
                  <Cancle
                    style={{ marginRight: '6px' }}
                    width={12}
                    height={13}
                    fill={COLOR_WHITE}
                  />
                  수정취소
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderForm;
