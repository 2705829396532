import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router';

import { STR_CURRENT_USER, STR_USER_APV } from '../../../../common/Terms';
import CurrentCustomerList from './CurrentCustomerList';
import CustomerManagementDetailContainer from './CustomerManagementDetailContainer';
import CustomerApv from './CustomerApv';
@inject(stores => ({
  commonStore: stores.CommonStore,
  accStore: stores.AccStore,
}))
@observer
class CustomerManagementContainer extends Component {
  state = {
    detailView: false,
    searchTyp: null,
    searchKeyword: null,
  };

  componentDidMount() {
    const { accStore } = this.props;
    accStore.initSelectedCustomer();
    let path = this.props.location.pathname;
    let pathSplit = path.split('/');
    this.setState({ activeItem: pathSplit[pathSplit.length - 1] });
  }

  handleItemClick = (e, name, path) => {
    const { commonStore, accStore } = this.props;
    this.setState({ activeItem: name });
    accStore.initSelectedCustomer();
    this.props.history.push(path);
    commonStore.setPath(path);
  };

  fnViewOpen = value => {
    const { accStore } = this.props;
    accStore.setSelectedCustomer(value);
    this.setState({
      detailView: true,
    });
  };

  fnOnchangeSearchTyp = value => {
    this.setState({
      searchTyp: value,
    });
  };

  render() {
    const { activeItem } = this.state;
    let currentPage;
    switch (this.props.location.pathname) {
      case '/user/customer/curlist':
        currentPage = (
          <CurrentCustomerList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            pathname={this.props.location.pathname}
            fnViewOpen={this.fnViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
          />
        );
        break;
      case '/user/customer/apvuser':
        currentPage = (
          <CustomerApv
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            pathname={this.props.location.pathname}
            fnViewOpen={this.fnViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
          />
        );
        break;
      default:
        currentPage = (
          <CurrentCustomerList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            pathname={this.props.location.pathname}
            fnViewOpen={this.fnViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
          />
        );
        break;
    }
    //회원현황 , 회원승인
    return (
      <React.Fragment>
        <Menu style={{ margin: '0px' }}>
          <Menu.Item
            name="curlist"
            active={activeItem === 'curlist'}
            style={{
              color: activeItem === 'curlist' ? '#fff' : null,
              backgroundColor: activeItem === 'curlist' ? '#db3d44' : null,
            }}
            onClick={e => {
              this.handleItemClick(e, 'curlist', '/user/customer/curlist');
            }}
          >
            {STR_CURRENT_USER}
          </Menu.Item>

          <Menu.Item
            name="apvuser"
            active={activeItem === 'apvuser'}
            style={{
              color: activeItem === 'apvuser' ? '#fff' : null,
              backgroundColor: activeItem === 'apvuser' ? '#db3d44' : null,
            }}
            onClick={e => {
              this.handleItemClick(e, 'apvuser', '/user/customer/apvuser');
            }}
          >
            {STR_USER_APV}
          </Menu.Item>
        </Menu>
        <div
          style={{
            width:
              this.props.location.pathname == '/user/customer/curlist'
                ? '40%'
                : '100%',
            height: 'calc(100vh - 40px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {currentPage}
        </div>
        {this.props.location.pathname == '/user/customer/curlist' &&
        this.state.detailView == true ? (
          <div
            style={{
              width: '60%',
              height: 'calc(100vh - 40px)',
              float: 'left',
              overflow: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <CustomerManagementDetailContainer />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(CustomerManagementContainer);
