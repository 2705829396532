import React, { Component } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import Agreements from '../../Component/Common/Agreements';
import Privacy from '../../Component/Common/Privacy';
import {
  COLOR_GREEN_SECOND,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import { ReactComponent as LogoBig } from '../../assets/icon/logo_long.svg';
@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class RegistReturn extends React.Component {
  componentDidMount() {
    const { accStore } = this.props;
    accStore.initForm();
  }

  fnMoveToMain = () => {
    this.props.history.push('/login');
  };

  render() {
    const { accStore } = this.props;
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          margin: '0 auto ',
          padding: '20px',
          paddingTop: '0px',
          background: '#F5F5F5',
        }}
      >
        <div
          style={{
            margin: '0 auto ',
            width: '780px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '80%',
          }}
        >
          <h1 style={{ color: COLOR_GREEN_SECOND }}>가입신청을 환영합니다.</h1>
          <p style={{ fontSize: '16pt' }}>
            입력하신 정보와 제출하신 서류를 바탕으로 담당자 확인 절차가
            진행됩니다.
          </p>
          <p style={{ fontSize: '16pt' }}> </p>
          <p style={{ fontSize: '16pt' }}>
            빠른 심사 후 연락 드리도록 하겠습니다.
          </p>
          <div
            style={{
              width: '75%',
              marginBottom: '100px',
            }}
          >
            <p style={{ fontSize: '16pt' }}>담당자 연락처 : 010-9611-2934</p>
            <p style={{ fontSize: '16pt' }}>
              담당자 이메일 : help@teamtrucker.co.kr
            </p>
          </div>
          <div style={{ width: '100%', height: '100px' }}>
            <div
              style={{
                width: '100%',
                backgroundColor: COLOR_GREEN_SECOND,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '6px',
                cursor: 'pointer',
                height: '46px',
              }}
              onClick={() => this.fnMoveToMain()}
            >
              <p
                style={{
                  color: COLOR_WHITE,
                  fontSize: '16px',
                  fontWeight: '700',
                }}
              >
                메인으로 이동하기
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RegistReturn);
