import React, { Component } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import {
  API_SELECT_ADS_LIST,
  API_SELECT_ADS_HISTORY_LIST,
  API_SELECT_ADS_ALL_LIST,
} from '../common/ApiAddress/AdsAPI';
import { Form, Dropdown, Button, Icon, Input } from 'semantic-ui-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { ko } from 'date-fns/esm/locale';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR00,
  COLOR_GRAY_GR02,
  COLOR_GRAY_GR09,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../common/Color';
import Media from 'react-media';
@inject(stores => ({
  adsStore: stores.AdsStore,
}))
@observer
class AdsTotalContainerTemp extends React.Component {
  state = {
    date: null,
    heatMapFlag: false,
    selectedIndex: null,
    issuedDateList: [],
    selectItemList: [],
  };

  //광고 히스토리 불러오기
  fnSelectAdsHistoryList = () => {
    const { adsStore } = this.props;

    let params = {};
    let that = this;
    params.advNo = 1;
    params.driverNo = adsStore.selectedAds.driverNo;
    axios({
      url: API_SELECT_ADS_HISTORY_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          adsStore.setAdsHistoryList(response.data.data);
          let dateList = [];
          for (let i = 0; i < response.data.data.length; i++) {
            dateList.push(new Date(response.data.data[i].registDt));
          }
          that.setState({
            issuedDateList: dateList,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //광고 차량 불러오기
  fnSelectAdsList = () => {
    const { adsStore } = this.props;
    let params = {};
    let that = this;
    params.advNo = 1;
    axios({
      url: API_SELECT_ADS_ALL_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (window.innerWidth < 769) {
            if (adsStore.adsList.length == 0) {
              let selectItemList = [];
              adsStore.setAdsList(response.data.data);
              if (adsStore.selectedAds == null) {
                that.fnSelectAdsVc(response.data.data[0], 0);
              }
              for (let i = 0; i < response.data.data.length; i++) {
                let selectItem = {};
                selectItem.key = response.data.data[i].driverNm;
                selectItem.text =
                  '차량번호 : ' + response.data.data[i].driverId;
                selectItem.value = response.data.data[i];
                selectItemList.push(selectItem);
              }
              console.log(selectItemList);
              that.setState({
                selectItemList: selectItemList,
              });
            }
          } else {
            adsStore.setAdsList(response.data.data);
            if (adsStore.selectedAds == null) {
              that.fnSelectAdsVc(response.data.data[0], 0);
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetDate = date => {
    this.setState({
      date: date,
      heatMapFlag: true,
    });
  };

  fnSelectAdsVc = (value, index) => {
    const { adsStore } = this.props;
    adsStore.setSelectedAds(value);
    this.setState({
      selectedIndex: index,
      heatMapFlag: false,
      date: null,
      issuedDateList: [],
    });
    this.fnSelectAdsHistoryList();
  };

  fnSelectAdsVcForMobile = data => {
    console.log(data.value);
    const { adsStore } = this.props;
    adsStore.setSelectedAds(data.value);
    this.setState({
      heatMapFlag: false,
      date: null,
      issuedDateList: [],
    });
    this.fnSelectAdsHistoryList();
  };

  render() {
    const { adsStore } = this.props;
    registerLocale('ko', ko);
    this.fnSelectAdsList();

    const iframePart = () => {
      return {
        __html:
          '<iframe src="https://teamtrucker.co.kr/naver/test?date=' +
          moment(this.state.date).format('YYYY-MM-DD') +
          '&driverId=' +
          adsStore.selectedAds.driverId +
          '" style="width:100%; height:calc(100vh - 67px); border:0px"></iframe>',
      };
    };
    return (
      <React.Fragment>
        <Media
          queries={{
            small: '(max-width: 768px)',
            medium: '(min-width: 769px) and (max-width: 1024px)',
            large: '(min-width: 1025px)',
          }}
        >
          {matches => (
            <React.Fragment>
              {matches.small && (
                <div>
                  <div style={{ width: '100%', height: '100vh' }}>
                    <div
                      style={{
                        width: '100%',
                        height: '60px',
                        background: COLOR_GREEN_PRIMARY,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        style={{
                          width: '100%',
                          height: '28.16px',
                          position: 'relative',
                          top: '20px',
                        }}
                        src={
                          process.env.REACT_APP_BASIC_ADDRESS +
                          'image/logo_colored_theme.svg'
                        }
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        height: '54px',
                        borderBottom: 'solid 1px #DFDFDF',
                      }}
                    >
                      {adsStore.selectedAds == null ? (
                        this.state.selectItemList.length > 0 ? (
                          <Dropdown
                            id="selectTruck"
                            style={{ width: '100%' }}
                            placeholder="차량을 선택 후 날짜를 선택해주세요"
                            openOnFocus
                            selection
                            options={this.state.selectItemList}
                            defaultValue={adsStore.selectedAds}
                          />
                        ) : null
                      ) : this.state.selectItemList.length > 0 ? (
                        <div
                          style={{
                            width: '100%',
                            height: '54px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {adsStore.selectedAds.adYn == 'Y' ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  marginLeft: '18.5px',
                                }}
                                src={
                                  process.env.REACT_APP_BASIC_ADDRESS +
                                  'image/start_big.svg'
                                }
                              />
                              <img
                                style={{
                                  width: '34px',
                                  height: '25px',
                                  marginLeft: '9px',
                                }}
                                src={
                                  process.env.REACT_APP_BASIC_ADDRESS +
                                  'image/local_shipping_active.svg'
                                }
                              />
                            </div>
                          ) : (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  marginLeft: '18.5px',
                                }}
                                src={
                                  process.env.REACT_APP_BASIC_ADDRESS +
                                  'image/pause.svg'
                                }
                              />
                              <img
                                style={{
                                  width: '34px',
                                  height: '25px',
                                  marginLeft: '9px',
                                }}
                                src={
                                  process.env.REACT_APP_BASIC_ADDRESS +
                                  'image/local_shipping_passive.svg'
                                }
                              />
                            </div>
                          )}

                          <Dropdown
                            id="selectTruck"
                            style={{ width: '76%' }}
                            placeholder="차량을 선택 후 날짜를 선택해주세요!"
                            openOnFocus
                            selection
                            options={this.state.selectItemList}
                            defaultValue={adsStore.selectedAds}
                            onChange={(e, data) =>
                              this.fnSelectAdsVcForMobile(data)
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                    <div style={{ width: '100%' }}>
                      <div style={{ marginTop: '15px', paddingLeft: '22px' }}>
                        <div>
                          <Form>
                            <Form.Field style={{ width: '80%' }}>
                              <label
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '700',
                                  color: COLOR_GRAY_GR09,
                                  marginBottom: 15,
                                  float: 'left',
                                  paddingRight: '19px',
                                }}
                              >
                                날짜선택
                              </label>
                              <div style={{ overflow: 'hidden', width: '50%' }}>
                                <DatePicker
                                  highlightDates={this.state.issuedDateList}
                                  readOnly={
                                    adsStore.selectedAds == null ? true : false
                                  }
                                  onInputClick={() => {
                                    if (adsStore.selectedAds == null) {
                                      alert('차량을 먼저 선택해주세요.');
                                    }
                                  }}
                                  locale="ko"
                                  selected={this.state.date}
                                  onChange={date => {
                                    if (adsStore.selectedAds == null) {
                                      alert('차량을 먼저 선택해주세요.');
                                    } else {
                                      this.fnSetDate(date);
                                    }
                                  }}
                                  dateFormat="yyyy-MM-dd"
                                  customInput={
                                    <Input
                                      icon={<Icon name="calendar outline" />}
                                    />
                                  }
                                />
                              </div>
                            </Form.Field>
                          </Form>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          overflow: 'hidden',
                          height: '69vh',
                          marginTop: '46px',
                        }}
                      >
                        {this.state.heatMapFlag == false ? null : (
                          <div
                            style={{
                              width: '100%',
                              height: '69vh',
                            }}
                            dangerouslySetInnerHTML={iframePart()}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {matches.medium && (
                <div>
                  <div style={{ width: '100%', height: '100vh' }}>
                    <div
                      style={{
                        width: '100%',
                        height: '80px',
                        background: COLOR_GREEN_PRIMARY,
                      }}
                    >
                      <img
                        style={{
                          width: '18%',
                          height: '28.16px',
                          position: 'fixed',
                          top: 25,
                          left: 26,
                        }}
                        src={
                          process.env.REACT_APP_BASIC_ADDRESS +
                          'image/logo_colored_theme.svg'
                        }
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '100px',
                        background: COLOR_GRAY_GR00,
                        overflowX: 'scroll',
                      }}
                    >
                      <ul
                        style={{
                          listStyle: 'none',
                          width: 360 * adsStore.adsList.length,
                          paddingLeft: '0px',
                        }}
                      >
                        {adsStore.adsList == null ||
                        adsStore.adsList.length == 0
                          ? null
                          : adsStore.adsList.map((data, i) => (
                              <li style={{ height: '65px', float: 'left' }}>
                                <div
                                  key={i}
                                  style={{
                                    width: '350px',
                                    height: '65px',
                                    backgroundColor:
                                      i == this.state.selectedIndex
                                        ? COLOR_WHITE
                                        : COLOR_GRAY_GR02,
                                    borderTopRightRadius: '20px',
                                    marginRight: '8px',
                                  }}
                                  onClick={() => {
                                    this.fnSelectAdsVc(data, i);
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '35%',
                                      height: '100%',
                                      float: 'left',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {data.adYn == 'Y' ? (
                                      <div>
                                        <img
                                          style={{
                                            width: '44px',
                                            height: '44px',
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BASIC_ADDRESS +
                                            'image/start_big.svg'
                                          }
                                        />
                                        <img
                                          style={{
                                            width: '44px',
                                            height: '44px',
                                            marginLeft: '9px',
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BASIC_ADDRESS +
                                            'image/local_shipping_active.svg'
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: '32px',
                                            height: '32px',
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BASIC_ADDRESS +
                                            'image/pause.svg'
                                          }
                                        />
                                        <img
                                          style={{
                                            width: '44px',
                                            height: '44px',
                                            marginLeft: '9px',
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BASIC_ADDRESS +
                                            'image/local_shipping_passive.svg'
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      float: 'left',
                                      height: '65px',
                                      paddingLeft: '10px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: '700',
                                        }}
                                      >
                                        차량번호 :{' '}
                                        <span
                                          style={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                          }}
                                        >
                                          {data.driverId}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                      </ul>
                    </div>
                    <div
                      style={{ width: '100%', height: 'calc(100vh - 180px)' }}
                    >
                      <div
                        style={{
                          width: '17%',
                          float: 'left',
                          height: '100%',
                          paddingLeft: '20px',
                          paddingTop: '18px',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '24px',
                            fontWeight: '700',
                            color: COLOR_GRAY_GR09,
                            marginBottom: 15,
                          }}
                        >
                          날짜선택
                        </p>
                        <Form>
                          <Form.Field style={{ width: '130px' }}>
                            <DatePicker
                              highlightDates={this.state.issuedDateList}
                              readOnly={
                                adsStore.selectedAds == null ? true : false
                              }
                              onInputClick={() => {
                                if (adsStore.selectedAds == null) {
                                  alert('차량을 먼저 선택해주세요.');
                                }
                              }}
                              locale="ko"
                              selected={this.state.date}
                              onChange={date => {
                                if (adsStore.selectedAds == null) {
                                  alert('차량을 먼저 선택해주세요.');
                                } else {
                                  this.fnSetDate(date);
                                }
                              }}
                              dateFormat="yyyy-MM-dd"
                              customInput={
                                <Input
                                  icon={<Icon name="calendar outline" />}
                                />
                              }
                            />
                          </Form.Field>
                        </Form>
                      </div>
                      <div
                        style={{
                          width: '83%',
                          overflow: 'hidden',
                          height: 'calc(100vh - 58%)',
                          paddingRight: '20px',
                        }}
                      >
                        {this.state.heatMapFlag == false ? null : (
                          <div
                            style={{
                              width: '100%',
                              height: 'calc(100vh - 58%)',
                            }}
                            dangerouslySetInnerHTML={iframePart()}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {matches.large && (
                <div>
                  <div style={{ width: '100%', height: '100vh' }}>
                    <div
                      style={{
                        width: '100%',
                        height: '80px',
                        background: COLOR_GREEN_PRIMARY,
                      }}
                    >
                      <img
                        style={{
                          width: '197px',
                          height: '28.16px',
                          position: 'fixed',
                          top: 25,
                          left: 26,
                        }}
                        src={
                          process.env.REACT_APP_BASIC_ADDRESS +
                          'image/logo_colored_theme.svg'
                        }
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        height: '100px',
                        background: COLOR_GRAY_GR00,
                        overflowX: 'scroll',
                      }}
                    >
                      <ul
                        style={{
                          listStyle: 'none',
                          width: 360 * adsStore.adsList.length,
                          paddingLeft: '0px',
                        }}
                      >
                        {adsStore.adsList == null ||
                        adsStore.adsList.length == 0
                          ? null
                          : adsStore.adsList.map((data, i) => (
                              <li style={{ height: '65px', float: 'left' }}>
                                <div
                                  key={i}
                                  style={{
                                    width: '350px',
                                    height: '65px',
                                    backgroundColor:
                                      i == this.state.selectedIndex
                                        ? COLOR_WHITE
                                        : COLOR_GRAY_GR02,
                                    borderTopRightRadius: '20px',
                                    marginRight: '8px',
                                  }}
                                  onClick={() => {
                                    this.fnSelectAdsVc(data, i);
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '35%',
                                      height: '100%',
                                      float: 'left',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {data.adYn == 'Y' ? (
                                      <div>
                                        <img
                                          style={{
                                            width: '44px',
                                            height: '44px',
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BASIC_ADDRESS +
                                            'image/start_big.svg'
                                          }
                                        />
                                        <img
                                          style={{
                                            width: '44px',
                                            height: '44px',
                                            marginLeft: '9px',
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BASIC_ADDRESS +
                                            'image/local_shipping_active.svg'
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: '32px',
                                            height: '32px',
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BASIC_ADDRESS +
                                            'image/pause.svg'
                                          }
                                        />
                                        <img
                                          style={{
                                            width: '44px',
                                            height: '44px',
                                            marginLeft: '9px',
                                          }}
                                          src={
                                            process.env
                                              .REACT_APP_BASIC_ADDRESS +
                                            'image/local_shipping_passive.svg'
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      float: 'left',
                                      height: '65px',
                                      paddingLeft: '10px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: '700',
                                        }}
                                      >
                                        차량번호 :{' '}
                                        <span
                                          style={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                          }}
                                        >
                                          {data.driverId}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                      </ul>
                    </div>
                    <div
                      style={{ width: '100%', height: 'calc(100vh - 180px)' }}
                    >
                      <div
                        style={{
                          width: '17%',
                          float: 'left',
                          height: '100%',
                          paddingLeft: '39px',
                          paddingTop: '29px',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '32px',
                            fontWeight: '700',
                            color: COLOR_GRAY_GR09,
                            marginBottom: 15,
                          }}
                        >
                          날짜선택
                        </p>
                        <Form>
                          <Form.Field style={{ width: '150px' }}>
                            <DatePicker
                              highlightDates={this.state.issuedDateList}
                              readOnly={
                                adsStore.selectedAds == null ? true : false
                              }
                              onInputClick={() => {
                                if (adsStore.selectedAds == null) {
                                  alert('차량을 먼저 선택해주세요.');
                                }
                              }}
                              locale="ko"
                              selected={this.state.date}
                              onChange={date => {
                                if (adsStore.selectedAds == null) {
                                  alert('차량을 먼저 선택해주세요.');
                                } else {
                                  this.fnSetDate(date);
                                }
                              }}
                              dateFormat="yyyy-MM-dd"
                              customInput={
                                <Input
                                  icon={<Icon name="calendar outline" />}
                                />
                              }
                            />
                          </Form.Field>
                        </Form>
                      </div>
                      <div
                        style={{
                          width: '83%',
                          overflow: 'hidden',
                          height: 'calc(100vh - 202px)',
                          paddingRight: '20px',
                        }}
                      >
                        {this.state.heatMapFlag == false ? null : (
                          <div
                            style={{
                              width: '100%',
                              height: 'calc(100vh - 202px)',
                            }}
                            dangerouslySetInnerHTML={iframePart()}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </Media>
      </React.Fragment>
    );
  }
}

export default withRouter(AdsTotalContainerTemp);
