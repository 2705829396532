import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from 'react-router';
const someHTML = `<!DOCTYPE html>
<html lang="ko">

<head>
	<meta charset="UTF-8" />
	<meta name="viewport" content="width=device-width" />
	<title>위치기반 서비스 이용약관</title>
</head>

<body style="padding:20px; color: rgb(0,51,102);">
	<h2><span>위치기반 서비스 이용약관</span></h2>
	<br />
	<h4><span>제1조(목적)</span></h4>
	<p> <span>이 약관은 (주)Recreate(이하 &quot;회사&quot;라 함)가 운영하는 “팀트럭커” (이하 “팀트럭커”이라 한다) 에서 화물정보망 서비스 제공에 필요한 위치기반 부가 서비스(이하 &quot;서비스&quot;라 한다)를 서비스 이용자(위치기반서비스 이용약관에 동의한 자를 말합니다. 이하 "이용자"라고 한다.)가
                이용하는데 당사자의 권리,의무 및 책임사항을 규정함을 목적으로 한다.</span
            >
        </p>
        <h4><span>제2조(이용약관의 효력 및 변경)</span>
		</h4>
		<ol>
			<li> <span>본 약관은 이용자 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생한다.</span></li>
            <li> <span>이용자가 온라인 또는 모바일에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 본다.</span></li>
            <li> <span>회사는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있다.</span></li>
            <li> <span>회사가 약관을 개정할 경우에는 약관의 변경내용, 사유 및 개정약관의 적용일자를 명시하여 공지한다.</span></li>
            <li> <span>회사가 전항에 따라 이용자에게 공지한 후 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 보며, 이용자가 개정약관에 동의하지 않을 경우 이용자는 이용계약을 해지할 수 있습니다.</span></li>
		</ol>
		<h4><span>제3조 (관계법령의 적용)</span></h4>
		<ol>
			<li><span>본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따른다.</span>
			</li>
			
        </ol>
        <h4><span>제4조 (서비스의 내용)</span>
				</h4>
				<ol>
					<li><span>회사가 제공하는 서비스는 아래와 같다.</span></li>
					<table style="border-collapse:collapse;text-align:center;">
                        <thead>
                            <tr>
                                <td style="border:1px solid;width:200px;padding:10px;">서비스 명</td>
                                <td style="border:1px solid;width:400px;padding:10px;">서비스 내용</td>
                            </tr>
                        </thead>
                            
                        <tbody style="">
                        <tr>
                                <td style="border:1px solid;width:200px;padding:10px;">위치기반 서비스</td>
                                <td style="border:1px solid;width:400px;padding-left:5px;text-align:left;">
                                <ol>
				                	<li><span>차량 근거리 오더 조회, 배차신청</span></li>
                                    <li><span></span>배차신청차량과 목적지(화물, 화주)간 거리조회</li>
                                    <li><span></span>운송계약관련 화물 또는 차량의 위치/경로 확인</li>
				                </ol>
                            </tr>
                        </tbody>
                    </table>
                    <li><span>위치정보를 활용한 검색결과 제공 및 배차처리: 화물정보 검색을 요청, 배차 신청시에 개인위치정보주체 또는 이동성 있는 기기의 위치정보를 이용한 검색결과를 제시하고 배차처리에 이용한다</span></li>
                    <li><span>위치정보 공유: 개인위치정보주체 또는 이동성 있는 기기의 위치정보를 운송계약과 관련한 화물이나 차량의 위치/경로 확인을 목적으로 오더등록, 운송의뢰, 운송물 인도, 인수를 포함한 운송이해관계자(주선사, 화주, 상하차 담당자 등) 또는 개인위치정보주체가 지정한 제3자에게 공유한다.</span></li>
                    <li><span>이용자 보호 및 부정 이용 방지: 개인위치정보주체 또는 이동성 있는 기기의 위치를 이용하여 이용자의 안전을 위한 조치를 하며, 권한 없는 자의 비정상적인 서비스 이용 시도 등을 차단한다</span></li>
                    <li><span>길 안내 등 편의 서비스 제공</span></li>
                    <li><span>현재 위치를 활용한 광고정보 제공 서비스 제공</span></li>
                    <li><span>회사가 제공하는 서비스 내용은 회사의 정책과 방침에 따라 이용자에게 공지한 후 변경될 수 있다.</span></li>
				</ol>
                
				<h4><span>제5조 (서비스 이용요금)</span></h4>
				<ol>
					<li><span>회사가 제공하는 서비스는 무료로 한다.</span></li>
                    <li><span>회사의 정책과 방침에 따라 서비스를 유료로 변경할 수 있으며, 유료 서비스의 경우 해당 서비스에 명시된 요금을 지불해야 사용 가능함.</span></li>
				</ol>

				<h4><span>제6조 (서비스 내용변경 통지 등)</span></h4>
				<ol>
					<li><span>회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 이용자의 등록된 전자우편 주소 및/또는 이용자 프로그램(웹페이지 또는 어플)의 공지를 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있다.</span></li>
				</ol>

				<h4><span>제7조 (서비스 이용 제한 및 중지)</span></h4>
				<ol>
                <li><span>회사는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 이용자의 서비스 이용을 제한하거나 중지시킬 수 있습니다.</span>
                        <ol>
                            <li><span>이용자가 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우</span></li>
                            <li><span>서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</span></li>
                            <li><span>전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</span></li>
                            <li><span>국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</span></li>
                            <li><span>기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</span></li>
                        </ol>
                    </li>
                <li><span>회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 이용자에게 알려야 합니다.</span>
                <li><span>회사는 위치정보사업자의 정책변경 등과 같이 회사의 제반 사정 또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우, 서비스의 전부 또는 일부를 제한, 변경하거나 중지할 수 있습니다. 이러한 서비스 중단의 경우에는 회사는 사전에 홈페이지, 어플 등에 공지하거나 개인위치정보주체에게 통지합니다.</span>
				</ol>

        <h4><span>제8조 (개인위치정보의 이용 또는 제공)</span></h4>
            <ol>
                <li><span>회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 다음 각호의 사항을 미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 합니다</<span></li>
                <ol>
                    <li><span>위치기반서비스사업자의 상호, 주소, 전화번호 그 밖의 연락처</span></li>
                    <li><span>개인위치정보주체 및 법정대리인(이 약관 제10조, 제11조 규정에 의하여 법정대리인의 동의를 얻어야 하는 경우로 한정함)의 권리와 그 행사방법</span></li>
                    <li><span>위치기반서비스사업자가 제공하고자 하는 위치기반서비스의 내용</span></li>
                    <li><span>위치정보 이용ㆍ제공사실 확인자료의 보유근거 및 보유기간</span></li>
                    <li><span>본조 제6조, 7조에 규정된 통보에 관한 사항</span></li>
                </ol>
                <li><span>회사는 서비스제공, 타이용자 제공 또는 이용 고객과의 민원처리 등을 위해 위치정보 이용·제공 및 사실 확인자료를 자동 기록·보존하며, 해당 자료는 1년간 보관합니다.</<span></li>
                <li><span>회사는 개인위치정보를 정보주체가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치를 통해 시스템, 어플에서 확인하는 방식으로 매회 이용자에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. 단, 화물운송 이행과정에서 상대방 등에게 위치정보를 제공하는 경우에는 이용자가 언제든지 직접 자신의 위치정보 제3자 제공 내역을 조회할 수 있도록 시스템을 구성, 조회권한을 부여, 제공하며 이를 개별적인 통지에 갈음할 수 있습니다.</<span></li>
                <li><span>④ 회사는 배차신청 차량과 목적지간 거리조회, 운송계약과 관련한 화물이나 차량의 위치/경로 확인을 목적으로 오더등록, 운송의뢰, 운송물 인도, 인수를 포함한 운송이해관계자(주선사, 화주, 상하차 담당자 등)에게 개인위치정보를 제공합니다. 또한 회사는 운송의뢰자 휴대폰 위치정보를 상차지 확인에 갈음할 목적으로 운송인(차주)에게 제공할 수 있습니다.</<span></li>
            </ol>

        <h4><span>제9조 (개인위치정보주체의 권리)</span></h4>
            <ol>
                <li><span>이용자는 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.</span></li>
                <li><span>이용자는 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.</span></li>
                <li><span>이용자는 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 이용자의 요구를 거절할 수 없습니다.</span>
                    <ol>
                        <li><span>본인에 대한 위치정보 수집, 이용, 제공사실 확인자료</span></li>
                        <li><span>본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용</span></li>
                    </ol>
                </li>
                <li><span>이용자는 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.</span></li>
                <li><span>이용자 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.</span></li>
            </ol>

        <h4><span>제10조 (화물정보망 서비스의 이용제한)</span></h4>
        <ol>
            <li><span>이용자가 개인위치정보의 이용, 제공에 동의하지 않거나 거절하는 경우, 회사는 화물정보망서비스 이용에 제한을 둘 수 있습니다.</span></li>
            <li><span>본 서비스에 따라 차주의 위치정보를 제공받은 화주는 화물운송계약의 목적달성에 필요한 범위에서 회사가 정한 절차와 방식으로 이용해야 합니다. 만일 위치정보를 제공받은 화주가 약관, 회사규정이나 법령에 위배하여 이용한 경우 법적인 책임을 지며, 회사는 해당 화주의 정보망서비스 이용에 제한을 둘 수 있습니다.</span></li>
        </ol>

        <h4><span>제11조 (위치정보관리책임자의 지정)</span></h4>
        <ol>
            <li><span>회사는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다. </span></li>
            <li><span>위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.</span></li>
        </ol>

        <h4><span>제12조 (손해배상)</span></h4>
        <ol>
            <li><span>회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 이용자에게 손해가 발생한 경우 이용자는 회사에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다. </span></li>
            <li><span>이용자가 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 이용자에 대하여 손해배상을 청구할 수 있습니다. 이 경우 이용자는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.</span></li>
            <li><span>화주가 제10조의 2항의 규정 및 법률을 위반하여 위치정보 권리 주체 또는 회사에 손해가 발생한 경우, 화주는 고의, 과실이 없음을 입증하지 못하는 경우 위치정보 권리주체와 회사에 대해 책임을 면할 수 없습니다.</span></li>
        </ol>

        <h4><span>제13조 (면책)</span></h4>
        <ol>
            <li><span>회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.</span>
                <ol>
                <li><span>천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</span></li>
                <li><span>서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우</span></li>
                <li><span>이용자의 귀책사유로 서비스 이용에 장애가 있는 경우</span></li>
                <li><span>제1호 내지 제3호를 제외한 기타 회사의 고의∙과실이 없는 사유로 인한 경우</span></li>
                </ol>
            </li>
            <li><span>회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 이용자의 손해에 대하여는 책임을 부담하지 아니합니다.</span></li>
        </ol>

        <h4><span>제14조 (분쟁의 조정 및 기타)</span></h4>
        <ol>
            <li><span>회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.</span></li>
            <li><span>회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.</span></li>
            <li><span>본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다.</span></li>
        </ol>

</body>

</html>
`;
class LocationAgreement extends Component {
  render() {
    return <React.Fragment>{ReactHtmlParser(someHTML)}</React.Fragment>;
  }
}
export default withRouter(LocationAgreement);
