import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class AccStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable customerId = null;
  @observable pw = null;
  @observable hpNo = null; // 회원 전화번호
  @observable customerNm = null; // 회원 이름
  @observable customerCd = null; // 회원구분
  @observable customerCdNm = null; // 회원구분
  @observable recomId = null;
  @observable email = null;
  @observable emailCd = null;

  @observable compRegImageUri = null;
  @observable compRegImageName = null;
  @observable accCd = null;
  @observable accNo = null; //계좌번호
  @observable basicAdd = null;
  @observable detailAdd = null;

  @observable authList = [];

  @observable apvList = [];
  @observable selectedDriver = null;
  @observable selectedCustomer = null;

  @observable driverList = [];
  @observable customerList = [];

  @observable compRegNo = null;
  @observable vcRegNo = null;
  @observable logisCertiNo = null;

  @observable staffList = [];
  @observable selectedStaff = {};
  @observable staffPw = null;
  @observable staffNm = null;
  @observable staffHpNo = null;
  @observable staffId = null;

  @observable customerKeywordList = [];
  @observable driverKeywordList = [];

  @observable searchCustomerIdList = [];

  @observable agreementModal = { isOpen: false, typeNum: 0 };

  @action
  setCustomerId = value => {
    this.customerId = value;
  };

  @action
  initCustomerId = () => {
    this.customerId = null;
  };

  @action
  setPw = value => {
    this.pw = value;
  };

  @action
  initPw = () => {
    this.pw = null;
  };

  @action
  setHpNo = value => {
    this.hpNo = value;
  };

  @action
  initHpNo = () => {
    this.hpNo = null;
  };

  @action
  setCustomerNm = value => {
    this.customerNm = value;
  };

  @action
  initCustomerNm = () => {
    this.customerNm = null;
  };

  @action
  setCustomerCd = value => {
    this.customerCd = value;
  };

  @action
  initCustomerCd = value => {
    this.customerCd = null;
  };

  @action
  setCustomerCdNm = value => {
    this.customerCdNm = value;
  };

  @action
  initCustomerCdNm = value => {
    this.customerCdNm = null;
  };

  @action
  setEmail = value => {
    this.email = value;
  };

  @action
  initEmail = () => {
    this.email = null;
  };

  @action
  setEmailCd = value => {
    this.emailCd = value;
  };

  @action
  initEmailCd = () => {
    this.emailCd = null;
  };

  @action
  setCompRegImageUri = value => {
    this.compRegImageUri = value;
  };

  @action
  initCompRegImageUri = () => {
    this.compRegImageUri = null;
  };

  @action
  setCompRegImageName = value => {
    this.compRegImageName = value;
  };

  @action
  initCompRegImageName = () => {
    this.compRegImageName = null;
  };

  @action
  setAccCd = value => {
    this.accCd = value;
  };

  @action
  initAccCd = () => {
    this.accCd = null;
  };

  @action
  setAccNo = value => {
    this.accNo = value;
  };

  @action
  initAccNo = () => {
    this.accNo = null;
  };

  @action
  setBasicAdd = value => {
    this.basicAdd = value;
  };

  @action
  initBasicAdd = () => {
    this.basicAdd = null;
  };

  @action
  setDetailAdd = value => {
    this.detailAdd = value;
  };

  @action
  initDetailAdd = () => {
    this.detailAdd = null;
  };

  @action
  setAuthList = value => {
    this.authList = value;
  };

  @action
  initAuthList = () => {
    this.authList = [];
  };

  @action
  setApvList = value => {
    this.apvList = value;
  };

  @action
  initApvList = () => {
    this.apvList = [];
  };

  @action
  setSelectedDriver = value => {
    this.selectedDriver = value;
  };

  @action
  initSelectedDriver = () => {
    this.selectedDriver = null;
  };

  @action
  setSelectedCustomer = value => {
    this.selectedCustomer = value;
  };

  @action
  initSelectedCustomer = () => {
    this.selectedCustomer = null;
  };

  @action
  setDriverList = value => {
    this.driverList = value;
  };

  @action
  initDriverList = () => {
    this.driverList = [];
  };

  @action
  setCustomerList = value => {
    this.customerList = value;
  };

  @action
  initCustomerList = () => {
    this.customerList = [];
  };

  @action
  setCompRegNo = value => {
    this.compRegNo = value;
  };

  @action
  initCompRegNo = () => {
    this.compRegNo = null;
  };

  @action
  setVcRegNo = value => {
    this.vcRegNo = value;
  };

  @action
  initVcRegNo = () => {
    this.vcRegNo = null;
  };

  @action
  setLogisCertiNo = value => {
    this.logisCertiNo = value;
  };

  @action
  initLogisCertiNo = () => {
    this.logisCertiNo = null;
  };

  @action
  setStaffList = value => {
    this.staffList = value;
  };

  @action
  initStaffList = () => {
    this.staffList = null;
  };

  @action
  setSelectedStaff = value => {
    this.selectedStaff = value;
  };

  @action
  initSelectedStaff = () => {
    this.selectedStaff = null;
  };

  @action
  setStaffPw = value => {
    this.staffPw = value;
  };

  @action
  initStaffPw = () => {
    this.staffPw = null;
  };

  @action
  setStaffNm = value => {
    this.staffNm = value;
  };

  @action
  initStaffNm = () => {
    this.staffNm = null;
  };

  @action
  setStaffHpNo = value => {
    this.staffHpNo = value;
  };

  @action
  initStaffHpNo = () => {
    this.staffHpNo = null;
  };

  @action
  setStaffId = value => {
    this.staffId = value;
  };

  @action
  initStaffId = () => {
    this.staffId = null;
  };

  @action
  setCustomerKeywordList = value => {
    this.customerKeywordList = value;
  };

  @action
  initCustomerKeywordList = () => {
    this.customerKeywordList = [];
  };

  @action
  setDriverKeywordList = value => {
    this.driverKeywordList = value;
  };

  @action
  initDriverKeywordList = () => {
    this.driverKeywordList = [];
  };

  @action
  setSearchCustomerIdList = value => {
    this.searchCustomerIdList = value;
  };

  @action
  initSearchCustomerIdList = () => {
    this.searchCustomerIdList = [];
  };

  @action
  agreementModalOpen = value => {
    this.agreementModal = { isOpen: true, typeNum: value };
  };

  @action
  agreementModalClose = () => {
    this.agreementModal = { isOpen: false, typeNum: null };
  };

  @action
  initForm = () => {
    this.initAccCd();
    this.initAccNo();
    this.initCompRegImageName();
    this.initCompRegImageUri();
    this.initCustomerCd();
    this.initCustomerCdNm();
    this.initCustomerId();
    this.initCustomerNm();
    this.initEmail();
    this.initEmailCd();
    this.initHpNo();
    this.initPw();
    this.initCompRegNo();
    this.initVcRegNo();
    this.initLogisCertiNo();
  };
}

export default AccStore;
