import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class QuestionStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable questionList = [];
  @observable selectedQuestion = null;
  @observable replyContent = null;

  @observable questionTitle = null;
  @observable questionContent = null;

  @action
  setQuestionList = value => {
    this.questionList = value;
  };

  @action
  initQuestionList = () => {
    this.questionList = [];
  };

  @action
  setSelectedQuestion = value => {
    this.selectedQuestion = value;
  };

  @action
  initSelectedQuestion = () => {
    this.selectedQuestion = null;
  };

  @action
  setReplyContent = value => {
    this.replyContent = value;
  };

  @action
  initReplyContent = () => {
    this.replyContent = null;
  };

  @action
  setQuestionTitle = value => {
    this.questionTitle = value;
  };

  @action
  initQuestionTitle = () => {
    this.questionTitle = null;
  };

  @action
  setQuestionContent = value => {
    this.questionContent = value;
  };

  @action
  initQuestionContent = () => {
    this.questionContent = null;
  };

  @action
  initForm = () => {
    this.initQuestionList();
    this.initSelectedQuestion();
    this.initReplyContent();
  };

  @action
  initCustomerForm = () => {
    this.initQuestionTitle();
    this.initQuestionContent();
  };
}

export default QuestionStore;
