import './App.css';
import { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Prompt,
} from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import 'react-toastify/dist/ReactToastify.css';
import { inject, observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import PublicRoute from './Component/Common/PublicRoute';
import PrivateRoute from './Component/Common/PrivateRoute';
import Error from './Component/Common/Error';

import { LoginIndex } from './Containers/Login/';
import { UserIndex } from './Containers/Management/User';
import { DashBoardIndex } from './Containers/DashBoard';
import { FreightIndex } from './Containers/Management/Freight';
import { SettingIndex } from './Containers/Management/Setting';
import { RegistIndex } from './Containers/Regist';
import { AuthIndex } from './Containers/Management/Auth';
import { GroupIndex } from './Containers/Management/Group';
import { MessageIndex } from './Containers/Management/Message';
import { AlarmTalkIndex } from './Containers/Management/AlarmTalk';
import { FavoriteIndex } from './Containers/Management/Favorite';
import { DepositIndex } from './Containers/Management/Deposit/Index';
import OrderContainer from './Containers/Order/OrderContainer';
import OrderMobileTest from './Containers/Order/OrderMobileTest';
import Agreements from './Component/Common/Agreements';
import Privacy from './Component/Common/Privacy';
import PrivacyThird from './Component/Common/PrivacyThird';
import PaymentSuccess from './Component/Payment/PaymentSuccess';
import PaymentFailed from './Component/Payment/PaymentFailed';

import axios from 'axios';
import { API_SELECT_AUTH_MENU_LIST } from './common/ApiAddress/PrevilegeAPI';
import { isEmp } from './Util/CommonUtil';
import NiceAuthForWebView from './Containers/Regist/NiceAuthForWebview';
import OrderStore from './store/OrderStore';
import Main from './Containers/Main';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import AdsTemp from './Containers/AdsTemp';
import NicePayForWebView from './Containers/Order/NicePayForWebView';
import PaymentSuccessForMobile from './Component/Payment/PaymentSuccessForMobile';
import SettingManagementContainer from './Containers/Management/Setting/SettingManagementContainer';
import AdsTotalContainerTemp from './Containers/AdsTotalContainerTemp';
import QuoteContainer from './Containers/Quote/QuoteContainer';
import LocationAgreement from './Component/Common/LocationAgreement';
import PrivacyLocationPolicy from './Component/Common/PrivacyLocationPolicy';
import FreightMiniContainer from './Containers/TempManagement/FreightMiniContainer';
import WorkbenchContainer from './Containers/Workbench/WorkbenchContainer';
import WorkbenchDriverContainer from './Containers/Workbench/WorkbenchDriverContainer';

@inject(stores => ({
  commonStore: stores.CommonStore,
  empStore: stores.EmpStore,
  prvStore: stores.PrivilegeStore,
}))
@observer
class App extends Component {
  async componentDidMount() {
    if (isEmp()) {
      this.fnSelectAuthList();
    }
  }

  //관리자 메뉴 불러오기
  fnSelectAuthList = () => {
    const { prvStore } = this.props;

    let params = {};
    let that = this;
    params.authId = sessionStorage.getItem('authId');
    axios({
      url: API_SELECT_AUTH_MENU_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          prvStore.setAuthMenuList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    //restricted 로그인한 사용자가 해당 루트로 접근을 막기 위함
    //restricted == true 로그인한 상태에서는 접근 불가
    //restricted == false 로그인한 상태에서도 접근 가능
    return (
      <Router>
        <Helmet>
          <title>TeamTrucker - 팀트럭커</title>
          <meta property="title" content="TeamTrucker - 팀트럭커" />
          <meta
            property="description"
            content="24시 전국 화물운송 빠른배차 친절상담 혼적 합짐 전문 운송플랫폼"
          />
          <meta property="image" content="../public/meta_image.png" />

          <meta property="og:title" content="TeamTrucker - 팀트럭커" />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content="24시 전국 화물운송 빠른배차 친절상담 혼적 합짐 전문 운송플랫폼"
          />
          <meta property="og:image" content="../public/meta_image.png" />
        </Helmet>
        <Switch>
          {/* <PublicRoute
            restricted={true}
            exact
            path="/"
            component={Main}
            sideBar={false}
          /> */}
          <PublicRoute
            restricted={true}
            exact
            path="/login"
            component={LoginIndex.LoginContainer}
            sideBar={false}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/empLogin"
            component={LoginIndex.EmpLogin}
            sideBar={false}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/regist/agree"
            component={RegistIndex.AgreementContainer}
            sideBar={false}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/regist/accinfo"
            component={RegistIndex.RegistContainer}
            sideBar={false}
          />
          <PublicRoute
            restricted={true}
            exact
            path="/regist/return"
            component={RegistIndex.RegistReturn}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/staffSelect"
            component={LoginIndex.SelectStaffContainer}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/niceInfo"
            component={RegistIndex.NiceAuthInfo}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/niceWebView"
            component={NiceAuthForWebView}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/searchacc"
            component={LoginIndex.SearchAccContainer}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/searchIdReturn"
            component={LoginIndex.SearchAccIdReturn}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/pwsetting"
            component={LoginIndex.SearchNewPwSetting}
            sideBar={false}
          />
          <PrivateRoute
            restricted={false}
            path="/main"
            component={DashBoardIndex.DashBoardContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/user/trucker/:driverSection"
            component={UserIndex.DriverManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/user/customer/:customerSection"
            component={UserIndex.CustomerManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/order"
            component={OrderContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/freight/:freightSection"
            component={FreightIndex.FreightManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/staff"
            component={SettingIndex.StaffManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/setting/:settingSection"
            component={SettingManagementContainer}
            sideBar={true}
          />

          <PrivateRoute
            restricted={false}
            path="/setting/notice"
            component={SettingIndex.NotiManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/setting/fee"
            component={SettingIndex.FreightFeeManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/setting/question"
            component={SettingIndex.QuestionManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/setting/config"
            component={SettingIndex.ConfigContainer}
            sideBar={true}
          />
          {/* <PublicRoute
            restricted={false}
            path="*"
            component={Error}
            sideBar={false}
          /> */}
          <PublicRoute
            restricted={false}
            path="/mobileTest"
            component={OrderMobileTest}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/terms"
            component={Agreements}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/privacy"
            component={Privacy}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/locationagreement"
            component={LocationAgreement}
            sideBar={false}
          />

          <PublicRoute
            restricted={false}
            path="/privacypolicy"
            component={PrivacyLocationPolicy}
            sideBar={false}
          />

          <PublicRoute
            restricted={false}
            path="/privacyThird"
            component={PrivacyThird}
            sideBar={false}
          />
          <PrivateRoute
            restricted={false}
            path="/group/emp"
            component={GroupIndex.EmpManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/auth/menu"
            component={AuthIndex.MenuManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/auth/privilege"
            component={AuthIndex.PrivilegeManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/paysuccess"
            component={PaymentSuccess}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/payfailed"
            component={PaymentFailed}
            sideBar={true}
          />

          <PrivateRoute
            restricted={false}
            path="/msg/template"
            component={MessageIndex.MessageTemplateManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/msg/send"
            component={MessageIndex.MessageSendContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/msg/msglist"
            component={MessageIndex.MessageListContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/arm/template"
            component={AlarmTalkIndex.AlarmTalkTemplateManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/arm/send"
            component={AlarmTalkIndex.AlarmTalkSendContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/arm/armlist"
            component={AlarmTalkIndex.AlarmTalkListContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/favorite"
            component={FavoriteIndex.FavoriteManagementContainer}
            sideBar={true}
          />
          <PrivateRoute
            restricted={false}
            path="/deposit"
            component={DepositIndex.DepositContainer}
            sideBar={true}
          />

          <PublicRoute
            restricted={true}
            path="/ads/muska"
            component={AdsTemp}
            sideBar={false}
          />

          <PublicRoute
            restricted={true}
            path="/ads/"
            component={AdsTotalContainerTemp}
            sideBar={false}
          />

          <PublicRoute
            restricted={false}
            path="/nicepayWebView"
            component={NicePayForWebView}
            sideBar={false}
          />

          <PublicRoute
            restricted={false}
            path="/paysuccessForMobile"
            component={PaymentSuccessForMobile}
            sideBar={false}
          />

          {/* <PublicRoute
            restricted={false}
            path="/tempQuote"
            component={QuoteContainer}
            sideBar={false}
          /> */}

          <PublicRoute
            restricted={false}
            exact
            path="/"
            component={QuoteContainer}
            sideBar={false}
          />

          <PublicRoute
            restricted={false}
            path="/quote/:quoteSection"
            component={QuoteContainer}
            sideBar={false}
          />

          <PublicRoute
            restricted={false}
            path="/freightMini"
            component={FreightMiniContainer}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/work/:workSection"
            component={WorkbenchContainer}
            sideBar={false}
          />
          <PublicRoute
            restricted={false}
            path="/workdriver/:workdriverSection"
            component={WorkbenchDriverContainer}
            sideBar={false}
          />
          <Route />
        </Switch>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          position="bottom-right"
        />
      </Router>
    );
  }
}

export default App;
