import _ from 'lodash';
import React, { Fragment } from 'react';
import { Search, Header, Segment, Label } from 'semantic-ui-react';
import { useObserver, observer } from 'mobx-react';
import RootStore from '../../../store/index';

const root = new RootStore();

const initialState = {
  loading: false,
  results: [],
  value: '',
};

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      console.log(action);
      return { ...state, value: action.selection };

    default:
      throw new Error();
  }
}

const SearchInput = observer(props => {
  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  const { loading, results, value } = state;
  const {
    searchTyp,
    activeItem,
    getCustomerList,
    getDriverList,
    resultList,
    fnSelectQuestionListWithKeyword,
  } = props;
  const acc = root.AccStore;
  const timeoutRef = React.useRef();
  const handleSearchChange = (e, data) => {
    if (activeItem == 'customer') {
      getCustomerList(data.value, searchTyp);
    } else if (activeItem == 'trucker') {
      getDriverList(data.value, searchTyp);
    }

    clearTimeout(timeoutRef.current);
    dispatch({ type: 'START_SEARCH', query: data.value });

    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' });
        return;
      }

      const re = new RegExp(_.escapeRegExp(data.value), 'i');
      const isMatch = result => re.test(result.title);

      dispatch({
        type: 'FINISH_SEARCH',
        //results: _.filter(acc.customerKeywordList, isMatch),
        results: _.filter(resultList, isMatch),
      });
    }, 300);
  };

  const resultRendererForId = ({ title, nm, hp, email }) => (
    <div style={{ width: '300px' }}>
      <p>아이디 : {title}</p>
      <p>이름 : {nm}</p>
      <p>연락처 : {hp}</p>
      <p>이메일 : {email}</p>
    </div>
  );

  const resultRendererForNm = ({ title, accid, hp, email }) => (
    <div style={{ width: '300px' }}>
      <p>이름 : {title}</p>
      <p>아이디 : {accid}</p>
      <p>연락처 : {hp}</p>
      <p>이메일 : {email}</p>
    </div>
  );

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return useObserver(() => (
    <Fragment>
      <Search
        disabled={searchTyp == null ? true : false}
        resultRenderer={
          searchTyp == 'A' ? resultRendererForId : resultRendererForNm
        }
        loading={loading}
        placeholder="검색어 입력"
        style={{ width: '175px', overflow: 'hidden', position: 'inherit' }}
        onResultSelect={(e, data) => [
          fnSelectQuestionListWithKeyword(data.result.title),
          dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title }),
        ]}
        onSearchChange={handleSearchChange}
        results={results}
        value={value}
      />
    </Fragment>
  ));
});

export default SearchInput;
