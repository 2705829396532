import React, { Component } from 'react';
import axios from 'axios';
import { Form } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../common/Color';
import MainFooter from '../../Component/Common/MainFooter';
import {
  STR_PW_CHECK,
  STR_MODIFY_PW,
  STR_NEW_PW,
  STR_SEN_3,
  STR_SEN_37,
  STR_SEN_38,
  STR_SEN_39,
} from '../../common/Terms';
import CommonAlert from '../../Component/Common/CommonAlert';
import { API_UPDATE_CUSTOMER_NEW_PW } from '../../common/ApiAddress/AccountAPI';
@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class SearchNewPwSetting extends React.Component {
  state = {
    pw: null,
    pwcheck: null,
  };

  fnMoveToMain = () => {
    const { accStore } = this.props;
    accStore.initCustomerId();
    this.props.history.push('/');
  };

  //비밀번호 변경
  fnUpdateCustomerId = () => {
    const { accStore, commonStore } = this.props;
    let params = {};
    let that = this;
    if(this.state.pwcheck != this.state.pw){
      commonStore.commonAlertOpen("비밀번호가 일치하지 않습니다.")
    }

    if (this.state.pw.length < 6) {
      commonStore.commonAlertOpen(STR_SEN_37);
      return false;
    }
    params.customerId = accStore.customerId;
    params.pw = this.state.pw;
    axios({
      url: API_UPDATE_CUSTOMER_NEW_PW,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.commonAlertOpen(STR_SEN_38);
          that.setState({
            pw: null,
            pwcheck: null,
          });
          that.fnMoveToMain()
        } else {
          commonStore.commonAlertOpen(STR_SEN_39);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { accStore } = this.props;
    return (
      <React.Fragment>
      <div
      style={{
        width: '100%',
        height: 'calc(100vh - 60px)',
        background: '#000',
      }}
      >
        <div style={{ textAlign: 'center', paddingTop: '100px' }}>
            <img
              style={{ width: '20%', marginBottom: '32px' }}
              src={'./image/logoDarktheme.png'}
            />
          </div>
          <div
            style={{
              width: '18%',
              margin: '0 auto ',
              display: 'flex',
              flexGrow: 2,
            }}
          >
        <Form>
          <Form.Field>
            <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
              {STR_NEW_PW}
            </label>
            <input
              style={{ height: '60px', fontSize: '20px' }}
              placeholder={STR_SEN_3}
              onChange={e =>
                this.setState({
                  pw: e.target.value,
                })
              }
              value={this.state.pw || ''}
              type="password"
            />
          </Form.Field>
          <Form.Field>
            <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
              {STR_PW_CHECK}
            </label>
            <input
              style={{ height: '60px', fontSize: '20px' }}
              placeholder={STR_SEN_3}
              onChange={e =>
                this.setState({
                  pwcheck: e.target.value,
                })
              }
              value={this.state.pwcheck || ''}
              type="password"
            />
          </Form.Field>
          <div
            onClick={() => {
              this.fnUpdateCustomerId();
            }}
            style={{
              width: '100%',
              height: '60px',
              marginTop: '33px',
              marginBottom: '33px',
              background: COLOR_GREEN_PRIMARY,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '20px',
              color: COLOR_WHITE,
            }}
          >
            {STR_MODIFY_PW}
          </div>
        </Form>
        </div>
       
      </div>
       <MainFooter/>
       <CommonAlert />
      </React.Fragment>
    );
  }
}

export default SearchNewPwSetting;
