import React, { Component } from 'react';
import { ReactComponent as IntroduceTop } from '../../assets/icon/introduce_top.svg';
import { ReactComponent as TruckerAppIcon } from '../../assets/icon/trucker_app_icon.svg';
import { ReactComponent as CustomerAppIcon } from '../../assets/icon/customer_app_icon.svg';

import {
  COLOR_BLACK,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';

class Introduce extends Component {
  componentDidMount() {
    this.props.fnFreightFormInit();
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%', height: '400px', position: 'relative' }}>
          <IntroduceTop
            style={{
              marginTop: '16px',
              verticalAlign: 'middle',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: '45%',
              left: '45%',
              textAlign: 'center',
              letterSpacing: '-1px',
            }}
          >
            <p
              style={{
                color: COLOR_WHITE,
                fontSize: '40px',
                fontWeight: '700',
                marginBottom: '0px',
              }}
            >
              팀트럭커 소개
            </p>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div
            style={{
              width: '100%',
              background: COLOR_WHITE,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CustomerAppIcon
                width={144}
                height={144}
                style={{
                  marginTop: '166px',
                  marginBottom: '122px',
                }}
              />
            </div>
            <div
              style={{
                width: '100%',
                zIndex: 98,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <p
                  style={{
                    color: COLOR_BLACK,
                    fontSize: '36px',
                    fontWeight: '700',
                    marginBottom: '0px',
                  }}
                >
                  지금껏 산업을 일구어 온 것은
                </p>
                <p
                  style={{
                    color: COLOR_BLACK,
                    fontSize: '36px',
                    fontWeight: '700',
                  }}
                >
                  기술이 아닌 사람이입니다.
                </p>
                <p
                  style={{
                    color: COLOR_BLACK,
                    fontSize: '36px',
                    fontWeight: '700',
                    marginBottom: '0px',
                  }}
                >
                  앞으로도 산업과 산업은
                </p>
                <p
                  style={{
                    color: COLOR_BLACK,
                    fontSize: '36px',
                    fontWeight: '700',
                  }}
                >
                  <span
                    style={{
                      color: COLOR_GREEN_PRIMARY,
                      fontSize: '36px',
                      fontWeight: '700',
                    }}
                  >
                    팀트럭커
                  </span>
                  가 연결합니다.
                </p>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <img
                style={{
                  width: '768px',
                }}
                src={'../image/mainTemp.png'}
              />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              background: 'url(../image/main_2.png)',
              width: '100%',
              height: '100vh',
              zIndex: 97,
              position: 'absolute',
            }}
          />
          <div
            style={{
              background: 'rgba(0,0,0,0.5)',
              position: 'relative',
              top: 0,
              width: '100%',
              height: '100vh',
              zIndex: 98,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '-1px',
            }}
          >
            <div style={{ top: '384 !important' }}>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                혁신적인 IT서비스를 기반으로
              </p>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                물류비 절감과 안전운임 달성을
              </p>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                동시에 추구하는
              </p>
              <p
                style={{
                  color: '#78C939',
                  fontSize: '36px',
                  fontWeight: 'bold',
                }}
              >
                TeamTrucker 플랫폼
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '100vh',
            background: COLOR_WHITE,
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100vh',
              zIndex: 98,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: -80,
            }}
          >
            <div>
              <TruckerAppIcon
                width={144}
                height={144}
                style={{ marginBottom: '80px' }}
              />
              <p
                style={{
                  color: COLOR_BLACK,
                  fontSize: '36px',
                  fontWeight: '700',
                  marginBottom: '0px',
                }}
              >
                산업과 산업을 연결하는
              </p>
              <p
                style={{
                  color: COLOR_BLACK,
                  fontSize: '36px',
                  fontWeight: '700',
                }}
              >
                물류의 근간,
                <span
                  style={{
                    color: '#78C939',
                    fontSize: '36px',
                    fontWeight: '700',
                  }}
                >
                  {' '}
                  팀트럭커
                </span>
              </p>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '157px',
                    height: '44px',
                    borderRadius: '100px',
                    padding: '10px 25px',
                    background: COLOR_GREEN_PRIMARY,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      fontSize: '20px',
                      fontWeight: '700',
                      color: COLOR_WHITE,
                      letterSpacing: '-1px',
                    }}
                  >
                    Test Version
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Introduce;
