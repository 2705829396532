export const COLOR_WHITE = '#fff';
export const COLOR_BLACK = '#000';
export const COLOR_GRAY = '#eaeaea';

export const COLOR_GRAY_GR00 = '#F8F9FA';
export const COLOR_GRAY_GR01 = '#EFEFEF';
export const COLOR_GRAY_GR02 = '#E9EBED';
export const COLOR_GRAY_GR05 = '#C2C2C4';
export const COLOR_GRAY_GR06 = '#8F8F8F';
export const COLOR_GRAY_GR07 = '#5A5D61';
export const COLOR_GRAY_GR08 = '#2C2E32';
export const COLOR_GRAY_GR09 = '#1F2023';

export const COLOR_PRIMARY = '#f73677';

export const COLOR_GREEN_PRIMARY = '#78C939';
export const COLOR_GREEN_SECOND = '#21BA45';
