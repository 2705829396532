import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
class CommonInput extends Component {
  render() {
    return (
      <Form.Field>
        <input
          label={this.props.label == null ? null : this.props.label}
          type={this.props.type == null ? 'text' : this.props.type}
          placeholder={this.props.placeholder}
          onChange={e => this.props.onChange(e.target.value)}
          value={this.props.defaultValue || ''}
        />
      </Form.Field>
    );
  }
}

export default CommonInput;
