import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class WorkbenchOrderStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable client = null;
  @observable clientStaff = null;
  @observable clientStaffHpNo = null;
  @observable freightInfo = null;
  @observable freightMemo = null;
  @observable freightWeight = null;
  @observable loadingStaffHpNo = null;
  @observable loadingAdd = null;
  @observable loadingCompNm = null;
  @observable loadingDate = null;
  @observable loadingStaff = null;
  @observable loadingTime = null;
  @observable unloadingStaffHpNo = null;
  @observable unloadingAdd = null;
  @observable unloadingCompNm = null;
  @observable unloadingDate = null;
  @observable unloadingStaff = null;
  @observable unloadingTime = null;
  @observable vcTyp = null;
  @observable vcWeight = null;
  @observable payCd = null;
  @observable freightCd = null;

  @observable driverNm = null;
  @observable driverHpNo = null;
  @observable driverVcWeight = null;
  @observable driverVcTyp = null;
  @observable vcNo = null;

  @observable orderList = [];
  @observable selectedOrder = null;

  @observable logisFee = 0;
  @observable commission = 0;
  @observable totalFee = 0;

  @observable freightStatementList = [];
  @observable totalFeeSum = 0;
  @observable totalPrintPage = 0;

  @action
  setClient = value => {
    this.client = value;
  };

  @action
  initClient = () => {
    this.client = null;
  };

  @action
  setClientStaff = value => {
    this.clientStaff = value;
  };

  @action
  initClientStaff = () => {
    this.clientStaff = null;
  };

  @action
  setClientStaffHpNo = value => {
    this.clientStaffHpNo = value;
  };

  @action
  initClientStaffHpNo = () => {
    this.clientStaffHpNo = null;
  };

  @action
  setLoadingCompNm = value => {
    this.loadingCompNm = value;
  };

  @action
  initLoadingCompNm = () => {
    this.loadingCompNm = null;
  };

  @action
  setLoadingAdd = value => {
    this.loadingAdd = value;
  };

  @action
  initLoadingAdd = () => {
    this.loadingAdd = null;
  };

  @action
  setLoadingStaff = value => {
    this.loadingStaff = value;
  };

  @action
  initLoadingStaff = () => {
    this.loadingStaff = null;
  };

  @action
  setLoadingStaffHpNo = value => {
    this.loadingStaffHpNo = value;
  };

  @action
  initLoadingStaffHpNo = () => {
    this.loadingStaffHpNo = null;
  };

  @action
  setUnloadingCompNm = value => {
    this.unloadingCompNm = value;
  };

  @action
  initUnloadingCompNm = () => {
    this.unloadingCompNm = null;
  };

  @action
  setUnloadingAdd = value => {
    this.unloadingAdd = value;
  };

  @action
  initUnloadingAdd = () => {
    this.unloadingAdd = null;
  };

  @action
  setUnloadingStaff = value => {
    this.unloadingStaff = value;
  };

  @action
  initUnloadingStaff = () => {
    this.unloadingStaff = null;
  };

  @action
  setUnloadingStaffHpNo = value => {
    this.unloadingStaffHpNo = value;
  };

  @action
  initUnloadingStaffHpNo = () => {
    this.unloadingStaffHpNo = null;
  };

  @action
  setVcTyp = value => {
    this.vcTyp = value;
  };

  @action
  initVcTyp = () => {
    this.vcTyp = null;
  };

  @action
  setVcWeight = value => {
    this.vcWeight = value;
  };

  @action
  initVcWeight = () => {
    this.vcWeight = null;
  };

  @action
  setFreightInfo = value => {
    this.freightInfo = value;
  };

  @action
  initFreightInfo = () => {
    this.freightInfo = null;
  };

  @action
  setFreightWeight = value => {
    this.freightWeight = value;
  };

  @action
  initFreightWeight = () => {
    this.freightWeight = null;
  };

  @action
  setFreightMemo = value => {
    this.freightMemo = value;
  };

  @action
  initFreightMemo = () => {
    this.freightMemo = null;
  };

  @action
  setLoadingDate = value => {
    this.loadingDate = value;
  };

  @action
  initLoadingDate = () => {
    this.loadingDate = null;
  };

  @action
  setLoadingTime = value => {
    this.loadingTime = value;
  };

  @action
  initLoadingTime = () => {
    this.loadingTime = null;
  };

  @action
  setUnloadingDate = value => {
    this.unloadingDate = value;
  };

  @action
  initUnloadingDate = () => {
    this.unloadingDate = null;
  };

  @action
  setUnloadingTime = value => {
    this.unloadingTime = value;
  };

  @action
  initUnloadingTime = () => {
    this.unloadingTime = null;
  };

  @action
  setPayCd = value => {
    this.payCd = value;
  };

  @action
  initPayCd = () => {
    this.payCd = null;
  };

  @action
  setOrderList = value => {
    this.orderList = value;
  };

  @action
  initOrderList = () => {
    this.orderList = [];
  };

  @action
  setSelectedOrder = value => {
    this.selectedOrder = value;
  };

  @action
  initSelectedOrder = () => {
    this.selectedOrder = null;
  };

  @action
  setFreightCd = value => {
    this.freightCd = value;
  };

  @action
  initFreightCd = () => {
    this.freightCd = null;
  };

  @action
  setDriverNm = value => {
    this.driverNm = value;
  };

  @action
  initDriverNm = () => {
    this.driverNm = null;
  };

  @action
  setDriverHpNo = value => {
    this.driverHpNo = value;
  };

  @action
  initDriverHpNo = () => {
    this.driverHpNo = null;
  };

  @action
  setDriverVcWeight = value => {
    this.driverVcWeight = value;
  };

  @action
  initDriverVcWeight = () => {
    this.driverVcWeight = null;
  };

  @action
  setDriverVcTyp = value => {
    this.driverVcTyp = value;
  };

  @action
  initDriverVcTyp = () => {
    this.driverVcTyp = null;
  };

  @action
  setVcNo = value => {
    this.vcNo = value;
  };

  @action
  initVcNo = () => {
    this.vcNo = null;
  };

  @action
  setLogisFee = value => {
    this.logisFee = value;
  };

  @action
  initLogisFee = () => {
    this.logisFee = 0;
  };

  @action
  setCommission = value => {
    this.commission = value;
  };

  @action
  initCommission = () => {
    this.commission = 0;
  };

  @action
  setTotalFee = value => {
    this.totalFee = value;
  };

  @action
  initTotalFee = () => {
    this.totalFee = 0;
  };

  @action
  setFreightStatementList = value => {
    if (value.length > 25) {
      let arry = value;
      //첫 23개 짜르고
      this.freightStatementList.push(arry.slice(0, 24));
      //39개씩 나눠서 담기
      let count = Math.ceil((value.length - 24) / 39);
      this.totalPrintPage = count;
      for (let i = 0; i < count; i++) {
        this.freightStatementList.push(
          arry.slice(i * 39 + 24, (i + 1) * 39 + 24),
        );
      }
    } else {
      let count = 23 - value.length;
      for (let i = 0; i < count; i++) {
        value.push({});
      }
      console.log(value);
      this.freightStatementList.push(value);
    }
  };

  @action
  initFreightStatementList = () => {
    this.freightStatementList = [];
  };

  @action
  setTotalFeeSum = value => {
    this.totalFeeSum = value;
  };

  @action
  initTotalFeeSum = () => {
    this.totalFeeSum = 0;
  };

  @action
  initForm = () => {
    this.initClient();
    this.initClientStaff();
    this.initClientStaffHpNo();
    this.initLoadingCompNm();
    this.initLoadingAdd();
    this.initLoadingStaff();
    this.initLoadingStaffHpNo();
    this.initUnloadingCompNm();
    this.initUnloadingAdd();
    this.initUnloadingStaff();
    this.initUnloadingStaffHpNo();
    this.initVcTyp();
    this.initVcWeight();
    this.initFreightInfo();
    this.initFreightWeight();
    this.initFreightMemo();
    this.initLoadingDate();
    this.initLoadingTime();
    this.initUnloadingDate();
    this.initUnloadingTime();
    this.initPayCd();
    this.initFreightCd();
    this.initDriverNm();
    this.initDriverHpNo();
    this.initDriverVcWeight();
    this.initDriverVcTyp();
    this.initVcNo();
    this.initLogisFee();
    this.initCommission();
    this.initTotalFee();
  };
}

export default WorkbenchOrderStore;
