import { Component } from 'react';
import { Table, Input, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class APVDetail extends Component {
  render() {
    const { accStore, vcStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {accStore.selectedDriver != null ? (
          <div>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    심사대기 회원정보
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>회원명</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.driverNm}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>아이디</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.driverId}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>신청구분</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.driverCdNm}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>전화번호</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.hpNo}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>이메일</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>계좌번호</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedDriver.accCdNm} :{' '}
                    {accStore.selectedDriver.accNo}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    심사대기 차량정보
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>중량구분</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedDriver.vcWeightCdNm}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>차량구분</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.vcTypCdNm}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>너비(단위 : mm)</Table.Cell>
                  <Table.Cell>
                    <Input
                      placeholder="차량 너비 기입"
                      value={vcStore.vcWidth}
                      onChange={e => {
                        vcStore.setVcWidth(e.target.value);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>길이(단위 : mm)</Table.Cell>
                  <Table.Cell>
                    <Input
                      placeholder="차량 길이 기입"
                      value={vcStore.vcLength}
                      onChange={e => {
                        vcStore.setVcLength(e.target.value);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>높이(단위 : mm)</Table.Cell>
                  <Table.Cell>
                    <Input
                      placeholder="차량 높이 기입"
                      value={vcStore.vcHeight}
                      onChange={e => {
                        vcStore.setVcHeight(e.target.value);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>사업자 등록번호</Table.HeaderCell>
                  <Table.HeaderCell>차량 등록번호</Table.HeaderCell>
                  <Table.HeaderCell>화물운송 자격 번호</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell
                    style={{
                      width: '300px',
                      height: '300px',
                      // display: 'flex',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      // flexDirection: 'column',
                    }}
                  >
                    <img id="compRegImg" style={{ width: '100%' }} />
                    <Button
                      positive
                      disabled={this.props.isAuth == true ? true : false}
                      onClick={() => {
                        this.props.fnAuthModalOpen();
                      }}
                    >
                      사업자등록번호 인증
                    </Button>
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      width: '300px',
                      height: '300px',
                      // display: 'flex',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      // flexDirection: 'column',
                    }}
                  >
                    <img id="vcRegImg" style={{ width: '100%' }} />
                    <div style={{ height: '36px' }} />
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      width: '300px',
                      height: '300px',
                      // display: 'flex',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      // flexDirection: 'column',
                    }}
                  >
                    <img id="logisCertiImg" style={{ width: '100%' }} />
                    <div style={{ height: '36px' }} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Input
                      disabled
                      style={{ width: '100%' }}
                      placeholder="사업자 등록번호"
                      defaultValue={accStore.compRegNo}
                      onChange={e => {
                        accStore.setCompRegNo(e.target.value);
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      style={{ width: '100%' }}
                      placeholder="차량 등록번호 기입"
                      value={accStore.vcRegNo}
                      onChange={e => {
                        accStore.setVcRegNo(e.target.value);
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      style={{ width: '100%' }}
                      placeholder="화물운송 자격 번호 기입"
                      value={accStore.logisCertiNo}
                      onChange={e => {
                        accStore.setLogisCertiNo(e.target.value);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <div>
              <Button
                disabled={!this.props.isAuth}
                positive
                onClick={() => {
                  this.props.fnUpdateInfoWithApproved();
                }}
              >
                승인하기
              </Button>
            </div>
            <div style={{ width: '100%', height: '50px' }} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default APVDetail;
