import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class PaymentStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable paymentToolList = [];
  @observable paymentTypeList = [];

  @observable paymentTo = 'IPYTO003';
  @observable paymentTy = 'IPYTY006';

  @action
  setPaymentToolList = value => {
    this.paymentToolList = value;
  };

  @action
  initPaymentToolList = () => {
    this.paymentToolList = [];
  };

  @action
  setPaymentTypeList = value => {
    this.paymentTypeList = value;
  };

  @action
  initPaymentTypeList = () => {
    this.paymentTypeList = [];
  };

  @action
  setPaymentTo = value => {
    this.paymentTo = value;
  };

  @action
  initPaymentTo = () => {
    this.paymentTo = 'IPYTO003';
  };

  @action
  setPaymentTy = value => {
    this.paymentTy = value;
  };

  @action
  initPaymentTy = () => {
    this.paymentTy = 'IPYTY006';
  };

  @action
  initForm = () => {
    this.initPaymentToolList();
    this.initPaymentTypeList();
    this.initPaymentTo();
    this.initPaymentTy();
  };
}

export default PaymentStore;
