export const STR_AGREEMENT = '약관동의';
export const STR_SELECTION_DRIVER_TYP = '회원 구분';
export const STR_DRIVER_REGIST_INFO = '기사님 정보 입력';
export const STR_VC_REGIST_INFO = '차량 정보 입력';
export const STR_UPLOAD_COMPREGIMAGE = '사업자등록증 첨부';
export const STR_UPLOAD_VCREGIMAGE = '차량등록증 첨부';
export const STR_UPLOAD_LOGIS_CERTI_IMAGE = '화물운송 자격증 첨부';
export const STR_ACC_INFO = '계좌번호 입력';
export const STR_ACC_NO = '계좌번호';
export const STR_ACC_SELECT = '은행';
export const STR_ACC_REGIST = '회원가입';
export const STR_ACC_INFO_FORM = '회원정보 입력';
export const STR_ACC_REGIST_BTN = '계정 만들기';

export const STR_ID = '아이디';
export const STR_NM = '이름';
export const STR_HP_NO = '연락처';
export const STR_PW = '비밀번호';
export const STR_CURRENT_PW = '기존 비밀번호';
export const STR_NEW_PW = '새 비밀번호';
export const STR_PW_CHECK = '비밀번호 확인';
export const STR_MODIFY_PW = '비밀번호 변경';
export const STR_LOGIN = '로그인';
export const STR_CURRENT_USER = '회원현황';
export const STR_USER_APV = '회원승인';
export const STR_ADDRESS = '주소';
export const STR_DEPARTURE_ADD_SEARCH = '상차지 주소 검색';
export const STR_DEPARTURE_DETAIL_ADD_SEARCH = '상차지 상세주소';
export const STR_ARRIVE_ADD_SEARCH = '하차지 주소 검색';
export const STR_ARRIVE_DETAIL_ADD_SEARCH = '하차지 상세주소';
export const STR_WAYPOINTS_ADD = '경유지';
export const STR_ADD_SEARCH = '주소검색';
export const STR_DETAIL_ADD = '상세주소';
export const STR_CAR_CNT = '차량대수';

export const STR_WAYPOINT_ADD_SEARCH_1 = '경유지1 주소 검색';
export const STR_WAYPOINT_ADD_SEARCH_2 = '경유지2 주소 검색';
export const STR_WAYPOINT_ADD_SEARCH_3 = '경유지3 주소 검색';
export const STR_SELECT_LOADING_WAY = '상차 방법 선택';
export const STR_SELECT_UNLOADING_WAY = '하차 방법 선택';

export const STR_ORDER_INFO = '운송정보 입력';
export const STR_ORDER_INFO_UPDATE = '운송정보 수정';

export const STR_ALL = '전체';
export const STR_RESERVED = '예약대기';
export const STR_RESERVED_COMPLETE = '예약완료';
export const STR_ON_TRANSPORTING = '운송중';
export const STR_COMPLETE_TRANSPORTING = '운송완료';

export const STR_SAVE = '저장';
export const STR_CANCEL = '취소';
export const STR_OK = '확인';
export const STR_CLOSE = '닫기';
export const STR_STAFF_PW = '관리자비밀번호 입력';
export const STR_ALERT = '알림';
export const STR_EMAIL = '이메일';
export const STR_EMAIL_INPUT = '이메일 주소 입력';
export const STR_EMAIL_SELECT = '이메일 선택';

export const STR_QUESTION_TRUCKER = '트럭커 문의사항';
export const STR_QUESTION_CUSTOMER = '화주 문의사항';
export const STR_COMP_AUTH = '사업자번호 인증';
export const STR_BUSINESSES_NO = '사업자번호';
export const STR_PRESENTATIVE = '대표자명';
export const STR_BUSINESSES_START_DATE = '개업일자';
export const STR_COMP_NM = '법인명';
export const STR_COMP_HP = '대표번호';

export const STR_ORDERER_NM = '상호(성명)';
export const STR_ORDERER_HP = '전화번호';

export const STR_SEARCH_ID = '아이디 찾기';
export const STR_SEARCH_ID_RESULT = '아이디 찾기 결과';
export const STR_SEARCH_PW = '비밀번호 재설정';

export const STR_MOVE_MAIN = '메인으로 이동';

export const STR_SEN_1 = '로딩중입니다. 잠시만 기다려주세요.';
export const STR_SEN_2 = '아이디를 입력해주세요.';
export const STR_SEN_3 = '비밀번호를 입력해주세요.';
export const STR_SEN_3_1 = '비밀번호를 다시 한번 입력해주세요.';
export const STR_SEN_4 = '차량 톤수를 선택해주세요.';
export const STR_SEN_5 = '차량 구분을 선택해주세요.';
export const STR_SEN_6 = '차량 종류를 선택해주세요.';
export const STR_SEN_7 = '화물 운임을 입력해주세요.';
export const STR_SEN_8 = '화물 정보를 입력해주세요.';
export const STR_SEN_9 = '화물 중량을 입력해주세요.';
export const STR_SEN_10 = '상차지 주소를 입력해주세요.';
export const STR_SEN_11 = '상차지 상세주소를 입력해주세요.';
export const STR_SEN_12 = '하차지 주소를 입력해주세요.';
export const STR_SEN_13 = '하차지 상세주소를 입력해주세요.';
export const STR_SEN_14 = '경로 검색에 실패 했습니다. 관리자에 문의하세요.';
export const STR_SEN_15 = '화물운송 구분을 선택해 주세요.';
export const STR_SEN_16 = '상차방법을 선택해 주세요.';
export const STR_SEN_17 = '하차방법을 선택해 주세요.';
export const STR_SEN_18 = '상차일시를 선택해 주세요.';
export const STR_SEN_19 = '하자일시를 선택해 주세요.';
export const STR_SEN_20 = '비밀번호를 설정해 주세요.';
export const STR_SEN_21 = '결제수단 선택해 주세요.';
export const STR_SEN_22 = '결제방법 선택해 주세요.';
export const STR_SEN_23 = '법인명을 적어주세요.';
export const STR_SEN_24 = '숫자만 적어주세요.';
export const STR_SEN_25 = '회원 구분을 선택해 주세요.';
export const STR_SEN_26 = '대표번호를 적어주세요.';
export const STR_SEN_27 = '아이디를 적어주세요.';
export const STR_SEN_28 = '아이디 중복확인을 해주세요.';
export const STR_SEN_29 = '비밀번호를 적어주세요.';
export const STR_SEN_30 = '이메일을 입력해주세요.';
export const STR_SEN_31 = '계좌번호를 입력해주세요.';
export const STR_SEN_32 = '사업자 등록증을 첨부해주세요.';
export const STR_SEN_33 = '사용가능한 아이디 입니다.';
export const STR_SEN_34 = '사용할 수 없는 아이디 입니다.';
export const STR_SEN_35 = '상차시간은 현재시간보다 빠를수 없습니다.';
export const STR_SEN_36 = '하차시간은 상차시간보다 빠를수 없습니다.';
export const STR_SEN_37 = '비밀번호는 6자리 이상입니다.';
export const STR_SEN_38 = '비밀번호가 성공적으로 변경되었습니다.';
export const STR_SEN_39 =
  '비밀번호 변경에 실패 하였습니다. 다시 시도해 주세요.';
export const STR_SEN_40 = '이름을 입력해주세요.';
export const STR_SEN_41 = '연락처를 입력해주세요.';

export const STR_SEN_42 = '주문자 상호(성명)을 입력해주세요.';
export const STR_SEN_43 = '주문자 연락처를 입력해주세요.';

export const RECREATE_PHONE_NUMBER = '0312912934';

export const STR_YES = '예';
export const STR_NO = '아니오';
