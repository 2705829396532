import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR02,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import { Button, Icon, List, TextArea } from 'semantic-ui-react';
@inject(stores => ({
  vcStore: stores.VcStore,
}))
@observer
class ConsultingContainer extends Component {
  state = {
    mmsTxt:
      '발주처 : X \n' +
      '발주처 담당자 : X/X \n\n' +
      '●상차지\n' +
      'X\nX\n상차지 담당자 : X / X\n\n' +
      '●하차지\n' +
      'X\nX\n하차지 담당자 : X / X\n\n' +
      '차량 : X/X\n' +
      '화물정보 : X\n' +
      '중량 : X\n' +
      '특이사항 : X\n' +
      '상차시간 : X/X\n' +
      '하차시간 : X/X\n' +
      '결제방법 : X',
  };

  fninserText = value => {
    this.setState({
      mmsTxt: value,
    });
  };

  fnCopyText = () => {
    let content = document.getElementById('mmsTextArea').value;
    window.navigator.clipboard.writeText(content).then(res => {
      alert('복사완료');
    });
  };
  render() {
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          background: COLOR_GRAY_GR02,
          overflowY: 'scroll',
        }}
      >
        <div style={{ width: '90%', margin: '0 auto' }}>
          <div
            style={{
              width: '49%',
              height: 'calc(100vh - 180px)',
              background: COLOR_WHITE,
              borderRadius: '10px',
              float: 'left',
              marginRight: '2%',
              padding: '20px',
            }}
          >
            <h2>상담 메뉴얼</h2>
            <List bulleted>
              <List.Item
                style={{
                  color: COLOR_PRIMARY,
                  fontWeight: '700',
                  fontSize: '20px',
                }}
              >
                필수 항목(반드시 필요한 항목)
                <List.List style={{ color: COLOR_BLACK, fontSize: '16px' }}>
                  <List.Item>
                    <List.Header>상/하차지 주소 확인</List.Header>
                    <List.Description>
                      {' '}
                      - 하차지주소의 경우 상차지에서 전달 받는 경우 있음
                    </List.Description>
                  </List.Item>
                  <List.Item>발주처 전화번호 확인</List.Item>
                  <List.Item>차량톤수 및 종류 확인</List.Item>
                  <List.Item>화물정보 확인</List.Item>
                  <List.Item>상/하차일시 확인</List.Item>
                </List.List>
              </List.Item>
              <List.Item
                style={{
                  color: COLOR_GREEN_PRIMARY,
                  fontWeight: '700',
                  fontSize: '20px',
                  marginTop: '20px',
                }}
              >
                중요 항목(배차 사고를 줄일 수 있는 항목)
                <List.List style={{ color: COLOR_BLACK, fontSize: '16px' }}>
                  <List.Item>물건 중량확인</List.Item>
                  <List.Item>
                    <List.Header>특이사항 확인</List.Header>
                    <List.Description> - 상하차 도움 필요</List.Description>
                    <List.Description> - 차량 길이 및 높이</List.Description>
                    <List.Description> - 그 외 화주 요구 사항</List.Description>
                  </List.Item>
                  <List.Item>상/하차지 담당자 번호 확인</List.Item>
                </List.List>
              </List.Item>
              <List.Item
                style={{
                  color: COLOR_GRAY_GR06,
                  fontWeight: '700',
                  fontSize: '20px',
                  marginTop: '20px',
                }}
              >
                선택 항목(알고 있으면 좋은 항목)
                <List.List style={{ color: COLOR_BLACK, fontSize: '16px' }}>
                  <List.Item>발주처 상호명 확인</List.Item>
                  <List.Item>발주처 담당자 이름 확인</List.Item>
                  <List.Item>상/하차지 상호명 확인</List.Item>
                  <List.Item>상/하차지 담당자 이름 확인</List.Item>
                  <List.Item>
                    <List.Header>결제방법 확인</List.Header>
                    <List.Description>
                      {' '}
                      - 카드결제 요청 고객 확인
                    </List.Description>
                    <List.Description>
                      {' '}
                      - 선/착불의 경우 선불 혹은 착불인지 확인
                    </List.Description>
                  </List.Item>
                </List.List>
              </List.Item>
            </List>
          </div>
          <div
            style={{
              width: '49%',
              height: 'calc(100vh - 180px)',
              background: COLOR_WHITE,
              borderRadius: '10px',
              overflow: 'hidden',
              marginLeft: '10%',
              marginTop: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                flexDirection: 'column',
              }}
            >
              <div style={{ marginBottom: '20px' }}>
                <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  메세지 입력창
                </p>
              </div>
              <TextArea
                style={{ width: '600px', height: '600px', resize: 'none' }}
                id="mmsTextArea"
                value={this.state.mmsTxt}
                onChange={() => this.fninserText()}
              ></TextArea>
              <Button
                style={{ marginTop: '20px' }}
                positive
                onClick={() => {
                  this.fnCopyText();
                }}
              >
                복사
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsultingContainer;
