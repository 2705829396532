import React, { Component, Fragment } from 'react';
import {
  Button,
  Modal,
  Form,
  Radio,
  Select,
  Input,
  Checkbox,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import DaumPostcode from 'react-daum-postcode';
import {
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR07,
  COLOR_GREEN_SECOND,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import {
  STR_ID,
  STR_SEN_2,
  STR_PW,
  STR_SEN_3,
  STR_ADD_SEARCH,
  STR_ACC_NO,
  STR_ACC_INFO,
  STR_ACC_SELECT,
  STR_ACC_REGIST,
  STR_ACC_INFO_FORM,
  STR_EMAIL,
  STR_EMAIL_INPUT,
  STR_EMAIL_SELECT,
  STR_SELECTION_DRIVER_TYP,
  STR_UPLOAD_COMPREGIMAGE,
  STR_ACC_REGIST_BTN,
  STR_NM,
  STR_HP_NO,
  STR_COMP_NM,
  STR_COMP_HP,
  STR_SEN_23,
  STR_SEN_24,
  STR_SEN_25,
  STR_SEN_26,
  STR_SEN_27,
  STR_SEN_28,
  STR_SEN_29,
  STR_SEN_30,
  STR_SEN_31,
  STR_SEN_32,
  STR_SEN_33,
  STR_SEN_34,
} from '../../common/Terms';
import {
  API_SELECT_COMCD,
  API_SELECT_SYSCD_CUSTOMER,
} from '../../common/ApiAddress/CommonAPI';
import {
  API_INSERT_CUSTOMER,
  API_GENERATE_NICD_ACCESS_TOKEN,
  API_CHECK_DUPLICATE_CUSTOMER_ID,
} from '../../common/ApiAddress/AccountAPI';
import CommonAlert from '../../Component/Common/CommonAlert';
import MainFooter from '../../Component/Common/MainFooter';
import AgreementModal from './AgreementModal';
import { ReactComponent as LogoBig } from '../../assets/icon/logo_long.svg';
@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class RegistContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addModal: false,
      eamilCdInput: false,
      compRegImage: null,
      customerNm: null,
      hpNo: null,
      idDupCheck: false,
      tempCusomerId: null,
      agreeTotal: false,
      agree1: false,
      agree2: false,
      agree3: false,
    };
  }
  componentDidMount() {
    const { accStore } = this.props;
    window.addEventListener('message', this.receiveMessage, false);
    this.fnSelectCustomerCd();
    //this.fnSelectEmailCd();
    this.fnSelectBankInfoList();
  }

  //회원구분 찾기(시스템코드)
  fnSelectCustomerCd = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IACCU';
    axios({
      url: API_SELECT_SYSCD_CUSTOMER,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            commonStore.setCustomerCdList(response.data.data);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //이메일구분 찾기(시스템코드)
  fnSelectEmailCd = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IACEM';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let emailcdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let emailCd = {};

              emailCd.key = response.data.data[i].sysCd;
              emailCd.value = response.data.data[i].sysCdNm;
              emailCd.text = response.data.data[i].sysCdNm;
              //emailCd.sysCdIdx = response.data.data[i].sysCdIdx;
              emailcdList.push(emailCd);
            }
            commonStore.setEmailCdList(emailcdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectBankInfoList = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IPYBN';
    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let backList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let accCd = {};
              accCd.key = response.data.data[i].sysCd;
              accCd.value = response.data.data[i].sysCd;
              accCd.text = response.data.data[i].sysCdNm;
              //accCd.sysCdIdx = response.data.data[i].sysCdIdx;
              backList.push(accCd);
            }
            commonStore.setBankInfoList(backList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOpenAddeModal = () => {
    this.setState({
      addModal: !this.state.addModal,
    });
  };

  //주소 검색 완료
  fnHandleCompleteAdd = data => {
    const { accStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    this.setState({
      addModal: !this.state.addModal,
    });
    accStore.setBasicAdd(fullAddress);
  };

  //상세주소
  fnsetDeailAdd = value => {
    const { accStore } = this.props;
    accStore.setDetailAdd(value);
  };

  fnOnchangeCustomerCd = value => {
    const { accStore } = this.props;
    accStore.setCustomerCd(value);
  };

  handleChangeCustomerCd = (e, { value }) => {
    this.fnOnchangeCustomerCd(value);
  };

  fnSetEmailCd = (e, data) => {
    const { accStore } = this.props;

    if (data.value == '직접입력') {
      accStore.initEmailCd();
      this.setState({
        eamilCdInput: false,
      });
    } else {
      accStore.setEmailCd(data.value);
      this.setState({
        eamilCdInput: true,
      });
    }
  };

  fnSetAccCd = (e, data) => {
    const { accStore } = this.props;
    accStore.setAccCd(data.value);
  };

  //회원가입
  fnRegist = () => {
    const { accStore } = this.props;
    let that = this;
    this.setState({
      loading: true,
    });
    const headers = {
      Accept: 'application/json',
      'content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    formData.append('customerId', accStore.customerId);
    formData.append('pw', accStore.pw);
    formData.append('customerCd', accStore.customerCd);
    formData.append('email', accStore.email);
    //formData.append('email', accStore.email + '@' + accStore.emailCd);
    formData.append('accNo', accStore.accNo);
    formData.append('accCd', accStore.accCd);
    formData.append('basicAdd', accStore.basicAdd);
    formData.append('detailAdd', accStore.detailAdd);
    formData.append('staffCd', accStore.customerCd);
    formData.append('compRegImage', this.state.compRegImage);
    formData.append('deviceToken', null);
    formData.append('hpNo', accStore.hpNo);
    formData.append('customerNm', accStore.customerNm);
    let validation = this.fnValidation();

    if (validation) {
      axios({
        url: API_INSERT_CUSTOMER,
        method: 'post',
        data: formData,
        headers: headers,
      })
        .then(function (response) {
          if (response.data.resultCode == 100) {
            that.props.history.push('/regist/return/');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //nice accessToken 발급
  fnGetAccessToken = () => {
    let that = this;
    axios({
      url: API_GENERATE_NICD_ACCESS_TOKEN,
      method: 'post',
    })
      .then(function (response) {
        if (response.data.dataHeader.GW_RSLT_CD == '1200') {
          that.fnConnetCheckPlus();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //nice 모듈 연결
  fnConnetCheckPlus = () => {
    let that = this;
    const headers = {
      'Access-Control-Allow-Origin': '*',
    };
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'checkplus_main',
      method: 'post',
      headers: headers,
    }).then(function (response) {
      document.getElementById('hideNice').innerHTML = response.data;
      if (response.status == 200) {
        that.fnPopup(response.data);
      }
    });
  };

  //팝업 오픈
  fnPopup = () => {
    window.open(
      '',
      'popupChk',
      'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
    );
    document.form_chk.action =
      'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
    document.form_chk.target = 'popupChk';
    document.form_chk.submit();
  };

  receiveMessage = async e => {
    const { accStore } = this.props;
    if (e.data.success == 'Y') {
      accStore.setCustomerNm(e.data.customerNm);
      accStore.setHpNo(e.data.hpNo);
    }
  };

  //아이디 입력(법인사업자)
  fnSetCustomerId = value => {
    const { accStore } = this.props;
    let customerId = value.replace(/[^a-z0-9]+$/gi, '');
    accStore.setCustomerId(customerId);
    if (value != this.state.tempCusomerId) {
      this.setState({
        idDupCheck: false,
      });
    }
  };

  //대표번호 입력(법인사업자)
  fnSetHpNo = value => {
    const { accStore } = this.props;
    let hpNo = value.replace(/[^0-9]/g, '');
    accStore.setHpNo(hpNo);
  };

  //이메일입력
  fnSetEmail = value => {
    const { accStore } = this.props;
    //let email = value.replace(/[^a-z0-9]+$/gi, '');
    accStore.setEmail(value);
  };

  //이메일 직접입력
  fnSetEmailCdInput = value => {
    const { accStore } = this.props;
    let emailCd = value.replace(/[^a-z0-9.]+$/gi, '');
    accStore.setEmailCd(emailCd);
  };

  //계좌번호입력
  fnSetAccNo = value => {
    const { accStore } = this.props;
    let accNo = value.replace(/[^0-9]/g, '');
    accStore.setAccNo(accNo);
  };

  //회원가입 유효성 검사
  fnValidation = () => {
    const { commonStore, accStore } = this.props;
    if (accStore.customerCd == null || accStore.customerCd == '') {
      commonStore.commonAlertOpen(STR_SEN_25);
      return false;
    }
    if (accStore.customerNm == null || accStore.customerNm == '') {
      commonStore.commonAlertOpen(STR_SEN_23);
      return false;
    }

    if (accStore.hpNo == null || accStore.hpNo == '') {
      commonStore.commonAlertOpen(STR_SEN_26);
      return false;
    }

    if (accStore.customerId == null || accStore.customerId == '') {
      commonStore.commonAlertOpen(STR_SEN_27);
      return false;
    }

    if (this.state.idDupCheck == null || this.state.idDupCheck == '') {
      commonStore.commonAlertOpen(STR_SEN_28);
      return false;
    }

    if (accStore.pw == null || accStore.pw == '') {
      commonStore.commonAlertOpen(STR_SEN_29);
      return false;
    }

    if (accStore.email == null || accStore.email == '') {
      commonStore.commonAlertOpen(STR_SEN_30);
      return false;
    }

    // if (accStore.emailCd == null || accStore.emailCd == '') {
    //   commonStore.commonAlertOpen(STR_SEN_30);
    //   return false;
    // }

    if (accStore.accNo == null || accStore.accNo == '') {
      commonStore.commonAlertOpen(STR_SEN_31);
      return false;
    }

    if (accStore.accCd == null || accStore.accCd == '') {
      commonStore.commonAlertOpen(STR_SEN_31);
      return false;
    }

    if (this.state.compRegImage == null || this.state.compRegImage == '') {
      commonStore.commonAlertOpen(STR_SEN_32);
      return false;
    }

    if (this.state.agree1 != true) {
      commonStore.commonAlertOpen('서비스 이용약관에 동의해주세요.');
      return false;
    }

    if (this.state.agree2 != true) {
      commonStore.commonAlertOpen(
        '개인(신용)정보의 수집, 이용에 관한 사항에 동의해주세요.',
      );
      return false;
    }

    if (this.state.agree3 != true) {
      commonStore.commonAlertOpen(
        '개인(신용)정보의 제 3자 제공에 관한 사항에 동의해주세요.',
      );
      return false;
    }

    return true;
  };

  //아이디 중복체크
  fnCustomerIdDupCheck = () => {
    const { commonStore, accStore } = this.props;
    let that = this;
    let params = {};
    params.customerId = accStore.customerId;

    if (accStore.customerId == null || accStore.customerId == '') {
      commonStore.commonAlertOpen(STR_SEN_27);
      return false;
    }

    axios({
      url: API_CHECK_DUPLICATE_CUSTOMER_ID,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data == null) {
            commonStore.commonAlertOpen(STR_SEN_33);
            that.setState({
              idDupCheck: true,
              tempCusomerId: accStore.customerId,
            });
          } else {
            commonStore.commonAlertOpen(STR_SEN_34);
            that.setState({
              idDupCheck: false,
              tempCusomerId: null,
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { accStore, commonStore } = this.props;
    return (
      <Fragment>
        <div
          style={{
            width: '100%',
            background: '#F5F5F5',
            height: '100vh',
          }}
        >
          <div
            style={{
              width: '510px',
              height: '100vh',
              padding: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              margin: '0 auto',
              paddingTop: '80px',
            }}
          >
            <div style={{ width: '100%' }}>
              <LogoBig
                style={{
                  display: 'block',
                  marginBottom: '66px',
                }}
              />
            </div>
            <div
              style={{
                width: '100%',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  color: COLOR_GRAY_GR07,
                  fontSize: '26px',
                  fontWeight: '700',
                }}
              >
                {STR_ACC_REGIST}
              </p>
            </div>
            <Form
              style={{
                marginTop: '10px',
                width: '600px',
              }}
            >
              <Form.Field style={{ marginBottom: '30px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                    color: COLOR_GRAY_GR07,
                  }}
                >
                  {STR_SELECTION_DRIVER_TYP}
                </label>
                {commonStore.customerCdList.length > 0
                  ? commonStore.customerCdList.map((data, i) => (
                      <Radio
                        key={data.sysCd}
                        //defaultChecked
                        style={{
                          width: '20%',
                          float: 'left',
                          color: COLOR_GRAY_GR07,
                        }}
                        label={data.sysCdNm}
                        name="customerCdGroup"
                        value={data.sysCd}
                        checked={accStore.customerCd == data.sysCd}
                        onChange={this.handleChangeCustomerCd}
                      />
                    ))
                  : null}
              </Form.Field>
              {/* 이름 */}
              {accStore.customerCd == 'IACCU003' ? (
                <Form.Field style={{ width: '100%', marginBottom: '24px' }}>
                  <label style={{ color: COLOR_GRAY_GR07 }}>
                    {STR_COMP_NM}
                  </label>
                  <div style={{ width: '100%' }}>
                    <input
                      value={accStore.customerNm || ''}
                      type="text"
                      style={{ width: '100%', height: '46px' }}
                      placeholder={STR_SEN_23}
                      onChange={e => {
                        accStore.setCustomerNm(e.target.value);
                      }}
                    />
                  </div>
                </Form.Field>
              ) : accStore.customerCd == 'IACCU002' ? (
                <Form.Field style={{ width: '100%', marginBottom: '24px' }}>
                  <label style={{ color: COLOR_GRAY_GR07 }}>{STR_NM}</label>
                  <div style={{ width: '100%' }}>
                    <input
                      //disabled
                      placeholder={'이름을 적어주세요.'}
                      value={accStore.customerNm || ''}
                      type="text"
                      style={{ width: '100%', height: '46px' }}
                      onChange={e => {
                        accStore.setCustomerNm(e.target.value);
                      }}
                    />
                  </div>
                  {/* <div
                    style={{
                      overflow: 'hidden',
                      width: '30%',
                    }}
                  >
                    <div
                      onClick={() => {
                        this.fnGetAccessToken();
                      }}
                      style={{
                        width: '100%',
                        height: '46px',
                        background: COLOR_GREEN_SECOND,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '16px',
                        color: COLOR_WHITE,
                        cursor: 'pointer',
                        borderRadius: '4px',
                        fontWeight: '700',
                      }}
                    >
                      본인인증
                    </div>
                  </div> */}
                </Form.Field>
              ) : null}

              {/* 연락처 */}

              {accStore.customerCd == 'IACCU003' ? (
                <Form.Field
                  style={{
                    width: '100%',
                    marginBottom: '24px',
                  }}
                >
                  <label style={{ color: COLOR_GRAY_GR07 }}>
                    {STR_COMP_HP}
                  </label>
                  <input
                    style={{ height: '46px' }}
                    value={accStore.hpNo || ''}
                    type="text"
                    placeholder={STR_SEN_24}
                    onChange={e => {
                      this.fnSetHpNo(e.target.value);
                    }}
                  />
                </Form.Field>
              ) : accStore.customerCd == 'IACCU002' ? (
                <Form.Field style={{ width: '100%', marginBottom: '24px' }}>
                  <label style={{ color: COLOR_GRAY_GR07 }}>{STR_HP_NO}</label>
                  <input
                    //disabled
                    value={accStore.hpNo || ''}
                    type="text"
                    style={{ height: '46px' }}
                    placeholder={STR_SEN_24}
                    onChange={e => {
                      this.fnSetHpNo(e.target.value);
                    }}
                  />
                </Form.Field>
              ) : null}

              <Form.Field style={{ width: '100%', marginBottom: '24px' }}>
                <label style={{ color: COLOR_GRAY_GR07 }}>
                  {STR_ID} <span>(영문, 숫자 만 입력)</span>
                </label>
                <div style={{ float: 'left', width: '66%', marginRight: '4%' }}>
                  <input
                    placeholder={STR_SEN_2}
                    onChange={e => this.fnSetCustomerId(e.target.value)}
                    value={accStore.customerId || ''}
                    type="text"
                    style={{ textTransform: 'lowercase', height: '46px' }}
                  />
                </div>
                <div style={{ overflow: 'hidden', width: '30%' }}>
                  <div
                    onClick={() => {
                      if (this.state.idDupCheck == false) {
                        this.fnCustomerIdDupCheck();
                      }
                    }}
                    style={{
                      width: '100%',
                      height: '46px',
                      background: this.state.idDupCheck
                        ? COLOR_GRAY_GR05
                        : COLOR_GREEN_SECOND,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '16px',
                      color: COLOR_WHITE,
                      cursor: 'pointer',
                      borderRadius: '4px',
                      fontWeight: '700',
                    }}
                  >
                    {this.state.idDupCheck == false ? '중복확인' : '완료'}
                  </div>
                </div>
              </Form.Field>
              <Form.Field style={{ width: '100%', marginBottom: '24px' }}>
                <label style={{ color: COLOR_GRAY_GR07 }}>{STR_PW}</label>
                <input
                  placeholder={STR_SEN_3}
                  onChange={e => accStore.setPw(e.target.value)}
                  value={accStore.pw || ''}
                  type="password"
                  style={{ height: '46px' }}
                />
              </Form.Field>
              <Form.Field style={{ width: '100%', marginBottom: '24px' }}>
                <label style={{ color: COLOR_GRAY_GR07 }}>{STR_EMAIL}</label>
                <input
                  style={{ width: '100%', height: '46px' }}
                  placeholder={STR_EMAIL_INPUT}
                  onChange={e => this.fnSetEmail(e.target.value)}
                  value={accStore.email || ''}
                  type="text"
                />
                {/* <span style={{ fontSize: '18pt', lineHeight: '44px' }}> @ </span>
              <input
                style={{ width: '40%', height: '46px' }}
                placeholder={STR_EMAIL_INPUT}
                onChange={e => this.fnSetEmailCdInput(e.target.value)}
                value={accStore.emailCd || ''}
                type="text"
                readOnly={this.state.eamilCdInput}
              /> */}
                {/* <Select
                  style={{ width: '30%', marginLeft: '2%', height: '46px' }}
                  placeholder={STR_EMAIL_SELECT}
                  options={commonStore.emailCdList}
                  onChange={(e, data) => this.fnSetEmailCd(e, data)}
                /> */}
              </Form.Field>
              <Form.Field style={{ width: '100%', marginBottom: '24px' }}>
                <label style={{ color: COLOR_GRAY_GR07 }}>
                  {STR_ACC_NO}
                  {'  '}
                  <span>(숫자만입력 '-'제외)</span>
                </label>
                <Select
                  style={{ maxWidth: '141px', minWidth: '141px' }}
                  placeholder={STR_ACC_SELECT}
                  options={commonStore.bankInfoList}
                  onChange={(e, data) => this.fnSetAccCd(e, data)}
                />
                <input
                  style={{ width: '68.5%', marginLeft: '2%' }}
                  placeholder={STR_ACC_INFO}
                  onChange={e => this.fnSetAccNo(e.target.value)}
                  value={accStore.accNo || ''}
                  type="text"
                />
              </Form.Field>
              <Form.Field style={{ width: '100%', marginBottom: '27px' }}>
                <label style={{ color: COLOR_GRAY_GR07 }}>
                  {STR_UPLOAD_COMPREGIMAGE}
                </label>
                <input
                  style={{ width: '100%' }}
                  onChange={e => {
                    this.setState({
                      compRegImage: e.target.files[0],
                    });
                  }}
                  type="file"
                />
              </Form.Field>
            </Form>
            <div style={{ width: '100%' }}>
              <ul
                style={{
                  listStyle: 'none',
                  alignItems: 'center',
                  paddingLeft: '0px',
                }}
              >
                <li style={{ marginBottom: '8px' }}>
                  <Checkbox
                    //label="전체동의"
                    //defaultChecked={this.state.agreeTotal}
                    checked={this.state.agreeTotal}
                    onChange={(e, data) => {
                      if (data.checked == true) {
                        this.setState({
                          agreeTotal: true,
                          agree1: true,
                          agree2: true,
                          agree3: true,
                        });
                      } else {
                        this.setState({
                          agreeTotal: false,
                          agree1: false,
                          agree2: false,
                          agree3: false,
                        });
                      }
                    }}
                  />
                  <span
                    style={{
                      marginLeft: '10px',
                      position: 'relative',
                      top: '-3px',
                      cursor: 'pointer',
                    }}
                  >
                    전체동의
                  </span>
                </li>
                <li style={{ marginBottom: '8px' }}>
                  <Checkbox
                    //label="(필수) 서비스 이용약관동의"
                    //defaultChecked={this.state.agree1}
                    checked={this.state.agree1}
                    onChange={(e, data) => {
                      if (data.checked == true) {
                        if (
                          this.state.agree2 == true &&
                          this.state.agree3 == true
                        ) {
                          this.setState({
                            agree1: true,
                            agreeTotal: true,
                          });
                        } else {
                          this.setState({
                            agree1: true,
                          });
                        }
                      } else {
                        this.setState({
                          agree1: false,
                          agreeTotal: false,
                        });
                      }
                    }}
                  />
                  <span
                    onClick={() => accStore.agreementModalOpen(1)}
                    style={{
                      marginLeft: '10px',
                      position: 'relative',
                      top: '-3px',
                      cursor: 'pointer',
                    }}
                  >
                    (필수) 서비스 이용약관동의
                  </span>
                </li>
                <li style={{ marginBottom: '8px' }}>
                  <Checkbox
                    //label="(필수) 개인(신용)정보의 수집, 이용에 관한 사항"
                    //defaultChecked={this.state.agree2}
                    checked={this.state.agree2}
                    onChange={(e, data) => {
                      if (data.checked == true) {
                        if (
                          this.state.agree1 == true &&
                          this.state.agree3 == true
                        ) {
                          this.setState({
                            agree2: true,
                            agreeTotal: true,
                          });
                        } else {
                          this.setState({
                            agree2: true,
                          });
                        }
                      } else {
                        this.setState({
                          agree2: false,
                          agreeTotal: false,
                        });
                      }
                    }}
                  />
                  <span
                    onClick={() => accStore.agreementModalOpen(2)}
                    style={{
                      marginLeft: '10px',
                      position: 'relative',
                      top: '-3px',
                      cursor: 'pointer',
                    }}
                  >
                    (필수) 개인(신용)정보의 수집, 이용에 관한 사항
                  </span>
                </li>
                <li style={{ marginBottom: '8px' }}>
                  <Checkbox
                    //label="(필수) 개인(신용)정보의 제 3자 제공에 관한 사항"
                    //defaultChecked={this.state.agree3}
                    checked={this.state.agree3}
                    onChange={(e, data) => {
                      if (data.checked == true) {
                        if (
                          this.state.agree1 == true &&
                          this.state.agree2 == true
                        ) {
                          this.setState({
                            agree3: true,
                            agreeTotal: true,
                          });
                        } else {
                          this.setState({
                            agree3: true,
                          });
                        }
                      } else {
                        this.setState({
                          agree3: false,
                          agreeTotal: false,
                        });
                      }
                    }}
                  />
                  <span
                    onClick={() => accStore.agreementModalOpen(3)}
                    style={{
                      marginLeft: '10px',
                      position: 'relative',
                      top: '-3px',
                      cursor: 'pointer',
                    }}
                  >
                    (필수) 위치기반서비스 이용약관
                  </span>
                </li>
              </ul>
            </div>
            <div style={{ width: '100%', marginTop: '10px' }}>
              <div
                onClick={() => {
                  this.fnRegist();
                }}
                style={{
                  width: '100%',
                  height: '46px',
                  background: COLOR_GREEN_SECOND,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '16px',
                  color: COLOR_WHITE,
                  cursor: 'pointer',
                  borderRadius: '4px',
                  fontWeight: '700',
                }}
              >
                {STR_ACC_REGIST_BTN}
              </div>
            </div>

            <div id="hideNice" style={{ display: 'none' }} />
          </div>

          <CommonAlert />
          <AgreementModal />
        </div>
        <MainFooter />
      </Fragment>
    );
  }
}

export default withRouter(RegistContainer);
