import React, { Component } from 'react';
import { Button, Modal, Table, Form } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
  COLOR_BLACK,
} from '../../common/Color';
import { STR_SAVE, STR_CANCEL } from '../../common/Terms';
import CommonInput from '../../Component/Common/CommonInput';
@inject(stores => ({
  favoStore: stores.FavoriteStore,
}))
@observer
class FavoriteInsertModal extends Component {
  componentDidMount() {
    this.props.fnSelecteFavoCateList();
  }
  render() {
    const { favoStore } = this.props;
    return (
      <Modal
        size="small"
        open={this.props.favoInsertModal}
        onClose={() => this.props.fnFavoInsertModalClose()}
        style={{ height: '510px' }}
        closeOnDimmerClick={false}
      >
        <Modal.Header>즐겨찾기 추가</Modal.Header>

        <Modal.Content style={{ height: '350px' }}>
          <div
            className="favoriteInsert"
            style={{
              width: '35%',
              height: '350px',
              overflow: 'scroll',
              padding: '10px',
              overflowX: 'hidden',
              float: 'left',
            }}
          >
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>순번</Table.HeaderCell>
                  <Table.HeaderCell>분류명</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {favoStore.cateList == null || favoStore.cateList.length == 0 ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="3" textAlign="center">
                      등록된 분류가 없습니다.
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : (
                <Table.Body>
                  {favoStore.cateList.map((data, i) => (
                    <React.Fragment>
                      <Table.Row
                        onClick={() => favoStore.setSelectedCate(data)}
                      >
                        <Table.Cell
                          style={{
                            backgroundColor:
                              favoStore.selectedCate != null &&
                              favoStore.selectedCate.cateNo === data.cateNo
                                ? COLOR_GREEN_PRIMARY
                                : COLOR_WHITE,
                            color:
                              favoStore.selectedCate != null &&
                              favoStore.selectedCate.cateNo === data.cateNo
                                ? COLOR_WHITE
                                : COLOR_BLACK,
                          }}
                        >
                          {data.cateNo}
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            backgroundColor:
                              favoStore.selectedCate != null &&
                              favoStore.selectedCate.cateNo === data.cateNo
                                ? COLOR_GREEN_PRIMARY
                                : COLOR_WHITE,
                            color:
                              favoStore.selectedCate != null &&
                              favoStore.selectedCate.cateNo === data.cateNo
                                ? COLOR_WHITE
                                : COLOR_BLACK,
                          }}
                        >
                          {data.cateNm}
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  ))}
                </Table.Body>
              )}
            </Table>
            <Button
              positive
              onClick={() => {
                this.props.fnOpenInsertCateModal();
              }}
            >
              새 분류 추가
            </Button>
          </div>
          <div style={{ width: '65%', overflow: 'hidden', padding: '10px' }}>
            <Form>
              <Form.Field>
                <label>즐겨찾기 명</label>
                <CommonInput
                  placeholder={'즐겨찾기 명을 적어주세요'}
                  defaultValue={favoStore.favoNm}
                  onChange={favoStore.setFavoNm}
                />
              </Form.Field>
            </Form>
          </div>
        </Modal.Content>
        <div style={{ float: 'right', marginTop: '30px', marginRight: '20px' }}>
          <Button negative onClick={() => this.props.fnFavoInsertModalClose()}>
            {STR_CANCEL}
          </Button>
          <Button
            positive
            onClick={() => this.props.fnInsertFavorite()}
            disabled={
              favoStore.selectedCate == null ||
              favoStore.favoNm == null ||
              favoStore.favoNm == ''
                ? true
                : false
            }
          >
            {STR_SAVE}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default FavoriteInsertModal;
