import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  armStore: stores.AlarmTalkStore,
}))
@observer
class AlarmTalkList extends Component {
  render() {
    const { armStore } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>템플릿 코드</Table.HeaderCell>
            <Table.HeaderCell>아이디</Table.HeaderCell>
            <Table.HeaderCell>연락처</Table.HeaderCell>
            <Table.HeaderCell>발송성공</Table.HeaderCell>
            <Table.HeaderCell>발송일</Table.HeaderCell>
            <Table.HeaderCell>발송자</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {armStore.alarmTalkList == null ||
        armStore.alarmTalkList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                알림톡 발송 내역이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {armStore.alarmTalkList.map((data, i) => (
              <React.Fragment key={data.messageId}>
                <Table.Row onClick={() => this.props.fnViewOpen(data)}>
                  <Table.Cell>{data.tmpCode}</Table.Cell>
                  <Table.Cell>{data.rcvId}</Table.Cell>
                  <Table.Cell>{data.rcvHpNo}</Table.Cell>
                  <Table.Cell>
                    {data.resultCode == '0000' ? 'Y' : 'N'}
                  </Table.Cell>
                  <Table.Cell>{data.sendTime}</Table.Cell>
                  <Table.Cell>{data.senderId}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default AlarmTalkList;
