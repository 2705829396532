import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import DaumPostcode from 'react-daum-postcode';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {
  API_SELECT_COMCD,
  API_SELECT_VC_TYP_CD,
  API_SELECT_PAYMENT_TOOL_CD,
} from '../../../common/ApiAddress/CommonAPI';
import {
  API_SELECT_LOGIS_FEE,
  API_UPDATE_FREIGHT,
} from '../../../common/ApiAddress/FreightAPI';
import { API_SELECT_GROUP_LOGIS_STAFF_LIST } from '../../../common/ApiAddress/AccountAPI';
import {
  STR_DEPARTURE_ADD_SEARCH,
  STR_ARRIVE_ADD_SEARCH,
  STR_SEN_4,
  STR_SEN_5,
  STR_WAYPOINT_ADD_SEARCH_1,
  STR_WAYPOINT_ADD_SEARCH_2,
  STR_WAYPOINT_ADD_SEARCH_3,
  STR_SEN_8,
  STR_SELECT_LOADING_WAY,
  STR_SELECT_UNLOADING_WAY,
  STR_SEN_10,
  STR_SEN_12,
  STR_SEN_14,
  STR_SEN_15,
  STR_SEN_16,
  STR_SEN_17,
  STR_SEN_18,
  STR_SEN_19,
  STR_SEN_9,
  STR_SEN_21,
  STR_SEN_22,
} from '../../../common/Terms';

import Map from '../../Order/Map';
import OrderForm from '../../Order/OrderForm';
import SelectLoadingModal from '../../../Component/Order/SelectLoadingModal';
import SelectUnLoadingModal from '../../../Component/Order/SelectUnLoadingModal';
import CommonAlert from '../../../Component/Common/CommonAlert';
import CorfirmModalForUpdate from '../../../Component/Common/ConfirmModalForUpdate';
import CorfirmModalForCnacle from '../../../Component/Common/ConfirmModalForCancle';
@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
  payStore: stores.PaymentStore,
}))
@observer
class FreightManagementUpdateContainer extends Component {
  state = {
    //위경도
    lat: null,
    lng: null,
    //모달
    addDeparture: false,
    addArrival: false,
    addWaypoint1: false,
    addWaypoint2: false,
    addWaypoint3: false,
    loadingModal: false,
    unLoadingModal: false,

    //출발지,도착지, 경유지 위경도
    departureX: null,
    departureY: null,
    arrivalX: null,
    arrivalY: null,
    coordsWaypoints: [],

    //출발지, 도착지 법정코드
    startBcode: null,
    endBcode: null,
    wayPoint1Bcode: null,
    wayPoint2Bcode: null,
    wayPoint3Bcode: null,

    //입력form관련
    wayPointsNo: 1,
    editablePrice: true,

    //map
    defaultZoom: 10,

    //logisStaffInfoFlag
    logisStaffFlag: true,
    logisfeeFlag: false,
  };

  componentDidMount() {
    const { orderStore, vcStore, payStore } = this.props;
    this.fnSelectLogisCd();
    this.fnSelectLogisStaffList();

    this.fnSelectVcWeightCd();
    let sysCd = vcStore.vcWeightCd;
    let sysCdIdx = sysCd.substring(5, 8);
    this.fnSelectVcTypCd(sysCdIdx);

    this.fnSelectLoadingCd();

    this.fnSelectPaymentToolList();
    this.fnSelectPaymentTypeList(payStore.paymentTo);

    let departureCoord = orderStore.departureCoord;
    let departureCoordSpl = departureCoord.split(',');

    let arriveCoord = orderStore.arriveCoord;
    let arriveCoordSpl = arriveCoord.split(',');

    let coordsWaypoints = [];
    if (orderStore.selectedFreight.waypoint1Coord != null) {
      let waypoint1Coord = orderStore.selectedFreight.waypoint1Coord;
      let waypoint1CoordSpl = waypoint1Coord.split(',');
      waypoint1Coord = {
        wayPointX: waypoint1CoordSpl[0],
        wayPointY: waypoint1CoordSpl[1],
      };
      coordsWaypoints.push(waypoint1Coord);
    }

    if (orderStore.selectedFreight.waypoint2Coord != null) {
      let waypoint2Coord = orderStore.selectedFreight.waypoint2Coord;
      let waypoint2CoordSpl = waypoint2Coord.split(',');
      waypoint2Coord = {
        wayPointX: waypoint2CoordSpl[0],
        wayPointY: waypoint2CoordSpl[1],
      };
      coordsWaypoints.push(waypoint2Coord);
    }

    if (orderStore.selectedFreight.waypoint3Coord != null) {
      let waypoint3Coord = orderStore.selectedFreight.waypoint3Coord;
      let waypoint3CoordSpl = waypoint3Coord.split(',');
      waypoint3Coord = {
        wayPointX: waypoint3CoordSpl[0],
        wayPointY: waypoint3CoordSpl[1],
      };
      coordsWaypoints.push(waypoint3Coord);
    }

    this.setState({
      wayPointsNo: orderStore.wayPointsList.length + 1,
      departureX: departureCoordSpl[0],
      departureY: departureCoordSpl[1],
      arrivalX: arriveCoordSpl[0],
      arrivalY: arriveCoordSpl[1],
      coordsWaypoints: coordsWaypoints,
      startBcode: orderStore.selectedFreight.loadingBcode,
      endBcode: orderStore.selectedFreight.unloadingBcode,
      wayPoint1Bcode: orderStore.selectedFreight.waypoint1Bcode,
      wayPoint2Bcode: orderStore.selectedFreight.waypoint2Bcode,
      wayPoint3Bcode: orderStore.selectedFreight.waypoint3Bcode,
    });

    this.fnUpdateFreightCancleModalClose();

    setTimeout(this.fnGetPath, 500);
  }

  componentWillUnmount() {
    const { orderStore, vcStore, payStore } = this.props;
    orderStore.initForm();
    payStore.initForm();
    vcStore.initForOrder();
  }

  //화물구분 불러오기
  fnSelectLogisCd = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRDI';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let logisCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              logisCdList.push(params);
            }
            orderStore.setLogisCdList(logisCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량적재무게 타입 불러오기
  fnSelectVcWeightCd = () => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRWE';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let weightCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              params.sysCdIdx = response.data.data[i].sysCdIdx;
              weightCdList.push(params);
            }
            vcStore.setVcWeightCdList(weightCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량구분 타입 불러오기
  fnSelectVcTypCd = value => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRTY';
    if (value == '001') {
      params.sysCdIdx = value;
    } else if (value == '002') {
      params.sysCdIdx = value;
    } else {
      params.sysCdIdx = '';
    }
    axios({
      url: API_SELECT_VC_TYP_CD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let typCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              params.sysCdIdx = response.data.data[i].sysCdIdx;
              typCdList.push(params);
            }
            vcStore.setVcTypCdList(typCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //결제방법 불러오기
  fnSelectPaymentToolList = () => {
    const { payStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IPYTO';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let payToolList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              payToolList.push(params);
            }
            payStore.setPaymentToolList(payToolList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //그룹 내 스태프 리스트 불러오기
  fnSelectLogisStaffList = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.groupNo = sessionStorage.getItem('groupNo');

    axios({
      url: API_SELECT_GROUP_LOGIS_STAFF_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let logistStaffList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = {
                customerNm: response.data.data[i].customerNm,
                hpNo: response.data.data[i].hpNo,
              };
              params.text = response.data.data[i].customerNm;
              logistStaffList.push(params);
            }
            let params = {};
            params.value = 'insert';
            params.text = '직접입력';
            logistStaffList.push(params);
            commonStore.setLogisStaffList(logistStaffList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //결제수단 불러오기
  fnSelectPaymentTypeList = value => {
    const { payStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IPYTY';
    params.sysCdIdx = value;

    axios({
      url: API_SELECT_PAYMENT_TOOL_CD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let payTypeList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              payTypeList.push(params);
            }
            payStore.setPaymentTypeList(payTypeList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량중량구분 관련 함수들
  fnSetVcWeightCd = (e, data) => {
    const { vcStore, orderStore } = this.props;
    vcStore.setVcWeightCd(data.value);
    vcStore.initVcTypCd();
    let sysCdIdx = data.value.substring(5, 8);
    this.fnSelectVcTypCd(sysCdIdx);

    //화물중량 자동세팅
    if (data.value == 'IFRWE001') {
      orderStore.setFreightWeight('330');
    }
    if (data.value == 'IFRWE002') {
      orderStore.setFreightWeight('550');
    }
    if (data.value == 'IFRWE003') {
      orderStore.setFreightWeight('1100');
    }
    if (data.value == 'IFRWE004') {
      orderStore.setFreightWeight('1540');
    }
    if (data.value == 'IFRWE005') {
      orderStore.setFreightWeight('2750');
    }
    if (data.value == 'IFRWE006') {
      orderStore.setFreightWeight('3850');
    }
    if (data.value == 'IFRWE007') {
      orderStore.setFreightWeight('3850');
    }
    if (data.value == 'IFRWE008') {
      orderStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE009') {
      orderStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE010') {
      orderStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE011') {
      orderStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE012') {
      orderStore.setFreightWeight('8800');
    }
    if (data.value == 'IFRWE013') {
      orderStore.setFreightWeight('11000');
    }
    if (data.value == 'IFRWE014') {
      orderStore.setFreightWeight('15400');
    }
    if (data.value == 'IFRWE015') {
      orderStore.setFreightWeight('19800');
    }
    if (data.value == 'IFRWE016') {
      orderStore.setFreightWeight('27500');
    }
  };
  //차량구분 선택함수
  fnSetVcTypCd = value => {
    const { vcStore } = this.props;
    vcStore.setVcTypCd(value);
  };

  //상차지 주소 검색 완료
  fnHandleCompleteDeparture = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addDeparture: !this.state.addDeparture,
      startBcode: data.bcode,
    });
    orderStore.setDepartureAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'D');
  };

  //하차지 주소 검색 완료
  fnHandleCompleteArrive = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addArrival: !this.state.addArrival,
      endBcode: data.bcode,
    });
    orderStore.setArriveAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'A');
  };

  fnGetcoords = (value, typ, index) => {
    const { orderStore } = this.props;
    let that = this;
    axios({
      url: 'https://teamtrucker.co.kr/naver/geocode',
      method: 'get',
      params: {
        query: value,
      },
    })
      .then(function (response) {
        if (typ === 'D') {
          //상차지
          that.setState({
            departureX: response.data.items.addresses[0].x,
            departureY: response.data.items.addresses[0].y,
          });
          orderStore.setDepartureCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          //경유지
        } else if (typ === 'W') {
          let wayPoint = {};
          let wayPointX = response.data.items.addresses[0].x;
          let wayPointY = response.data.items.addresses[0].y;
          wayPoint.wayPointX = wayPointX;
          wayPoint.wayPointY = wayPointY;

          let wayPointList = [...that.state.coordsWaypoints];
          wayPointList.splice(index - 1, 1, wayPoint);
          that.setState({
            coordsWaypoints: wayPointList,
          });
          if (that.state.arrivalX != null) {
            that.fnGetPath();
          }
        } else {
          //하차지
          that.setState({
            arrivalX: response.data.items.addresses[0].x,
            arrivalY: response.data.items.addresses[0].y,
          });
          orderStore.setArriveCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          //경로 구하는 함수로 이동
          that.fnGetPath();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetPath = () => {
    const { orderStore } = this.props;
    let start = this.state.departureX + ',' + this.state.departureY;
    let goal = this.state.arrivalX + ',' + this.state.arrivalY;
    let waypointsList = [...this.state.coordsWaypoints];
    let waypoints = null;

    for (let i = 0; i < waypointsList.length; i++) {
      if (i == 0) {
        waypoints =
          waypointsList[i].wayPointX + ',' + waypointsList[i].wayPointY;
      }
      if (i >= 1) {
        waypoints =
          waypoints +
          '|' +
          waypointsList[i].wayPointX +
          ',' +
          waypointsList[i].wayPointY;
      }
    }

    let that = this;
    axios({
      url: 'https://teamtrucker.co.kr/naver/drive',
      method: 'get',
      params: {
        start: start,
        goal: goal,
        waypoints: waypoints == null ? null : waypoints,
      },
    })
      .then(function (response) {
        let distance = response.data.items[0].summary.distance * 0.001;
        let second = response.data.items[0].summary.duration * 0.001;
        second = second + 3600;
        let hour = parseInt(second / 3600);
        let min = parseInt((second % 3600) / 60);

        if (parseInt(distance) < 50) {
          that.setState({
            defaultZoom: 12,
          });
        }

        if (50 < parseInt(distance) && parseInt(distance) < 100) {
          that.setState({
            defaultZoom: 9,
          });
        }
        if (100 < parseInt(distance) && parseInt(distance) < 150) {
          that.setState({
            defaultZoom: 8,
          });
        }
        if (150 < parseInt(distance)) {
          that.setState({
            defaultZoom: 7,
          });
        }

        orderStore.setDistance(distance + 'km');
        if (hour > 0) {
          orderStore.setDuration(hour + '시간' + min + '분');
        } else {
          orderStore.setDuration(min + '분');
        }
        orderStore.setOrderPath(response.data.items[0].path);
      })
      .catch(function (error) {
        toast(error);
        console.log(error);
      });
  };

  fnOpenDepartureModal = () => {
    this.setState({
      addDeparture: !this.state.addDeparture,
    });
  };

  fnOpenArriveModal = () => {
    this.setState({
      addArrival: !this.state.addArrival,
    });
  };

  fnSetDepartureDetailAdd = value => {
    const { orderStore } = this.props;
    orderStore.setDepartureDetailAdd(value);
  };

  fnSetArriveDetailAdd = value => {
    const { orderStore } = this.props;
    orderStore.setArriveDetailAdd(value);
  };

  fnOnchangeLogisCd = value => {
    const { orderStore } = this.props;
    orderStore.setLogisCd(value);
  };

  //경유지 관련 함수
  //경유지 증가
  fnIncreaseWayPoint = () => {
    const { orderStore } = this.props;
    if (this.state.wayPointsNo <= 3) {
      orderStore.setWayPointsList(this.state.wayPointsNo, 'add', {});
      let freightInfo = orderStore.freightInfo;
      let addFreightInfo = null;
      if (freightInfo == null) {
        addFreightInfo = '경유지' + this.state.wayPointsNo + ' 특이사항 : ';
      } else {
        addFreightInfo =
          freightInfo + '\n경유지' + this.state.wayPointsNo + ' 특이사항 : ';
      }
      orderStore.setFreightInfo(addFreightInfo);
      this.setState({
        wayPointsNo: this.state.wayPointsNo + 1,
      });
    } else {
      alert('경유지는 최대 3개까지 추가 가능합니다.');
    }
  };

  //경유지 삭제
  fnDecreaseWayPoint = index => {
    const { orderStore } = this.props;
    orderStore.setWayPointsList(index, 'remove');
    this.setState({
      wayPointsNo: this.state.wayPointsNo - 1,
    });

    //위경도 리스트 삭제
    let spreadCoords = [...this.state.coordsWaypoints];
    spreadCoords.splice(index, 1);
    this.setState({
      coordsWaypoints: spreadCoords,
    });

    if (index == 0) {
      this.setState({
        wayPoint1Bcode: null,
      });
    }
    if (index == 1) {
      this.setState({
        wayPoint2Bcode: null,
      });
    }
    if (index == 2) {
      this.setState({
        wayPoint3Bcode: null,
      });
    }
    setTimeout(this.fnGetPath, 500);
  };

  //경유지 주소 검색 모달 오픈
  fnOpenWayPointModal = index => {
    if (index == 1) {
      this.setState({
        addWaypoint1: !this.state.addWaypoint1,
      });
    } else if (index == 2) {
      this.setState({
        addWaypoint2: !this.state.addWaypoint2,
      });
    } else if (index == 3) {
      this.setState({
        addWaypoint3: !this.state.addWaypoint3,
      });
    }
  };

  //경유지1 주소 검색 완료
  fnHandleCompleteWayPoint1 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint1: !this.state.addWaypoint1,
      wayPoint1Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(1, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 1);
  };

  //경유지2 주소 검색 완료
  fnHandleCompleteWayPoint2 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint2: !this.state.addWaypoint2,
      wayPoint2Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(2, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 2);
  };

  //경유지3 주소 검색 완료
  fnHandleCompleteWayPoint3 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint3: !this.state.addWaypoint3,
      wayPoint3Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(3, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 3);
  };

  //경유지 상세주소
  fnSetWayPointDetailAdd = (index, value) => {
    const { orderStore } = this.props;
    orderStore.setWayPointsDetailAdd(index, value);
  };

  //상하차 방법 불러오기
  fnSelectLoadingCd = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRLO';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let loadingCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              loadingCdList.push(params);
            }
            orderStore.setLoadingCdList(loadingCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOnchangeLoadingCd = value => {
    const { orderStore } = this.props;
    orderStore.setLoadingCd(value);
  };

  fnOnchangeUnLoadingCd = value => {
    const { orderStore } = this.props;
    orderStore.setUnLoadingCd(value);
  };

  //상차방법 모달 열기
  fnSelectLoadingModalOpen = () => {
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };
  //상차방법 모달 닫기
  fnSelectLoadingModalClose = () => {
    const { orderStore } = this.props;
    orderStore.initLoadingCd();
    orderStore.initLoadingNm();
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };
  // 상차방법 선택 후 모달 닫기
  fnSetSelectLoading = value => {
    const { orderStore } = this.props;
    console.log(value);
    orderStore.setLoadingCd(value.value);
    orderStore.setLoadingNm(value.text);
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };

  //하차방법 모달 열기
  fnSelectUnLoadingModalOpen = () => {
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };

  //하차방법 모달 닫기
  fnSelectUnLoadingModalClose = () => {
    const { orderStore } = this.props;
    orderStore.initUnLoadingCd();
    orderStore.initUnLoadingNm();
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };
  // 하차방법선택 후 모달 닫기
  fnSetSelectUnLoading = value => {
    const { orderStore } = this.props;
    orderStore.setUnLoadingCd(value.value);
    orderStore.setUnLoadingNm(value.text);
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };

  //화물운임 계산
  fnCalLogisFee = () => {
    const { orderStore, vcStore } = this.props;
    let params = {};
    let that = this;

    this.setState({
      calLogisFeeLoader: true,
    });

    params.startBcode = this.state.startBcode;
    params.endBcode = this.state.endBcode;

    params.logisCd = orderStore.logisCd; //화물운송구분
    params.freightWeight = orderStore.freightWeight.toString(); // 중량
    params.loadingCd = orderStore.loadingCd; // 상차방법
    params.unloadingCd = orderStore.unloadingCd; //하차방법
    params.loadingDate = moment(orderStore.loadingDate).format(
      'YYYY-MM-DD HH:mm',
    ); // 상차시간
    params.unloadingDate = moment(orderStore.unloadingDate).format(
      'YYYY-MM-DD HH:mm',
    ); // 하차시간

    params.distance = orderStore.distance; // 거리
    params.duration = orderStore.duration; // 예상시간

    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;

    let validation = this.fnValidation();

    if (validation == true) {
      axios({
        url: API_SELECT_LOGIS_FEE,
        method: 'post',
        data: params,
      })
        .then(response => {
          if (response.data.resultCode == 100) {
            orderStore.setLogisFee(response.data.data);
            setTimeout(that.fnCalLogisFeeLoaderClose, 2000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.setState({
        calLogisFeeLoader: false,
      });
    }
  };

  //운임계산 로더 닫기
  fnCalLogisFeeLoaderClose = () => {
    this.setState({ calLogisFeeLoader: false, logisfeeFlag: true });
  };

  //화물운송 수정 모달 열기
  fnUpdateFreightModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForUpdateOpen('변경된 내용을 저장 하시겠습니까?');
  };

  //화물운송 수정 모달 닫기
  fnUpdateFreightModalClose = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForUpdateClose();
  };

  //화물운송 수정
  fnUpdateFreight = () => {
    const { orderStore, vcStore } = this.props;
    let params = {};
    let that = this;
    params.loadingBasicAdd = orderStore.departureAdd;
    params.loadingDetailAdd = orderStore.departureDetailAdd;
    params.loadingCoord = orderStore.departureCoord;
    params.loadingBcode = this.state.startBcode;
    params.unloadingBasicAdd = orderStore.arriveAdd;
    params.unloadingDetailAdd = orderStore.arriveDetailAdd;
    params.unloadingCoord = orderStore.arriveCoord;
    params.unloadingBcode = this.state.endBcode;

    //경유지
    params.waypoint1BasicAdd =
      orderStore.wayPointsList[0] != undefined
        ? orderStore.wayPointsList[0].basicAdd
        : null;
    params.waypoint1DetailAdd =
      orderStore.wayPointsList[0] != undefined
        ? orderStore.wayPointsList[0].detailAdd
        : null;
    params.waypoint1Coord =
      this.state.coordsWaypoints[0] != undefined
        ? this.state.coordsWaypoints[0].wayPointX +
          ',' +
          this.state.coordsWaypoints[0].wayPointY
        : null;
    params.waypoint1Bcode =
      orderStore.wayPointsList[0] != undefined
        ? this.state.wayPoint1Bcode
        : null;

    params.waypoint2BasicAdd =
      orderStore.wayPointsList[1] != undefined
        ? orderStore.wayPointsList[1].basicAdd
        : null;
    params.waypoint2DetailAdd =
      orderStore.wayPointsList[1] != undefined
        ? orderStore.wayPointsList[1].detailAdd
        : null;
    params.waypoint2Coord =
      this.state.coordsWaypoints[1] != undefined
        ? this.state.coordsWaypoints[1].wayPointX +
          ',' +
          this.state.coordsWaypoints[1].wayPointY
        : null;
    params.waypoint2Bcode =
      orderStore.wayPointsList[1] != undefined
        ? this.state.wayPoint2Bcode
        : null;

    params.waypoint3BasicAdd =
      orderStore.wayPointsList[2] != undefined
        ? orderStore.wayPointsList[2].basicAdd
        : null;
    params.waypoint3DetailAdd =
      orderStore.wayPointsList[2] != undefined
        ? orderStore.wayPointsList[2].detailAdd
        : null;
    params.waypoint3Coord =
      this.state.coordsWaypoints[2] != undefined
        ? this.state.coordsWaypoints[2].wayPointX +
          ',' +
          this.state.coordsWaypoints[2].wayPointY
        : null;

    params.waypoint3Bcode =
      orderStore.wayPointsList[2] != undefined
        ? this.state.wayPoint3Bcode
        : null;

    params.logisFee = orderStore.logisFee;
    params.freightInfo = orderStore.freightInfo;
    params.logisCd = orderStore.logisCd;
    params.freightWeight = orderStore.freightWeight;
    params.loadingCd = orderStore.loadingCd;
    params.unloadingCd = orderStore.unloadingCd;
    params.loadingDate =
      orderStore.loadingDate == null
        ? null
        : moment(orderStore.loadingDate).format('YYYY-MM-DD HH:mm');
    params.unloadingDate =
      orderStore.unloadingDate == null
        ? null
        : moment(orderStore.unloadingDate).format('YYYY-MM-DD HH:mm');

    params.vcCnt = orderStore.vcCnt;
    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;
    params.distance = orderStore.distance;
    params.duration = orderStore.duration;
    params.updateId = sessionStorage.getItem('customerId');

    params.freightNo = orderStore.selectedFreight.freightNo;

    //상차지 담당자
    if (
      orderStore.loadingStaffRank == null ||
      orderStore.loadingStaffRank == ''
    ) {
      params.loadingStaffInfo = orderStore.loadingStaffNm + '|';
    } else {
      params.loadingStaffInfo =
        orderStore.loadingStaffNm + '|' + orderStore.loadingStaffRank;
    }
    params.loadingStaffHpNo = orderStore.loadingStaffHpNo;

    //하차지 담당자
    if (
      orderStore.unloadingStaffRank == null ||
      orderStore.unloadingStaffRank == ''
    ) {
      params.unloadingStaffInfo = orderStore.unloadingStaffNm + '|';
    } else {
      params.unloadingStaffInfo =
        orderStore.unloadingStaffNm + '|' + orderStore.unloadingStaffRank;
    }
    params.unloadingStaffHpNo = orderStore.unloadingStaffHpNo;

    let validation = this.fnValidation();

    if (validation == true) {
      axios({
        url: API_UPDATE_FREIGHT,
        method: 'post',
        data: params,
      })
        .then(response => {
          if (response.data.resultCode == 100) {
            //인수증 이기 떄문에 => orderInfo제외 => payment정보 변경
            that.fnUpdateFreightModalClose();
            that.props.fnUpdateViewClose();
            //window.location.reload();
          } else {
            alert('수정실패');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //화물등록 유효성 검사
  fnValidation = () => {
    const { commonStore, orderStore, vcStore, payStore } = this.props;

    if (orderStore.departureAdd == null || orderStore.departureAdd == '') {
      commonStore.commonAlertOpen(STR_SEN_10);
      return false;
    }

    if (orderStore.arriveAdd == null || orderStore.arriveAdd == '') {
      commonStore.commonAlertOpen(STR_SEN_12);
      return false;
    }

    if (vcStore.vcWeightCd == null || vcStore.vcWeightCd == '') {
      commonStore.commonAlertOpen(STR_SEN_4);
      return false;
    }

    if (vcStore.vcTypCd == null || vcStore.vcTypCd == '') {
      commonStore.commonAlertOpen(STR_SEN_5);
      return false;
    }

    if (orderStore.logisCd == null || orderStore.logisCd == '') {
      commonStore.commonAlertOpen(STR_SEN_15);
      return false;
    }

    if (orderStore.freightInfo == null || orderStore.freightInfo == '') {
      commonStore.commonAlertOpen(STR_SEN_8);
      return false;
    }

    if (orderStore.freightWeight == null || orderStore.freightWeight == '') {
      commonStore.commonAlertOpen(STR_SEN_9);
      return false;
    }

    if (orderStore.loadingCd == null || orderStore.loadingCd == '') {
      commonStore.commonAlertOpen(STR_SEN_16);
      return false;
    }

    if (orderStore.unloadingCd == null || orderStore.unloadingCd == '') {
      commonStore.commonAlertOpen(STR_SEN_17);
      return false;
    }

    if (payStore.paymentTo == null || payStore.paymentTo == '') {
      commonStore.commonAlertOpen(STR_SEN_21);
      return false;
    }

    if (payStore.paymentTy == null || payStore.paymentTy == '') {
      commonStore.commonAlertOpen(STR_SEN_22);
      return false;
    }

    if (orderStore.departureCoord == null || orderStore.departureCoord == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.arriveCoord == null || orderStore.arriveCoord == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.distance == null || orderStore.distance == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.duration == null || orderStore.duration == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    return true;

    // if (
    //   orderStore.loadingDate == null ||
    //   orderStore.loadingDate == 'Invalid date'
    // ) {
    //   commonStore.commonAlertOpen(STR_SEN_18);
    //   return false;
    // }

    // if (
    //   orderStore.unloadingDate == null ||
    //   orderStore.unloadingDate == 'Invalid date'
    // ) {
    //   commonStore.commonAlertOpen(STR_SEN_19);
    //   return false;
    // }
    //return true;
  };

  //상차지 담당자 선택 handler
  fnSetLodingLogistStaff = (e, data) => {
    const { orderStore } = this.props;
    if (data.value == 'insert') {
      orderStore.initLoadingStaffNm();
      orderStore.initLoadingStaffHpNo();
      orderStore.initLoadingStaffRank();
      this.setState({
        logisStaffFlag: false,
      });
    } else {
      orderStore.setLoadingStaffNm(data.value.customerNm);
      orderStore.setLoadingStaffHpNo(data.value.hpNo);
      this.setState({
        logisStaffFlag: true,
      });
    }
  };

  //RadioGroup Handler
  handleChangeLogis = (e, { value }) => this.fnOnchangeLogisCd(value);
  handleChangeLoading = (e, { value }) => this.fnOnchangeLoadingCd(value);
  handleChangeUnLoading = (e, { value }) => this.fnOnchangeUnLoadingCd(value);

  //화물운송 수정 취소모달 열기
  fnUpdateFreightCancleModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForCancleOpen(
      '변경된 내용은 모두 사라집니다. 취소하시겠습니까?',
    );
  };

  //화물운송 수정 취소모달 닫기
  fnUpdateFreightCancleModalClose = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForCancleClose();
  };

  render() {
    const { vcStore, orderStore, payStore, commonStore } = this.props;
    //회원현황 , 회원승인
    return (
      <React.Fragment>
        <Map
          orderPath={orderStore.orderPath}
          departureX={this.state.departureX}
          departureY={this.state.departureY}
          arrivalX={this.state.arrivalX}
          arrivalY={this.state.arrivalY}
          coordsWaypoints={this.state.coordsWaypoints}
          defaultZoom={this.state.defaultZoom}
          updateView={this.props.updateView}
        />
        <OrderForm
          updateView={this.props.updateView}
          //state
          wayPointsNo={this.state.wayPointsNo}
          logisStaffFlag={this.state.logisStaffFlag}
          //function
          fnOpenDepartureModal={this.fnOpenDepartureModal}
          fnSetDepartureDetailAdd={this.fnSetDepartureDetailAdd}
          fnOpenWayPointModal={this.fnOpenWayPointModal}
          fnSetWayPointDetailAdd={this.fnSetWayPointDetailAdd}
          fnDecreaseWayPoint={this.fnDecreaseWayPoint}
          fnIncreaseWayPoint={this.fnIncreaseWayPoint}
          fnOpenArriveModal={this.fnOpenArriveModal}
          fnSetArriveDetailAdd={this.fnSetArriveDetailAdd}
          fnSelectLoadingModalOpen={this.fnSelectLoadingModalOpen}
          fnSelectUnLoadingModalOpen={this.fnSelectUnLoadingModalOpen}
          fnCalLogisFee={this.fnCalLogisFee}
          //fnUpdateFreight={this.fnUpdateFreight}
          fnInsertFreight={this.fnInsertFreight}
          //Select function
          fnSetVcWeightCd={this.fnSetVcWeightCd}
          fnSetVcTypCd={this.fnSetVcTypCd}
          fnSetLodingLogistStaff={this.fnSetLodingLogistStaff}
          //Radio Handler
          fnOnchangeLogisCd={this.fnOnchangeLogisCd}
          fnOnchangeLoadingCd={this.fnOnchangeLoadingCd}
          fnOnchangeUnLoadingCd={this.fnOnchangeUnLoadingCd}
          //Favorite
          fnFavoListModalOpen={this.fnFavoListModalOpen}
          //Update
          fnUpdateFreightModalOpen={this.fnUpdateFreightModalOpen}
          fnUpdateFreightCancleModalOpen={this.fnUpdateFreightCancleModalOpen}
          logisfeeFlag={this.state.logisfeeFlag}
        />

        {/* Modal 영역 */}
        {/* 상차지 검색 모달 */}
        <Modal
          open={this.state.addDeparture}
          onClose={() =>
            this.setState({
              addDeparture: false,
            })
          }
        >
          <Modal.Header>{STR_DEPARTURE_ADD_SEARCH}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteDeparture} />
          </Modal.Content>
        </Modal>
        {/* 허차지 검색 모달 */}
        <Modal
          open={this.state.addArrival}
          onClose={() =>
            this.setState({
              addArrival: false,
            })
          }
        >
          <Modal.Header>{STR_ARRIVE_ADD_SEARCH}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteArrive} />
          </Modal.Content>
        </Modal>

        {/* 경유지 검색 모달 */}
        <Modal
          open={this.state.addWaypoint1}
          onClose={() =>
            this.setState({
              addWaypoint1: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_1}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint1} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.addWaypoint2}
          onClose={() =>
            this.setState({
              addWaypoint2: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_2}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint2} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.addWaypoint3}
          onClose={() =>
            this.setState({
              addWaypoint3: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_3}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint3} />
          </Modal.Content>
        </Modal>
        {/* 경유지 검색 모달 */}
        {/* 상차방법 모달 */}
        <SelectLoadingModal
          open={this.state.loadingModal}
          onClose={this.fnSelectLoadingModalClose}
          modalHeader={STR_SELECT_LOADING_WAY}
          onChange={this.fnSetSelectLoading}
        />
        {/* 하차방법 모달 */}
        <SelectUnLoadingModal
          open={this.state.unLoadingModal}
          onClose={this.fnSelectUnLoadingModalClose}
          modalHeader={STR_SELECT_UNLOADING_WAY}
          onChange={this.fnSetSelectUnLoading}
        />

        <CommonAlert />
        <CorfirmModalForUpdate
          fnConfirmNo={this.fnUpdateFreightModalClose}
          fnConfirmYes={this.fnUpdateFreight}
        />
        <CorfirmModalForCnacle
          fnConfirmNo={this.fnUpdateFreightCancleModalClose}
          fnConfirmYes={this.props.fnUpdateViewClose}
        />
        <Dimmer active={this.state.calLogisFeeLoader}>
          <Loader content="운임을 계산중입니다." />
        </Dimmer>
      </React.Fragment>
    );
  }
}

export default FreightManagementUpdateContainer;
