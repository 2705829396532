/*global AUTHNICE*/
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
@inject(stores => ({
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
  payStore: stores.PaymentStore,
}))
class NicePayForWebView extends React.Component {
  componentDidMount() {
    document.addEventListener('message', e => {
      let paresedData = JSON.parse(e.data);
      this.fnOpenPayModule(paresedData);
    });
  }

  //결제창 열기
  fnOpenPayModule = data => {
    AUTHNICE.requestPay({
      clientId: 'R2_8efc667e395f483d941cf253916741f0',
      method: 'card',

      orderId: data.orderId,
      amount: Number(data.amount),
      goodsName: data.goodsName,
      buyerName: data.buyerName,
      buyerTel: data.buyerTel,
      buyerEmail: data.buyerEmail,
      mallUserId: data.mallUserId,
      mallReserved: JSON.stringify({
        logisfeeList: data.logisfeeList,
        freightNo: data.freightNo,
      }),
      returnUrl:
        process.env.REACT_APP_API_EXPRESS_ADDRESS + 'serverAuthForMobile', //API를 호출할 Endpoint 입력
      fnError: function (result) {
        alert('개발자확인용 : ' + result + '');
        alert('개발자확인용 : ' + result.errorMsg + '');
      },
    });
  };

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default NicePayForWebView;
