import { Component } from 'react';
import { Table, Input, Button, Form, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR06,
  COLOR_GRAY_GR07,
} from '../../../../common/Color';
@inject(stores => ({
  notiStore: stores.NotiStore,
}))
@observer
class NotiDetailContainer extends Component {
  render() {
    const { notiStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {notiStore.selectedNoti != null ? (
          <div style={{ marginTop: '40px', paddingLeft: '38px' }}>
            <p
              style={{
                fontSize: '18px',
                fontWeight: '700',
                color: COLOR_BLACK,
              }}
            >
              {notiStore.selectedNoti.notiTitle}
            </p>
            <div style={{ marginTp: '11px', marginBottom: '56px' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: COLOR_GRAY_GR06,
                }}
              >
                {moment(notiStore.selectedNoti.registDt).format(
                  'YYYY-MM-DD HH:mm',
                )}
              </p>
            </div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '500',
                color: COLOR_GRAY_GR07,
              }}
            >
              {ReactHtmlParser(notiStore.selectedNoti.notiContent)}
            </div>
          </div>
        ) : null}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default NotiDetailContainer;
