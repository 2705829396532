import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  accStore: stores.AccStore,
}))
class NiceAuthInfo extends React.Component {
  componentDidMount() {
    const url = new URL(window.location.href);
    alert('본인인증이 완료되었습니다.');
    const urlParams = url.searchParams;
    let name = urlParams.get('name');
    let mobileno = urlParams.get('mobileno');
    let dupinfo = urlParams.get('dupinfo');

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ customerNm: name, hpNo: mobileno, dupinfo: dupinfo }),
      );
    } else {
      window.opener.postMessage(
        { success: 'Y', customerNm: name, hpNo: mobileno, dupinfo: dupinfo },
        '*',
      );
    }

    window.close();
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default NiceAuthInfo;
