import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  compStore: stores.CompStore,
  wborderStore: stores.WorkbenchOrderStore,
}))
@observer
class FreightStatementList extends Component {
  fnMakeAdd = add => {
    let newAdd = add;
    if (newAdd != null) {
      let addSplit = newAdd.split(' ');
      let result;
      if (addSplit[1] == undefined) {
        result = addSplit[0];
      } else if (addSplit[2] == undefined) {
        result = addSplit[0] + ' ' + addSplit[1];
      } else {
        result = addSplit[0] + ' ' + addSplit[1] + ' ' + addSplit[2];
      }
      return result;
    }
  };
  render() {
    const { wborderStore, compStore } = this.props;
    const that = this;
    return (
      <Fragment>
        {wborderStore.freightStatementList == null ||
        wborderStore.freightStatementList.length == 0 ? (
          <tr
            style={{
              width: '690px',
              height: '26px',
            }}
          >
            <td
              colSpan={12}
              style={{
                borderTop: '2px solid',
                borderLeft: '2px solid',
                borderRight: '2px solid',
                borderBottom: '2px solid',
                textAlign: 'center',
              }}
            >
              운송 내역이 없습니다.
            </td>
          </tr>
        ) : (
          wborderStore.freightStatementList[this.props.index].map((data, i) => (
            <tr
              key={i}
              style={{
                width: '690px',
                height: '26px',
              }}
            >
              <td
                colSpan={2}
                style={{
                  borderLeft: '2px solid',
                  borderBottom:
                    wborderStore.freightStatementList[this.props.index]
                      .length ==
                    i + 1
                      ? '2px solid'
                      : '1px solid',
                  borderRight: '1px solid',
                  textAlign: 'center',
                  fontSize: '9pt',
                }}
              >
                {data.loadingDate}
              </td>
              <td
                style={{
                  width: '60px',
                  borderBottom:
                    wborderStore.freightStatementList[this.props.index]
                      .length ==
                    i + 1
                      ? '2px solid'
                      : '1px solid',
                  borderRight: '1px solid',
                  textAlign: 'center',
                  fontSize: '9pt',
                }}
              >
                {data.vcNo != undefined ? data.vcNo.toString().slice(-4) : null}
              </td>

              <td
                style={{
                  width: '60px',
                  borderBottom:
                    wborderStore.freightStatementList[this.props.index]
                      .length ==
                    i + 1
                      ? '2px solid'
                      : '1px solid',
                  borderRight: '1px solid',
                  textAlign: 'center',
                  fontSize: '9pt',
                }}
              >
                {data.driverVcWeight}
              </td>

              <td
                colSpan={2}
                style={{
                  maxWidth: '120px',
                  borderBottom:
                    wborderStore.freightStatementList[this.props.index]
                      .length ==
                    i + 1
                      ? '2px solid'
                      : '1px solid',
                  borderRight: '1px solid',
                  textAlign: 'center',
                  fontSize: '9pt',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {data.loadingCompNm != undefined
                  ? data.loadingCompNm
                  : that.fnMakeAdd(data.loadingAdd)}
              </td>
              <td
                colSpan={3}
                style={{
                  maxWidth: '120px',
                  borderBottom:
                    wborderStore.freightStatementList[this.props.index]
                      .length ==
                    i + 1
                      ? '2px solid'
                      : '1px solid',
                  borderRight: '1px solid',
                  textAlign: 'center',
                  fontSize: '9pt',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {data.unloadingCompNm != undefined
                  ? data.unloadingCompNm
                  : that.fnMakeAdd(data.unloadingAdd)}
              </td>
              <td
                colSpan={2}
                style={{
                  width: '30px',
                  borderBottom:
                    wborderStore.freightStatementList[this.props.index]
                      .length ==
                    i + 1
                      ? '2px solid'
                      : '1px solid',
                  borderRight: '1px solid',
                  textAlign: 'center',
                  fontSize: '9pt',
                }}
              >
                {data.totalFee != undefined
                  ? data.totalFee
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : null}
              </td>

              <td
                style={{
                  width: '120px',
                  borderRight: '2px solid',
                  borderBottom:
                    wborderStore.freightStatementList[this.props.index]
                      .length ==
                    i + 1
                      ? '2px solid'
                      : '1px solid',
                  textAlign: 'center',
                  fontSize: '9pt',
                }}
              >
                {compStore.selectedComp.compNo == 17
                  ? data.clientStaffNm
                  : null}
              </td>
            </tr>
          ))
        )}
      </Fragment>
    );
  }
}

export default FreightStatementList;
