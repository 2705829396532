/* global location */
import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import QuoteMain from './QuoteMain';
import Introduce from './Introduce';
import Spec from './Spec';
import LogisFeeTable from './LogisFeeTable';
import B2BContact from './B2BContact';
import { ReactComponent as WebLogo } from '../../assets/icon/web_logo.svg';
import { ReactComponent as ModeEdit } from '../../assets/icon/mode_edit.svg';
import { ReactComponent as TalkContact } from '../../assets/icon/talk_contact.svg';
import { ReactComponent as PhoneCall } from '../../assets/icon/phone_mo.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icon/phone_icon.svg';
import { ReactComponent as Speaker } from '../../assets/icon/speaker.svg';
import {
  COLOR_GRAY_GR07,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import MediaQuery from 'react-responsive';
import MobileFreightModal from './MobileFreightModal';
import { inject, observer } from 'mobx-react';
import DaumPostcode from 'react-daum-postcode';
import axios from 'axios';
import {
  STR_DEPARTURE_ADD_SEARCH,
  STR_ARRIVE_ADD_SEARCH,
  STR_SEN_4,
  STR_SEN_8,
  STR_SEN_10,
  STR_SEN_12,
  STR_SEN_42,
  STR_SEN_43,
  STR_SEN_6,
  STR_SEN_9,
} from '../../common/Terms';
import {
  API_SELECT_COMCD,
  API_SELECT_VC_TYP_CD,
} from '../../common/ApiAddress/CommonAPI';

import CommonAlert from '../../Component/Common/CommonAlert';
import CommonAlertWithCallback from '../../Component/Common/CommonAlertWithCallback';
import FreightRequesForm from './FreightRequestForm';
import moment from 'moment';
import CombinedFreightRequsetForm from './CombinedFreightRequsetForm';
import MobileCombinedFreightModal from './MobileCombinedFreightModal';
import QuoteFooter from '../../Component/Quote/QuoteFooter';
import {
  API_INSERT_FREIGHT_MINI,
  API_UPDATE_FREIGHT_MINI_MSG_TEXT,
} from '../../common/ApiAddress/FreightMiniAPI';
import MailUnsubscribe from './MailUnsubscribe';
import ReactGA from 'react-ga4';
@inject(stores => ({
  quoteStore: stores.QuoteStore,
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
}))
@observer
class QuoteContainer extends Component {
  state = {
    addDeparture: false,
    addArrival: false,
    combinedFreight: false,

    departureX: null,
    departureY: null,
    arrivalX: null,
    arrivalY: null,
  };

  componentDidMount() {
    this.setState({
      combinedFreight: false,
    });
  }

  componentWillUnmount() {
    this.setState({
      combinedFreight: false,
    });
  }

  fnPhoneCall = () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = 'tel:' + '010-9611-2934';
  };

  fnOpenDepartureModal = () => {
    this.setState({
      addDeparture: !this.state.addDeparture,
    });
  };

  fnOpenArriveModal = () => {
    this.setState({
      addArrival: !this.state.addArrival,
    });
  };

  fnSetDepartureDetailAdd = value => {
    const { quoteStore } = this.props;
    quoteStore.setLoadingDetailAdd(value);
  };

  fnSetArriveDetailAdd = value => {
    const { quoteStore } = this.props;
    quoteStore.setUnloadingDetailAdd(value);
  };

  fnSetOrdererNm = value => {
    const { quoteStore } = this.props;
    quoteStore.setOrdererNm(value);
  };
  fnSetOrdererHpNo = value => {
    const { quoteStore } = this.props;
    quoteStore.setOrdererHpNo(value);
  };

  //상차지 주소 검색 완료
  fnHandleCompleteDeparture = data => {
    const { quoteStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addDeparture: !this.state.addDeparture,
    });
    quoteStore.setLoadingBasicAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'D');
  };

  //하차지 주소 검색 완료
  fnHandleCompleteArrive = data => {
    console.log(data);
    const { quoteStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addArrival: !this.state.addArrival,
    });
    quoteStore.setUnloadingBasicAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'A');
  };

  //차량적재무게 타입 불러오기
  fnSelectVcWeightCd = () => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRWE';
    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let weightCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              params.syscdidx = response.data.data[i].sysCdIdx;
              weightCdList.push(params);
            }
            vcStore.setVcWeightCdList(weightCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량구분 타입 불러오기
  fnSelectVcTypCd = value => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRTY';
    if (value == '001') {
      params.sysCdIdx = value;
    } else if (value == '002') {
      params.sysCdIdx = value;
    } else {
      params.sysCdIdx = '';
    }
    axios({
      url: API_SELECT_VC_TYP_CD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let typCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              params.sysCdIdx = response.data.data[i].sysCdIdx;
              typCdList.push(params);
            }
            vcStore.setVcTypCdList(typCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량중량구분 관련 함수들
  fnSetVcWeightCd = (e, data) => {
    const { vcStore } = this.props;
    vcStore.setVcWeightCd(data.value);
    vcStore.setVcWeightCdNm(e.target.textContent);
    vcStore.initVcTypCd();
    let syscdidx = data.value.substring(5, 8);
    this.fnSelectVcTypCd(syscdidx);
  };
  //차량구분 선택함수
  fnSetVcTypCd = (e, value) => {
    const { vcStore } = this.props;
    vcStore.setVcTypCd(value);
    vcStore.setVcTypCdNm(e.target.textContent);
  };

  fnGetcoords = (value, typ) => {
    const { quoteStore } = this.props;
    let that = this;
    axios({
      url: 'https://teamtrucker.co.kr/naver/geocode',
      method: 'get',
      params: {
        query: value,
      },
    })
      .then(function (response) {
        if (typ === 'D') {
          //상차지
          that.setState({
            departureX: response.data.items.addresses[0].x,
            departureY: response.data.items.addresses[0].y,
          });
          quoteStore.setDepartureCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          if (
            that.state.departureX != null &&
            that.state.departureY != null &&
            that.state.arrivalX != null &&
            that.state.arrivalY != null
          ) {
            console.log('경로 탐색');
            //경로 구하는 함수로 이동
            that.fnGetPath();
          }
        } else {
          //하차지
          that.setState({
            arrivalX: response.data.items.addresses[0].x,
            arrivalY: response.data.items.addresses[0].y,
          });
          quoteStore.setArriveCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          if (
            that.state.departureX != null &&
            that.state.departureY != null &&
            that.state.arrivalX != null &&
            that.state.arrivalY != null
          ) {
            console.log('경로 탐색');
            //경로 구하는 함수로 이동
            that.fnGetPath();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetPath = () => {
    const { quoteStore } = this.props;
    let start = this.state.departureX + ',' + this.state.departureY;
    let goal = this.state.arrivalX + ',' + this.state.arrivalY;
    let that = this;
    axios({
      url: 'https://teamtrucker.co.kr/naver/drive',
      method: 'get',
      params: {
        start: start,
        goal: goal,
        //waypoints: waypoints == null ? null : waypoints,
      },
    })
      .then(function (response) {
        let distance = response.data.items[0].summary.distance * 0.001;
        quoteStore.setDistance(Number(distance).toFixed(2).toString() + 'km');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //배차신청 내역 저장
  fnInsertFreightMini = () => {
    const { quoteStore, commonStore, vcStore } = this.props;
    let params = {};
    let that = this;

    if (
      quoteStore.loadingBasicAdd == null ||
      quoteStore.loadingBasicAdd == ''
    ) {
      commonStore.commonAlertOpen(STR_SEN_10);
      return false;
    }

    if (
      quoteStore.unloadingBasicAdd == null ||
      quoteStore.unloadingBasicAdd == ''
    ) {
      commonStore.commonAlertOpen(STR_SEN_12);
      return false;
    }

    if (vcStore.vcWeightCd == null || vcStore.vcWeightCd == '') {
      commonStore.commonAlertOpen(STR_SEN_4);
      return false;
    }

    if (vcStore.vcTypCd == null || vcStore.vcTypCd == '') {
      commonStore.commonAlertOpen(STR_SEN_6);
      return false;
    }

    if (
      quoteStore.cargoDispathInfo == null ||
      quoteStore.cargoDispathInfo == ''
    ) {
      commonStore.commonAlertOpen(STR_SEN_8);
      return false;
    }

    if (quoteStore.ordererNm == null || quoteStore.ordererNm == '') {
      commonStore.commonAlertOpen(STR_SEN_42);
      return false;
    }

    if (quoteStore.ordererHpNo == null || quoteStore.ordererHpNo == '') {
      commonStore.commonAlertOpen(STR_SEN_43);
      return false;
    }

    params.loadingBasicAdd = quoteStore.loadingBasicAdd;
    params.loadingDetailAdd = quoteStore.loadingDetailAdd;
    params.unloadingBasicAdd = quoteStore.unloadingBasicAdd;
    params.unloadingDetailAdd = quoteStore.unloadingDetailAdd;
    params.freightInfo = quoteStore.cargoDispathInfo;
    params.ordererNm = quoteStore.ordererNm;
    params.ordererHpNo = quoteStore.ordererHpNo;
    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;
    params.vcWeightCdNm = vcStore.vcWeightCdNm;
    params.vcTypCdNm = vcStore.vcTypCdNm;
    params.distance = quoteStore.distance;
    params.freightCd = 'IFRST001';
    params.customerNm = quoteStore.ordererNm;
    params.customerHpNo = quoteStore.ordererHpNo;
    params.logisCd = 'IFRDI001';

    if (quoteStore.loadingDate == null || quoteStore.loadingDate == '') {
      params.loadingDate = '미기입';
    } else {
      params.loadingDate = moment(quoteStore.loadingDate).format(
        'YYYY-MM-DD HH:mm',
      );
    }

    if (
      quoteStore.unloadingStaffNm == null ||
      quoteStore.unloadingStaffNm == ''
    ) {
      params.unloadingStaffNm = '미기입';
    } else {
      params.unloadingStaffNm = quoteStore.unloadingStaffNm;
    }

    if (
      quoteStore.unloadingStaffHpNo == null ||
      quoteStore.unloadingStaffHpNo == ''
    ) {
      params.unloadingStaffHpNo = '미기입';
    } else {
      params.unloadingStaffHpNo = quoteStore.unloadingStaffHpNo;
    }

    axios({
      url: API_INSERT_FREIGHT_MINI,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.fnGetMsgAuth(params, response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //메세지 전송 권한 요청 fnInsertFreightMini
  fnGetMsgAuth = (freightData, freightNo) => {
    console.log(freightData);
    console.log(freightNo);
    let params = freightData;
    params.freightNo = freightNo;
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgAuth',
      method: 'post',
      data: params,
    })
      .then(response => {
        that.fnSendMsg(params, response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //메세지 전송
  fnSendMsg = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content = '배차요청접수 \n ●상차지\n' + msgData.loadingBasicAdd + '\n';

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != '') {
      content = content + msgData.loadingDetailAdd + '\n';
    }

    content = content + '●하차지\n' + msgData.unloadingBasicAdd + '\n';

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ''
    ) {
      content = content + msgData.unloadingDetailAdd + '\n';
    }

    content =
      content + '차량 : ' + msgData.vcWeightCdNm + msgData.vcTypCdNm + '\n';
    content = content + '화물정보 : ' + msgData.freightInfo + '\n';
    content = content + '요청담당자 : ' + msgData.ordererNm + '\n';
    content = content + '담당자 번호 : ' + msgData.ordererHpNo + '\n';
    content = content + '상차 일시 : ' + msgData.loadingDate + '\n';
    content = content + '예상 거리 : ' + msgData.distance + '\n';
    content = content + '하차지 담당자 : ' + msgData.unloadingStaffNm + '\n';
    content = content + '하차지 연락처 : ' + msgData.unloadingStaffHpNo;

    phone.push('01090710205'); // 송훈프로
    phone.push('01096949505'); // 송찬프로
    phone.push('01096112934'); // 서브폰
    //phone.push('01031254802'); // 서브폰
    id.push('ttSystem');
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    msgData.smsText =
      '배차요청접수<br/>●상차지<br/>' + msgData.loadingBasicAdd + '<br/>';

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != '') {
      content = content + msgData.loadingDetailAdd + '\n';
    }

    msgData.smsText =
      msgData.smsText + '●하차지<br/>' + msgData.unloadingBasicAdd + '<br/>';

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ''
    ) {
      content = content + msgData.unloadingDetailAdd + '<br/>';
    }
    msgData.smsText =
      msgData.smsText +
      '차량 : ' +
      msgData.vcWeightCdNm +
      msgData.vcTypCdNm +
      '<br/>';
    msgData.smsText =
      msgData.smsText + '화물정보 : ' + msgData.freightInfo + '<br/>';
    msgData.smsText =
      msgData.smsText + '요청담당자 : ' + msgData.ordererNm + '<br/>';
    msgData.smsText =
      msgData.smsText + '담당자 번호 : ' + msgData.ordererHpNo + '<br/>';
    msgData.smsText =
      msgData.smsText + '상차 일시 : ' + msgData.loadingDate + '<br/>';
    msgData.smsText =
      msgData.smsText + '예상 거리 : ' + msgData.distance + '<br/>';
    msgData.smsText =
      msgData.smsText + '하차지 담당자 : ' + msgData.unloadingStaffNm + '<br/>';
    msgData.smsText =
      msgData.smsText + '하차지 연락처 : ' + msgData.unloadingStaffHpNo;

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgSendLms',
      method: 'get',
      params: params,
    })
      .then(function (response) {
        if (response.data.code == '1000') {
          that.fnUpdateMsgContent(msgData.smsText, msgData.freightNo);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //문자 내역 입력
  fnUpdateMsgContent = (smsText, freightNo) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.smsText = smsText;
    params.freightNo = freightNo;
    axios({
      url: API_UPDATE_FREIGHT_MINI_MSG_TEXT,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.commonAlertWithCallbackOpen(
            '배차요청을 완료하였습니다. 빠른시간 내 연락드리겠습니다.',
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //합짐 문의 신청하기
  fnSendCombinedFreightRequest = () => {
    const { quoteStore, commonStore, vcStore } = this.props;

    let params = {};
    let that = this;

    if (
      quoteStore.loadingBasicAdd == null ||
      quoteStore.loadingBasicAdd == ''
    ) {
      commonStore.commonAlertOpen(STR_SEN_10);
      return false;
    }

    if (
      quoteStore.unloadingBasicAdd == null ||
      quoteStore.unloadingBasicAdd == ''
    ) {
      commonStore.commonAlertOpen(STR_SEN_12);
      return false;
    }

    if (
      quoteStore.cargoDispathInfo == null ||
      quoteStore.cargoDispathInfo == ''
    ) {
      commonStore.commonAlertOpen(STR_SEN_8);
      return false;
    }

    if (quoteStore.freightWeight == null || quoteStore.freightWeight == '') {
      commonStore.commonAlertOpen(STR_SEN_9);
      return false;
    }

    if (quoteStore.ordererNm == null || quoteStore.ordererNm == '') {
      commonStore.commonAlertOpen(STR_SEN_42);
      return false;
    }

    if (quoteStore.ordererHpNo == null || quoteStore.ordererHpNo == '') {
      commonStore.commonAlertOpen(STR_SEN_43);
      return false;
    }

    params.loadingBasicAdd = quoteStore.loadingBasicAdd;
    params.loadingDetailAdd = quoteStore.loadingDetailAdd;
    params.unloadingBasicAdd = quoteStore.unloadingBasicAdd;
    params.unloadingDetailAdd = quoteStore.unloadingDetailAdd;
    params.freightInfo = quoteStore.cargoDispathInfo;
    params.ordererNm = quoteStore.ordererNm;
    params.ordererHpNo = quoteStore.ordererHpNo;
    params.freightWeight = quoteStore.freightWeight;
    params.distance = quoteStore.distance;
    params.freightCd = 'IFRST001';
    params.customerNm = quoteStore.ordererNm;
    params.customerHpNo = quoteStore.ordererHpNo;
    params.logisCd = 'IFRDI003';

    if (quoteStore.loadingDate == null || quoteStore.loadingDate == '') {
      params.loadingDate = '미기입';
    } else {
      params.loadingDate = moment(quoteStore.loadingDate).format(
        'YYYY-MM-DD HH:mm',
      );
    }
    if (
      quoteStore.unloadingStaffNm == null ||
      quoteStore.unloadingStaffNm == ''
    ) {
      params.unloadingStaffNm = '미기입';
    } else {
      params.unloadingStaffNm = quoteStore.unloadingStaffNm;
    }

    if (
      quoteStore.unloadingStaffHpNo == null ||
      quoteStore.unloadingStaffHpNo == ''
    ) {
      params.unloadingStaffHpNo = '미기입';
    } else {
      params.unloadingStaffHpNo = quoteStore.unloadingStaffHpNo;
    }

    axios({
      url: API_INSERT_FREIGHT_MINI,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.fnGetMsgAuthforCombined(params, response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //메세지 전송 권한 요청
  fnGetMsgAuthforCombined = (freightData, freightNo) => {
    let params = freightData;
    params.freightNo = freightNo;
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgAuth',
      method: 'post',
      data: params,
    })
      .then(response => {
        that.fnSendMsgForCombinedFreight(params, response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //메세지 전송
  fnSendMsgForCombinedFreight = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content = '합짐문의 접수 \n ●상차지\n' + msgData.loadingBasicAdd + '\n';

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != '') {
      content = content + msgData.loadingDetailAdd + '\n';
    }

    content = content + '●하차지\n' + msgData.unloadingBasicAdd + '\n';

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ''
    ) {
      content = content + msgData.unloadingDetailAdd + '\n';
    }

    content = content + '화물정보 : ' + msgData.freightInfo + '\n';
    content = content + '화물중량 : ' + msgData.freightWeight + ' Kg' + '\n';
    content = content + '요청담당자 : ' + msgData.ordererNm + '\n';
    content = content + '담당자 번호 : ' + msgData.ordererHpNo + '\n';
    content = content + '상차 일시 : ' + msgData.loadingDate + '\n';
    content = content + '예상 거리 : ' + msgData.distance + '\n';
    content = content + '하차지 담당자 : ' + msgData.unloadingStaffNm + '\n';
    content = content + '하차지 연락처 : ' + msgData.unloadingStaffHpNo;

    phone.push('01090710205'); // 송훈프로
    phone.push('01096949505'); // 송찬프로
    phone.push('01096112934'); // 서브폰
    id.push('ttSystem');
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;
    msgData.smsText =
      '합짐문의 접수<br/>●상차지<br/>' + msgData.loadingBasicAdd + '<br/>';

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != '') {
      content = content + msgData.loadingDetailAdd + '\n';
    }

    msgData.smsText =
      msgData.smsText + '●하차지<br/>' + msgData.unloadingBasicAdd + '<br/>';

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ''
    ) {
      content = content + msgData.unloadingDetailAdd + '<br/>';
    }
    msgData.smsText =
      msgData.smsText + '화물정보 : ' + msgData.freightInfo + '<br/>';
    msgData.smsText =
      msgData.smsText + '화물정보 : ' + msgData.freightWeight + '<br/>';
    msgData.smsText =
      msgData.smsText + '요청담당자 : ' + msgData.ordererNm + '<br/>';
    msgData.smsText =
      msgData.smsText + '담당자 번호 : ' + msgData.ordererHpNo + '<br/>';
    msgData.smsText =
      msgData.smsText + '상차 일시 : ' + msgData.loadingDate + '<br/>';
    msgData.smsText =
      msgData.smsText + '예상 거리 : ' + msgData.distance + '<br/>';
    msgData.smsText =
      msgData.smsText + '하차지 담당자 : ' + msgData.unloadingStaffNm + '<br/>';
    msgData.smsText =
      msgData.smsText + '하차지 연락처 : ' + msgData.unloadingStaffHpNo;

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgSendLms',
      method: 'get',
      params: params,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.code == '1000') {
          that.fnUpdateMsgContent(msgData.smsText, msgData.freightNo);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnReloadPage = () => {
    window.location.reload();
  };

  fnFreightFormInit = () => {
    const { quoteStore, vcStore } = this.props;
    quoteStore.initOrderForm();
    vcStore.initVcWeightCd();
    vcStore.initVcWeightCdNm();
    vcStore.initVcTypCd();
    vcStore.initVcTypCdNm();
  };

  fnSetUnloadingStaffNm = value => {
    const { quoteStore } = this.props;
    quoteStore.setUnloadingStaffNm(value);
  };

  fnSetUnloadingStaffHpNo = value => {
    const { quoteStore } = this.props;
    quoteStore.setUnloadingStaffHpNo(value);
  };

  render() {
    let currentPage;
    const { quoteStore, vcStore } = this.props;

    switch (this.props.location.pathname) {
      case '/':
        currentPage = (
          <QuoteMain
            combinedFreight={this.state.combinedFreight}
            fnOpenDepartureModal={this.fnOpenDepartureModal}
            fnSetDepartureDetailAdd={this.fnSetDepartureDetailAdd}
            fnOpenArriveModal={this.fnOpenArriveModal}
            fnSetArriveDetailAdd={this.fnSetArriveDetailAdd}
            fnSetVcWeightCd={this.fnSetVcWeightCd}
            fnSetVcTypCd={this.fnSetVcTypCd}
            fnSetOrdererNm={this.fnSetOrdererNm}
            fnSetOrdererHpNo={this.fnSetOrdererHpNo}
            fnInsertFreightMini={this.fnInsertFreightMini}
            fnSendCombinedFreightRequest={this.fnSendCombinedFreightRequest}
            fnSelectVcWeightCd={this.fnSelectVcWeightCd}
            fnSetUnloadingStaffNm={this.fnSetUnloadingStaffNm}
            fnSetUnloadingStaffHpNo={this.fnSetUnloadingStaffHpNo}
          />
        );
        break;
      case '/quote/main':
        currentPage = (
          <QuoteMain
            combinedFreight={this.state.combinedFreight}
            fnOpenDepartureModal={this.fnOpenDepartureModal}
            fnSetDepartureDetailAdd={this.fnSetDepartureDetailAdd}
            fnOpenArriveModal={this.fnOpenArriveModal}
            fnSetArriveDetailAdd={this.fnSetArriveDetailAdd}
            fnSetVcWeightCd={this.fnSetVcWeightCd}
            fnSetVcTypCd={this.fnSetVcTypCd}
            fnSetOrdererNm={this.fnSetOrdererNm}
            fnSetOrdererHpNo={this.fnSetOrdererHpNo}
            fnInsertFreightMini={this.fnInsertFreightMini}
            fnSendCombinedFreightRequest={this.fnSendCombinedFreightRequest}
            fnSelectVcWeightCd={this.fnSelectVcWeightCd}
            fnSetUnloadingStaffNm={this.fnSetUnloadingStaffNm}
            fnSetUnloadingStaffHpNo={this.fnSetUnloadingStaffHpNo}
          />
        );
        break;
      case '/quote/introduce':
        currentPage = <Introduce fnFreightFormInit={this.fnFreightFormInit} />;
        break;
      case '/quote/spec':
        currentPage = <Spec fnFreightFormInit={this.fnFreightFormInit} />;
        break;
      case '/quote/feeTable':
        currentPage = (
          <LogisFeeTable fnFreightFormInit={this.fnFreightFormInit} />
        );
        break;
      case '/quote/contact':
        currentPage = <B2BContact fnFreightFormInit={this.fnFreightFormInit} />;
        break;
      case '/quote/mailunsubs':
        currentPage = (
          <MailUnsubscribe fnFreightFormInit={this.fnFreightFormInit} />
        );
        break;
    }
    return (
      <React.Fragment>
        <MediaQuery maxWidth={768}>
          <div style={{ background: COLOR_GRAY_GR07 }}>
            <div
              style={{
                height: '60px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                background: COLOR_WHITE,
              }}
              onClick={() => this.props.history.push('/')}
            >
              <div style={{ width: '60%' }}>
                <WebLogo
                  style={{
                    marginLeft: '22px',
                  }}
                />
              </div>
              <div
                style={{
                  width: '40%',
                  textAlign: 'right',
                  paddingRight: '5%',
                }}
                onClick={() => this.fnPhoneCall()}
              >
                <PhoneCall />
              </div>
            </div>
            {currentPage}
            <div
              style={{
                position: 'fixed',
                width: '100%',
                height: '107px',
                bottom: '0',
                borderTop: '0px',
                background: COLOR_GRAY_GR07,
                zIndex: 998,
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
              }}
            >
              <ul
                style={{
                  listStyle: 'none',
                  textAlign: 'center',
                  width: '100%',
                  height: '64px',
                  paddingLeft: '0px',
                  color: COLOR_WHITE,
                  fontSize: '16px',
                  fontWeight: '700',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <li
                  style={{
                    width: '33.3%',
                    float: 'left',
                  }}
                  onClick={() => this.props.history.push('/quote/spec')}
                >
                  차량규격
                </li>
                <li
                  style={{
                    width: '33.3%',
                    float: 'left',
                  }}
                  onClick={() => this.props.history.push('/quote/feeTable')}
                >
                  전국요금
                </li>
                <li
                  style={{
                    width: '33.3%',
                    float: 'left',
                  }}
                  onClick={() => this.props.history.push('/quote/contact')}
                >
                  신용거래
                </li>
              </ul>
              <ul
                style={{
                  listStyle: 'none',
                  textAlign: 'center',
                  width: '100%',
                  height: '43px',
                  paddingLeft: '0px',
                  color: COLOR_WHITE,
                  fontSize: '16px',
                  fontWeight: '700',
                  marginTop: '0px',
                }}
              >
                <li
                  style={{
                    width: '33.3%',
                    float: 'left',
                    height: '43px',
                    background: '#21BA45',
                    color: COLOR_WHITE,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => [
                    this.fnSelectVcWeightCd(),
                    quoteStore.setMobileFreightModalOpen(),
                  ]}
                >
                  <ModeEdit style={{ marginRight: '2%' }} fill={COLOR_WHITE} />
                  배차신청
                </li>
                <li
                  style={{
                    width: '33.3%',
                    float: 'left',
                    height: '43px',
                    background: '#D9D9D9',
                    color: COLOR_GRAY_GR07,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    quoteStore.setMobileCombinedFreightModalOpen();
                  }}
                >
                  <Speaker
                    style={{ marginRight: '2%' }}
                    fill={COLOR_GRAY_GR07}
                  />
                  합짐문의
                </li>
                <li
                  style={{
                    width: '33.3%',
                    float: 'left',
                    height: '43px',
                    background: '#FAE300',
                    color: COLOR_GRAY_GR07,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => window.open('http://pf.kakao.com/_Lxaxfeb')}
                >
                  <TalkContact style={{ marginRight: '2%' }} />
                  카카오톡 문의
                </li>
              </ul>
            </div>
            <MobileFreightModal
              fnOpenDepartureModal={this.fnOpenDepartureModal}
              fnOpenArriveModal={this.fnOpenArriveModal}
              fnSetDepartureDetailAdd={this.fnSetDepartureDetailAdd}
              fnSetArriveDetailAdd={this.fnSetArriveDetailAdd}
              fnSetVcWeightCd={this.fnSetVcWeightCd}
              fnSetVcTypCd={this.fnSetVcTypCd}
              fnInsertFreightMini={this.fnInsertFreightMini}
              fnSetOrdererNm={this.fnSetOrdererNm}
              fnSetOrdererHpNo={this.fnSetOrdererHpNo}
              fnSetUnloadingStaffNm={this.fnSetUnloadingStaffNm}
              fnSetUnloadingStaffHpNo={this.fnSetUnloadingStaffHpNo}
            />

            <MobileCombinedFreightModal
              fnOpenDepartureModal={this.fnOpenDepartureModal}
              fnOpenArriveModal={this.fnOpenArriveModal}
              fnSetDepartureDetailAdd={this.fnSetDepartureDetailAdd}
              fnSetArriveDetailAdd={this.fnSetArriveDetailAdd}
              fnSendCombinedFreightRequest={this.fnSendCombinedFreightRequest}
              fnSetOrdererNm={this.fnSetOrdererNm}
              fnSetOrdererHpNo={this.fnSetOrdererHpNo}
              fnSetUnloadingStaffNm={this.fnSetUnloadingStaffNm}
              fnSetUnloadingStaffHpNo={this.fnSetUnloadingStaffHpNo}
            />

            <CommonAlert />
            <CommonAlertWithCallback callback={this.fnReloadPage} />

            {/* Modal 영역 */}
            {/* 상차지 검색 모달 */}
            <Modal
              open={this.state.addDeparture}
              onClose={() =>
                this.setState({
                  addDeparture: false,
                })
              }
            >
              <Modal.Header>{STR_DEPARTURE_ADD_SEARCH}</Modal.Header>
              <Modal.Content>
                <DaumPostcode onComplete={this.fnHandleCompleteDeparture} />
              </Modal.Content>
            </Modal>
            {/* 허차지 검색 모달 */}
            <Modal
              open={this.state.addArrival}
              onClose={() =>
                this.setState({
                  addArrival: false,
                })
              }
            >
              <Modal.Header>{STR_ARRIVE_ADD_SEARCH}</Modal.Header>
              <Modal.Content>
                <DaumPostcode onComplete={this.fnHandleCompleteArrive} />
              </Modal.Content>
            </Modal>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1025}>
          <div
            style={{
              width: '100%',
              background: '#F5F5F5',
              height: '100%',
              overflowX: 'hidden',
              overflowY: 'scroll',
            }}
          >
            <div style={{ width: '100%', margin: '0 auto', height: '56px' }}>
              <div style={{ height: '56px', marginLeft: '264px' }}>
                <div
                  style={{
                    width: '8%',
                    float: 'left',
                    height: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    ReactGA.event({
                      category: 'mainTest',
                      action: 'mainEvent Test',
                    });
                    this.props.history.push('/');
                  }}
                >
                  <WebLogo
                    style={{
                      marginTop: '22px',
                    }}
                  />
                </div>
                <div
                  style={{
                    width: '35%',
                    float: 'left',
                    height: '100%',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    fontWeight: '700',
                    fontSize: '20px',
                    color: COLOR_GREEN_PRIMARY,
                  }}
                >
                  <PhoneIcon
                    style={{
                      width: '16px',
                      height: '16px',
                      marginRight: '2px',
                      marginTop: '18px',
                    }}
                  />
                  <p style={{ marginTop: '15px', lineHeight: '14px' }}>
                    전화문의 010-9611-2934 / 010-9694-9505
                  </p>
                </div>
                <div
                  style={{
                    width: '50%',
                    overflow: 'hidden',
                    height: '100%',
                  }}
                >
                  <ul
                    style={{
                      listStyle: 'none',
                      height: '100%',
                      fontWeight: '700',
                      fontSize: '15px',
                      lineHeight: '27px',
                      color: COLOR_GRAY_GR07,
                    }}
                  >
                    <li
                      style={{
                        marginTop: '8px',
                        float: 'left',
                        height: '100%',
                        marginRight: '48px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        this.props.history.push('/quote/introduce')
                      }
                    >
                      소개
                    </li>
                    <li
                      style={{
                        marginTop: '8px',
                        float: 'left',
                        height: '100%',
                        marginRight: '48px',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.props.history.push('/quote/spec')}
                    >
                      차량규격 및 제원
                    </li>
                    <li
                      style={{
                        marginTop: '8px',
                        float: 'left',
                        height: '100%',
                        marginRight: '48px',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.props.history.push('/quote/feeTable')}
                    >
                      전국 화물 요금
                    </li>
                    <li
                      style={{
                        float: 'left',
                        height: '100%',
                        marginTop: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.props.history.push('/quote/contact')}
                    >
                      기업신용거래 안내
                    </li>
                  </ul>
                </div>
              </div>
              <div
                style={{
                  width: '12%',
                  height: '539px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                  marginTop: '39px',
                  position: 'fixed',
                  right: '2%',
                  bottom: '5%',
                  zIndex: 99,
                }}
              >
                <div
                  style={{
                    width: '160px',
                    height: '60px',
                    background: '#21BA45',

                    borderRadius: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 4px 4px rgba(0,0,0,0.15)',
                    marginBottom: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    //메인 화면일 경우 합짐문의와 배차 신청 폼 변경
                    if (
                      this.props.location.pathname == '/' ||
                      this.props.location.pathname == '/quote/main'
                    ) {
                      this.setState({
                        combinedFreight: false,
                      });
                      this.fnFreightFormInit();
                    }
                    //그 외에 페이지에서는 모달 노출
                    else {
                      quoteStore.setFreightModalForPCOpen();
                    }
                  }}
                >
                  <ModeEdit fill={COLOR_WHITE} />
                  <span
                    style={{
                      color: COLOR_WHITE,
                      fontSize: '16px',
                      fontWeight: '700',
                      marginLeft: '9px',
                    }}
                  >
                    배차 신청하기
                  </span>
                </div>
                <div
                  style={{
                    width: '160px',
                    height: '60px',
                    background: '#D9D9D9',
                    borderRadius: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 4px 4px rgba(0,0,0,0.15)',
                    marginBottom: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    //메인 화면일 경우 합짐문의와 배차 신청 폼 변경
                    if (
                      this.props.location.pathname == '/' ||
                      this.props.location.pathname == '/quote/main'
                    ) {
                      this.setState({
                        combinedFreight: true,
                      });
                      this.fnFreightFormInit();
                    } //그 외에 페이지에서는 모달 노출
                    else {
                      quoteStore.setCombinedFreightModalPCOpen();
                    }
                  }}
                >
                  <Speaker fill={COLOR_GRAY_GR07} />
                  <span
                    style={{
                      color: COLOR_GRAY_GR07,
                      fontSize: '16px',
                      fontWeight: '700',
                      marginLeft: '9px',
                    }}
                  >
                    합짐 문의하기
                  </span>
                </div>

                <div
                  style={{
                    width: '160px',
                    height: '60px',
                    background: '#FAE300',
                    borderRadius: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 4px 4px rgba(0,0,0,0.15)',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open('http://pf.kakao.com/_Lxaxfeb')}
                >
                  <TalkContact />
                  <span
                    style={{
                      color: COLOR_GRAY_GR07,
                      fontSize: '16px',
                      fontWeight: '700',
                      marginLeft: '9px',
                    }}
                  >
                    카카오톡 문의
                  </span>
                </div>
              </div>
            </div>

            {currentPage}
            <QuoteFooter />
          </div>
          <Modal
            style={{
              height: '900px',
              width: '25%',
              background: COLOR_WHITE,
              paddingLeft: '20px',
              borderRadius: '20px',
              paddingRight: '20px',
              float: 'left',
              marginTop: '39px',
              marginBottom: '44px',
            }}
            closeIcon={true}
            closeOnDimmerClick={false}
            open={quoteStore.freightModalForPC}
            onClose={() => {
              this.fnFreightFormInit();
              quoteStore.setFreightModalForPCClose();
            }}
          >
            <FreightRequesForm
              fnOpenDepartureModal={this.fnOpenDepartureModal}
              fnSetDepartureDetailAdd={this.fnSetDepartureDetailAdd}
              fnOpenArriveModal={this.fnOpenArriveModal}
              fnSetArriveDetailAdd={this.fnSetArriveDetailAdd}
              fnSetVcWeightCd={this.fnSetVcWeightCd}
              fnSetVcTypCd={this.fnSetVcTypCd}
              fnSetOrdererNm={this.fnSetOrdererNm}
              fnSetOrdererHpNo={this.fnSetOrdererHpNo}
              fnInsertFreightMini={this.fnInsertFreightMini}
              fnSelectVcWeightCd={this.fnSelectVcWeightCd}
              fnReloadPage={this.fnReloadPage}
              fnSetUnloadingStaffNm={this.fnSetUnloadingStaffNm}
              fnSetUnloadingStaffHpNo={this.fnSetUnloadingStaffHpNo}
            />
          </Modal>

          <Modal
            style={{
              height: '900px',
              width: '25%',
              background: COLOR_WHITE,
              paddingLeft: '20px',
              borderRadius: '20px',
              paddingRight: '20px',
              float: 'left',
              marginTop: '39px',
              marginBottom: '44px',
            }}
            closeIcon={true}
            closeOnDimmerClick={false}
            open={quoteStore.combinedFreightModalPC}
            onClose={() => {
              this.fnFreightFormInit();
              quoteStore.setCombinedFreightModalPCClose();
            }}
          >
            <CombinedFreightRequsetForm
              fnOpenDepartureModal={this.fnOpenDepartureModal}
              fnSetDepartureDetailAdd={this.fnSetDepartureDetailAdd}
              fnOpenArriveModal={this.fnOpenArriveModal}
              fnSetArriveDetailAdd={this.fnSetArriveDetailAdd}
              fnSetVcWeightCd={this.fnSetVcWeightCd}
              fnSetVcTypCd={this.fnSetVcTypCd}
              fnSetOrdererNm={this.fnSetOrdererNm}
              fnSetOrdererHpNo={this.fnSetOrdererHpNo}
              fnSendCombinedFreightRequest={this.fnSendCombinedFreightRequest}
              fnReloadPage={this.fnReloadPage}
              fnSetUnloadingStaffNm={this.fnSetUnloadingStaffNm}
              fnSetUnloadingStaffHpNo={this.fnSetUnloadingStaffHpNo}
            />
          </Modal>

          {/* Modal 영역 */}
          {/* 상차지 검색 모달 */}
          <Modal
            open={this.state.addDeparture}
            onClose={() =>
              this.setState({
                addDeparture: false,
              })
            }
          >
            <Modal.Header>{STR_DEPARTURE_ADD_SEARCH}</Modal.Header>
            <Modal.Content>
              <DaumPostcode onComplete={this.fnHandleCompleteDeparture} />
            </Modal.Content>
          </Modal>
          {/* 허차지 검색 모달 */}
          <Modal
            open={this.state.addArrival}
            onClose={() =>
              this.setState({
                addArrival: false,
              })
            }
          >
            <Modal.Header>{STR_ARRIVE_ADD_SEARCH}</Modal.Header>
            <Modal.Content>
              <DaumPostcode onComplete={this.fnHandleCompleteArrive} />
            </Modal.Content>
          </Modal>
        </MediaQuery>
        <CommonAlert />
        <CommonAlertWithCallback callback={this.fnReloadPage} />
      </React.Fragment>
    );
  }
}

export default withRouter(QuoteContainer);
