import { Component } from 'react';
import { Table, Input, Button, Form, Radio, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { ReactComponent as UpdateIcon } from '../../../assets/icon/edit.svg';
import { ReactComponent as Cancle } from '../../../assets/icon/cancle.svg';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../../common/Color';

@inject(stores => ({
  workDriverStore: stores.WorkbenchDriverStore,
}))
@observer
class DriverUpsertContainer extends Component {
  render() {
    const { workDriverStore } = this.props;
    return (
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
          marginRight: '1%',
          padding: '10px',
        }}
      >
        {workDriverStore.selectedDriver == null ? (
          <div
            style={{
              width: '100%',
              textAlign: 'right',
              //marginTop: '10px',
              //paddingRight: '10px',
            }}
          >
            <Button
              style={{ marginRight: '10px' }}
              positive
              onClick={() => {
                this.props.fnUpsertDriver();
              }}
            >
              트럭커 추가
            </Button>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                width: '130px',
                backgroundColor: COLOR_GREEN_PRIMARY,
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '700',
                borderRadius: '5px',
                padding: '10px 25px',
                float: 'right',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.fnUpsertDriver();
              }}
            >
              <UpdateIcon style={{ marginRight: '6px' }} />
              수정하기
            </div>
          </div>
        )}
        {workDriverStore.selectedDriver == null ? (
          <Table style={{ border: '0px', marginTop: '55px' }}>
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell colSpan="2" style={{ background: '#fff' }}>
                  신규 트럭커 추가
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>트럭커
                  이름
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="트럭커 이름"
                    value={workDriverStore.driverNm || ''}
                    onChange={e => {
                      workDriverStore.setDriverNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>연락처
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="연락처"
                    value={workDriverStore.driverHpNo || ''}
                    onChange={e => {
                      workDriverStore.setDriverHpNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>계좌정보
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="계좌정보"
                    value={workDriverStore.driverAccInfo || ''}
                    onChange={e => {
                      workDriverStore.setDriverAccInfo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량번호
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="차량번호"
                    value={workDriverStore.vcNo || ''}
                    onChange={e => {
                      workDriverStore.setVcNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량톤수
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="차량톤수"
                    value={workDriverStore.vcWeight || ''}
                    onChange={e => {
                      workDriverStore.setVcWeight(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량종류
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="차량종류"
                    value={workDriverStore.vcTyp || ''}
                    onChange={e => {
                      workDriverStore.setVcTyp(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Table style={{ border: '0px', marginTop: '58px' }}>
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell colSpan="2" style={{ background: '#fff' }}>
                  트럭커 정보 수정
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>트럭커
                  이름
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="트럭커 이름"
                    value={workDriverStore.driverNm || ''}
                    onChange={e => {
                      workDriverStore.setDriverNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>연락처
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="연락처"
                    value={workDriverStore.driverHpNo || ''}
                    onChange={e => {
                      workDriverStore.setDriverHpNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>계좌정보
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="계좌정보"
                    value={workDriverStore.driverAccInfo || ''}
                    onChange={e => {
                      workDriverStore.setDriverAccInfo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량번호
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="차량번호"
                    value={workDriverStore.vcNo || ''}
                    onChange={e => {
                      workDriverStore.setVcNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량톤수
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="차량톤수"
                    value={workDriverStore.vcWeight || ''}
                    onChange={e => {
                      workDriverStore.setVcWeight(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량종류
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="차량종류"
                    value={workDriverStore.vcTyp || ''}
                    onChange={e => {
                      workDriverStore.setVcTyp(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default DriverUpsertContainer;
