import React, { Component } from 'react';
import { Button, Modal, Input } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  favoStore: stores.FavoriteStore,
}))
@observer
class FavoriteCateDeleteModal extends Component {
  render() {
    const { favoStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={this.props.deleteCateModal}
        onClose={() => this.props.fnCloseDeleteCateModal()}
        style={{ height: '260px' }}
      >
        <Modal.Header>즐겨찾기 분류 삭제</Modal.Header>
        <Modal.Content>
          <div>
            <h3>해당 분류 삭제 시 분류 내 즐겨찾기도 모두 삭제 됩니다.</h3>
            <h3>해당 분류를 삭제 하시겠습니까?</h3>
          </div>
        </Modal.Content>
        <div style={{ float: 'right', marginTop: '20px', marginRight: '20px' }}>
          <Button negative onClick={() => this.props.fnCloseDeleteCateModal()}>
            취소
          </Button>
          <Button positive onClick={() => this.props.fnDeleteFavoCate()}>
            삭제
          </Button>
        </div>
      </Modal>
    );
  }
}

export default FavoriteCateDeleteModal;
