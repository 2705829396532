import React, { Component, Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin, isAdmin, isCustomer, isEmp } from '../../Util/CommonUtil';
import SideBar from './SideBar';
import AdminSideBar from './AdminSideBar';
import { COLOR_WHITE } from '../../common/Color';
class PrivateRoute extends Component {
  state = {
    selected: null,
    expanded: false,
  };

  componentDidMount() {
    let path = this.props.location.pathname;
    let pathSplit = path.split('/');
    if (pathSplit[1] == 'main') {
      this.onSelect('order');
    } else {
      this.onSelect(pathSplit[1]);
    }
  }

  onSelect = selected => {
    console.log(selected);
    this.setState({ selected: selected });
  };
  onToggle = () => {
    this.setState({ expanded: true });
  };

  onToggleClose = () => {
    this.setState({ expanded: false });
  };

  render() {
    return (
      <Route>
        {isLogin() == true ? (
          <div
            style={{
              height: '100vh',
              marginLeft: this.state.expanded ? '250px' : '80px',
            }}
          >
            {this.props.sideBar == true ? (
              isEmp() ? (
                <AdminSideBar
                  onSelect={this.onSelect}
                  onToggle={this.onToggle}
                  onToggleClose={this.onToggleClose}
                  expanded={this.state.expanded}
                  selected={this.state.selected}
                />
              ) : isLogin() ? (
                <SideBar
                  onSelect={this.onSelect}
                  onToggle={this.onToggle}
                  onToggleClose={this.onToggleClose}
                  expanded={this.state.expanded}
                  selected={this.state.selected}
                />
              ) : null
            ) : null}
            {/* {isEmp() ? (
              <div
                style={{
                  width: '100%',
                  height: '50px',
                  backgroundColor: '#db3d44',
                }}
              >
                <p
                  style={{
                    color: COLOR_WHITE,
                    fontSize: '14pt',
                    textAlign: 'right',
                    paddingRight: '10px',
                    paddingTop: '10px',
                  }}
                >
                  {sessionStorage.getItem('empId') != undefined
                    ? sessionStorage.getItem('empId')
                    : null}
                  님 환영합니다.
                </p>
              </div>
            ) : null} */}
            <this.props.component />
          </div>
        ) : (
          <Redirect to="/" />
        )}
      </Route>
    );
  }
}

export default PrivateRoute;
