import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { COLOR_BLACK, COLOR_PRIMARY, COLOR_WHITE } from '../../../common/Color';

@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class CustomerList extends Component {
  render() {
    const { accStore, commonStore } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>이름</Table.HeaderCell>
            <Table.HeaderCell>아이디</Table.HeaderCell>
            <Table.HeaderCell>연락처</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {accStore.customerList == null || accStore.customerList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                회원이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {accStore.customerList.map((data, i) => (
              <React.Fragment key={data.customerNo}>
                <Table.Row onClick={() => this.props.fnSetSelectUser(data)}>
                  <Table.Cell
                    style={{
                      background:
                        commonStore.selectedUser != null &&
                        commonStore.selectedUser.customerNo == data.customerNo
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        commonStore.selectedUser != null &&
                        commonStore.selectedUser.customerNo == data.customerNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.customerNm}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      background:
                        commonStore.selectedUser != null &&
                        commonStore.selectedUser.customerNo == data.customerNo
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        commonStore.selectedUser != null &&
                        commonStore.selectedUser.customerNo == data.customerNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.customerId}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      background:
                        commonStore.selectedUser != null &&
                        commonStore.selectedUser.customerNo == data.customerNo
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        commonStore.selectedUser != null &&
                        commonStore.selectedUser.customerNo == data.customerNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.hpNo}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default CustomerList;
