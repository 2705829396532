import React, { Component } from 'react';
import { Button, Modal, Input } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  favoStore: stores.FavoriteStore,
}))
@observer
class FavoriteDeleteModal extends Component {
  render() {
    const { favoStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={this.props.deleteModal}
        onClose={() => this.props.fnCloseFavoDeleteModal()}
        style={{ height: '210px' }}
      >
        <Modal.Header>즐겨찾기 삭제</Modal.Header>
        <Modal.Content>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <h3>선택한 즐겨찾기를 삭제 하시겠습니까?</h3>
          </div>
        </Modal.Content>
        <div style={{ float: 'right', marginTop: '20px', marginRight: '20px' }}>
          <Button negative onClick={() => this.props.fnCloseFavoDeleteModal()}>
            취소
          </Button>
          <Button positive onClick={() => this.props.fnDeleteFavorite()}>
            삭제
          </Button>
        </div>
      </Modal>
    );
  }
}

export default FavoriteDeleteModal;
