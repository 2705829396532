import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
//import EmpList from './EmpList';
import MenuUpsertContainer from './MenuUpsertContainer';
import {
  API_SELECT_MENU_LIST,
  API_INSERT_MENU,
  API_UPDATE_MENU,
  API_SELECT_PRIMARY_MENU,
} from '../../../../common/ApiAddress/MenuAPI';
import TreeMenu from 'react-simple-tree-menu';
import '../../../../../node_modules/react-simple-tree-menu/dist/main.css';

@inject(stores => ({
  menuStore: stores.MenuStore,
}))
@observer
class MenuManagementContainer extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
  };

  treeMenuRef = React.createRef();

  componentDidMount() {
    this.fnSelectAllmenuTree();
  }

  componentWillUnmount() {
    const { menuStore } = this.props;
    menuStore.initMenuList();
  }

  //전체메뉴 리스트 불러오기
  fnSelectAllmenuTree = () => {
    const { menuStore } = this.props;
    let params = {};
    let that = this;
    axios({
      url: API_SELECT_MENU_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          menuStore.setMenuList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnViewOpen = (value, key, label) => {
    const { menuStore } = this.props;
    this.fnSelectPrimaryMenuList();
    if (value.depth == 1) {
      value.menuNo = Number(key);
    } else {
      var keyString = key;
      var keySplit = keyString.split('/');
      value.menuNo = keySplit[1];
    }

    menuStore.setSelectedMenu(value);
    menuStore.setMenuNm(label);
    menuStore.setMenuPath(value.menuPath);
    menuStore.setDepth(value.depth);
    menuStore.setSortNo(value.sortNo);
    menuStore.setPrimaryNo(value.primaryNo);
    menuStore.setEventKey(value.eventKey);
    menuStore.setIcon(value.icon);
    if (value.useYn == 'Y') {
      menuStore.setUseYnTrue();
    } else {
      menuStore.setUseYnFalse();
    }
    this.setState({
      upsertView: true,
      upsertMode: 'U',
    });
  };

  fnInitForm = () => {
    const { menuStore } = this.props;
    this.treeMenuRef.current.resetOpenNodes();
    menuStore.initForm();
  };

  fnSelectPrimaryMenuList = () => {
    const { menuStore } = this.props;
    let params = {};
    let that = this;
    axios({
      url: API_SELECT_PRIMARY_MENU,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          menuStore.setPrimaryMenuList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnInsertMenu = () => {
    const { menuStore } = this.props;
    let params = {};

    params.menuNm = menuStore.menuNm;
    params.menuPath = menuStore.menuPath;
    params.depth = Number(menuStore.depth);
    params.primaryNo =
      menuStore.primaryNo == null ? null : Number(menuStore.primaryNo);
    params.sortNo =
      menuStore.sortNo == null || menuStore.sortNo == ''
        ? null
        : Number(menuStore.sortNo);
    params.eventKey = menuStore.eventKey;
    params.icon = menuStore.icon;
    params.registId = sessionStorage.getItem('empId');
    let that = this;
    axios({
      url: API_INSERT_MENU,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.fnInitForm();
          that.fnSelectAllmenuTree();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpdateMenu = () => {
    const { menuStore } = this.props;
    let params = {};
    params.menuNm = menuStore.menuNm;
    params.menuPath = menuStore.menuPath;
    params.depth = Number(menuStore.depth);
    params.primaryNo =
      menuStore.primaryNo == null ? null : Number(menuStore.primaryNo);
    params.sortNo =
      menuStore.sortNo == null || menuStore.sortNo == ''
        ? null
        : Number(menuStore.sortNo);
    params.eventKey = menuStore.eventKey;
    params.icon = menuStore.icon;
    params.menuNo = menuStore.selectedMenu.menuNo;
    params.updateId = sessionStorage.getItem('empId');
    if (menuStore.useYn == true) {
      params.useYn = 'Y';
    } else {
      params.useYn = 'N';
    }
    console.log(params);
    let that = this;
    axios({
      url: API_UPDATE_MENU,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.fnInitForm();
          that.fnSelectAllmenuTree();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { menuStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '30%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          <div style={{ padding: '10px', float: 'right', height: '50px' }}>
            <Button
              positive
              onClick={() => {
                this.fnInitForm();
                this.setState({
                  upsertView: true,
                  upsertMode: 'I',
                });
              }}
            >
              신규 메뉴 추가
            </Button>
          </div>
          <div style={{ marginTop: '70px' }}>
            <TreeMenu
              ref={this.treeMenuRef}
              hasSearch={false}
              data={menuStore.menuList}
              onClickItem={({ key, label, ...props }) => {
                this.fnViewOpen(props, key, label);
              }}
              debounceTime={125}
            />
          </div>
        </div>
        <div
          style={{
            width: '70%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {this.state.upsertView == true ? (
            <MenuUpsertContainer
              fnInsertMenu={this.fnInsertMenu}
              fnUpdateMenu={this.fnUpdateMenu}
              fnSelectPrimaryMenuList={this.fnSelectPrimaryMenuList}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MenuManagementContainer);
