import React, { Component } from 'react';
import { Button, Modal, Input } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  STR_COMP_AUTH,
  STR_OK,
  STR_BUSINESSES_NO,
  STR_PRESENTATIVE,
  STR_BUSINESSES_START_DATE,
  STR_COMP_NM,
  STR_CLOSE,
} from '../../../../../common/Terms';
@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class CompRegAuthModal extends Component {
  render() {
    const { accStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={this.props.AuthModal}
        onClose={() => this.props.fnAuthModalClose()}
        style={{ height: '430px' }}
      >
        <Modal.Header>{STR_COMP_AUTH}</Modal.Header>
        <Modal.Content>
          <div
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '6px',
              padding: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <div style={{ width: '20%', float: 'left' }}>
                <label>{STR_BUSINESSES_NO}</label>
              </div>
              <div style={{ width: '80%', float: 'left' }}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="사업자 등록번호 기입"
                  value={accStore.compRegNo}
                  onChange={e => {
                    accStore.setCompRegNo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <div style={{ width: '20%', float: 'left' }}>
                <label>{STR_PRESENTATIVE}</label>
              </div>
              <div style={{ width: '80%', float: 'left' }}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="대표자명 기입"
                  value={this.props.presentativeNm}
                  onChange={e => {
                    this.props.fnSetPresentativeNm(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <div style={{ width: '20%', float: 'left' }}>
                <label>{STR_BUSINESSES_START_DATE}</label>
              </div>
              <div style={{ width: '80%', float: 'left' }}>
                <Input
                  style={{ width: '100%' }}
                  placeholder="개업일자 기입"
                  value={this.props.startDt}
                  onChange={e => {
                    this.props.fnSetStartDt(e.target.value);
                  }}
                />
              </div>
            </div>
            {accStore.selectedCustomer.customerCd == 'IACCU003' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '20%', float: 'left' }}>
                  <label>{STR_COMP_NM}</label>
                </div>
                <div style={{ width: '80%', float: 'left' }}>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="법인명 기입"
                    value={this.props.compNm}
                    onChange={e => {
                      this.props.fnSetCompNm(e.target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}

            <div style={{ marginTop: '10px', textAlign: 'right' }}>
              {this.props.isAuth == false &&
              this.props.authComplete == false ? null : this.props.isAuth ==
                  false && this.props.authComplete == true ? (
                <p>인증실패</p>
              ) : this.props.isAuth == true &&
                this.props.authComplete == true ? (
                <p>인증성공</p>
              ) : null}
            </div>
          </div>
        </Modal.Content>
        <div style={{ float: 'right', marginRight: '20px' }}>
          <Button
            positive
            onClick={() =>
              this.props.isAuth == true && this.props.authComplete == true
                ? this.props.fnAuthModalCloseForSeccess()
                : this.props.fnCompRegAuth()
            }
          >
            {STR_OK}
          </Button>
          {this.props.isAuth == false ? (
            <Button negative onClick={() => this.props.fnAuthModalClose()}>
              {STR_CLOSE}
            </Button>
          ) : null}
        </div>
      </Modal>
    );
  }
}

export default CompRegAuthModal;
