import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Menu } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import ChangePWContainer from './ChangePWContainer';
@inject(stores => ({
  commonStore: stores.CommonStore,
  orderStore: stores.OrderStore,
}))
@observer
class ConfigContainer extends Component {
  state = {
    activeItem: null,
  };

  componentDidMount() {
    let path = this.props.location.pathname;
    let pathSplit = path.split('/');
    this.setState({ activeItem: pathSplit[pathSplit.length - 1] });
  }

  handleItemClick = (e, name, path) => {
    const { commonStore, orderStore } = this.props;
    this.setState({
      activeItem: name,
      searchTyp: null,
      searchKeyword: null,
      resultList: [],
    });
    this.props.history.push(path);
    orderStore.initFreightList();
    orderStore.initSelectedFreight();
    commonStore.setPath(path);
  };

  render() {
    const { activeItem } = this.state;
    let currentPage;
    switch (this.props.location.pathname) {
      case '/setting/config/changepw':
        currentPage = (
          <ChangePWContainer pathname={this.props.location.pathname} />
        );
        break;

      default:
        currentPage = (
          <ChangePWContainer pathname={this.props.location.pathname} />
          // <Fragment>
          //   <p>상단에 메뉴를 클릭하세요.</p>
          // </Fragment>
        );
        break;
    }

    //운송현황 , 운송상태별 리스트
    return (
      <React.Fragment>
        {/* <Menu style={{ margin: '0px' }}>
          <Menu.Item
            name="changepw"
            active={activeItem === 'changepw'}
            style={{
              color: activeItem === 'changepw' ? '#fff' : null,
              backgroundColor: activeItem === 'changepw' ? '#db3d44' : null,
            }}
            onClick={e => {
              this.handleItemClick(e, 'changepw', '/setting/config/changepw');
            }}
          >
            비밀번호 변경
          </Menu.Item>

          <Menu.Item
            name="reserved"
            active={activeItem === 'reserved'}
            style={{
              color: activeItem === 'reserved' ? '#fff' : null,
              backgroundColor: activeItem === 'reserved' ? '#db3d44' : null,
            }}
            onClick={e => {
              this.handleItemClick(e, 'reserved', '/freight/reserved');
            }}
          >
            {STR_RESERVED}
          </Menu.Item>
          <Menu.Item
            name="rescomplete"
            active={activeItem === 'rescomplete'}
            style={{
              color: activeItem === 'rescomplete' ? '#fff' : null,
              backgroundColor: activeItem === 'rescomplete' ? '#db3d44' : null,
            }}
            onClick={e => {
              this.handleItemClick(e, 'rescomplete', '/freight/rescomplete');
            }}
          >
            {STR_RESERVED_COMPLETE}
          </Menu.Item>
          <Menu.Item
            name="transporting"
            active={activeItem === 'transporting'}
            style={{
              color: activeItem === 'transporting' ? '#fff' : null,
              backgroundColor: activeItem === 'transporting' ? '#db3d44' : null,
            }}
            onClick={e => {
              this.handleItemClick(e, 'transporting', '/freight/transporting');
            }}
          >
            {STR_ON_TRANSPORTING}
          </Menu.Item>
          <Menu.Item
            name="transcomplete"
            active={activeItem === 'transcomplete'}
            style={{
              color: activeItem === 'transcomplete' ? '#fff' : null,
              backgroundColor:
                activeItem === 'transcomplete' ? '#db3d44' : null,
            }}
            onClick={e => {
              this.handleItemClick(
                e,
                'transcomplete',
                '/freight/transcomplete',
              );
            }}
          >
            {STR_COMPLETE_TRANSPORTING}
          </Menu.Item> 
        </Menu>
        */}
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 45px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            padding: '10px',
          }}
        >
          {currentPage}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ConfigContainer);
