import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button, Form, Table } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import {
  API_SELECT_SIDO_LIST,
  API_SELECT_SIGU_LIST,
  API_SELECT_DONG_LIST,
  API_UPDATE_FEES,
} from '../../../../common/ApiAddress/FreightFeeAPI';
import SidoList from '../../../../Component/Management/Setting/FreightFee/SidoList';
import SiguList from '../../../../Component/Management/Setting/FreightFee/SiguList';
import DongList from '../../../../Component/Management/Setting/FreightFee/DongList';
import {
  COLOR_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../../common/Color';
@inject(stores => ({
  feeStore: stores.FreightFeeStore,
}))
@observer
class FreightFeeManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upsertView: false,
      upsertMode: 'I',
      activItem: null,
    };
  }

  componentDidMount() {
    this.fnSelectSidoList();
  }

  componentWillUnmount() {
    const { feeStore } = this.props;
    feeStore.initForm();
  }

  //시도 리스트 불러오기
  fnSelectSidoList = () => {
    const { feeStore } = this.props;
    let params = {};
    let that = this;
    axios({
      url: API_SELECT_SIDO_LIST,
      method: 'post',
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          feeStore.setSidoList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //시도 선택 및 시군구 불러오기
  fnSelectSiguList = value => {
    const { feeStore } = this.props;
    feeStore.initSiguList();
    feeStore.initDongList();
    feeStore.initSelectedSigu();
    feeStore.initSelectedDong();
    feeStore.setSelectedSido(value);

    let params = {};
    let that = this;
    params.sidoCd = feeStore.selectedSido.sidoCd;

    axios({
      url: API_SELECT_SIGU_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          feeStore.setSiguList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //시군구 선택 및 읍면동 불러오기
  fnSelectDongList = value => {
    const { feeStore } = this.props;
    feeStore.initDongList();
    feeStore.initSelectedDong();
    feeStore.setSelectedSigu(value);

    if (value.startFee != '') {
      feeStore.setStartFee(value.startFee);
    } else {
      feeStore.setStartFee('');
    }
    if (value.endFee != '') {
      feeStore.setEndFee(value.endFee);
    } else {
      feeStore.setEndFee('');
    }

    let params = {};
    let that = this;
    params.sidoCd = feeStore.selectedSido.sidoCd;
    params.siguCd = feeStore.selectedSigu.siguCd;

    axios({
      url: API_SELECT_DONG_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          feeStore.setDongList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //읍면동 선택
  fnSelectedDong = value => {
    console.log(value);
    console.log(value.dongCd);
    const { feeStore } = this.props;
    feeStore.setSelectedDong(value);
  };

  //차량톤수 선택
  fnSelectVd = (value, index, startFee, endFee) => {
    const { feeStore } = this.props;
    feeStore.setSelectedVc(value);

    if (startFee != '') {
      feeStore.setStartFee(startFee);
    } else {
      feeStore.setStartFee('');
    }
    if (endFee != '') {
      feeStore.setEndFee(endFee);
    } else {
      feeStore.setEndFee('');
    }

    this.setState({
      activItem: index,
    });
  };

  fnUpdateFee = () => {
    const { feeStore } = this.props;
    let params = {};
    if (feeStore.selectedSido == null) {
      alert('시도를 선택해주세요.');
      return false;
    }

    if (feeStore.selectedSigu == null) {
      alert('시군구를 선택해주세요.');
      return false;
    }

    if (feeStore.selectedDong == null) {
      alert('읍면동을 선택해주세요.');
      return false;
    }

    if (this.state.activItem == null) {
      alert('차량 톤수를 선택해주세요.');
      return false;
    }

    params.sidoCd = feeStore.selectedSido.sidoCd;
    params.siguCd =
      feeStore.selectedSigu != null ? feeStore.selectedSigu.siguCd : null;
    params.dongCd =
      feeStore.selectedDong != null ? feeStore.selectedDong.dongCd : null;
    params.startFee = feeStore.startFee != '' ? feeStore.startFee : '';
    params.endFee = feeStore.endFee != '' ? feeStore.endFee : '';
    params.vcWeight = feeStore.selectedVc;

    let that = this;
    axios({
      url: API_UPDATE_FEES,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.resultCode == 100) {
          that.setState({
            activItem: null,
          });
          feeStore.initForm();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { feeStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '20%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          <SidoList fnSelectSiguList={this.fnSelectSiguList} />
        </div>
        <div
          style={{
            width: '20%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          <SiguList fnSelectDongList={this.fnSelectDongList} />
        </div>
        <div
          style={{
            width: '20%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          <DongList fnSelectedDong={this.fnSelectedDong} />
        </div>
        <div
          style={{
            width: '40%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {feeStore.selectedDong != null ? (
            <React.Fragment>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>구분</Table.HeaderCell>
                    <Table.HeaderCell>상차지 기본요금</Table.HeaderCell>
                    <Table.HeaderCell>하자지 기본요금</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 0 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 0 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '0T',
                      0,
                      feeStore.selectedDong.startFee0t,
                      feeStore.selectedDong.endFee0t,
                    )
                  }
                >
                  <Table.Cell>0t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee0t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee0t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 1 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 1 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '1T',
                      1,
                      feeStore.selectedDong.startFee1t,
                      feeStore.selectedDong.endFee1t,
                    )
                  }
                >
                  <Table.Cell>1t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee1t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee1t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 2 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 2 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '2T',
                      2,
                      feeStore.selectedDong.startFee2t,
                      feeStore.selectedDong.endFee2t,
                    )
                  }
                >
                  <Table.Cell>2t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee2t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee2t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 3 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 3 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '3T',
                      3,
                      feeStore.selectedDong.startFee3t,
                      feeStore.selectedDong.endFee3t,
                    )
                  }
                >
                  <Table.Cell>3t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee3t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee3t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 4 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 4 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '4T',
                      4,
                      feeStore.selectedDong.startFee4t,
                      feeStore.selectedDong.endFee4t,
                    )
                  }
                >
                  <Table.Cell>4t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee4t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee4t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 5 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 5 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '5T',
                      5,
                      feeStore.selectedDong.startFee5t,
                      feeStore.selectedDong.endFee5t,
                    )
                  }
                >
                  <Table.Cell>5t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee5t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee5t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 6 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 6 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '12T',
                      6,
                      feeStore.selectedDong.startFee12t,
                      feeStore.selectedDong.endFee12t,
                    )
                  }
                >
                  <Table.Cell>12t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee12t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee12t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 7 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 7 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '14T',
                      7,
                      feeStore.selectedDong.startFee14t,
                      feeStore.selectedDong.endFee14t,
                    )
                  }
                >
                  <Table.Cell>14t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee14t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee14t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 8 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 8 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '18T',
                      8,
                      feeStore.selectedDong.startFee18t,
                      feeStore.selectedDong.endFee18t,
                    )
                  }
                >
                  <Table.Cell>18t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee18t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee18t}</Table.Cell>
                </Table.Row>
                <Table.Row
                  style={{
                    backgroundColor:
                      this.state.activItem == 9 ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      this.state.activItem == 9 ? COLOR_WHITE : COLOR_BLACK,
                  }}
                  onClick={() =>
                    this.fnSelectVd(
                      '25T',
                      9,
                      feeStore.selectedDong.startFee25t,
                      feeStore.selectedDong.endFee25t,
                    )
                  }
                >
                  <Table.Cell>25t</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.startFee25t}</Table.Cell>
                  <Table.Cell>{feeStore.selectedDong.endFee25t}</Table.Cell>
                </Table.Row>
              </Table>
              <Form
                style={{
                  border: '1px solid rgba(34,36,38,.15)',
                  borderRadius: '6px',
                  padding: '10px',
                }}
              >
                <Form.Field>
                  <h1>운임표 입력(읍면동 단위)</h1>
                  <label>상차지운임</label>
                  <input
                    onChange={e => feeStore.setStartFee(e.target.value)}
                    value={feeStore.startFee || ''}
                    type="text"
                  />
                </Form.Field>
                <Form.Field>
                  <label>하차지운임</label>
                  <input
                    onChange={e => feeStore.setEndFee(e.target.value)}
                    value={feeStore.endFee || ''}
                    type="text"
                  />
                </Form.Field>

                <Button
                  onClick={() => {
                    this.fnUpdateFee();
                  }}
                  size="medium"
                  color="green"
                  style={{ width: '100%', marginTop: '10px' }}
                >
                  수정
                </Button>
              </Form>
            </React.Fragment>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(FreightFeeManagementContainer);
