import React, { Component } from 'react';
import { ReactComponent as LogisFeeTop } from '../../assets/icon/logis_fee_top.svg';
import { ReactComponent as LogisFeeTopMo } from '../../assets/icon/logis_fee_top_mo.svg';
import {
  COLOR_GRAY_GR00,
  COLOR_GRAY_GR01,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR07,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import {
  logisFeeList,
  logisFeeListFirst,
  logisFeeListSecond,
  logisFeeListThird,
} from './LogisFeeDataList';
import Media from 'react-media';
class LogisFeeTable extends Component {
  state = {
    mobileFlag: 1,
  };
  componentDidMount() {
    this.props.fnFreightFormInit();
  }

  render() {
    return (
      <Media
        queries={{
          small: '(max-width: 768px)',
          medium: '(min-width: 769px) and (max-width: 1024px)',
          large: '(min-width: 1025px)',
        }}
      >
        {matches => (
          <React.Fragment>
            {matches.small && (
              <div
                id="specTable"
                style={{
                  width: '100%',
                  background: COLOR_WHITE,
                  height: 'calc(100vh - 240px)',
                  overflowY: 'scroll',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: 'fit-content',
                      verticalAlign: 'middle',
                    }}
                    src={'../../image/logisfee_top_mo.png'}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      textAlign: 'center',
                      letterSpacing: '-1px',
                      transform: 'translate( -50%, -50% )',
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: '22px',
                        fontWeight: '700',
                        marginBottom: '0px',
                      }}
                    >
                      전국 화물 요금
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '29px',
                    background: COLOR_GRAY_GR01,
                    marginTop: '-5px',
                  }}
                />
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      margin: '0 auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '16px',
                      marginBottom: '22px',
                      background: COLOR_GRAY_GR01,
                    }}
                  >
                    <div
                      style={{
                        width: '33.3%',
                        float: 'left',
                        height: '36px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: this.state.mobileFlag == 1 ? '700' : '400',
                        background:
                          this.state.mobileFlag == 1
                            ? COLOR_WHITE
                            : COLOR_GRAY_GR00,
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        color:
                          this.state.mobileFlag == 1
                            ? COLOR_GRAY_GR07
                            : COLOR_GRAY_GR05,
                        filter:
                          this.state.mobileFlag == 1
                            ? 'none'
                            : 'drop-shadow(1px -1px 4px rgba(0, 0, 0, 0.1))',
                        //boxShadow: '1px 4px 4px rgba(0, 0, 0, 0.1)',
                      }}
                      onClick={() =>
                        this.setState({
                          mobileFlag: 1,
                        })
                      }
                    >
                      1톤 ~ 2.5톤
                    </div>
                    <div
                      style={{
                        width: '33.3%',
                        float: 'left',
                        height: '36px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: this.state.mobileFlag == 2 ? '700' : '400',
                        background:
                          this.state.mobileFlag == 2
                            ? COLOR_WHITE
                            : COLOR_GRAY_GR00,
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        color:
                          this.state.mobileFlag == 2
                            ? COLOR_GRAY_GR07
                            : COLOR_GRAY_GR05,
                        filter:
                          this.state.mobileFlag == 2
                            ? 'none'
                            : 'drop-shadow(1px -1px 4px rgba(0, 0, 0, 0.1))',
                      }}
                      onClick={() =>
                        this.setState({
                          mobileFlag: 2,
                        })
                      }
                    >
                      3.5톤 ~ 5톤
                    </div>
                    <div
                      style={{
                        width: '33.3%',
                        float: 'left',
                        height: '36px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: this.state.mobileFlag == 3 ? '700' : '400',
                        background:
                          this.state.mobileFlag == 3
                            ? COLOR_WHITE
                            : COLOR_GRAY_GR00,
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        color:
                          this.state.mobileFlag == 3
                            ? COLOR_GRAY_GR07
                            : COLOR_GRAY_GR05,
                        filter:
                          this.state.mobileFlag == 3
                            ? 'none'
                            : 'drop-shadow(1px -1px 4px rgba(0, 0, 0, 0.1))',
                      }}
                      onClick={() =>
                        this.setState({
                          mobileFlag: 3,
                        })
                      }
                    >
                      11톤 ~ 25톤
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      width: '92%',
                      margin: '0 auto',
                      marginBottom: '40px',
                    }}
                  >
                    <table
                      style={{
                        width: '100%',
                        border: '0px',
                        borderCollapse: 'collapse',
                        textAlign: 'left',
                        marginBottom: '44px',
                      }}
                    >
                      {this.state.mobileFlag == 1 ? (
                        <tr>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '23%',
                              padding: '0px',
                              fontSize: '13px',
                              fontWeight: '700',
                              color: COLOR_GREEN_PRIMARY,
                            }}
                          >
                            거리
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '23%',
                              padding: '0px',
                              fontSize: '13px',
                              fontWeight: '700',
                              color: COLOR_GREEN_PRIMARY,
                            }}
                          >
                            1톤
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '23%',
                              padding: '0px',
                              fontSize: '13px',
                              fontWeight: '700',
                              color: COLOR_GREEN_PRIMARY,
                            }}
                          >
                            1.4톤
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '23%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            2.5톤
                          </th>
                        </tr>
                      ) : this.state.mobileFlag == 2 ? (
                        <tr>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '18%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            거리
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '18%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            3.5톤
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '17%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            일반5톤
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '18%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            5톤 플러스
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '18%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            5톤축
                          </th>
                        </tr>
                      ) : this.state.mobileFlag == 3 ? (
                        <tr>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '23%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            거리
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '23%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            11톤
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '23%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            18톤
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '23%',
                              padding: '0px',
                              fontSize: '13px',
                              color: COLOR_GREEN_PRIMARY,
                              fontWeight: '700',
                            }}
                          >
                            25톤
                          </th>
                        </tr>
                      ) : null}

                      {this.state.mobileFlag == 1
                        ? logisFeeListFirst.map((data, i) => (
                            <tr>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListFirst.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '23%',
                                  padding: '0px',
                                  fontWeight: '700',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.distance}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListFirst.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '23%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T1}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListFirst.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '23%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T104}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListFirst.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '23%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T205}
                              </th>
                            </tr>
                          ))
                        : this.state.mobileFlag == 2
                        ? logisFeeListSecond.map((data, i) => (
                            <tr>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListSecond.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '18%',
                                  padding: '0px',
                                  fontWeight: '700',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.distance}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListSecond.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '18%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T305}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListSecond.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '18%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T5}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListSecond.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '18%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T5P}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListSecond.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '18%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T5L}
                              </th>
                            </tr>
                          ))
                        : this.state.mobileFlag == 3
                        ? logisFeeListThird.map((data, i) => (
                            <tr>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListThird.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '23%',
                                  padding: '0px',
                                  fontWeight: '700',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.distance}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListThird.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '23%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T11}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListThird.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '23%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T18}
                              </th>
                              <th
                                style={{
                                  borderBottom:
                                    logisFeeListThird.length == i + 1
                                      ? '0px'
                                      : '1px solid #E9ECEF',
                                  width: '23%',
                                  padding: '0px',
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  height: '34px',
                                }}
                              >
                                {data.T25}
                              </th>
                            </tr>
                          ))
                        : null}
                    </table>
                  </div>
                  <div
                    style={{
                      //textAlign: 'center',
                      width: '90%',
                      margin: '0 auto',
                      fontSize: '13px',
                    }}
                  >
                    <p style={{ color: 'red', fontWeight: '600' }}>
                      * 현재 운송 단가표는 거리상 단가표 입니다 실제 배차시 날씨
                      및 상황에 따라 운송비는 조금씩 변경될수 있습니다.
                    </p>
                    <p>
                      * 지방 장거리 운송의 경우 전날상차 다음날 하차가 많습니다
                      라보/1톤짐의경우 미리 공지해주시면 혼적으로
                      운송비조정가능합니다.
                    </p>
                    <p>
                      * 왕복 운송비에 경우 편도 운송비에 서울/경기 50% 지방 70%
                      합한 운송비로 배차가능합니다.
                    </p>
                    <p>
                      * 카톡 문자 전화 편하신 방법으로 문의주시면 친절하게 상담
                      도와드리도록 하겠습니다.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {matches.large && (
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    width: '100%',
                    height: '400px',
                    position: 'relative',
                  }}
                >
                  <LogisFeeTop
                    style={{
                      marginTop: '16px',
                      verticalAlign: 'middle',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '45%',
                      left: '45%',
                      textAlign: 'center',
                      letterSpacing: '-1px',
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: '40px',
                        fontWeight: '700',
                        marginBottom: '0px',
                      }}
                    >
                      전국 화물 요금
                    </p>
                  </div>
                </div>
                <div style={{ width: '100%', marginTop: '40px' }}>
                  <div
                    style={{
                      textAlign: 'center',
                      width: '80%',
                      margin: '0 auto',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'right',
                        marginBottom: '12px',
                        marginTop: '24px',
                      }}
                    >
                      <p>단위 : 원</p>
                    </div>
                    <table
                      style={{
                        width: '100%',
                        border: '0px',
                        borderCollapse: 'collapse',
                        textAlign: 'left',
                        marginBottom: '44px',
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          거리
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          1톤
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          1.4톤
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          2.5톤
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          3.5톤
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          일반5톤
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          5톤 플러스
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          5톤축
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          11톤
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          18톤
                        </th>
                        <th
                          style={{
                            borderBottom: '1px solid #CED4DA',
                            width: '9%',
                            padding: '0px',
                            fontSize: '14px',
                            fontWeight: '700',
                          }}
                        >
                          25톤
                        </th>
                      </tr>
                      {logisFeeList.map((data, i) => (
                        <tr>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.distance}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T1}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T104}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T205}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T305}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T5}
                          </th>

                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T5P}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T5L}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T11}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T18}
                          </th>
                          <th
                            style={{
                              borderBottom:
                                logisFeeList.length == i + 1
                                  ? '0px'
                                  : '1px solid #E9ECEF',
                              width: '9%',
                              padding: '0px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '16px',
                              height: '34px',
                            }}
                          >
                            {data.T25}
                          </th>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <div
                    style={{
                      //textAlign: 'center',
                      width: '80%',
                      margin: '0 auto',
                      marginBottom: '20px',
                      fontSize: '15px',
                    }}
                  >
                    <p style={{ color: 'red', fontWeight: '600' }}>
                      * 현재 운송 단가표는 거리상 단가표 입니다 실제 배차시 날씨
                      및 상황에 따라 운송비는 조금씩 변경될수 있습니다.
                    </p>
                    <p>
                      * 지방 장거리 운송의 경우 전날상차 다음날 하차가 많습니다
                      라보/1톤짐의경우 미리 공지해주시면 혼적으로
                      운송비조정가능합니다.
                    </p>
                    <p>
                      * 왕복 운송비에 경우 편도 운송비에 서울/경기 50% 지방 70%
                      합한 운송비로 배차가능합니다.
                    </p>
                    <p>
                      * 카톡 문자 전화 편하신 방법으로 문의주시면 친절하게 상담
                      도와드리도록 하겠습니다.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Media>
    );
  }
}

export default LogisFeeTable;
