import { Component } from 'react';
import { Table, Input, Button, Form, Radio } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { STR_SEN_3 } from '../../../../common/Terms';

@inject(stores => ({
  empStore: stores.EmpStore,
}))
@observer
class EmpUpsertContainer extends Component {
  render() {
    const { empStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {empStore.selectedEmp == null ? (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">신규 사원 추가</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>사원 아이디</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="사원 아이디 기입"
                    value={empStore.empId || ''}
                    onChange={e => {
                      empStore.setEmpId(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>사원 이름</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="사원 이름 기입"
                    value={empStore.empNm || ''}
                    onChange={e => {
                      empStore.setEmpNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>연락처</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="연락처 기입"
                    value={empStore.hpNo || ''}
                    onChange={e => {
                      empStore.setHpNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">사원 정보 수정</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>사원 아이디</Table.Cell>
                <Table.Cell>{empStore.empId}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>비밀번호</Table.Cell>
                <Table.Cell>
                  <Form>
                    <Form.Field>
                      <input
                        placeholder={STR_SEN_3}
                        onChange={e => empStore.setPw(e.target.value)}
                        value={empStore.pw || ''}
                        type="password"
                      />
                    </Form.Field>
                  </Form>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>사원 이름</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="사원 이름 기입"
                    value={empStore.empNm || ''}
                    onChange={e => {
                      empStore.setEmpNm(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>연락처</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="연락처 기입"
                    value={empStore.hpNo || ''}
                    onChange={e => {
                      empStore.setHpNo(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>사용여부</Table.Cell>
                <Table.Cell>
                  <Form>
                    <Radio
                      toggle
                      defaultChecked={empStore.useYn}
                      onChange={e => {
                        if (empStore.useYn == true) {
                          empStore.setUseYnFalse();
                        } else {
                          empStore.setUseYnTrue();
                        }
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
        {empStore.selectedEmp == null ? (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnUpsertEmp();
              }}
            >
              저장하기
            </Button>
          </div>
        ) : (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnUpsertEmp();
              }}
            >
              수정하기
            </Button>
          </div>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default EmpUpsertContainer;
