import React, { Component } from 'react';
import { Button, Modal, Form, Input } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  STR_OK,
  STR_PW,
  STR_PW_CHECK,
  STR_SEN_3,
  STR_SEN_3_1,
} from '../../common/Terms';

@inject(stores => ({
  empStore: stores.EmpStore,
}))
@observer
class EmpPwUpdateModal extends Component {
  render() {
    const { empStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={this.props.open}
        onClose={() => this.props.onClose()}
        style={{ height: '270px' }}
      >
        <Modal.Header>{this.props.modalHeader}</Modal.Header>
        <Modal.Content>
          <div
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '6px',
              padding: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <div style={{ width: '20%', float: 'left' }}>
                <label>{STR_PW}</label>
              </div>
              <div style={{ width: '80%', float: 'left' }}>
                <Input
                  style={{ width: '80%' }}
                  placeholder={STR_SEN_3}
                  onChange={e => empStore.setPw(e.target.value)}
                  value={empStore.pw || ''}
                  type="password"
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '20%', float: 'left' }}>
                <label>{STR_PW_CHECK}</label>
              </div>
              <div style={{ width: '80%', float: 'left' }}>
                <Input
                  style={{ width: '80%' }}
                  placeholder={STR_SEN_3_1}
                  onChange={e => empStore.setCheckPw(e.target.value)}
                  value={empStore.checkPw || ''}
                  error={
                    empStore.pw == empStore.checkPw
                      ? false
                      : empStore.checkPw == null
                      ? true
                      : true
                  }
                  type="password"
                />
              </div>
            </div>
          </div>
          <Button
            onClick={() => {
              this.props.fnUpdateEmpPw();
            }}
            size="medium"
            color="pink"
            style={{ width: '100%', marginTop: '10px' }}
          >
            {STR_OK}
          </Button>
        </Modal.Content>
      </Modal>
    );
  }
}

export default EmpPwUpdateModal;
