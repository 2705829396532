import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Select,
  Modal,
  Radio,
  Progress,
  Popup,
  Icon,
  Grid,
  Input,
  TextArea,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import DaumPostcode from 'react-daum-postcode';
import moment from 'moment';
import { COLOR_WHITE } from '../../common/Color';
import 'react-datepicker/dist/react-datepicker.css';
import {
  API_SELECT_COMCD,
  API_SELECT_VC_TYP_CD,
  API_SELECT_COMCD_WITHOUT_CODE_ZERO,
} from '../../common/ApiAddress/CommonAPI';
import {
  API_INSERT_FREIGHT,
  API_SELECT_LOGIS_FEE,
} from '../../common/ApiAddress/FreightAPI';
import {
  STR_DEPARTURE_ADD_SEARCH,
  STR_DEPARTURE_DETAIL_ADD_SEARCH,
  STR_ARRIVE_ADD_SEARCH,
  STR_ARRIVE_DETAIL_ADD_SEARCH,
  STR_SEN_4,
  STR_SEN_5,
  STR_SEN_6,
  STR_ORDER_INFO,
  STR_WAYPOINTS_ADD,
  STR_ADD_SEARCH,
  STR_DETAIL_ADD,
  STR_WAYPOINT_ADD_SEARCH_1,
  STR_WAYPOINT_ADD_SEARCH_2,
  STR_WAYPOINT_ADD_SEARCH_3,
  STR_SEN_7,
  STR_SEN_8,
  STR_CAR_CNT,
  STR_SEN_9,
  STR_SELECT_LOADING_WAY,
  STR_SELECT_UNLOADING_WAY,
  STR_SEN_10,
  STR_SEN_11,
  STR_SEN_12,
  STR_SEN_13,
  STR_SEN_14,
  STR_SEN_15,
  STR_SEN_16,
  STR_SEN_17,
  STR_SEN_18,
  STR_SEN_19,
} from '../../common/Terms';

import Map from './Map';
import OnClickInput from '../../Component/Common/OnClickInput';
import CommonInput from '../../Component/Common/CommonInput';
import SelectLoadingModal from '../../Component/Order/SelectLoadingModal';
import SelectUnLoadingModal from '../../Component/Order/SelectUnLoadingModal';
import CommonAlert from '../../Component/Common/CommonAlert';
@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
}))
@observer
class OrderMobileTest extends Component {
  state = {
    //위경도
    lat: null,
    lng: null,
    //모달
    addDeparture: false,
    addArrival: false,
    addWaypoint1: false,
    addWaypoint2: false,
    addWaypoint3: false,
    loadingModal: false,
    unLoadingModal: false,
    //출발지,도착지, 경유지 위경도
    departureX: null,
    departureY: null,
    arrivalX: null,
    arrivalY: null,
    coordsWaypoints: [],

    //출발지, 도착지 법정코드
    startBcode: null,
    endBcode: null,
    wayPoint1Bcode: null,
    wayPoint2Bcode: null,
    wayPoint3Bcode: null,

    //입력form관련
    formPageNo: 1,
    wayPointsNo: 1,
    editablePrice: true,

    loader: false,
  };

  componentDidMount() {
    this.fnSelectLogisCd();
    this.fnSelectVcWeightCd();
    this.fnSelectLoadingCd();
  }

  componentWillUnmount() {
    const { orderStore, vcStore } = this.props;
    orderStore.initForm();
    vcStore.initForOrder();
  }

  //화물구분 불러오기
  fnSelectLogisCd = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.hrankCd = '017';

    axios({
      url: API_SELECT_COMCD_WITHOUT_CODE_ZERO,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let logisCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].comCd;
              params.text = response.data.data[i].lrankCdNm;
              logisCdList.push(params);
            }
            orderStore.setLogisCdList(logisCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량적재무게 타입 불러오기
  fnSelectVcWeightCd = () => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.hrankCd = '005';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let weightCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].comCd;
              params.text = response.data.data[i].lrankCdNm;
              weightCdList.push(params);
            }
            vcStore.setVcWeightCdList(weightCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량구분 타입 불러오기
  fnSelectVcTypCd = value => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    console.log(value);

    if (value == '005001') {
      params.comCd = '005001';
    } else if (value == '005002') {
      params.comCd = '005002';
    } else {
      params.comCd = '';
    }

    axios({
      url: API_SELECT_VC_TYP_CD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let typCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].comCd;
              params.text = response.data.data[i].lrankCdNm;
              typCdList.push(params);
            }
            vcStore.setVcTypCdList(typCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량종류 타입 불러오기
  fnSelectVcModel = value => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    let lrankCd = value.slice(-3);
    switch (lrankCd) {
      case '001':
        params.hrankCd = '007';
        break;
      case '002':
        params.hrankCd = '008';
        break;
      case '003':
        params.hrankCd = '009';
        break;
      case '004':
        params.hrankCd = '010';
        break;
      case '005':
        params.hrankCd = '011';
        break;
      case '006':
        params.hrankCd = '012';
        break;
      case '007':
        params.hrankCd = '013';
        break;
    }

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let modelCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].comCd;
              params.text = response.data.data[i].lrankCdNm;
              modelCdList.push(params);
            }
            vcStore.setVcModelCDList(modelCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량중량구분 관련 함수들
  fnSetVcWeightCd = value => {
    const { vcStore } = this.props;
    vcStore.setVcWeightCd(value);
    this.fnSelectVcTypCd(value);
  };
  //차량구분 선택함수
  fnSetVcTypCd = value => {
    const { vcStore } = this.props;
    vcStore.setVcTypCd(value);
    this.fnSelectVcModel(value);
  };
  //차량종류 선택함수
  fnSetVcModelCd = value => {
    const { vcStore } = this.props;
    vcStore.setVcModelCd(value);
  };

  //상차지 주소 검색 완료
  fnHandleCompleteDeparture = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addDeparture: !this.state.addDeparture,
      startBcode: data.bcode,
    });
    orderStore.setDepartureAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'D');
  };

  //하차지 주소 검색 완료
  fnHandleCompleteArrive = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addArrival: !this.state.addArrival,
      endBcode: data.bcode,
    });
    orderStore.setArriveAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'A');
  };

  fnGetcoords = (value, typ, index) => {
    const { orderStore } = this.props;
    let that = this;
    axios({
      url: 'http://106.243.89.42:80/geocode',
      method: 'get',
      params: {
        query: value,
      },
    })
      .then(function (response) {
        if (typ === 'D') {
          //상차지
          that.setState({
            departureX: response.data.items.addresses[0].x,
            departureY: response.data.items.addresses[0].y,
          });
          orderStore.setDepartureCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          //경유지
        } else if (typ === 'W') {
          let wayPoint = {};
          let wayPointX = response.data.items.addresses[0].x;
          let wayPointY = response.data.items.addresses[0].y;
          wayPoint.wayPointX = wayPointX;
          wayPoint.wayPointY = wayPointY;

          let wayPointList = [...that.state.coordsWaypoints];
          wayPointList.splice(index - 1, 1, wayPoint);
          that.setState({
            coordsWaypoints: wayPointList,
          });
          if (that.state.arrivalX != null) {
            that.fnGetPath();
          }
        } else {
          //하차지
          that.setState({
            arrivalX: response.data.items.addresses[0].x,
            arrivalY: response.data.items.addresses[0].y,
          });
          orderStore.setArriveCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          //경로 구하는 함수로 이동
          that.fnGetPath();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetPath = () => {
    const { orderStore } = this.props;
    let start = this.state.departureX + ',' + this.state.departureY;
    let goal = this.state.arrivalX + ',' + this.state.arrivalY;
    let waypointsList = [...this.state.coordsWaypoints];
    let waypoints = null;

    this.setState({
      loader: true,
    });

    for (let i = 0; i < waypointsList.length; i++) {
      if (i == 0) {
        waypoints =
          waypointsList[i].wayPointX + ',' + waypointsList[i].wayPointY;
      }
      if (i >= 1) {
        waypoints =
          waypoints +
          '|' +
          waypointsList[i].wayPointX +
          ',' +
          waypointsList[i].wayPointY;
      }
    }

    let that = this;
    axios({
      url: 'http://106.243.89.42:80/drive',
      method: 'get',
      params: {
        start: start,
        goal: goal,
        waypoints: waypoints == null ? null : waypoints,
      },
    })
      .then(function (response) {
        let distance = response.data.items[0].summary.distance * 0.001;
        let second = response.data.items[0].summary.duration * 0.001;
        let hour = parseInt(second / 3600);
        let min = parseInt((second % 3600) / 60);

        orderStore.setDistance(distance + 'km');
        if (hour > 0) {
          orderStore.setDuration(hour + '시간' + min + '분');
        } else {
          orderStore.setDuration(min + '분');
        }
        orderStore.setOrderPath(response.data.items[0].path);
        that.setState({
          loader: false,
        });
        // const optionSuccess = {
        //   style: { color: 'green' },
        // };

        // 가격 계산을 거리만으로 측정할지 위경도로 요율을 정할지 내용좀 확인해봐야 할듯

        // 거리(KM)당 가격 측정중
        // 2000원부터 계산 했으나 1000원도 거리가 길어지면 너무 많은 비용이 발생함
        // 천원단위 계산을 위해 2km당 1000원씩 으로 계산함
        // 기름값이 리터당 1600원이 넘는 지금 시점에서 연비가 리터당 3km 이하로 운행되면 장거리의 경우 적자가 될수 있음

        //let fee_per_distance = (parseInt(distance) / 2) * 1000;

        // 출발점 가격 - 톤수에 따른 기본가격 차이발생 예상
        //let fee_per_start = 15000;

        // 도착점 가격 - 톤수에 따른 기본가격 차이발생 예상
        //let fee_per_end = 15000;

        // 각종 옵션들에 따른 가격
        //let fee_option = 0;

        //let fee_total = fee_per_start + fee_per_end + fee_per_distance;

        // toast(
        //   '거리 : ' +
        //     orderStore.distance +
        //     '/ 시간 : ' +
        //     orderStore.duration +
        //     '  /  ' +
        //     fee_total +
        //     ' 원',
        //   optionSuccess,
        // );
      })
      .catch(function (error) {
        toast(error);
        console.log(error);
      });
  };

  fnOpenDepartureModal = () => {
    this.setState({
      addDeparture: !this.state.addDeparture,
    });
  };

  fnOpenArriveModal = () => {
    this.setState({
      addArrival: !this.state.addArrival,
    });
  };

  fnSetDepartureDetailAdd = value => {
    const { orderStore } = this.props;
    orderStore.setDepartureDetailAdd(value);
  };

  fnSetArriveDetailAdd = value => {
    const { orderStore } = this.props;
    orderStore.setArriveDetailAdd(value);
  };

  fnOnchangeLogisCd = value => {
    const { orderStore } = this.props;
    orderStore.setLogisCd(value);
  };

  //경유지 관련 함수
  //경유지 증가
  fnIncreaseWayPoint = () => {
    const { orderStore } = this.props;
    if (this.state.wayPointsNo <= 3) {
      orderStore.setWayPointsList(this.state.wayPointsNo, 'add', {});
      this.setState({
        wayPointsNo: this.state.wayPointsNo + 1,
      });
    } else {
      alert('경유지는 최대 3개까지 추가 가능합니다.');
    }
  };

  //경유지 삭제
  fnDecreaseWayPoint = index => {
    const { orderStore } = this.props;
    orderStore.setWayPointsList(index, 'remove');
    this.setState({
      wayPointsNo: this.state.wayPointsNo - 1,
    });

    //위경도 리스트 삭제
    let spreadCoords = [...this.state.coordsWaypoints];
    spreadCoords.splice(index, 1);
    this.setState({
      coordsWaypoints: spreadCoords,
    });
  };

  //경유지 주소 검색 모달 오픈
  fnOpenWayPointModal = index => {
    if (index == 1) {
      this.setState({
        addWaypoint1: !this.state.addWaypoint1,
      });
    } else if (index == 2) {
      this.setState({
        addWaypoint2: !this.state.addWaypoint2,
      });
    } else if (index == 3) {
      this.setState({
        addWaypoint3: !this.state.addWaypoint3,
      });
    }
  };

  //경유지1 주소 검색 완료
  fnHandleCompleteWayPoint1 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint1: !this.state.addWaypoint1,
      wayPoint1Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(1, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 1);
  };

  //경유지2 주소 검색 완료
  fnHandleCompleteWayPoint2 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint2: !this.state.addWaypoint2,
      wayPoint2Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(2, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 2);
  };

  //경유지3 주소 검색 완료
  fnHandleCompleteWayPoint3 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint3: !this.state.addWaypoint3,
      wayPoint3Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(3, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 3);
  };

  //경유지 상세주소
  fnSetWayPointDetailAdd = (index, value) => {
    const { orderStore } = this.props;
    orderStore.setWayPointsDetailAdd(index, value);
  };

  //상하차 방법 불러오기
  fnSelectLoadingCd = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.hrankCd = '004';

    axios({
      url: API_SELECT_COMCD_WITHOUT_CODE_ZERO,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let loadingCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].comCd;
              params.text = response.data.data[i].lrankCdNm;
              loadingCdList.push(params);
            }
            orderStore.setLoadingCdList(loadingCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOnchangeLoadingCd = value => {
    const { orderStore } = this.props;
    console.log(value);
    orderStore.setLoadingCd(value);
  };

  fnOnchangeUnLoadingCd = value => {
    const { orderStore } = this.props;
    orderStore.setUnLoadingCd(value);
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };

  //상차방법 모달 열기
  fnSelectLoadingModalOpen = () => {
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };
  //상차방법 모달 닫기
  fnSelectLoadingModalClose = () => {
    const { orderStore } = this.props;
    orderStore.initLoadingCd();
    orderStore.initLoadingNm();
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };
  // 상차방법 선택 후 모달 닫기
  fnSetSelectLoading = value => {
    const { orderStore } = this.props;
    orderStore.setLoadingCd(value.value);
    orderStore.setLoadingNm(value.text);
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };

  //하차방법 모달 열기
  fnSelectUnLoadingModalOpen = () => {
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };

  //하차방법 모달 닫기
  fnSelectUnLoadingModalClose = () => {
    const { orderStore } = this.props;
    orderStore.initUnLoadingCd();
    orderStore.initUnLoadingNm();
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };
  // 하차방법선택 후 모달 닫기
  fnSetSelectUnLoading = value => {
    const { orderStore } = this.props;
    orderStore.setUnLoadingCd(value.value);
    orderStore.setUnLoadingNm(value.text);
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };

  //화물운임 계산
  fnCalLogisFee = () => {
    const { orderStore, vcStore } = this.props;
    let params = {};
    let that = this;

    params.startBcode = this.state.startBcode;
    params.endBcode = this.state.endBcode;

    params.logisCd = orderStore.logisCd; //화물운송구분
    params.freightWeight = orderStore.freightWeight; // 중량
    params.loadingCd = orderStore.loadingCd; // 상차방법
    params.unloadingCd = orderStore.unLodingCd; //하차방법
    params.loadingDate = moment(orderStore.loadingDate).format(
      'YYYY-MM-DD HH:mm',
    ); // 상차시간
    params.unloadingDate = moment(orderStore.unLoadingDate).format(
      'YYYY-MM-DD HH:mm',
    ); // 하차시간

    params.distance = orderStore.distance; // 거리
    params.duration = orderStore.duration; // 예상시간

    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;
    params.vcModelCd = vcStore.vcModelCd;

    axios({
      url: API_SELECT_LOGIS_FEE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data);

          that.setState({
            editablePrice: false,
          });
          orderStore.setLogisFee(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //화물운송 생성(임시)
  //추후 결제 이후로 변경해야함
  fnInsertFreight = () => {
    const { orderStore, vcStore } = this.props;
    let params = {};
    let that = this;
    params.freightCd = '018001';
    params.loadingBasicAdd = orderStore.departureAdd;
    params.loadingDetailAdd = orderStore.departureDetailAdd;
    params.loadingCoord = orderStore.departureCoord;
    params.unloadingBasicAdd = orderStore.arriveAdd;
    params.unloadingDetailAdd = orderStore.arriveDetailAdd;
    params.unloadingCoord = orderStore.arriveCoord;

    //경유지
    params.waypoint1BasicAdd =
      orderStore.wayPointsList[0] != undefined
        ? orderStore.wayPointsList[0].basicAdd
        : null;
    params.waypoint1DetailAdd =
      orderStore.wayPointsList[0] != undefined
        ? orderStore.wayPointsList[0].detailAdd
        : null;
    params.waypoint1Coord =
      this.state.coordsWaypoints[0] != undefined
        ? this.state.coordsWaypoints[0].wayPointX +
          ',' +
          this.state.coordsWaypoints[0].wayPointY
        : null;

    params.waypoint2BasicAdd =
      orderStore.wayPointsList[1] != undefined
        ? orderStore.wayPointsList[1].basicAdd
        : null;
    params.waypoint2DetailAdd =
      orderStore.wayPointsList[1] != undefined
        ? orderStore.wayPointsList[1].detailAdd
        : null;
    params.waypoint2Coord =
      this.state.coordsWaypoints[1] != undefined
        ? this.state.coordsWaypoints[1].wayPointX +
          ',' +
          this.state.coordsWaypoints[1].wayPointY
        : null;

    params.waypoint3BasicAdd =
      orderStore.wayPointsList[2] != undefined
        ? orderStore.wayPointsList[2].basicAdd
        : null;
    params.waypoint3DetailAdd =
      orderStore.wayPointsList[2] != undefined
        ? orderStore.wayPointsList[2].detailAdd
        : null;
    params.waypoint3Coord =
      this.state.coordsWaypoints[2] != undefined
        ? this.state.coordsWaypoints[2].wayPointX +
          ',' +
          this.state.coordsWaypoints[2].wayPointY
        : null;

    params.logisFee = orderStore.logisFee;
    params.freightInfo = orderStore.freightInfo;
    params.logisCd = orderStore.logisCd;
    params.freightWeight = orderStore.freightWeight;
    params.loadingCd = orderStore.loadingCd;
    params.unloadingCd = orderStore.unLodingCd;
    params.loadingDate = moment(orderStore.loadingDate).format(
      'YYYY-MM-DD HH:mm',
    );
    params.unloadingDate = moment(orderStore.unLoadingDate).format(
      'YYYY-MM-DD HH:mm',
    );

    params.vcCnt = orderStore.vcCnt;
    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;
    params.vcModelCd = vcStore.vcModelCd;
    params.distance = orderStore.distance;
    params.duration = orderStore.duration;

    params.registId = sessionStorage.getItem('customerId');
    params.staffNo = sessionStorage.getItem('staffNo');
    params.staffNm = sessionStorage.getItem('staffNm');
    params.staffHpNo = sessionStorage.getItem('staffHpNo');
    params.customerNo = sessionStorage.getItem('customerNo');

    let validation = this.fnValidationStepTwo(params);
    if (validation == true) {
      axios({
        url: API_INSERT_FREIGHT,
        method: 'post',
        data: params,
      })
        .then(response => {
          console.log(response.data);
          if (response.data.resultCode == 100) {
            alert('등록완료');
            console.log(response.data);
            window.location.reload();
          } else {
            alert('등록실패');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //화물운송 입력 유효성 검사(STEP1)
  fnValidationStepOne = () => {
    const { commonStore, orderStore, vcStore } = this.props;

    if (orderStore.logisCd == null || orderStore.logisCd == '') {
      commonStore.commonAlertOpen(STR_SEN_15);
      return false;
    }

    if (vcStore.vcWeightCd == null || vcStore.vcWeightCd == '') {
      commonStore.commonAlertOpen(STR_SEN_4);
      return false;
    }

    if (vcStore.vcTypCd == null || vcStore.vcTypCd == '') {
      commonStore.commonAlertOpen(STR_SEN_5);
      return false;
    }

    if (vcStore.vcModelCd == null || vcStore.vcModelCd == '') {
      commonStore.commonAlertOpen(STR_SEN_6);
      return false;
    }

    if (orderStore.departureAdd == null || orderStore.departureAdd == '') {
      commonStore.commonAlertOpen(STR_SEN_10);
      return false;
    }

    if (
      orderStore.departureDetailAdd == null ||
      orderStore.departureDetailAdd == ''
    ) {
      commonStore.commonAlertOpen(STR_SEN_11);
      return false;
    }

    if (orderStore.arriveAdd == null || orderStore.arriveAdd == '') {
      commonStore.commonAlertOpen(STR_SEN_12);
      return false;
    }

    if (
      orderStore.arriveDetailAdd == null ||
      orderStore.arriveDetailAdd == ''
    ) {
      commonStore.commonAlertOpen(STR_SEN_13);
      return false;
    }

    if (orderStore.departureCoord == null || orderStore.departureCoord == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.arriveCoord == null || orderStore.arriveCoord == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.distance == null || orderStore.distance == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.duration == null || orderStore.duration == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }
    this.setState({ formPageNo: this.state.formPageNo + 1 });
  };

  //화물운송 입력 유효성 검사(STEP2)
  fnValidationStepTwo = params => {
    console.log(params);
    const { commonStore } = this.props;
    if (params.logisFee == null || params.logisFee == '') {
      commonStore.commonAlertOpen(STR_SEN_7);
      return false;
    }

    if (params.freightInfo == null || params.freightInfo == '') {
      commonStore.commonAlertOpen(STR_SEN_8);
      return false;
    }

    if (params.loadingCd == null || params.loadingCd == '') {
      commonStore.commonAlertOpen(STR_SEN_16);
      return false;
    }

    if (params.unloadingCd == null || params.unloadingCd == '') {
      commonStore.commonAlertOpen(STR_SEN_17);
      return false;
    }

    if (params.loadingDate == null || params.loadingDate == 'Invalid date') {
      commonStore.commonAlertOpen(STR_SEN_18);
      return false;
    }

    if (
      params.unloadingDate == null ||
      params.unloadingDate == 'Invalid date'
    ) {
      commonStore.commonAlertOpen(STR_SEN_19);
      return false;
    }
    return true;
  };

  //RadioGroup Handler
  handleChangeLogis = (e, { value }) => this.fnOnchangeLogisCd(value);
  handleChangeLoading = (e, { value }) => this.fnOnchangeLoadingCd(value);
  handleChangeUnLoading = (e, { value }) => this.fnOnchangeUnLoadingCd(value);

  render() {
    const { commonStore, vcStore, orderStore } = this.props;
    registerLocale('ko', ko);
    //회원현황 , 회원승인
    return (
      <React.Fragment>
        {/* <Map
          orderPath={orderStore.orderPath}
          departureX={this.state.departureX}
          departureY={this.state.departureY}
          arrivalX={this.state.arrivalX}
          arrivalY={this.state.arrivalY}
          coordsWaypoints={this.state.coordsWaypoints}
        /> */}
        <div
          style={{
            //position: 'fixed',
            //top: 20,
            //right: 20,
            backgroundColor: COLOR_WHITE,
            width: '100%',
            padding: '20px',
            borderRadius: '6px',
          }}
        >
          <h2 style={{ marginTop: '9px' }}>{STR_ORDER_INFO}</h2>
          <div
            className="orderForm"
            style={{
              overflowX: 'hidden',
              overflowY: 'scroll',
              //overflowY: this.state.wayPointsNo == 1 ? 'hidden' : 'scroll',
              height:
                this.state.wayPointsNo == 1 ? '520px' : `calc(100vh - 197px)`,
              marginBottom: '20px',
            }}
          >
            <Form>
              <Form.Field style={{ height: '20px', marginBottom: '36px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  화물 운송 구분
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{ fontSize: '0.65em', width: '200px' }}
                        />
                      }
                      position="right center"
                    >
                      <p>일반 : 일반화물</p>
                      <p>긴급 : 긴급화물(가격이 상승 할 수 있습니다.)</p>
                      <p>혼적 : 다른 화물과 함께 운송합니다.</p>
                      <p>왕복 : 왕복 화물 운송 입니다.</p>
                    </Popup>
                  </span>
                </label>

                {orderStore.logisCdList.length > 0
                  ? orderStore.logisCdList.map((data, i) => (
                      <Radio
                        //defaultChecked
                        style={{ width: '20%', float: 'left' }}
                        label={data.text}
                        name="logisCdGroup"
                        value={data.value}
                        checked={orderStore.logisCd == data.value}
                        onChange={this.handleChangeLogis}
                      />
                    ))
                  : null}
              </Form.Field>
              {/* 선택 부분 */}
              <Form.Field style={{ marginBottom: '20px' }}>
                <label
                  style={{
                    height: '23Px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  차량 선택
                  <span style={{ marginLeft: '4px' }}>
                    <Popup
                      trigger={
                        <Icon
                          name="question"
                          size="small"
                          circular
                          style={{ fontSize: '0.65em', width: '200px' }}
                        />
                      }
                      position="right center"
                    >
                      <p>
                        운송 할 화물에 맞게 차량 톤수, 구분, 종류를 선택해
                        주세요.
                      </p>
                    </Popup>
                  </span>
                </label>
                <Select
                  style={{
                    maxWidth: '190px',
                    minWidth: '100px',
                    float: 'left',
                    marginRight: '9px',
                  }}
                  placeholder={STR_SEN_4}
                  options={vcStore.vcWeightCdList}
                  onChange={(e, { value }) => this.fnSetVcWeightCd(value)}
                  defaultValue={vcStore.vcWeightCd}
                />
                <Input
                  min={1}
                  style={{ maxWidth: '152px', minWidth: '100px' }}
                  label={{ basic: true, content: '대' }}
                  labelPosition="right"
                  type="number"
                  placeholder={STR_CAR_CNT}
                  defaultValue={orderStore.vcCnt}
                  onChange={e => orderStore.setVcCnt(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <Select
                  style={{
                    maxWidth: '190px',
                    minWidth: '100px',
                    float: 'left',
                    marginRight: '9px',
                  }}
                  placeholder={STR_SEN_5}
                  options={vcStore.vcTypCdList}
                  onChange={(e, { value }) => this.fnSetVcTypCd(value)}
                  defaultValue={vcStore.vcTypCd}
                />
                <Select
                  style={{ maxWidth: '190px', minWidth: '100px' }}
                  placeholder={STR_SEN_6}
                  options={vcStore.vcModelCDList}
                  onChange={(e, { value }) => this.fnSetVcModelCd(value)}
                  defaultValue={vcStore.vcModelCd}
                />
              </Form.Field>
              {/* 상차지 주소 */}
              <Form.Field>
                <label>상차지 주소</label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_DEPARTURE_ADD_SEARCH}
                  onClick={this.fnOpenDepartureModal}
                  defaultValue={orderStore.departureAdd}
                />
                <CommonInput
                  placeholder={STR_DEPARTURE_DETAIL_ADD_SEARCH}
                  defaultValue={orderStore.departureDetailAdd}
                  onChange={this.fnSetDepartureDetailAdd}
                />
              </Form.Field>

              {/* 경유지 주소 */}

              {orderStore.wayPointsList.length > 0
                ? orderStore.wayPointsList.map((data, i) => (
                    <Form.Field>
                      <label>
                        {STR_WAYPOINTS_ADD + '주소'}
                        {i + 1}
                      </label>
                      <OnClickInput
                        readOnly={true}
                        placeholder={`${
                          STR_WAYPOINTS_ADD + (i + 1) + STR_ADD_SEARCH
                        }`}
                        onClick={() => this.fnOpenWayPointModal(i + 1)}
                        defaultValue={
                          orderStore.wayPointsList[i].basicAdd == undefined
                            ? null
                            : orderStore.wayPointsList[i].basicAdd
                        }
                      />
                      <Form.Field>
                        <input
                          placeholder={`${
                            STR_WAYPOINTS_ADD + (i + 1) + STR_DETAIL_ADD
                          }`}
                          onChange={e =>
                            this.fnSetWayPointDetailAdd(i + 1, e.target.value)
                          }
                          defaultValue={
                            orderStore.wayPointsList[i].detailAdd == undefined
                              ? null
                              : orderStore.wayPointsList[i].detailAdd
                          }
                        />
                      </Form.Field>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                        onClick={() => this.fnDecreaseWayPoint()}
                      >
                        <Icon name="minus circle" size="large" color="red" />
                        <p>경유지 삭제</p>
                      </div>
                    </Form.Field>
                  ))
                : null}
              {this.state.wayPointsNo > 3 ? null : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => this.fnIncreaseWayPoint()}
                >
                  <Icon name="plus circle" size="big" color="red" />
                  <p>경유지 추가</p>
                </div>
              )}

              {/* 하차지 주소 */}
              <Form.Field>
                <label>하차지 주소</label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_ARRIVE_ADD_SEARCH}
                  onClick={this.fnOpenArriveModal}
                  defaultValue={orderStore.arriveAdd}
                />
                <CommonInput
                  placeholder={STR_ARRIVE_DETAIL_ADD_SEARCH}
                  defaultValue={orderStore.arriveDetailAdd}
                  onChange={this.fnSetArriveDetailAdd}
                />
              </Form.Field>
              <p>
                거리 : {orderStore.distance} / 예상 소요 시간 :{' '}
                {orderStore.duration}
              </p>

              {/* 화물 운임 */}
              <Form.Field>
                <label>화물운임</label>
                <Input
                  disabled={this.state.editablePrice}
                  min={1}
                  label={{ basic: true, content: '원' }}
                  labelPosition="right"
                  type="number"
                  placeholder={STR_SEN_7}
                  defaultValue={orderStore.logisFee}
                  onChange={e => orderStore.setLogisFee(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label>화물정보</label>
                <TextArea
                  style={{ resize: 'none' }}
                  placeholder={STR_SEN_8}
                  rows={3}
                  onChange={e => {
                    orderStore.setFreightInfo(e.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>화물중량</label>
                <Input
                  min={1}
                  label={{ basic: true, content: 'Kg' }}
                  labelPosition="right"
                  type="number"
                  placeholder={STR_SEN_9}
                  defaultValue={orderStore.freightWeight}
                  onChange={e => orderStore.setFreightWeight(e.target.value)}
                />
              </Form.Field>
              <Form.Field style={{ height: '40px', marginBottom: '36px' }}>
                <label>상차 방법</label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_SELECT_LOADING_WAY}
                  onClick={this.fnSelectLoadingModalOpen}
                  defaultValue={orderStore.loadingNm}
                />
              </Form.Field>
              <Form.Field style={{ height: '40px', marginBottom: '36px' }}>
                <label>하차 방법</label>
                <OnClickInput
                  readOnly={true}
                  placeholder={STR_SELECT_UNLOADING_WAY}
                  onClick={this.fnSelectUnLoadingModalOpen}
                  defaultValue={orderStore.unLodingNm}
                />
              </Form.Field>
              <Form.Field>
                <label>상차 일시</label>
                <DatePicker
                  locale="ko"
                  selected={orderStore.loadingDate}
                  onChange={date => {
                    orderStore.setLoadingDate(date);
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="시간"
                  dateFormat="yyyy-MM-dd HH:mm aa"
                />
              </Form.Field>
              <Form.Field>
                <label>하차 일시</label>
                <DatePicker
                  locale="ko"
                  selected={orderStore.unLoadingDate}
                  onChange={date => orderStore.setUnLoadingDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="시간"
                  dateFormat="yyyy-MM-dd HH:mm aa"
                />
              </Form.Field>
            </Form>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{
                width: '48%',
                backgroundColor: '#fff',
                color: '#ff695e',
                border: '1px solid #ff695e',
              }}
              onClick={() => {
                this.fnCalLogisFee();
                //alert('결제로이동');
              }}
            >
              운임계산하기
            </Button>
            <Button
              style={{
                width: '48%',
                backgroundColor: '#ff695e',
                color: '#fff',
              }}
              onClick={() => {
                this.fnInsertFreight();
                //alert('결제로이동');
              }}
            >
              결제 하기
            </Button>
          </div>
        </div>

        {/* Modal 영역 */}
        {/* 상차지 검색 모달 */}
        <Modal
          open={this.state.addDeparture}
          onClose={() =>
            this.setState({
              addDeparture: false,
            })
          }
        >
          <Modal.Header>{STR_DEPARTURE_ADD_SEARCH}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteDeparture} />
          </Modal.Content>
        </Modal>
        {/* 허차지 검색 모달 */}
        <Modal
          open={this.state.addArrival}
          onClose={() =>
            this.setState({
              addArrival: false,
            })
          }
        >
          <Modal.Header>{STR_ARRIVE_ADD_SEARCH}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteArrive} />
          </Modal.Content>
        </Modal>

        {/* 경유지 검색 모달 */}
        <Modal
          open={this.state.addWaypoint1}
          onClose={() =>
            this.setState({
              addWaypoint1: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_1}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint1} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.addWaypoint2}
          onClose={() =>
            this.setState({
              addWaypoint2: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_2}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint2} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.addWaypoint3}
          onClose={() =>
            this.setState({
              addWaypoint3: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_3}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint3} />
          </Modal.Content>
        </Modal>
        {/* 경유지 검색 모달 */}
        {/* 상차방법 모달 */}
        <SelectLoadingModal
          open={this.state.loadingModal}
          onClose={this.fnSelectLoadingModalClose}
          modalHeader={STR_SELECT_LOADING_WAY}
          onChange={this.fnSetSelectLoading}
        />
        {/* 하차방법 모달 */}
        <SelectUnLoadingModal
          open={this.state.unLoadingModal}
          onClose={this.fnSelectUnLoadingModalClose}
          modalHeader={STR_SELECT_UNLOADING_WAY}
          onChange={this.fnSetSelectUnLoading}
        />

        <CommonAlert />
        <Dimmer active={this.state.loader}>
          <Loader>Loading</Loader>
        </Dimmer>
      </React.Fragment>
    );
  }
}

export default withRouter(OrderMobileTest);
