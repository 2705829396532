import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import {
  COLOR_GRAY_GR02,
  COLOR_GRAY_GR05,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import axios from 'axios';
import {
  API_SELECT_FREIGHT_MINI_ALL_LIST,
  API_UPDATE_FREIGHT_MINI,
  API_UPDATE_FREIGHT_MINI_TASKCD,
} from '../../common/ApiAddress/FreightMiniAPI';
import {
  Accordion,
  Button,
  Icon,
  Form,
  Select,
  Input,
  TextArea,
} from 'semantic-ui-react';
import MediaQuery from 'react-responsive';
import { slide as Menu } from 'react-burger-menu';
import { ReactComponent as WebLogo } from '../../assets/icon/web_logo.svg';
@inject(stores => ({
  commonStore: stores.CommonStore,
  quoteStore: stores.QuoteStore,
  vcStore: stores.VcStore,
}))
@observer
class FreightMiniContainer extends Component {
  state = {
    activeIndex: 0,
    msgCharge: 0,
    driverNm: null,
    driverHpNo: null,
    vcNo: null,
    memo: null,
    freightCd: null,
  };

  handleClick = (data, i) => {
    const newIndex = this.state.activeIndex === i ? -1 : i;
    this.setState({ activeIndex: newIndex });
    this.fnSetSelectedFreightMini(data);
  };

  componentDidMount() {
    this.fnGetAuthForMsg();
    this.fnSelectFreightMiniList();
  }
  fnSetSelectedFreightMini = data => {
    const { quoteStore } = this.props;
    quoteStore.setSelectedFreightMini(data);
    quoteStore.setLogisFee(data.logisFee);
    quoteStore.setCommission(data.commission);
    quoteStore.setTotalFee(data.totalFee);

    this.setState({
      driverNm: data.driverNm,
      driverHpNo: data.driverHpNo,
      vcNo: data.vcNo,
      memo: data.memo,
      freightCd: data.freightCd,
    });
  };

  fnGetAuthForMsg = () => {
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgAuth',
      method: 'post',
      //data: params,
    })
      .then(response => {
        that.fnGetChargeForMsg(response.data.access_token);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetChargeForMsg = acessToken => {
    let params = {};
    let that = this;
    params.access_token = acessToken;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'charge',
      method: 'get',
      params: params,
    })
      .then(response => {
        that.setState({
          msgCharge: response.data.use_cdr,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectFreightMiniList = () => {
    const { quoteStore } = this.props;
    let params = {};
    let that = this;
    params.startCnt = 0;
    params.endCnt = 50;
    axios({
      url: API_SELECT_FREIGHT_MINI_ALL_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            quoteStore.setFreightMiniList(response.data.data);
            if (quoteStore.selectedFreightMini == null) {
              that.fnSetSelectedFreightMini(
                response.data.data[that.state.activeIndex],
              );
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //상담완료업데이트
  fnUpdateTaskCd = () => {
    const { quoteStore } = this.props;
    let params = {};
    let that = this;
    params.freightNo = quoteStore.selectedFreightMini.freightNo;
    axios({
      url: API_UPDATE_FREIGHT_MINI_TASKCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnSelectFreightMiniList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpdateFreightMini = () => {
    const { quoteStore } = this.props;
    let params = {};
    let that = this;
    params.freightNo = quoteStore.selectedFreightMini.freightNo;
    params.logisFee = quoteStore.logisFee;
    params.driverNm = this.state.driverNm;
    params.driverHpNo = this.state.driverHpNo;
    params.vcNo = this.state.vcNo;
    params.memo = this.state.memo;
    params.freightCd = this.state.freightCd;
    params.commission = quoteStore.commission;
    params.totalFee = quoteStore.totalFee;
    console.log(params);
    axios({
      url: API_UPDATE_FREIGHT_MINI,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { activeIndex } = this.state;
    const { quoteStore } = this.props;
    let styles = {
      bmBurgerButton: {
        position: 'absolute',
        width: '36px',
        height: '30px',
        right: '15px',
        top: '10px',
      },
      bmBurgerBars: {
        background: '#373a47',
      },
      bmBurgerBarsHover: {
        background: '#a90000',
      },
      bmCrossButton: {
        height: '24px',
        width: '24px',
      },
      bmCross: {
        background: '#bdc3c7',
      },
      bmMenuWrap: {
        position: 'fixed',
        height: '100%',
      },
      bmMenu: {
        background: '#373a47',
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em',
      },
      bmMorphShape: {
        fill: '#373a47',
      },
      bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
      },
      bmItem: {
        display: 'inline-block',
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
      },
    };
    const freightCdList = [
      { text: '예약대기', value: 'IFRST001' },
      { text: '예약확정', value: 'IFRST003' },
      { text: '운송완료', value: 'IFRST005' },
      { text: '예약취소', value: 'IFRST007' },
    ];
    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div style={{ width: '100%', height: '50px' }}>
            <WebLogo
              style={{
                marginLeft: '10px',
                marginTop: '12px',
              }}
            />
            <Menu width={'70%'} styles={styles} right>
              <a id="home" className="menu-item">
                준비중입니다.
              </a>
            </Menu>
          </div>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h3>배차문자관리</h3>
          </div>
          <div style={{ paddingLeft: '10px' }}>
            <h4>문자 잔액 : {this.state.msgCharge}원</h4>
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
              margin: '0 auto',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <Accordion>
              {quoteStore.freightMiniList.length > 0
                ? quoteStore.freightMiniList.map((data, i) => (
                    <Fragment>
                      <Accordion.Title
                        active={activeIndex === i}
                        index={i}
                        onClick={() => this.handleClick({ ...data }, i)}
                        style={{
                          borderBottom: 'solid 1px #000',
                          height: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          color:
                            data.taskCd == '상담전'
                              ? COLOR_PRIMARY
                              : COLOR_GREEN_PRIMARY,
                          fontWeight: '700',
                        }}
                      >
                        {data.customerNm + ' | ' + data.customerHpNo}
                        <Icon name="dropdown" />
                      </Accordion.Title>

                      <Accordion.Content
                        active={activeIndex === i}
                        style={{ background: COLOR_GRAY_GR02 }}
                      >
                        <div style={{ paddingLeft: '10px' }}>
                          <div
                            style={{
                              fontWeight: '700',
                              width: '18%',
                              float: 'left',
                              height: '30px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            운송번호
                          </div>
                          <div
                            style={{
                              fontWeight: '400',
                              width: '32%',
                              height: '30px',
                              display: 'flex',
                              alignItems: 'center',
                              float: 'left',
                            }}
                          >
                            {data.freightNo}
                          </div>
                          <div
                            style={{
                              fontWeight: '700',
                              width: '18%',
                              float: 'left',
                              height: '30px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            운송구분
                          </div>
                          <div
                            style={{
                              fontWeight: '400',
                              width: '32%',
                              height: '30px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {data.logisCdNm}
                          </div>

                          <div
                            style={{
                              width: '97%',
                              background: COLOR_WHITE,
                              padding: '10px',
                            }}
                          >
                            <h4>문자내용</h4>
                            <p
                              dangerouslySetInnerHTML={{ __html: data.smsText }}
                            />
                          </div>
                          <div style={{ marginTop: '15px' }}>
                            <Button
                              color="instagram"
                              onClick={() => alert('준비중입니다.')}
                            >
                              고객SMS
                            </Button>
                            <Button
                              color="teal"
                              onClick={() => alert('준비중입니다.')}
                            >
                              트럭커SMS
                            </Button>
                            {data.taskCd == '상담전' ? (
                              <Button
                                color="orange"
                                onClick={() => this.fnUpdateTaskCd()}
                              >
                                상담완료
                              </Button>
                            ) : null}
                          </div>

                          <Form style={{ width: '100%', marginTop: '20px' }}>
                            <Form.Field>
                              <label>운송료</label>
                              <Input
                                style={{ width: '96%' }}
                                placeholder={'운송료 입력'}
                                value={quoteStore.logisFee}
                                onChange={e => {
                                  quoteStore.setLogisFee(e.target.value);
                                  quoteStore.setTotalFee(
                                    Number(e.target.value) +
                                      Number(quoteStore.commission),
                                  );
                                }}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>수수료</label>
                              <Input
                                style={{ width: '96%' }}
                                placeholder={'수수료 입력'}
                                value={quoteStore.commission}
                                onChange={e => {
                                  quoteStore.setCommission(e.target.value);
                                  quoteStore.setTotalFee(
                                    Number(e.target.value) +
                                      Number(quoteStore.logisFee),
                                  );
                                }}
                              />
                            </Form.Field>
                            <h3>
                              총 운임 :{' '}
                              {quoteStore.totalFee > 0
                                ? quoteStore.totalFee
                                : '운송료와 수수료를 입력해주세요.'}
                            </h3>
                            <Form.Field style={{ width: '100%' }}>
                              <label>운송상태</label>
                              <Select
                                style={{ width: '96%' }}
                                placeholder={'운송상태 선택'}
                                //defaultValue={data.freightCd}
                                value={this.state.freightCd}
                                options={freightCdList}
                                onChange={(e, { value }) =>
                                  this.setState({
                                    freightCd: value,
                                  })
                                }
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>트럭커 정보</label>
                              <Input
                                style={{ width: '96%' }}
                                placeholder={'트럭커 이름'}
                                defaultValue={this.state.driverNm || null}
                                onChange={e => {
                                  this.setState({
                                    driverNm: e.target.value,
                                  });
                                }}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Input
                                style={{ width: '96%' }}
                                placeholder={'트럭커 연락처'}
                                defaultValue={this.state.driverHpNo || null}
                                onChange={e => {
                                  this.setState({
                                    driverHpNo: e.target.value,
                                  });
                                }}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Input
                                style={{ width: '96%' }}
                                placeholder={'차량번호'}
                                defaultValue={this.state.vcNo || null}
                                onChange={e => {
                                  this.setState({
                                    vcNo: e.target.value,
                                  });
                                }}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>업무메모</label>
                              <TextArea
                                style={{ resize: 'none', width: '96%' }}
                                value={this.state.memo || ''}
                                onChange={e => {
                                  this.setState({
                                    memo: e.target.value,
                                  });
                                }}
                              />
                            </Form.Field>
                          </Form>

                          <div
                            style={{
                              marginTop: '15px',
                              textAlign: 'right',
                              paddingRight: '10px',
                            }}
                          >
                            <Button
                              color="green"
                              onClick={() => this.fnUpdateFreightMini()}
                            >
                              수정완료
                            </Button>
                          </div>

                          <div style={{ width: '100%', height: '30px' }} />
                        </div>
                      </Accordion.Content>
                    </Fragment>
                  ))
                : null}
            </Accordion>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1025}>
          <div style={{ width: '50%', margin: '0 auto' }}>
            <div style={{ width: '100%', height: '50px' }}>
              <WebLogo
                style={{
                  marginLeft: '10px',
                  marginTop: '12px',
                }}
              />
              <Menu width={'70%'} styles={styles} right>
                <a id="home" className="menu-item">
                  준비중입니다.
                </a>
              </Menu>
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3>배차문자관리</h3>
            </div>
            <div style={{ paddingLeft: '10px' }}>
              <h4>문자 잔액 : {this.state.msgCharge}원</h4>
            </div>
            <div
              style={{
                width: '100%',
                height: '100%',
                margin: '0 auto',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              <Accordion>
                {quoteStore.freightMiniList.length > 0
                  ? quoteStore.freightMiniList.map((data, i) => (
                      <Fragment>
                        <Accordion.Title
                          active={activeIndex === i}
                          index={i}
                          onClick={() => this.handleClick({ ...data }, i)}
                          style={{
                            borderBottom: 'solid 1px #000',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            color:
                              data.taskCd == '상담전'
                                ? COLOR_PRIMARY
                                : COLOR_GREEN_PRIMARY,
                            fontWeight: '700',
                          }}
                        >
                          {data.customerNm + ' | ' + data.customerHpNo}
                          <Icon name="dropdown" />
                        </Accordion.Title>

                        <Accordion.Content
                          active={activeIndex === i}
                          style={{ background: COLOR_GRAY_GR02 }}
                        >
                          <div style={{ paddingLeft: '10px' }}>
                            <div
                              style={{
                                fontWeight: '700',
                                width: '18%',
                                float: 'left',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              운송번호
                            </div>
                            <div
                              style={{
                                fontWeight: '400',
                                width: '32%',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                float: 'left',
                              }}
                            >
                              {data.freightNo}
                            </div>
                            <div
                              style={{
                                fontWeight: '700',
                                width: '18%',
                                float: 'left',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              운송구분
                            </div>
                            <div
                              style={{
                                fontWeight: '400',
                                width: '32%',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {data.logisCdNm}
                            </div>

                            <div
                              style={{
                                width: '97%',
                                background: COLOR_WHITE,
                                padding: '10px',
                              }}
                            >
                              <h4>문자내용</h4>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.smsText,
                                }}
                              />
                            </div>
                            <div style={{ marginTop: '15px' }}>
                              <Button
                                color="instagram"
                                onClick={() => alert('준비중입니다.')}
                              >
                                고객SMS
                              </Button>
                              <Button
                                color="teal"
                                onClick={() => alert('준비중입니다.')}
                              >
                                트럭커SMS
                              </Button>
                              {data.taskCd == '상담전' ? (
                                <Button
                                  color="orange"
                                  onClick={() => this.fnUpdateTaskCd()}
                                >
                                  상담완료
                                </Button>
                              ) : null}
                            </div>

                            <Form style={{ width: '100%', marginTop: '20px' }}>
                              <Form.Field>
                                <label>운송료</label>
                                <Input
                                  style={{ width: '96%' }}
                                  placeholder={'운송료 입력'}
                                  value={quoteStore.logisFee}
                                  onChange={e => {
                                    quoteStore.setLogisFee(e.target.value);
                                    quoteStore.setTotalFee(
                                      Number(e.target.value) +
                                        Number(quoteStore.commission),
                                    );
                                  }}
                                />
                              </Form.Field>
                              <Form.Field>
                                <label>수수료</label>
                                <Input
                                  style={{ width: '96%' }}
                                  placeholder={'수수료 입력'}
                                  value={quoteStore.commission}
                                  onChange={e => {
                                    quoteStore.setCommission(e.target.value);
                                    quoteStore.setTotalFee(
                                      Number(e.target.value) +
                                        Number(quoteStore.logisFee),
                                    );
                                  }}
                                />
                              </Form.Field>
                              <h3>
                                총 운임 :{' '}
                                {quoteStore.totalFee > 0
                                  ? quoteStore.totalFee
                                  : '운송료와 수수료를 입력해주세요.'}
                              </h3>
                              <Form.Field style={{ width: '100%' }}>
                                <label>운송상태</label>
                                <Select
                                  style={{ width: '96%' }}
                                  placeholder={'운송상태 선택'}
                                  //defaultValue={data.freightCd}
                                  value={this.state.freightCd}
                                  options={freightCdList}
                                  onChange={(e, { value }) =>
                                    this.setState({
                                      freightCd: value,
                                    })
                                  }
                                />
                              </Form.Field>
                              <Form.Field>
                                <label>트럭커 정보</label>
                                <Input
                                  style={{ width: '96%' }}
                                  placeholder={'트럭커 이름'}
                                  defaultValue={this.state.driverNm || null}
                                  onChange={e => {
                                    this.setState({
                                      driverNm: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Input
                                  style={{ width: '96%' }}
                                  placeholder={'트럭커 연락처'}
                                  defaultValue={this.state.driverHpNo || null}
                                  onChange={e => {
                                    this.setState({
                                      driverHpNo: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Input
                                  style={{ width: '96%' }}
                                  placeholder={'차량번호'}
                                  defaultValue={this.state.vcNo || null}
                                  onChange={e => {
                                    this.setState({
                                      vcNo: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Field>
                              <Form.Field>
                                <label>업무메모</label>
                                <TextArea
                                  style={{ resize: 'none', width: '96%' }}
                                  value={this.state.memo || ''}
                                  onChange={e => {
                                    this.setState({
                                      memo: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Field>
                            </Form>

                            <div
                              style={{
                                marginTop: '15px',
                                textAlign: 'right',
                                paddingRight: '10px',
                              }}
                            >
                              <Button
                                color="green"
                                onClick={() => this.fnUpdateFreightMini()}
                              >
                                수정완료
                              </Button>
                            </div>

                            <div style={{ width: '100%', height: '30px' }} />
                          </div>
                        </Accordion.Content>
                      </Fragment>
                    ))
                  : null}
              </Accordion>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(FreightMiniContainer);
