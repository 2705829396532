import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { API_EMP_PW_UPDATE } from '../../common/ApiAddress/EmpAPI';
import EmpPwUpdateModal from '../../Component/Login/EmpPwUpdateModal';
import { STR_SEN_20 } from '../../common/Terms';
@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
  empStore: stores.EmpStore,
}))
@observer
class DashBoard extends Component {
  state = {
    pwModal: false,
  };

  componentDidMount() {
    if (sessionStorage.getItem('initpwcheck') == 'true') {
      this.setState({
        pwModal: true,
      });
    }
  }

  //사원 비밀번호 설정 모달 닫기
  fnClosePwModal = () => {
    const { empStore } = this.props;
    empStore.initForm();
    this.setState({
      pwModal: false,
    });
  };

  //사원 비밀번호 업데이트
  fnUpdateEmpPw = () => {
    const { empStore } = this.props;
    let params = {};
    let that = this;
    let pw = empStore.pw;
    if (pw == null) {
      alert('비밀번호를 입력해주세요.');
      return false;
    }
    if (pw.length < 6) {
      alert('비밀번호는 6자리 이상입니다.');
      return false;
    }

    if (pw != empStore.checkPw) {
      alert('비밀번호를 확인해 주세요.');
      return false;
    }

    params.pw = empStore.pw;
    params.empNo = sessionStorage.getItem('empNo');
    axios({
      url: API_EMP_PW_UPDATE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.setState({
            pwModal: false,
          });
          //empStore.setEmpList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { accStore } = this.props;
    //회원현황 , 회원승인
    return (
      <React.Fragment>
        <p>This is DashBoard</p>

        <EmpPwUpdateModal
          modalHeader={STR_SEN_20}
          open={this.state.pwModal}
          onClose={this.fnClosePwModal}
          fnUpdateEmpPw={this.fnUpdateEmpPw}
        />
      </React.Fragment>
    );
  }
}

export default DashBoard;
