import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  empStore: stores.EmpStore,
}))
@observer
class CurrentList extends Component {
  render() {
    const { empStore } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>사원 번호</Table.HeaderCell>
            <Table.HeaderCell>담당자 이름</Table.HeaderCell>
            <Table.HeaderCell>담당자 연락처</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {empStore.empList == null || empStore.empList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                회원이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {empStore.empList.map((data, i) => (
              <React.Fragment>
                <Table.Row
                  onClick={() => {
                    this.props.fnViewOpen(data);
                  }}
                >
                  <Table.Cell>{data.empNo}</Table.Cell>
                  <Table.Cell>{data.empNm}</Table.Cell>
                  <Table.Cell>{data.hpNo}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default CurrentList;
