/*global AUTHNICE*/
import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Modal, Dimmer, Loader } from 'semantic-ui-react';
import { ko } from 'date-fns/esm/locale';
import DaumPostcode from 'react-daum-postcode';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {
  API_SELECT_COMCD,
  API_SELECT_VC_TYP_CD,
  API_SELECT_PAYMENT_TOOL_CD,
} from '../../common/ApiAddress/CommonAPI';
import {
  API_INSERT_FREIGHT,
  API_SELECT_LOGIS_FEE,
  API_INSERT_ORDER_INFO,
  API_INSERT_PAYMENT_INFO,
} from '../../common/ApiAddress/FreightAPI';
import { API_SELECT_GROUP_LOGIS_STAFF_LIST } from '../../common/ApiAddress/AccountAPI';
import {
  STR_DEPARTURE_ADD_SEARCH,
  STR_ARRIVE_ADD_SEARCH,
  STR_SEN_4,
  STR_SEN_5,
  STR_WAYPOINT_ADD_SEARCH_1,
  STR_WAYPOINT_ADD_SEARCH_2,
  STR_WAYPOINT_ADD_SEARCH_3,
  STR_SEN_8,
  STR_SELECT_LOADING_WAY,
  STR_SELECT_UNLOADING_WAY,
  STR_SEN_10,
  STR_SEN_12,
  STR_SEN_14,
  STR_SEN_15,
  STR_SEN_16,
  STR_SEN_17,
  STR_SEN_18,
  STR_SEN_19,
  STR_SEN_9,
  STR_SEN_21,
  STR_SEN_22,
} from '../../common/Terms';

import Map from './Map';
import SelectLoadingModal from '../../Component/Order/SelectLoadingModal';
import SelectUnLoadingModal from '../../Component/Order/SelectUnLoadingModal';
import CommonAlert from '../../Component/Common/CommonAlert';
import CommonAlertWithCallback from '../../Component/Common/CommonAlertWithCallback';
import CommonCorfirmModal from '../../Component/Common/CommonConfirmModal';
import FavoriteInsertModal from './FavoriteInsertModal';
import OrderForm from './OrderForm';
import {
  API_INSERT_FAVORITE,
  API_INSERT_FAVORITE_CATE,
  API_SELECT_FAVORITE_CATE_LIST,
} from '../../common/ApiAddress/FavoriteAPI';
import FavoriteListModal from './FavoriteListModal';
import FavoriteCateInsertModal from '../../Component/Management/Favorite/FavoriteCateInsertModal';
@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
  payStore: stores.PaymentStore,
  favoStore: stores.FavoriteStore,
}))
@observer
class OrderContainer extends Component {
  state = {
    //모달
    addDeparture: false,
    addArrival: false,
    addWaypoint1: false,
    addWaypoint2: false,
    addWaypoint3: false,
    loadingModal: false,
    unLoadingModal: false,
    insertCateModal: false,
    favoInsertModal: false,
    favoListModal: false,
    //출발지,도착지, 경유지 위경도
    departureX: null,
    departureY: null,
    arrivalX: null,
    arrivalY: null,
    coordsWaypoints: [],

    //출발지, 도착지 법정코드
    startBcode: null,
    endBcode: null,
    wayPoint1Bcode: null,
    wayPoint2Bcode: null,
    wayPoint3Bcode: null,

    //입력form관련
    wayPointsNo: 1,
    editablePrice: true,

    //map
    defaultZoom: 10,

    //logisStaffInfoFlag
    logisStaffFlag: true,
    calLogisFeeLoader: false,
  };

  componentDidMount() {
    this.fnSelectLogisCd();
    this.fnSelectVcWeightCd();
    this.fnSelectLoadingCd();
    this.fnSelectPaymentToolList();
    this.fnSelectLogisStaffList();
  }

  componentWillUnmount() {
    const { orderStore, vcStore } = this.props;
    orderStore.initForm();
    vcStore.initForOrder();
  }

  //화물구분 불러오기
  fnSelectLogisCd = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRDI';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let logisCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              logisCdList.push(params);
            }
            orderStore.setLogisCdList(logisCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량적재무게 타입 불러오기
  fnSelectVcWeightCd = () => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRWE';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let weightCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              params.syscdidx = response.data.data[i].sysCdIdx;
              weightCdList.push(params);
            }
            vcStore.setVcWeightCdList(weightCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량구분 타입 불러오기
  fnSelectVcTypCd = value => {
    const { vcStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRTY';
    if (value == '001') {
      params.sysCdIdx = value;
    } else if (value == '002') {
      params.sysCdIdx = value;
    } else {
      params.sysCdIdx = '';
    }
    axios({
      url: API_SELECT_VC_TYP_CD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let typCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              params.sysCdIdx = response.data.data[i].sysCdIdx;
              typCdList.push(params);
            }
            vcStore.setVcTypCdList(typCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //결제방법 불러오기
  fnSelectPaymentToolList = () => {
    const { payStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IPYTO';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let payToolList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              payToolList.push(params);
            }
            payStore.setPaymentToolList(payToolList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //그룹 내 스태프 리스트 불러오기
  fnSelectLogisStaffList = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.groupNo = sessionStorage.getItem('groupNo');

    axios({
      url: API_SELECT_GROUP_LOGIS_STAFF_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let logistStaffList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = {
                customerNm: response.data.data[i].customerNm,
                hpNo: response.data.data[i].hpNo,
              };
              params.text = response.data.data[i].customerNm;
              logistStaffList.push(params);
            }
            let params = {};
            params.value = 'insert';
            params.text = '직접입력';
            logistStaffList.push(params);
            commonStore.setLogisStaffList(logistStaffList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //결제수단 불러오기
  fnSelectPaymentTypeList = value => {
    const { payStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IPYTY';
    params.sysCdIdx = value;

    axios({
      url: API_SELECT_PAYMENT_TOOL_CD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let payTypeList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              payTypeList.push(params);
            }
            payStore.setPaymentTypeList(payTypeList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //차량중량구분 관련 함수들
  fnSetVcWeightCd = (e, data) => {
    const { vcStore, orderStore } = this.props;
    vcStore.setVcWeightCd(data.value);
    vcStore.initVcTypCd();
    let syscdidx = data.value.substring(5, 8);
    this.fnSelectVcTypCd(syscdidx);

    //화물중량 자동세팅
    if (data.value == 'IFRWE001') {
      orderStore.setFreightWeight('330');
    }
    if (data.value == 'IFRWE002') {
      orderStore.setFreightWeight('550');
    }
    if (data.value == 'IFRWE003') {
      orderStore.setFreightWeight('1100');
    }
    if (data.value == 'IFRWE004') {
      orderStore.setFreightWeight('1540');
    }
    if (data.value == 'IFRWE005') {
      orderStore.setFreightWeight('2750');
    }
    if (data.value == 'IFRWE006') {
      orderStore.setFreightWeight('3850');
    }
    if (data.value == 'IFRWE007') {
      orderStore.setFreightWeight('3850');
    }
    if (data.value == 'IFRWE008') {
      orderStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE009') {
      orderStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE010') {
      orderStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE011') {
      orderStore.setFreightWeight('5500');
    }
    if (data.value == 'IFRWE012') {
      orderStore.setFreightWeight('8800');
    }
    if (data.value == 'IFRWE013') {
      orderStore.setFreightWeight('11000');
    }
    if (data.value == 'IFRWE014') {
      orderStore.setFreightWeight('15400');
    }
    if (data.value == 'IFRWE015') {
      orderStore.setFreightWeight('19800');
    }
    if (data.value == 'IFRWE016') {
      orderStore.setFreightWeight('27500');
    }
  };
  //차량구분 선택함수
  fnSetVcTypCd = value => {
    const { vcStore } = this.props;
    vcStore.setVcTypCd(value);
  };

  //상차지 주소 검색 완료
  fnHandleCompleteDeparture = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addDeparture: !this.state.addDeparture,
      startBcode: data.bcode,
    });
    orderStore.setDepartureAdd(fullAddress);
    console.log(fullAddress);
    this.fnGetcoords(fullAddress, 'D');
  };

  //하차지 주소 검색 완료
  fnHandleCompleteArrive = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addArrival: !this.state.addArrival,
      endBcode: data.bcode,
    });
    orderStore.setArriveAdd(fullAddress);
    this.fnGetcoords(fullAddress, 'A');
  };

  fnGetcoords = (value, typ, index) => {
    const { orderStore } = this.props;
    let that = this;
    axios({
      url: 'https://teamtrucker.co.kr/naver/geocode',
      method: 'get',
      params: {
        query: value,
      },
    })
      .then(function (response) {
        if (typ === 'D') {
          //상차지
          that.setState({
            departureX: response.data.items.addresses[0].x,
            departureY: response.data.items.addresses[0].y,
          });
          orderStore.setDepartureCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          //경유지
        } else if (typ === 'W') {
          let wayPoint = {};
          let wayPointX = response.data.items.addresses[0].x;
          let wayPointY = response.data.items.addresses[0].y;
          wayPoint.wayPointX = wayPointX;
          wayPoint.wayPointY = wayPointY;

          let wayPointList = [...that.state.coordsWaypoints];
          wayPointList.splice(index - 1, 1, wayPoint);
          that.setState({
            coordsWaypoints: wayPointList,
          });
          if (that.state.arrivalX != null) {
            that.fnGetPath();
          }
        } else {
          //하차지
          that.setState({
            arrivalX: response.data.items.addresses[0].x,
            arrivalY: response.data.items.addresses[0].y,
          });
          orderStore.setArriveCoord(
            response.data.items.addresses[0].x +
              ',' +
              response.data.items.addresses[0].y,
          );
          //경로 구하는 함수로 이동
          that.fnGetPath();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetPath = () => {
    const { orderStore } = this.props;
    let start = this.state.departureX + ',' + this.state.departureY;
    let goal = this.state.arrivalX + ',' + this.state.arrivalY;
    let waypointsList = [...this.state.coordsWaypoints];
    let waypoints = null;

    for (let i = 0; i < waypointsList.length; i++) {
      if (i == 0) {
        waypoints =
          waypointsList[i].wayPointX + ',' + waypointsList[i].wayPointY;
      }
      if (i >= 1) {
        waypoints =
          waypoints +
          '|' +
          waypointsList[i].wayPointX +
          ',' +
          waypointsList[i].wayPointY;
      }
    }

    let that = this;
    axios({
      url: 'https://teamtrucker.co.kr/naver/drive',
      method: 'get',
      params: {
        start: start,
        goal: goal,
        waypoints: waypoints == null ? null : waypoints,
      },
    })
      .then(function (response) {
        let distance = response.data.items[0].summary.distance * 0.001;
        let second = response.data.items[0].summary.duration * 0.001;
        second = second + 3600;
        let hour = parseInt(second / 3600);
        let min = parseInt((second % 3600) / 60);

        if (parseInt(distance) < 50) {
          that.setState({
            defaultZoom: 12,
          });
        }

        if (50 < parseInt(distance) && parseInt(distance) < 100) {
          that.setState({
            defaultZoom: 9,
          });
        }
        if (100 < parseInt(distance) && parseInt(distance) < 150) {
          that.setState({
            defaultZoom: 8,
          });
        }
        if (150 < parseInt(distance)) {
          that.setState({
            defaultZoom: 7,
          });
        }

        orderStore.setDistance(distance + 'km');
        if (hour > 0) {
          orderStore.setDuration(hour + '시간' + min + '분');
        } else {
          orderStore.setDuration(min + '분');
        }
        orderStore.setOrderPath(response.data.items[0].path);
        // const optionSuccess = {
        //   style: { color: 'green' },
        // };

        // 가격 계산을 거리만으로 측정할지 위경도로 요율을 정할지 내용좀 확인해봐야 할듯

        // 거리(KM)당 가격 측정중
        // 2000원부터 계산 했으나 1000원도 거리가 길어지면 너무 많은 비용이 발생함
        // 천원단위 계산을 위해 2km당 1000원씩 으로 계산함
        // 기름값이 리터당 1600원이 넘는 지금 시점에서 연비가 리터당 3km 이하로 운행되면 장거리의 경우 적자가 될수 있음

        //let fee_per_distance = (parseInt(distance) / 2) * 1000;

        // 출발점 가격 - 톤수에 따른 기본가격 차이발생 예상
        //let fee_per_start = 15000;

        // 도착점 가격 - 톤수에 따른 기본가격 차이발생 예상
        //let fee_per_end = 15000;

        // 각종 옵션들에 따른 가격
        //let fee_option = 0;

        //let fee_total = fee_per_start + fee_per_end + fee_per_distance;

        // toast(
        //   '거리 : ' +
        //     orderStore.distance +
        //     '/ 시간 : ' +
        //     orderStore.duration +
        //     '  /  ' +
        //     fee_total +
        //     ' 원',
        //   optionSuccess,
        // );
      })
      .catch(function (error) {
        toast(error);
        console.log(error);
      });
  };

  fnOpenDepartureModal = () => {
    this.setState({
      addDeparture: !this.state.addDeparture,
    });
  };

  fnOpenArriveModal = () => {
    this.setState({
      addArrival: !this.state.addArrival,
    });
  };

  fnSetDepartureDetailAdd = value => {
    const { orderStore } = this.props;
    orderStore.setDepartureDetailAdd(value);
  };

  fnSetArriveDetailAdd = value => {
    const { orderStore } = this.props;
    orderStore.setArriveDetailAdd(value);
  };

  fnOnchangeLogisCd = value => {
    const { orderStore } = this.props;
    orderStore.setLogisCd(value);
  };

  //경유지 관련 함수
  //경유지 증가
  fnIncreaseWayPoint = () => {
    const { orderStore, commonStore } = this.props;
    if (this.state.wayPointsNo <= 3) {
      orderStore.setWayPointsList(this.state.wayPointsNo, 'add', {});
      let freightInfo = orderStore.freightInfo;
      let addFreightInfo = null;
      if (freightInfo == null) {
        addFreightInfo = '경유지' + this.state.wayPointsNo + ' 특이사항 : ';
      } else {
        addFreightInfo =
          freightInfo + '\n경유지' + this.state.wayPointsNo + ' 특이사항 : ';
      }
      orderStore.setFreightInfo(addFreightInfo);
      this.setState({
        wayPointsNo: this.state.wayPointsNo + 1,
      });
    }
  };

  //경유지 삭제
  fnDecreaseWayPoint = index => {
    const { orderStore } = this.props;
    orderStore.setWayPointsList(index, 'remove');
    this.setState({
      wayPointsNo: this.state.wayPointsNo - 1,
    });

    //위경도 리스트 삭제
    let spreadCoords = [...this.state.coordsWaypoints];
    spreadCoords.splice(index, 1);
    this.setState({
      coordsWaypoints: spreadCoords,
    });

    if (index == 0) {
      this.setState({
        wayPoint1Bcode: null,
      });
    }
    if (index == 1) {
      this.setState({
        wayPoint2Bcode: null,
      });
    }
    if (index == 2) {
      this.setState({
        wayPoint3Bcode: null,
      });
    }
    setTimeout(this.fnGetPath, 500);
  };

  //경유지 주소 검색 모달 오픈
  fnOpenWayPointModal = index => {
    if (index == 1) {
      this.setState({
        addWaypoint1: !this.state.addWaypoint1,
      });
    } else if (index == 2) {
      this.setState({
        addWaypoint2: !this.state.addWaypoint2,
      });
    } else if (index == 3) {
      this.setState({
        addWaypoint3: !this.state.addWaypoint3,
      });
    }
  };

  //경유지1 주소 검색 완료
  fnHandleCompleteWayPoint1 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint1: !this.state.addWaypoint1,
      wayPoint1Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(1, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 1);
  };

  //경유지2 주소 검색 완료
  fnHandleCompleteWayPoint2 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint2: !this.state.addWaypoint2,
      wayPoint2Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(2, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 2);
  };

  //경유지3 주소 검색 완료
  fnHandleCompleteWayPoint3 = data => {
    const { orderStore } = this.props;
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    this.setState({
      addWaypoint3: !this.state.addWaypoint3,
      wayPoint3Bcode: data.bcode,
    });

    orderStore.setWayPointsBasicAdd(3, fullAddress); //mobx 주소 담기
    this.fnGetcoords(fullAddress, 'W', 3);
  };

  //경유지 상세주소
  fnSetWayPointDetailAdd = (index, value) => {
    const { orderStore } = this.props;
    orderStore.setWayPointsDetailAdd(index, value);
  };

  //상하차 방법 불러오기
  fnSelectLoadingCd = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IFRLO';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let loadingCdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = response.data.data[i].sysCd;
              params.text = response.data.data[i].sysCdNm;
              loadingCdList.push(params);
            }
            orderStore.setLoadingCdList(loadingCdList);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //상차방법 모달 열기
  fnSelectLoadingModalOpen = () => {
    this.setState({
      loadingModal: !this.state.loadingModal,
    });
  };
  //상차방법 모달 닫기
  fnSelectLoadingModalClose = () => {
    const { orderStore } = this.props;
    orderStore.initLoadingCd();
    orderStore.initLoadingNm();
    // this.setState({
    //   loadingModal: !this.state.loadingModal,
    // });
  };
  // 상차방법 선택 후 모달 닫기
  fnSetSelectLoading = value => {
    const { orderStore } = this.props;
    orderStore.setLoadingCd(value);
    //orderStore.setLoadingNm(value.text);
    // this.setState({
    //   loadingModal: !this.state.loadingModal,
    // });
  };

  //하차방법 모달 열기
  fnSelectUnLoadingModalOpen = () => {
    this.setState({
      unLoadingModal: !this.state.unLoadingModal,
    });
  };

  //하차방법 모달 닫기
  fnSelectUnLoadingModalClose = () => {
    const { orderStore } = this.props;
    orderStore.initUnLoadingCd();
    orderStore.initUnLoadingNm();
    // this.setState({
    //   unLoadingModal: !this.state.unLoadingModal,
    // });
  };
  // 하차방법선택 후 모달 닫기
  fnSetSelectUnLoading = value => {
    const { orderStore } = this.props;
    orderStore.setUnLoadingCd(value);
    orderStore.setUnLoadingNm(value.text);
    // this.setState({
    //   unLoadingModal: !this.state.unLoadingModal,
    // });
  };

  //화물운임 계산
  fnCalLogisFee = () => {
    const { orderStore, vcStore } = this.props;
    let params = {};
    let that = this;

    this.setState({
      calLogisFeeLoader: true,
    });

    params.startBcode = this.state.startBcode;
    params.endBcode = this.state.endBcode;

    params.logisCd = orderStore.logisCd; //화물운송구분
    params.freightWeight = orderStore.freightWeight; // 중량
    params.loadingCd = orderStore.loadingCd; // 상차방법
    params.unloadingCd = orderStore.unloadingCd; //하차방법
    params.loadingDate = moment(orderStore.loadingDate).format(
      'YYYY-MM-DD HH:mm',
    ); // 상차시간
    params.unloadingDate = moment(orderStore.unloadingDate).format(
      'YYYY-MM-DD HH:mm',
    ); // 하차시간

    params.distance = orderStore.distance; // 거리
    params.duration = orderStore.duration; // 예상시간

    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;

    let validation = this.fnValidation();

    if (validation == true) {
      axios({
        url: API_SELECT_LOGIS_FEE,
        method: 'post',
        data: params,
      })
        .then(function (response) {
          if (response.data.resultCode == 100) {
            setTimeout(that.fnCalLogisFeeLoaderClose, 2000);
            orderStore.setLogisFee(response.data.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.setState({
        calLogisFeeLoader: false,
      });
      return false;
    }
  };

  //운임계산 로더 닫기
  fnCalLogisFeeLoaderClose = () => {
    this.setState({ calLogisFeeLoader: false, editablePrice: false });
  };

  //화물 운송 생성
  fnInsertFreight = () => {
    const { orderStore, vcStore, payStore, commonStore } = this.props;
    let params = {};
    let that = this;
    params.loadingBasicAdd = orderStore.departureAdd;
    params.loadingDetailAdd = orderStore.departureDetailAdd;
    params.loadingCoord = orderStore.departureCoord;
    params.loadingBcode = this.state.startBcode;
    params.unloadingBasicAdd = orderStore.arriveAdd;
    params.unloadingDetailAdd = orderStore.arriveDetailAdd;
    params.unloadingCoord = orderStore.arriveCoord;
    params.unloadingBcode = this.state.endBcode;

    //경유지
    params.waypoint1BasicAdd =
      orderStore.wayPointsList[0] != undefined
        ? orderStore.wayPointsList[0].basicAdd
        : null;
    params.waypoint1DetailAdd =
      orderStore.wayPointsList[0] != undefined
        ? orderStore.wayPointsList[0].detailAdd
        : null;
    params.waypoint1Coord =
      this.state.coordsWaypoints[0] != undefined
        ? this.state.coordsWaypoints[0].wayPointX +
          ',' +
          this.state.coordsWaypoints[0].wayPointY
        : null;
    params.waypoint1Bcode =
      orderStore.wayPointsList[0] != undefined
        ? this.state.wayPoint1Bcode
        : null;

    params.waypoint2BasicAdd =
      orderStore.wayPointsList[1] != undefined
        ? orderStore.wayPointsList[1].basicAdd
        : null;
    params.waypoint2DetailAdd =
      orderStore.wayPointsList[1] != undefined
        ? orderStore.wayPointsList[1].detailAdd
        : null;
    params.waypoint2Coord =
      this.state.coordsWaypoints[1] != undefined
        ? this.state.coordsWaypoints[1].wayPointX +
          ',' +
          this.state.coordsWaypoints[1].wayPointY
        : null;
    params.waypoint2Bcode =
      orderStore.wayPointsList[1] != undefined
        ? this.state.wayPoint2Bcode
        : null;

    params.waypoint3BasicAdd =
      orderStore.wayPointsList[2] != undefined
        ? orderStore.wayPointsList[2].basicAdd
        : null;
    params.waypoint3DetailAdd =
      orderStore.wayPointsList[2] != undefined
        ? orderStore.wayPointsList[2].detailAdd
        : null;
    params.waypoint3Coord =
      this.state.coordsWaypoints[2] != undefined
        ? this.state.coordsWaypoints[2].wayPointX +
          ',' +
          this.state.coordsWaypoints[2].wayPointY
        : null;

    params.waypoint3Bcode =
      orderStore.wayPointsList[2] != undefined
        ? this.state.wayPoint3Bcode
        : null;

    params.logisFee = orderStore.logisFee;
    params.freightInfo = orderStore.freightInfo;
    params.logisCd = orderStore.logisCd;
    params.freightWeight = orderStore.freightWeight;
    params.loadingCd = orderStore.loadingCd;
    params.unloadingCd = orderStore.unloadingCd;
    params.loadingDate =
      orderStore.loadingDate == null
        ? null
        : moment(orderStore.loadingDate).format('YYYY-MM-DD HH:mm');
    params.unloadingDate =
      orderStore.unloadingDate == null
        ? null
        : moment(orderStore.unloadingDate).format('YYYY-MM-DD HH:mm');

    params.vcCnt = orderStore.vcCnt;
    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;

    params.distance = orderStore.distance;
    params.duration = orderStore.duration;

    params.registId = sessionStorage.getItem('customerId');
    params.customerId = sessionStorage.getItem('customerId');
    params.groupNo = sessionStorage.getItem('groupNo');
    params.customerNo = sessionStorage.getItem('customerNo');

    //상차지 담당자
    if (
      orderStore.loadingStaffRank == null ||
      orderStore.loadingStaffRank == ''
    ) {
      params.loadingStaffInfo = orderStore.loadingStaffNm + '|';
    } else {
      params.loadingStaffInfo =
        orderStore.loadingStaffNm + '|' + orderStore.loadingStaffRank;
    }
    params.loadingStaffHpNo = orderStore.loadingStaffHpNo;

    //하차지 담당자
    if (
      orderStore.unloadingStaffRank == null ||
      orderStore.unloadingStaffRank == ''
    ) {
      params.unloadingStaffInfo = orderStore.unloadingStaffNm + '|';
    } else {
      params.unloadingStaffInfo =
        orderStore.unloadingStaffNm + '|' + orderStore.unloadingStaffRank;
    }
    params.unloadingStaffHpNo = orderStore.unloadingStaffHpNo;

    let validation = this.fnValidation();
    if (validation == true) {
      axios({
        url: API_INSERT_FREIGHT,
        method: 'post',
        data: params,
      })
        .then(response => {
          if (response.data.resultCode == 100) {
            //결제 페이지 이동 필요한 경우(카드)
            if (payStore.paymentTo == 'IPYTO002') {
              // 주문정보 등록
              this.fnInsertOrderInfo(response.data.data);
            } else {
              //바로 결제 정보 입력으로 이동
              this.fnInsertPayment(response.data.data);
            }
          } else {
            commonStore.commonAlertOpen(
              '운송등록에 실패하였습니다. 관리자에 문의하세요.',
            );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //화물등록 유효성 검사
  fnValidation = () => {
    const { commonStore, orderStore, vcStore, payStore } = this.props;

    if (orderStore.departureAdd == null || orderStore.departureAdd == '') {
      commonStore.commonAlertOpen(STR_SEN_10);
      return false;
    }

    if (orderStore.arriveAdd == null || orderStore.arriveAdd == '') {
      commonStore.commonAlertOpen(STR_SEN_12);
      return false;
    }

    if (vcStore.vcWeightCd == null || vcStore.vcWeightCd == '') {
      commonStore.commonAlertOpen(STR_SEN_4);
      return false;
    }

    if (vcStore.vcTypCd == null || vcStore.vcTypCd == '') {
      commonStore.commonAlertOpen(STR_SEN_5);
      return false;
    }

    if (orderStore.logisCd == null || orderStore.logisCd == '') {
      commonStore.commonAlertOpen(STR_SEN_15);
      return false;
    }

    if (orderStore.freightInfo == null || orderStore.freightInfo == '') {
      commonStore.commonAlertOpen(STR_SEN_8);
      return false;
    }

    if (orderStore.freightWeight == null || orderStore.freightWeight == '') {
      commonStore.commonAlertOpen(STR_SEN_9);
      return false;
    }

    if (orderStore.loadingCd == null || orderStore.loadingCd == '') {
      commonStore.commonAlertOpen(STR_SEN_16);
      return false;
    }

    if (orderStore.unloadingCd == null || orderStore.unloadingCd == '') {
      commonStore.commonAlertOpen(STR_SEN_17);
      return false;
    }

    if (payStore.paymentTo == null || payStore.paymentTo == '') {
      commonStore.commonAlertOpen(STR_SEN_21);
      return false;
    }

    if (payStore.paymentTy == null || payStore.paymentTy == '') {
      commonStore.commonAlertOpen(STR_SEN_22);
      return false;
    }

    if (orderStore.departureCoord == null || orderStore.departureCoord == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.arriveCoord == null || orderStore.arriveCoord == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.distance == null || orderStore.distance == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    if (orderStore.duration == null || orderStore.duration == '') {
      commonStore.commonAlertOpen(STR_SEN_14);
      return false;
    }

    return true;
  };

  //결제방법 선택 handler
  fnSetPaymentTo = (e, data) => {
    const { payStore } = this.props;
    payStore.setPaymentTo(data.value);
    payStore.initPaymentTy();
    let sysCdIdx = data.value.substring(5, 8);
    this.fnSelectPaymentTypeList(sysCdIdx);
  };

  //결제수단 선택함수
  fnSetPaymentTy = value => {
    const { payStore } = this.props;
    payStore.setPaymentTy(value);
  };

  //주문정보 저장
  fnInsertOrderInfo = data => {
    const { orderStore, commonStore } = this.props;
    let params = {};
    let that = this;
    params.orderNm =
      '상차지: ' +
      orderStore.departureAdd +
      orderStore.departureDetailAdd +
      '하차지: ' +
      orderStore.arriveAdd +
      orderStore.arriveDetailAdd;
    params.orderAmt = data.logisfee;
    params.registId = sessionStorage.getItem('customerId');
    params.freightNo = data.freightno; //freightNo List
    params.logisfeeList = data.logisfeelist;
    params.buyerName = data.customernm;
    params.buyerTel = data.customerhpno;
    params.buyerEmail = data.email;

    axios({
      url: API_INSERT_ORDER_INFO,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          //결제 모듈 열기
          this.fnOpenPayModule(response.data.data, params);
        } else {
          commonStore.commonAlertWithCallbackOpen(
            '주문정보 등록에 실패 하였습니다. 관리자에 문의하세요.',
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //결제창 열기
  fnOpenPayModule = (orderData, payData) => {
    AUTHNICE.requestPay({
      clientId: process.env.REACT_APP_NICEPAY_CLIENT_ID,
      method: 'card',
      orderId: orderData.orderno,
      amount: Number(payData.orderAmt),
      goodsName: sessionStorage.getItem('customerId') + orderData.orderno,
      buyerName: payData.buyerName,
      buyerTel: payData.buyerTel,
      buyerEmail: payData.buyerEmail,
      mallUserId: sessionStorage.getItem('customerId'),
      mallReserved: JSON.stringify({
        logisfeeList: payData.logisfeeList,
        freightNo: payData.freightNo,
      }),
      returnUrl: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'serverAuth', //API를 호출할 Endpoint 입력
      fnError: function (result) {
        alert('개발자확인용 : ' + result.errorMsg + '');
      },
    });
  };

  //결제 성공시 결제 정보 입력
  fnInsertPayment = payData => {
    const { payStore, commonStore } = this.props;
    let params = {};
    //params.paymentTo = payStore.paymentTo;
    //params.paymentTy = payStore.paymentTy;
    params.paymentTo = 'IPYTO003';
    params.paymentTy = 'IPYTY006';

    if (payStore.paymentTo == 'IPYTO001') {
      //선착불일경우(결제준비)
      //추후 트럭커앱에서 상태 변경 해야함
      params.paymentSt = 'IPYST001';
    } else {
      //인수증일경우(결제완료)
      params.paymentSt = 'IPYST003';
    }
    params.payerId = sessionStorage.getItem('customerId');
    params.payerNm = payData.customernm;
    params.payerHpNo = payData.customerhpno;
    params.payerEmail = payData.email;
    params.logisfeeList = payData.logisfeelist;
    params.freightNo = payData.freightno;
    params.registId = sessionStorage.getItem('customerId');
    let that = this;
    axios({
      url: API_INSERT_PAYMENT_INFO,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          commonStore.commonConfirmOpen(
            '운송등록이 완료되었습니다. 해당 운송을 즐겨찾기에 추가하시겠습니까?',
          );
        } else {
          commonStore.commonAlertWithCallbackOpen('운송등록에 실패하였습니다.');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnReloadAfterPayment = () => {
    window.location.reload();
  };

  //상차지 담당자 선택 handler
  fnSetLodingLogistStaff = (e, data) => {
    const { orderStore } = this.props;
    if (data.value == 'insert') {
      orderStore.initLoadingStaffNm();
      orderStore.initLoadingStaffHpNo();
      orderStore.initLoadingStaffRank();
      this.setState({
        logisStaffFlag: false,
      });
    } else {
      orderStore.setLoadingStaffNm(data.value.customerNm);
      orderStore.setLoadingStaffHpNo(data.value.hpNo);
      this.setState({
        logisStaffFlag: true,
      });
    }
  };

  //즐겨찾기 추가
  fnInsertFavorite = () => {
    const { orderStore, vcStore, favoStore } = this.props;
    let params = {};
    let that = this;
    params.cateNo = favoStore.selectedCate.cateNo;
    params.favoNm = favoStore.favoNm;

    params.loadingBasicAdd = orderStore.departureAdd;
    params.loadingDetailAdd = orderStore.departureDetailAdd;
    params.loadingCoord = orderStore.departureCoord;
    params.loadingBcode = this.state.startBcode;
    params.unloadingBasicAdd = orderStore.arriveAdd;
    params.unloadingDetailAdd = orderStore.arriveDetailAdd;
    params.unloadingCoord = orderStore.arriveCoord;
    params.unloadingBcode = this.state.endBcode;

    //경유지
    params.waypoint1BasicAdd =
      orderStore.wayPointsList[0] != undefined
        ? orderStore.wayPointsList[0].basicAdd
        : null;
    params.waypoint1DetailAdd =
      orderStore.wayPointsList[0] != undefined
        ? orderStore.wayPointsList[0].detailAdd
        : null;
    params.waypoint1Coord =
      this.state.coordsWaypoints[0] != undefined
        ? this.state.coordsWaypoints[0].wayPointX +
          ',' +
          this.state.coordsWaypoints[0].wayPointY
        : null;
    params.waypoint1Bcode =
      orderStore.wayPointsList[0] != undefined
        ? this.state.wayPoint1Bcode
        : null;

    params.waypoint2BasicAdd =
      orderStore.wayPointsList[1] != undefined
        ? orderStore.wayPointsList[1].basicAdd
        : null;
    params.waypoint2DetailAdd =
      orderStore.wayPointsList[1] != undefined
        ? orderStore.wayPointsList[1].detailAdd
        : null;
    params.waypoint2Coord =
      this.state.coordsWaypoints[1] != undefined
        ? this.state.coordsWaypoints[1].wayPointX +
          ',' +
          this.state.coordsWaypoints[1].wayPointY
        : null;
    params.waypoint2Bcode =
      orderStore.wayPointsList[1] != undefined
        ? this.state.wayPoint2Bcode
        : null;

    params.waypoint3BasicAdd =
      orderStore.wayPointsList[2] != undefined
        ? orderStore.wayPointsList[2].basicAdd
        : null;
    params.waypoint3DetailAdd =
      orderStore.wayPointsList[2] != undefined
        ? orderStore.wayPointsList[2].detailAdd
        : null;
    params.waypoint3Coord =
      this.state.coordsWaypoints[2] != undefined
        ? this.state.coordsWaypoints[2].wayPointX +
          ',' +
          this.state.coordsWaypoints[2].wayPointY
        : null;

    params.waypoint3Bcode =
      orderStore.wayPointsList[2] != undefined
        ? this.state.wayPoint3Bcode
        : null;

    params.logisFee = orderStore.logisFee;
    params.freightInfo = orderStore.freightInfo;
    params.logisCd = orderStore.logisCd;
    params.freightWeight = orderStore.freightWeight;
    params.loadingCd = orderStore.loadingCd;
    params.unloadingCd = orderStore.unloadingCd;
    params.loadingDate = moment(orderStore.loadingDate).format(
      'YYYY-MM-DD HH:mm',
    );
    params.unloadingDate = moment(orderStore.unloadingDate).format(
      'YYYY-MM-DD HH:mm',
    );

    params.vcCnt = orderStore.vcCnt;
    params.vcWeightCd = vcStore.vcWeightCd;
    params.vcTypCd = vcStore.vcTypCd;

    params.distance = orderStore.distance;
    params.duration = orderStore.duration;

    params.registId = sessionStorage.getItem('customerId');
    params.customerId = sessionStorage.getItem('customerId');
    params.groupNo = sessionStorage.getItem('groupNo');
    params.customerNo = sessionStorage.getItem('customerNo');

    //상하차지 담당자
    params.loadingStaffInfo =
      orderStore.loadingStaffNm + '|' + orderStore.loadingStaffRank;
    params.loadingStaffHpNo = orderStore.loadingStaffHpNo;
    params.unloadingStaffInfo =
      orderStore.unloadingStaffNm + '|' + orderStore.unloadingStaffRank;
    params.unloadingStaffHpNo = orderStore.unloadingStaffHpNo;

    axios({
      url: API_INSERT_FAVORITE,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.fnFavoInsertModalCloseWithRefresh();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnConfirmYes = () => {
    const { commonStore } = this.props;
    commonStore.commonConfirmClose();
    this.setState({
      favoInsertModal: true,
    });
  };

  fnConfirmNo = () => {
    const { commonStore } = this.props;
    commonStore.commonConfirmClose();
    window.location.reload();
  };

  fnFavoInsertModalClose = () => {
    this.setState({
      favoInsertModal: false,
    });
    this.fnFavoInsertModalCloseWithRefresh();
  };

  fnFavoInsertModalCloseWithRefresh = () => {
    this.setState({
      favoInsertModal: false,
    });
    window.location.reload();
  };

  fnFavoListModalOpen = () => {
    this.setState({
      favoListModal: true,
    });
  };

  fnFavoListModalClose = () => {
    const { favoStore } = this.props;
    this.setState({
      favoListModal: false,
    });
    favoStore.initSelectedCate();
    favoStore.initFavoList();
    favoStore.initSelectedFavo();
  };

  fnSetOrderForm = () => {
    const { orderStore, favoStore, vcStore } = this.props;
    let selectedFavo = { ...favoStore.selectedFavo };

    orderStore.setLogisCd(selectedFavo.logisCd);
    orderStore.setFreightInfo(selectedFavo.freightInfo);
    orderStore.setVcCnt(selectedFavo.vcCnt);
    orderStore.setFreightWeight(selectedFavo.freightWeight);
    orderStore.setLoadingCd(selectedFavo.loadingCd);
    orderStore.setLoadingNm(selectedFavo.loadingCdNm);
    orderStore.setUnLoadingCd(selectedFavo.unloadingCd);
    orderStore.setUnLoadingNm(selectedFavo.unloadingCdNm);

    //상차지주소
    orderStore.setDepartureAdd(selectedFavo.loadingBasicAdd);
    orderStore.setDepartureDetailAdd(selectedFavo.loadingDetailAdd);
    orderStore.setDepartureCoord(selectedFavo.loadingCoord);
    //하차지주소
    orderStore.setArriveAdd(selectedFavo.unloadingBasicAdd);
    orderStore.setArriveDetailAdd(selectedFavo.unloadingDetailAdd);
    orderStore.setArriveCoord(selectedFavo.unloadingCoord);

    //경유지주소
    if (selectedFavo.waypoint1BasicAdd != null) {
      orderStore.setWayPointsList(0, 'add', {
        basicAdd: selectedFavo.waypoint1BasicAdd,
        detailAdd: selectedFavo.waypoint1DetailAdd,
      });
    }
    if (selectedFavo.waypoint2BasicAdd != null) {
      orderStore.setWayPointsList(1, 'add', {
        basicAdd: selectedFavo.waypoint2BasicAdd,
        detailAdd: selectedFavo.waypoint2DetailAdd,
      });
    }
    if (selectedFavo.waypoint3BasicAdd != null) {
      orderStore.setWayPointsList(2, 'add', {
        basicAdd: selectedFavo.waypoint3BasicAdd,
        detailAdd: selectedFavo.waypoint3DetailAdd,
      });
    }

    //차량관련
    vcStore.setVcTypCd(selectedFavo.vcTypCd);
    vcStore.setVcWeightCd(selectedFavo.vcWeightCd);

    //상하차지 담당자
    let loadingStaffNm = selectedFavo.loadingStaffInfo.split('|');
    orderStore.setLoadingStaffNm(loadingStaffNm[0]);
    orderStore.setLoadingStaffRank(loadingStaffNm[1]);
    orderStore.setLoadingStaffHpNo(selectedFavo.loadingStaffHpNo);

    let unloadingStaffNm = selectedFavo.unloadingStaffInfo.split('|');
    orderStore.setUnLoadingStaffNm(unloadingStaffNm[0]);
    orderStore.setUnLoadingStaffRank(unloadingStaffNm[1]);
    orderStore.setUnLoadingStaffHpNo(selectedFavo.unloadingStaffHpNo);
    this.fnFavoListModalClose();

    this.fnSelectVcWeightCd();
    let sysCd = vcStore.vcWeightCd;
    let sysCdIdx = sysCd.substring(5, 8);
    this.fnSelectVcTypCd(sysCdIdx);
    this.fnSelectLoadingCd();

    let departureCoord = orderStore.departureCoord;
    let departureCoordSpl = departureCoord.split(',');

    let arriveCoord = orderStore.arriveCoord;
    let arriveCoordSpl = arriveCoord.split(',');

    let coordsWaypoints = [];
    if (selectedFavo.waypoint1Coord != null) {
      let waypoint1Coord = selectedFavo.waypoint1Coord;
      let waypoint1CoordSpl = waypoint1Coord.split(',');
      waypoint1Coord = {
        wayPointX: waypoint1CoordSpl[0],
        wayPointY: waypoint1CoordSpl[1],
      };
      coordsWaypoints.push(waypoint1Coord);
    }

    if (selectedFavo.waypoint2Coord != null) {
      let waypoint2Coord = selectedFavo.waypoint2Coord;
      let waypoint2CoordSpl = waypoint2Coord.split(',');
      waypoint2Coord = {
        wayPointX: waypoint2CoordSpl[0],
        wayPointY: waypoint2CoordSpl[1],
      };
      coordsWaypoints.push(waypoint2Coord);
    }

    if (selectedFavo.waypoint3Coord != null) {
      let waypoint3Coord = selectedFavo.waypoint3Coord;
      let waypoint3CoordSpl = waypoint3Coord.split(',');
      waypoint3Coord = {
        wayPointX: waypoint3CoordSpl[0],
        wayPointY: waypoint3CoordSpl[1],
      };
      coordsWaypoints.push(waypoint3Coord);
    }

    this.setState({
      wayPointsNo: orderStore.wayPointsList.length + 1,
      departureX: departureCoordSpl[0],
      departureY: departureCoordSpl[1],
      arrivalX: arriveCoordSpl[0],
      arrivalY: arriveCoordSpl[1],
      coordsWaypoints: coordsWaypoints,
      startBcode: selectedFavo.loadingBcode,
      endBcode: selectedFavo.unloadingBcode,
      wayPoint1Bcode: selectedFavo.waypoint1Bcode,
      wayPoint2Bcode: selectedFavo.waypoint2Bcode,
      wayPoint3Bcode: selectedFavo.waypoint3Bcode,
    });

    setTimeout(this.fnGetPath, 500);
  };

  fnInsertFavoCate = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.cateNm = favoStore.cateNm;
    params.registId = sessionStorage.getItem('customerId');
    axios({
      url: API_INSERT_FAVORITE_CATE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          favoStore.initCateNm();
          that.fnCloseInsertCateModal();
          that.fnSelecteFavoCateList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOpenInsertCateModal = () => {
    this.setState({
      insertCateModal: true,
    });
  };

  fnCloseInsertCateModal = () => {
    this.setState({
      insertCateModal: false,
    });
  };

  fnSelecteFavoCateList = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.registId = sessionStorage.getItem('customerId');
    axios({
      url: API_SELECT_FAVORITE_CATE_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          favoStore.initSelectedCate();
          favoStore.setCateList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOnchangeLoadingCd = value => {
    console.log(value);
    this.fnSetSelectLoading(value);
  };

  fnOnchangeUnLoadingCd = value => {
    this.fnSetSelectUnLoading(value);
  };

  render() {
    const { orderStore } = this.props;
    //회원현황 , 회원승인
    return (
      <React.Fragment>
        <Map
          orderPath={orderStore.orderPath}
          departureX={this.state.departureX}
          departureY={this.state.departureY}
          arrivalX={this.state.arrivalX}
          arrivalY={this.state.arrivalY}
          coordsWaypoints={this.state.coordsWaypoints}
          defaultZoom={this.state.defaultZoom}
        />
        <OrderForm
          //state
          wayPointsNo={this.state.wayPointsNo}
          logisStaffFlag={this.state.logisStaffFlag}
          //function
          fnOpenDepartureModal={this.fnOpenDepartureModal}
          fnSetDepartureDetailAdd={this.fnSetDepartureDetailAdd}
          fnOpenWayPointModal={this.fnOpenWayPointModal}
          fnSetWayPointDetailAdd={this.fnSetWayPointDetailAdd}
          fnDecreaseWayPoint={this.fnDecreaseWayPoint}
          fnIncreaseWayPoint={this.fnIncreaseWayPoint}
          fnOpenArriveModal={this.fnOpenArriveModal}
          fnSetArriveDetailAdd={this.fnSetArriveDetailAdd}
          fnSelectLoadingModalOpen={this.fnSelectLoadingModalOpen}
          fnSelectUnLoadingModalOpen={this.fnSelectUnLoadingModalOpen}
          fnCalLogisFee={this.fnCalLogisFee}
          fnInsertFreight={this.fnInsertFreight}
          //Select function
          fnSetPaymentTo={this.fnSetPaymentTo}
          fnSetPaymentTy={this.fnSetPaymentTy}
          fnSetVcWeightCd={this.fnSetVcWeightCd}
          fnSetVcTypCd={this.fnSetVcTypCd}
          fnSetLodingLogistStaff={this.fnSetLodingLogistStaff}
          //Radio Handler
          fnOnchangeLogisCd={this.fnOnchangeLogisCd}
          fnOnchangeLoadingCd={this.fnOnchangeLoadingCd}
          fnOnchangeUnLoadingCd={this.fnOnchangeUnLoadingCd}
          //Favorite
          fnFavoListModalOpen={this.fnFavoListModalOpen}
        />

        {/* Modal 영역 */}
        {/* 상차지 검색 모달 */}
        <Modal
          open={this.state.addDeparture}
          onClose={() =>
            this.setState({
              addDeparture: false,
            })
          }
        >
          <Modal.Header>{STR_DEPARTURE_ADD_SEARCH}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteDeparture} />
          </Modal.Content>
        </Modal>
        {/* 허차지 검색 모달 */}
        <Modal
          open={this.state.addArrival}
          onClose={() =>
            this.setState({
              addArrival: false,
            })
          }
        >
          <Modal.Header>{STR_ARRIVE_ADD_SEARCH}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteArrive} />
          </Modal.Content>
        </Modal>

        {/* 경유지 검색 모달 */}
        <Modal
          open={this.state.addWaypoint1}
          onClose={() =>
            this.setState({
              addWaypoint1: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_1}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint1} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.addWaypoint2}
          onClose={() =>
            this.setState({
              addWaypoint2: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_2}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint2} />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.addWaypoint3}
          onClose={() =>
            this.setState({
              addWaypoint3: false,
            })
          }
        >
          <Modal.Header>{STR_WAYPOINT_ADD_SEARCH_3}</Modal.Header>
          <Modal.Content>
            <DaumPostcode onComplete={this.fnHandleCompleteWayPoint3} />
          </Modal.Content>
        </Modal>
        {/* 경유지 검색 모달 */}
        {/* 상차방법 모달 */}
        <SelectLoadingModal
          open={this.state.loadingModal}
          onClose={this.fnSelectLoadingModalClose}
          modalHeader={STR_SELECT_LOADING_WAY}
          onChange={this.fnSetSelectLoading}
        />
        {/* 하차방법 모달 */}
        <SelectUnLoadingModal
          open={this.state.unLoadingModal}
          onClose={this.fnSelectUnLoadingModalClose}
          modalHeader={STR_SELECT_UNLOADING_WAY}
          onChange={this.fnSetSelectUnLoading}
        />

        <CommonAlert />
        <CommonAlertWithCallback callback={this.fnReloadAfterPayment} />
        <CommonCorfirmModal
          fnConfirmYes={this.fnConfirmYes}
          fnConfirmNo={this.fnConfirmNo}
        />
        {this.state.favoInsertModal == false ? null : (
          <FavoriteInsertModal
            favoInsertModal={this.state.favoInsertModal}
            fnFavoInsertModalClose={this.fnFavoInsertModalClose}
            fnInsertFavorite={this.fnInsertFavorite}
            fnOpenInsertCateModal={this.fnOpenInsertCateModal}
            fnSelecteFavoCateList={this.fnSelecteFavoCateList}
          />
        )}
        {this.state.favoListModal == false ? null : (
          <FavoriteListModal
            favoListModal={this.state.favoListModal}
            fnFavoListModalClose={this.fnFavoListModalClose}
            fnSetOrderForm={this.fnSetOrderForm}
          />
        )}

        <FavoriteCateInsertModal
          insertCateModal={this.state.insertCateModal}
          fnCloseInsertCateModal={this.fnCloseInsertCateModal}
          fnInsertFavoCate={this.fnInsertFavoCate}
        />

        <Dimmer active={this.state.calLogisFeeLoader}>
          <Loader content="운임을 계산중입니다." />
        </Dimmer>
      </React.Fragment>
    );
  }
}

export default withRouter(OrderContainer);
