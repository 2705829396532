import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Input, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import CustomerList from './CustomerList';
import DriverList from './DriverList';
import {
  API_SELECT_CUSTOMER_SEARCH_LIST_WITH_KEYWORD,
  API_SELECT_DRIVER_SEARCH_LIST_WITH_KEYWORD,
} from '../../../common/ApiAddress/AccountAPI';
@inject(stores => ({
  commonStore: stores.CommonStore,
  accStore: stores.AccStore,
}))
@observer
class CommonFindUserModal extends Component {
  //화주 검색 리스트 불러오기
  fnSelectCustomerListWithKeyword = searchKeyword => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    console.log(searchKeyword);
    params.searchKeyword = searchKeyword;
    params.searchTyp = this.props.searchTyp;
    params.startCnt = 0;
    params.endCnt = 50;
    axios({
      url: API_SELECT_CUSTOMER_SEARCH_LIST_WITH_KEYWORD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100 && response.data.data != null) {
          accStore.setCustomerList(response.data.data);
        } else if (response.data.resultCode == 200) {
          accStore.initCustomerList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //트럭커 검색 리스트 불러오기
  fnSelectDriverListWithKeyword = searchKeyword => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    params.searchKeyword = searchKeyword;
    params.searchTyp = this.props.searchTyp;
    params.startCnt = 0;
    params.endCnt = 30;
    axios({
      url: API_SELECT_DRIVER_SEARCH_LIST_WITH_KEYWORD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100 && response.data.data != null) {
          accStore.setDriverList(response.data.data);
        } else if (
          response.data.resultCode == 200 ||
          response.data.data == null
        ) {
          accStore.initDriverList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetSelectUser = value => {
    const { commonStore } = this.props;
    commonStore.setSelectedUser(value);
  };

  render() {
    const { empStore } = this.props;
    const searchCondotionList = [
      { text: '화주', value: 'C' },
      { text: '트럭커', value: 'T' },
    ];
    const searchTypList = [
      { text: '이름', value: 'N' },
      { text: '아이디', value: 'A' },
    ];
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={this.props.open}
        onClose={() => this.props.onClose()}
        style={{ width: '50%', height: '270px', top: '15%' }}
      >
        <Modal.Header>회원 검색</Modal.Header>
        <Modal.Content>
          <div>
            <Form style={{ width: '100%', marginTop: '10px' }}>
              <Form.Field style={{ width: '100%' }}>
                <Select
                  style={{
                    minWidth: '122px',
                    maxWidth: '122px',
                    marginRight: '10px',
                    float: 'left',
                  }}
                  placeholder={'회원구분 선택'}
                  options={searchCondotionList}
                  onChange={(e, { value }) =>
                    this.props.fnOnchangeSearchCondition(value)
                  }
                />
                <Select
                  style={{
                    minWidth: '122px',
                    maxWidth: '122px',
                    marginRight: '10px',
                    float: 'left',
                  }}
                  placeholder={'검색조건 선택'}
                  options={searchTypList}
                  onChange={(e, { value }) =>
                    this.props.fnOnchangeSearchTyp(value)
                  }
                />
                <Fragment>
                  <Input
                    icon="search"
                    placeholder="검색어 입력"
                    style={{ width: '150px' }}
                    onKeyPress={e => {
                      if (e.charCode == 13) {
                        if (this.props.searchConditon == 'C') {
                          this.fnSelectCustomerListWithKeyword(e.target.value);
                        } else if (this.props.searchConditon == 'T') {
                          this.fnSelectDriverListWithKeyword(e.target.value);
                        }
                      }
                    }}
                  />
                  <span style={{ marginLeft: '10px', color: '#cfcfcf' }}>
                    Enter
                  </span>
                </Fragment>
              </Form.Field>
            </Form>
          </div>
          <div
            style={{ overflowY: 'scroll', height: '230px', marginTop: '10px' }}
          >
            {this.props.searchConditon == 'C' ? (
              <CustomerList fnSetSelectUser={this.fnSetSelectUser} />
            ) : this.props.searchConditon == 'T' ? (
              <DriverList fnSetSelectUser={this.fnSetSelectUser} />
            ) : null}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              onClick={() => {
                this.props.onSelect();
              }}
              positive
              size="medium"
              style={{ width: '30%', marginTop: '10px' }}
            >
              완료
            </Button>

            <Button
              onClick={() => {
                this.props.onClose();
              }}
              negative
              size="medium"
              style={{ width: '30%', marginTop: '10px' }}
            >
              닫기
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default CommonFindUserModal;
