//트럭커
import DriverManagementContainer from './Driver/DriverManagementContainer';
import CurrentDriverList from './Driver/CurrentDriverList';
import DriverAPV from './Driver/DriverAPV';

//화주
import CustomerManagementContainer from './Customer/CustomerManagementContainer';
import CurrentCustomerList from './Customer/CurrentCustomerList';
import CustomerApv from './Customer/CustomerApv';

export const UserIndex = {
  //트럭커
  DriverManagementContainer,
  CurrentDriverList,
  DriverAPV,

  //화주
  CustomerManagementContainer,
  CurrentCustomerList,
  CustomerApv,
};
