import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class OrderStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable logisCdList = [];
  @observable logisCd = 'IFRDI001';

  //주소 경로 관련
  @observable departureAdd = null;
  @observable departureDetailAdd = null;
  @observable departureCoord = null;
  @observable arriveAdd = null;
  @observable arriveDetailAdd = null;
  @observable arriveCoord = null;
  @observable wayPointsList = [];
  @observable orderPath = null;

  @observable logisFee = null;
  @observable freightInfo = null;
  @observable vcCnt = 1;
  @observable loadingCdList = [];

  @observable loadingCd = 'IFRLO001';
  @observable loadingNm = null;
  @observable unloadingCd = 'IFRLO001';
  @observable unloadingNm = null;

  @observable freightWeight = null;
  @observable loadingDate = null;
  @observable unloadingDate = null;

  @observable freightList = [];

  @observable distance = null;
  @observable duration = null;

  @observable selectedFreight = {};

  @observable detailView = false;
  @observable updateView = false;

  @observable loadingStaffNm = null;
  @observable loadingStaffRank = null;
  @observable loadingStaffHpNo = null;

  @observable unloadingStaffNm = null;
  @observable unloadingStaffRank = null;
  @observable unloadingStaffHpNo = null;

  @observable freightListCurrentPageNum = 1;
  @observable freightListTotalPageNum = 0;
  @observable freightListTotalCount = null;

  @action
  setLogisCdList = value => {
    this.logisCdList = value;
  };

  @action
  initLogisCdList = () => {
    this.logisCdList = [];
  };

  @action
  setLogisCd = value => {
    this.logisCd = value;
  };

  @action
  initLogisCd = () => {
    this.logisCd = 'IFRDI001';
  };

  @action
  setDepartureAdd = value => {
    this.departureAdd = value;
  };

  @action
  initDepartureAdd = () => {
    this.departureAdd = null;
  };

  @action
  setDepartureDetailAdd = value => {
    this.departureDetailAdd = value;
  };

  @action
  initDepartureDetailAdd = () => {
    this.departureDetailAdd = null;
  };

  @action
  setDepartureCoord = value => {
    this.departureCoord = value;
  };

  @action
  initDepartureCoord = () => {
    this.departureCoord = null;
  };

  @action
  setArriveAdd = value => {
    this.arriveAdd = value;
  };

  @action
  initArriveAdd = () => {
    this.arriveAdd = null;
  };

  @action
  setArriveDetailAdd = value => {
    this.arriveDetailAdd = value;
  };

  @action
  initArriveDetailAdd = () => {
    this.arriveDetailAdd = null;
  };

  @action
  setArriveCoord = value => {
    this.arriveCoord = value;
  };

  @action
  initArriveCoord = () => {
    this.arriveCoord = null;
  };

  @action
  setOrderPath = value => {
    this.orderPath = value;
  };

  @action
  initOrderPath = () => {
    this.orderPath = null;
  };

  @action
  setWayPointsList = (index, type, value) => {
    if (type == 'add') {
      this.wayPointsList.splice(index, 0, value);
    }
    if (type == 'remove') {
      this.wayPointsList.splice(index, 1);
    }
  };

  @action
  initWayPointsList = () => {
    this.wayPointsList = [];
  };

  @action
  setWayPointsBasicAdd = (index, value) => {
    let orig = [...this.wayPointsList];
    let basicAdd = {
      basicAdd: value,
      detailAdd: orig[index - 1].detailAdd,
    };
    orig[index - 1] = basicAdd;
    this.wayPointsList = orig;
  };

  @action
  setWayPointsDetailAdd = (index, value) => {
    let orig = [...this.wayPointsList];
    let detailAdd = {
      basicAdd: orig[index - 1].basicAdd,
      detailAdd: value,
    };
    orig[index - 1] = detailAdd;
    this.wayPointsList = orig;
  };

  @action
  setLogisFee = value => {
    this.logisFee = value;
  };

  @action
  initLogisFee = () => {
    this.logisFee = null;
  };

  @action
  setFreightInfo = value => {
    this.freightInfo = value;
  };

  @action
  initFreightInfo = value => {
    this.freightInfo = null;
  };

  @action
  setVcCnt = value => {
    this.vcCnt = value;
  };

  @action
  initVcCnt = value => {
    this.vcCnt = 1;
  };

  @action
  setLoadingCdList = value => {
    this.loadingCdList = value;
  };

  @action
  initLoadingCdList = () => {
    this.loadingCdList = [];
  };

  @action
  setLoadingCd = value => {
    this.loadingCd = value;
  };

  @action
  initLoadingCd = () => {
    this.loadingCd = 'IFRLO001';
  };

  @action
  setLoadingNm = value => {
    this.loadingNm = value;
  };

  @action
  initLoadingNm = () => {
    this.loadingNm = null;
  };

  @action
  setUnLoadingCd = value => {
    this.unloadingCd = value;
  };

  @action
  initUnLoadingCd = () => {
    this.unloadingCd = 'IFRLO001';
  };

  @action
  setUnLoadingNm = value => {
    this.unloadingNm = value;
  };

  @action
  initUnLoadingNm = () => {
    this.unloadingNm = null;
  };

  @action
  setFreightWeight = value => {
    this.freightWeight = value;
  };

  @action
  initFreightWeight = () => {
    this.freightWeight = null;
  };

  @action
  setLoadingDate = value => {
    this.loadingDate = value;
  };

  @action
  initLoadingDate = () => {
    this.loadingDate = null;
  };

  @action
  setUnLoadingDate = value => {
    this.unloadingDate = value;
  };

  @action
  initUnLoadingDate = () => {
    this.unloadingDate = null;
  };

  @action
  setFreightList = value => {
    this.freightList = value;
  };

  @action
  initFreightList = () => {
    this.freightList = [];
  };

  @action
  setDistance = value => {
    this.distance = value;
  };

  @action
  initDistance = () => {
    this.distance = null;
  };

  @action
  setDuration = value => {
    this.duration = value;
  };

  @action
  initDuration = () => {
    this.duration = null;
  };

  @action
  setSelectedFreight = value => {
    this.selectedFreight = value;
  };

  @action
  initSelectedFreight = () => {
    this.selectedFreight = null;
  };

  @action
  setDetailViewFalse = () => {
    this.detailView = false;
  };

  @action
  setDetailViewTrue = () => {
    this.detailView = true;
  };

  @action
  setUpdateViewFalse = () => {
    this.updateView = false;
  };

  @action
  setUpdateViewTrue = () => {
    this.updateView = true;
  };

  @action
  setLoadingStaffNm = value => {
    this.loadingStaffNm = value;
  };

  @action
  initLoadingStaffNm = () => {
    this.loadingStaffNm = null;
  };

  @action
  setLoadingStaffRank = value => {
    this.loadingStaffRank = value;
  };

  @action
  initLoadingStaffRank = () => {
    this.loadingStaffRank = null;
  };

  @action
  setLoadingStaffHpNo = value => {
    this.loadingStaffHpNo = value;
  };

  @action
  initLoadingStaffHpNo = () => {
    this.loadingStaffHpNo = null;
  };

  @action
  setUnLoadingStaffNm = value => {
    this.unloadingStaffNm = value;
  };

  @action
  initUnLoadingStaffNm = () => {
    this.unloadingStaffNm = null;
  };

  @action
  setUnLoadingStaffRank = value => {
    this.unloadingStaffRank = value;
  };

  @action
  initUnLoadingStaffRank = () => {
    this.unloadingStaffRank = null;
  };

  @action
  setUnLoadingStaffHpNo = value => {
    this.unloadingStaffHpNo = value;
  };

  @action
  initUnLoadingStaffHpNo = () => {
    this.unloadingStaffHpNo = null;
  };

  @action
  setFreightListCurrentPageNum = value => {
    this.freightListCurrentPageNum = value;
  };

  @action
  initFreightListCurrentPageNum = () => {
    this.freightListCurrentPageNum = 1;
  };

  @action
  setFreightListTotalPageNum = value => {
    this.freightListTotalPageNum = value;
  };

  @action
  initFreightListTotalPageNum = () => {
    this.freightListTotalPageNum = 0;
  };

  @action
  setFreightListTotalCount = value => {
    this.freightListTotalCount = value;
  };

  @action
  initFreightListTotalCount = () => {
    this.freightListTotalCount = null;
  };

  @action
  initForm = () => {
    this.initDepartureAdd();
    this.initDepartureDetailAdd();
    this.initArriveAdd();
    this.initArriveDetailAdd();
    this.initOrderPath();
    this.initLogisFee();
    this.initWayPointsList();
    this.initFreightInfo();
    this.initVcCnt();
    this.initLogisCd();
    this.initLogisCdList();
    this.initLoadingCd();
    this.initLoadingCdList();
    this.initUnLoadingCd();
    this.initFreightWeight();
    this.initLoadingDate();
    this.initUnLoadingDate();
    this.initDepartureCoord();
    this.initArriveCoord();
    this.initDistance();
    this.initDuration();
    this.initSelectedFreight();
    this.initLoadingStaffNm();
    this.initLoadingStaffRank();
    this.initLoadingStaffHpNo();
    this.initUnLoadingStaffNm();
    this.initUnLoadingStaffRank();
    this.initUnLoadingStaffHpNo();
    this.initFreightListTotalPageNum();
    this.initFreightListTotalCount();
    this.initFreightListCurrentPageNum();
    this.initFreightList();
    this.initLoadingNm();
    this.initUnLoadingNm();
  };
}

export default OrderStore;
