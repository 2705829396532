import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  compStore: stores.CompStore,
}))
@observer
class StatementTitle extends Component {
  render() {
    const { compStore } = this.props;
    return (
      <Fragment>
        <tr
          style={{
            width: '690px',
            height: '40px',
          }}
        >
          <td
            colSpan={12}
            style={{
              textAlign: 'center',
              borderLeft: '2px solid',
              borderRight: '2px solid',
              fontSize: '18pt',
              fontWeight: '700',
              letterSpacing: '5px',
            }}
          >
            거래명세표
          </td>
        </tr>
        <tr
          style={{
            width: '690px',
            height: '26px',
          }}
        >
          <td
            colSpan={12}
            style={{
              borderLeft: '2px solid',
              borderRight: '2px solid',
            }}
          />
        </tr>
      </Fragment>
    );
  }
}

export default StatementTitle;
