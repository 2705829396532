import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {
  isAdmin,
  isCustomer,
  isCustomerMaster,
  isLogisManager,
} from '../../Util/CommonUtil';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR01,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GRAY_GR08,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import { ReactComponent as LogoSquare } from '../../assets/icon/logo_square.svg';
import { ReactComponent as LogoBig } from '../../assets/icon/logo_long.svg';
import { ReactComponent as Order } from '../../assets/icon/write.svg';
import { ReactComponent as OrderList } from '../../assets/icon/list.svg';
import { ReactComponent as Favo } from '../../assets/icon/favo.svg';
import { ReactComponent as Person } from '../../assets/icon/person.svg';
import { ReactComponent as Setting } from '../../assets/icon/setting.svg';
import { ReactComponent as LogoRecreate } from '../../assets/icon/logo_recreate.svg';
import { ReactComponent as ProfileComp } from '../../assets/icon/profile_comp.svg';
import { ReactComponent as Logout } from '../../assets/icon/logout.svg';

@inject(stores => ({
  commonStore: stores.CommonStore,
  accStore: stores.AccStore,
}))
@observer
class SideBar extends Component {
  fnLogout = () => {
    const { accStore } = this.props;
    accStore.initCustomerId();
    accStore.initPw();
    sessionStorage.removeItem('customerId');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('customerCd');
    sessionStorage.removeItem('customerNo');
    sessionStorage.removeItem('staffNo');
    sessionStorage.removeItem('staffNm');
    //window.location.href = '/';
    window.location.href = '/login';
  };

  componentDidMount() {
    let el = document.getElementById('sideNav');
    el.addEventListener('mouseenter', this.fnOnToggle);

    el.addEventListener('mouseleave', this.fnOnToggleClose);
  }

  fnOnToggle = () => {
    this.props.onToggle();
  };

  fnOnToggleClose = () => {
    this.props.onToggleClose();
  };

  render() {
    return (
      <SideNav
        expanded={this.props.expanded}
        //expanded={true}
        id="sideNav"
        style={{
          background: COLOR_GRAY_GR01,
          width: '80px',
          boxShadow: '4px 4px 10px rgba(0,0,0,0.15)',
          overflow: 'hidden',
        }}
        onSelect={selected => this.props.onSelect(selected)}
        onToggle={() => {
          this.props.onToggle();
        }}
      >
        <SideNav.Nav
          defaultSelected={this.props.selected}
          style={
            this.props.expanded == false
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              : null
          }
        >
          {this.props.expanded == false ? (
            <LogoSquare
              width={52}
              height={42}
              style={{
                marginTop: '19px',
              }}
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <LogoBig
                style={{
                  margin: '0 auto',
                  marginTop: '19px',
                  display: 'block',
                }}
              />
            </div>
          )}

          {isCustomerMaster() || isLogisManager() || isCustomer() ? (
            <NavItem
              eventKey={'order'}
              onClick={() => this.props.history.push('/order')}
              style={{
                marginTop: this.props.expanded == false ? '4%' : '6%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <NavIcon
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Order
                  width={27}
                  height={28}
                  fill={
                    this.props.selected == 'order'
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05
                  }
                />
              </NavIcon>
              <NavText
                style={{
                  color:
                    this.props.selected == 'order'
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                운송예약
              </NavText>
            </NavItem>
          ) : null}

          <NavItem
            style={{
              marginTop: this.props.expanded == false ? '13%' : '6%',
              display: 'flex',
              alignItems: 'center',
            }}
            eventKey={'freight'}
            onClick={() => this.props.history.push('/freight/all')}
          >
            <NavIcon
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <OrderList
                width={24}
                height={24}
                fill={
                  this.props.selected === 'freight'
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05
                }
              />
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected === 'freight'
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              운송현황
            </NavText>
          </NavItem>
          <NavItem
            eventKey={'favorite'}
            //active={this.props.selected === 'favorite' ? true : false}
            onClick={() => this.props.history.push('/favorite')}
            style={{
              marginTop: this.props.expanded == false ? '13%' : '6%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <NavIcon
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Favo
                width={27}
                height={26}
                fill={
                  this.props.selected === 'favorite'
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05
                }
              />
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected === 'favorite'
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              즐겨찾기
            </NavText>
          </NavItem>
          {isCustomerMaster() ? (
            <NavItem
              eventKey={'staff'}
              onClick={() => this.props.history.push('/staff')}
              style={{
                marginTop: this.props.expanded == false ? '13%' : '6%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <NavIcon
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Person
                  width={22}
                  height={22}
                  fill={
                    this.props.selected === 'staff'
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05
                  }
                />
              </NavIcon>
              <NavText
                style={{
                  color:
                    this.props.selected === 'staff'
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                담당자관리
              </NavText>
            </NavItem>
          ) : null}

          <NavItem
            eventKey="setting"
            style={{
              marginTop: this.props.expanded == false ? '13%' : '6%',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => [
              //this.props.onSelect('setting/notice'),
              this.props.history.push('/setting/notice'),
            ]}
          >
            <NavIcon
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Setting
                width={26}
                height={27}
                fill={
                  this.props.selected === 'setting'
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05
                }
              />
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected === 'setting'
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              설정
            </NavText>
          </NavItem>

          <NavItem
            style={{
              display: this.props.expanded ? 'block' : 'none',
              width: this.props.expanded ? '250px' : '80px',
              marginTop: '20%',
            }}
          >
            <div
              style={{
                display: this.props.expanded ? 'block' : 'none',
                width: '250px',
              }}
            >
              <div style={{ height: '30px', paddingLeft: '24px' }}>
                <LogoRecreate />
              </div>
              <ul
                style={{
                  paddingLeft: 0,
                  listStyle: 'none',
                  fontSize: '12px',
                  margin: '0px',
                  color: COLOR_GRAY_GR06,
                  fontWeight: '400',
                  letterSpacing: '-0.5px',
                  paddingLeft: '24px',
                }}
              >
                <li
                  style={{
                    width: '100%',
                    height: '30px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  (주)리크리에이트
                </li>
                <li
                  style={{
                    width: '100%',
                    height: '30px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  <span style={{ fontWeight: '700' }}>대표</span> 박훈진
                </li>
                <li
                  style={{
                    width: '100%',
                    height: '20px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  <span style={{ fontWeight: '700' }}>주소</span>
                  <span>경기도 수원시 영통구 광교로107,</span>
                </li>
                <li
                  style={{
                    width: '100%',
                    height: '30px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  <span style={{ fontWeight: '700' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span>경기도 경제과학진흥원 보육동208호</span>
                </li>
                <li
                  style={{
                    width: '100%',
                    height: '30px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  <span style={{ fontWeight: '700' }}>사업자등록번호</span>
                  771-86-02308
                </li>
                <li
                  style={{
                    width: '100%',
                    height: '30px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  <span style={{ fontWeight: '700' }}>통신판매업신고번호</span>
                  제2021-수원장안-1033호
                </li>
                <li
                  style={{
                    width: '100%',
                    height: '30px',
                    float: 'left',
                  }}
                >
                  <span style={{ fontWeight: '700' }}>대표번호</span>
                  010-9611-2934
                </li>
                <li
                  style={{
                    width: '100%',
                    height: '30px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  <span style={{ fontWeight: '700' }}>고객센터</span>
                  010-9611-2934 (10:00 ~ 18:00)
                </li>
              </ul>
            </div>
          </NavItem>

          <NavItem
            onClick={() => this.fnLogout()}
            eventKey="/"
            style={{
              position: 'absolute',
              bottom: 21,
              width: this.props.expanded ? '100%' : '80px',
              marginTop: '35px',
              paddingLeft: '10px',
              height: '50px',
            }}
          >
            <NavIcon
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {sessionStorage.getItem('customerCd') == 'IACCU003' ? (
                <ProfileComp />
              ) : (
                <div
                  style={{
                    width: '32px',
                    height: '32px',
                    background: COLOR_WHITE,
                    borderRadius: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Person width={18} height={18} fill={COLOR_BLACK} />
                </div>
              )}
            </NavIcon>
            <NavText style={{ width: '100%' }}>
              <div
                style={{
                  color: COLOR_GRAY_GR08,
                  fontSize: '18px',
                  fontWeight: '500',
                  float: 'left',
                }}
              >
                {sessionStorage.getItem('customerId')}
              </div>
              <div
                style={{
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  height: '50px',
                  alignItems: 'center',
                  paddingRight: '25px',
                }}
              >
                <Logout />
              </div>
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  }
}

export default withRouter(SideBar);
