import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class AdsStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }
  //템플릿 관련
  @observable adsList = [];
  @observable selectedAds = null;

  @observable adsHistoryList = [];

  @action
  setAdsList = value => {
    this.adsList = value;
  };

  @action
  initAdsList = () => {
    this.adsList = [];
  };

  @action
  setSelectedAds = value => {
    this.selectedAds = value;
  };

  @action
  initSelectedAds = () => {
    this.selectedAds = null;
  };

  @action
  setAdsHistoryList = value => {
    this.adsHistoryList = value;
  };

  @action
  initAdsHistoryList = () => {
    this.adsHistoryList = [];
  };
}

export default AdsStore;
