import React, { Component } from 'react';

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            height: '80px',
            textAlign: 'center',
            position: 'absolute',
            top: 0,
            zIndex: 101,
            borderBottom: '1px solid #78C939',
          }}
        >
          <img
            style={{ width: '12%', marginTop: '27px' }}
            src={'./image/logoDarktheme.png'}
          />
          <p
            onClick={() => this.props.fnMoveToLogin()}
            style={{
              color: '#78C939',
              position: 'absolute',
              fontSize: '16px',
              fontWeight: 'bold',
              top: '38%',
              right: '15%',
            }}
          >
            LOGIN
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
