import { Component } from 'react';
import { Icon, Label, Menu, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class APVList extends Component {
  render() {
    const { accStore } = this.props;
    console.log(accStore.apvList.length);
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>회원번호</Table.HeaderCell>
            <Table.HeaderCell>신청자 이름</Table.HeaderCell>
            <Table.HeaderCell>신청자 연락처</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {accStore.apvList == null || accStore.apvList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                신청자가 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {accStore.apvList.map((data, i) => (
              <Table.Row onClick={() => this.props.fnSelectDriver(data)}>
                <Table.Cell>{data.driverNo}</Table.Cell>
                <Table.Cell>{data.driverNm}</Table.Cell>
                <Table.Cell>{data.hpNo}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default APVList;
