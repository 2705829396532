import React, { Component } from 'react';
import { Table, TextArea, Button, Form, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class CustomerManagementDetailContainer extends Component {
  render() {
    const { accStore } = this.props;
    return (
      <div
        style={{
          padding: '0px 10px',
        }}
      >
        {accStore.selectedCustomer == null ? null : (
          <React.Fragment>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    <p
                      style={{
                        float: 'left',
                        width: '75%',
                        marginBottom: '0px',
                      }}
                    >
                      아이디 : {accStore.selectedCustomer.customerId}
                    </p>
                    <span
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      등록일시 :{' '}
                      {moment(accStore.selectedCustomer.registDt).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>회원 구분</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedCustomer.customerCdNm}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>회원 이름</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedCustomer.customerNm}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>이메일</Table.Cell>
                  <Table.Cell>{accStore.selectedCustomer.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>연락처</Table.Cell>
                  <Table.Cell>{accStore.selectedCustomer.hpNo}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>승인여부</Table.Cell>
                  <Table.Cell>{accStore.selectedCustomer.apvYn}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>계좌정보 </Table.Cell>
                  <Table.Cell>
                    {accStore.selectedCustomer.accCdNm} {'\u00a0\u00a0'}
                    {accStore.selectedCustomer.accNo}
                  </Table.Cell>
                </Table.Row>
                {accStore.selectedCustomer.customerCd == 'IACCU002' ? null : (
                  <Table.Row>
                    <Table.Cell collapsing>사업자등록번호</Table.Cell>
                    <Table.Cell>
                      {accStore.selectedCustomer.compRegNo}
                    </Table.Cell>
                  </Table.Row>
                )}
                {accStore.selectedCustomer.customerCd == 'IACCU003' ||
                accStore.selectedCustomer.customerCd == 'IACCU004' ||
                accStore.selectedCustomer.customerCd == 'IACCU005' ? (
                  <Table.Row>
                    <Table.Cell collapsing>법인대표자 성명</Table.Cell>
                    <Table.Cell>{accStore.selectedCustomer.groupNm}</Table.Cell>
                  </Table.Row>
                ) : null}
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CustomerManagementDetailContainer;
