import React, { Component, Fragment } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
  payStore: stores.PaymentStore,
}))
@observer
class PaymentFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 0,
      second: 5,
    };
  }

  componentDidMount() {
    let timer = setInterval(this.fnCalTimer, 1000);
    this.setState({
      timer: timer,
    });
  }

  fnCalTimer = () => {
    if (this.state.second > 0) {
      this.setState({
        second: this.state.second - 1,
      });
    } else if (this.state.second == 0) {
      clearInterval(this.state.timer);
      this.props.history.push('/order');
    }
  };

  render() {
    return (
      <Fragment>
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1>
            결제에 실패하였습니다. {this.state.second}초 후 운송등록 화면으로
            돌아갑니다.
          </h1>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(PaymentFailed);
