import { Component } from 'react';
import { Icon, Label, Menu, Table } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import AWS, { S3 } from 'aws-sdk';
import {
  API_SELECT_CUSTOMER_APV_LIST,
  API_UPDATE_INFO_WITH_APPROVED_CUSTOMER,
  API_SELECT_NTS_BUSINESSMAN,
} from '../../../../common/ApiAddress/AccountAPI';

import APVList from '../../../../Component/Management/User/Custromer/APV/APVList';
import APVDetail from '../../../../Component/Management/User/Custromer/APV/APVDetail';
import CompRegAuthModal from '../../../../Component/Management/User/Custromer/APV/CompRegAuthModal';
@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class CustomerApv extends Component {
  state = {
    AuthModal: false,
    isAuth: false,
    authComplete: false,
    presentativeNm: null,
    startDt: null,
    compNm: null,
  };

  componentDidMount() {
    this.fnSelectAPVList();
  }

  //승인 심사 대기 리스트 불러오기
  fnSelectAPVList = () => {
    const { accStore } = this.props;
    let params = {};
    axios({
      url: API_SELECT_CUSTOMER_APV_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100 && response.data.data != null) {
          accStore.setApvList(response.data.data);
        } else if (response.data.resultCode == 200) {
          accStore.initApvList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectCustomer = value => {
    const { accStore } = this.props;
    //input값 세팅
    console.log(value);
    accStore.setSelectedCustomer(value);
    accStore.setCompRegNo(value.compRegNo);

    const BUCKET_NAME = 'teamtrucker';
    let Key = value.compRegFilePath.replace(
      process.env.REACT_APP_AWS_S3_ADDRESS,
      '',
    );
    const client = new S3({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    });
    let that = this;
    client.getObject(
      {
        Bucket: BUCKET_NAME,
        Key: Key,
      },
      function (s3Err, data) {
        if (s3Err) throw s3Err;
        console.log(data);
        window.document.getElementById('my-img').src = URL.createObjectURL(
          new Blob([data.Body], { type: 'image/png' }),
        );
      },
    );
  };

  //승인 하기 및 정보 업데이트
  fnUpdateInfoWithApproved = () => {
    const { accStore } = this.props;
    let params = {};
    let that = this;

    params.customerId = accStore.selectedCustomer.customerId;
    params.customerNo = accStore.selectedCustomer.customerNo;
    params.customerCd = accStore.selectedCustomer.customerCd;
    params.customerNm = accStore.selectedCustomer.customerNm;
    params.email = accStore.selectedCustomer.email;
    params.compRegNo = accStore.compRegNo;
    params.empId = sessionStorage.getItem('empId');
    axios({
      url: API_UPDATE_INFO_WITH_APPROVED_CUSTOMER,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnSelectAPVList(); // 리스트 다시 불러오기
          accStore.initSelectedCustomer(); // 선택한 기사 초기화
          accStore.initForm(); //입력폼 초기화
          that.setState({
            isAuth: false,
            authComplete: false,
            presentativeNm: null,
            startDt: null,
            compNm: null,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnCompRegAuth = () => {
    const { accStore } = this.props;
    let params = {};
    let that = this;

    let businesses = [];
    let item = {};
    item.b_no = accStore.compRegNo;
    item.start_dt = this.state.startDt;
    item.p_nm = this.state.presentativeNm;
    item.b_nm = this.state.compNm;
    businesses.push(item);
    params.businesses = businesses;
    axios({
      url: API_SELECT_NTS_BUSINESSMAN,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        console.log(response);
        //7718602308
        if (response.status == 200) {
          console.log(response.data.data[0]);
          console.log(response.data.data[0].valid);
          if (response.data.data[0].valid == '01') {
            that.setState({
              isAuth: true,
              authComplete: true,
            });
          } else {
            that.setState({
              isAuth: false,
              authComplete: true,
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnAuthModalOpen = () => {
    this.setState({
      AuthModal: true,
    });
  };

  //닫기 버튼
  fnAuthModalClose = () => {
    const { accStore } = this.props;
    accStore.initCompRegNo();
    this.setState({
      AuthModal: false,
      isAuth: false,
      authComplete: false,
      presentativeNm: null,
      startDt: null,
      compNm: null,
    });
  };

  // 인증 성공 후 닫기
  fnAuthModalCloseForSeccess = () => {
    this.setState({
      AuthModal: false,
    });
  };

  fnSetPresentativeNm = value => {
    this.setState({
      presentativeNm: value,
    });
  };

  fnSetStartDt = value => {
    this.setState({
      startDt: value,
    });
  };

  fnSetCompNm = value => {
    this.setState({
      compNm: value,
    });
  };

  render() {
    const { accStore } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '40%',
            height: 'calc(100vh - 40px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <APVList fnSelectCustomer={this.fnSelectCustomer} />
        </div>
        <div
          style={{
            width: '60%',
            height: 'calc(100vh - 40px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {accStore.selectedCustomer != null ? (
            <APVDetail
              isAuth={this.state.isAuth}
              fnAuthModalOpen={this.fnAuthModalOpen}
              fnUpdateInfoWithApproved={this.fnUpdateInfoWithApproved}
            />
          ) : null}
        </div>
        {this.state.AuthModal == true ? (
          <CompRegAuthModal
            isAuth={this.state.isAuth}
            authComplete={this.state.authComplete}
            AuthModal={this.state.AuthModal}
            presentativeNm={this.state.presentativeNm}
            startDt={this.state.startDt}
            compNm={this.state.compNm}
            fnSetPresentativeNm={this.fnSetPresentativeNm}
            fnSetStartDt={this.fnSetStartDt}
            fnSetCompNm={this.fnSetCompNm}
            fnAuthModalClose={this.fnAuthModalClose}
            fnCompRegAuth={this.fnCompRegAuth}
            fnAuthModalCloseForSeccess={this.fnAuthModalCloseForSeccess}
          />
        ) : null}
      </div>
    );
  }
}

export default CustomerApv;
