import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { API_SELECT_ALARMTALK_LIST } from '../../../../common/ApiAddress/AlarmTalkAPI';
import { isEmp } from '../../../../Util/CommonUtil';
import AlarmTalkList from '../../../../Component/Management/AlarmTalk/AlarmTalkList/AlarmTalkList';
import AlarmTalkDetailContainer from './AlarmTalkDetailContainer';
@inject(stores => ({
  armStore: stores.AlarmTalkStore,
}))
@observer
class AlarmTalkListContainer extends Component {
  state = {
    detailView: false,
  };

  componentDidMount() {
    this.fnSelectAlarmTalkList();
  }

  //알림톡 리스트 불러오기
  fnSelectAlarmTalkList = () => {
    const { armStore } = this.props;
    let params = {};
    let that = this;

    axios({
      url: API_SELECT_ALARMTALK_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          armStore.setAlarmTalkList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnViewOpen = value => {
    const { armStore } = this.props;
    armStore.setSelectedAlarmTalk(value);
    this.setState({
      detailView: true,
    });
  };

  render() {
    const { armStore } = this.props;
    return isEmp() ? (
      <React.Fragment>
        <div
          style={{
            width: '50%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            padding: '10px',
          }}
        >
          <AlarmTalkList fnViewOpen={this.fnViewOpen} />
        </div>
        <div
          style={{
            width: '50%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {this.state.detailView == true ? <AlarmTalkDetailContainer /> : null}
        </div>
      </React.Fragment>
    ) : null;
  }
}

export default withRouter(AlarmTalkListContainer);
