import React, { Component } from 'react';
import { Table, TextArea, Button, Form, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class DriverManagementDetailContainer extends Component {
  render() {
    const { accStore } = this.props;
    return (
      <div
        style={{
          padding: '0px 10px',
        }}
      >
        {accStore.selectedDriver == null ? null : (
          <React.Fragment>
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    <p
                      style={{
                        float: 'left',
                        width: '75%',
                        marginBottom: '0px',
                      }}
                    >
                      아이디 : {accStore.selectedDriver.driverId}
                    </p>
                    <span
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      등록일시 :{' '}
                      {moment(accStore.selectedDriver.registDt).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>회원 구분</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.driverCdNm}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>회원 이름</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.driverNm}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>이메일</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>연락처</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.hpNo}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>승인여부</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.apvYn}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>계좌정보 </Table.Cell>
                  <Table.Cell>
                    {accStore.selectedDriver.accCdNm} {'\u00a0\u00a0'}
                    {accStore.selectedDriver.accNo}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell collapsing>사업자등록번호</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.compRegNo}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>화물운송자격증 번호</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedDriver.logisCertiNo}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>차량등록번호</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.vcRegNo}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">차량정보</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>중량구분</Table.Cell>
                  <Table.Cell>
                    {accStore.selectedDriver.vcWeightCdNm}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>차량구분</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.vcTypCdNm}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>너비(단위 : mm)</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.vcWidth}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>길이(단위 : mm)</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.vcLength}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>높이(단위 : mm)</Table.Cell>
                  <Table.Cell>{accStore.selectedDriver.vcHeight}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default DriverManagementDetailContainer;
