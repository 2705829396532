import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class CommonStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable path = null;
  @observable commonAlert = { isOpen: false, content: null };
  @observable commonAlertWithCallBack = { isOpen: false, content: null };
  @observable commonConfirm = { isOpen: false, content: null };
  @observable confirmModalForInsert = { isOpen: false, content: null };
  @observable confirmModalForUpdate = { isOpen: false, content: null };
  @observable confirmModalForDelete = { isOpen: false, content: null };
  @observable confirmModalForCancle = { isOpen: false, content: null };

  @observable customerCdList = [];
  @observable emailCdList = [];
  @observable bankInfoList = [];
  @observable logisStaffList = [];

  @observable findUserModal = false;
  @observable selectedUser = null;

  @action
  setCustomerCdList = value => {
    this.customerCdList = value;
  };

  @action
  initCustomerCdList = () => {
    this.customerCdList = [];
  };

  @action
  setEmailCdList = value => {
    this.emailCdList = value;
  };

  @action
  initEmailCdList = () => {
    this.emailCdList = [];
  };

  @action
  setBankInfoList = value => {
    this.bankInfoList = toJS(value);
  };

  @action
  initBankInfoList = () => {
    this.bankInfoList = [];
  };

  @action
  setPath = value => {
    this.path = value;
  };

  @action
  commonAlertOpen = value => {
    this.commonAlert = { isOpen: true, content: value };
  };

  @action
  commonAlertClose = () => {
    this.commonAlert = { isOpen: false, content: null };
  };

  @action
  commonAlertWithCallbackOpen = value => {
    this.commonAlertWithCallBack = { isOpen: true, content: value };
  };

  @action
  commonAlertWithCallbackClose = () => {
    this.commonAlertWithCallBack = { isOpen: false, content: null };
  };

  @action
  commonConfirmOpen = value => {
    this.commonConfirm = { isOpen: true, content: value };
  };

  @action
  commonConfirmClose = () => {
    this.commonConfirm = { isOpen: false, content: null };
  };

  @action
  setLogisStaffList = value => {
    this.logisStaffList = value;
  };

  @action
  initLogisStaffList = () => {
    this.logisStaffList = [];
  };

  @action
  openFindUserModal = () => {
    this.findUserModal = true;
  };

  @action
  closeFindUserModal = () => {
    this.findUserModal = false;
  };

  @action
  setSelectedUser = value => {
    this.selectedUser = value;
  };

  @action
  initSelectedUser = () => {
    this.selectedUser = null;
  };

  @action
  confirmModalForInsertOpen = value => {
    this.confirmModalForInsert = { isOpen: true, content: value };
  };

  @action
  confirmModalForInsertClose = () => {
    this.confirmModalForInsert = { isOpen: false, content: null };
  };

  @action
  confirmModalForUpdateOpen = value => {
    this.confirmModalForUpdate = { isOpen: true, content: value };
  };

  @action
  confirmModalForUpdateClose = () => {
    this.confirmModalForUpdate = { isOpen: false, content: null };
  };

  @action
  confirmModalForDeleteOpen = value => {
    this.confirmModalForDelete = { isOpen: true, content: value };
  };

  @action
  confirmModalForDeleteClose = () => {
    this.confirmModalForDelete = { isOpen: false, content: null };
  };

  @action
  confirmModalForCancleOpen = value => {
    this.confirmModalForCancle = { isOpen: true, content: value };
  };

  @action
  confirmModalForCancleClose = () => {
    this.confirmModalForCancle = { isOpen: false, content: null };
  };
}

export default CommonStore;
