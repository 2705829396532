import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Menu, Button, Select, Form, Input } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import QuestionList from '../../../../Component/Management/Setting/Question/QuestionList';
import QuestionReplyContainer from './QuestionReplyContainer';
import QuestionInsertContainer from './QuestionInsertContainer';
import {
  isCustomerMaster,
  isEmp,
  isCustomer,
} from '../../../../Util/CommonUtil';
import {
  STR_QUESTION_CUSTOMER,
  STR_QUESTION_TRUCKER,
} from '../../../../common/Terms';
import {
  API_SELECT_QUESTION_LIST,
  API_UPDATE_QEUSTION_REPLY,
  API_INSERT_QUESTION,
  API_SELECT_QUESTION_LIST_WITH_KEYWORD,
} from '../../../../common/ApiAddress/QuestionAPI';
import {
  API_SELECT_CUSTOMER_SEARCH_LIST,
  API_SELECT_DRIVER_SEARCH_LIST,
} from '../../../../common/ApiAddress/AccountAPI';
import QuestionDetailContainer from './QuestionDetailContainer';
import SearchInput from '../../../../Component/Management/Question/SearchInput';
@inject(stores => ({
  queStore: stores.QuestionStore,
  accStore: stores.AccStore,
}))
@observer
class QuestionManagementContainer extends Component {
  state = {
    replyView: false,
    insertView: false,
    detailView: false,
    addfile1: null,
    addfile2: null,
    searchTyp: null,
    searchKeyword: null,
    resultList: [],
  };

  componentDidMount() {
    this.fnSelectQuestionList();
    this.handleItemClick(null, 'trucker');
  }

  handleItemClick = (e, name) => {
    const { queStore } = this.props;
    if (name == 'trucker') {
      this.setState({ activeItem: name });
      queStore.initForm();
      this.fnSelectQuestionList('T');
    }

    if (name == 'customer') {
      this.setState({ activeItem: name });
      queStore.initForm();
      this.fnSelectQuestionList('C');
    }
  };

  //문의사항 리스트 불러오기
  fnSelectQuestionList = questionCd => {
    const { queStore } = this.props;
    let params = {};
    let that = this;
    if (isEmp()) {
      if (questionCd == undefined) {
        params.questionCd = 'T';
      } else {
        params.questionCd = questionCd;
      }
    } else if (isCustomer()) {
      params.questionCd = 'C';
      params.registId = sessionStorage.getItem('customerId');
    }

    axios({
      url: API_SELECT_QUESTION_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          queStore.setQuestionList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnViewOpen = value => {
    const { queStore } = this.props;
    if (isEmp()) {
      queStore.setSelectedQuestion(value);
      queStore.setReplyContent(value.replyContent);
      this.setState({
        replyView: true,
      });
    } else if (isCustomer() || isCustomerMaster()) {
      queStore.setSelectedQuestion(value);
      this.setState({
        insertView: false,
        detailView: true,
        replyView: false,
      });
    }
  };

  fnUpDateReply = () => {
    const { queStore } = this.props;
    let params = {};
    params.replyContent = queStore.replyContent;
    params.replyRegistId = sessionStorage.getItem('empId');
    params.questionNo = queStore.selectedQuestion.questionNo;

    let that = this;
    axios({
      url: API_UPDATE_QEUSTION_REPLY,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.resultCode == 100) {
          queStore.initForm();
          that.fnSelectQuestionList();
          that.setState({
            replyView: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetFileState = (file, index) => {
    if (index == 0) {
      this.setState({
        addfile1: file,
      });
    } else {
      this.setState({
        addfile2: file,
      });
    }
  };

  fnInitFileState = index => {
    if (index == 0) {
      this.setState({
        addfile1: null,
      });
    } else {
      this.setState({
        addfile2: null,
      });
    }
  };

  fnInsertReply = () => {
    const { queStore } = this.props;
    let that = this;
    const headers = {
      Accept: 'application/json',
      'content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    formData.append('questionTitle', queStore.questionTitle);
    formData.append('questionContent', queStore.questionContent);
    formData.append('questionCd', 'C');
    formData.append('registId', sessionStorage.getItem('customerId'));

    if (this.state.addfile1 == null) {
      formData.append('addFile1', null);
    } else {
      formData.append('addFile1', this.state.addfile1);
    }

    if (this.state.addfile2 == null) {
      formData.append('addFile2', null);
    } else {
      formData.append('addFile2', this.state.addfile2);
    }

    axios({
      url: API_INSERT_QUESTION,
      method: 'post',
      data: formData,
      headers: headers,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.resultCode == 100) {
          queStore.initCustomerForm();
          that.fnSelectQuestionList();
          that.setState({
            insertView: false,
            addfile1: null,
            addfile2: null,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //문의사항 검색 리스트 불러오기
  fnSelectQuestionListWithKeyword = searchKeyword => {
    const { queStore } = this.props;
    let params = {};
    let that = this;
    let questionCd = this.state.activeItem == 'trucker' ? 'T' : 'C';
    params.questionCd = questionCd;
    params.searchKeyword = searchKeyword;
    axios({
      url: API_SELECT_QUESTION_LIST_WITH_KEYWORD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          queStore.setQuestionList(response.data.data);
        } else {
          queStore.initQuestionList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //자동검색 화주 리스트
  getCustomerList = (searchKeyword, searchTyp) => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    params.searchKeyword = searchKeyword;
    params.searchTyp = searchTyp;

    if (isEmp()) {
      params.registId = 'emp';
    }

    if (isCustomerMaster()) {
      params.groupNo = sessionStorage.getItem('groupNo');
    }

    axios({
      url: API_SELECT_CUSTOMER_SEARCH_LIST,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.setState({
            resultList: response.data.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //자동완성 트럭커 리스트
  getDriverList = (searchKeyword, searchTyp) => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    params.searchKeyword = searchKeyword;
    params.searchTyp = searchTyp;
    axios({
      url: API_SELECT_DRIVER_SEARCH_LIST,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.setState({
            resultList: response.data.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { activeItem } = this.state;
    const { queStore } = this.props;
    const searchTypList = [
      { text: '이름', value: 'N' },
      { text: '아이디', value: 'A' },
    ];

    return (
      <React.Fragment>
        <div
          style={{
            width: '40%',
            height: 'calc(100vh - 84px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            padding: '10px',
          }}
        >
          {isEmp() ? (
            <Fragment>
              <Menu style={{ margin: '0px' }}>
                <Menu.Item
                  name="trucker"
                  active={activeItem === 'trucker'}
                  style={{
                    width: '50%',
                    justifyContent: 'center',
                    color: activeItem === 'trucker' ? '#fff' : null,
                    backgroundColor:
                      activeItem === 'trucker' ? '#db3d44' : null,
                  }}
                  onClick={e => {
                    this.handleItemClick(e, 'trucker');
                  }}
                >
                  {STR_QUESTION_TRUCKER}
                </Menu.Item>

                <Menu.Item
                  name="customer"
                  active={activeItem === 'customer'}
                  style={{
                    width: '50%',
                    justifyContent: 'center',
                    color: activeItem === 'customer' ? '#fff' : null,
                    backgroundColor:
                      activeItem === 'customer' ? '#db3d44' : null,
                  }}
                  onClick={e => {
                    this.handleItemClick(e, 'customer');
                  }}
                >
                  {STR_QUESTION_CUSTOMER}
                </Menu.Item>
              </Menu>
              <div>
                <Form style={{ width: '100%', marginTop: '10px' }}>
                  <Form.Field style={{ width: '100%' }}>
                    <Select
                      style={{
                        minWidth: '122px',
                        maxWidth: '122px',
                        marginRight: '10px',
                        float: 'left',
                      }}
                      placeholder={'검색조건 선택'}
                      options={searchTypList}
                      onChange={(e, { value }) =>
                        this.setState({
                          searchTyp: value,
                        })
                      }
                    />

                    <SearchInput
                      searchTyp={this.state.searchTyp}
                      activeItem={this.state.activeItem}
                      getCustomerList={this.getCustomerList}
                      getDriverList={this.getDriverList}
                      resultList={this.state.resultList}
                      fnSelectQuestionListWithKeyword={
                        this.fnSelectQuestionListWithKeyword
                      }
                    />
                  </Form.Field>
                </Form>
              </div>
            </Fragment>
          ) : (
            <Button
              positive
              onClick={() => {
                queStore.initCustomerForm();
                this.setState({
                  insertView: true,
                  detailView: false,
                });
              }}
              style={{ float: 'right', marginBottom: '10px' }}
            >
              문의사항 작성
            </Button>
          )}

          <QuestionList fnViewOpen={this.fnViewOpen} />
        </div>
        <div
          style={{
            width: '60%',
            height: 'calc(100vh - 84px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {this.state.replyView == true ? (
            <QuestionReplyContainer fnUpDateReply={this.fnUpDateReply} />
          ) : null}
          {this.state.insertView == true ? (
            <QuestionInsertContainer
              fnSetFileState={this.fnSetFileState}
              fnInitFileState={this.fnInitFileState}
              fnInsertReply={this.fnInsertReply}
            />
          ) : null}
          {this.state.detailView == true ? <QuestionDetailContainer /> : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(QuestionManagementContainer);
