import React, { Component } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import {
  STR_ID,
  STR_SEN_2,
  STR_SEN_40,
  STR_SEARCH_ID,
  STR_SEARCH_PW,
  STR_COMP_NM,
  STR_EMAIL,
  STR_SEN_30,
} from '../../../common/Terms';
import {
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';
@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class Corporation extends React.Component {
  render() {
    const { accStore, commonStore } = this.props;
    return (
      <div
        style={{
          width: '60%',
          margin: '0 auto ',
          display: 'flex',
          flexGrow: 2,
        }}
      >
        <div style={{ flexGrow: 1, borderRight: '1px solid #fff' }}>
          <h3 style={{ color: COLOR_GREEN_PRIMARY, textAlign: 'center' }}>
            {STR_SEARCH_ID}
          </h3>
          <Form
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '6px',
              padding: '20px',
            }}
          >
            <Form.Field style={{ marginBottom: '23px' }}>
              <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
                {STR_COMP_NM}
              </label>
              <input
                style={{ height: '60px', fontSize: '20px' }}
                placeholder={STR_SEN_40}
                onChange={e => accStore.setCustomerNm(e.target.value)}
                value={accStore.customerNm || ''}
                type="text"
              />
            </Form.Field>
            <Form.Field>
              <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
                {STR_EMAIL}
              </label>
              <input
                style={{
                  height: '60px',
                  fontSize: '20px',
                }}
                placeholder={STR_SEN_30}
                onChange={e => accStore.setEmail(e.target.value)}
                value={accStore.email || ''}
              />
            </Form.Field>
            <div
              onClick={() => {
                this.props.fnSearchCustomerId();
              }}
              style={{
                width: '100%',
                height: '60px',
                marginTop: '33px',
                marginBottom: '33px',
                background: COLOR_GREEN_PRIMARY,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                color: COLOR_WHITE,
                cursor: 'pointer',
              }}
            >
              {STR_SEARCH_ID}
            </div>
          </Form>
        </div>
        <div style={{ flexGrow: 1 }}>
          <h3 style={{ color: COLOR_GREEN_PRIMARY, textAlign: 'center' }}>
            {STR_SEARCH_PW}
          </h3>

          <Form
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '6px',
              padding: '20px',
            }}
          >
            <Form.Field style={{ marginBottom: '23px' }}>
              <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
                {STR_ID}
              </label>
              <input
                style={{ height: '60px', fontSize: '20px' }}
                placeholder={STR_SEN_2}
                onChange={e => accStore.setCustomerId(e.target.value)}
                value={accStore.customerId || ''}
                type="text"
              />
            </Form.Field>
            <Form.Field>
              <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
                {STR_EMAIL}
              </label>
              <input
                style={{
                  height: '60px',
                  fontSize: '20px',
                }}
                placeholder={STR_SEN_30}
                onChange={e => this.props.fnSetEmailForSearch(e.target.value)}
                value={this.props.email || ''}
              />
            </Form.Field>

            <div
              onClick={() => {
                if (
                  this.props.email == null ||
                  this.props.email == '' ||
                  accStore.customerId == null ||
                  accStore.customerId == ''
                ) {
                  commonStore.commonAlertOpen('아이디, 이메일을 입력해주세요.');
                } else {
                  this.props.fnCheckPwIsMatch();
                }
              }}
              style={{
                width: '100%',
                height: '60px',
                marginTop: '33px',
                marginBottom: '33px',
                background: COLOR_GREEN_PRIMARY,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                color: COLOR_WHITE,
                cursor: 'pointer',
              }}
            >
              {STR_SEARCH_PW}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Corporation;
