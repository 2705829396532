import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class FreightFeeStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable sidoList = [];
  @observable siguList = [];
  @observable dongList = [];

  @observable selectedSido = null;
  @observable selectedSigu = null;
  @observable selectedDong = null;
  @observable selectedVc = null;

  @observable startFee = null;
  @observable endFee = null;

  @action
  setSidoList = value => {
    this.sidoList = value;
  };

  @action
  initSidoList = () => {
    this.sidoList = [];
  };

  @action
  setSiguList = value => {
    this.siguList = value;
  };

  @action
  initSiguList = () => {
    this.siguList = [];
  };

  @action
  setDongList = value => {
    this.dongList = value;
  };

  @action
  initDongList = () => {
    this.dongList = [];
  };

  @action
  setSelectedSido = value => {
    this.selectedSido = value;
  };

  @action
  initSelectedSido = () => {
    this.selectedSido = null;
  };

  @action
  setSelectedSigu = value => {
    this.selectedSigu = value;
  };

  @action
  initSelectedSigu = () => {
    this.selectedSigu = null;
  };

  @action
  setSelectedDong = value => {
    this.selectedDong = value;
  };

  @action
  initSelectedDong = () => {
    this.selectedDong = null;
  };

  @action
  setStartFee = value => {
    this.startFee = value;
  };

  @action
  initStartFee = () => {
    this.startFee = null;
  };

  @action
  setEndFee = value => {
    this.endFee = value;
  };

  @action
  initEndFee = () => {
    this.endFee = null;
  };

  @action
  setSelectedVc = value => {
    this.selectedVc = value;
  };

  @action
  initSelectedVc = () => {
    this.selectedVc = null;
  };

  @action
  initForm = () => {
    this.initSelectedSido();
    this.initSelectedSigu();
    this.initSelectedDong();
    this.initSiguList();
    this.initDongList();
    this.initStartFee();
    this.initEndFee();
    this.initSelectedVc();
  };
}

export default FreightFeeStore;
