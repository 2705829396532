import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  orderStore: stores.OrderStore,
}))
@observer
class FreightList extends Component {
  state = {
    column: null,
    direction: null,
  };

  handleSort = clickedColumn => {
    const { column, direction } = this.state;
    const { orderStore } = this.props;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
      });
      let listItem = _.sortBy(orderStore.freightList, [clickedColumn]);
      orderStore.setFreightList(listItem);

      return;
    }

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
    let listItem = [...orderStore.freightList];
    orderStore.setFreightList(listItem.reverse());
  };

  render() {
    const { orderStore } = this.props;
    return (
      <Fragment>
        <Table sortable style={{ border: '0px' }}>
          {orderStore.freightList == null ||
          orderStore.freightList.length == 0 ? null : (
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                  }}
                  sorted={
                    this.state.column === 'freightNo'
                      ? this.state.direction
                      : null
                  }
                  onClick={() => this.handleSort('freightNo')}
                >
                  운송번호
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    borderLeft: '0px',
                    background: '#fff',
                  }}
                  sorted={
                    this.state.column === 'freightCdNm'
                      ? this.state.direction
                      : null
                  }
                  onClick={() => this.handleSort('freightCdNm')}
                >
                  운송상태
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    borderLeft: '0px',
                    background: '#fff',
                  }}
                  sorted={
                    this.state.column === 'registId'
                      ? this.state.direction
                      : null
                  }
                  onClick={() => this.handleSort('registId')}
                >
                  화주ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    borderLeft: '0px',
                    background: '#fff',
                  }}
                  sorted={
                    this.state.column === 'driverId'
                      ? this.state.direction
                      : null
                  }
                  onClick={() => this.handleSort('driverId')}
                >
                  트럭커ID
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          {orderStore.freightList == null ||
          orderStore.freightList.length == 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="14" textAlign="center">
                  등록된 화물내역이 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {orderStore.freightList.map((data, i) => (
                <React.Fragment key={data.freightNo}>
                  <Table.Row
                    onClick={() => this.props.fnDetailViewOpen(data, i)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        orderStore.selectedFreight != null &&
                        orderStore.selectedFreight.freightNo === data.freightNo
                          ? COLOR_GREEN_PRIMARY
                          : COLOR_WHITE,
                      color:
                        orderStore.selectedFreight != null &&
                        orderStore.selectedFreight.freightNo === data.freightNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    <Table.Cell>{data.freightNo}</Table.Cell>
                    <Table.Cell>
                      <div
                        style={{
                          width: '49px',
                          height: '20px',
                          background:
                            data.freightCd == 'IFRST001'
                              ? null
                              : data.freightCd == 'IFRST003'
                              ? COLOR_PRIMARY
                              : data.freightCd == 'IFRST004'
                              ? COLOR_GREEN_PRIMARY
                              : data.freightCd == 'IFRST005'
                              ? '#6e6ee6'
                              : data.freightCd == 'IFRST006'
                              ? '#FEF0BC'
                              : data.freightCd == 'IFRST007'
                              ? '#000'
                              : null,

                          color:
                            data.freightCd == 'IFRST001'
                              ? COLOR_GRAY_GR06
                              : data.freightCd == 'IFRST003'
                              ? COLOR_WHITE
                              : data.freightCd == 'IFRST004'
                              ? COLOR_WHITE
                              : data.freightCd == 'IFRST005'
                              ? COLOR_WHITE
                              : data.freightCd == 'IFRST006'
                              ? '#FFB800'
                              : data.freightCd == 'IFRST007'
                              ? '#fff'
                              : null,

                          border:
                            data.freightCd == 'IFRST001'
                              ? '1px solid #8F8F8F'
                              : data.freightCd == 'IFRST003'
                              ? '1px solid #f73677'
                              : data.freightCd == 'IFRST004'
                              ? '1px solid #78C939'
                              : data.freightCd == 'IFRST005'
                              ? '1px solid #6e6ee6'
                              : data.freightCd == 'IFRST006'
                              ? '1px solid #FEF0BC'
                              : null,
                          padding: '2px 4px',
                          borderRadius: '3px',
                          fontSize: '11px',
                          fontWeight: '700',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {data.freightCdNm}
                      </div>
                    </Table.Cell>
                    <Table.Cell>{data.registId}</Table.Cell>
                    <Table.Cell>{data.driverId}</Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
        <div style={{ textAlign: 'right' }}>
          <Pagination
            id="pagination"
            boundaryRange={1}
            defaultActivePage={this.props.freightListCurrentPageNum}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={this.props.freightListTotalPageNum}
            onPageChange={(e, data) => {
              this.props.fnDirectListMove(data.activePage);
            }}
            style={{ border: '0px' }}
          />
        </div>
      </Fragment>
    );
  }
}

export default FreightList;
