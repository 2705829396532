import React, { Component } from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { STR_OK, STR_PW, STR_SEN_3 } from '../../common/Terms';

@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class StaffPWModal extends Component {
  render() {
    const { accStore } = this.props;
    return (
      <Modal
        size="tiny"
        open={this.props.open}
        onClose={() => this.props.onClose()}
        style={{ height: '270px' }}
      >
        <Modal.Header>{this.props.modalHeader}</Modal.Header>
        <Modal.Content>
          <Form
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '6px',
              padding: '20px',
            }}
          >
            <Form.Field>
              <label>{STR_PW}</label>
              <input
                placeholder={STR_SEN_3}
                onChange={e => accStore.setStaffPw(e.target.value)}
                value={accStore.staffPw || ''}
                type="password"
              />
            </Form.Field>

            <Button
              onClick={() => {
                this.props.fnMovetoMenu();
              }}
              size="medium"
              color="pink"
              style={{ width: '100%', marginTop: '10px' }}
            >
              {STR_OK}
            </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default StaffPWModal;
