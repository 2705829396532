import React, { Component } from 'react';
import axios from 'axios';
import { ReactComponent as MainTop } from '../../assets/icon/main_top.svg';
import { COLOR_GREEN_PRIMARY, COLOR_WHITE } from '../../common/Color';
import { inject, observer } from 'mobx-react';
import CommonAlert from '../../Component/Common/CommonAlert';
import CommonAlertWithCallback from '../../Component/Common/CommonAlertWithCallback';
import moment from 'moment/moment';
import MediaQuery from 'react-responsive';
import FreightRequesForm from './FreightRequestForm';
import CombinedFreightRequsetForm from './CombinedFreightRequsetForm';
@inject(stores => ({
  quoteStore: stores.QuoteStore,
  vcStore: stores.VcStore,
  commonStore: stores.CommonStore,
}))
@observer
class QuoteMain extends Component {
  state = {
    randomList: [],
  };

  componentDidMount() {
    this.props.fnSelectVcWeightCd();
    //this.fnSelectOrderListFromCOMS();

    //let isMobile = /Mobi/i.test(window.navigator.userAgent);
    //if (isMobile) {
    this.fnRandomFreight();
    //}
  }

  fnRandomFreight = () => {
    const location = [
      '경기 안산',
      '경기 시흥',
      '인천 남동',
      '경기 화성',
      '서울 용산',
      '부산 기장',
      '충남 천안',
      '경기 남양주',
      '서울 중구',
      '경기 평택',
      '경기 오산',
      '경남 창원',
      '충남 당진',
      '경기 고양',
      '경기 군포',
      '강원 춘천',
      '경남 진해',
      '전북 전주',
      '충남 공주',
      '경기 가평',
      '부산 남구',
      '충남 보령',
      '충남 금산',
      '경북 상주',
      '경북 영주',
      '경북 의성',
      '경남 양산',
      '전북 군산',
      '전남 여수',
      '전남 순천',
    ];

    const vc = [
      '라보',
      '다마스',
      '1톤',
      '1.4톤',
      '2.5톤',
      '3.5톤',
      '5톤',
      '11톤',
      '25톤',
    ];

    const status = ['배차완료', '배차완료', '배차완료', '배차완료', '배차중'];
    const cargoDetail = [
      '합판',
      '원자재',
      '기계장비',
      '파렛제품',
      '철제품',
      '금형',
      '사출품',
      '철판',
      '아이스팩',
      '목재',
      '자제',
      '각제',
      '파이프',
    ];
    let randomList = [];
    for (let i = 0; i < 10; i++) {
      let item = {};
      let randomloading = location[Math.floor(Math.random() * location.length)];
      let randomunloading =
        location[Math.floor(Math.random() * location.length)];
      let randomVc = vc[Math.floor(Math.random() * vc.length)];
      let randomstatus = status[Math.floor(Math.random() * status.length)];
      let randomcargoDetail =
        cargoDetail[Math.floor(Math.random() * cargoDetail.length)];

      item.date = moment().format('YYYY-MM-DD');
      item.loading = randomloading;
      item.unloading = randomunloading;
      item.vc = randomVc;
      item.status = randomstatus;
      item.cargoDetail = randomcargoDetail;
      randomList.push(item);
    }
    this.setState({
      randomList: randomList,
    });
  };

  fnSelectOrderListFromCOMS = () => {
    const { quoteStore } = this.props;
    let params = {};
    let that = this;
    params.page_num = 1;
    params.page_size = 10;

    axios({
      url: 'http://coms.teamtrucker.co.kr/api/transport/orders/',
      method: 'get',
      params: params,
    })
      .then(function (response) {
        console.log(response);
        if (response.status == 200) {
          quoteStore.setOrderList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { quoteStore, vcStore } = this.props;
    return (
      <React.Fragment>
        <MediaQuery maxWidth={768}>
          <div
            id="specTable"
            style={{
              width: '100%',
              height: 'calc(100vh - 210px)',
              overflowY: 'scroll',
              background: COLOR_WHITE,
            }}
          >
            <div
              style={{
                width: '100%',
                position: 'relative',
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: 'fit-content',
                  verticalAlign: 'middle',
                }}
                src={'../../image/main_top_mo.png'}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  textAlign: 'center',
                  letterSpacing: '-1px',
                  transform: 'translate( -50%, -50% )',
                }}
              >
                <p
                  style={{
                    color: COLOR_WHITE,
                    fontSize: '22px',
                    fontWeight: '700',
                    marginBottom: '0px',
                  }}
                >
                  산업과 산업을 연결하는
                </p>
                <p
                  style={{
                    color: COLOR_WHITE,
                    fontSize: '22px',
                    fontWeight: '700',
                  }}
                >
                  물류의 근간,{' '}
                  <span
                    style={{
                      color: COLOR_GREEN_PRIMARY,
                      fontSize: '22px',
                      fontWeight: '700',
                    }}
                  >
                    팀트럭커
                  </span>
                </p>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                background: COLOR_WHITE,
                paddingLeft: '19px',
              }}
            >
              <p
                style={{
                  fontSize: '20px',
                  fontWeight: '700',
                  paddingTop: '23px',
                }}
              >
                실시간 배차현황
              </p>
            </div>
            <div
              style={{
                background: COLOR_WHITE,
                textAlign: 'center',
                width: '100%',
                margin: '0 auto',
                marginBottom: '40px',
                paddingLeft: '4%',
                paddingRight: '4%',
                paddingTop: '20px',
              }}
            >
              <table
                style={{
                  width: '100%',
                  border: '0px',
                  borderCollapse: 'collapse',
                  textAlign: 'left',
                  marginBottom: '44px',
                }}
              >
                <tr>
                  <th
                    style={{
                      borderBottom: '1px solid #CED4DA',
                      width: '23%',
                      padding: '0px',
                      fontSize: '13px',
                      fontWeight: '700',
                      color: COLOR_GREEN_PRIMARY,
                    }}
                  >
                    일자
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid #CED4DA',
                      width: '18%',
                      padding: '0px',
                      fontSize: '13px',
                      fontWeight: '700',
                      color: COLOR_GREEN_PRIMARY,
                    }}
                  >
                    상차지
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid #CED4DA',
                      width: '18%',
                      padding: '0px',
                      fontSize: '13px',
                      fontWeight: '700',
                      color: COLOR_GREEN_PRIMARY,
                    }}
                  >
                    하차지
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid #CED4DA',
                      width: '15%',
                      padding: '0px',
                      fontSize: '13px',
                      fontWeight: '700',
                      color: COLOR_GREEN_PRIMARY,
                    }}
                  >
                    차종
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid #CED4DA',
                      width: '20%',
                      padding: '0px',
                      fontSize: '13px',
                      fontWeight: '700',
                      color: COLOR_GREEN_PRIMARY,
                    }}
                  >
                    배차상태
                  </th>
                </tr>
                {this.state.randomList.map((data, i) => (
                  <tr>
                    <th
                      style={{
                        borderBottom:
                          this.state.randomList.length == i + 1
                            ? '0px'
                            : '1px solid #E9ECEF',
                        width: '23%',
                        padding: '0px',
                        fontWeight: '400',
                        fontSize: '13px',
                        lineHeight: '16px',
                        height: '34px',
                      }}
                    >
                      {data.date}
                    </th>
                    <th
                      style={{
                        borderBottom:
                          this.state.randomList.length == i + 1
                            ? '0px'
                            : '1px solid #E9ECEF',
                        width: '20%',
                        padding: '0px',
                        fontWeight: '400',
                        fontSize: '13px',
                        lineHeight: '16px',
                        height: '34px',
                      }}
                    >
                      {data.loading}
                    </th>
                    <th
                      style={{
                        borderBottom:
                          this.state.randomList.length == i + 1
                            ? '0px'
                            : '1px solid #E9ECEF',
                        width: '20%',
                        padding: '0px',
                        fontWeight: '400',
                        fontSize: '13px',
                        lineHeight: '16px',
                        height: '34px',
                      }}
                    >
                      {data.unloading}
                    </th>
                    <th
                      style={{
                        borderBottom:
                          this.state.randomList.length == i + 1
                            ? '0px'
                            : '1px solid #E9ECEF',
                        width: '15%',
                        padding: '0px',
                        fontWeight: '400',
                        fontSize: '13px',
                        lineHeight: '16px',
                        height: '34px',
                      }}
                    >
                      {data.vc}
                    </th>
                    <th
                      style={{
                        borderBottom:
                          this.state.randomList.length == i + 1
                            ? '0px'
                            : '1px solid #E9ECEF',
                        width: '23%',
                        padding: '0px',
                        fontWeight: '700',
                        fontSize: '13px',
                        lineHeight: '16px',
                        height: '34px',
                        color:
                          data.status == '배차완료' ? '#21BA45' : '#FFB800',
                      }}
                    >
                      <span
                        style={{
                          padding: '2px 4px',
                          borderRadius: '3px',
                          border:
                            data.status == '배차완료'
                              ? '1px solid #21BA45'
                              : '1px solid #FFB800',
                        }}
                      >
                        {data.status}
                      </span>
                    </th>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={1025}>
          <div style={{ width: '100%' }}>
            <div
              style={{
                width: '100%',
                height: '400px',
                position: 'relative',
              }}
            >
              <MainTop
                style={{
                  marginTop: '16px',
                  verticalAlign: 'middle',
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '40%',
                  left: '40%',
                  textAlign: 'center',
                  letterSpacing: '-1px',
                }}
              >
                <p
                  style={{
                    color: COLOR_WHITE,
                    fontSize: '40px',
                    fontWeight: '700',
                    marginBottom: '0px',
                  }}
                >
                  산업과 산업을 연결하는
                </p>
                <p
                  style={{
                    color: COLOR_WHITE,
                    fontSize: '40px',
                    fontWeight: '700',
                  }}
                >
                  물류의 근간,{' '}
                  <span
                    style={{
                      color: COLOR_GREEN_PRIMARY,
                      fontSize: '40px',
                      fontWeight: '700',
                    }}
                  >
                    팀트럭커
                  </span>
                </p>
              </div>
            </div>
            <div
              style={{
                width: '90%',
                //margin:"0 auto",
                marginBottom: '44px',
                height: '530px',
                marginLeft: '10%',
              }}
            >
              <div
                style={{
                  width: '60%',
                  background: COLOR_WHITE,
                  paddingLeft: '59px',
                  borderRadius: '12px',
                  height: '500px',
                  paddingRight: '59px',
                  marginLeft: '12px',
                  float: 'left',
                  marginRight: '12px',
                  marginTop: '39px',
                  marginBottom: '44px',
                }}
              >
                <p
                  style={{
                    fontSize: '24px',
                    fontWeight: '700',
                    lineHeight: '34.75px',
                    paddingTop: '27px',
                  }}
                >
                  실시간 배차현황
                </p>
                <div>
                  <table
                    style={{
                      width: '100%',
                      border: '0px',
                      borderCollapse: 'collapse',
                      textAlign: 'left',
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          borderBottom: '1px solid #CED4DA',
                          width: '15%',
                          padding: '0px',
                          fontSize: '14px',
                          fontWeight: '700',
                        }}
                      >
                        일자
                      </th>
                      <th
                        style={{
                          borderBottom: '1px solid #CED4DA',
                          width: '15%',
                          padding: '0px',
                          fontSize: '14px',
                          fontWeight: '700',
                        }}
                      >
                        상차지
                      </th>
                      <th
                        style={{
                          borderBottom: '1px solid #CED4DA',
                          width: '15%',
                          padding: '0px',
                          fontSize: '14px',
                          fontWeight: '700',
                        }}
                      >
                        하차지
                      </th>
                      <th
                        style={{
                          borderBottom: '1px solid #CED4DA',
                          width: '15%',
                          padding: '0px',
                          fontSize: '14px',
                          fontWeight: '700',
                        }}
                      >
                        차종
                      </th>
                      <th
                        style={{
                          borderBottom: '1px solid #CED4DA',
                          width: '15%',
                          padding: '0px',
                          fontSize: '14px',
                          fontWeight: '700',
                        }}
                      >
                        화물정보
                      </th>
                      <th
                        style={{
                          borderBottom: '1px solid #CED4DA',
                          width: '15%',
                          padding: '0px',
                          fontSize: '14px',
                          fontWeight: '700',
                        }}
                      >
                        배차상태
                      </th>
                    </tr>
                    {this.state.randomList.map((data, i) => (
                      <tr>
                        <th
                          style={{
                            borderBottom:
                              this.state.randomList.length == i + 1
                                ? '0px'
                                : '1px solid #E9ECEF',
                            width: '15%',
                            padding: '0px',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '16px',
                            height: '34px',
                          }}
                        >
                          {moment(data.date).format('YYYY-MM-DD')}
                        </th>
                        <th
                          style={{
                            borderBottom:
                              this.state.randomList.length == i + 1
                                ? '0px'
                                : '1px solid #E9ECEF',
                            width: '15%',
                            padding: '0px',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '16px',
                            height: '34px',
                          }}
                        >
                          {data.loading}
                        </th>
                        <th
                          style={{
                            borderBottom:
                              this.state.randomList.length == i + 1
                                ? '0px'
                                : '1px solid #E9ECEF',
                            width: '15%',
                            padding: '0px',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '16px',
                            height: '34px',
                          }}
                        >
                          {data.unloading}
                        </th>
                        <th
                          style={{
                            borderBottom:
                              this.state.randomList.length == i + 1
                                ? '0px'
                                : '1px solid #E9ECEF',
                            width: '15%',
                            padding: '0px',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '16px',
                            height: '34px',
                          }}
                        >
                          {data.vc}
                        </th>
                        <th
                          style={{
                            borderBottom:
                              this.state.randomList.length == i + 1
                                ? '0px'
                                : '1px solid #E9ECEF',
                            width: '15%',
                            padding: '0px',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '16px',
                            height: '34px',
                          }}
                        >
                          {data.cargoDetail}
                        </th>
                        <th
                          style={{
                            borderBottom:
                              this.state.randomList.length == i + 1
                                ? '0px'
                                : '1px solid #E9ECEF',
                            width: '15%',
                            padding: '0px',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '16px',
                            height: '34px',
                            color:
                              data.status == '배차완료'
                                ? COLOR_GREEN_PRIMARY
                                : '#FFB800',
                          }}
                        >
                          {data.status}
                        </th>
                      </tr>
                    ))}
                    {/* {quoteStore.orderList.length > 0
                          ? quoteStore.orderList.map((data, i) => (
                              <tr>
                                <th
                                  style={{
                                    borderBottom:
                                      quoteStore.orderList.length == i + 1
                                        ? '0px'
                                        : '1px solid #E9ECEF',
                                    width: '15%',
                                    padding: '0px',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    height: '34px',
                                  }}
                                >
                                  {moment(data.order_date).format('YYYY-MM-DD')}
                                </th>
                                <th
                                  style={{
                                    borderBottom:
                                      quoteStore.orderList.length == i + 1
                                        ? '0px'
                                        : '1px solid #E9ECEF',
                                    width: '15%',
                                    padding: '0px',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    height: '34px',
                                  }}
                                >
                                  {data.loaders[0].company_address}
                                </th>
                                <th
                                  style={{
                                    borderBottom:
                                      quoteStore.orderList.length == i + 1
                                        ? '0px'
                                        : '1px solid #E9ECEF',
                                    width: '15%',
                                    padding: '0px',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    height: '34px',
                                  }}
                                >
                                  {data.unloaders[0].company_address}
                                </th>
                                <th
                                  style={{
                                    borderBottom:
                                      quoteStore.orderList.length == i + 1
                                        ? '0px'
                                        : '1px solid #E9ECEF',
                                    width: '15%',
                                    padding: '0px',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    height: '34px',
                                  }}
                                >
                                  {data.cargo_weights[0].cargo_weight + '톤'}
                                </th>
                                <th
                                  style={{
                                    borderBottom:
                                      quoteStore.orderList.length == i + 1
                                        ? '0px'
                                        : '1px solid #E9ECEF',
                                    width: '15%',
                                    padding: '0px',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    height: '34px',
                                  }}
                                >
                                  {data.cargo_detail}
                                </th>
                                <th
                                  style={{
                                    borderBottom:
                                      quoteStore.orderList.length == i + 1
                                        ? '0px'
                                        : '1px solid #E9ECEF',
                                    width: '15%',
                                    padding: '0px',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    height: '34px',
                                    color:
                                      moment(data.order_date).format(
                                        'YYYY-MM-DD HH:mm',
                                      ) <= moment().format('YYYY-MM-DD HH:mm')
                                        ? COLOR_GREEN_PRIMARY
                                        : COLOR_PRIMARY,
                                  }}
                                >
                                  {moment(data.order_date).format(
                                    'YYYY-MM-DD HH:mm',
                                  ) <= moment().format('YYYY-MM-DD HH:mm')
                                    ? '배차완료'
                                    : '배차중'}
                                </th>
                              </tr>
                            ))
                          : null} */}
                  </table>
                </div>
              </div>
              <div
                style={{
                  height: '500px',
                  width: '25%',
                  background: COLOR_WHITE,
                  paddingLeft: '12px',
                  borderRadius: '12px',
                  paddingRight: '12px',
                  float: 'left',
                  overflowY: 'scroll',
                  marginTop: '39px',
                  marginBottom: '44px',
                }}
              >
                {this.props.combinedFreight == false ? (
                  <FreightRequesForm
                    fnOpenDepartureModal={this.props.fnOpenDepartureModal}
                    fnSetDepartureDetailAdd={this.props.fnSetDepartureDetailAdd}
                    fnOpenArriveModal={this.props.fnOpenArriveModal}
                    fnSetArriveDetailAdd={this.props.fnSetArriveDetailAdd}
                    fnSetVcWeightCd={this.props.fnSetVcWeightCd}
                    fnSetVcTypCd={this.props.fnSetVcTypCd}
                    fnSetOrdererNm={this.props.fnSetOrdererNm}
                    fnSetOrdererHpNo={this.props.fnSetOrdererHpNo}
                    fnInsertFreightMini={this.props.fnInsertFreightMini}
                    fnSetUnloadingStaffNm={this.props.fnSetUnloadingStaffNm}
                    fnSetUnloadingStaffHpNo={this.props.fnSetUnloadingStaffHpNo}
                  />
                ) : (
                  <CombinedFreightRequsetForm
                    fnOpenDepartureModal={this.props.fnOpenDepartureModal}
                    fnSetDepartureDetailAdd={this.props.fnSetDepartureDetailAdd}
                    fnOpenArriveModal={this.props.fnOpenArriveModal}
                    fnSetArriveDetailAdd={this.props.fnSetArriveDetailAdd}
                    fnSetVcWeightCd={this.props.fnSetVcWeightCd}
                    fnSetVcTypCd={this.props.fnSetVcTypCd}
                    fnSetOrdererNm={this.props.fnSetOrdererNm}
                    fnSetOrdererHpNo={this.props.fnSetOrdererHpNo}
                    fnSendCombinedFreightRequest={
                      this.props.fnSendCombinedFreightRequest
                    }
                    fnSetUnloadingStaffNm={this.props.fnSetUnloadingStaffNm}
                    fnSetUnloadingStaffHpNo={this.props.fnSetUnloadingStaffHpNo}
                  />
                )}
              </div>
            </div>

            <CommonAlert />
            <CommonAlertWithCallback callback={this.fnReloadPage} />
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default QuoteMain;
