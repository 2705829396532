import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class MenuStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable menuList = [];
  @observable selectedMenu = null;

  @observable menuNm = null;
  @observable menuPath = null;
  @observable depth = null;
  @observable sortNo = null;
  @observable primaryNo = null;
  @observable primaryNm = null;
  @observable eventKey = null;
  @observable icon = null;
  @observable useYn = false;

  @observable primaryMenuList = [];

  @action
  setMenuList = value => {
    this.menuList = value;
  };

  @action
  initMenuList = () => {
    this.menuList = [];
  };

  @action
  setSelectedMenu = value => {
    this.selectedMenu = value;
  };

  @action
  initSelectedMenu = () => {
    this.selectedMenu = null;
  };

  @action
  setMenuNm = value => {
    this.menuNm = value;
  };

  @action
  initMenuNm = () => {
    this.menuNm = null;
  };

  @action
  setMenuPath = value => {
    this.menuPath = value;
  };

  @action
  initMenuPath = () => {
    this.menuPath = null;
  };

  @action
  setDepth = value => {
    this.depth = value;
  };

  @action
  initDepth = () => {
    this.depth = null;
  };

  @action
  setSortNo = value => {
    this.sortNo = value;
  };

  @action
  initSortNo = () => {
    this.sortNo = null;
  };

  @action
  setPrimaryNo = value => {
    this.primaryNo = value;
  };

  @action
  initPrimaryNo = () => {
    this.primaryNo = null;
  };

  @action
  setPrimaryNm = value => {
    this.primaryNm = value;
  };

  @action
  initPrimaryNm = () => {
    this.primaryNm = null;
  };

  @action
  setEventKey = value => {
    this.eventKey = value;
  };

  @action
  initEventKey = () => {
    this.eventKey = null;
  };

  @action
  setIcon = value => {
    this.icon = value;
  };

  @action
  initIcon = () => {
    this.icon = null;
  };

  @action
  setPrimaryMenuList = value => {
    this.primaryMenuList = value;
  };

  @action
  initPrimaryMenuList = value => {
    this.primaryMenuList = [];
  };

  @action
  setUseYnTrue = () => {
    this.useYn = true;
  };

  @action
  setUseYnFalse = () => {
    this.useYn = false;
  };

  @action
  initForm = () => {
    this.initSelectedMenu();
    this.initMenuNm();
    this.initMenuPath();
    this.initDepth();
    this.initSortNo();
    this.initPrimaryNo();
    this.initPrimaryNm();
    this.initEventKey();
    this.initIcon();
    this.initPrimaryMenuList();
    this.setUseYnFalse();
  };
}

export default MenuStore;
