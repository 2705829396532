import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Header from '../Component/Common/Header';
import MainFooter from '../Component/Common/MainFooter';

class Main extends React.Component {
  fnMoveToLogin = () => {
    this.props.history.push('/login');
  };

  render() {
    const { accStore } = this.props;
    return (
      <React.Fragment>
        <Header fnMoveToLogin={this.fnMoveToLogin} />
        <div
          style={{
            background: 'url(./image/main_1.jpg)',
            width: '100%',
            height: '100vh',
            zIndex: 98,
          }}
        />
        <div
          style={{
            background: 'rgba(0,0,0,0.5)',
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100vh',
            zIndex: 99,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ top: '384 !important' }}>
            <p
              style={{
                color: '#fff',
                fontSize: '36px',
                fontWeight: 'bold',
                marginBottom: '0px',
              }}
            >
              산업과 산업을 연결하는
            </p>
            <p style={{ color: '#fff', fontSize: '36px', fontWeight: 'bold' }}>
              물류의 근간,{' '}
              <span
                style={{
                  color: '#78C939',
                  fontSize: '36px',
                  fontWeight: 'bold',
                }}
              >
                팀트럭커
              </span>
            </p>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '100vh',
            background: '#000',
            position: 'relative',
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '77px',
                zIndex: 102,
                position: 'absolute',
                top: 120,
              }}
              src={'./image/app_icon_customer.png'}
            />
          </div> */}
          <div
            style={{
              width: '100%',
              height: '100vh',
              zIndex: 99,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              //position: 'relative',
              top: -80,
            }}
          >
            <div>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                지금껏 산업을 일구어 온 것은
              </p>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                }}
              >
                기술이 아닌 사람이입니다.
              </p>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                앞으로도 산업과 산업은
              </p>
              <p
                style={{ color: '#fff', fontSize: '36px', fontWeight: 'bold' }}
              >
                <span
                  style={{
                    color: '#78C939',
                    fontSize: '36px',
                    fontWeight: 'bold',
                  }}
                >
                  팀트럭커
                </span>
                가 연결합니다.
              </p>
            </div>
          </div>
          <img
            style={{
              width: '768px',
              zIndex: '99',
              position: 'relative',
              top: '50.1%',
              height: 'calc(100vh * 0.5)',
            }}
            src={'./image/mainTemp.png'}
          />
        </div>
        <div>
          <div
            style={{
              background: 'url(./image/main_2.png)',
              width: '100%',
              height: '100vh',
              zIndex: 98,
              position: 'absolute',
            }}
          />
          <div
            style={{
              background: 'rgba(0,0,0,0.5)',
              position: 'relative',
              top: 0,
              width: '100%',
              height: '100vh',
              zIndex: 99,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ top: '384 !important' }}>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                혁신적인 IT서비스를 기반으로
              </p>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                물류비 절감과 안전운임 달성을
              </p>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                동시에 추구하는
              </p>
              <p
                style={{
                  color: '#78C939',
                  fontSize: '36px',
                  fontWeight: 'bold',
                }}
              >
                TeamTrucker 플랫폼
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '100vh',
            background: '#000',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100vh',
              zIndex: 99,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              //position: 'relative',
              top: -80,
            }}
          >
            <div>
              {/* <img
                style={{
                  width: '77px',
                  zIndex: 102,

                  position: 'relative',
                }}
                src={'./image/app_icon_trucker.png'}
              /> */}
              <div
                style={{
                  background: '#78C939',
                  width: '60%',
                  margin: '0 auto',
                  border: '1xp solid #78C939',
                  borderRadius: '20px',
                  marginBottom: '126px',
                  marginTop: '20px',
                }}
              ></div>
              <p
                style={{
                  color: '#fff',
                  fontSize: '36px',
                  fontWeight: 'bold',
                  marginBottom: '0px',
                }}
              >
                산업과 산업을 연결하는
              </p>
              <p
                style={{ color: '#fff', fontSize: '36px', fontWeight: 'bold' }}
              >
                물류의 근간,
                <span
                  style={{
                    color: '#78C939',
                    fontSize: '36px',
                    fontWeight: 'bold',
                  }}
                >
                  팀트럭커
                </span>
              </p>
              {/* <div style={{ marginTop: '120px' }}>
                <p
                  style={{
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginBottom: '0px',
                  }}
                >
                  App Test Version for Android
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <div style={{ marginRight: '40px' }}>
                    <img
                      style={{
                        width: '77px',
                        zIndex: 102,

                        position: 'relative',
                      }}
                      src={'./image/app_icon_customer.png'}
                    />
                    <a
                      href="https://drive.google.com/file/d/10BLuhBWyZCTwaKDf__UUhFV2izDNSerb/view?usp=sharing"
                      download
                    >
                      <p
                        style={{
                          color: '#fff',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          marginBottom: '0px',
                        }}
                      >
                        화주용
                      </p>
                    </a>
                  </div>
                  <div>
                    <img
                      style={{
                        width: '77px',
                        zIndex: 102,

                        position: 'relative',
                      }}
                      src={'./image/app_icon_trucker.png'}
                    />
                    <a
                      href="https://drive.google.com/file/d/11w-Z9Fnfz8Ji-fY8JYuhqAseinRGLncZ/view?usp=sharing"
                      download
                    >
                      <p
                        style={{
                          color: '#fff',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          marginBottom: '0px',
                        }}
                      >
                        트럭커용
                      </p>
                    </a>
                  </div>
                </div> 
              </div>*/}
            </div>
          </div>
        </div>

        <MainFooter />
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
