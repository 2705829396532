import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { COLOR_GRAY_GR02, COLOR_WHITE } from '../../../common/Color';
import { Button } from 'semantic-ui-react';
import CompUpsertContainer from './CompUpsertContainer';
import {
  API_INSERT_COMP,
  API_SELECT_COMP_LIST,
  API_UPDATE_COMP,
} from '../../../common/ApiAddress/CompAPI';
import CompList from './CompList';
@inject(stores => ({
  quoteStore: stores.QuoteStore,
  vcStore: stores.VcStore,
  commonStore: stores.CommonStore,
  compStore: stores.CompStore,
}))
@observer
class CompManagement extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
    selectedCompIndex: 0,
  };

  componentDidMount() {
    this.fnSelectCompList();
  }

  fnInsertViewOpen = () => {
    this.setState({
      upsertView: true,
      upsertMode: 'I',
      selectedCompIndex: 0,
    });
  };

  fnUpdateViewOpen = (value, i) => {
    const { compStore } = this.props;
    compStore.setSelectedComp(value);
    compStore.setCompNm(value.compNm);
    compStore.setCompBsNo(value.compBsNo);
    compStore.setCompHpNo(value.compHpNo);
    compStore.setCompFaxNo(value.compFaxNo);
    compStore.setRepresentative(value.representative);
    compStore.setBsTyp(value.bsTyp);
    compStore.setBsNm(value.bsNm);
    compStore.setCompAdd(value.compAdd);
    compStore.setCompCd(value.compCd);
    compStore.setEmail(value.email);
    this.setState({
      upsertView: true,
      upsertMode: 'U',
      selectedCompIndex: i,
    });
  };

  handleChangeCompCd = (e, { value }) => {
    this.fnOnchangeCompCd(value);
  };

  fnOnchangeCompCd = value => {
    const { compStore } = this.props;
    console.log(value);
    compStore.setCompCd(value);
  };

  fnUpsertComp = () => {
    const { compStore } = this.props;
    let params = {};
    let that = this;
    params.compNm = compStore.compNm;
    params.compBsNo = compStore.compBsNo;
    params.compHpNo = compStore.compHpNo;
    params.compFaxNo = compStore.compFaxNo;
    params.representative = compStore.representative;
    params.bsTyp = compStore.bsTyp;
    params.bsNm = compStore.bsNm;
    params.compAdd = compStore.compAdd;
    params.compCd = compStore.compCd;
    params.email = compStore.email;

    if (this.state.upsertMode == 'U') {
      params.compNo = compStore.selectedComp.compNo;
    }

    axios({
      url: this.state.upsertMode == 'I' ? API_INSERT_COMP : API_UPDATE_COMP,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnSelectCompList();
          compStore.initForm();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectCompList = () => {
    const { compStore } = this.props;
    let params = {};
    let that = this;
    params.startCnt =
      compStore.compListCurrentPageNum == 1
        ? 0
        : 50 * (Number(compStore.compListCurrentPageNum) - 1) + 1;

    if (compStore.compListTotalPageNum == 0) {
      params.endCnt = 50;
    } else if (
      compStore.compListTotalPageNum > 0 &&
      compStore.compListTotalCount - 50 * compStore.compListCurrentPageNum >= 0
    ) {
      params.endCnt = 50;
    } else if (
      compStore.compListTotalPageNum > 0 &&
      compStore.compListTotalCount - 50 * compStore.compListCurrentPageNum < 0
    ) {
      params.endCnt =
        compStore.compListTotalCount -
        50 * (compStore.compListCurrentPageNum - 1);
    }

    params.compCd = 'C';

    axios({
      url: API_SELECT_COMP_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          let pageNum = Math.ceil(response.data.totalPageNum / 50);
          compStore.setCompList(response.data.data);
          compStore.setCompListTotalCount(response.data.totalPageNum);
          compStore.setCompListTotalPageNum(pageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnDirectListMove = pageNum => {
    const { compStore } = this.props;
    compStore.initCompList();
    compStore.setCompListCurrentPageNum(pageNum);
    this.fnSelectCompList();
  };

  render() {
    const { compStore } = this.props;
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          background: COLOR_GRAY_GR02,
          overflowY: 'scroll',
        }}
      >
        <div style={{ width: '90%', margin: '0 auto' }}>
          <div
            style={{
              width: '49%',
              height: 'calc(100vh - 180px)',
              background: COLOR_WHITE,
              borderRadius: '10px',
              float: 'left',
              marginRight: '2%',
              marginTop: this.state.upsertView == true ? '0px' : '10px',
            }}
          >
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                marginTop: '10px',
                paddingRight: '10px',
              }}
            >
              <Button color="blue" onClick={this.fnInsertViewOpen}>
                거래처등록
              </Button>
              <div
                style={{ height: 'calc(100vh - 240px)', overflowY: 'scroll' }}
              >
                <CompList
                  compListCurrentPageNum={compStore.compListCurrentPageNum}
                  compListTotalPageNum={compStore.compListTotalPageNum}
                  fnDirectListMove={this.fnDirectListMove}
                  fnUpdateViewOpen={this.fnUpdateViewOpen}
                />
              </div>
            </div>
          </div>

          {this.state.upsertView == true ? (
            <div
              style={{
                width: '49%',
                height: 'calc(100vh - 180px)',
                background: COLOR_WHITE,
                borderRadius: '10px',
                overflow: 'hidden',
                marginLeft: '10%',
                marginTop: '10px',
              }}
            >
              <CompUpsertContainer
                handleChangeCompCd={this.handleChangeCompCd}
                fnUpsertComp={this.fnUpsertComp}
              />
            </div>
          ) : null}

          <div style={{ width: '100%', height: '50px' }} />
        </div>
      </div>
    );
  }
}

export default CompManagement;
