import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../../../common/Color';

@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class CurrentList extends Component {
  render() {
    const { accStore } = this.props;
    return (
      <Table style={{ border: '0px' }}>
        <Table.Header style={{ border: '0px' }}>
          <Table.Row>
            <Table.HeaderCell style={{ background: '#fff' }}>
              회원 번호
            </Table.HeaderCell>
            <Table.HeaderCell style={{ background: '#fff' }}>
              담당자 구분
            </Table.HeaderCell>
            <Table.HeaderCell style={{ background: '#fff' }}>
              담당자 이름
            </Table.HeaderCell>
            <Table.HeaderCell style={{ background: '#fff' }}>
              담당자 연락처
            </Table.HeaderCell>
            <Table.HeaderCell style={{ background: '#fff' }}>
              사용여부
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {accStore.staffList == null || accStore.staffList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                회원이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {accStore.staffList.map((data, i) => (
              <React.Fragment key={data.customerNo}>
                <Table.Row
                  onClick={() => {
                    this.props.fnViewOpen(data, i);
                  }}
                  style={{
                    background:
                      accStore.selectedStaff != null &&
                      accStore.selectedStaff.customerNo == data.customerNo
                        ? COLOR_GREEN_PRIMARY
                        : null,
                    color:
                      accStore.selectedStaff != null &&
                      accStore.selectedStaff.customerNo == data.customerNo
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                  }}
                >
                  <Table.Cell>{data.customerNo}</Table.Cell>
                  <Table.Cell>{data.staffCdNm}</Table.Cell>
                  <Table.Cell>{data.customerNm}</Table.Cell>
                  <Table.Cell>{data.hpNo}</Table.Cell>
                  <Table.Cell>{data.useYn}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default CurrentList;
