/*global XLSX*/
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { COLOR_GRAY_GR02, COLOR_WHITE } from '../../../common/Color';
import { Button } from 'semantic-ui-react';
//import CompUpsertContainer from './CompUpsertContainer';
import {
  API_INSERT_COMP,
  API_SELECT_COMP_LIST,
  API_UPDATE_COMP,
} from '../../../common/ApiAddress/CompAPI';
import StatementCompList from './StatementCompList';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import CompInfo from './CompInfo';
import moment from 'moment/moment';
import { API_SELECT_WORKBENCH_FREIGHT_STATEMENT_LIST } from '../../../common/ApiAddress/WorkBenchFreightAPI';
import FreightStatementList from './FreightStatementList';
import StatementHeader from './StatementHeader';
import StatementTitle from './StatementTitle';
import StatementAmount from './StatementAmount';
import FreightStatementListHeader from './FreightStatementListHeader';
import StatementSum from './StatementSum';
let initBodyHtml;
@inject(stores => ({
  compStore: stores.CompStore,
  wborderStore: stores.WorkbenchOrderStore,
}))
@observer
class StatementContainer extends Component {
  state = {
    preView: false,
    selectedMonth: null,
  };

  componentDidMount() {
    const { compStore, wborderStore } = this.props;
    compStore.initSelectedComp();
    this.fnSelectCompList();
  }

  fnPreViewOpen = (value, i) => {
    const { compStore, wborderStore } = this.props;
    // if (
    //   compStore.selectedComp != null &&
    //   wborderStore.freightStatementList.length > 0
    // ) {
    //   document.getElementById('sumKo').innerHTML = '';
    // }
    compStore.setSelectedComp(value);
    compStore.setCompNm(value.compNm);
    compStore.setCompBsNo(value.compBsNo);
    compStore.setCompHpNo(value.compHpNo);
    compStore.setCompFaxNo(value.compFaxNo);
    compStore.setRepresentative(value.representative);
    compStore.setBsTyp(value.bsTyp);
    compStore.setBsNm(value.bsNm);
    compStore.setCompAdd(value.compAdd);
    compStore.setCompCd(value.compCd);
    compStore.setEmail(value.email);
    this.setState({
      preView: true,
      selectedMonth: null,
    });
    wborderStore.initFreightStatementList();
    wborderStore.initTotalFeeSum();
  };

  fnSelectCompList = () => {
    const { compStore } = this.props;
    let params = {};
    let that = this;
    params.startCnt =
      compStore.compListCurrentPageNum == 1
        ? 0
        : 50 * (Number(compStore.compListCurrentPageNum) - 1) + 1;

    if (compStore.compListTotalPageNum == 0) {
      params.endCnt = 50;
    } else if (
      compStore.compListTotalPageNum > 0 &&
      compStore.compListTotalCount - 50 * compStore.compListCurrentPageNum >= 0
    ) {
      params.endCnt = 50;
    } else if (
      compStore.compListTotalPageNum > 0 &&
      compStore.compListTotalCount - 50 * compStore.compListCurrentPageNum < 0
    ) {
      params.endCnt =
        compStore.compListTotalCount -
        50 * (compStore.compListCurrentPageNum - 1);
    }

    params.compCd = 'C';

    axios({
      url: API_SELECT_COMP_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          let pageNum = Math.ceil(response.data.totalPageNum / 50);
          compStore.setCompList(response.data.data);
          compStore.setCompListTotalCount(response.data.totalPageNum);
          compStore.setCompListTotalPageNum(pageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnDirectListMove = pageNum => {
    const { compStore } = this.props;
    compStore.initCompList();
    compStore.setCompListCurrentPageNum(pageNum);
    this.fnSelectCompList();
  };

  fnTest = () => {
    window.print();
  };

  //날짜 선택
  fnSelectMonth = value => {
    const { compStore, wborderStore } = this.props;
    this.setState({
      selectedMonth: value,
    });
    wborderStore.initFreightStatementList();
    wborderStore.initTotalFeeSum();
    if (
      compStore.selectedComp != null &&
      wborderStore.freightStatementList.length > 0
    ) {
      document.getElementById('sumKo').innerHTML = '';
    }
    this.fnSelectFreightStatementList(moment(value).format('YYYY-MM'));
  };

  //날짜 선택에 따른 데이터 불러오기
  fnSelectFreightStatementList = value => {
    const { compStore, wborderStore } = this.props;
    let params = {};
    let that = this;

    params.compNo = compStore.selectedComp.compNo;
    params.selectedMonth = value;
    axios({
      url: API_SELECT_WORKBENCH_FREIGHT_STATEMENT_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          wborderStore.setFreightStatementList(response.data.data);
          wborderStore.setTotalFeeSum(response.data.totalFeeSum);

          console.log(wborderStore.freightStatementList);
        } else {
          wborderStore.initFreightStatementList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { compStore, wborderStore } = this.props;
    registerLocale('ko', ko);
    window.onbeforeprint = () => {
      initBodyHtml = document.body.innerHTML;
      document.body.innerHTML =
        document.getElementById('tableWrapper').innerHTML;
    };
    window.onafterprint = () => {
      window.location.reload();
    };

    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          background: COLOR_GRAY_GR02,
          overflowY: 'scroll',
        }}
      >
        <div style={{ width: '95%', margin: '0 auto' }}>
          <div
            style={{
              width: '25%',
              height: 'calc(100vh - 110px)',
              background: COLOR_WHITE,
              borderRadius: '10px',
              float: 'left',
              marginRight: '2%',
            }}
          >
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                marginTop: '10px',
                paddingRight: '10px',
              }}
            >
              <div style={{ width: '100%', height: '50px' }}>
                <DatePicker
                  locale="ko"
                  showMonthYearPicker
                  selected={this.state.selectedMonth}
                  onChange={date => {
                    this.fnSelectMonth(date);
                  }}
                  dateFormat="yyyy-MM"
                />
              </div>
              <div
                style={{ height: 'calc(100vh - 180px)', overflowY: 'scroll' }}
              >
                <StatementCompList
                  compListCurrentPageNum={compStore.compListCurrentPageNum}
                  compListTotalPageNum={compStore.compListTotalPageNum}
                  fnDirectListMove={this.fnDirectListMove}
                  fnPreViewOpen={this.fnPreViewOpen}
                />
              </div>
            </div>
          </div>

          {compStore.selectedComp != null ? (
            <div
              style={{
                width: '55%',
                height: 'calc(100vh - 110px)',
                background: COLOR_WHITE,
                borderRadius: '10px',
                overflow: 'hidden',
                marginLeft: '10%',
                marginTop: '10px',
                padding: '10px',
                overflowY: 'scroll',
              }}
            >
              <div
                id="tableWrapper"
                style={{
                  width: '100%',
                  marginLeft: '10%',
                }}
              >
                {wborderStore.freightStatementList.length == 0 ? (
                  <table cellSpacing={0}>
                    <tbody>
                      <StatementHeader height={'26px'} />
                      <StatementTitle />
                      <CompInfo />
                      <StatementAmount />
                      <FreightStatementList />
                    </tbody>
                  </table>
                ) : wborderStore.freightStatementList.length == 1 ? (
                  <table cellSpacing={0}>
                    <tbody>
                      <StatementHeader height={'26px'} />
                      <StatementTitle />
                      <CompInfo />
                      <StatementAmount />
                      <FreightStatementListHeader />
                      <FreightStatementList index={0} />
                      <StatementSum />
                    </tbody>
                  </table>
                ) : wborderStore.freightStatementList.length == 2 ? (
                  <Fragment>
                    <table cellSpacing={0}>
                      <tbody>
                        <StatementHeader height={'26px'} />
                        <StatementTitle />
                        <CompInfo />
                        <StatementAmount />
                        <FreightStatementListHeader />
                        <FreightStatementList index={0} />
                      </tbody>
                    </table>
                    <div style={{ width: '100%', height: '40px' }} />
                    <table cellSpacing={0}>
                      <tbody>
                        <StatementHeader height={'0px'} />
                        <FreightStatementList index={1} />
                        <StatementSum />
                      </tbody>
                    </table>
                  </Fragment>
                ) : wborderStore.freightStatementList.length == 3 ? (
                  <Fragment>
                    <table cellSpacing={0}>
                      <tbody>
                        <StatementHeader height={'26px'} />
                        <StatementTitle />
                        <CompInfo />
                        <StatementAmount />
                        <FreightStatementListHeader />
                        <FreightStatementList index={0} />
                      </tbody>
                    </table>
                    <div style={{ width: '100%', height: '40px' }} />
                    <table cellSpacing={0}>
                      <tbody>
                        <StatementHeader height={'0px'} />
                        <FreightStatementList index={1} />
                      </tbody>
                    </table>
                    <div style={{ width: '100%', height: '40px' }} />
                    <table cellSpacing={0}>
                      <tbody>
                        <StatementHeader height={'0px'} />
                        <FreightStatementList index={2} />
                        <StatementSum />
                      </tbody>
                    </table>
                  </Fragment>
                ) : null}

                {/* {wborderStore.totalPrintPage == 2 ? (
                      <Fragment>
                        
                        <table cellSpacing={0}>
                          <tbody>
                            <StatementHeader height={'0px'} />
                            <FreightStatementList index={2} />
                            <StatementSum />
                          </tbody>
                        </table>
                      </Fragment>
                    ) : wborderStore.totalPrintPage == 3 ? (
                      <Fragment>
                        <div style={{ width: '100%', height: '40px' }} />
                        <table cellSpacing={0}>
                          <tbody>
                            <StatementHeader height={'0px'} />
                            <FreightStatementList index={2} />
                          </tbody>
                        </table>
                        <div style={{ width: '100%', height: '40px' }} />
                        <table cellSpacing={0}>
                          <tbody>
                            <StatementHeader height={'0px'} />
                            <FreightStatementList index={3} />
                            <StatementSum />
                          </tbody>
                        </table>
                      </Fragment>
                    ) : null} */}
              </div>
              <Button onClick={() => this.fnTest()}>Export to CSV</Button>
            </div>
          ) : null}

          <div style={{ width: '100%', height: '50px' }} />
        </div>
      </div>
    );
  }
}

export default StatementContainer;
