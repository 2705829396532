import { Component, Fragment } from 'react';
import { Select, Form, Input } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';

import {
  API_SELECT_DRIVER_LIST,
  API_SELECT_DRIVER_SEARCH_LIST_WITH_KEYWORD,
} from '../../../../common/ApiAddress/AccountAPI';
import CurrentList from '../../../../Component/Management/User/Driver/Current/CurrentList';

@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class CurrentDriverList extends Component {
  componentDidMount() {
    this.fnSelectDriverList();
  }

  //트럭커 리스트 불러오기
  fnSelectDriverList = () => {
    const { accStore } = this.props;
    let params = {};
    params.startCnt = 0;
    params.endCnt = 20;
    axios({
      url: API_SELECT_DRIVER_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100 && response.data.data != null) {
          accStore.setDriverList(response.data.data);
        } else if (response.data.resultCode == 200) {
          accStore.initDriverList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //트럭커 검색 리스트 불러오기
  fnSelectDriverListWithKeyword = searchKeyword => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    params.searchKeyword = searchKeyword;
    params.searchTyp = this.props.searchTyp;
    params.startCnt = 0;
    params.endCnt = 30;
    axios({
      url: API_SELECT_DRIVER_SEARCH_LIST_WITH_KEYWORD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100 && response.data.data != null) {
          accStore.setDriverList(response.data.data);
        } else if (
          response.data.resultCode == 200 ||
          response.data.data == null
        ) {
          accStore.initDriverList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const searchTypList = [
      { text: '이름', value: 'N' },
      { text: '아이디', value: 'A' },
    ];
    return (
      <Fragment>
        <div>
          <Form style={{ width: '100%', marginTop: '10px' }}>
            <Form.Field style={{ width: '100%' }}>
              <Select
                style={{
                  minWidth: '122px',
                  maxWidth: '122px',
                  marginRight: '10px',
                  float: 'left',
                }}
                placeholder={'검색조건 선택'}
                options={searchTypList}
                onChange={(e, { value }) =>
                  this.props.fnOnchangeSearchTyp(value)
                }
              />
              <Fragment>
                <Input
                  icon="search"
                  placeholder="검색어 입력"
                  style={{ width: '150px' }}
                  onKeyPress={e => {
                    if (e.charCode == 13) {
                      this.fnSelectDriverListWithKeyword(e.target.value);
                    }
                  }}
                />
                <span style={{ marginLeft: '10px', color: '#cfcfcf' }}>
                  엔터키 입력시 검색됩니다.
                </span>
              </Fragment>
            </Form.Field>
          </Form>
        </div>
        <CurrentList fnViewOpen={this.props.fnViewOpen} />
      </Fragment>
    );
  }
}

export default CurrentDriverList;
