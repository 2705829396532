import { apiAddress } from '../ApiPlatform';

export const API_SELECT_MESSAGE_TEMPLATE_LIST =
  apiAddress + 'msg/selectMsgTemplateList';
export const API_INSERT_MESSAGE_TEMPLATE = apiAddress + 'msg/insertMsgTemplate';
export const API_UPDATE_MESSAGE_TEMPLATE =
  apiAddress + '/msg/updateMsgTemplate';

export const API_SELECT_MESSAGE_TEMPLATE = apiAddress + 'msg/selectMsgTemplate';

export const API_INSERT_MESSAGE = apiAddress + 'msg/insertMsg';
export const API_SELECT_MSG_LIST = apiAddress + 'msg/selectMsgList';
