import { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import AWS, { S3 } from 'aws-sdk';
import {
  API_SELECT_APV_LIST_DRIVER,
  API_UPDATE_INFO_WITH_APPROVED_DRIVER,
  API_SELECT_NTS_BUSINESSMAN,
} from '../../../../common/ApiAddress/AccountAPI';

import APVList from '../../../../Component/Management/User/Driver/APV/APVList';
import APVDetail from '../../../../Component/Management/User/Driver/APV/APVDetail';
import CompRegAuthModal from '../../../../Component/Management/User/Driver/APV/CompRegAuthModal';
@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class DriverAPV extends Component {
  state = {
    AuthModal: false,
    isAuth: false,
    authComplete: false,
    presentativeNm: null,
    startDt: null,
    compNm: null,
  };

  componentDidMount() {
    this.fnSelectAPVList();
  }

  //승인 심사 대기 리스트 불러오기
  fnSelectAPVList = () => {
    const { accStore } = this.props;
    let params = {};
    axios({
      url: API_SELECT_APV_LIST_DRIVER,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100 && response.data.data != null) {
          console.log(response.data.data);
          accStore.setApvList(response.data.data);
        } else if (response.data.resultCode == 200) {
          accStore.initApvList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectDriver = value => {
    const { accStore, vcStore } = this.props;
    //input값 세팅
    accStore.initForm();
    vcStore.initForm();

    accStore.setSelectedDriver(value);
    vcStore.setVcWidth(value.vcWidth);
    vcStore.setVcHeight(value.vcHeight);
    vcStore.setVcLength(value.vcLength);
    accStore.setCompRegNo(value.compRegNo);
    accStore.setVcRegNo(value.vcRegNo);
    accStore.setLogisCertiNo(value.logisCertiNo);

    const BUCKET_NAME = 'teamtrucker';
    let Key = value.compRegFilePath.replace(
      process.env.REACT_APP_AWS_S3_ADDRESS,
      '',
    );
    console.log(value);
    const client = new S3({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    });
    let that = this;
    client.getObject(
      {
        Bucket: BUCKET_NAME,
        Key: Key,
      },
      function (s3Err, data) {
        if (s3Err) throw s3Err;
        console.log(data);
        window.document.getElementById('compRegImg').src = URL.createObjectURL(
          new Blob([data.Body], { type: 'image/png' }),
        );
      },
    );

    Key = value.vcRegFilePath.replace(
      'https://teamtrucker.s3.ap-northeast-2.amazonaws.com/',
      '',
    );

    client.getObject(
      {
        Bucket: BUCKET_NAME,
        Key: Key,
      },
      function (s3Err, data) {
        if (s3Err) throw s3Err;
        console.log(data);
        window.document.getElementById('vcRegImg').src = URL.createObjectURL(
          new Blob([data.Body], { type: 'image/png' }),
        );
      },
    );

    Key = value.logisCertiFilePath.replace(
      'https://teamtrucker.s3.ap-northeast-2.amazonaws.com/',
      '',
    );

    client.getObject(
      {
        Bucket: BUCKET_NAME,
        Key: Key,
      },
      function (s3Err, data) {
        if (s3Err) throw s3Err;
        console.log(data);
        window.document.getElementById('logisCertiImg').src =
          URL.createObjectURL(new Blob([data.Body], { type: 'image/png' }));
      },
    );
  };

  //승인 하기 및 정보 업데이트
  fnUpdateInfoWithApproved = () => {
    const { accStore, vcStore } = this.props;
    let params = {};
    let that = this;

    if (accStore.vcRegNo == null || accStore.vcRegNo == '') {
      alert('차량등록번호를 기입해주세요.');
      return false;
    }
    if (accStore.logisCertiNo == null || accStore.logisCertiNo == '') {
      alert('화물운송 자격번호를 기입해주세요.');
      return false;
    }

    params.driverId = accStore.selectedDriver.driverId;
    params.driverNo = accStore.selectedDriver.driverNo;
    params.compRegNo = accStore.compRegNo;
    params.vcRegNo = accStore.vcRegNo;
    params.logisCertiNo = accStore.logisCertiNo;
    params.vcNo = accStore.selectedDriver.vcNo;
    params.vcWidth = vcStore.vcWidth;
    params.vcLength = vcStore.vcLength;
    params.vcHeight = vcStore.vcHeight;
    params.empId = sessionStorage.getItem('empId');
    axios({
      url: API_UPDATE_INFO_WITH_APPROVED_DRIVER,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          //accStore.initApvList(); // 리스트 초기화
          that.fnSelectAPVList(); // 리스트 다시 불러오기
          accStore.initSelectedDriver(); // 선택한 기사 초기화
          accStore.initForm(); //입력폼 초기화
          vcStore.initForm(); //입력폼 초기화
          that.setState({
            isAuth: false,
            authComplete: false,
            presentativeNm: null,
            startDt: null,
            compNm: null,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnCompRegAuth = () => {
    const { accStore } = this.props;
    let params = {};
    let that = this;

    let businesses = [];
    let item = {};
    item.b_no = accStore.compRegNo;
    item.start_dt = this.state.startDt;
    item.p_nm = this.state.presentativeNm;
    businesses.push(item);
    params.businesses = businesses;
    axios({
      url: API_SELECT_NTS_BUSINESSMAN,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        console.log(response);
        //7718602308
        if (response.status == 200) {
          console.log(response.data.data[0]);
          console.log(response.data.data[0].valid);
          if (response.data.data[0].valid == '01') {
            that.setState({
              isAuth: true,
              authComplete: true,
            });
          } else {
            that.setState({
              isAuth: false,
              authComplete: true,
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnAuthModalOpen = () => {
    this.setState({
      AuthModal: true,
    });
  };

  //닫기 버튼
  fnAuthModalClose = () => {
    const { accStore } = this.props;
    accStore.initCompRegNo();
    this.setState({
      AuthModal: false,
      isAuth: false,
      authComplete: false,
      presentativeNm: null,
      startDt: null,
      compNm: null,
    });
  };

  // 인증 성공 후 닫기
  fnAuthModalCloseForSeccess = () => {
    this.setState({
      AuthModal: false,
    });
  };

  fnSetPresentativeNm = value => {
    this.setState({
      presentativeNm: value,
    });
  };

  fnSetStartDt = value => {
    this.setState({
      startDt: value,
    });
  };

  fnSetCompNm = value => {
    this.setState({
      compNm: value,
    });
  };

  render() {
    const { accStore } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '30%',
            height: 'calc(100vh - 40px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <APVList fnSelectDriver={this.fnSelectDriver} />
        </div>
        <div
          style={{
            width: '70%',
            height: 'calc(100vh - 40px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {accStore.selectedDriver != null ? (
            <APVDetail
              isAuth={this.state.isAuth}
              fnAuthModalOpen={this.fnAuthModalOpen}
              fnUpdateInfoWithApproved={this.fnUpdateInfoWithApproved}
            />
          ) : null}
        </div>
        {this.state.AuthModal == true ? (
          <CompRegAuthModal
            isAuth={this.state.isAuth}
            authComplete={this.state.authComplete}
            AuthModal={this.state.AuthModal}
            presentativeNm={this.state.presentativeNm}
            startDt={this.state.startDt}
            compNm={this.state.compNm}
            fnSetPresentativeNm={this.fnSetPresentativeNm}
            fnSetStartDt={this.fnSetStartDt}
            fnSetCompNm={this.fnSetCompNm}
            fnAuthModalClose={this.fnAuthModalClose}
            fnCompRegAuth={this.fnCompRegAuth}
            fnAuthModalCloseForSeccess={this.fnAuthModalCloseForSeccess}
          />
        ) : null}
      </div>
    );
  }
}

export default DriverAPV;
