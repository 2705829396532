import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import StaffList from './StaffList';
import StaffUpsertContainer from './StaffUpsertContainer';
import {
  API_SELECT_STAFF_LIST,
  API_INSERT_STAFF,
  API_UPDATE_STAFF,
  API_DELETE_STAFF,
} from '../../../../common/ApiAddress/AccountAPI';
import { API_SELECT_COMCD } from '../../../../common/ApiAddress/CommonAPI';
import CommonAlert from '../../../../Component/Common/CommonAlert';
import CorfirmModalForInsert from '../../../../Component/Common/ConfirmModalForInsert';
import ConfirmModalForDelete from '../../../../Component/Common/ConfirmModalForDelete';
import ConfirmModalForUpdate from '../../../../Component/Common/ConfirmModalForUpdate';
import { toJS } from 'mobx';
@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class StaffManagementContainer extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
    eamilCdInput: false,
    selectedStaffIndex: 0,
  };

  componentDidMount() {
    this.fnSelectStaffList();
  }

  //스태프 리스트
  fnSelectStaffList = () => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    params.groupNo = Number(sessionStorage.getItem('customerNo'));
    axios({
      url: API_SELECT_STAFF_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          accStore.setStaffList(response.data.data);
          that.fnSelectEmailCd();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnInsertViewOpen = () => {
    this.setState({
      upsertView: true,
      upsertMode: 'I',
      selectedStaffIndex: 0,
    });
  };

  fnViewOpen = (value, i) => {
    const { accStore } = this.props;

    console.log(value);
    console.log(i);

    accStore.setSelectedStaff(value);
    accStore.setStaffNm(value.customerNm);
    accStore.setStaffHpNo(value.hpNo);
    accStore.setStaffId(value.customerId);
    accStore.initStaffPw();

    if (value.email != undefined) {
      let email = value.email;
      let emailSplit = email.split('@');
      accStore.setEmail(emailSplit[0]);
      accStore.setEmailCd(emailSplit[1]);
    } else {
      accStore.initEmail();
      accStore.initEmailCd();
    }

    accStore.setCustomerCd(value.customerCd);
    this.setState({
      upsertView: true,
      upsertMode: 'U',
      selectedStaffIndex: i,
    });
  };

  fnInitForm = () => {
    const { accStore } = this.props;
    accStore.initSelectedStaff();
    accStore.initStaffNm();
    accStore.initStaffHpNo();
    accStore.initStaffPw();
    accStore.initCustomerId();
    accStore.initStaffId();
    accStore.initEmail();
    accStore.initEmailCd();
    accStore.initCustomerCd();
  };

  fnUpsertStaff = () => {
    const { accStore, commonStore } = this.props;
    let params = {};

    if (accStore.staffId == null || accStore.staffId == '') {
      commonStore.commonAlertOpen('담당자 아이디를 입력해주세요.');
      this.fnStaffInsertModalClose();
      this.fnUpdateStaffModalClose();
      return false;
    }

    if (accStore.staffPw == null || accStore.staffPw == '') {
      commonStore.commonAlertOpen('비밀번호를 입력해주세요.');
      this.fnStaffInsertModalClose();
      this.fnUpdateStaffModalClose();
      return false;
    }

    if (accStore.staffNm == null || accStore.staffNm == '') {
      commonStore.commonAlertOpen('담당자 이름을 입력해주세요.');
      this.fnStaffInsertModalClose();
      this.fnUpdateStaffModalClose();
      return false;
    }

    if (accStore.staffHpNo == null || accStore.staffHpNo == '') {
      commonStore.commonAlertOpen('담당자 연락처 입력해주세요.');
      this.fnStaffInsertModalClose();
      this.fnUpdateStaffModalClose();
      return false;
    }

    params.groupNo = Number(sessionStorage.getItem('customerNo'));
    params.customerCd = 'IACCU004';
    //params.customerCd = accStore.customerCd;

    params.customerId = accStore.staffId;
    params.pw = accStore.staffPw;
    params.customerNm = accStore.staffNm;
    params.hpNo = accStore.staffHpNo;

    if (accStore.email == null || accStore.email == '') {
      params.email = null;
    } else {
      params.email = accStore.email + '@' + accStore.emailCd;
    }

    if (this.state.upsertMode == 'U') {
      params.customerNo = accStore.selectedStaff.customerNo;
    }

    let that = this;
    axios({
      url: this.state.upsertMode == 'I' ? API_INSERT_STAFF : API_UPDATE_STAFF,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnStaffInsertModalClose();
          that.fnUpdateStaffModalClose();
          if (that.state.upsertMode == 'I') {
            that.setState({
              selectedStaffIndex: accStore.staffList.length,
            });
          }

          that.fnSelectStaffList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOnchangeCustomerCd = value => {
    const { accStore } = this.props;
    accStore.setCustomerCd(value);
  };

  handleChangeCustomerCd = (e, { value }) => {
    this.fnOnchangeCustomerCd(value);
  };

  //이메일구분 찾기(시스템코드)
  fnSelectEmailCd = () => {
    const { commonStore, accStore } = this.props;
    let params = {};
    let that = this;
    params.sysCd = 'IACEM';

    axios({
      url: API_SELECT_COMCD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let emailcdList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let emailCd = {};
              emailCd.key = response.data.data[i].sysCd;
              emailCd.value = response.data.data[i].sysCdNm;
              emailCd.text = response.data.data[i].sysCdNm;
              emailCd.sysCdIdx = response.data.data[i].sysCdIdx;
              emailcdList.push(emailCd);
            }
            commonStore.setEmailCdList(emailcdList);
            let staffList = toJS(accStore.staffList);
            that.fnViewOpen(
              staffList[that.state.selectedStaffIndex],
              that.state.selectedStaffIndex,
            );
            // if (that.state.upsertMode == 'I') {
            //   that.fnViewOpen(
            //     staffList[that.state.selectedStaffIndex],
            //     that.state.selectedStaffIndex,
            //   );
            // } else if (that.state.upsertMode == 'U') {
            //   that.fnViewOpen(
            //     staffList[that.state.selectedStaffIndex - 2],
            //     that.state.selectedStaffIndex,
            //   );
            // }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetEmailCd = (e, data) => {
    const { accStore } = this.props;

    if (data.value == '직접입력') {
      accStore.initEmailCd();
      this.setState({
        eamilCdInput: false,
      });
    } else {
      accStore.setEmailCd(data.value);
      this.setState({
        eamilCdInput: true,
      });
    }
  };

  fnDeleteStaff = () => {
    const { accStore, commonStore } = this.props;
    let params = {};
    let that = this;
    if (accStore.selectedStaff.customerCd == 'IACCU003') {
      commonStore.commonAlertOpen('대표계정을 삭제 할 수 없습니다.');
      return false;
    } else {
      params.updateId = sessionStorage.getItem('customerId');
      params.customerId = accStore.selectedStaff.customerId;
    }
    axios({
      url: API_DELETE_STAFF,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnSelectStaffList();
          that.setState({
            selectedStaffIndex: 0,
          });
          that.fnViewOpen(accStore.staffList[0], 0);
          that.fnDeleteStaffModalClose();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //담당자 등록 모달 열기
  fnStaffInsertModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForInsertOpen('담당자를 추가 하시겠습니까?');
  };

  //담당자 등록 모달 닫기
  fnStaffInsertModalClose = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForInsertClose();
  };

  //담당자 삭제 모달 열기
  fnDeleteStaffModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForDeleteOpen('선택된 담당자를 삭제 하시겠습니까?');
  };

  //담당자 삭제 모달 닫기
  fnDeleteStaffModalClose = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForDeleteClose();
  };

  //담당자 수정 모달 열기
  fnUpdateStaffModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForUpdateOpen('변경된 내용을 저장 하시겠습니까?');
  };

  //담당자 수정 모달 닫기
  fnUpdateStaffModalClose = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForUpdateClose();
  };

  render() {
    return (
      <React.Fragment>
        <StaffList
          fnViewOpen={this.fnViewOpen}
          fnInitForm={this.fnInitForm}
          fnInsertViewOpen={this.fnInsertViewOpen}
        />

        {this.state.upsertView == true ? (
          <StaffUpsertContainer
            handleChangeCustomerCd={this.handleChangeCustomerCd}
            fnSetEmailCd={this.fnSetEmailCd}
            eamilCdInput={this.state.eamilCdInput}
            fnDeleteStaff={this.fnDeleteStaff}
            fnStaffInsertModalOpen={this.fnStaffInsertModalOpen}
            fnDeleteStaffModalOpen={this.fnDeleteStaffModalOpen}
            fnUpdateStaffModalOpen={this.fnUpdateStaffModalOpen}
          />
        ) : null}
        <CommonAlert />
        <CorfirmModalForInsert
          fnConfirmNo={this.fnStaffInsertModalClose}
          fnConfirmYes={this.fnUpsertStaff}
        />
        <ConfirmModalForDelete
          fnConfirmNo={this.fnDeleteStaffModalClose}
          fnConfirmYes={this.fnDeleteStaff}
        />

        <ConfirmModalForUpdate
          fnConfirmNo={this.fnUpdateStaffModalClose}
          fnConfirmYes={this.fnUpsertStaff}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(StaffManagementContainer);
