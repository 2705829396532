import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import AlarmTalkTemplateList from '../../../../Component/Management/AlarmTalk/AlarmTalkTemplate/AlarmTalkTemplateList';
import {
  API_SELECT_ALARMTALK_TEMPLATE_LIST,
  API_INSERT_ALARMTALK_TEMPLATE,
  API_UPDATE_ALARMTALK_TEMPLATE,
} from '../../../../common/ApiAddress/AlarmTalkAPI';
import AlarmTalkTemplateUpsertContainer from './AlarmTalkTemplateUpsertContainer';
import { isEmp } from '../../../../Util/CommonUtil';
@inject(stores => ({
  armStore: stores.AlarmTalkStore,
}))
@observer
class AlarmTalkTamplateManagementContainer extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
  };

  componentDidMount() {
    this.fnSelectTemplateiList();
  }

  //알림톡 템플릿 리스트 불러오기
  fnSelectTemplateiList = () => {
    const { armStore } = this.props;
    let params = {};
    let that = this;

    axios({
      url: API_SELECT_ALARMTALK_TEMPLATE_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          armStore.setAlarmTalkTemplateList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnViewOpen = value => {
    const { armStore } = this.props;
    armStore.setSelectedTemplate(value);
    armStore.setTmpTitle(value.tmpTitle);
    armStore.setTmpContent(value.tmpContent);
    armStore.setMergeKey(value.mergeKeys);
    if (value.useYn == 'Y') {
      armStore.setUseYnTrue();
    } else {
      armStore.setUseYnFalse();
    }
    this.setState({
      upsertView: true,
      upsertMode: 'U',
    });
  };

  //알림톡 템플릿 저장
  fnUpsertAlarmTalkTemplate = () => {
    const { armStore } = this.props;
    let params = {};
    params.tmpTitle = armStore.tmpTitle;
    params.tmpContent = armStore.tmpContent;
    params.mergeKeys = armStore.mergeKey;
    params.registId = sessionStorage.getItem('empId');

    if (this.state.upsertMode == 'U') {
      params.tmpCode = armStore.selectedTemplate.tmpCode;
      if (armStore.useYn == true) {
        params.useYn = 'Y';
      } else {
        params.useYn = 'N';
      }
    }

    console.log(params);

    let that = this;
    axios({
      url:
        this.state.upsertMode == 'I'
          ? API_INSERT_ALARMTALK_TEMPLATE
          : API_UPDATE_ALARMTALK_TEMPLATE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          armStore.initForm();
          that.fnSelectTemplateiList();
          that.setState({
            upsertView: false,
            upsertMode: 'I',
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { armStore } = this.props;
    return isEmp() ? (
      <React.Fragment>
        <div
          style={{
            width: '30%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          <Button
            positive
            onClick={() => {
              armStore.initForm();
              this.setState({
                upsertView: true,
                upsertMode: 'I',
              });
            }}
            style={{ float: 'right' }}
          >
            새 템플릿 작성
          </Button>
          <AlarmTalkTemplateList fnViewOpen={this.fnViewOpen} />
        </div>
        <div
          style={{
            width: '70%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {this.state.upsertView == true ? (
            <AlarmTalkTemplateUpsertContainer
              fnUpsertAlarmTalkTemplate={this.fnUpsertAlarmTalkTemplate}
            />
          ) : null}
        </div>
      </React.Fragment>
    ) : null;
  }
}

export default withRouter(AlarmTalkTamplateManagementContainer);
