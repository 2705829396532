import { Component } from 'react';
import { Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  mgsStore: stores.MessageStore,
}))
@observer
class MessageDetailContainer extends Component {
  render() {
    const { mgsStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {mgsStore.selectedMsg == null ? null : (
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>발송대상(아이디)</Table.Cell>
                <Table.Cell>{mgsStore.selectedMsg.rcvId}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>연락처</Table.Cell>
                <Table.Cell>{mgsStore.selectedMsg.rcvHpNo}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>발송성공여부</Table.Cell>
                <Table.Cell>
                  {mgsStore.selectedMsg.result == '0' ? 'Y' : 'N'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>템플릿코드</Table.Cell>
                <Table.Cell>{mgsStore.selectedMsg.groupKey}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>내용</Table.Cell>
                <Table.Cell>{mgsStore.selectedMsg.content}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default MessageDetailContainer;
