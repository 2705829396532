import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from 'react-router';
const someHTML = `<!DOCTYPE html>
<html lang="ko">

<head>
	<meta charset="UTF-8" />
	<meta name="viewport" content="width=device-width" />
	<title>개인정보 처리방침</title>
</head>

<body style="padding:20px; color: rgb(0,51,102);">
	<h2><span>개인정보 처리방침</span></h2>
	<p style="text-align: left;"> <span>(주)Recreate(이하“회사”라 함)는 이용자의 개인정보를 중요시하며, 개인정보의 보호와 관련하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등
                관련법 상의 개인정보 보호 규정을 준수하며, 관련 법령에 의거한 개인정보 취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 단, 본 개인정보 취급방침은 정부의 법령 및 지침의
                변경, 또는 보다 나은 서비스의 제공을 위하여 그 내용이 변경될 수 있고, 회사는 개인정보 취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</span
            >
        </p>
        <br />
        <h3><span>1. 개인정보 수집동의 및 수집항목</span>
		</h3>
		<p><span>회사가 원활한 서비스 제공을 위해 수집하는 이용자의 개인정보 항목은 다음과 같습니다.</span>
		</p>
		<ol>
			<li><span>이용자 성명 및 연락처(휴대폰 번호)</span>
			</li>
			<li><span>이메일 및 사업자 등록증</span>
			</li>
			<li><span>팀트럭커 서비스 회원가입 시 회원 아이디 및 비밀번호</span>
			</li>
			<li><span>결제 대금 처리를 위한 대표계좌번호</span>
			</li>
			<li> <span>상기의 항목을 필수 항목으로 하며, 서비스 제공을 위해 필요에 따라 이용자의 동의하에 추가적인 정보들을 요청하고 수집할 수 있습니다.</span>
				<ol class="depth2">
					<li><span>신용카드 관련정보(신용카드발급사, 카드번호, 유효기간)</span>
					</li>
					<li><span>생년월일, 사진</span>
					</li>
					<li><span>주소</span>
					</li>
					<li><span>운전면허번호</span>
					</li>
				</ol>
			</li>
		</ol>
		<br />
		<h3><span>2. 개인정보 수집목적 및 활용방식</span></h3>
		<ol>
			<li> <span>회사는 회사에서 제공하는 서비스의 원활한 제공을 위하여 이용자의 동의 하에 이용자의 개인정보를 수집하고 있습니다. 회사가 수집한 개인정보는 다음의 목적을 위해 활용합니다.</span>
				<ol class="depth2">
					<li><span>관련 해당 서비스 이용 권한</span>
					</li>
					<li><span>이메일 주소 – 고지사항 전달</span>
					</li>
					<li><span>불만 처리 등을 위한 원활한 의사소통 경로의 확보</span>
					</li>
					<li><span>새로운 서비스 및 행사 정보 등의 안내</span>
					</li>
					<li><span>차량의 입차, 출차 서비스 및 현장 확인용</span>
					</li>
					<li><span>원활한 모바일 신용카드 결제</span>
					</li>
				</ol>
			</li>
			<li><span>회사는 위의 목적 이외에는 수집된 개인정보를 사용할 수 없습니다. 다만 추후 개인정보의 사용목적과 용도가 변경될 경우에는 공지합니다. </span>
			</li>
			<li> <span>회사는 어떠한 경우라도 이용자의 개인정보를 사전에 밝힌 목적 이외의 다른 목적으로 사용하지 않으며, 해당 정보를 외부로 유출 시키지 않습니다. 그러나 회사의 서비스 또는 광고 배너
                    등으로 링크되어 있는 타 사이트에서 이용자의 동의를 거쳐 개인정보를 수집하는 행위에 대해서는 본 개인정보 보호정책이 적용되지 않음을 알려드립니다.</span
                >
            </li>
        </ol>
        <br />
        <h3><span>3. 수집한 개인정보 보유 및 이용기간</span>
				</h3>
				<p><span>이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존합니다.</span>
				</p>
				<ol>
					<li> <span>회원탈퇴 시 보존 개인정보 </span>
						<ol class="depth2">
							<li><span>보존항목 : 최초 접속 시 제공한 정보</span>
							</li>
							<li><span>보존근거 : 불량 이용자의 재가입 방지, 부정 이용 방지, 명예훼손 등 권리침해 분쟁 및 수사협조</span>
							</li>
							<li><span>보존기간 : 회원탈퇴 후 3개월</span>
							</li>
						</ol>
					</li>
					<li> <span>이용자가 회사에서 제공하는 서비스를 이용하는 동안은 회사는 이용자의 개인정보를 지속적으로 보유하며 편리한 서비스의 제공을 위한 기초 자료로 이용합니다. 다만 이용자 본인이개인정보를
                    변경하거나 삭제 요청을 하는 경우는 회사에서 정책에 따라서 삭제하여 추후 열람이나 이용이 불가능한 상태로 처리하고 있습니다.</span
                >
            </li>
        </ol>
        <br />
        <h3><span>4. 개인정보의 파기 절차 및 방법</span>
						</h3>
						<ol>
							<li><span>이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기 절차 및 방법은 다음과 같습니다.</span>
							</li>
							<li> <span>파기 절차</span>
								<ol class="depth2">
									<li> <span>이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라(보유 및
                            이용기간 참조) 일정 기간 저장된 후 파기됩니다.</span
                        >
                    </li>
                    <li><span>동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다</span>
									</li>
								</ol>
							</li>
							<li> <span>파기 방법 </span>
								<ol class="depth2">
									<li><span>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</span>
									</li>
									<li><span>전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</span>
									</li>
								</ol>
							</li>
						</ol>
						<br />
						<h3><span>5. 개인정보 제공 및 공유</span></h3>
						<ol>
							<li> <span>개인정보는 원칙적으로 서비스와 무관한 제3자에게 이용자의 동의 없이 제공될 수 없습니다. 단 아래의 경우에는 예외로 합니다.</span>
								<ol class="depth2">
									<li><span>이용자들이 사전에 동의한 경우</span>
									</li>
									<li><span>회사가 제공하는 서비스를 통하여 주문 및 결제가 이루어진 경우 상담, 배송 등 거래 이행을 위하여 관련된 정보를 필요한 범위 내에서 거래 당사자에게 제공합니다.</span>
									</li>
								</ol>
							</li>
							<li> <span>회사는 약관에 의거하여 다음과 같은 경우 개인정보를 이용자의 동의 없이 제공, 판매할 수 있습니다.</span>
								<ol class="depth2">
									<li><span>특정 개인을 식별할 수 없는 형태로 가공되어 회사와 계약한 개인 또는 고객에게 제공되는 경우</span>
									</li>
									<li><span>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로써 특정 개인을 식별할 수 없는 형태로 제공하는 경우</span>
									</li>
								</ol>
							</li>
							<li> <span>회사는 약관에 의거하여 다음과 같은 경우 개인정보를 이용자의 동의 없이 제3자에게 제공할 수 있습니다.</span>
								<ol class="depth2">
									<li><span>이용자의 법령 또는 약관의 위반을 포함하여 부정행위 확인 등의 정보보호 업무를 위해 필요한 경우</span>
									</li>
									<li> <span>이용자가 서비스를 이용하여 타인에게 정신적 또는 물질적 피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는
                            경우</span
                        >
                    </li>
                    <li><span>법에 의거하여 적법한 절차에 의한 수사기관이나 기타 정부기관으로부터 정보제공을 요청 받은 경우</span>
									</li>
									<li><span>기타 법률에 의해 요구되는 경우</span>
									</li>
								</ol>
							</li>
						</ol>
						<br />
						<h3><span>6. 개인정보 위탁처리 업체</span></h3>
						<ol>
							<li> <span>회사는 이용자에게 다양한 서비스를 제공하기 위해 다음과 같은 업무를 위탁하고 있습니다.</span>
								<ol class="depth2">
									<li><span>신용카드 결제</span>
									</li>
									<li><span>휴대폰 결제</span>
									</li>
									<li><span>문자발송 및 카카오 알림톡 발송</span>
									</li>
									<li><span>사업자등록 번호 진위 여부</span>
									</li>
								</ol>
							</li>
							<li> <span>회사는 추가적인 서비스 제공으로 인한 개인정보 취급위탁이 있는 경우 및 업무내용에 변경이 있는 경우, 위탁처리기관, 업무내용 및 개인정보의 보유 및 이용기간 등을 명확히 공지하는 등
                    관계법령을 준수합니다.</span
                >
            </li>
        </ol>
        <br />
        <h3><span>7. 이용자 및 법정대리인의 권리와 그 행사방법</span>
								</h3>
								<ol>
									<li><span>이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.</span>
									</li>
									<li><span>개인정보관리책임자에게 서면, 전화, email 또는 기타 가능한 방법으로 연락하시면 지체없이 조치하겠습니다.</span>
									</li>
									<li> <span>이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는
                    정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.</span
                >
            </li>
        </ol>
        <br />
        <h3><span>8. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항</span>
										</h3>
										<ol>
											<li> <span>회사는 이용자에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’등을 운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가
                    이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장되기도 합니다.</span
                >
            </li>
            <li>
                <span>회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.</span>
												<ol class="depth2">
													<li> <span>쿠키 등 사용 목적 – 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문횟수 파악 등을 통한 타겟
                            마케팅 및 개인 맞춤 서비스 제공</span
                        >
                    </li>
                    <li>
                        <span
                            >쿠키 설정 거부 방법 – 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다
                            확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</span
                        >
                    </li>
                    <li>
                        <span
                            >설정방법 (인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 탭 > 개인정보취급 수준에서 설정, 단 쿠키의 저장을 거부할 경우 로그인이 필요한 일부
                            서비스의 이용에 제한이 생길 수 있음을 양지하시기 바랍니다.</span
                        >
                    </li>
                </ol>
            </li>
        </ol>
        <br />
        <h3><span>9. 개인정보보호를 위한 기술적 / 관리적 대책</span>
														</h3>
														<ol>
															<li> <span>회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 노출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/ 관리적 대책을 강구하고 있습니다.</span>
															</li>
															<li> <span>기술적 대책</span>
																<ol class="depth2">
																	<li><span>회원 개개인의 개인정보는 비밀번호에 의해 보호되며, 개인정보 데이터는 별도의 보안기능을 통해 보호되고 있습니다.</span>
																	</li>
																	<li><span>해킹 등에 대비한 대책 – 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.</span>
																	</li>
																	<li><span>개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있습니다.</span>
																	</li>
																	<li> <span>최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수
                            있도록 하고 있습니다.</span
                        >
                    </li>
                    <li>
                        <span>침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</span>
																	</li>
																</ol>
															</li>
															<li> <span>관리적인 대책</span>
																<ol class="depth2">
																	<li> <span>위와 같은 노력 이외에 회원님 스스로도 제3자에게 비밀번호 등이 노출되지 않도록 주의하셔야 합니다. 특히 비밀번호 등이 공공장소에 설치한 PC를 통해 유출되지 않도록 항상
                            유의하시기 바랍니다. 회원님의 ID와 비밀번호는 반드시 본인만 사용하시고 비밀번호를 자주 바꿔주시는 것이 좋습니다.</span
                        >
                    </li>
                    <li>
                        <span
                            >취급 직원의 최소화 및 교육 - 회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시
                            교육을 통하여 회사는 개인정보취급방침의 준수를 항상 강조하고 있습니다.</span
                        >
                    </li>
                </ol>
            </li>
        </ol>
        <br />
        <h3><span>10. 개인정보관리책임자 및 담당자의 연락처</span>
																		</h3>
																		<p> <span>회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을
                드릴 것입니다.</span
            >
        </p>
        <p>
            <span>개인정보관리책임자 : (주)Recreate 정기현</span> 
																			<br /> <span>이메일 : svchk12@teamtrucker.co.kr</span>
																		</p>
																		<p> <span>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의 하시기 바랍니다.</span> 
																			<br /> <span>개인정보침해신고센터(<a href="http://www.118.or.kr/">http://www.118.or.kr/</a> &nbsp;Tel: 118)</span> 
																			<br /> <span>정보보호마크인증위원회(<a href="http://www.eprivacy.or.kr/">http://www.eprivacy.or.kr/</a> &nbsp;Tel: 02-580-0533~4)</span> 
																			<br /> <span>대검찰청 첨단범죄수사과(<a href="http://www.spo.go.kr/">http://www.spo.go.kr/</a> &nbsp;Tel: 02-3480-2000)</span> 
																			<br /> <span>경찰청 사이버테러대응센터(<a href="http://www.ctrc.go.kr/">http://www.ctrc.go.kr/</a> &nbsp;Tel: 02-392-0330)</span>
																		</p>
																		<br />
																		<h3><span>11. 고지의 의무</span></h3>
																		<p><span>현 개인정보취급방침의 내용 추가, 삭제 및 수정이 있을 시에는 온라인 사이트의 ‘공지사항’을 통해 공고하도록 하겠습니다.</span>
																		</p>
																		<br />
																		<p> <span>공고일자 : 2023년 03월 01일</span> 
																			<br /> <span>시행일자 : 2021년 03월 01일</span>
																		</p>

<h2><span>위치기반 서비스 이용약관</span></h2>
	<br />
	<h4><span>제1조(목적)</span></h4>
	<p> <span>이 약관은 (주)Recreate(이하 &quot;회사&quot;라 함)가 운영하는 “팀트럭커” (이하 “팀트럭커”이라 한다) 에서 화물정보망 서비스 제공에 필요한 위치기반 부가 서비스(이하 &quot;서비스&quot;라 한다)를 서비스 이용자(위치기반서비스 이용약관에 동의한 자를 말합니다. 이하 "이용자"라고 한다.)가
                이용하는데 당사자의 권리,의무 및 책임사항을 규정함을 목적으로 한다.</span
            >
        </p>
        <h4><span>제2조(이용약관의 효력 및 변경)</span>
		</h4>
		<ol>
			<li> <span>본 약관은 이용자 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생한다.</span></li>
            <li> <span>이용자가 온라인 또는 모바일에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 본다.</span></li>
            <li> <span>회사는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있다.</span></li>
            <li> <span>회사가 약관을 개정할 경우에는 약관의 변경내용, 사유 및 개정약관의 적용일자를 명시하여 공지한다.</span></li>
            <li> <span>회사가 전항에 따라 이용자에게 공지한 후 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 보며, 이용자가 개정약관에 동의하지 않을 경우 이용자는 이용계약을 해지할 수 있습니다.</span></li>
		</ol>
		<h4><span>제3조 (관계법령의 적용)</span></h4>
		<ol>
			<li><span>본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따른다.</span>
			</li>
			
        </ol>
        <h4><span>제4조 (서비스의 내용)</span>
				</h4>
				<ol>
					<li><span>회사가 제공하는 서비스는 아래와 같다.</span></li>
					<table style="border-collapse:collapse;text-align:center;">
                        <thead>
                            <tr>
                                <td style="border:1px solid;width:200px;padding:10px;">서비스 명</td>
                                <td style="border:1px solid;width:400px;padding:10px;">서비스 내용</td>
                            </tr>
                        </thead>
                            
                        <tbody style="">
                        <tr>
                                <td style="border:1px solid;width:200px;padding:10px;">위치기반 서비스</td>
                                <td style="border:1px solid;width:400px;padding-left:5px;text-align:left;">
                                <ol>
				                	<li><span>차량 근거리 오더 조회, 배차신청</span></li>
                                    <li><span></span>배차신청차량과 목적지(화물, 화주)간 거리조회</li>
                                    <li><span></span>운송계약관련 화물 또는 차량의 위치/경로 확인</li>
				                </ol>
                            </tr>
                        </tbody>
                    </table>
                    <li><span>위치정보를 활용한 검색결과 제공 및 배차처리: 화물정보 검색을 요청, 배차 신청시에 개인위치정보주체 또는 이동성 있는 기기의 위치정보를 이용한 검색결과를 제시하고 배차처리에 이용한다</span></li>
                    <li><span>위치정보 공유: 개인위치정보주체 또는 이동성 있는 기기의 위치정보를 운송계약과 관련한 화물이나 차량의 위치/경로 확인을 목적으로 오더등록, 운송의뢰, 운송물 인도, 인수를 포함한 운송이해관계자(주선사, 화주, 상하차 담당자 등) 또는 개인위치정보주체가 지정한 제3자에게 공유한다.</span></li>
                    <li><span>이용자 보호 및 부정 이용 방지: 개인위치정보주체 또는 이동성 있는 기기의 위치를 이용하여 이용자의 안전을 위한 조치를 하며, 권한 없는 자의 비정상적인 서비스 이용 시도 등을 차단한다</span></li>
                    <li><span>길 안내 등 편의 서비스 제공</span></li>
                    <li><span>현재 위치를 활용한 광고정보 제공 서비스 제공</span></li>
                    <li><span>회사가 제공하는 서비스 내용은 회사의 정책과 방침에 따라 이용자에게 공지한 후 변경될 수 있다.</span></li>
				</ol>
                
				<h4><span>제5조 (서비스 이용요금)</span></h4>
				<ol>
					<li><span>회사가 제공하는 서비스는 무료로 한다.</span></li>
                    <li><span>회사의 정책과 방침에 따라 서비스를 유료로 변경할 수 있으며, 유료 서비스의 경우 해당 서비스에 명시된 요금을 지불해야 사용 가능함.</span></li>
				</ol>

				<h4><span>제6조 (서비스 내용변경 통지 등)</span></h4>
				<ol>
					<li><span>회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 이용자의 등록된 전자우편 주소 및/또는 이용자 프로그램(웹페이지 또는 어플)의 공지를 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있다.</span></li>
				</ol>

				<h4><span>제7조 (서비스 이용 제한 및 중지)</span></h4>
				<ol>
                <li><span>회사는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 이용자의 서비스 이용을 제한하거나 중지시킬 수 있습니다.</span>
                        <ol>
                            <li><span>이용자가 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우</span></li>
                            <li><span>서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</span></li>
                            <li><span>전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</span></li>
                            <li><span>국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</span></li>
                            <li><span>기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</span></li>
                        </ol>
                    </li>
                <li><span>회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 이용자에게 알려야 합니다.</span>
                <li><span>회사는 위치정보사업자의 정책변경 등과 같이 회사의 제반 사정 또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우, 서비스의 전부 또는 일부를 제한, 변경하거나 중지할 수 있습니다. 이러한 서비스 중단의 경우에는 회사는 사전에 홈페이지, 어플 등에 공지하거나 개인위치정보주체에게 통지합니다.</span>
				</ol>

        <h4><span>제8조 (개인위치정보의 이용 또는 제공)</span></h4>
            <ol>
                <li><span>회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 다음 각호의 사항을 미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 합니다</<span></li>
                <ol>
                    <li><span>위치기반서비스사업자의 상호, 주소, 전화번호 그 밖의 연락처</span></li>
                    <li><span>개인위치정보주체 및 법정대리인(이 약관 제10조, 제11조 규정에 의하여 법정대리인의 동의를 얻어야 하는 경우로 한정함)의 권리와 그 행사방법</span></li>
                    <li><span>위치기반서비스사업자가 제공하고자 하는 위치기반서비스의 내용</span></li>
                    <li><span>위치정보 이용ㆍ제공사실 확인자료의 보유근거 및 보유기간</span></li>
                    <li><span>본조 제6조, 7조에 규정된 통보에 관한 사항</span></li>
                </ol>
                <li><span>회사는 서비스제공, 타이용자 제공 또는 이용 고객과의 민원처리 등을 위해 위치정보 이용·제공 및 사실 확인자료를 자동 기록·보존하며, 해당 자료는 1년간 보관합니다.</<span></li>
                <li><span>회사는 개인위치정보를 정보주체가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치를 통해 시스템, 어플에서 확인하는 방식으로 매회 이용자에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. 단, 화물운송 이행과정에서 상대방 등에게 위치정보를 제공하는 경우에는 이용자가 언제든지 직접 자신의 위치정보 제3자 제공 내역을 조회할 수 있도록 시스템을 구성, 조회권한을 부여, 제공하며 이를 개별적인 통지에 갈음할 수 있습니다.</<span></li>
                <li><span>④ 회사는 배차신청 차량과 목적지간 거리조회, 운송계약과 관련한 화물이나 차량의 위치/경로 확인을 목적으로 오더등록, 운송의뢰, 운송물 인도, 인수를 포함한 운송이해관계자(주선사, 화주, 상하차 담당자 등)에게 개인위치정보를 제공합니다. 또한 회사는 운송의뢰자 휴대폰 위치정보를 상차지 확인에 갈음할 목적으로 운송인(차주)에게 제공할 수 있습니다.</<span></li>
            </ol>

        <h4><span>제9조 (개인위치정보주체의 권리)</span></h4>
            <ol>
                <li><span>이용자는 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.</span></li>
                <li><span>이용자는 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.</span></li>
                <li><span>이용자는 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 이용자의 요구를 거절할 수 없습니다.</span>
                    <ol>
                        <li><span>본인에 대한 위치정보 수집, 이용, 제공사실 확인자료</span></li>
                        <li><span>본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용</span></li>
                    </ol>
                </li>
                <li><span>이용자는 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.</span></li>
                <li><span>이용자 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.</span></li>
            </ol>

        <h4><span>제10조 (화물정보망 서비스의 이용제한)</span></h4>
        <ol>
            <li><span>이용자가 개인위치정보의 이용, 제공에 동의하지 않거나 거절하는 경우, 회사는 화물정보망서비스 이용에 제한을 둘 수 있습니다.</span></li>
            <li><span>본 서비스에 따라 차주의 위치정보를 제공받은 화주는 화물운송계약의 목적달성에 필요한 범위에서 회사가 정한 절차와 방식으로 이용해야 합니다. 만일 위치정보를 제공받은 화주가 약관, 회사규정이나 법령에 위배하여 이용한 경우 법적인 책임을 지며, 회사는 해당 화주의 정보망서비스 이용에 제한을 둘 수 있습니다.</span></li>
        </ol>

        <h4><span>제11조 (위치정보관리책임자의 지정)</span></h4>
        <ol>
            <li><span>회사는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다. </span></li>
            <li><span>위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.</span></li>
        </ol>

        <h4><span>제12조 (손해배상)</span></h4>
        <ol>
            <li><span>회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 이용자에게 손해가 발생한 경우 이용자는 회사에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다. </span></li>
            <li><span>이용자가 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 이용자에 대하여 손해배상을 청구할 수 있습니다. 이 경우 이용자는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.</span></li>
            <li><span>화주가 제10조의 2항의 규정 및 법률을 위반하여 위치정보 권리 주체 또는 회사에 손해가 발생한 경우, 화주는 고의, 과실이 없음을 입증하지 못하는 경우 위치정보 권리주체와 회사에 대해 책임을 면할 수 없습니다.</span></li>
        </ol>

        <h4><span>제13조 (면책)</span></h4>
        <ol>
            <li><span>회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.</span>
                <ol>
                <li><span>천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</span></li>
                <li><span>서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우</span></li>
                <li><span>이용자의 귀책사유로 서비스 이용에 장애가 있는 경우</span></li>
                <li><span>제1호 내지 제3호를 제외한 기타 회사의 고의∙과실이 없는 사유로 인한 경우</span></li>
                </ol>
            </li>
            <li><span>회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 이용자의 손해에 대하여는 책임을 부담하지 아니합니다.</span></li>
        </ol>

        <h4><span>제14조 (분쟁의 조정 및 기타)</span></h4>
        <ol>
            <li><span>회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.</span></li>
            <li><span>회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.</span></li>
            <li><span>본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다.</span></li>
        </ol>

</body>

</html>
`;
class PrivacyLocationPolicy extends Component {
  render() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          whiteSpace: 'normal',
          //  paddingLeft: 20
        }}
      >
        {ReactHtmlParser(someHTML)}
      </div>
    );
    //ReactDOM.render(someHTML)
  }
}
export default withRouter(PrivacyLocationPolicy);
