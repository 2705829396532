import React, { Component } from 'react';
import {
  COLOR_BLACK,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import {
  STR_ID,
  STR_PW,
  STR_LOGIN,
  STR_SEN_2,
  STR_SEN_3,
} from '../../common/Terms';

class MainFooter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            height: '60px',
            background: '#F5F5F5',
            display: 'flex',
            justifyContent: 'center',
            color: COLOR_GREEN_PRIMARY,
            //borderTop: '1px solid #78C939',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ul
            style={{
              listStyle: 'none',
              fontSize: '14px',
              color: COLOR_WHITE,
              marginTop: '5px',
              marginBottom: '5px',
              fontWeight: '400',
            }}
          >
            <li
              style={{
                float: 'left',
                marginRight: '5px',
                color: COLOR_BLACK,
              }}
            >
              CopyrightⓒTeamTrucker All rights Reserved
              {/* (주)리크리에이트 대표이사 박훈진 */}
            </li>
            {/* <li
              style={{
                float: 'left',
                marginRight: '5px',
                color: COLOR_BLACK,
              }}
            >
              | 주소 : 경기도 수원시 영통구 광교로 107 경기도 경제과학진흥원
              보육동 208호
            </li> */}
          </ul>
          {/* <ul
            style={{
              listStyle: 'none',
              fontSize: '12pt',
              color: COLOR_WHITE,
              marginTop: '5px',
              marginBottom: '5px',
            }}
          >
            <li
              style={{
                float: 'left',
                marginRight: '5px',
                color: COLOR_BLACK,
              }}
            >
              | 사업자등록번호 771-86-02308
            </li>
            <li style={{ float: 'left', color: COLOR_BLACK }}>
              | 대표번호 031-291-2934
            </li>
            <li
              style={{
                float: 'left',
                marginRight: '5px',
                color: COLOR_BLACK,
              }}
            >
              | 통신판매업 신고번호 : 제2021-수원장안-1033호
            </li>
            <li
              style={{
                float: 'left',
                marginRight: '5px',
                color: COLOR_BLACK,
              }}
            >
              | 고객센터 : 031-291-2934 (10:00 ~ 18:00)
            </li>
          </ul> */}
        </div>
      </React.Fragment>
    );
  }
}

export default MainFooter;
