import React from 'react';
import { withRouter } from 'react-router';
import { UserIndex } from '../Management/User';
import { DashBoardIndex } from './index';
import OrderContainer from '../Order/OrderContainer';

class DashBoardContainer extends React.Component {
  render() {
    let currentPage;
    switch (this.props.location.pathname) {
      case '/main':
        currentPage = <OrderContainer />;
      case '/user/trucker':
        currentPage = <UserIndex.DriverManagementContainer />;
      case '/user/cutomer':
        currentPage = <UserIndex.CustomerManagementContainer />;
      default:
        currentPage = <OrderContainer />;
        break;
    }
    return <React.Fragment>{currentPage}</React.Fragment>;
  }
}

export default withRouter(DashBoardContainer);
