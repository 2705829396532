import { apiAddress } from '../ApiPlatform';

export const API_SELECT_COMCD = apiAddress + '/common/findComcd';
export const API_SELECT_VC_TYP_CD = apiAddress + '/common/findVcTypComcd';

export const API_SELECT_COMCD_WITHOUT_CODE_ZERO =
  apiAddress + '/common/selectComcdWithOutCodeZero';

export const API_IMAGE_HANDLER = apiAddress + '/common/imageHandler';

export const API_SELECT_SYSCD_CUSTOMER =
  apiAddress + '/common/selectSyscdCustomer';

export const API_SELECT_PAYMENT_TOOL_CD =
  apiAddress + '/common/findPaymentToolCd';
