import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import TreeMenu from 'react-simple-tree-menu';
import '../../../../common/Treement.css';
import { API_SELECT_MENU_LIST } from '../../../../common/ApiAddress/MenuAPI';
import { API_SELECT_EMP_LIST } from '../../../../common/ApiAddress/EmpAPI';
import {
  API_SELECT_CHECK_PRIVILEGE,
  API_INSERT_PRIVILEGE,
  API_UPDATE_PRIVILEGE,
} from '../../../../common/ApiAddress/PrevilegeAPI';
import PrivilegeUpsertContainer from './PrivilegeUpsertContainer';
import EmpList from '../../Group/Emp/EmpList';
@inject(stores => ({
  menuStore: stores.MenuStore,
  empStore: stores.EmpStore,
  prvStore: stores.PrivilegeStore,
}))
@observer
class PrivilegeManagementContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      upsertView: false,
      upsertMode: 'I',
    };
  }

  treeMenuRef = React.createRef();

  componentDidMount() {
    this.fnSelectEmpList();
  }

  //사원 리스트 불러오기
  fnSelectEmpList = () => {
    const { empStore } = this.props;
    let params = {};
    let that = this;
    axios({
      url: API_SELECT_EMP_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          empStore.setEmpList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectedEmp = value => {
    const { menuStore, empStore, prvStore } = this.props;

    empStore.setSelectedEmp(value);
    empStore.setEmpId(value.empId);
    empStore.setPw(value.pw);
    empStore.setEmpNm(value.empNm);
    empStore.setHpNo(value.hpNo);
    if (value.useYn == 'Y') {
      empStore.setUseYnTrue();
    } else {
      empStore.setUseYnFalse();
    }
    this.fnSelectAllmenuTree();

    if (menuStore.selectedMenu != null) {
      this.treeMenuRef.current.resetOpenNodes();
    }
    menuStore.initForm();
    prvStore.initForm();
    this.setState({
      upsertView: false,
    });
  };

  //전체메뉴 리스트 불러오기
  fnSelectAllmenuTree = () => {
    const { menuStore } = this.props;
    let params = {};
    let that = this;
    params.primaryUseYn = 'Y';
    params.secondaryUseYn = 'Y';
    axios({
      url: API_SELECT_MENU_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          menuStore.setMenuList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //메뉴 선택
  fnSelectedMenu = (value, key, label) => {
    const { menuStore } = this.props;

    if (value.depth == 1) {
      value.menuNo = Number(key);
    } else {
      var keyString = key;
      var keySplit = keyString.split('/');
      value.menuNo = keySplit[1];
    }
    menuStore.setSelectedMenu(value);
    menuStore.setMenuNm(label);
    menuStore.setMenuPath(value.menuPath);
    menuStore.setDepth(value.depth);
    menuStore.setSortNo(value.sortNo);
    menuStore.setPrimaryNo(value.primaryNo);
    menuStore.setEventKey(value.eventKey);
    menuStore.setIcon(value.icon);
    if (value.useYn == 'Y') {
      menuStore.setUseYnTrue();
    } else {
      menuStore.setUseYnFalse();
    }

    this.fnSelectCheckAuthMenu();
  };

  //해당메뉴 권한 체크
  fnSelectCheckAuthMenu = () => {
    const { prvStore, menuStore, empStore } = this.props;
    let params = {};
    let that = this;
    params.authId = empStore.selectedEmp.authId;
    params.menuNo = menuStore.selectedMenu.menuNo;
    axios({
      url: API_SELECT_CHECK_PRIVILEGE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            console.log(response.data.data);
            prvStore.setSelectedAuthMenu(response.data.data);
            that.fnSetPrivilegeProps(response.data.data);
          } else {
            prvStore.initForm();
          }
        }
        that.setState({
          upsertView: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetPrivilegeProps = value => {
    const { prvStore } = this.props;
    console.log(value);

    if (value.selectYn == 'Y') {
      prvStore.setUseYnSelectTrue();
    } else {
      prvStore.setUseYnSelectFalse();
    }

    if (value.insertYn == 'Y') {
      prvStore.setUseYnInsertTrue();
    } else {
      prvStore.setUseYnInsertFalse();
    }

    if (value.updateYn == 'Y') {
      prvStore.setUseYnUpdateTrue();
    } else {
      prvStore.setUseYnUpdateFalse();
    }

    if (value.deleteYn == 'Y') {
      prvStore.setUseYnDeleteTrue();
    } else {
      prvStore.setUseYnDeleteFalse();
    }
  };

  //권한 부여
  fnInsertPrivilege = () => {
    const { prvStore, menuStore, empStore } = this.props;
    let params = {};
    let that = this;
    params.authId = empStore.selectedEmp.authId;
    params.menuNo = Number(menuStore.selectedMenu.menuNo);
    params.primaryNo =
      menuStore.selectedMenu.primaryNo == undefined
        ? null
        : menuStore.selectedMenu.primaryNo;

    if (prvStore.useYnSelect) {
      params.selectYn = 'Y';
    } else {
      params.selectYn = 'N';
    }

    if (prvStore.useYnInsert) {
      params.insertYn = 'Y';
    } else {
      params.insertYn = 'N';
    }

    if (prvStore.useYnUpdate) {
      params.updateYn = 'Y';
    } else {
      params.updateYn = 'N';
    }

    if (prvStore.useYnDelete) {
      params.deleteYn = 'Y';
    } else {
      params.deleteYn = 'N';
    }
    console.log(params);
    axios({
      url: API_INSERT_PRIVILEGE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnInitForm();
        }
        that.setState({
          upsertView: false,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //권한 수정
  fnUpdatePrivilege = () => {
    const { prvStore, menuStore, empStore } = this.props;
    let params = {};
    let that = this;
    params.authId = empStore.selectedEmp.authId;
    params.menuNo = menuStore.selectedMenu.menuNo;

    if (prvStore.useYnSelect) {
      params.selectYn = 'Y';
    } else {
      params.selectYn = 'N';
    }

    if (prvStore.useYnInsert) {
      params.insertYn = 'Y';
    } else {
      params.insertYn = 'N';
    }

    if (prvStore.useYnUpdate) {
      params.updateYn = 'Y';
    } else {
      params.updateYn = 'N';
    }

    if (prvStore.useYnDelete) {
      params.deleteYn = 'Y';
    } else {
      params.deleteYn = 'N';
    }

    axios({
      url: API_UPDATE_PRIVILEGE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnInitForm();
        }
        that.setState({
          upsertView: false,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnInitForm = () => {
    const { menuStore, empStore, prvStore } = this.props;
    this.treeMenuRef.current.resetOpenNodes();
    menuStore.initForm();
    empStore.initForm();
    prvStore.initForm();
  };

  render() {
    const { empStore, menuStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '30%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          <EmpList fnViewOpen={this.fnSelectedEmp} />
        </div>
        <div
          style={{
            width: '30%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {empStore.selectedEmp != null ? (
            <TreeMenu
              ref={this.treeMenuRef}
              hasSearch={false}
              data={menuStore.menuList}
              onClickItem={({ key, label, ...props }) => {
                this.fnSelectedMenu(props, key, label);
              }}
              debounceTime={125}
            />
          ) : null}
        </div>
        <div
          style={{
            width: '40%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {this.state.upsertView == true ? (
            <PrivilegeUpsertContainer
              fnInsertPrivilege={this.fnInsertPrivilege}
              fnUpdatePrivilege={this.fnUpdatePrivilege}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(PrivilegeManagementContainer);
