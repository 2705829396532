import React, { Component } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import {
  API_SEARCH_CUSTOMER_ID,
  API_GENERATE_NICD_ACCESS_TOKEN,
  API_SEARCH_CUSTOMER_PW_ISMATCH,
  API_UPDATE_CORPORATION_NEW_PW,
} from '../../common/ApiAddress/AccountAPI';
import {
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import SelectCustomerCd from '../../Component/Login/SearchAcc/SelectCustomerCd';
import Individual from '../../Component/Login/SearchAcc/Individual';
import Corporation from '../../Component/Login/SearchAcc/Corporation';
import MainFooter from '../../Component/Common/MainFooter';
import CommonAlert from '../../Component/Common/CommonAlert';
import CommonAlertWithCallback from '../../Component/Common/CommonAlertWithCallback';
@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class SearchAccContainer extends React.Component {
  state = {
    authYn: false,
    customerNm: null,
    hpNo: null,
    email: null,
  };

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
  }

  //화주 아이디 찾기
  fnSearchCustomerId = () => {
    const { accStore } = this.props;

    const optionFail = {
      style: { color: 'red' },
    };

    const optionSuccess = {
      style: { color: 'green' },
    };

    let params = {};
    let that = this;
    params.customerCd = accStore.customerCd;
    params.customerNm = accStore.customerNm;
    if (accStore.customerCd == 'IACCU002') {
      params.hpNo = accStore.hpNo;
    } else {
      params.email = accStore.email;
    }

    axios({
      url: API_SEARCH_CUSTOMER_ID,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          accStore.setSearchCustomerIdList(response.data.data);
          that.setState({
            authYn: false,
            customerNm: null,
            hpNo: null,
          });
          that.props.history.push('/searchIdReturn');
        }
        if (response.data.resultCode == 200) {
          toast('정보와 일치하는 아이디가 없습니다.', optionFail);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //nice accessToken 발급
  fnGetAccessToken = () => {
    let that = this;
    const { accStore, commonStore } = this.props;

    if (accStore.customerId == null || accStore.customerId == '') {
      commonStore.commonAlertOpen('아이디를 적어주세요.');
      return false;
    }

    axios({
      url: API_GENERATE_NICD_ACCESS_TOKEN,
      method: 'post',
    })
      .then(function (response) {
        if (response.data.dataHeader.GW_RSLT_CD == '1200') {
          that.fnConnetCheckPlus();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //nice 모듈 연결
  fnConnetCheckPlus = () => {
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'checkplus_main',
      method: 'post',
    }).then(function (response) {
      document.getElementById('hideNice').innerHTML = response.data;
      if (response.status == 200) {
        that.fnPopup(response.data);
      }
    });
  };

  //팝업 오픈
  fnPopup = () => {
    window.open(
      '',
      'popupChk',
      'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
    );
    document.form_chk.action =
      'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
    document.form_chk.target = 'popupChk';
    document.form_chk.submit();
  };

  receiveMessage = async e => {
    if (e.data.success == 'Y') {
      this.setState({
        authYn: true,
        customerNm: e.data.customerNm,
        hpNo: e.data.hpNo,
      });
    }
  };

  //화주 정보 매칭 결과 받기
  fnCheckPwIsMatch = () => {
    const { accStore } = this.props;

    const optionFail = {
      style: { color: 'red' },
    };
    const optionSuccess = {
      style: { color: 'green' },
    };

    let params = {};
    let that = this;
    params.customerId = accStore.customerId;
    params.customerCd = accStore.customerCd;
    if (accStore.customerCd == 'IACCU002') {
      params.hpNo = accStore.hpNo;
    } else {
      params.email = this.state.email;
    }

    axios({
      url: API_SEARCH_CUSTOMER_PW_ISMATCH,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (accStore.customerCd == 'IACCU002') {
            that.setState({
              authYn: false,
              customerNm: null,
              hpNo: null,
              email: null,
            });
            that.props.history.push('/pwsetting');
          } else {
            that.fnInitCorporationPw();
          }
        }
        if (response.data.resultCode == 200) {
          toast('정보와 일치하는 아이디가 없습니다.', optionFail);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetEmailForSearch = value => {
    this.setState({
      email: value,
    });
  };

  fnMoveToMain = () => {
    const { accStore } = this.props;
    accStore.initCustomerId();
    this.props.history.push('/');
  };

  //법인화주 비밀번호 초기화
  fnInitCorporationPw = () => {
    const { accStore, commonStore } = this.props;
    let params = {};
    let that = this;
    params.email = this.state.email;
    params.customerId = accStore.customerId;
    params.updateId = accStore.customerId;
    console.log(params);
    axios({
      url: API_UPDATE_CORPORATION_NEW_PW,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.commonAlertWithCallbackOpen(
            '입력하신 이메일로 새 비밀번호가 전송되었습니다.',
          );
          that.setState({
            authYn: false,
            customerNm: null,
            hpNo: null,
            email: null,
          });
        } else {
          commonStore.commonAlertWithCallbackOpen(
            '실패하였습니다. 관리자에 문의하세요.',
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  render() {
    const { accStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            height: '100vh',
            background: '#000',
          }}
        >
          <div style={{ textAlign: 'center', paddingTop: '100px' }}>
            <img
              style={{ width: '20%', marginBottom: '32px' }}
              src={'./image/logoDarktheme.png'}
            />
          </div>
          <SelectCustomerCd />
          {accStore.customerCd == 'IACCU002' ? (
            <Individual
              fnSearchCustomerId={this.fnSearchCustomerId}
              fnCheckPwIsMatch={this.fnCheckPwIsMatch}
              fnGetAccessToken={this.fnGetAccessToken}
              authYn={this.state.authYn}
            />
          ) : accStore.customerCd == 'IACCU003' ? (
            <Corporation
              fnSearchCustomerId={this.fnSearchCustomerId}
              fnCheckPwIsMatch={this.fnCheckPwIsMatch}
              email={this.state.email}
              fnSetEmailForSearch={this.fnSetEmailForSearch}
            />
          ) : null}

          <CommonAlert />
          <CommonAlertWithCallback callback={this.fnMoveToMain} />
          <div id="hideNice" style={{ display: 'none' }} />
          <div style={{ position: 'fixed', bottom: '0px', width: '100%' }}>
            <MainFooter />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchAccContainer;
