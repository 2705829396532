import React, { Component } from 'react';
import { ReactComponent as SpecTop } from '../../assets/icon/spec_top.svg';
import { ReactComponent as SpecTopMo } from '../../assets/icon/spec_top_mo.svg';
import {
  COLOR_WHITE,
  COLOR_GRAY_GR01,
  COLOR_GREEN_PRIMARY,
  COLOR_GRAY_GR07,
} from '../../common/Color';
import Media from 'react-media';

class Spec extends Component {
  componentDidMount() {
    this.props.fnFreightFormInit();
  }
  render() {
    const specList = [
      {
        vcTypNm: '0.5톤 다마스',
        spec: '110cm',
        length: '160cm',
        bottomHeight: '70cm',
        stroageHeight: '110cm',
      },
      {
        vcTypNm: '0.5톤 라보',
        width: '140cm',
        length: '220cm',
        bottomHeight: '70cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '1톤 일반카고',
        width: '160cm',
        length: '275-285cm',
        bottomHeight: '80cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '1톤 탑차/윙바디',
        width: '160cm',
        length: '275-285cm',
        bottomHeight: '80cm',
        stroageHeight: '160-180cm',
      },
      {
        vcTypNm: '1.4톤 일반카고',
        width: '170cm',
        length: '290-310cm',
        bottomHeight: '80-85cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '1.4톤 탑차/윙바디',
        width: '170cm',
        length: '290-310cm',
        bottomHeight: '80-85cm',
        stroageHeight: '160-200cm',
      },
      {
        vcTypNm: '2.5톤 일반카고',
        width: '180cm',
        length: '430cm',
        bottomHeight: '100cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '2.5톤 탑차/윙바디',
        width: '180cm',
        length: '430cm',
        bottomHeight: '100cm',
        stroageHeight: '180-200cm',
      },
      {
        vcTypNm: '3.5톤 일반카고',
        width: '205cm',
        length: '460cm',
        bottomHeight: '105cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '3.5톤 탑차/윙바디',
        width: '205cm',
        length: '460cm',
        bottomHeight: '105cm',
        stroageHeight: '180-200cm',
      },
      {
        vcTypNm: '5톤 일반카고',
        width: '215-230cm',
        length: '620cm',
        bottomHeight: '110-120cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '5톤 탑차/윙바디',
        width: '215-230cm',
        length: '620cm',
        bottomHeight: '110-120cm',
        stroageHeight: '180-230cm',
      },
      {
        vcTypNm: '5톤 플러스 축차 일반카고',
        width: '230cm',
        length: '720-800cm',
        bottomHeight: '110-120cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '5톤 플러스 축차 탑차/윙바디',
        width: '235cm',
        length: '720-800cm',
        bottomHeight: '110-120cm',
        stroageHeight: '180-230cm',
      },
      {
        vcTypNm: '11~15톤 일반카고',
        width: '235cm',
        length: '900cm',
        bottomHeight: '130-140cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '11~15톤 탑차/윙바디',
        width: '235cm',
        length: '900-1,000cm',
        bottomHeight: '130-140cm',
        stroageHeight: '200-250cm',
      },
      {
        vcTypNm: '18톤 일반카고',
        width: '235cm',
        length: '1,010cm',
        bottomHeight: '130-140cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '18톤 탑차/윙바디',
        width: '235cm',
        length: '1,010cm',
        bottomHeight: '130-140cm',
        stroageHeight: '200-250cm',
      },
      {
        vcTypNm: '22~25톤 일반카고',
        width: '235cm',
        length: '1,010cm',
        bottomHeight: '130-140cm',
        stroageHeight: '-',
      },
      {
        vcTypNm: '22~25톤 탑차/윙바디',
        width: '235cm',
        length: '1,010cm',
        bottomHeight: '130-140cm',
        stroageHeight: '200-250cm',
      },
    ];

    const specListForMobile = [
      {
        vcTypNm: '다마스',
        width: '110',
        length: '160',
        bottomHeight: '70',
        stroageHeight: '110',
      },
      {
        vcTypNm: '라보',
        width: '140',
        length: '220',
        bottomHeight: '70',
        stroageHeight: '-',
      },
      {
        vcTypNm: '1톤카고',
        width: '160',
        length: '275-285',
        bottomHeight: '80',
        stroageHeight: '-',
      },
      {
        vcTypNm: '1톤\n탑차/윙바디',
        width: '160',
        length: '275-285',
        bottomHeight: '80',
        stroageHeight: '160-180',
      },
      {
        vcTypNm: '1.4톤카고',
        width: '170',
        length: '290-310',
        bottomHeight: '80-85',
        stroageHeight: '-',
      },
      {
        vcTypNm: '1.4톤\n탑차/윙바디',
        width: '170',
        length: '290-310',
        bottomHeight: '80-85',
        stroageHeight: '160-200',
      },
      {
        vcTypNm: '2.5톤카고',
        width: '180',
        length: '430',
        bottomHeight: '100',
        stroageHeight: '-',
      },
      {
        vcTypNm: '2.5톤\n탑차/윙바디',
        width: '180',
        length: '430',
        bottomHeight: '100',
        stroageHeight: '180-200',
      },
      {
        vcTypNm: '3.5톤카고',
        width: '205',
        length: '460',
        bottomHeight: '105',
        stroageHeight: '-',
      },
      {
        vcTypNm: '3.5톤\n탑차/윙바디',
        width: '205',
        length: '460',
        bottomHeight: '105',
        stroageHeight: '180-200',
      },
      {
        vcTypNm: '5톤카고',
        width: '215-230',
        length: '620',
        bottomHeight: '110-120',
        stroageHeight: '-',
      },
      {
        vcTypNm: '5톤\n탑차/윙바디',
        width: '215-230',
        length: '620',
        bottomHeight: '110-120',
        stroageHeight: '180-230',
      },
      {
        vcTypNm: '5톤플러스\n축차카고',
        width: '230',
        length: '720-800',
        bottomHeight: '110-120',
        stroageHeight: '-',
      },
      {
        vcTypNm: '5톤플러스\n축차\n탑차/윙바디',
        width: '235',
        length: '720-800',
        bottomHeight: '110-120',
        stroageHeight: '180-230',
      },
      {
        vcTypNm: '11~15톤\n일반카고',
        width: '235',
        length: '900',
        bottomHeight: '130-140',
        stroageHeight: '-',
      },
      {
        vcTypNm: '11~15톤\n탑차/윙바디',
        width: '235',
        length: '900-1,000',
        bottomHeight: '130-140',
        stroageHeight: '200-250',
      },
      {
        vcTypNm: '18톤\n일반카고',
        width: '235',
        length: '1,010',
        bottomHeight: '130-140',
        stroageHeight: '-',
      },
      {
        vcTypNm: '18톤\n탑차/윙바디',
        width: '235',
        length: '1,010',
        bottomHeight: '130-140',
        stroageHeight: '200-250',
      },
      {
        vcTypNm: '22~25톤\n일반카고',
        width: '235',
        length: '1,010',
        bottomHeight: '130-140',
        stroageHeight: '-',
      },
      {
        vcTypNm: '22~25톤\n탑차/윙바디',
        width: '235',
        length: '1,010',
        bottomHeight: '130-140',
        stroageHeight: '200-250',
      },
    ];

    return (
      <Media
        queries={{
          small: '(max-width: 768px)',
          medium: '(min-width: 769px) and (max-width: 1024px)',
          large: '(min-width: 1025px)',
        }}
      >
        {matches => (
          <React.Fragment>
            {matches.small && (
              <div
                id="specTable"
                style={{
                  width: '100%',
                  background: COLOR_WHITE,
                  height: 'calc(100vh - 210px)',
                  overflowY: 'scroll',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: 'fit-content',
                      verticalAlign: 'middle',
                    }}
                    src={'../../image/spec_top_mo.png'}
                  />

                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      textAlign: 'center',
                      letterSpacing: '-1px',
                      transform: 'translate( -50%, -50% )',
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: '22px',
                        fontWeight: '700',
                        marginBottom: '0px',
                      }}
                    >
                      차량규격 및 제원
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '29px',
                    background: COLOR_GRAY_GR01,
                    marginTop: '-5px',
                  }}
                />
                <div
                  style={{
                    width: '100%',
                    marginTop: '20px',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      width: '94%',
                      margin: '0 auto',
                      marginBottom: '40px',
                    }}
                  >
                    <div>
                      <table
                        style={{
                          width: '100%',
                          border: '0px',
                          borderCollapse: 'collapse',
                          textAlign: 'left',
                        }}
                      >
                        <tr>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '40%',
                              padding: '0px',
                              fontSize: '13px',
                              fontWeight: '700',
                              color: COLOR_GREEN_PRIMARY,
                            }}
                          >
                            차량명
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '60%',
                              padding: '0px',
                              fontSize: '13px',
                              fontWeight: '700',
                              color: COLOR_GREEN_PRIMARY,
                            }}
                          >
                            넓이/길이/바닥높이/윙탑내부높이(cm)
                          </th>
                        </tr>
                        {specListForMobile.map((data, i) => (
                          <tr>
                            <th
                              style={{
                                borderBottom:
                                  specListForMobile.length == i + 1
                                    ? '0px'
                                    : '1px solid #E9ECEF',
                                width: '40%',
                                padding: '0px',
                                fontWeight: '400',
                                fontSize: '13px',
                                lineHeight: '16px',
                                height: '34px',
                                color: COLOR_GRAY_GR07,
                              }}
                            >
                              {data.vcTypNm}
                            </th>
                            <th
                              style={{
                                borderBottom:
                                  specListForMobile.length == i + 1
                                    ? '0px'
                                    : '1px solid #E9ECEF',
                                width: '60%',
                                padding: '0px',
                                fontWeight: '400',
                                fontSize: '13px',
                                lineHeight: '16px',
                                height: '40px',
                                color: COLOR_GRAY_GR07,
                              }}
                            >
                              {data.width +
                                ' / ' +
                                data.length +
                                ' / ' +
                                data.bottomHeight +
                                ' / ' +
                                data.stroageHeight}
                            </th>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {matches.large && (
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    width: '100%',
                    height: '400px',
                    position: 'relative',
                  }}
                >
                  <SpecTop
                    style={{
                      marginTop: '16px',
                      verticalAlign: 'middle',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '45%',
                      left: '45%',
                      textAlign: 'center',
                      letterSpacing: '-1px',
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: '40px',
                        fontWeight: '700',
                        marginBottom: '0px',
                      }}
                    >
                      차량규격 및 제원
                    </p>
                  </div>
                </div>
                <div style={{ width: '100%', marginTop: '40px' }}>
                  <div
                    style={{
                      textAlign: 'center',
                      width: '80%',
                      margin: '0 auto',
                      marginBottom: '40px',
                    }}
                  >
                    <div>
                      <table
                        style={{
                          width: '100%',
                          border: '0px',
                          borderCollapse: 'collapse',
                          textAlign: 'left',
                        }}
                      >
                        <tr>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '15%',
                              padding: '0px',
                              fontSize: '14px',
                              fontWeight: '700',
                            }}
                          >
                            차량명
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '15%',
                              padding: '0px',
                              fontSize: '14px',
                              fontWeight: '700',
                            }}
                          >
                            적재함 넓이
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '15%',
                              padding: '0px',
                              fontSize: '14px',
                              fontWeight: '700',
                            }}
                          >
                            적재함 길이
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '15%',
                              padding: '0px',
                              fontSize: '14px',
                              fontWeight: '700',
                            }}
                          >
                            적재함 바닥높이
                          </th>
                          <th
                            style={{
                              borderBottom: '1px solid #CED4DA',
                              width: '15%',
                              padding: '0px',
                              fontSize: '14px',
                              fontWeight: '700',
                            }}
                          >
                            탑/윙 내부높이
                          </th>
                        </tr>
                        {specList.map((data, i) => (
                          <tr>
                            <th
                              style={{
                                borderBottom:
                                  specList.length == i + 1
                                    ? '0px'
                                    : '1px solid #E9ECEF',
                                width: '15%',
                                padding: '0px',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '16px',
                                height: '34px',
                              }}
                            >
                              {data.vcTypNm}
                            </th>
                            <th
                              style={{
                                borderBottom:
                                  specList.length == i + 1
                                    ? '0px'
                                    : '1px solid #E9ECEF',
                                width: '15%',
                                padding: '0px',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '16px',
                                height: '34px',
                              }}
                            >
                              {data.width}
                            </th>
                            <th
                              style={{
                                borderBottom:
                                  specList.length == i + 1
                                    ? '0px'
                                    : '1px solid #E9ECEF',
                                width: '15%',
                                padding: '0px',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '16px',
                                height: '34px',
                              }}
                            >
                              {data.length}
                            </th>
                            <th
                              style={{
                                borderBottom:
                                  specList.length == i + 1
                                    ? '0px'
                                    : '1px solid #E9ECEF',
                                width: '15%',
                                padding: '0px',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '16px',
                                height: '34px',
                              }}
                            >
                              {data.bottomHeight}
                            </th>
                            <th
                              style={{
                                borderBottom:
                                  specList.length == i + 1
                                    ? '0px'
                                    : '1px solid #E9ECEF',
                                width: '15%',
                                padding: '0px',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '16px',
                                height: '34px',
                              }}
                            >
                              {data.stroageHeight}
                            </th>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Media>
    );
  }
}

export default Spec;
