import { apiAddress } from '../ApiPlatform';

export const API_INSERT_WORKBENCH_DRIVER =
  apiAddress + '/work/insertWorkDriver';

export const API_UPDATE_WORKBENCH_DRIVER =
  apiAddress + '/work/updateWorkDriver';

export const API_SELECT_WORKBENCH_DRIVER_LIST =
  apiAddress + '/work/selectWorkDriverList';
