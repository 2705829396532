import { apiAddress } from '../ApiPlatform';

export const API_SELECT_ALARMTALK_TEMPLATE_LIST =
  apiAddress + 'talk/selectTalkTemplateList';
export const API_INSERT_ALARMTALK_TEMPLATE =
  apiAddress + 'talk/insertTalkTemplate';
export const API_UPDATE_ALARMTALK_TEMPLATE =
  apiAddress + 'talk/updateTalkTemplate';

export const API_SELECT_ALARMTALK_TEMPLATE =
  apiAddress + 'talk/selectTalkTemplate';

export const API_INSERT_ALARMTALK = apiAddress + 'talk/insertTalk';
export const API_SELECT_ALARMTALK_LIST = apiAddress + 'talk/selectTalkList';
