import React, { Component, Fragment } from 'react';
import { Grid, Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  COLOR_BLACK,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';
import moment from 'moment';

@inject(stores => ({
  depoStore: stores.DepositStore,
}))
@observer
class DepositHistoryList extends Component {
  render() {
    const { depoStore } = this.props;
    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>증감구분</Table.HeaderCell>
              <Table.HeaderCell>내용</Table.HeaderCell>
              <Table.HeaderCell>금액</Table.HeaderCell>
              <Table.HeaderCell>날짜</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {depoStore.depositHisDivList == null ||
          depoStore.depositHisDivList.length == 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="3" textAlign="center">
                  예치금 사용 내역이 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {depoStore.depositHisDivList.map((data, i) => (
                <React.Fragment>
                  <Table.Row>
                    <Table.Cell
                      style={{ color: data.calTyp == 'P' ? 'red' : 'blue' }}
                    >
                      {data.calTyp == 'P' ? '증액' : '감액'}
                    </Table.Cell>
                    <Table.Cell>{data.content}</Table.Cell>
                    <Table.Cell>
                      {data.balance
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(data.registDt).format('YYYY-MM-DD HH:mm')}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    );
  }
}

export default DepositHistoryList;
