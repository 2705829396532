import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button, Modal, Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import FreightAllList from './FreightAllList';
import FreightDivList from './FreightDivList';
import FreightManagementDetailContainer from './FreightManagementDetailContainer';
import FreightManagementUpdateContainer from './FreightManagementUpdateContainer';
import {
  API_DELETE_FREIGHT,
  API_UPDAGTE_FREIGHT_LOADING_DATE,
  API_UPDAGTE_FREIGHT_LOADING_DATE_MULTI,
  API_SELECT_FREIGHT_LIST,
} from '../../../common/ApiAddress/FreightAPI';
import {
  isCustomerMaster,
  isEmp,
  isLogisManager,
  isCustomer,
} from '../../../Util/CommonUtil';
import CommonConfirmModal from '../../../Component/Common/CommonConfirmModal';
import CorfirmModalForDelete from '../../../Component/Common/ConfirmModalForDelete';
import { STR_ALERT, STR_NO, STR_YES } from '../../../common/Terms';
@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
  payStore: stores.PaymentStore,
}))
@observer
class FreightManagementContainer extends Component {
  state = {
    detailView: false,
    updateView: false,
    searchTyp: null,
    searchKeyword: null,
    resultList: [],
    selectedFreightIndex: 0,
    updateLoadingDateMultiModal: false,
  };

  componentDidMount() {
    let path = this.props.location.pathname;
    let pathSplit = path.split('/');
    this.setState({ activeItem: pathSplit[pathSplit.length - 1] });
  }

  componentWillUnmount() {
    const { orderStore } = this.props;
    orderStore.initForm();
  }

  handleItemClick = (name, path) => {
    const { commonStore, orderStore } = this.props;
    this.setState({
      activeItem: name,
      searchTyp: null,
      searchKeyword: null,
      resultList: [],
    });

    let prevPath = this.props.location.pathname;
    let pathSplit = prevPath.split('/');

    if (name == pathSplit[pathSplit.length - 1]) {
      return false;
    } else {
      this.props.history.push(path);
      orderStore.initFreightList();
      orderStore.initSelectedFreight();
      orderStore.initFreightListCurrentPageNum();
      orderStore.initFreightListTotalPageNum();
      orderStore.initFreightListTotalCount();
      commonStore.setPath(path);
    }
  };

  fnDetailViewOpen = (value, i) => {
    const { orderStore } = this.props;
    orderStore.setSelectedFreight(value);
    this.setState({
      detailView: true,
      updateView: false,
      selectedFreightIndex: i,
    });
  };

  fnUpdateViewOpen = () => {
    const { orderStore, vcStore, payStore } = this.props;
    let selectedFreight = { ...orderStore.selectedFreight };
    orderStore.setLogisCd(selectedFreight.logisCd);
    orderStore.setFreightInfo(selectedFreight.freightInfo);
    orderStore.setVcCnt(selectedFreight.vcCnt);
    orderStore.setFreightWeight(selectedFreight.freightWeight);
    orderStore.setLoadingCd(selectedFreight.loadingCd);
    orderStore.setLoadingNm(selectedFreight.loadingCdNm);
    orderStore.setUnLoadingCd(selectedFreight.unloadingCd);
    orderStore.setUnLoadingNm(selectedFreight.unloadingCdNm);

    if (selectedFreight.loadingDate != undefined) {
      orderStore.setLoadingDate(
        new Date(selectedFreight.loadingDate.toString().replace('T', ' ')),
      );
    }

    if (selectedFreight.unloadingDate != undefined) {
      orderStore.setUnLoadingDate(
        new Date(selectedFreight.unloadingDate.toString().replace('T', ' ')),
      );
    }

    orderStore.setLogisFee(selectedFreight.logisFee);

    //상차지주소
    orderStore.setDepartureAdd(selectedFreight.loadingBasicAdd);
    if (
      selectedFreight.loadingDetailAdd == null ||
      selectedFreight.loadingDetailAdd == undefined
    ) {
      orderStore.initDepartureDetailAdd();
    } else {
      orderStore.setDepartureDetailAdd(selectedFreight.loadingDetailAdd);
    }

    orderStore.setDepartureCoord(selectedFreight.loadingCoord);
    //하차지주소
    orderStore.setArriveAdd(selectedFreight.unloadingBasicAdd);
    if (
      selectedFreight.unloadingDetailAdd == null ||
      selectedFreight.unloadingDetailAdd == undefined
    ) {
      orderStore.initArriveDetailAdd();
    } else {
      orderStore.setArriveDetailAdd(selectedFreight.unloadingDetailAdd);
    }
    orderStore.setArriveCoord(selectedFreight.unloadingCoord);
    //경유지주소
    if (selectedFreight.waypoint1BasicAdd != null) {
      orderStore.setWayPointsList(0, 'add', {
        basicAdd: selectedFreight.waypoint1BasicAdd,
        detailAdd: selectedFreight.waypoint1DetailAdd,
      });
    }
    if (selectedFreight.waypoint2BasicAdd != null) {
      orderStore.setWayPointsList(1, 'add', {
        basicAdd: selectedFreight.waypoint2BasicAdd,
        detailAdd: selectedFreight.waypoint2DetailAdd,
      });
    }
    if (selectedFreight.waypoint3BasicAdd != null) {
      orderStore.setWayPointsList(2, 'add', {
        basicAdd: selectedFreight.waypoint3BasicAdd,
        detailAdd: selectedFreight.waypoint3DetailAdd,
      });
    }

    //차량관련
    vcStore.setVcTypCd(selectedFreight.vcTypCd);
    vcStore.setVcWeightCd(selectedFreight.vcWeightCd);
    //결제관련
    payStore.setPaymentTo(selectedFreight.paymentTo);
    payStore.setPaymentTy(selectedFreight.paymentTy);
    this.setState({
      detailView: false,
      updateView: true,
    });

    //상하차지 담당자
    let loadingStaffNm = selectedFreight.loadingStaffInfo.split('|');
    if (
      loadingStaffNm[0] == null ||
      loadingStaffNm[0] == '' ||
      loadingStaffNm[0] == 'null'
    ) {
      orderStore.initLoadingStaffNm();
    } else {
      orderStore.setLoadingStaffNm(loadingStaffNm[0]);
    }
    if (
      loadingStaffNm[1] == null ||
      loadingStaffNm[1] == '' ||
      loadingStaffNm[1] == 'null'
    ) {
      orderStore.initLoadingStaffRank();
    } else {
      orderStore.setLoadingStaffRank(loadingStaffNm[1]);
    }

    if (
      selectedFreight.loadingStaffHpNo == null ||
      selectedFreight.loadingStaffHpNo == '' ||
      selectedFreight.loadingStaffHpNo == 'null'
    ) {
      orderStore.initLoadingStaffHpNo();
    } else {
      orderStore.setLoadingStaffHpNo(selectedFreight.loadingStaffHpNo);
    }

    let unloadingStaffNm = selectedFreight.unloadingStaffInfo.split('|');
    if (
      unloadingStaffNm[0] == null ||
      unloadingStaffNm[0] == '' ||
      unloadingStaffNm[0] == 'null'
    ) {
      orderStore.initUnLoadingStaffNm();
    } else {
      orderStore.setUnLoadingStaffNm(unloadingStaffNm[0]);
    }
    if (
      unloadingStaffNm[1] == null ||
      unloadingStaffNm[1] == '' ||
      unloadingStaffNm[1] == 'null'
    ) {
      orderStore.initUnLoadingStaffRank();
    } else {
      orderStore.setUnLoadingStaffRank(unloadingStaffNm[1]);
    }

    if (
      selectedFreight.unloadingStaffHpNo == null ||
      selectedFreight.unloadingStaffHpNo == '' ||
      selectedFreight.unloadingStaffHpNo == 'null'
    ) {
      orderStore.initUnLoadingStaffHpNo();
    } else {
      orderStore.setUnLoadingStaffHpNo(selectedFreight.loadingStaffHpNo);
    }
  };

  fnOnchangeSearchTyp = value => {
    this.setState({
      searchTyp: value,
    });
  };

  fnSetResultList = value => {
    this.setState({
      resultList: value,
    });
  };

  //단건 끌어 올리기 모달 열기
  fnUpdateLoadingDateModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.commonConfirmOpen(
      '선택된 운송건을 상단으로 끌어올리시겠습니까?',
    );
  };

  //단건 끌어 올리기 모달 열기
  fnUpdateLoadingDateModalClose = () => {
    const { commonStore } = this.props;
    commonStore.commonConfirmClose();
  };

  //단건 끌어올리기
  fnUpdateLoadingDate = () => {
    const { orderStore } = this.props;
    const params = {};
    const that = this;
    params.freightNo = orderStore.selectedFreight.freightNo;
    params.updateId = sessionStorage.getItem('customerId');
    axios({
      url: API_UPDAGTE_FREIGHT_LOADING_DATE,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.fnUpdateLoadingDateModalClose();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //모두 끌어올리기
  fnUpdateLoadingDateAll = () => {
    const { orderStore } = this.props;
    const that = this;
    let freightList = orderStore.freightList;
    let paramList = [];
    let params = {};
    for (var i = 0; i < freightList.length; i++) {
      params = {};
      params.freightNo = freightList[i].freightNo;
      params.updateId = sessionStorage.getItem('customerId');
      paramList.push(params);
    }
    axios({
      url: API_UPDAGTE_FREIGHT_LOADING_DATE_MULTI,
      method: 'post',
      data: paramList,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.setState({
            updateLoadingDateMultiModal: false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //운송 취소 모달 열기
  fnDeleteFreightModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForDeleteOpen('선택된 운송건을 취소하시겠습니까?');
  };

  //운송 취소 모달 닫기
  fnDeleteFreightModalClose = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForDeleteClose();
  };

  //운송취소
  fnDeleteFreight = () => {
    const { orderStore } = this.props;
    const that = this;
    let params = {};
    params.freightNo = orderStore.selectedFreight.freightNo;
    params.updateId = sessionStorage.getItem('customerId');

    axios({
      url: API_DELETE_FREIGHT,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpdateViewClose = () => {
    this.setState({
      detailView: true,
      updateView: false,
    });
    this.fnSelectFreightList();

    let prevPath = this.props.location.pathname;
    let pathSplit = prevPath.split('/');

    this.handleItemClick(
      pathSplit[pathSplit.length - 1],
      pathSplit[pathSplit.length - 1],
    );
  };

  //운송현황 불러오기(전체)
  fnSelectFreightList = () => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;

    if (isEmp()) {
      params.registId = 'emp';
    }
    if (isCustomerMaster()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
      params.groupNo = sessionStorage.getItem('groupNo');
    }
    if (isLogisManager()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
      params.groupNo = sessionStorage.getItem('groupNo');
    }

    if (isCustomer()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
    }

    params.startCnt =
      orderStore.freightListCurrentPageNum == 1
        ? 0
        : 50 * (Number(orderStore.freightListCurrentPageNum) - 1) + 1;

    if (orderStore.freightListTotalPageNum == 0) {
      params.endCnt = 50;
    } else if (
      orderStore.freightListTotalPageNum > 0 &&
      orderStore.freightListTotalCount -
        50 * orderStore.freightListCurrentPageNum >=
        0
    ) {
      params.endCnt = 50;
    } else if (
      orderStore.freightListTotalPageNum > 0 &&
      orderStore.freightListTotalCount -
        50 * orderStore.freightListCurrentPageNum <
        0
    ) {
      params.endCnt =
        orderStore.freightListTotalCount -
        50 * (orderStore.freightListCurrentPageNum - 1);
    }

    axios({
      url: API_SELECT_FREIGHT_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let pageNum = Math.ceil(response.data.totalPageNum / 50);
            orderStore.setFreightList(response.data.data);
            orderStore.setFreightListTotalCount(response.data.totalPageNum);
            orderStore.setFreightListTotalPageNum(pageNum);
            that.fnDetailViewOpen(
              response.data.data[that.state.selectedFreightIndex],
              that.state.selectedFreightIndex,
            );
          } else {
            orderStore.initFreightList();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpdateLoadingDateAllModalOpen = () => {
    this.setState({
      updateLoadingDateMultiModal: true,
    });
  };

  render() {
    const { orderStore } = this.props;
    let currentPage;

    switch (this.props.location.pathname) {
      case '/freight/all':
        currentPage = (
          <FreightAllList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            resultList={this.state.resultList}
            pathname={this.props.location.pathname}
            fnDetailViewOpen={this.fnDetailViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
            fnSetResultList={this.fnSetResultList}
            fnSelectFreightList={this.fnSelectFreightList}
          />
        );
        break;
      case '/freight/reserved':
        currentPage = (
          <FreightDivList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            resultList={this.state.resultList}
            pathname={this.props.location.pathname}
            fnDetailViewOpen={this.fnDetailViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
            fnSetResultList={this.fnSetResultList}
            fnUpdateLoadingDateAllModalOpen={
              this.fnUpdateLoadingDateAllModalOpen
            }
          />
        );
        break;
      case '/freight/rescomplete':
        currentPage = (
          <FreightDivList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            resultList={this.state.resultList}
            pathname={this.props.location.pathname}
            fnDetailViewOpen={this.fnDetailViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
            fnSetResultList={this.fnSetResultList}
          />
        );
        break;
      case '/freight/transporting':
        currentPage = (
          <FreightDivList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            resultList={this.state.resultList}
            pathname={this.props.location.pathname}
            fnDetailViewOpen={this.fnDetailViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
            fnSetResultList={this.fnSetResultList}
          />
        );
        break;
      case '/freight/transcomplete':
        currentPage = (
          <FreightDivList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            resultList={this.state.resultList}
            pathname={this.props.location.pathname}
            fnDetailViewOpen={this.fnDetailViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
            fnSetResultList={this.fnSetResultList}
          />
        );
        break;
      case '/freight/transdraw':
        currentPage = (
          <FreightDivList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            resultList={this.state.resultList}
            pathname={this.props.location.pathname}
            fnDetailViewOpen={this.fnDetailViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
            fnSetResultList={this.fnSetResultList}
          />
        );
        break;
      case '/freight/transcancle':
        currentPage = (
          <FreightDivList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            resultList={this.state.resultList}
            pathname={this.props.location.pathname}
            fnDetailViewOpen={this.fnDetailViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
            fnSetResultList={this.fnSetResultList}
          />
        );
        break;
      default:
        currentPage = (
          <FreightAllList
            searchTyp={this.state.searchTyp}
            searchKeyword={this.state.searchKeyword}
            resultList={this.state.resultList}
            pathname={this.props.location.pathname}
            fnDetailViewOpen={this.fnDetailViewOpen}
            fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
            fnSetResultList={this.fnSetResultList}
            fnSelectFreightList={this.fnSelectFreightList}
          />
        );
        break;
    }

    const panes = [
      {
        menuItem: {
          content: '전체',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {viewPage()}
          </Tab.Pane>
        ),
        pane: { name: 'all', path: '/freight/all' },
      },
      {
        menuItem: {
          content: '예약대기',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {viewPage()}
          </Tab.Pane>
        ),
        pane: { name: 'reserved', path: '/freight/reserved' },
      },
      {
        menuItem: {
          content: '예약완료',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {viewPage()}
          </Tab.Pane>
        ),
        pane: { name: 'rescomplete', path: '/freight/rescomplete' },
      },
      {
        menuItem: {
          content: '운송중',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {viewPage()}
          </Tab.Pane>
        ),
        pane: { name: 'transporting', path: '/freight/transporting' },
      },
      {
        menuItem: {
          content: '운송완료',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {viewPage()}
          </Tab.Pane>
        ),
        pane: { name: 'transcomplete', path: '/freight/transcomplete' },
      },
      {
        menuItem: {
          content: '운송보류',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {viewPage()}
          </Tab.Pane>
        ),
        pane: { name: 'transdraw', path: '/freight/transdraw' },
      },
      {
        menuItem: {
          content: '운송취소',
          color: 'green',
          fontSize: '15px',
        },
        render: () => (
          <Tab.Pane attached={false} style={{ height: 'calc(100vh - 54px)' }}>
            {viewPage()}
          </Tab.Pane>
        ),
        pane: { name: 'transcancle', path: '/freight/transcancle' },
      },
    ];

    const viewPage = () => {
      return (
        <Fragment>
          <div
            style={{
              width: this.state.updateView == false ? '50%' : '0px',
              height: '100%',
              float: 'left',
              overflow: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {currentPage}
          </div>
          <div
            style={{
              width: this.state.updateView == false ? '50%' : '100%',
              height:
                this.state.updateView == false ? '100%' : 'calc(100vh - 84px)',
              float: 'left',
              //overflow: 'scroll',
              overflowX: 'hidden',
            }}
          >
            {this.state.detailView == true && this.state.updateView == false ? (
              <FreightManagementDetailContainer
                activeItem={this.state.activeItem}
                fnUpdateLoadingDate={this.fnUpdateLoadingDate}
                fnUpdateViewOpen={this.fnUpdateViewOpen}
                fnDeleteFreightModalOpen={this.fnDeleteFreightModalOpen}
                fnUpdateLoadingDateModalOpen={this.fnUpdateLoadingDateModalOpen}
              />
            ) : null}
            {this.state.updateView == true && this.state.detailView == false ? (
              <FreightManagementUpdateContainer
                updateView={this.state.updateView}
                fnUpdateViewClose={this.fnUpdateViewClose}
              />
            ) : null}
          </div>
        </Fragment>
      );
    };

    //운송현황 , 운송상태별 리스트
    return (
      <React.Fragment>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          onTabChange={(e, data) => {
            this.handleItemClick(
              data.panes[data.activeIndex].pane.name,
              data.panes[data.activeIndex].pane.path,
            );
          }}
          style={{ color: 'red' }}
        />
        <CorfirmModalForDelete
          fnConfirmNo={this.fnDeleteFreightModalClose}
          fnConfirmYes={this.fnDeleteFreight}
        />
        {/* 단건 끌어올리기 모달 */}
        <CommonConfirmModal
          fnConfirmNo={this.fnUpdateLoadingDateModalClose}
          fnConfirmYes={this.fnUpdateLoadingDate}
        />

        <Modal
          closeOnDimmerClick={false}
          size="tiny"
          open={this.state.updateLoadingDateMultiModal}
          onClose={() =>
            this.setState({
              updateLoadingDateMultiModal: false,
            })
          }
          style={{ height: '250px' }}
        >
          <Modal.Header>{STR_ALERT}</Modal.Header>
          <Modal.Content>
            <p style={{ fontSize: '16pt' }}>
              예약대기 중인 모든 운송건을 상단으로 끌어 올리시겠습니까?
            </p>
          </Modal.Content>
          <div
            style={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
          >
            <Button
              negative
              onClick={() =>
                this.setState({
                  updateLoadingDateMultiModal: false,
                })
              }
            >
              {STR_NO}
            </Button>
            <Button positive onClick={() => this.fnUpdateLoadingDateAll()}>
              {STR_YES}
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(FreightManagementContainer);
