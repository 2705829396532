import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Menu, Button, Select, Form, Input } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { API_SELECT_DRIVER_SEARCH_LIST } from '../../../common/ApiAddress/AccountAPI';
import {
  API_SELECT_DEPOSIT_HIS_DIV,
  API_UPSERT_DEPOSIT,
  API_SELECT_DEPOSIT_TOTAL_BALANCE,
  API_SELECT_DEPOSIT_HIS,
} from '../../../common/ApiAddress/DepositAPI';
import SearchInput from '../../../Component/Management/Deposit/SearchInput';
import UserInfo from '../../../Component/Management/Deposit/UserInfo';
import DepositUpdateModal from './DepositUpdateModal';
import DepositHistoryList from '../../../Component/Management/Deposit/DepositHistoryList';
import DepositHistoryTotalList from '../../../Component/Management/Deposit/DepositHistoryTotalList';
@inject(stores => ({
  depoStore: stores.DepositStore,
  accStore: stores.AccStore,
}))
@observer
class DepositContainer extends Component {
  state = {
    searchTyp: null,
    searchKeyword: null,
    resultList: [],
    updateModal: false,
    updateTyp: null,
    historyView: false,
    totalHistoryView: false,
  };

  componentDidMount() {
    this.fnSelectTotalDeposit();
  }

  //전체 예치금 불러오기
  fnSelectTotalDeposit = () => {
    const { depoStore } = this.props;
    let params = {};
    let that = this;
    axios({
      url: API_SELECT_DEPOSIT_TOTAL_BALANCE,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          depoStore.setTotalBalance(response.data.data.balance);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //자동완성 트럭커 리스트
  getDriverList = (searchKeyword, searchTyp) => {
    let params = {};
    let that = this;
    params.searchKeyword = searchKeyword;
    params.searchTyp = searchTyp;
    axios({
      url: API_SELECT_DRIVER_SEARCH_LIST,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.setState({
            resultList: response.data.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetSearchDriver = data => {
    const { depoStore } = this.props;
    console.log(data);
    depoStore.setSearchDriverInfo(data);
  };

  fnDepositUpdateModalOpen = () => {
    this.setState({
      updateModal: true,
    });
  };

  fnDepositUpdateModalClose = () => {
    this.setState({
      updateModal: false,
    });
  };

  fnSetUpdateTyp = value => {
    this.setState({
      updateTyp: value,
    });
  };

  //예치금 upsert
  fnUpsertDeposit = () => {
    const { depoStore } = this.props;
    let params = {};
    let that = this;
    params.driverNo = depoStore.searchDriverInfo.driverno;
    params.driverId =
      this.state.searchTyp == 'A'
        ? depoStore.searchDriverInfo.title
        : depoStore.searchDriverInfo.accid;
    params.balance = Number(depoStore.balance);
    params.registId = sessionStorage.getItem('empId');
    params.calTyp = this.state.updateTyp;
    params.content = depoStore.content;

    axios({
      url: API_UPSERT_DEPOSIT,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //회원별 예치금 내역 조회
  fnSelectDepositHisDiv = () => {
    const { depoStore } = this.props;
    let params = {};
    let that = this;
    params.driverId =
      this.state.searchTyp == 'A'
        ? depoStore.searchDriverInfo.title
        : depoStore.searchDriverInfo.accid;

    axios({
      url: API_SELECT_DEPOSIT_HIS_DIV,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.setState({
            historyView: true,
          });
          depoStore.setDepositHisDivList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //전체 예치금 내역 조회
  fnSelectDepositHis = () => {
    const { depoStore } = this.props;
    let params = {};
    let that = this;

    axios({
      url: API_SELECT_DEPOSIT_HIS,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.setState({
            totalHistoryView: true,
          });
          depoStore.setDepositHisList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { depoStore } = this.props;
    const searchTypList = [
      { text: '이름', value: 'N' },
      { text: '아이디', value: 'A' },
    ];

    return (
      <React.Fragment>
        <div
          style={{
            width: '50%',
            height: '100vh',
            overflow: 'scroll',
            overflowX: 'hidden',
            padding: '10px',
            float: 'left',
          }}
        >
          <Fragment>
            <div>
              <Form style={{ width: '100%', marginTop: '10px' }}>
                <Form.Field
                  style={{
                    width: '100%',
                  }}
                >
                  <Select
                    style={{
                      minWidth: '122px',
                      maxWidth: '122px',
                      marginRight: '10px',
                      float: 'left',
                    }}
                    placeholder={'검색조건 선택'}
                    options={searchTypList}
                    onChange={(e, { value }) => [
                      depoStore.initSearchDriverInfo(),
                      depoStore.initDepositHisList(),
                      this.setState({
                        searchTyp: value,
                        searchKeyword: null,
                        historyView: false,
                      }),
                    ]}
                  />

                  <SearchInput
                    searchTyp={this.state.searchTyp}
                    activeItem={this.state.activeItem}
                    getDriverList={this.getDriverList}
                    resultList={this.state.resultList}
                    fnSetSearchDriver={this.fnSetSearchDriver}
                  />
                </Form.Field>
              </Form>
            </div>
            {depoStore.searchDriverInfo == null ? null : (
              <Fragment>
                <UserInfo searchTyp={this.state.searchTyp} />
                <div style={{ textAlign: 'center' }}>
                  <Button
                    color="facebook"
                    style={{ marginBottom: '10px' }}
                    onClick={() => this.fnSelectDepositHisDiv()}
                  >
                    예치금 내역 조회
                  </Button>
                  <Button
                    positive
                    style={{ marginBottom: '10px' }}
                    onClick={() => {
                      this.fnDepositUpdateModalOpen();
                    }}
                  >
                    예치금 변경
                  </Button>
                </div>
                {this.state.historyView == false ? null : (
                  <DepositHistoryList />
                )}
              </Fragment>
            )}
          </Fragment>
        </div>
        <div
          style={{
            width: '50%',
            height: '100vh',
            overflow: 'scroll',
            overflowX: 'hidden',
            padding: '10px',
          }}
        >
          <h2>예치금 전체 현황</h2>
          {depoStore.totalBalance != null ? (
            <h3>
              금액 :{' '}
              {depoStore.totalBalance
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
            </h3>
          ) : null}

          <Button
            color="facebook"
            style={{ marginBottom: '10px' }}
            onClick={() => this.fnSelectDepositHis()}
          >
            예치금 젠체 내역 조회
          </Button>
          {this.state.totalHistoryView == false ? null : (
            <DepositHistoryTotalList />
          )}
        </div>
        <DepositUpdateModal
          updateModal={this.state.updateModal}
          fnDepositUpdateModalClose={this.fnDepositUpdateModalClose}
          fnSetUpdateTyp={this.fnSetUpdateTyp}
          fnUpsertDeposit={this.fnUpsertDeposit}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(DepositContainer);
