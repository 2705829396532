import { apiAddress } from '../ApiPlatform';

export const API_INSERT_FAVORITE_CATE = apiAddress + '/favorite/insertFavoCate';
export const API_UPDATE_FAVORITE_CATE = apiAddress + '/favorite/updateFavoCate';
export const API_SELECT_FAVORITE_CATE_LIST =
  apiAddress + '/favorite/selectFavoCateList';

export const API_INSERT_FAVORITE = apiAddress + '/favorite/insertFavorite';
export const API_SELECT_FAVORITE_LIST =
  apiAddress + '/favorite/selectFavoriteList';
export const API_UPDATE_FAVORITE = apiAddress + '/favorite/updateFavorite';
export const API_DELETE_FAVORITE_CATE = apiAddress + 'favorite/deleteFavoCate';
export const API_DELETE_FAVORITE = apiAddress + 'favorite/deleteFavorite';
