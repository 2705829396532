import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import {
  COLOR_GRAY_GR02,
  COLOR_WHITE,
  COLOR_GREEN_PRIMARY,
} from '../../../common/Color';
import { Button, Form, Input, Select, Table } from 'semantic-ui-react';
@inject(stores => ({
  wborderStore: stores.WorkbenchOrderStore,
}))
@observer
class WorkBenchOrderDetail extends Component {
  render() {
    const { wborderStore } = this.props;
    const freightCdList = [
      { text: '예약확정', value: 'IFRCD001' },
      { text: '배차완료', value: 'IFRCD002' },
      { text: '예약취소', value: 'IFRCD011' },
      { text: '배차취소', value: 'IFRCD012' },
    ];
    return (
      <div>
        <Table style={{ border: '0px', marginTop: '15px' }}>
          <Table.Header style={{ border: '0px' }}>
            <Table.Row>
              <Table.HeaderCell colSpan="6" style={{ background: '#fff' }}>
                <div style={{ float: 'right' }}>
                  <Button
                    color="orange"
                    onClick={() => {
                      this.props.fnCompSelectModalOpen();
                    }}
                  >
                    거래처선택
                  </Button>
                  <Button
                    color="violet"
                    onClick={() => {
                      this.props.fnMsgCopyForCustomer();
                    }}
                  >
                    화주SMS복사
                  </Button>
                  <Button
                    color="teal"
                    onClick={() => {
                      this.props.fnGetMsgAuthForCustomer();
                    }}
                  >
                    화주SMS
                  </Button>
                  <Button
                    color="brown"
                    onClick={() => this.props.fnGetMsgAuth()}
                  >
                    기사SMS
                  </Button>
                  <Button
                    color="black"
                    onClick={() => {
                      this.props.fnGetMsgAuthForTruckerInvoice();
                    }}
                  >
                    기사송장안내문자
                  </Button>
                  <Button
                    color="green"
                    onClick={() => {
                      this.props.fnUpdateFreight();
                    }}
                  >
                    수정
                  </Button>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>발주처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="발주처"
                  value={wborderStore.client || ''}
                  onChange={e => {
                    wborderStore.setClient(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>발주처
                담당자
              </Table.Cell>
              <Table.Cell>
                <Form>
                  <Form.Field>
                    <input
                      placeholder="발주처 담당자"
                      onChange={e =>
                        wborderStore.setClientStaff(e.target.value)
                      }
                      value={wborderStore.clientStaff || ''}
                    />
                  </Form.Field>
                </Form>
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>발주처
                연락처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="발주처 연락처"
                  value={wborderStore.clientStaffHpNo || ''}
                  onChange={e => {
                    wborderStore.setClientStaffHpNo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>운송상태
              </Table.Cell>
              <Table.Cell>
                <Form.Field>
                  <Select
                    key={'lo'}
                    style={{
                      //maxWidth: '190px',
                      //minWidth: '100px',
                      marginRight: '9px',
                    }}
                    defaultValue={wborderStore.freightCd}
                    options={freightCdList}
                    onChange={(e, data) =>
                      wborderStore.setFreightCd(data.value)
                    }
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>결제방법
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="결제방법"
                  value={wborderStore.payCd || ''}
                  onChange={e => {
                    wborderStore.setPayCd(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지
                상호명
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차지 상호명"
                  value={wborderStore.loadingCompNm || ''}
                  onChange={e => {
                    wborderStore.setLoadingCompNm(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지 주소
              </Table.Cell>
              <Table.Cell colSpan="3">
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차지 주소"
                  value={wborderStore.loadingAdd || ''}
                  onChange={e => {
                    wborderStore.setLoadingAdd(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지
                담당자
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차지 담당자"
                  value={wborderStore.loadingStaff || ''}
                  onChange={e => {
                    wborderStore.setLoadingStaff(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차지
                연락처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차지 연락처"
                  value={wborderStore.loadingStaffHpNo || ''}
                  onChange={e => {
                    wborderStore.setLoadingStaffHpNo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지
                상호명
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차지 상호명"
                  value={wborderStore.unloadingCompNm || ''}
                  onChange={e => {
                    wborderStore.setUnloadingCompNm(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지 주소
              </Table.Cell>
              <Table.Cell colSpan="3">
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차지 주소"
                  value={wborderStore.unloadingAdd || ''}
                  onChange={e => {
                    wborderStore.setUnloadingAdd(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지
                담당자
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차지 담당자"
                  value={wborderStore.unloadingStaff || ''}
                  onChange={e => {
                    wborderStore.setUnloadingStaff(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차지
                연락처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차지 연락처"
                  value={wborderStore.unloadingStaffHpNo || ''}
                  onChange={e => {
                    wborderStore.setUnloadingStaffHpNo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>톤수
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차량 톤수"
                  value={wborderStore.vcWeight || ''}
                  onChange={e => {
                    wborderStore.setVcWeight(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량 종류
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차량 종류"
                  value={wborderStore.vcTyp || ''}
                  onChange={e => {
                    wborderStore.setVcTyp(e.target.value);
                  }}
                  setVcTyp
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>중량
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="중량"
                  value={wborderStore.freightWeight || ''}
                  onChange={e => {
                    wborderStore.setFreightWeight(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>화물정보
              </Table.Cell>
              <Table.Cell colSpan="3">
                <Input
                  style={{ width: '100%' }}
                  placeholder="화묾정보"
                  value={wborderStore.freightInfo || ''}
                  onChange={e => {
                    wborderStore.setFreightInfo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>특이사항
              </Table.Cell>
              <Table.Cell colSpan="3">
                <Input
                  style={{ width: '100%' }}
                  placeholder="특이사항"
                  value={wborderStore.freightMemo || ''}
                  onChange={e => {
                    wborderStore.setFreightMemo(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차일자
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차일자"
                  value={wborderStore.loadingDate || ''}
                  onChange={e => {
                    wborderStore.setLoadingDate(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>상차시간
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="상차시간"
                  value={wborderStore.loadingTime || ''}
                  onChange={e => {
                    wborderStore.setLoadingTime(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차일자
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차일자"
                  value={wborderStore.unloadingDate || ''}
                  onChange={e => {
                    wborderStore.setUnloadingDate(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>하차시간
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="하차시간"
                  value={wborderStore.unloadingTime || ''}
                  onChange={e => {
                    wborderStore.setUnloadingTime(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차주이름
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차주이름"
                  value={wborderStore.driverNm || ''}
                  onChange={e => {
                    this.props.fnAutoCompleteVcInfo(e.target.value);
                    //wborderStore.setLoadingDate(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차주연락처
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차주연락처"
                  value={wborderStore.driverHpNo || ''}
                  disabled
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차량번호
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차량번호"
                  value={wborderStore.vcNo || ''}
                  disabled
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차주톤수
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차주톤수"
                  value={wborderStore.driverVcWeight || ''}
                  onChange={e => {
                    wborderStore.setDriverVcWeight(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차주차종
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차주차종"
                  value={wborderStore.driverVcTyp || ''}
                  onChange={e => {
                    wborderStore.setDriverVcTyp(e.target.value);
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>차주운임
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="차주운임"
                  value={wborderStore.logisFee || ''}
                  onChange={e => {
                    this.props.fnSetLogisFee(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>수수료
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: '100%' }}
                  placeholder="수수료"
                  value={wborderStore.commission || ''}
                  onChange={e => {
                    this.props.fnSetCommission(e.target.value);
                  }}
                />
              </Table.Cell>
              <Table.Cell collapsing>
                <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>총운임
              </Table.Cell>
              <Table.Cell>{wborderStore.totalFee}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
export default WorkBenchOrderDetail;
