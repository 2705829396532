//담당자 관리
import StaffManagementContainer from './Staff/StaffManagementContainer';
import StaffList from './Staff/StaffList';

//공지사항
import NotiManagementContainer from './Notification/NotiManagementContainer';

//운임표관리
import FreightFeeManagementContainer from './FreightFee/FreightFeeManagementContainer';

//문의사항
import QuestionManagementContainer from './Question/QuestionManagementContainer';

//환경설정
import ConfigContainer from './Config/ConfigContainer';

export const SettingIndex = {
  //담당자 관리
  StaffManagementContainer,
  StaffList,
  NotiManagementContainer,
  FreightFeeManagementContainer,
  QuestionManagementContainer,
  ConfigContainer,
};
