import React, { Component } from 'react';
import { Icon, Table, Accordion } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class CurrentList extends Component {
  render() {
    const { accStore } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>이름</Table.HeaderCell>
            <Table.HeaderCell>아이디</Table.HeaderCell>
            <Table.HeaderCell>승인여부</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {accStore.driverList == null || accStore.driverList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                회원이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {accStore.driverList.map((data, i) => (
              <React.Fragment key={data.driverNo}>
                <Table.Row onClick={() => this.props.fnViewOpen(data)}>
                  <Table.Cell>{data.driverNm}</Table.Cell>
                  <Table.Cell>{data.driverId}</Table.Cell>
                  <Table.Cell>{data.apvYn}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default CurrentList;
