import React, { Component, Fragment } from 'react';
import { Grid, Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  COLOR_BLACK,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  favoStore: stores.FavoriteStore,
}))
@observer
class FavoriteCateList extends Component {
  render() {
    const { favoStore } = this.props;
    return (
      <div style={{ padding: '10px' }}>
        <Table style={{ border: '0px' }}>
          <Table.Header style={{ border: '0px' }}>
            <Table.Row>
              <Table.HeaderCell style={{ background: '#fff' }}>
                순번
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: '#fff' }}>
                분류명
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {favoStore.cateList == null || favoStore.cateList.length == 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="3" textAlign="center">
                  등록된 분류가 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {favoStore.cateList.map((data, i) => (
                <React.Fragment key={'catelist' + i}>
                  <Table.Row
                    onClick={() => this.props.fnSetCate(data, i)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Table.Cell
                      style={{
                        backgroundColor:
                          favoStore.selectedCate != null &&
                          favoStore.selectedCate.cateNo === data.cateNo
                            ? COLOR_GREEN_PRIMARY
                            : COLOR_WHITE,
                        color:
                          favoStore.selectedCate != null &&
                          favoStore.selectedCate.cateNo === data.cateNo
                            ? COLOR_WHITE
                            : COLOR_BLACK,
                      }}
                    >
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor:
                          favoStore.selectedCate != null &&
                          favoStore.selectedCate.cateNo === data.cateNo
                            ? COLOR_GREEN_PRIMARY
                            : COLOR_WHITE,
                        color:
                          favoStore.selectedCate != null &&
                          favoStore.selectedCate.cateNo === data.cateNo
                            ? COLOR_WHITE
                            : COLOR_BLACK,
                      }}
                    >
                      {data.cateNm}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    );
  }
}

export default FavoriteCateList;
