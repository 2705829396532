import React, { Component, Fragment } from 'react';
import { ReactComponent as B2BTop } from '../../assets/icon/b2b_top.svg';
import { ReactComponent as B2BTopMo } from '../../assets/icon/b2b_top_mo.svg';
import { ReactComponent as B2BFormArrow } from '../../assets/icon/b2b_form_arrow.svg';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import {
  COLOR_GRAY_GR01,
  COLOR_GRAY_GR00,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import { Checkbox, Form, Input, TextArea } from 'semantic-ui-react';
import { API_SEND_QUOTE_FORM } from '../../common/ApiAddress/QuoteAPI';
import MediaQuery from 'react-responsive';
import CommonAlert from '../../Component/Common/CommonAlert';
@inject(stores => ({
  quoteStore: stores.QuoteStore,
  commonStore: stores.CommonStore,
}))
@observer
class B2BContact extends Component {
  componentDidMount() {
    const { quoteStore } = this.props;
    quoteStore.initB2BContactForm();
    this.props.fnFreightFormInit();
  }

  handleMonthlyCount = (e, { value }) => {
    const { quoteStore } = this.props;
    quoteStore.setMonthCountValue(value);
  };

  fnSendB2BContact = () => {
    const { quoteStore, commonStore } = this.props;

    let params = {};
    let that = this;

    if (quoteStore.companyNm == null || quoteStore.companyNm == '') {
      commonStore.commonAlertOpen('회사명을 작성해 주세요.');
      return false;
    }

    if (quoteStore.companyEmail == null || quoteStore.companyEmail == '') {
      commonStore.commonAlertOpen('이메일을 작성해 주세요.');
      return false;
    }

    if (quoteStore.requestNm == null || quoteStore.requestNm == '') {
      commonStore.commonAlertOpen('담당자명을 작성해 주세요.');
      return false;
    }

    if (quoteStore.requestHpNo == null || quoteStore.requestHpNo == '') {
      commonStore.commonAlertOpen('연락처를 작성해 주세요.');
      return false;
    }

    if (quoteStore.feightInfo == null || quoteStore.feightInfo == '') {
      commonStore.commonAlertOpen('운송품목을 작성해 주세요.');
      return false;
    }

    if (quoteStore.requestContent == null || quoteStore.requestContent == '') {
      commonStore.commonAlertOpen('문의사항을 작성해 주세요.');
      return false;
    }

    params.companyNm = quoteStore.companyNm;
    params.companyEmail = quoteStore.companyEmail;
    params.requestNm = quoteStore.requestNm;
    params.requestHpNo = quoteStore.requestHpNo;
    params.localeList = quoteStore.localeList.join();
    params.vcWeightList = quoteStore.vcWeightList.join();
    params.vcTypList = quoteStore.vcTypList.join();
    params.monthCountValue = quoteStore.monthCountValue;
    params.feightInfo = quoteStore.feightInfo;
    params.requestContent = quoteStore.requestContent;

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgAuth',
      method: 'post',
      data: params,
    })
      .then(response => {
        that.fnSendMsg(params, response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //메세지 전송
  fnSendMsg = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];

    let content = '기업신용거래문의 \n ●회사명\n' + msgData.companyNm + '\n';
    content = content + '●이메일\n' + msgData.companyEmail + '\n';
    content = content + '●담당자명 : ' + msgData.requestNm + '\n';
    content = content + '●연락처 : ' + msgData.requestHpNo + '\n';
    content = content + '●이용지역 : ' + msgData.localeList + '\n';
    content = content + '●이용차량톤수 : ' + msgData.vcWeightList + '\n';
    content = content + '●이용차량종류 : ' + msgData.vcTypList + '\n';
    content = content + '●월이용횟수 : ' + msgData.monthCountValue + '\n';
    content = content + '●운송품목 : ' + msgData.feightInfo + '\n';
    content = content + '●문의사항 : ' + msgData.requestContent + '\n';

    phone.push('01090710205');
    id.push('ttSystem');
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    console.log(params);

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgSendLms',
      method: 'get',
      params: params,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.code == '1000') {
          that.fnSendEmailForm();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSendEmailForm = () => {
    const { quoteStore } = this.props;
    const params = {};
    const that = this;
    params.companyNm = quoteStore.companyNm;
    params.companyEmail = quoteStore.companyEmail;
    params.requestNm = quoteStore.requestNm;
    params.requestHpNo = quoteStore.requestHpNo;
    params.localeList = quoteStore.localeList.join();
    params.vcWeightList = quoteStore.vcWeightList.join();
    params.vcTypList = quoteStore.vcTypList.join();
    params.monthCountValue = quoteStore.monthCountValue;
    params.feightInfo = quoteStore.feightInfo;
    params.requestContent = quoteStore.requestContent;

    console.log(params);

    axios({
      url: API_SEND_QUOTE_FORM,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { quoteStore } = this.props;
    const localeListObj = [
      {
        text: '서울특별시',
        value: '서울특별시',
      },
      {
        text: '경기도',
        value: '경기도',
      },
      {
        text: '인천광역시',
        value: '인천광역시',
      },
      {
        text: '그 외 지역',
        value: '그 외 지역',
      },
    ];

    const vcWeightListObj = [
      {
        text: '0.3톤',
        value: '0.3톤',
      },
      {
        text: '0.5톤',
        value: '0.5톤',
      },
      {
        text: '1톤',
        value: '1톤',
      },
      {
        text: '1.4톤',
        value: '1.4톤',
      },
      {
        text: '2.5톤',
        value: '2.5톤',
      },
      {
        text: '3.5톤',
        value: '3.5톤',
      },
      {
        text: '3.5톤 광폭',
        value: '3.5톤 광폭',
      },
      {
        text: '5톤',
        value: '5톤',
      },
      {
        text: '5톤 플러스',
        value: '5톤 플러스',
      },
      {
        text: '5톤 축',
        value: '5톤 축',
      },
      {
        text: '5톤 풀축',
        value: '5톤 풀축',
      },
      {
        text: '8톤',
        value: '8톤',
      },
      {
        text: '11톤',
        value: '11톤',
      },
      {
        text: '14톤',
        value: '14톤',
      },
      {
        text: '18톤',
        value: '18톤',
      },
      {
        text: '25톤',
        value: '25톤',
      },
    ];

    const vcTypListObj = [
      {
        text: '다마스',
        value: '다마스',
      },
      {
        text: '라보',
        value: '라보',
      },
      {
        text: '라보호루',
        value: '라보호루',
      },
      {
        text: '카고',
        value: '카고',
      },
      {
        text: '윙바디',
        value: '윙바디',
      },
      {
        text: '리프트',
        value: '리프트',
      },
      {
        text: '윙바디 리프트',
        value: '윙바디 리프트',
      },
      {
        text: '호루 리프트',
        value: '호루 리프트',
      },
      {
        text: '냉장탑',
        value: '냉장탑',
      },
      {
        text: '냉동탑',
        value: '냉동탑',
      },
    ];

    const monthlyCount = [
      { text: '1~10회', value: '1~10회' },
      { text: '11~50회', value: '11~50회' },
      { text: '51~100회', value: '51~100회' },
      { text: '100회 이상', value: '100회 이상' },
    ];

    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div
            id="specTable"
            style={{
              width: '100%',
              height: 'calc(100vh - 210px)',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                width: '100%',
                position: 'relative',
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: 'fit-content',
                  verticalAlign: 'middle',
                }}
                src={'../../image/b2b_top_mo.png'}
              />
              {/* <B2BTopMo
                style={{
                  width: '100%',
                  height: 'fit-content',
                  verticalAlign: 'middle',
                }}
              /> */}
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  textAlign: 'center',
                  letterSpacing: '-1px',
                  transform: 'translate( -50%, -50% )',
                }}
              >
                <p
                  style={{
                    color: COLOR_WHITE,
                    fontSize: '20px',
                    fontWeight: '700',
                    marginBottom: '0px',
                  }}
                >
                  기업 신용거래 안내
                </p>
              </div>
            </div>
            <div
              style={{
                paddingTop: '21px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                background: COLOR_GRAY_GR01,
                marginTop: '-5px',
              }}
            >
              <p style={{ fontSize: '12px', fontWeight: '400' }}>
                다마스, 라보, 용달 / 기업 계약거래
              </p>
              <p style={{ fontSize: '22px', fontWeight: '700' }}>
                이제 쉽고 간단하게!
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '21px',
                }}
              >
                <div
                  className="b2bTopCirle"
                  style={{
                    borderRadius: '100px',
                    padding: '10px',
                    border: '1px solid',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '14px',
                    background: COLOR_WHITE,
                  }}
                >
                  <p style={{ fontWeight: '400', fontSize: '12px' }}>
                    운송비 조회
                  </p>
                </div>
                <B2BFormArrow style={{ marginRight: '14px' }} />
                <div
                  className="b2bTopCirle"
                  style={{
                    borderRadius: '100px',
                    padding: '10px',
                    border: '1px solid',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '14px',
                    position: 'relative',
                    background: COLOR_WHITE,
                  }}
                >
                  <p style={{ fontWeight: '400', fontSize: '12px' }}>
                    오더접수
                  </p>
                </div>
                <B2BFormArrow style={{ marginRight: '14px' }} />
                <div
                  className="b2bTopCirle"
                  style={{
                    borderRadius: '100px',
                    padding: '10px',
                    border: '1px solid',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: COLOR_WHITE,
                  }}
                >
                  <p style={{ fontWeight: '400', fontSize: '12px' }}>
                    차량배차
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  background: COLOR_WHITE,
                  marginTop: '28px',
                  paddingLeft: '3%',
                }}
              >
                <p
                  style={{
                    fontWeight: '700',
                    fontSize: '24px',
                    paddingTop: '27px',
                  }}
                >
                  기업 계약거래 신청
                </p>

                <div>
                  <div
                    style={{
                      width: '100%',
                      hegith: '63px',
                      fontsize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    <label>
                      회사명
                      <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: '90%', height: '63px' }}>
                      <input
                        type={'text'}
                        style={{
                          border: '0px',
                          borderBottom: '1px solid #8F8F8F',
                          width: '100%',
                        }}
                        value={quoteStore.companyNm || ''}
                        onChange={e => quoteStore.setCompanyNm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      hegith: '63px',
                      fontsize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    <label>
                      이메일
                      <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: '90%', height: '63px' }}>
                      <input
                        type={'text'}
                        style={{
                          border: '0px',
                          borderBottom: '1px solid #8F8F8F',
                          width: '100%',
                        }}
                        value={quoteStore.companyEmail || ''}
                        onChange={e =>
                          quoteStore.setCompanyEmail(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      width: '100%',
                      hegith: '63px',
                      fontsize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    <label>
                      담당자명
                      <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: '90%', height: '63px' }}>
                      <input
                        type={'text'}
                        style={{
                          border: '0px',
                          borderBottom: '1px solid #8F8F8F',
                          width: '100%',
                        }}
                        value={quoteStore.requestNm || ''}
                        onChange={e => quoteStore.setRequestNm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      hegith: '63px',
                      fontsize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    <label>
                      연락처
                      <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: '90%', height: '63px' }}>
                      <input
                        type={'text'}
                        style={{
                          border: '0px',
                          borderBottom: '1px solid #8F8F8F',
                          width: '100%',
                        }}
                        value={quoteStore.requestHpNo || ''}
                        onChange={e =>
                          quoteStore.setRequestHpNo(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    이용지역
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: COLOR_GRAY_GR06,
                      }}
                    >
                      (출발지 기준, 중복선택 가능)
                    </span>
                  </p>
                  {localeListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: '25%' }}
                      onChange={() => quoteStore.setLocaleList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: '41px' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    이용차량 톤수
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: COLOR_GRAY_GR06,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcWeightListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{
                        width: '25%',
                        marginTop: '13px',
                      }}
                      onChange={() => quoteStore.setVcWeightList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: '41px' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    이용차량 종류
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: COLOR_GRAY_GR06,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcTypListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{
                        width: '33%',
                        marginTop: '13px',
                        letterSpacing: '-0.5px',
                      }}
                      onChange={() => quoteStore.setVcTypList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: '41px' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    월 이용 횟수
                  </p>
                  {monthlyCount.map((data, i) => (
                    <Checkbox
                      radio={true}
                      label={data.text}
                      value={data.value}
                      style={{ width: '25%', marginTop: '13px' }}
                      checked={quoteStore.monthCountValue == data.value}
                      onChange={this.handleMonthlyCount}
                    />
                  ))}
                </div>

                <div style={{ marginTop: '41px', width: '94%' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    운송 품목
                    <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: 'none',
                        width: '100%',
                        background: COLOR_GRAY_GR00,
                        border: '0px',
                      }}
                      onChange={e => {
                        quoteStore.setFeightInfo(e.target.value);
                      }}
                    />
                  </Form>
                </div>

                <div style={{ marginTop: '41px', width: '94%' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    문의 사항
                    <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: 'none',
                        width: '100%',
                        background: COLOR_GRAY_GR00,
                        border: '0px',
                      }}
                      onChange={e => {
                        quoteStore.setRequestContent(e.target.value);
                      }}
                    />
                  </Form>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '50%',
                      height: '50px',
                      background: COLOR_GREEN_PRIMARY,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '10px',
                      marginTop: '23px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      this.fnSendB2BContact();
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: '20px',
                        fontWeight: '700',
                      }}
                    >
                      기업거래 문의하기
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1025}>
          <div style={{ width: '100%' }}>
            <div
              style={{
                position: 'absolute',
                top: 210,
                width: '100%',
                zIndex: 99,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                letterSpacing: '-1px',
              }}
            ></div>
            <div
              style={{
                width: '100%',
                height: '400px',
                position: 'relative',
              }}
            >
              <B2BTop
                style={{
                  marginTop: '16px',
                  verticalAlign: 'middle',
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '45%',
                  left: '45%',
                  textAlign: 'center',
                  letterSpacing: '-1px',
                }}
              >
                <p
                  style={{
                    color: COLOR_WHITE,
                    fontSize: '40px',
                    fontWeight: '700',
                    marginBottom: '0px',
                  }}
                >
                  기업 신용거래 안내
                </p>
              </div>
            </div>
            <div
              style={{
                marginTop: '38px',
                marginBottom: '42px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p style={{ fontSize: '18px', fontWeight: '400' }}>
                다마스, 라보, 용달 / 기업 계약거래
              </p>
              <p style={{ fontSize: '40px', fontWeight: '700' }}>
                이제 쉽고 간단하게!
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '42px',
                }}
              >
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '100px',
                    padding: '10px',
                    border: '1px solid',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '14px',
                  }}
                >
                  <p style={{ fontWeight: '400', fontSize: '20px' }}>
                    운송비 조회
                  </p>
                </div>
                <B2BFormArrow style={{ marginRight: '14px' }} />
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '100px',
                    padding: '10px',
                    border: '1px solid',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '14px',
                  }}
                >
                  <p style={{ fontWeight: '400', fontSize: '20px' }}>
                    오더접수
                  </p>
                </div>
                <B2BFormArrow style={{ marginRight: '14px' }} />
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '100px',
                    padding: '10px',
                    border: '1px solid',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ fontWeight: '400', fontSize: '20px' }}>
                    차량배차
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: '1000px',
                  background: COLOR_WHITE,
                  borderRadius: '12px',
                  paddingLeft: '28px',
                  marginTop: '56px',
                }}
              >
                <p
                  style={{
                    fontWeight: '700',
                    fontSize: '24px',
                    paddingTop: '27px',
                  }}
                >
                  기업 계약거래 신청
                </p>

                <div>
                  <div
                    style={{
                      float: 'left',
                      width: '48%',
                      hegith: '63px',
                      fontsize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    <label>
                      회사명
                      <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: '90%', height: '63px' }}>
                      <input
                        type={'text'}
                        style={{
                          border: '0px',
                          borderBottom: '1px solid #8F8F8F',
                          width: '100%',
                        }}
                        value={quoteStore.companyNm || ''}
                        onChange={e => quoteStore.setCompanyNm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '48%',
                      hegith: '63px',
                      overflow: 'hidden',
                      fontsize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    <label>
                      이메일
                      <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: '90%', height: '63px' }}>
                      <input
                        type={'text'}
                        style={{
                          border: '0px',
                          borderBottom: '1px solid #8F8F8F',
                          width: '100%',
                        }}
                        value={quoteStore.companyEmail || ''}
                        onChange={e =>
                          quoteStore.setCompanyEmail(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      float: 'left',
                      width: '48%',
                      hegith: '63px',
                      fontsize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    <label>
                      담당자명
                      <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: '90%', height: '63px' }}>
                      <input
                        type={'text'}
                        style={{
                          border: '0px',
                          borderBottom: '1px solid #8F8F8F',
                          width: '100%',
                        }}
                        value={quoteStore.requestNm || ''}
                        onChange={e => quoteStore.setRequestNm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '48%',
                      hegith: '63px',
                      overflow: 'hidden',
                      fontsize: '16px',
                      fontWeight: '700',
                    }}
                  >
                    <label>
                      연락처
                      <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: '90%', height: '63px' }}>
                      <input
                        type={'text'}
                        style={{
                          border: '0px',
                          borderBottom: '1px solid #8F8F8F',
                          width: '100%',
                        }}
                        value={quoteStore.requestHpNo || ''}
                        onChange={e =>
                          quoteStore.setRequestHpNo(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    이용지역
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: COLOR_GRAY_GR06,
                      }}
                    >
                      (출발지 기준, 중복선택 가능)
                    </span>
                  </p>
                  {localeListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: '25%' }}
                      onChange={() => quoteStore.setLocaleList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: '41px' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    이용차량 톤수
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: COLOR_GRAY_GR06,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcWeightListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: '25%', marginTop: '13px' }}
                      onChange={() => quoteStore.setVcWeightList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: '41px' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    이용차량 종류
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: COLOR_GRAY_GR06,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcTypListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: '25%', marginTop: '13px' }}
                      onChange={() => quoteStore.setVcTypList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: '41px' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    월 이용 횟수
                  </p>
                  {monthlyCount.map((data, i) => (
                    <Checkbox
                      radio={true}
                      label={data.text}
                      value={data.value}
                      style={{ width: '25%', marginTop: '13px' }}
                      checked={quoteStore.monthCountValue == data.value}
                      onChange={this.handleMonthlyCount}
                    />
                  ))}
                </div>

                <div style={{ marginTop: '41px' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    운송 품목
                    <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: 'none',
                        width: '944px',
                        background: COLOR_GRAY_GR00,
                        border: '0px',
                      }}
                      onChange={e => {
                        quoteStore.setFeightInfo(e.target.value);
                      }}
                    />
                  </Form>
                </div>

                <div style={{ marginTop: '41px' }}>
                  <p style={{ fontSize: '16px', fontWeight: '700' }}>
                    문의 사항
                    <span style={{ color: COLOR_GREEN_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: 'none',
                        width: '944px',
                        background: COLOR_GRAY_GR00,
                        border: '0px',
                      }}
                      onChange={e => {
                        quoteStore.setRequestContent(e.target.value);
                      }}
                    />
                  </Form>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '40px',
                  }}
                >
                  <div
                    style={{
                      width: '200px',
                      height: '67px',
                      background: COLOR_GREEN_PRIMARY,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '10px',
                      marginTop: '23px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      this.fnSendB2BContact();
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: '20px',
                        fontWeight: '700',
                      }}
                    >
                      기업거래 문의하기
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <CommonAlert />
      </Fragment>
    );
  }
}

export default B2BContact;
