import { Component } from 'react';
import {
  Table,
  Input,
  Button,
  Form,
  Select,
  TextArea,
  Radio,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { API_IMAGE_HANDLER } from '../../../../common/ApiAddress/CommonAPI';
import axios from 'axios';

@inject(stores => ({
  mgsStore: stores.MessageStore,
}))
@observer
class MessageTemplateUpsertContainer extends Component {
  render() {
    const { mgsStore } = this.props;
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {mgsStore.selectedTemplate == null ? (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  메세지 템플릿 작성
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>제목</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="템플릿 제목"
                    value={mgsStore.title || ''}
                    onChange={e => {
                      mgsStore.setTitle(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>내용</Table.Cell>
                <Table.Cell>
                  <Form>
                    <TextArea
                      value={mgsStore.text || ''}
                      style={{ resize: 'none' }}
                      placeholder={'템플릿 내용 작성'}
                      rows={3}
                      onChange={e => {
                        mgsStore.setText(e.target.value);
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>치환키</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="치환키 구분자  # "
                    value={mgsStore.mergeKey || ''}
                    onChange={e => {
                      mgsStore.setMergeKey(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  {mgsStore.selectedTemplate.title} 수정
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>제목</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="템플릿 제목"
                    value={mgsStore.title || ''}
                    onChange={e => {
                      mgsStore.setTitle(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>내용</Table.Cell>
                <Table.Cell>
                  <Form>
                    <TextArea
                      value={mgsStore.text || ''}
                      style={{ resize: 'none' }}
                      placeholder={'템플릿 내용 작성'}
                      rows={3}
                      onChange={e => {
                        mgsStore.setText(e.target.value);
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>치환키</Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="치환키 구분자  | "
                    value={mgsStore.mergeKey || ''}
                    onChange={e => {
                      mgsStore.setMergeKey(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>사용여부</Table.Cell>
                <Table.Cell>
                  <Form>
                    <Radio
                      toggle
                      checked={mgsStore.useYn}
                      onChange={e => {
                        if (mgsStore.useYn == true) {
                          mgsStore.setUseYnTrue();
                          //mgsStore.setUseYnFalse();
                        } else {
                          mgsStore.setUseYnFalse();
                          //mgsStore.setUseYnTrue();
                        }
                      }}
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
        {mgsStore.selectedTemplate == null ? (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnUpsertMessageTemplate();
              }}
            >
              저장하기
            </Button>
          </div>
        ) : (
          <div>
            <Button
              positive
              onClick={() => {
                this.props.fnUpsertMessageTemplate();
              }}
            >
              수정하기
            </Button>
          </div>
        )}

        <div style={{ width: '100%', height: '50px' }} />
      </div>
    );
  }
}

export default MessageTemplateUpsertContainer;
