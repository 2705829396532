import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class PrivilegeStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable authMenuList = [];
  @observable selectedAuthMenu = null;

  @observable useYnSelect = false;
  @observable useYnInsert = false;
  @observable useYnUpdate = false;
  @observable useYnDelete = false;

  @action
  setAuthMenuList = value => {
    this.authMenuList = value;
  };

  @action
  initAuthMenuList = () => {
    this.authMenuList = [];
  };

  @action
  setSelectedAuthMenu = value => {
    this.selectedAuthMenu = value;
  };

  @action
  initSelectedAuthMenu = () => {
    this.selectedAuthMenu = null;
  };

  @action
  setUseYnSelectTrue = () => {
    this.useYnSelect = true;
  };

  @action
  setUseYnSelectFalse = () => {
    this.useYnSelect = false;
  };

  @action
  setUseYnInsertTrue = () => {
    this.useYnInsert = true;
  };

  @action
  setUseYnInsertFalse = () => {
    this.useYnInsert = false;
  };

  @action
  setUseYnUpdateTrue = () => {
    this.useYnUpdate = true;
  };

  @action
  setUseYnUpdateFalse = () => {
    this.useYnUpdate = false;
  };

  @action
  setUseYnDeleteTrue = () => {
    this.useYnDelete = true;
  };

  @action
  setUseYnDeleteFalse = () => {
    this.useYnDelete = false;
  };

  @action
  initForm = () => {
    this.initSelectedAuthMenu();
    this.setUseYnSelectFalse();
    this.setUseYnInsertFalse();
    this.setUseYnUpdateFalse();
    this.setUseYnDeleteFalse();
  };
  @action
  initState = () => {
    this.setUseYnSelectFalse();
    this.setUseYnInsertFalse();
    this.setUseYnUpdateFalse();
    this.setUseYnDeleteFalse();
  };
}

export default PrivilegeStore;
