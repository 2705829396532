import React, { Component, Fragment } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
  payStore: stores.PaymentStore,
}))
@observer
class PaymentSuccessForMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 0,
      second: 3,
    };
  }

  componentDidMount() {
    let timer = setInterval(this.fnCalTimer, 1000);
    this.setState({
      timer: timer,
    });
  }

  fnCalTimer = () => {
    if (this.state.second > 0) {
      this.setState({
        second: this.state.second - 1,
      });
    } else if (this.state.second == 0) {
      clearInterval(this.state.timer);
      window.open('', '_self', '');
      window.close();
      return false;
    }
  };

  render() {
    return <Fragment></Fragment>;
  }
}

export default withRouter(PaymentSuccessForMobile);
