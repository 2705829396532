import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class VcStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable vcWeightCdList = [];
  @observable vcTypCdList = [];
  @observable vcModelCDList = [];

  @observable vcWeightCd = null;
  @observable vcWeightCdNm = null;
  @observable vcTypCd = null;
  @observable vcTypCdNm = null;
  @observable vcModelCd = null;

  @observable vcWidth = 0;
  @observable vcHeight = 0;
  @observable vcLength = 0;

  @action
  setVcWeightCdList = value => {
    this.vcWeightCdList = value;
  };

  @action
  initVcWeightCdList = () => {
    this.vcWeightCdList = [];
  };

  @action
  setVcTypCdList = value => {
    this.vcTypCdList = value;
  };

  @action
  initVcTypCdList = () => {
    this.vcTypCdList = [];
  };

  @action
  setVcModelCDList = value => {
    this.vcModelCDList = value;
  };

  @action
  initVcModelCDList = () => {
    this.vcModelCDList = [];
  };

  @action
  setVcWeightCd = value => {
    this.vcWeightCd = value;
  };

  @action
  initVcWeightCd = () => {
    this.vcWeightCd = null;
  };

  @action
  setVcWeightCdNm = value => {
    this.vcWeightCdNm = value;
  };

  @action
  initVcWeightCdNm = () => {
    this.vcWeightCdNm = null;
  };

  @action
  setVcTypCd = value => {
    this.vcTypCd = value;
  };

  @action
  initVcTypCd = () => {
    this.vcTypCd = null;
  };

  @action
  setVcTypCdNm = value => {
    this.vcTypCdNm = value;
  };

  @action
  initVcTypCdNm = () => {
    this.vcTypCdNm = null;
  };

  @action
  setVcModelCd = value => {
    this.vcModelCd = value;
  };

  @action
  initVcModelCd = () => {
    this.vcModelCd = null;
  };

  @action
  setVcWidth = value => {
    this.vcWidth = value;
  };

  @action
  initVcWidth = () => {
    this.vcWidth = null;
  };

  @action
  setVcHeight = value => {
    this.vcHeight = value;
  };

  @action
  initVcHeight = () => {
    this.vcHeight = null;
  };

  @action
  setVcLength = value => {
    this.vcLength = value;
  };

  @action
  initVcLength = () => {
    this.vcLength = null;
  };

  @action
  initForm = () => {
    this.initVcWidth();
    this.initVcHeight();
    this.initVcLength();
  };

  @action
  initForOrder = () => {
    this.initVcWeightCdList();
    this.initVcTypCdList();
    this.initVcModelCDList();
    this.initVcWeightCd();
    this.initVcTypCd();
    this.initVcModelCd();
  };
}

export default VcStore;
