import { apiAddress } from '../ApiPlatform';

export const API_UPSERT_DEPOSIT = apiAddress + '/deposit/upsertDeposit';
export const API_SELECT_DEPOSIT_HIS_DIV =
  apiAddress + '/deposit/selectDepositHisListDiv';

export const API_SELECT_DEPOSIT_HIS =
  apiAddress + '/deposit/selectDepositHisList';

export const API_SELECT_DEPOSIT_TOTAL_BALANCE =
  apiAddress + '/deposit/selectDepositTotalBalance';
