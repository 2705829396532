import React, { Component } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { API_EMP_LOGIN } from '../../common/ApiAddress/EmpAPI';
import { API_SELECT_AUTH_MENU_LIST } from '../../common/ApiAddress/PrevilegeAPI';
import {
  STR_ID,
  STR_PW,
  STR_LOGIN,
  STR_SEN_2,
  STR_SEN_3,
} from '../../common/Terms';

@inject(stores => ({
  empStore: stores.EmpStore,
  prvStore: stores.PrivilegeStore,
}))
@observer
class EmpLogin extends React.Component {
  //로그인
  fnLogin = () => {
    const { empStore } = this.props;

    const optionFail = {
      style: { color: 'red' },
    };

    let params = {};
    let that = this;
    params.empId = empStore.empId;
    params.pw = empStore.pw;
    axios({
      url: API_EMP_LOGIN,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          //아이디 저장
          console.log(response.data.data);
          sessionStorage.setItem('empId', response.data.data.empId);
          sessionStorage.setItem('empNo', response.data.data.empNo);
          sessionStorage.setItem('initpwcheck', response.data.data.initpwcheck);
          sessionStorage.setItem('authId', response.data.data.authId);
          empStore.initForm();
          that.fnSelectAuthList(response.data.data.authId);
        }
        if (response.data.resultCode == 200) {
          toast(response.data.resultMsg, optionFail);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectAuthList = atuhId => {
    const { prvStore } = this.props;

    const optionSuccess = {
      style: { color: 'green' },
    };

    let params = {};
    let that = this;
    params.authId = atuhId;
    axios({
      url: API_SELECT_AUTH_MENU_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          //아이디 저장
          console.log(response.data.data);
          prvStore.setAuthMenuList(response.data.data);
        }
        toast(
          '환영합니다.' + sessionStorage.getItem('empId') + '님',
          optionSuccess,
        );
        that.props.history.push('/main');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { empStore } = this.props;
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '25%', margin: '0 auto ', marginTop: '-80px' }}>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <img style={{ width: '100%' }} src="./image/logo.jpg" />
          </div>
          <h1>회사 관리자 로그인 입니다.</h1>
          <Form
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '6px',
              padding: '20px',
            }}
          >
            <Form.Field>
              <label>{STR_ID}</label>
              <input
                placeholder={STR_SEN_2}
                onChange={e => empStore.setEmpId(e.target.value)}
                value={empStore.empId || ''}
                type="text"
              />
            </Form.Field>
            <Form.Field>
              <label>{STR_PW}</label>
              <input
                placeholder={STR_SEN_3}
                onChange={e => empStore.setPw(e.target.value)}
                value={empStore.pw || ''}
                type="password"
              />
            </Form.Field>

            <Button
              onClick={() => {
                this.fnLogin();
              }}
              size="medium"
              color="pink"
              style={{ width: '100%', marginTop: '10px' }}
            >
              {STR_LOGIN}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default EmpLogin;
