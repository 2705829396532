import React, { Component } from 'react';
import axios from 'axios';
import {
  COLOR_GRAY_GR00,
  COLOR_GRAY_GR01,
  COLOR_GRAY_GR05,
  COLOR_BLACK,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import {
  logisFeeList,
  logisFeeListFirst,
  logisFeeListSecond,
  logisFeeListThird,
} from './LogisFeeDataList';
import Media from 'react-media';
class MailUnsubscribe extends Component {
  state = {
    email: null,
  };
  componentDidMount() {
    this.props.fnFreightFormInit();
    const urlParams = new URL(window.location.href).searchParams;
    const email = urlParams.get('email');
    this.setState({
      email: email,
    });
  }

  fnUnsubscribe = () => {
    let params = {};
    params.compEmail = this.state.email;
    axios({
      //url: 'http://localhost:8080/mail/unsubscribe',
      url: 'https://teamtruck.co.kr/mail/unsubscribe',
      method: 'post',
      data: params,
    })
      .then(response => {
        alert('수신거부 처리가 완료되었습니다.');
        window.location.href = '/';
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    return (
      <Media
        queries={{
          small: '(max-width: 768px)',
          medium: '(min-width: 769px) and (max-width: 1024px)',
          large: '(min-width: 1025px)',
        }}
      >
        {matches => (
          <React.Fragment>
            {matches.small && (
              <div
                id="specTable"
                style={{
                  width: '100%',
                  background: COLOR_WHITE,
                  height: 'calc(100vh - 210px)',
                  overflowY: 'scroll',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '29px',
                    background: COLOR_GRAY_GR01,
                    marginTop: '-5px',
                  }}
                />
                <div
                  style={{
                    width: '100%',
                    marginTop: '20px',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      width: '94%',
                      margin: '0 auto',
                      marginBottom: '40px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '15%',
                        flexDirection: 'column',
                      }}
                    >
                      <p style={{ fontSize: '20px', textAlign: 'center' }}>
                        메일링 서비스를 수신거부 하시겠습니까?
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          marginTop: '10%',
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                            background: COLOR_GRAY_GR05,
                            color: COLOR_BLACK,
                            height: '60px',
                            fontSize: '15px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #C2C2C4',
                            borderRadius: '50px',
                            marginBottom: '30px',
                          }}
                        >
                          <p>아니요. 계속 받을래요</p>
                        </div>
                        <div
                          style={{
                            width: '50%',
                            background: COLOR_GREEN_PRIMARY,
                            color: COLOR_WHITE,
                            height: '60px',
                            fontSize: '15px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #78C939',
                            borderRadius: '50px',
                          }}
                          onClick={() => {
                            this.fnUnsubscribe();
                          }}
                        >
                          <p>수신거부 해주세요.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {matches.large && (
              <div style={{ width: '100%', height: 'calc(100vh - 176px)' }}>
                <div style={{ width: '80%', margin: '0 auto' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '15%',
                      flexDirection: 'column',
                    }}
                  >
                    <p style={{ fontSize: '30px', textAlign: 'center' }}>
                      메일링 서비스를 수신거부 하시겠습니까?
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        style={{
                          width: '20%',
                          background: COLOR_GRAY_GR05,
                          color: COLOR_BLACK,
                          height: '80px',
                          fontSize: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid #C2C2C4',
                          borderRadius: '50px',
                          marginRight: '30px',
                        }}
                      >
                        <p>아니요. 계속 받을래요</p>
                      </div>
                      <div
                        style={{
                          width: '20%',
                          background: COLOR_GREEN_PRIMARY,
                          color: COLOR_WHITE,
                          height: '80px',
                          fontSize: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: '1px solid #78C939',
                          borderRadius: '50px',
                        }}
                        onClick={() => {
                          this.fnUnsubscribe();
                        }}
                      >
                        <p>수신거부 해주세요.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Media>
    );
  }
}

export default MailUnsubscribe;
