import { apiAddress } from '../ApiPlatform';

export const API_INSERT_FREIGHT_MINI =
  apiAddress + '/freightMini/insertFreightMini';

export const API_SELECT_FREIGHT_MINI_ALL_LIST =
  apiAddress + '/freightMini/selectFreightMiniList';

export const API_UPDATE_FREIGHT_MINI_TASKCD =
  apiAddress + '/freightMini/updateTaskCd';

export const API_UPDATE_FREIGHT_MINI =
  apiAddress + '/freightMini/updateFreightMini';

export const API_UPDATE_FREIGHT_MINI_MSG_TEXT =
  apiAddress + '/freightMini/updateFreightMiniMsgTxt';
