import React, { Component } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import {
  STR_ID,
  STR_PW,
  STR_SEN_2,
  STR_SEN_3,
  STR_NM,
  STR_HP_NO,
  STR_SEN_40,
  STR_SEN_41,
  STR_SEARCH_ID,
  STR_SEARCH_PW,
} from '../../../common/Terms';
import {
  COLOR_BLACK,
  COLOR_GRAY,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';
@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class Individual extends React.Component {
  render() {
    const { accStore, commonStore } = this.props;
    return (
      <div
        style={{
          width: '60%',
          margin: '0 auto ',
          display: 'flex',
          flexGrow: 2,
        }}
      >
        <div style={{ flexGrow: 1, borderRight: '1px solid #fff' }}>
          <h3 style={{ color: COLOR_GREEN_PRIMARY, textAlign: 'center' }}>
            {STR_SEARCH_ID}
          </h3>
          <Form
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '6px',
              padding: '20px',
            }}
          >
            <Form.Field style={{ marginBottom: '23px' }}>
              <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
                {STR_NM}
              </label>
              <input
                style={{ height: '60px', fontSize: '20px' }}
                placeholder={STR_SEN_40}
                onChange={e => accStore.setCustomerNm(e.target.value)}
                value={accStore.customerNm || ''}
                type="text"
              />
            </Form.Field>
            <Form.Field>
              <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
                {STR_HP_NO}
              </label>
              <input
                style={{
                  height: '60px',
                  fontSize: '20px',
                }}
                placeholder={STR_SEN_41}
                onChange={e => accStore.setHpNo(e.target.value)}
                value={accStore.hpNo || ''}
              />
            </Form.Field>
            <div
              onClick={() => {
                this.props.fnSearchCustomerId();
              }}
              style={{
                width: '100%',
                height: '60px',
                marginTop: '33px',
                marginBottom: '33px',
                background: COLOR_GREEN_PRIMARY,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                color: COLOR_WHITE,
                cursor: 'pointer',
              }}
            >
              {STR_SEARCH_ID}
            </div>
          </Form>
        </div>
        <div style={{ flexGrow: 1 }}>
          <h3 style={{ color: COLOR_GREEN_PRIMARY, textAlign: 'center' }}>
            {STR_SEARCH_PW}
          </h3>

          <Form
            style={{
              border: '1px solid rgba(34,36,38,.15)',
              borderRadius: '6px',
              padding: '20px',
            }}
          >
            <Form.Field style={{ marginBottom: '23px' }}>
              <label style={{ fontSize: '14px', color: COLOR_GREEN_PRIMARY }}>
                {STR_ID}
              </label>
              <input
                style={{ height: '60px', fontSize: '20px' }}
                placeholder={STR_SEN_2}
                onChange={e => accStore.setCustomerId(e.target.value)}
                value={accStore.customerId || ''}
                type="text"
              />
            </Form.Field>
            <div
              onClick={() => {
                this.props.authYn == false
                  ? this.props.fnGetAccessToken()
                  : commonStore.commonAlertOpen('본인인증이 완료되었습니다.');
              }}
              style={{
                width: '100%',
                height: '60px',
                marginTop: '48px',
                marginBottom: '33px',
                background:
                  this.props.authYn == false
                    ? COLOR_BLACK
                    : COLOR_GREEN_PRIMARY,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                color:
                  this.props.authYn == false
                    ? COLOR_GREEN_PRIMARY
                    : COLOR_WHITE,
                cursor: 'pointer',
                border: this.props.authYn == false ? '1px solid #78C939' : null,
              }}
            >
              {this.props.authYn == false ? '본인인증' : '본인인증 완료'}
            </div>
            <div
              onClick={() => {
                if (this.props.authYn == false) {
                  commonStore.commonAlertOpen(
                    '본인인증 완료 후 시도해 주세요.',
                  );
                } else {
                  this.props.fnCheckPwIsMatch();
                }
              }}
              style={{
                width: '100%',
                height: '60px',
                marginTop: '33px',
                marginBottom: '33px',
                background:
                  this.props.authYn == false ? COLOR_GRAY : COLOR_GREEN_PRIMARY,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                color: COLOR_WHITE,
                cursor: 'pointer',
              }}
            >
              {STR_SEARCH_PW}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Individual;
