import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../../common/Color';

@inject(stores => ({
  feeStore: stores.FreightFeeStore,
}))
@observer
class DongList extends Component {
  render() {
    const { feeStore } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>읍면동코드</Table.HeaderCell>
            <Table.HeaderCell>읍면동명</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {feeStore.dongList == null || feeStore.dongList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                읍면동이 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {feeStore.dongList.map((data, i) => (
              <React.Fragment>
                <Table.Row
                  onClick={() =>
                    this.props.fnSelectedDong(feeStore.dongList[i])
                  }
                >
                  <Table.Cell
                    style={{
                      backgroundColor:
                        feeStore.selectedDong != null &&
                        feeStore.selectedDong.dongCd === data.dongCd
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        feeStore.selectedDong != null &&
                        feeStore.selectedDong.dongCd === data.dongCd
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.dongCd}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      backgroundColor:
                        feeStore.selectedDong != null &&
                        feeStore.selectedDong.dongCd === data.dongCd
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        feeStore.selectedDong != null &&
                        feeStore.selectedDong.dongCd === data.dongCd
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.dongCdNm}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default DongList;
