import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import NotiList from '../../../../Component/Management/Setting/Notification/NotiList';
import {
  API_INSERT_NOTI,
  API_SELECT_NOTI_LIST,
  API_SELECT_NOTI_LIST_FOR_CUSTOMER,
  API_UPDATE_NOTI,
} from '../../../../common/ApiAddress/NotiAPI';
import NotiUpsertContainer from './NotiUpsertContainer';
import NotiDetailContainer from './NotiDetailContainer';
import { isCustomer, isEmp } from '../../../../Util/CommonUtil';
@inject(stores => ({
  notiStore: stores.NotiStore,
}))
@observer
class NotiManagementContainer extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
  };

  componentDidMount() {
    this.fnSelectNotiList();
  }

  //공지사항 리스트 불러오기
  fnSelectNotiList = () => {
    const { notiStore } = this.props;
    let params = {};
    let that = this;

    if (isEmp()) {
      params = {};
    } else if (isCustomer()) {
      params.useYn = 'Y';
      params.notiCd = 'C';
    }

    axios({
      url: API_SELECT_NOTI_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          notiStore.setNotiList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnViewOpen = value => {
    const { notiStore } = this.props;
    notiStore.setSelectedNoti(value);
    notiStore.setNotiTitle(value.notiTitle);
    notiStore.setNotiContent(value.notiContent);
    notiStore.setNotiCd(value.notiCd);
    this.setState({
      upsertView: true,
      upsertMode: 'U',
    });
  };

  fnUpsertNoti = () => {
    const { notiStore } = this.props;
    let params = {};
    params.notiTitle = notiStore.notiTitle;
    params.notiContent = notiStore.notiContent;
    params.notiCd = notiStore.notiCd;
    params.registId = sessionStorage.getItem('empId');

    if (this.state.upsertMode == 'U') {
      params.notiNo = notiStore.selectedNoti.notiNo;
    }

    console.log(params);

    let that = this;
    axios({
      url: this.state.upsertMode == 'I' ? API_INSERT_NOTI : API_UPDATE_NOTI,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.resultCode == 100) {
          notiStore.initForm();
          that.fnSelectNotiList();
          that.setState({
            upsertView: false,
            upsertMode: 'I',
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { notiStore } = this.props;
    return isEmp() ? (
      <React.Fragment>
        <div
          style={{
            width: '30%',
            height: 'calc(100vh - 40px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          <Button
            positive
            onClick={() => {
              notiStore.initForm();
              this.setState({
                upsertView: true,
                upsertMode: 'I',
              });
            }}
            style={{ float: 'right' }}
          >
            공지사항 작성
          </Button>
          <NotiList
            fnViewOpen={this.fnViewOpen}
            notiList={notiStore.notiList}
          />
        </div>
        <div
          style={{
            width: '70%',
            height: 'calc(100vh - 40px)',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {this.state.upsertView == true ? (
            <NotiUpsertContainer fnUpsertNoti={this.fnUpsertNoti} />
          ) : null}
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div
          style={{
            width: '40%',
            height: '100%',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            padding: '10px',
          }}
        >
          <NotiList
            fnViewOpen={this.fnViewOpen}
            notiList={notiStore.notiList}
          />
        </div>
        <div
          style={{
            width: '60%',
            height: '100%',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {this.state.upsertView == true ? <NotiDetailContainer /> : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(NotiManagementContainer);
