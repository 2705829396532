import axios from 'axios';
import { useCallback } from 'react';

export const isLogin = () => {
  if (
    sessionStorage.getItem('customerId') != undefined ||
    sessionStorage.getItem('empId') != undefined
  ) {
    return true;
  } else {
    return false;
  }
};

export const isAdmin = () => {
  if (sessionStorage.getItem('customerCd').substring(0, 3) == '016') {
    return true;
  } else {
    return false;
  }
};

// export const isCustomer = () => {
//   if (sessionStorage.getItem('customerCd').substring(0, 5) == 'IACCU') {
//     return true;
//   } else {
//     return false;
//   }
// };

export const isEmp = () => {
  if (sessionStorage.getItem('empId') != undefined) {
    return true;
  } else {
    return false;
  }
};

export const isCustomerMaster = () => {
  if (
    sessionStorage.getItem('customerCd') != undefined &&
    sessionStorage.getItem('customerCd').substring(5, 8) == '003'
  ) {
    return true;
  } else {
    return false;
  }
};

export const isLogisManager = () => {
  if (
    sessionStorage.getItem('customerCd') != undefined &&
    sessionStorage.getItem('customerCd').substring(5, 8) == '004'
  ) {
    return true;
  } else {
    return false;
  }
};

export const isCustomer = () => {
  if (
    sessionStorage.getItem('customerCd') != undefined &&
    sessionStorage.getItem('customerCd').substring(5, 8) == '002'
  ) {
    return true;
  } else {
    return false;
  }
};

//Promis Test
export const fnTest = () => {
  let that = this;
  let params = {};
  params.sysCd = 'IACCU';
  return new Promise(resolve => {
    axios({
      url: 'http://106.243.89.42:8080/common/selectSyscdCustomer',
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          resolve(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  });
};
