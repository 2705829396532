import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { COLOR_GRAY_GR02, COLOR_WHITE } from '../../../common/Color';
import { Button } from 'semantic-ui-react';
import DriverUpsertContainer from './DriverUpsertContainer';
import {
  API_INSERT_WORKBENCH_DRIVER,
  API_SELECT_WORKBENCH_DRIVER_LIST,
  API_UPDATE_WORKBENCH_DRIVER,
} from '../../../common/ApiAddress/WorkBenchDriverAPI';
import DriverList from './DriverList';
@inject(stores => ({
  workDriverStore: stores.WorkbenchDriverStore,
}))
@observer
class DriverManagement extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
    selectedDriverIndex: 0,
  };

  componentDidMount() {
    this.fnSelectDriverList();
  }

  fnInsertViewOpen = () => {
    this.setState({
      upsertView: true,
      upsertMode: 'I',
      selectedDriverIndex: 0,
    });
  };

  fnUpdateViewOpen = (value, i) => {
    const { workDriverStore } = this.props;
    workDriverStore.setSelectedDriver(value);
    workDriverStore.setDriverNm(value.driverNm);
    workDriverStore.setDriverHpNo(value.driverHpNo);
    workDriverStore.setDriverAccInfo(value.driverAccInfo);
    workDriverStore.setVcNo(value.vcNo);
    workDriverStore.setVcWeight(value.vcWeight);
    workDriverStore.setVcTyp(value.vcTyp);

    this.setState({
      upsertView: true,
      upsertMode: 'U',
      selectedDriverIndex: i,
    });
  };

  fnUpsertDriver = () => {
    const { workDriverStore } = this.props;
    let params = {};
    let that = this;
    params.driverNm = workDriverStore.driverNm;
    params.driverHpNo = workDriverStore.driverHpNo;
    params.driverAccInfo = workDriverStore.driverAccInfo;
    params.vcNo = workDriverStore.vcNo;
    params.vcWeight = workDriverStore.vcWeight;
    params.vcTyp = workDriverStore.vcTyp;

    if (this.state.upsertMode == 'U') {
      params.driverNo = workDriverStore.selectedDriver.driverNo;
    }

    axios({
      url:
        this.state.upsertMode == 'I'
          ? API_INSERT_WORKBENCH_DRIVER
          : API_UPDATE_WORKBENCH_DRIVER,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnSelectDriverList();
          workDriverStore.initForm();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectDriverList = () => {
    const { workDriverStore } = this.props;
    let params = {};
    let that = this;
    params.startCnt = 0;
    params.endCnt = 50;

    axios({
      url: API_SELECT_WORKBENCH_DRIVER_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          workDriverStore.setDriverList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { workDriverStore } = this.props;
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          background: COLOR_GRAY_GR02,
          overflowY: 'scroll',
        }}
      >
        <div style={{ width: '90%', margin: '0 auto' }}>
          <div
            style={{
              width: '49%',
              height: 'calc(100vh - 180px)',
              background: COLOR_WHITE,
              borderRadius: '10px',
              float: 'left',
              marginRight: '2%',
              marginTop: this.state.upsertView == true ? '0px' : '10px',
            }}
          >
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                marginTop: '10px',
                paddingRight: '10px',
              }}
            >
              <Button color="blue" onClick={this.fnInsertViewOpen}>
                트럭커 등록
              </Button>
              <div
                style={{ height: 'calc(100vh - 240px)', overflowY: 'scroll' }}
              >
                <DriverList fnUpdateViewOpen={this.fnUpdateViewOpen} />
              </div>
            </div>
          </div>

          {this.state.upsertView == true ? (
            <div
              style={{
                width: '49%',
                height: 'calc(100vh - 180px)',
                background: COLOR_WHITE,
                borderRadius: '10px',
                overflow: 'hidden',
                marginLeft: '10%',
                marginTop: '10px',
              }}
            >
              <DriverUpsertContainer fnUpsertDriver={this.fnUpsertDriver} />
            </div>
          ) : null}

          <div style={{ width: '100%', height: '50px' }} />
        </div>
      </div>
    );
  }
}

export default DriverManagement;
