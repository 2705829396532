import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  compStore: stores.CompStore,
}))
@observer
class FreightStatementListHeader extends Component {
  render() {
    const { compStore } = this.props;
    return (
      <Fragment>
        <tr
          style={{
            width: '690px',
            height: '26px',
            fontSize: '10pt',
          }}
        >
          <td
            colSpan={2}
            style={{
              borderTop: '2px solid',
              borderLeft: '2px solid',
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          >
            일자
          </td>
          <td
            style={{
              borderTop: '2px solid',
              width: '60px',
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          >
            차량번호
          </td>

          <td
            style={{
              borderTop: '2px solid',
              width: '40px',
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          >
            톤수
          </td>

          <td
            colSpan={2}
            style={{
              borderTop: '2px solid',
              width: '30px',
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          >
            출발지
          </td>
          <td
            colSpan={3}
            style={{
              borderTop: '2px solid',
              width: '120px',
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          >
            도착지
          </td>
          <td
            colSpan={2}
            style={{
              borderTop: '2px solid',
              width: '30px',
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          >
            매출액
          </td>

          <td
            style={{
              borderTop: '2px solid',
              width: '120px',
              borderRight: '2px solid',
              borderBottom: '2px solid',
              textAlign: 'center',
            }}
          >
            비고
          </td>
        </tr>
      </Fragment>
    );
  }
}

export default FreightStatementListHeader;
