export const logisFeeList = [
  {
    distance: '5Km',
    T1: '35,000',
    T104: '45,000',
    T205: '50,000',
    T305: '60,000',
    T5: '80,000',
    T5P: '90,000',
    T5L: '100,000',
    T11: '120,000',
    T18: '160,000',
    T25: '180,000',
  },
  {
    distance: '10Km',
    T1: '40,000',
    T104: '50,000',
    T205: '60,000',
    T305: '70,000',
    T5: '90,000',
    T5P: '100,000',
    T5L: '110,000',
    T11: '140,000',
    T18: '170,000',
    T25: '190,000',
  },
  {
    distance: '20Km',
    T1: '50,000',
    T104: '60,000',
    T205: '70,000',
    T305: '80,000',
    T5: '100,000',
    T5P: '110,000',
    T5L: '120,000',
    T11: '150,000',
    T18: '180,000',
    T25: '200,000',
  },
  {
    distance: '30Km',
    T1: '55,000',
    T104: '65,000',
    T205: '75,000',
    T305: '85,000',
    T5: '105,000',
    T5P: '115,000',
    T5L: '125,000',
    T11: '155,000',
    T18: '190,000',
    T25: '210,000',
  },
  {
    distance: '60Km',
    T1: '65,000',
    T104: '75,000',
    T205: '100,000',
    T305: '110,000',
    T5: '130,000',
    T5P: '140,000',
    T5L: '150,000',
    T11: '170,000',
    T18: '200,000',
    T25: '230,000',
  },
  {
    distance: '80Km',
    T1: '75,000',
    T104: '90,000',
    T205: '110,000',
    T305: '120,000',
    T5: '140,000',
    T5P: '150,000',
    T5L: '170,000',
    T11: '190,000',
    T18: '230,000',
    T25: '240,000',
  },
  {
    distance: '110Km',
    T1: '90,000',
    T104: '100,000',
    T205: '120,000',
    T305: '130,000',
    T5: '160,000',
    T5P: '170,000',
    T5L: '180,000',
    T11: '200,000',
    T18: '240,000',
    T25: '270,000',
  },
  {
    distance: '130Km',
    T1: '100,000',
    T104: '110,000',
    T205: '130,000',
    T305: '140,000',
    T5: '170,000',
    T5P: '180,000',
    T5L: '190,000',
    T11: '220,000',
    T18: '250,000',
    T25: '280,000',
  },
  {
    distance: '150Km',
    T1: '110,000',
    T104: '120,000',
    T205: '150,000',
    T305: '160,000',
    T5: '180,000',
    T5P: '190,000',
    T5L: '210,000',
    T11: '240,000',
    T18: '270,000',
    T25: '300,000',
  },
  {
    distance: '170Km',
    T1: '120,000',
    T104: '130,000',
    T205: '160,000',
    T305: '170,000',
    T5: '200,000',
    T5P: '210,000',
    T5L: '230,000',
    T11: '260,000',
    T18: '290,000',
    T25: '330,000',
  },
  {
    distance: '200Km',
    T1: '130,000',
    T104: '140,000',
    T205: '190,000',
    T305: '200,000',
    T5: '230,000',
    T5P: '240,000',
    T5L: '260,000',
    T11: '300,000',
    T18: '340,000',
    T25: '360,000',
  },
  {
    distance: '250Km',
    T1: '160,000',
    T104: '170,000',
    T205: '230,000',
    T305: '240,000',
    T5: '260,000',
    T5P: '270,000',
    T5L: '300,000',
    T11: '360,000',
    T18: '400,000',
    T25: '430,000',
  },
  {
    distance: '300Km',
    T1: '180,000',
    T104: '190,000',
    T205: '250,000',
    T305: '260,000',
    T5: '300,000',
    T5P: '320,000',
    T5L: '350,000',
    T11: '390,000',
    T18: '440,000',
    T25: '470,000',
  },
  {
    distance: '400Km',
    T1: '210,000',
    T104: '220,000',
    T205: '300,000',
    T305: '320,000',
    T5: '350,000',
    T5P: '360,000',
    T5L: '400,000',
    T11: '440,000',
    T18: '490,000',
    T25: '520,000',
  },
];

export const logisFeeListFirst = [
  {
    distance: '5Km',
    T1: '35,000',
    T104: '45,000',
    T205: '50,000',
  },
  {
    distance: '10Km',
    T1: '40,000',
    T104: '50,000',
    T205: '60,000',
  },
  {
    distance: '20Km',
    T1: '50,000',
    T104: '60,000',
    T205: '70,000',
  },
  {
    distance: '30Km',
    T1: '55,000',
    T104: '65,000',
    T205: '75,000',
  },
  {
    distance: '60Km',
    T1: '65,000',
    T104: '75,000',
    T205: '100,000',
  },
  {
    distance: '80Km',
    T1: '75,000',
    T104: '90,000',
    T205: '110,000',
  },
  {
    distance: '110Km',
    T1: '90,000',
    T104: '100,000',
    T205: '120,000',
  },
  {
    distance: '130Km',
    T1: '100,000',
    T104: '110,000',
    T205: '130,000',
  },
  {
    distance: '150Km',
    T1: '110,000',
    T104: '120,000',
    T205: '150,000',
  },
  {
    distance: '170Km',
    T1: '120,000',
    T104: '130,000',
    T205: '160,000',
  },
  {
    distance: '200Km',
    T1: '130,000',
    T104: '140,000',
    T205: '190,000',
  },
  {
    distance: '250Km',
    T1: '160,000',
    T104: '170,000',
    T205: '230,000',
  },
  {
    distance: '300Km',
    T1: '180,000',
    T104: '190,000',
    T205: '250,000',
  },
  {
    distance: '400Km',
    T1: '210,000',
    T104: '220,000',
    T205: '300,000',
  },
];

export const logisFeeListSecond = [
  {
    distance: '5Km',
    T305: '60,000',
    T5: '80,000',
    T5P: '90,000',
    T5L: '100,000',
  },
  {
    distance: '10Km',
    T305: '70,000',
    T5: '90,000',
    T5P: '100,000',
    T5L: '110,000',
  },
  {
    distance: '20Km',
    T305: '80,000',
    T5: '100,000',
    T5P: '110,000',
    T5L: '120,000',
  },
  {
    distance: '30Km',
    T305: '85,000',
    T5: '105,000',
    T5P: '115,000',
    T5L: '125,000',
  },
  {
    distance: '60Km',
    T305: '110,000',
    T5: '130,000',
    T5P: '140,000',
    T5L: '150,000',
  },
  {
    distance: '80Km',
    T305: '120,000',
    T5: '140,000',
    T5P: '150,000',
    T5L: '170,000',
  },
  {
    distance: '110Km',
    T305: '130,000',
    T5: '160,000',
    T5P: '170,000',
    T5L: '180,000',
  },
  {
    distance: '130Km',
    T305: '140,000',
    T5: '170,000',
    T5P: '180,000',
    T5L: '190,000',
  },
  {
    distance: '150Km',
    T305: '160,000',
    T5: '180,000',
    T5P: '190,000',
    T5L: '210,000',
  },
  {
    distance: '170Km',
    T305: '170,000',
    T5: '200,000',
    T5P: '210,000',
    T5L: '230,000',
  },
  {
    distance: '200Km',
    T305: '200,000',
    T5: '230,000',
    T5P: '240,000',
    T5L: '260,000',
  },
  {
    distance: '250Km',
    T305: '240,000',
    T5: '260,000',
    T5P: '270,000',
    T5L: '300,000',
  },
  {
    distance: '300Km',
    T305: '260,000',
    T5: '300,000',
    T5P: '320,000',
    T5L: '350,000',
  },
  {
    distance: '400Km',
    T305: '320,000',
    T5: '350,000',
    T5P: '360,000',
    T5L: '400,000',
  },
];

export const logisFeeListThird = [
  {
    distance: '5Km',
    T11: '120,000',
    T18: '160,000',
    T25: '180,000',
  },
  {
    distance: '10Km',
    T11: '140,000',
    T18: '170,000',
    T25: '190,000',
  },
  {
    distance: '20Km',
    T11: '150,000',
    T18: '180,000',
    T25: '200,000',
  },
  {
    distance: '30Km',
    T11: '155,000',
    T18: '190,000',
    T25: '210,000',
  },
  {
    distance: '60Km',
    T11: '170,000',
    T18: '200,000',
    T25: '230,000',
  },
  {
    distance: '80Km',
    T11: '190,000',
    T18: '230,000',
    T25: '240,000',
  },
  {
    distance: '110Km',
    T11: '200,000',
    T18: '240,000',
    T25: '270,000',
  },
  {
    distance: '130Km',
    T11: '220,000',
    T18: '250,000',
    T25: '280,000',
  },
  {
    distance: '150Km',
    T11: '240,000',
    T18: '270,000',
    T25: '300,000',
  },
  {
    distance: '170Km',
    T11: '260,000',
    T18: '290,000',
    T25: '330,000',
  },
  {
    distance: '200Km',
    T11: '300,000',
    T18: '340,000',
    T25: '360,000',
  },
  {
    distance: '250Km',
    T11: '360,000',
    T18: '400,000',
    T25: '430,000',
  },
  {
    distance: '300Km',
    T11: '390,000',
    T18: '440,000',
    T25: '470,000',
  },
  {
    distance: '400Km',
    T11: '440,000',
    T18: '490,000',
    T25: '520,000',
  },
];
