import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class EmpStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable empList = [];
  @observable selectedEmp = null;

  @observable empId = null;
  @observable pw = null;
  @observable checkPw = null;
  @observable empNm = null;
  @observable hpNo = null;
  @observable useYn = false;
  @observable deptNo = null;

  @action
  setEmpList = value => {
    this.empList = value;
  };

  @action
  initEmpList = () => {
    this.empList = [];
  };

  @action
  setSelectedEmp = value => {
    this.selectedEmp = value;
  };

  @action
  initSelectedEmp = () => {
    this.selectedEmp = null;
  };

  @action
  setEmpId = value => {
    this.empId = value;
  };

  @action
  initEmpId = () => {
    this.empId = null;
  };

  @action
  setPw = value => {
    this.pw = value;
  };

  @action
  initPw = () => {
    this.pw = null;
  };

  @action
  setCheckPw = value => {
    this.checkPw = value;
  };

  @action
  initCheckPw = () => {
    this.checkPw = null;
  };

  @action
  setEmpNm = value => {
    this.empNm = value;
  };

  @action
  initEmpNm = () => {
    this.empNm = null;
  };

  @action
  setHpNo = value => {
    this.hpNo = value;
  };

  @action
  initHpNo = () => {
    this.hpNo = null;
  };

  @action
  setUseYnTrue = () => {
    this.useYn = true;
  };

  @action
  setUseYnFalse = () => {
    this.useYn = false;
  };

  @action
  setDeptNo = value => {
    this.deptNo = value;
  };

  @action
  initDeptNo = () => {
    this.deptNo = null;
  };

  @action
  initForm = () => {
    this.initSelectedEmp();
    this.initEmpId();
    this.initEmpNm();
    this.initPw();
    this.initHpNo();
    this.initDeptNo();
    this.initCheckPw();
    this.setUseYnFalse();
  };
}

export default EmpStore;
