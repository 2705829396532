import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { COLOR_GRAY_GR02, COLOR_WHITE } from '../../../common/Color';
import OrderList from './OrderList';
import {
  API_SELECT_WORKBENCH_FREIGHT_LIST,
  API_UPDATE_WORKBENCH_FREIGHT,
  API_UPDATE_WORKBENCH_FREIGHT_COMP_NO,
} from '../../../common/ApiAddress/WorkBenchFreightAPI';
import WorkBenchOrderDetail from './WorkBenchOrderDetail';
import moment from 'moment';
import { Button, Modal } from 'semantic-ui-react';
import { API_SELECT_COMP_LIST } from '../../../common/ApiAddress/CompAPI';
import CompList from '../Comp/CompList';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
@inject(stores => ({
  wborderStore: stores.WorkbenchOrderStore,
  compStore: stores.CompStore,
}))
@observer
class WorkBenchOrderListContainer extends Component {
  state = {
    upsertView: false,
    compSelectModal: false,
    selectedDate: null,
  };
  componentDidMount() {
    const date = new Date();
    if (sessionStorage.getItem('loadingDate') == undefined) {
      sessionStorage.setItem('loadingDate', date);
      this.fnSelectDate(date);
    } else {
      let date = sessionStorage.getItem('loadingDate');
      let ldate = new Date(date);
      this.fnSelectDate(ldate);
    }
  }

  fnSelectOrderList = value => {
    const { wborderStore } = this.props;
    let params = {};
    let that = this;
    params.loadingDate = moment(value).format('YYYY-MM-DD');

    axios({
      url: API_SELECT_WORKBENCH_FREIGHT_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          wborderStore.setOrderList(response.data.data);
        } else {
          wborderStore.initOrderList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetSelectedOrder = value => {
    const { wborderStore } = this.props;
    wborderStore.setSelectedOrder(value);

    wborderStore.setClient(value.client);
    wborderStore.setClientStaff(value.clientStaffNm);
    wborderStore.setClientStaffHpNo(value.clientStaffHpNo);
    wborderStore.setLoadingCompNm(value.loadingCompNm);
    wborderStore.setLoadingAdd(value.loadingAdd);
    wborderStore.setLoadingStaff(value.loadingStaff);
    wborderStore.setLoadingStaffHpNo(value.loadingStaffHpNo);

    wborderStore.setUnloadingCompNm(value.unloadingCompNm);
    wborderStore.setUnloadingAdd(value.unloadingAdd);
    wborderStore.setUnloadingStaff(value.unloadingStaff);
    wborderStore.setUnloadingStaffHpNo(value.unloadingStaffHpNo);

    wborderStore.setVcWeight(value.vcWeight);
    wborderStore.setVcTyp(value.vcTyp);
    wborderStore.setFreightWeight(value.freightWeight);
    wborderStore.setPayCd(value.payCd);

    wborderStore.setFreightInfo(value.freightInfo);
    wborderStore.setFreightMemo(value.freightMemo);

    wborderStore.setLoadingDate(value.loadingDateStr);
    wborderStore.setLoadingTime(value.loadingTime);
    wborderStore.setUnloadingDate(value.unloadingDateStr);
    wborderStore.setUnloadingTime(value.unloadingTime);

    wborderStore.setFreightCd(value.freightCd);

    wborderStore.setDriverNm(value.driverNm);
    wborderStore.setDriverHpNo(value.driverHpNo);
    wborderStore.setDriverVcWeight(value.driverVcWeight);
    wborderStore.setDriverVcTyp(value.driverVcTyp);
    wborderStore.setVcNo(value.vcNo);

    wborderStore.setLogisFee(value.logisFee);
    wborderStore.setCommission(value.commission);
    wborderStore.setTotalFee(value.totalFee);

    this.setState({
      upsertView: true,
    });
  };

  fnUpdateFreight = () => {
    const { wborderStore } = this.props;
    let params = {};
    let that = this;
    params.client = wborderStore.client;
    params.clientStaffNm = wborderStore.clientStaff;
    params.clientStaffHpNo = wborderStore.clientStaffHpNo;
    params.loadingCompNm = wborderStore.loadingCompNm;
    params.loadingAdd = wborderStore.loadingAdd;
    params.loadingStaff = wborderStore.loadingStaff;
    params.loadingStaffHpNo = wborderStore.loadingStaffHpNo;
    params.unloadingCompNm = wborderStore.unloadingCompNm;
    params.unloadingAdd = wborderStore.unloadingAdd;
    params.unloadingStaff = wborderStore.unloadingStaff;
    params.unloadingStaffHpNo = wborderStore.unloadingStaffHpNo;
    params.vcTyp = wborderStore.vcTyp;
    params.vcWeight = wborderStore.vcWeight;
    params.freightInfo = wborderStore.freightInfo;
    params.freightWeight = wborderStore.freightWeight;
    params.freightMemo = wborderStore.freightMemo;
    params.payCd = wborderStore.payCd;

    params.loadingDateStr = wborderStore.loadingDate;
    params.loadingTime = wborderStore.loadingTime;
    params.unloadingDateStr = wborderStore.unloadingDate;
    params.unloadingTime = wborderStore.unloadingTime;

    //날짜 변환 로직
    let now = new Date();
    let year = now.getFullYear();
    if (
      wborderStore.loadingDate != null ||
      wborderStore.loadingDate != undefined ||
      wborderStore.loadingDate != ' '
    ) {
      console.log(wborderStore.loadingDate);
      let loadingDate = wborderStore.loadingDate;
      let loadingDateMonthSpl = loadingDate.split('월');
      let loadingDateDateSpl = loadingDateMonthSpl[1].split('일');
      let modifyLoadingDate = moment(
        year + '-' + loadingDateMonthSpl[0] + '-' + loadingDateDateSpl[0],
      ).format('YYYY-MM-DD');
      params.loadingDate = modifyLoadingDate;
    }

    if (
      wborderStore.unloadingDate != null ||
      wborderStore.unloadingDate != undefined ||
      wborderStore.unloadingDate != ' '
    ) {
      let unloadingDate = wborderStore.unloadingDate;
      let unloadingDateMonthSpl = unloadingDate.split('월');
      let unloadingDateDateSpl = unloadingDateMonthSpl[1].split('일');
      let modifyunLoadingDate = moment(
        year + '-' + unloadingDateMonthSpl[0] + '-' + unloadingDateDateSpl[0],
      ).format('YYYY-MM-DD');
      params.unloadingDate = modifyunLoadingDate;
    }
    params.RNo = wborderStore.selectedOrder.RNo;
    params.freightCd = wborderStore.freightCd;
    params.driverNm = wborderStore.driverNm;
    params.driverHpNo = wborderStore.driverHpNo;
    params.driverVcWeight = wborderStore.driverVcWeight;
    params.driverVcTyp = wborderStore.driverVcTyp;
    params.vcNo = wborderStore.vcNo;

    params.logisFee = wborderStore.logisFee;
    params.commission = wborderStore.commission;
    params.totalFee = wborderStore.totalFee;

    axios({
      url: API_UPDATE_WORKBENCH_FREIGHT,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnAutoCompleteVcInfo = value => {
    const { wborderStore } = this.props;
    let splt = value.split(' ');
    if (splt.length > 2) {
      wborderStore.setDriverNm(splt[0]);
      wborderStore.setDriverHpNo(splt[2]);
      wborderStore.setVcNo(splt[1]);
    }
  };

  //메세지 전송 권한 요청
  fnGetMsgAuth = () => {
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgAuth',
      method: 'post',
    })
      .then(response => {
        that.fnSendMsg(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //기사님 문자보내기
  fnSendMsg = authData => {
    const { wborderStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content = '발주처 : ' + wborderStore.client + '\n';

    if (
      wborderStore.clientStaff == null &&
      wborderStore.clientStaffHpNo != null
    ) {
      content =
        content + '발주처 담당자: ' + wborderStore.clientStaffHpNo + '\n';
    }

    if (
      wborderStore.clientStaff != null &&
      wborderStore.clientStaffHpNo == null
    ) {
      content = content + '발주처 담당자: ' + wborderStore.clientStaff + '\n';
    }

    if (
      wborderStore.clientStaff != null &&
      wborderStore.clientStaffHpNo != null
    ) {
      content =
        content +
        '발주처 담당자: ' +
        wborderStore.clientStaff +
        ' / ' +
        wborderStore.clientStaffHpNo +
        '\n\n';
    }

    content = content + '●상차지\n';
    if (wborderStore.loadingCompNm != null) {
      content = content + wborderStore.loadingCompNm + '\n';
    }
    content = content + wborderStore.loadingAdd + '\n';

    if (
      wborderStore.loadingStaff == null &&
      wborderStore.loadingStaffHpNo != null
    ) {
      content =
        content + '상차지 담당자: ' + wborderStore.loadingStaffHpNo + '\n';
    }

    if (
      wborderStore.loadingStaff != null &&
      wborderStore.loadingStaffHpNo == null
    ) {
      content = content + '상차지 담당자: ' + wborderStore.loadingStaff + '\n';
    }

    if (
      wborderStore.loadingStaff != null &&
      wborderStore.loadingStaffHpNo != null
    ) {
      content =
        content +
        '상차지 담당자: ' +
        wborderStore.loadingStaff +
        ' / ' +
        wborderStore.loadingStaffHpNo +
        '\n\n';
    }

    content = content + '●하차지\n';
    if (wborderStore.unloadingCompNm != null) {
      content = content + wborderStore.unloadingCompNm + '\n';
    }
    content = content + wborderStore.unloadingAdd + '\n';

    if (
      wborderStore.unloadingStaff == null &&
      wborderStore.unloadingStaffHpNo != null
    ) {
      content =
        content + '하차지 담당자: ' + wborderStore.unloadingStaffHpNo + '\n';
    }

    if (
      wborderStore.unloadingStaff != null &&
      wborderStore.unloadingStaffHpNo == null
    ) {
      content =
        content + '하차지 담당자: ' + wborderStore.unloadingStaff + '\n';
    }

    if (
      wborderStore.unloadingStaff != null &&
      wborderStore.unloadingStaffHpNo != null
    ) {
      content =
        content +
        '하차지 담당자: ' +
        wborderStore.unloadingStaff +
        ' / ' +
        wborderStore.unloadingStaffHpNo +
        '\n\n';
    }

    content =
      content +
      '차량 : ' +
      wborderStore.vcWeight +
      ' / ' +
      wborderStore.vcTyp +
      '\n';
    content = content + '화물정보 : ' + wborderStore.freightInfo + '\n';
    content = content + '중량 : ' + wborderStore.vcWeight + '\n';
    content = content + '특이사항 : ' + wborderStore.freightMemo + '\n';
    content =
      content +
      '상차시간 : ' +
      wborderStore.loadingDate +
      ' / ' +
      wborderStore.loadingTime +
      '\n';
    content =
      content +
      '하차시간 : ' +
      wborderStore.unloadingDate +
      ' / ' +
      wborderStore.unloadingTime +
      '\n';
    content = content + '결제방법 : ' + wborderStore.payCd + '\n';

    phone.push(wborderStore.driverHpNo);
    id.push('ttSystem');
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgSendLmsforTrucker',
      method: 'get',
      params: params,
    })
      .then(function (response) {
        if (response.data.code == '1000') {
          alert('문자전송완료');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetLogisFee = value => {
    const { wborderStore } = this.props;
    wborderStore.setLogisFee(value);
    let totalFee =
      Number(wborderStore.logisFee) + Number(wborderStore.commission);
    wborderStore.setTotalFee(totalFee);
  };

  fnSetCommission = value => {
    const { wborderStore } = this.props;
    wborderStore.setCommission(value);
    let totalFee =
      Number(wborderStore.logisFee) + Number(wborderStore.commission);
    console.log(totalFee);
    wborderStore.setTotalFee(totalFee);
  };

  fnMsgCopyForCustomer = () => {
    const { wborderStore } = this.props;
    let content = null;
    if (
      wborderStore.loadingCompNm != null &&
      wborderStore.unloadingCompNm != null
    ) {
      content =
        wborderStore.loadingCompNm +
        ' -> ' +
        wborderStore.unloadingCompNm +
        '\n';
    }
    if (
      wborderStore.loadingCompNm != null &&
      wborderStore.unloadingCompNm == null
    ) {
      content =
        wborderStore.loadingCompNm + ' -> ' + wborderStore.unloadingAdd + '\n';
    }

    if (
      wborderStore.loadingCompNm == null &&
      wborderStore.unloadingCompNm != null
    ) {
      content =
        wborderStore.loadingAdd + ' -> ' + wborderStore.unloadingCompNm + '\n';
    }

    if (
      wborderStore.loadingCompNm == null &&
      wborderStore.unloadingCompNm == null
    ) {
      content =
        wborderStore.loadingAdd + ' -> ' + wborderStore.unloadingAdd + '\n';
    }

    content =
      content + wborderStore.driverNm + ' / ' + wborderStore.driverHpNo + '\n';

    content =
      content +
      wborderStore.driverVcWeight +
      wborderStore.driverVcTyp +
      ' / ' +
      wborderStore.vcNo +
      '\n';
    content =
      content +
      wborderStore.totalFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '원 ' +
      '배차했습니다. \n';
    content = content + '감사합니다. \n\n';
    content =
      content +
      '상차시간 : ' +
      wborderStore.loadingDate +
      ' / ' +
      wborderStore.loadingTime +
      '\n';
    content =
      content +
      '하차시간 : ' +
      wborderStore.unloadingDate +
      ' / ' +
      wborderStore.unloadingTime;
    window.navigator.clipboard.writeText(content).then(res => {
      alert('복사완료');
    });
  };

  //메세지 전송 권한 요청
  fnGetMsgAuthForCustomer = () => {
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgAuth',
      method: 'post',
    })
      .then(response => {
        that.fnSendMsgForCustomer(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //기사님 문자보내기
  fnSendMsgForCustomer = authData => {
    const { wborderStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content = null;
    if (
      wborderStore.loadingCompNm != null &&
      wborderStore.unloadingCompNm != null
    ) {
      content =
        wborderStore.loadingCompNm +
        ' -> ' +
        wborderStore.unloadingCompNm +
        '\n';
    }
    if (
      wborderStore.loadingCompNm != null &&
      wborderStore.unloadingCompNm == null
    ) {
      content =
        wborderStore.loadingCompNm + ' -> ' + wborderStore.unloadingAdd + '\n';
    }

    if (
      wborderStore.loadingCompNm == null &&
      wborderStore.unloadingCompNm != null
    ) {
      content =
        wborderStore.loadingAdd + ' -> ' + wborderStore.unloadingCompNm + '\n';
    }

    if (
      wborderStore.loadingCompNm == null &&
      wborderStore.unloadingCompNm == null
    ) {
      content =
        wborderStore.loadingAdd + ' -> ' + wborderStore.unloadingAdd + '\n';
    }

    content =
      content + wborderStore.driverNm + ' / ' + wborderStore.driverHpNo + '\n';

    content =
      content +
      wborderStore.driverVcWeight +
      wborderStore.driverVcTyp +
      ' / ' +
      wborderStore.vcNo +
      '\n';
    content =
      content +
      wborderStore.totalFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '원 ' +
      '배차했습니다. \n';
    content = content + '감사합니다.\n\n';

    content =
      content +
      '상차시간 : ' +
      wborderStore.loadingDate +
      ' / ' +
      wborderStore.loadingTime +
      '\n';
    content =
      content +
      '하차시간 : ' +
      wborderStore.unloadingDate +
      ' / ' +
      wborderStore.unloadingTime;

    phone.push(wborderStore.clientStaffHpNo);
    id.push('ttSystem');
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgSendLmsforTrucker',
      method: 'get',
      params: params,
    })
      .then(function (response) {
        if (response.data.code == '1000') {
          alert('문자전송완료');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //메세지 전송 권한 요청
  fnGetMsgAuthForTruckerInvoice = () => {
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgAuth',
      method: 'post',
    })
      .then(response => {
        that.fnSendMsgForTruckerInvoice(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //기사님 문자보내기
  fnSendMsgForTruckerInvoice = authData => {
    const { wborderStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content = null;

    content =
      '거래명세표 하차지에서 사인 받으시고 꼭 사진 찍어서 보내주셔야 합니다.\n' +
      '(거래명세서가 없으면 하차 사진이라도 꼭 부탁드립니다)\n' +
      '*010-9694-9505* \n' +
      '※송장이 한장밖에 없어도 사진 찍어서 보내주시고 하차지에 전달 해주세요※';

    phone.push(wborderStore.driverHpNo);
    id.push('ttSystem');
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgSendLmsforTrucker',
      method: 'get',
      params: params,
    })
      .then(function (response) {
        if (response.data.code == '1000') {
          alert('문자전송완료');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnCompSelectModalOpen = () => {
    this.setState({
      compSelectModal: true,
    });
    this.fnSelectCompList();
  };

  fnSelectCompList = () => {
    const { compStore } = this.props;
    let params = {};
    let that = this;
    params.startCnt =
      compStore.compListCurrentPageNum == 1
        ? 0
        : 50 * (Number(compStore.compListCurrentPageNum) - 1) + 1;

    if (compStore.compListTotalPageNum == 0) {
      params.endCnt = 50;
    } else if (
      compStore.compListTotalPageNum > 0 &&
      compStore.compListTotalCount - 50 * compStore.compListCurrentPageNum >= 0
    ) {
      params.endCnt = 50;
    } else if (
      compStore.compListTotalPageNum > 0 &&
      compStore.compListTotalCount - 50 * compStore.compListCurrentPageNum < 0
    ) {
      params.endCnt =
        compStore.compListTotalCount -
        50 * (compStore.compListCurrentPageNum - 1);
    }

    params.compCd = 'C';

    axios({
      url: API_SELECT_COMP_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          let pageNum = Math.ceil(response.data.totalPageNum / 50);
          compStore.setCompList(response.data.data);
          compStore.setCompListTotalCount(response.data.totalPageNum);
          compStore.setCompListTotalPageNum(pageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnDirectListMove = pageNum => {
    const { compStore } = this.props;
    compStore.initCompList();
    compStore.setCompListCurrentPageNum(pageNum);
    this.fnSelectCompList();
  };

  fnSelectedComp = value => {
    const { compStore } = this.props;
    compStore.setSelectedComp(value);
  };

  fnUpdateCompNo = () => {
    const { compStore, wborderStore } = this.props;
    let params = {};
    let that = this;

    params.compNo = compStore.selectedComp.compNo;
    params.RNo = wborderStore.selectedOrder.RNo;
    console.log(params);

    axios({
      url: API_UPDATE_WORKBENCH_FREIGHT_COMP_NO,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //날짜 선택
  fnSelectDate = value => {
    const { compStore, wborderStore } = this.props;
    this.setState({
      selectedDate: value,
    });
    this.fnSelectOrderList(value);
    sessionStorage.setItem('loadingDate', value);
  };

  render() {
    const { compStore } = this.props;
    registerLocale('ko', ko);
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 50px)',
          background: COLOR_GRAY_GR02,
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            width: '40%',
            margin: '1%',
            height: 'calc(100vh - 100px)',
            background: COLOR_WHITE,
            borderRadius: '10px',
            padding: '10px',
            float: 'left',
            overflowY: 'scroll',
          }}
        >
          <div style={{ width: '100%', height: '50px' }}>
            <span>날짜입력</span>
            <DatePicker
              locale="ko"
              selected={this.state.selectedDate}
              onChange={date => {
                this.fnSelectDate(date);
              }}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <OrderList fnSetSelectedOrder={this.fnSetSelectedOrder} />
        </div>
        {this.state.upsertView == false ? null : (
          <div
            style={{
              width: '56%',
              margin: '1%',
              height: 'calc(100vh - 100px)',
              background: COLOR_WHITE,
              borderRadius: '10px',
              padding: '10px',
              overflowY: 'scroll',
            }}
          >
            <WorkBenchOrderDetail
              fnUpdateFreight={this.fnUpdateFreight}
              fnAutoCompleteVcInfo={this.fnAutoCompleteVcInfo}
              fnGetMsgAuth={this.fnGetMsgAuth}
              fnSetLogisFee={this.fnSetLogisFee}
              fnSetCommission={this.fnSetCommission}
              fnMsgCopyForCustomer={this.fnMsgCopyForCustomer}
              fnGetMsgAuthForCustomer={this.fnGetMsgAuthForCustomer}
              fnGetMsgAuthForTruckerInvoice={this.fnGetMsgAuthForTruckerInvoice}
              fnCompSelectModalOpen={this.fnCompSelectModalOpen}
            />
          </div>
        )}
        <Modal
          size="large"
          open={this.state.compSelectModal}
          onClose={() => this.setState({ compSelectModal: false })}
          style={{ height: '850px', overflowY: 'scroll' }}
        >
          <Modal.Header>거래처 선택</Modal.Header>
          <Modal.Content>
            <CompList
              compListCurrentPageNum={compStore.compListCurrentPageNum}
              compListTotalPageNum={compStore.compListTotalPageNum}
              fnDirectListMove={this.fnDirectListMove}
              fnUpdateViewOpen={this.fnSelectedComp}
            />
          </Modal.Content>
          <div
            style={{ float: 'right', marginTop: '20px', marginRight: '20px' }}
          >
            <Button
              positive
              onClick={() => {
                this.fnUpdateCompNo();
              }}
            >
              거래처 확정
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
export default WorkBenchOrderListContainer;
