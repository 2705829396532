import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button, Table } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import {
  API_INSERT_FAVORITE_CATE,
  API_UPDATE_FAVORITE_CATE,
  API_SELECT_FAVORITE_CATE_LIST,
  API_SELECT_FAVORITE_LIST,
  API_DELETE_FAVORITE_CATE,
} from '../../../common/ApiAddress/FavoriteAPI';
import FavoriteCateInsertModal from '../../../Component/Management/Favorite/FavoriteCateInsertModal';
import FavoriteCateUpdateModal from '../../../Component/Management/Favorite/FavoriteCateUpdateModal';
import FavoriteCateList from '../../../Component/Management/Favorite/FavoriteCateList';
import FavoriteList from '../../../Component/Management/Favorite/FavoriteList';
import FavoriteUpdateContainer from './FavoriteUpdateContainer';
import FavoriteCateDeleteModal from '../../../Component/Management/Favorite/FavoriteCateDeleteModal';
@inject(stores => ({
  favoStore: stores.FavoriteStore,
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  payStore: stores.PaymentStore,
}))
@observer
class FavoriteManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insertCateModal: false,
      updateCateModal: false,
      deleteCateModal: false,
      selectedCateIndex: 0,
      selectedFavoIndex: 0,
    };
  }

  componentDidMount() {
    this.fnSelecteFavoCateList();
  }

  componentWillUnmount() {
    const { favoStore } = this.props;
    favoStore.initCateList();
    favoStore.initSelectedCate();
    favoStore.initFavoList();
    //favoStore.initSelectedFavo();
    favoStore.initFavoForm();
  }

  fnSelecteFavoCateList = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.registId = sessionStorage.getItem('customerId');
    axios({
      url: API_SELECT_FAVORITE_CATE_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          //favoStore.initSelectedCate();
          favoStore.setCateList(response.data.data);
          that.fnSetCate(response.data.data[that.state.selectedCateIndex]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnInsertFavoCate = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.cateNm = favoStore.cateNm;
    params.registId = sessionStorage.getItem('customerId');
    axios({
      url: API_INSERT_FAVORITE_CATE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          favoStore.initCateNm();

          that.fnCloseInsertCateModal();
          that.setState({
            selectedCateIndex: favoStore.cateList.length,
          });

          that.fnSelecteFavoCateList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOpenInsertCateModal = () => {
    const { favoStore } = this.props;
    favoStore.initCateNm();
    this.setState({
      insertCateModal: true,
    });
  };

  fnCloseInsertCateModal = () => {
    this.setState({
      insertCateModal: false,
    });
  };

  fnUpdateFavoCate = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.cateNo = favoStore.selectedCate.cateNo;
    params.cateNm = favoStore.cateNm;
    params.updateId = sessionStorage.getItem('customerId');
    axios({
      url: API_UPDATE_FAVORITE_CATE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnCloseUpdateCateModal();
          that.fnSelecteFavoCateList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOpenUpdateCateModal = () => {
    const { favoStore } = this.props;
    favoStore.setCateNm(favoStore.selectedCate.cateNm);
    this.setState({
      updateCateModal: true,
    });
  };

  fnCloseUpdateCateModal = () => {
    const { favoStore } = this.props;
    favoStore.initCateNm();
    this.setState({
      updateCateModal: false,
    });
  };

  fnSetCate = (value, i) => {
    const { favoStore } = this.props;
    favoStore.setSelectedCate(value);
    this.setState({
      selectedCateIndex: i,
      selectedFavoIndex: 0,
    });
    this.fnSelectFavoList();
  };

  fnSelectFavoList = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.registId = sessionStorage.getItem('customerId');
    params.cateNo = favoStore.selectedCate.cateNo;
    axios({
      url: API_SELECT_FAVORITE_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          favoStore.setFavoList(response.data.data);
          if (response.data.data.length > 0) {
            that.fnSetFavo(
              response.data.data[that.state.selectedFavoIndex],
              that.state.selectedFavoIndex,
            );
          }
        } else {
          favoStore.initFavoList();
          favoStore.initFavoForm();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetFavo = (value, i) => {
    const { favoStore, vcStore } = this.props;
    console.log(i);
    favoStore.initFavoForm();
    //favoStore.initSelectedFavo();
    setTimeout(() => this.fnSetFavoriteContent(value, i), 200);
  };

  fnSetFavoriteContent = (data, i) => {
    const { favoStore, vcStore } = this.props;
    favoStore.setSelectedFavo(data);
    this.setState({
      selectedFavoIndex: i,
    });

    let selectedFavo = { ...favoStore.selectedFavo };
    favoStore.setFavoNm(selectedFavo.favoNm);
    favoStore.setLogisCd(selectedFavo.logisCd);
    favoStore.setFreightInfo(selectedFavo.freightInfo);
    favoStore.setVcCnt(1);
    favoStore.setFreightWeight(selectedFavo.freightWeight);
    favoStore.setLoadingCd(selectedFavo.loadingCd);
    favoStore.setLoadingNm(selectedFavo.loadingCdNm);
    favoStore.setUnLoadingCd(selectedFavo.unloadingCd);
    favoStore.setUnLoadingNm(selectedFavo.unloadingCdNm);

    //상차지주소
    favoStore.setDepartureAdd(selectedFavo.loadingBasicAdd);
    favoStore.setDepartureDetailAdd(selectedFavo.loadingDetailAdd);
    favoStore.setDepartureCoord(selectedFavo.loadingCoord);
    //하차지주소
    favoStore.setArriveAdd(selectedFavo.unloadingBasicAdd);
    favoStore.setArriveDetailAdd(selectedFavo.unloadingDetailAdd);
    favoStore.setDepartureCoord(selectedFavo.loadingCoord);
    favoStore.setArriveCoord(selectedFavo.unloadingCoord);
    //경유지주소
    if (selectedFavo.waypoint1BasicAdd != null) {
      favoStore.setWayPointsList(0, 'add', {
        basicAdd: selectedFavo.waypoint1BasicAdd,
        detailAdd: selectedFavo.waypoint1DetailAdd,
      });
    }
    if (selectedFavo.waypoint2BasicAdd != null) {
      favoStore.setWayPointsList(1, 'add', {
        basicAdd: selectedFavo.waypoint2BasicAdd,
        detailAdd: selectedFavo.waypoint2DetailAdd,
      });
    }
    if (selectedFavo.waypoint3BasicAdd != null) {
      favoStore.setWayPointsList(2, 'add', {
        basicAdd: selectedFavo.waypoint3BasicAdd,
        detailAdd: selectedFavo.waypoint3DetailAdd,
      });
    }

    //차량관련
    vcStore.setVcTypCd(selectedFavo.vcTypCd);
    vcStore.setVcWeightCd(selectedFavo.vcWeightCd);
    //결제관련
    favoStore.setDetailViewFalse();
    favoStore.setUpdateViewTrue();

    //상하차지 담당자
    let loadingStaffNm = selectedFavo.loadingStaffInfo.split('|');
    if (
      loadingStaffNm[0] == null ||
      loadingStaffNm[0] == '' ||
      loadingStaffNm[0] == 'null'
    ) {
      favoStore.initLoadingStaffNm();
    } else {
      favoStore.setLoadingStaffNm(loadingStaffNm[0]);
    }
    if (
      loadingStaffNm[1] == null ||
      loadingStaffNm[1] == '' ||
      loadingStaffNm[1] == 'null'
    ) {
      favoStore.initLoadingStaffRank();
    } else {
      favoStore.setLoadingStaffRank(loadingStaffNm[1]);
    }

    if (
      selectedFavo.loadingStaffHpNo == null ||
      selectedFavo.loadingStaffHpNo == '' ||
      selectedFavo.loadingStaffHpNo == 'null'
    ) {
      favoStore.initLoadingStaffHpNo();
    } else {
      favoStore.setLoadingStaffHpNo(selectedFavo.loadingStaffHpNo);
    }

    let unloadingStaffNm = selectedFavo.unloadingStaffInfo.split('|');
    if (
      unloadingStaffNm[0] == null ||
      unloadingStaffNm[0] == '' ||
      unloadingStaffNm[0] == 'null'
    ) {
      favoStore.initUnLoadingStaffNm();
    } else {
      favoStore.setUnLoadingStaffNm(unloadingStaffNm[0]);
    }
    if (
      unloadingStaffNm[1] == null ||
      unloadingStaffNm[1] == '' ||
      unloadingStaffNm[1] == 'null'
    ) {
      favoStore.initUnLoadingStaffRank();
    } else {
      favoStore.setUnLoadingStaffRank(unloadingStaffNm[1]);
    }

    if (
      selectedFavo.unloadingStaffHpNo == null ||
      selectedFavo.unloadingStaffHpNo == '' ||
      selectedFavo.unloadingStaffHpNo == 'null'
    ) {
      favoStore.initUnLoadingStaffHpNo();
    } else {
      favoStore.setUnLoadingStaffHpNo(selectedFavo.loadingStaffHpNo);
    }
  };

  fnOpenDeleteCateModal = () => {
    this.setState({
      deleteCateModal: true,
    });
  };

  fnCloseDeleteCateModal = () => {
    this.setState({
      deleteCateModal: false,
    });
  };

  fnDeleteFavoCate = () => {
    const { favoStore } = this.props;
    let params = {};
    let that = this;
    params.updateId = sessionStorage.getItem('customerId');
    params.cateNo = favoStore.selectedCate.cateNo;
    axios({
      url: API_DELETE_FAVORITE_CATE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.setState({
            selectedCateIndex: 0,
          });
          favoStore.initSelectedFavo();
          that.fnCloseDeleteCateModal();
          that.fnSelecteFavoCateList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectedFavoIndexInit = () => {
    this.setState({
      selectedFavoIndex: 0,
    });
  };

  render() {
    const { favoStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '20%',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            boxShadow: '4px 4px 10px rgba(5,5,5,0.1)',
            borderRadius: '10px',
            height: '90%',
            marginTop: '2.5%',
            marginBottom: '2.5%',
            marginRight: '1%',
            marginLeft: '1%',
          }}
          id="faveCateList"
        >
          <div style={{ paddingTop: '10px', paddingLeft: '10px' }}>
            <h2>즐겨찾기 분류</h2>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            {favoStore.selectedCate != null ? (
              <Fragment>
                <Button
                  color="brown"
                  onClick={() => this.fnOpenUpdateCateModal()}
                  style={{ height: '36px' }}
                >
                  분류 수정
                </Button>
                <Button
                  color="red"
                  onClick={() => this.fnOpenDeleteCateModal()}
                  style={{ height: '36px' }}
                >
                  분류 삭제
                </Button>
              </Fragment>
            ) : null}
            <Button
              color="green"
              onClick={() => this.fnOpenInsertCateModal()}
              style={{ marginRight: '20px', marginBottom: '10px' }}
            >
              새 분류
            </Button>
          </div>
          <FavoriteCateList fnSetCate={this.fnSetCate} />
        </div>
        <div
          style={{
            width: '20%',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            boxShadow: '4px 4px 10px rgba(5,5,5,0.1)',
            borderRadius: '10px',
            height: '90%',
            marginTop: '2.5%',
            marginBottom: '2.5%',
            marginRight: '1%',
            marginLeft: '1%',
          }}
          id="faveCateList"
        >
          {favoStore.selectedCate == null ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90%',
              }}
            >
              <h3>즐겨찾기 분류를 먼저 선택해주세요.</h3>
            </div>
          ) : (
            <Fragment>
              <div style={{ paddingTop: '10px', paddingLeft: '10px' }}>
                <h2>즐겨찾기 목록</h2>
              </div>
              <div style={{ height: '90%', padding: '20px' }}>
                <FavoriteList fnSetFavo={this.fnSetFavo} />
              </div>
            </Fragment>
          )}
        </div>
        {favoStore.selectedFavo == null ? null : (
          <div
            style={{
              width: '53%',
              float: 'left',
              overflow: 'scroll',
              overflowX: 'hidden',
              height: '90%',
              marginTop: '2.5%',
              marginBottom: '2.5%',
              marginRight: '1%',
              marginLeft: '1%',
              boxShadow: '4px 4px 10px rgba(5,5,5,0.1)',
              borderRadius: '10px',
            }}
            id="faveCateList"
          >
            <FavoriteUpdateContainer
              fnSelectFavoList={this.fnSelectFavoList}
              fnSelectedFavoIndexInit={this.fnSelectedFavoIndexInit}
            />
          </div>
        )}
        <FavoriteCateInsertModal
          insertCateModal={this.state.insertCateModal}
          fnCloseInsertCateModal={this.fnCloseInsertCateModal}
          fnInsertFavoCate={this.fnInsertFavoCate}
        />
        <FavoriteCateUpdateModal
          updateCateModal={this.state.updateCateModal}
          fnCloseUpdateCateModal={this.fnCloseUpdateCateModal}
          fnUpdateFavoCate={this.fnUpdateFavoCate}
        />
        <FavoriteCateDeleteModal
          deleteCateModal={this.state.deleteCateModal}
          fnCloseDeleteCateModal={this.fnCloseDeleteCateModal}
          fnDeleteFavoCate={this.fnDeleteFavoCate}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(FavoriteManagementContainer);
