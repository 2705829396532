import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  wborderStore: stores.WorkbenchOrderStore,
}))
@observer
class OrderList extends Component {
  render() {
    const { wborderStore } = this.props;
    return (
      <Fragment>
        <Table style={{ border: '0px', width: '100%', paddingBottom: '30px' }}>
          {wborderStore.orderList == null ||
          wborderStore.orderList.length == 0 ? null : (
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    width: '7%',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                  }}
                >
                  번호
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '15%',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                  }}
                >
                  상차일자
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '13%',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                  }}
                >
                  상태
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '23%',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                  }}
                >
                  발주처
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    maxWidth: '30px',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  상차지
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '15%',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                  }}
                >
                  발주처 지정
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          {wborderStore.orderList == null ||
          wborderStore.orderList.length == 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="14" textAlign="center">
                  등록된 오더가 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {wborderStore.orderList.map((data, i) => (
                <React.Fragment key={data.RNo}>
                  <Table.Row
                    onClick={() => this.props.fnSetSelectedOrder(data, i)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        wborderStore.selectedOrder != null &&
                        wborderStore.selectedOrder.RNo == data.RNo
                          ? COLOR_GREEN_PRIMARY
                          : COLOR_WHITE,
                      color:
                        wborderStore.selectedOrder != null &&
                        wborderStore.selectedOrder.RNo == data.RNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    <Table.Cell>{data.RNo}</Table.Cell>
                    <Table.Cell style={{ fontSize: '13px' }}>
                      {data.loadingDate}
                    </Table.Cell>
                    <Table.Cell>
                      <div
                        style={{
                          width: '70px',
                          height: '35px',
                          background:
                            data.freightCd == 'IFRCD001'
                              ? '#f5d742'
                              : data.freightCd == 'IFRCD002'
                              ? COLOR_GREEN_PRIMARY
                              : data.freightCd == 'IFRCD011'
                              ? COLOR_PRIMARY
                              : data.freightCd == 'IFRCD012'
                              ? COLOR_PRIMARY
                              : null,

                          color:
                            data.freightCd == 'IFRCD001'
                              ? COLOR_BLACK
                              : data.freightCd == 'IFRCD002'
                              ? COLOR_WHITE
                              : data.freightCd == 'IFRCD011'
                              ? COLOR_WHITE
                              : data.freightCd == 'IFRCD012'
                              ? COLOR_WHITE
                              : null,

                          border:
                            data.freightCd == 'IFRCD001'
                              ? '#f5d742'
                              : data.freightCd == 'IFRCD002'
                              ? COLOR_GREEN_PRIMARY
                              : data.freightCd == 'IFRCD011'
                              ? COLOR_PRIMARY
                              : data.freightCd == 'IFRCD012'
                              ? COLOR_PRIMARY
                              : null,
                          padding: '2px 4px',
                          borderRadius: '3px',
                          fontSize: '14px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontWeight: '600',
                        }}
                      >
                        {data.freightCd == 'IFRCD001'
                          ? '예약확정'
                          : data.freightCd == 'IFRCD002'
                          ? '배차완료'
                          : data.freightCd == 'IFRCD011'
                          ? '예약취소'
                          : data.freightCd == 'IFRCD012'
                          ? '배차취소'
                          : null}
                      </div>
                    </Table.Cell>
                    <Table.Cell>{data.client}</Table.Cell>
                    <Table.Cell
                      style={{
                        maxWidth: '30px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.loadingAdd}
                    </Table.Cell>
                    <Table.Cell>{data.compNo != null ? 'Y' : 'N'}</Table.Cell>
                    {/* <Table.Cell>
                      {data.clientStaffNm != null ? data.clientStaffNm : 'X'}/
                      {data.clientStaffHpNo}
                    </Table.Cell>
                    <Table.Cell>
                      <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>
                        {data.loadingCompNm}
                      </p>
                      <p>{data.loadingAdd}</p>
                    </Table.Cell>
                    <Table.Cell>
                      {data.loadingStaff}/{data.loadingStaffHpNo}
                    </Table.Cell>

                    <Table.Cell>
                      <p style={{ marginBottom: '5px', fontWeight: 'bold' }}>
                        {data.unloadingCompNm}
                      </p>
                      <p>{data.unloadingAdd}</p>
                    </Table.Cell>
                    <Table.Cell>
                      {data.unloadingStaff}/{data.unloadingStaffHpNo}
                    </Table.Cell>
                    <Table.Cell>
                      {data.vcTyp} / {data.vcWeight}
                    </Table.Cell>
                    <Table.Cell>{data.freightInfo}</Table.Cell>
                    <Table.Cell>{data.freightWeight}</Table.Cell>
                    <Table.Cell>{data.freightMemo}</Table.Cell>
                    <Table.Cell>
                      {data.unloadingDateStr}/{data.unloadingTime}
                    </Table.Cell>
                    <Table.Cell>{data.payCd}</Table.Cell> */}
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
        {/* <div style={{ textAlign: 'right' }}>
          <Pagination
            id="paginationComp"
            boundaryRange={1}
            defaultActivePage={this.props.compListCurrentPageNum}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={this.props.compListTotalPageNum}
            onPageChange={(e, data) => {
              this.props.fnDirectListMove(data.activePage);
            }}
            style={{ border: '0px' }}
          />
        </div> */}
      </Fragment>
    );
  }
}

export default OrderList;
