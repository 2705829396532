import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../../common/Color';

@inject(stores => ({
  feeStore: stores.FreightFeeStore,
}))
@observer
class SiguList extends Component {
  render() {
    const { feeStore } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>시군구코드</Table.HeaderCell>
            <Table.HeaderCell>시군구명</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {feeStore.siguList == null || feeStore.siguList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                신군구가 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {feeStore.siguList.map((data, i) => (
              <React.Fragment>
                <Table.Row onClick={() => this.props.fnSelectDongList(data)}>
                  <Table.Cell
                    style={{
                      backgroundColor:
                        feeStore.selectedSigu != null &&
                        feeStore.selectedSigu.siguCd === data.siguCd
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        feeStore.selectedSigu != null &&
                        feeStore.selectedSigu.siguCd === data.siguCd
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.siguCd}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      backgroundColor:
                        feeStore.selectedSigu != null &&
                        feeStore.selectedSigu.siguCd === data.siguCd
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        feeStore.selectedSigu != null &&
                        feeStore.selectedSigu.siguCd === data.siguCd
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.siguCdNm}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default SiguList;
