import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import EmpList from './EmpList';
import EmpUpsertContainer from './EmpUpsertContainer';
import {
  API_SELECT_EMP_LIST,
  API_INSERT_EMP,
  API_UPDATE_EMP,
} from '../../../../common/ApiAddress/EmpAPI';
@inject(stores => ({
  empStore: stores.EmpStore,
}))
@observer
class EmpManagementContainer extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
  };

  componentDidMount() {
    this.fnSelectEmpList();
  }

  //사원 리스트 불러오기
  fnSelectEmpList = () => {
    const { empStore } = this.props;
    let params = {};
    let that = this;
    axios({
      url: API_SELECT_EMP_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          empStore.setEmpList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnViewOpen = value => {
    const { empStore } = this.props;
    empStore.setSelectedEmp(value);
    empStore.setEmpId(value.empId);
    empStore.setPw(value.pw);
    empStore.setEmpNm(value.empNm);
    empStore.setHpNo(value.hpNo);
    if (value.useYn == 'Y') {
      empStore.setUseYnTrue();
    } else {
      empStore.setUseYnFalse();
    }
    this.setState({
      upsertView: true,
      upsertMode: 'U',
    });
  };

  fnInitForm = () => {
    const { empStore } = this.props;
    empStore.initForm();
  };

  fnUpsertEmp = () => {
    const { empStore } = this.props;
    let params = {};

    params.empId = empStore.empId;
    params.pw = empStore.pw;
    params.empNm = empStore.empNm;
    params.hpNo = empStore.hpNo;
    params.registId = sessionStorage.getItem('empId');

    //추후 부서 번호 추가해야함
    params.deptNo = null;

    if (this.state.upsertMode == 'U') {
      params.empNo = empStore.selectedEmp.empNo;
      params.updateId = sessionStorage.getItem('empId');
      if (empStore.useYn == true) {
        params.useYn = 'Y';
      } else {
        params.useYn = 'N';
      }
    }

    console.log(params);

    let that = this;
    axios({
      url: this.state.upsertMode == 'I' ? API_INSERT_EMP : API_UPDATE_EMP,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          that.fnInitForm();
          that.fnSelectEmpList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <EmpList fnViewOpen={this.fnViewOpen} />
        <div style={{ padding: '10px', float: 'right' }}>
          <Button
            positive
            onClick={() => {
              this.fnInitForm();
              this.setState({
                upsertView: true,
                upsertMode: 'I',
              });
            }}
          >
            신규 사원 추가
          </Button>
        </div>
        {this.state.upsertView == true ? (
          <EmpUpsertContainer fnUpsertEmp={this.fnUpsertEmp} />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(EmpManagementContainer);
