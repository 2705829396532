import React, { Component } from 'react';
import { Button, Checkbox, Form, Input } from 'semantic-ui-react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import {
  API_ADMIN_LOGIN,
  API_GENERATE_NICD_ACCESS_TOKEN,
} from '../../common/ApiAddress/AccountAPI';
import { API_SELECT_MESSAGE_TEMPLATE } from '../../common/ApiAddress/MessageAPI';
import {
  COLOR_GREEN_PRIMARY,
  COLOR_GREEN_SECOND,
  COLOR_WHITE,
} from '../../common/Color';
import {
  STR_ID,
  STR_PW,
  STR_LOGIN,
  STR_SEN_2,
  STR_SEN_3,
} from '../../common/Terms';
import MainFooter from '../../Component/Common/MainFooter';
import { fnTest } from '../../Util/CommonUtil';
import { ReactComponent as LogoBig } from '../../assets/icon/logo_long.svg';
@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class LoginContainer extends React.Component {
  //로그인
  fnLogin = () => {
    const { accStore } = this.props;

    const optionFail = {
      style: { color: 'red' },
    };

    const optionSuccess = {
      style: { color: 'green' },
    };

    let params = {};
    let that = this;
    params.customerId = accStore.customerId;
    params.pw = accStore.pw;

    console.log(API_ADMIN_LOGIN);
    axios({
      url: API_ADMIN_LOGIN,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          //아이디, 토큰 저장
          sessionStorage.setItem('customerId', response.data.data.customerId);
          sessionStorage.setItem('customerCd', response.data.data.customerCd);
          sessionStorage.setItem('customerNo', response.data.data.customerNo);
          sessionStorage.setItem('groupNo', response.data.data.groupNo);
          toast(
            '환영합니다.' + response.data.data.customerId + '님',
            optionSuccess,
          );
          that.props.history.push('/main');
        }
        if (response.data.resultCode == 200) {
          toast(response.data.resultMsg, optionFail);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnTestMsgAuth = () => {
    let that = this;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgAuth',
      method: 'post',
    })
      .then(function (response) {
        that.fnSelectTemplate(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectTemplate = data => {
    let that = this;
    let params = {};
    let authData = this.data;
    params.groupKey = 'IMSTY000';
    axios({
      url: API_SELECT_MESSAGE_TEMPLATE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        that.fnTestMsgSend(data, response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnTestMsgSend = (authData, templateData) => {
    let that = this;
    let data = {};
    let merge_keys = [];
    let mergeSplt = templateData.mergeKeys.split('|');
    for (let i = 0; i < mergeSplt.length; i++) {
      merge_keys.push(mergeSplt[i]);
    }
    let merge_vals = ['500,000', '2020-04-31'];
    data.access_token = authData.access_token;
    data.groupKey = templateData.groupKey;
    data.text = templateData.text;
    data.phone = ['01031254802'];
    data.id = ['svchk12'];
    data.merge_vals = merge_vals;
    data.merge_keys = merge_keys;

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'msgSend',
      method: 'get',
      params: data,
    })
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //Promis Test
  fnTest = () => {
    let value = fnTest();
    value.then(result => console.log(result));
  };

  render() {
    const { accStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 60px)',
            display: 'flex',
            alignItems: 'center',
            background: '#F5F5F5',
          }}
        >
          <div style={{ width: '25%', margin: '0 auto ', marginTop: '-80px' }}>
            <LogoBig
              style={{
                display: 'block',
                marginBottom: '27px',
                marginLeft: '20px',
              }}
            />
            <Form
              style={{
                borderRadius: '5px',
                padding: '20px',
                background: COLOR_WHITE,
                boxShadow: '0px 4px 10px 2px rgba(0,0,0,0.15)',
              }}
            >
              <Form.Field style={{ marginBottom: '23px' }}>
                <label style={{ fontSize: '14px' }}>
                  {STR_ID}
                  <span style={{ color: '#EA3396', marginLeft: '4px' }}>*</span>
                </label>
                <Input
                  style={{ height: '60px', fontSize: '20px' }}
                  placeholder={STR_SEN_2}
                  onChange={e => accStore.setCustomerId(e.target.value)}
                  value={accStore.customerId || ''}
                  type="text"
                />
              </Form.Field>
              <Form.Field>
                <label style={{ fontSize: '14px' }}>
                  {STR_PW}
                  <span style={{ color: '#EA3396', marginLeft: '4px' }}>*</span>
                </label>
                <Input
                  style={{
                    height: '60px',
                    fontSize: '20px',
                  }}
                  placeholder={STR_SEN_3}
                  onChange={e => accStore.setPw(e.target.value)}
                  value={accStore.pw || ''}
                  type="password"
                  onKeyPress={e => {
                    if (e.charCode == 13) {
                      this.fnLogin();
                    }
                  }}
                />
              </Form.Field>

              <div
                onClick={() => {
                  this.fnLogin();
                }}
                style={{
                  width: '100%',
                  height: '60px',
                  marginTop: '33px',
                  marginBottom: '33px',
                  background: COLOR_GREEN_PRIMARY,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                  color: COLOR_WHITE,
                  cursor: 'pointer',
                }}
              >
                {STR_LOGIN}
              </div>
            </Form>
            <div
              style={{
                width: 'calc(100% - 40px)',
                textAlign: 'center',
                borderTop: '1px solid rgba(255,255,255 ,0.8)',
                marginLeft: '20px',
              }}
            >
              <div style={{ display: 'flex', flexGrow: 2 }}>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <p
                    onClick={() => this.props.history.push('/searchacc')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '18px',
                      cursor: 'pointer',
                      fontWeight: '400',
                    }}
                  >
                    아이디 / 비밀번호 찾기
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                  }}
                >
                  <p
                    onClick={() => this.props.history.push('/regist/accinfo')}
                    style={{
                      color: COLOR_GREEN_SECOND,
                      fontSize: '14px',
                      paddingTop: '18px',
                      cursor: 'pointer',
                      fontWeight: '700',
                    }}
                  >
                    회원가입
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <p style={{ color: '#fff' }} onClick={() => this.fnTest()}>
            테스트 함수
          </p> */}
        </div>
        <MainFooter />
      </React.Fragment>
    );
  }
}

export default LoginContainer;
