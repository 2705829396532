import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  compStore: stores.CompStore,
}))
@observer
class StatementHeader extends Component {
  render() {
    const { compStore } = this.props;
    return (
      <Fragment>
        <tr
          style={{
            width: '690px',
            height: this.props.height,
          }}
        >
          <td
            style={{
              borderTop: '2px solid',
              width: '30px',
              borderLeft: '2px solid',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '60px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '60px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '40px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '30px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '120px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '30px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '60px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '60px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '50px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '30px',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
          <td
            style={{
              borderTop: '2px solid',
              width: '120px',
              borderRight: '2px solid',
              padding: this.props.height == '0px' ? '0px' : null,
            }}
          />
        </tr>
      </Fragment>
    );
  }
}

export default StatementHeader;
