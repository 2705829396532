import React, { Component } from 'react';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import Agreements from '../../Component/Common/Agreements';
import Privacy from '../../Component/Common/Privacy';
import { COLOR_PRIMARY, COLOR_WHITE } from '../../common/Color';
@inject(stores => ({
  accStore: stores.AccStore,
}))
@observer
class AgreementContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreeTotal: false,
      agree1: false,
      agree2: false,
      agree3: false,
    };
  }

  fnMoveToNext = () => {
    if (
      this.state.agree1 != true ||
      this.state.agree2 != true ||
      this.state.agree3 != true ||
      this.state.agreeTotal != true
    ) {
      alert('약관에 동의해주세요.');
      return false;
    } else {
      this.props.history.push('/regist/accinfo');
    }
  };

  render() {
    const { accStore } = this.props;
    return (
      <div
        style={{
          width: '780px',
          height: '100vh',
          margin: '0 auto ',
          padding: '20px',
          paddingTop: '0px',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '50px',
            backgroundColor: COLOR_PRIMARY,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              color: COLOR_WHITE,
              fontSize: '20pt',
              paddingLeft: '10px',
            }}
          >
            회원가입 - 약관동의
          </p>
        </div>
        <div
          style={{
            textAlign: 'right',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <Checkbox
            label="전체동의"
            //defaultChecked={this.state.agreeTotal}
            checked={this.state.agreeTotal}
            onChange={(e, data) => {
              if (data.checked == true) {
                this.setState({
                  agreeTotal: true,
                  agree1: true,
                  agree2: true,
                  agree3: true,
                });
              } else {
                this.setState({
                  agreeTotal: false,
                  agree1: false,
                  agree2: false,
                  agree3: false,
                });
              }
            }}
          />
        </div>
        <div
          style={{ height: '300px', border: '1px solid', marginBottom: '50px' }}
        >
          <Agreements />
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Checkbox
              label="(필수) 서비스 이용약관동의"
              //defaultChecked={this.state.agree1}
              checked={this.state.agree1}
              onChange={(e, data) => {
                if (data.checked == true) {
                  if (this.state.agree2 == true && this.state.agree3 == true) {
                    this.setState({
                      agree1: true,
                      agreeTotal: true,
                    });
                  } else {
                    this.setState({
                      agree1: true,
                    });
                  }
                } else {
                  this.setState({
                    agree1: false,
                    agreeTotal: false,
                  });
                }
              }}
            />
          </div>
        </div>
        <div
          style={{ height: '300px', border: '1px solid', marginBottom: '50px' }}
        >
          <Privacy />
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Checkbox
              label="(필수) 개인(신용)정보의 수집, 이용에 관한 사항"
              //defaultChecked={this.state.agree2}
              checked={this.state.agree2}
              onChange={(e, data) => {
                if (data.checked == true) {
                  if (this.state.agree1 == true && this.state.agree3 == true) {
                    this.setState({
                      agree2: true,
                      agreeTotal: true,
                    });
                  } else {
                    this.setState({
                      agree2: true,
                    });
                  }
                } else {
                  this.setState({
                    agree2: false,
                    agreeTotal: false,
                  });
                }
              }}
            />
          </div>
        </div>
        <div
          style={{ height: '300px', border: '1px solid', marginBottom: '50px' }}
        >
          <Agreements />
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Checkbox
              label="(필수) 개인(신용)정보의 제 3자 제공에 관한 사항"
              //defaultChecked={this.state.agree3}
              checked={this.state.agree3}
              onChange={(e, data) => {
                if (data.checked == true) {
                  if (this.state.agree1 == true && this.state.agree2 == true) {
                    this.setState({
                      agree3: true,
                      agreeTotal: true,
                    });
                  } else {
                    this.setState({
                      agree3: true,
                    });
                  }
                } else {
                  this.setState({
                    agree3: false,
                    agreeTotal: false,
                  });
                }
              }}
            />
          </div>
        </div>

        <div style={{ width: '100%', height: '100px' }}>
          <div
            style={{
              width: '100%',
              backgroundColor: COLOR_PRIMARY,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '6px',
            }}
            onClick={() => this.fnMoveToNext()}
          >
            <p style={{ color: COLOR_WHITE, fontSize: '20pt' }}>다음</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AgreementContainer);
