import React, { Component } from 'react';
import {
  Button,
  Modal,
  Input,
  Form,
  Select,
  TextArea,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  depoStore: stores.DepositStore,
}))
@observer
class DepositUpdateModal extends Component {
  render() {
    const { depoStore } = this.props;
    const updateTyp = [
      { text: '증액', value: 'P' },
      { text: '감액', value: 'M' },
    ];
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={this.props.updateModal}
        onClose={() => this.props.fnDepositUpdateModalClose()}
        style={{ height: '250px' }}
      >
        <Modal.Header>예치금 변경</Modal.Header>
        <Modal.Content>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Form style={{ width: '50%', marginTop: '10px' }}>
              <Form.Field
                style={{
                  width: '100%',
                }}
              >
                <Select
                  style={{
                    minWidth: '122px',
                    maxWidth: '122px',
                    marginRight: '10px',
                  }}
                  placeholder={'선택'}
                  options={updateTyp}
                  onChange={(e, { value }) => this.props.fnSetUpdateTyp(value)}
                />
              </Form.Field>
            </Form>
            <div style={{ width: '20%', float: 'left', marginTop: '10px' }}>
              <label>금액</label>
            </div>
            <div style={{ width: '80%', marginTop: '10px' }}>
              <Input
                style={{ width: '80%' }}
                placeholder={'금액을 입력해주세요.'}
                onChange={e => depoStore.setBalance(e.target.value)}
                value={depoStore.balance || ''}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '10%', float: 'left', marginTop: '10px' }}>
              <label>사유</label>
            </div>
            <div style={{ width: '100%', float: 'left', marginTop: '10px' }}>
              <Input
                style={{ width: '80%' }}
                placeholder={'사유를 입력해주세요.'}
                onChange={e => depoStore.setContent(e.target.value)}
                value={depoStore.content || ''}
              />
            </div>
          </div>
        </Modal.Content>
        <div style={{ float: 'right', marginTop: '10px', marginRight: '20px' }}>
          <Button
            negative
            onClick={() => this.props.fnDepositUpdateModalClose()}
          >
            취소
          </Button>
          <Button positive onClick={() => this.props.fnUpsertDeposit()}>
            확인
          </Button>
        </div>
      </Modal>
    );
  }
}

export default DepositUpdateModal;
