import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
  wborderStore: stores.WorkbenchOrderStore,
}))
@observer
class StatementSum extends Component {
  render() {
    const { wborderStore } = this.props;
    return (
      <Fragment>
        <tr
          style={{
            width: '690px',
            height: '26px',
          }}
        >
          <td
            colSpan={3}
            style={{
              borderLeft: '2px solid',
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          >
            합계
          </td>
          <td
            colSpan={6}
            style={{
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          ></td>

          <td
            colSpan={2}
            style={{
              borderBottom: '2px solid',
              borderRight: '1px solid',
              textAlign: 'center',
            }}
          >
            {Number(wborderStore.totalFeeSum)
              .toFixed(0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </td>

          <td
            style={{
              borderRight: '2px solid',
              borderBottom: '2px solid',
              textAlign: 'center',
            }}
          ></td>
        </tr>
      </Fragment>
    );
  }
}

export default StatementSum;
