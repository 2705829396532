import React, { Fragment, Component } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR06,
  COLOR_GREEN_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  compStore: stores.CompStore,
}))
@observer
class CompList extends Component {
  render() {
    const { compStore } = this.props;
    return (
      <Fragment>
        <Table style={{ border: '0px' }}>
          {compStore.compList == null ||
          compStore.compList.length == 0 ? null : (
            <Table.Header style={{ border: '0px' }}>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    background: '#fff',
                  }}
                >
                  상호명
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    borderLeft: '0px',
                    background: '#fff',
                  }}
                >
                  구분
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: '25%',
                    borderBottom: '1px solid #CED4DA',
                    borderLeft: '0px',
                    background: '#fff',
                  }}
                >
                  연락처
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          {compStore.compList == null || compStore.compList.length == 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="14" textAlign="center">
                  등록된 거래처가 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {compStore.compList.map((data, i) => (
                <React.Fragment key={data.compNo}>
                  <Table.Row
                    onClick={() => this.props.fnUpdateViewOpen(data, i)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        compStore.selectedComp != null &&
                        compStore.selectedComp.compNo === data.compNo
                          ? COLOR_GREEN_PRIMARY
                          : COLOR_WHITE,
                      color:
                        compStore.selectedComp != null &&
                        compStore.selectedComp.compNo === data.compNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    <Table.Cell>{data.compNm}</Table.Cell>
                    <Table.Cell>
                      {data.compCd == 'C' ? '기업' : '개인'}
                    </Table.Cell>
                    <Table.Cell>{data.compHpNo}</Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
        <div style={{ textAlign: 'right' }}>
          <Pagination
            id="paginationComp"
            boundaryRange={1}
            defaultActivePage={this.props.compListCurrentPageNum}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={this.props.compListTotalPageNum}
            onPageChange={(e, data) => {
              this.props.fnDirectListMove(data.activePage);
            }}
            style={{ border: '0px' }}
          />
        </div>
      </Fragment>
    );
  }
}

export default CompList;
