import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class WorkbenchDriverStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable driverNm = null;
  @observable driverHpNo = null;
  @observable driverAccInfo = null;

  @observable vcNo = null;
  @observable vcWeight = null;
  @observable vcTyp = null;

  @observable selectedDriver = null;
  @observable driverList = [];

  @action
  setDriverNm = value => {
    this.driverNm = value;
  };

  @action
  initDriverNm = () => {
    this.driverNm = null;
  };

  @action
  setDriverHpNo = value => {
    this.driverHpNo = value;
  };

  @action
  initDriverHpNo = () => {
    this.driverHpNo = null;
  };

  @action
  setDriverAccInfo = value => {
    this.driverAccInfo = value;
  };

  @action
  initDriverAccInfo = () => {
    this.driverAccInfo = null;
  };

  @action
  setVcNo = value => {
    this.vcNo = value;
  };

  @action
  initVcNo = () => {
    this.vcNo = null;
  };

  @action
  setVcWeight = value => {
    this.vcWeight = value;
  };

  @action
  initVcWeight = () => {
    this.vcWeight = null;
  };

  @action
  setVcTyp = value => {
    this.vcTyp = value;
  };

  @action
  initVcTyp = () => {
    this.vcTyp = null;
  };

  @action
  setSelectedDriver = value => {
    this.selectedDriver = value;
  };

  @action
  initSelectedDriver = () => {
    this.selectedDriver = null;
  };

  @action
  setDriverList = value => {
    this.driverList = value;
  };

  @action
  initDriverList = () => {
    this.driverList = [];
  };

  @action
  initForm = () => {
    this.initDriverNm();
    this.initDriverHpNo();
    this.initDriverAccInfo();
    this.initVcNo();
    this.initVcWeight();
    this.initVcTyp();
    this.initSelectedDriver();
  };
}

export default WorkbenchDriverStore;
