import React, { Component } from 'react';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR07,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../common/Color';
import {
  STR_ID,
  STR_PW,
  STR_LOGIN,
  STR_SEN_2,
  STR_SEN_3,
} from '../../common/Terms';
import { ReactComponent as LogoGray } from '../../assets/icon/logo_gray.svg';

class QuoteFooter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            height: '120px',
            background: COLOR_GRAY_GR07,
            color: COLOR_GRAY_GR05,
          }}
        >
          <div style={{ width: '78%', margin: '0 auto' }}>
            <p>
              <LogoGray style={{ marginTop: '18px' }} />
            </p>
            <ul
              style={{
                width: '100%',
                listStyle: 'none',
                fontSize: '14px',
                marginTop: '5px',
                marginBottom: '5px',
                fontWeight: '400',
                paddingLeft: '0px',
                height: '20px',
              }}
            >
              <li
                style={{
                  float: 'left',
                  marginRight: '16px',
                }}
              >
                <span style={{ fontWeight: '700' }}>
                  (주)리크리에이트{'   '}
                </span>
                경기도 수원시 영통구 광교로 107 경기도 경제과학진흥원 보육동
                208호
              </li>
              <li
                style={{
                  float: 'left',
                  marginRight: '16px',
                }}
              >
                <span style={{ fontWeight: '700' }}>대표자명{'   '}</span>
                박훈진
              </li>
              <li
                style={{
                  float: 'left',
                  marginRight: '16px',
                }}
              >
                <span style={{ fontWeight: '700' }}>사업자등록번호{'   '}</span>
                771-86-02308
              </li>
              <li
                style={{
                  float: 'left',
                }}
              >
                <span style={{ fontWeight: '700' }}>대표번호{'   '}</span>
                010-9611-2934
              </li>
            </ul>
            <div>
              <p>Copyright ⓒ 2023. TeamTrucker All rights reserved.</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default QuoteFooter;
