import React, { Component } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  COLOR_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from '../../../../common/Color';

@inject(stores => ({
  feeStore: stores.FreightFeeStore,
}))
@observer
class SidoList extends Component {
  render() {
    const { feeStore } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>시도코드</Table.HeaderCell>
            <Table.HeaderCell>시도명</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {feeStore.sidoList == null || feeStore.sidoList.length == 0 ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan="3" textAlign="center">
                시도가 없습니다.
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            {feeStore.sidoList.map((data, i) => (
              <React.Fragment>
                <Table.Row onClick={() => this.props.fnSelectSiguList(data)}>
                  <Table.Cell
                    style={{
                      backgroundColor:
                        feeStore.selectedSido != null &&
                        feeStore.selectedSido.sidoCd === data.sidoCd
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        feeStore.selectedSido != null &&
                        feeStore.selectedSido.sidoCd === data.sidoCd
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.sidoCd}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      backgroundColor:
                        feeStore.selectedSido != null &&
                        feeStore.selectedSido.sidoCd === data.sidoCd
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        feeStore.selectedSido != null &&
                        feeStore.selectedSido.sidoCd === data.sidoCd
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    {data.sidoCdNm}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        )}
      </Table>
    );
  }
}

export default SidoList;
