import React, { Component, Fragment } from 'react';
import { Grid, Icon, Table } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import {
  COLOR_BLACK,
  COLOR_GREEN_PRIMARY,
  COLOR_WHITE,
} from '../../../common/Color';

@inject(stores => ({
  favoStore: stores.FavoriteStore,
}))
@observer
class FavoriteList extends Component {
  render() {
    const { favoStore } = this.props;
    return (
      <div style={{ marginTop: '55px' }}>
        <Table style={{ border: '0px' }}>
          <Table.Header style={{ border: '0px' }}>
            <Table.Row>
              <Table.HeaderCell style={{ background: '#fff' }}>
                순번
              </Table.HeaderCell>
              <Table.HeaderCell style={{ background: '#fff' }}>
                즐겨찾기 이름
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {favoStore.favoList == null || favoStore.favoList.length == 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="3" textAlign="center">
                  등록된 즐겨찾기가 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {favoStore.favoList.map((data, i) => (
                <React.Fragment key={'favoList' + i}>
                  <Table.Row
                    onClick={() => this.props.fnSetFavo(data, i)}
                    style={{ cursor: 'pointer' }}
                  >
                    <Table.Cell
                      style={{
                        backgroundColor:
                          favoStore.selectedFavo != null &&
                          favoStore.selectedFavo.favoNo === data.favoNo
                            ? COLOR_GREEN_PRIMARY
                            : COLOR_WHITE,
                        color:
                          favoStore.selectedFavo != null &&
                          favoStore.selectedFavo.favoNo === data.favoNo
                            ? COLOR_WHITE
                            : COLOR_BLACK,
                      }}
                    >
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor:
                          favoStore.selectedFavo != null &&
                          favoStore.selectedFavo.favoNo === data.favoNo
                            ? COLOR_GREEN_PRIMARY
                            : COLOR_WHITE,
                        color:
                          favoStore.selectedFavo != null &&
                          favoStore.selectedFavo.favoNo === data.favoNo
                            ? COLOR_WHITE
                            : COLOR_BLACK,
                      }}
                    >
                      {data.favoNm}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    );
  }
}

export default FavoriteList;
