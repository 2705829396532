import React, { Component } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import CommonAlert from '../../Component/Common/CommonAlert';
import moment from 'moment';
import { ReactComponent as WebLogo } from '../../assets/icon/web_logo.svg';
import CompManagement from './Comp/CompManagement';
import { COLOR_GRAY_GR02 } from '../../common/Color';
import WorkBenchOrderManagementContainer from './Order/WorkBenchOrderManagementContainer';
import WorkBenchOrderListContainer from './Order/WorkBenchOrderListContainer';
import ConsultingContainer from './ConsultingContainer';
import StatementContainer from './Statement/StatementContainer';
@inject(stores => ({
  vcStore: stores.VcStore,
}))
@observer
class WorkbenchContainer extends Component {
  render() {
    let currentPage;
    switch (this.props.location.pathname) {
      case '/work/comp':
        currentPage = <CompManagement />;
        break;

      case '/work/order':
        currentPage = <WorkBenchOrderManagementContainer />;
        break;
      case '/work/orderlist':
        currentPage = <WorkBenchOrderListContainer />;
        break;
      case '/work/consulting':
        currentPage = <ConsultingContainer />;
        break;
      case '/work/statement':
        currentPage = <StatementContainer />;
        break;
    }
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{ width: '100%', height: '50px', borderBottom: '1px solid' }}
        >
          <WebLogo
            style={{
              marginTop: '10px',
              marginLeft: '30px',
              float: 'left',
            }}
          />
          <ul
            style={{
              listStyle: 'none',
              paddingTop: '12px',
              height: '50px',
              overflow: 'hidden',
            }}
          >
            <li
              style={{
                float: 'left',
                width: '120px',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.history.push('/work/comp');
              }}
            >
              거래처 관리
            </li>
            <li
              style={{
                float: 'left',
                width: '120px',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.history.push('/work/order');
              }}
            >
              오더 입력
            </li>
            <li
              style={{
                float: 'left',
                width: '120px',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.history.push('/work/orderlist');
              }}
            >
              오더 목록
            </li>
            <li
              style={{
                float: 'left',
                width: '150px',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.history.push('/work/statement');
              }}
            >
              거래명세서 관리
            </li>
            <li
              style={{
                float: 'left',
                width: '150px',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                this.props.history.push('/work/consulting');
              }}
            >
              상담메뉴얼
            </li>
          </ul>
        </div>
        <div>{currentPage}</div>
      </div>
    );
  }
}

export default withRouter(WorkbenchContainer);
