import React, { Component, Fragment } from 'react';
import { Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class SelectCustomerCd extends React.Component {
  render() {
    const customerCdList = [
      { text: '개인 사업자', value: 'IACCU002' },
      { text: '법인 사업자', value: 'IACCU003' },
    ];
    const { accStore, commonStore } = this.props;
    return (
      <Fragment>
        <div
          style={{ width: '100%', marginBottom: '26px', textAlign: 'center' }}
        >
          <Select
            style={{ width: '250px' }}
            options={customerCdList}
            onChange={(e, { value }) => {
              accStore.setCustomerCd(value);
            }}
            value={accStore.customerCd}
            placeholder="회원 구분을 선택해 주세요."
          />
        </div>
      </Fragment>
    );
  }
}

export default SelectCustomerCd;
