import { Component, Fragment } from 'react';
import axios from 'axios';
import { Select, Form, Input, Dropdown } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import {
  API_SELECT_FREIGHT_LIST,
  API_SELECT_FREIGHT_LIST_WITH_KEYWORD,
} from '../../../common/ApiAddress/FreightAPI';
import { API_SELECT_CUSTOMER_SEARCH_LIST } from '../../../common/ApiAddress/AccountAPI';
import FreightList from '../../../Component/Management/Feright/FreightList';
import {
  isCustomerMaster,
  isEmp,
  isLogisManager,
  isCustomer,
} from '../../../Util/CommonUtil';
import SearchInput from '../../../Component/Management/Feright/SearchInput';

@inject(stores => ({
  orderStore: stores.OrderStore,
}))
@observer
class FreightAllList extends Component {
  componentDidMount() {
    this.props.fnSelectFreightList();
  }

  //운송내역 검색 리스트 불러오기
  fnSelectFreightListWithKeyword = searchKeyword => {
    const { orderStore } = this.props;
    let params = {};
    let that = this;
    params.startCnt = 0;
    params.endCnt = 100;
    params.searchKeyword = searchKeyword;
    params.searchTyp = this.props.searchTyp;

    if (isEmp()) {
      params.registId = 'emp';
    }
    if (isCustomerMaster()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
      params.groupNo = sessionStorage.getItem('groupNo');
    }
    if (isLogisManager()) {
      params.registId = sessionStorage.getItem('customerId');
      params.customerCd = sessionStorage.getItem('customerCd');
      params.groupNo = sessionStorage.getItem('groupNo');
    }

    console.log(params);
    axios({
      url: API_SELECT_FREIGHT_LIST_WITH_KEYWORD,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          orderStore.setFreightList(response.data.data);
        } else {
          orderStore.initFreightList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //자동검색 화주 리스트
  getCustomerList = (searchKeyword, searchTyp) => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    params.searchKeyword = searchKeyword;
    params.searchTyp = searchTyp;

    if (isEmp()) {
      params.registId = 'emp';
    }

    if (isCustomerMaster()) {
      params.groupNo = sessionStorage.getItem('groupNo');
    }

    axios({
      url: API_SELECT_CUSTOMER_SEARCH_LIST,
      method: 'post',
      data: params,
    })
      .then(response => {
        if (response.data.resultCode == 100) {
          this.props.fnSetResultList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnDirectListMove = pageNum => {
    const { orderStore } = this.props;
    orderStore.initFreightList();
    orderStore.setFreightListCurrentPageNum(pageNum);
    this.props.fnSelectFreightList();
  };

  render() {
    const { orderStore } = this.props;
    const searchTypList =
      isCustomerMaster() || isEmp()
        ? [
            { text: '운송번호', value: 'N' },
            { text: '아이디', value: 'A' },
          ]
        : [{ text: '운송번호', value: 'N' }];
    return (
      <Fragment>
        <div>
          <Form style={{ width: '100%', marginTop: '10px' }}>
            <Form.Field style={{ width: '100%' }}>
              {this.props.searchTyp == 'N' || this.props.searchTyp == null ? (
                <Fragment>
                  <Input
                    label={
                      <Dropdown
                        style={{
                          minWidth: '120px',
                          maxWidth: '120px',
                          //float: 'left',
                        }}
                        value={this.props.searchTyp}
                        placeholder={'검색조건 선택'}
                        options={searchTypList}
                        onChange={(e, { value }) =>
                          this.props.fnOnchangeSearchTyp(value)
                        }
                      />
                    }
                    icon="search"
                    placeholder="검색어 입력"
                    style={{ width: '475px' }}
                    onKeyPress={e => {
                      if (e.charCode == 13) {
                        this.fnSelectFreightListWithKeyword(e.target.value);
                      }
                    }}
                  />
                </Fragment>
              ) : (
                <SearchInput
                  fnOnchangeSearchTyp={this.props.fnOnchangeSearchTyp}
                  searchTypList={searchTypList}
                  searchTyp={this.props.searchTyp}
                  resultList={this.props.resultList}
                  getCustomerList={this.getCustomerList}
                  fnSelectFreightListWithKeyword={
                    this.fnSelectFreightListWithKeyword
                  }
                />
              )}
            </Form.Field>
          </Form>
        </div>
        <FreightList
          fnDetailViewOpen={this.props.fnDetailViewOpen}
          freightList={orderStore.freightList}
          freightListCurrentPageNum={orderStore.freightListCurrentPageNum}
          freightListTotalPageNum={orderStore.freightListTotalPageNum}
          fnDirectListMove={this.fnDirectListMove}
        />
      </Fragment>
    );
  }
}

export default FreightAllList;
