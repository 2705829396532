import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import {
  API_SELECT_STAFF_LIST,
  API_SELECT_STAFF_LOGIN,
} from '../../common/ApiAddress/AccountAPI';
import { STR_STAFF_PW } from '../../common/Terms';
import StaffListItem from '../../Component/Login/StaffListItem';
import StaffPWModal from '../../Component/Login/StaffPWMoal';

@inject(stores => ({
  accStore: stores.AccStore,
  vcStore: stores.VcStore,
}))
@observer
class SelectStaffContainer extends React.Component {
  state = {
    pwModal: false,
  };

  componentDidMount() {
    this.fnSelectStaffList();
  }
  //스탭 프로필 리스트 불러오기
  fnSelectStaffList = () => {
    const { accStore } = this.props;
    let params = {};
    let that = this;
    params.customerNo = sessionStorage.getItem('customerNo');
    axios({
      url: API_SELECT_STAFF_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          accStore.setStaffList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectStaff = data => {
    const { accStore } = this.props;
    accStore.setSelectedStaff(data);
    this.setState({
      pwModal: true,
    });
  };

  fnClosePwModal = () => {
    const { accStore } = this.props;
    accStore.initSelectedStaff();
    accStore.initStaffPw();
    this.setState({
      pwModal: false,
    });
  };

  fnMovetoMenu = () => {
    const { accStore } = this.props;
    let params = {};
    params.pw = accStore.staffPw;
    params.customerNo = accStore.selectedStaff.customerNo;
    params.staffNo = accStore.selectedStaff.staffNo;
    let that = this;

    axios({
      url: API_SELECT_STAFF_LOGIN,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.resultCode == 100) {
          sessionStorage.setItem('staffNo', accStore.selectedStaff.staffNo);
          sessionStorage.setItem('staffNm', accStore.selectedStaff.staffNm);
          sessionStorage.setItem('staffHpNo', accStore.selectedStaff.staffHpNo);
          sessionStorage.setItem('staffCd', accStore.selectedStaff.staffCd);
          accStore.initSelectedStaff();
          accStore.initStaffPw();
          that.props.history.push('/order');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { accStore } = this.props;
    return (
      <div
        style={{
          backgroundColor: '#000',
          height: '100vh',
        }}
      >
        <div
          style={{
            width: '100%',
            top: '32%',
            position: 'fixed',
            textAlign: 'center',
          }}
        >
          <h1 style={{ color: '#fff' }}>관리자를 선택해 주세요.</h1>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {accStore.staffList.map((data, i) => (
            <StaffListItem
              data={data}
              i={i}
              fnSelectStaff={this.fnSelectStaff}
            />
          ))}
          <StaffPWModal
            modalHeader={STR_STAFF_PW}
            open={this.state.pwModal}
            onClose={this.fnClosePwModal}
            fnMovetoMenu={this.fnMovetoMenu}
          />
        </div>
      </div>
    );
  }
}

export default SelectStaffContainer;
