import React, { Component } from 'react';
import { Table, Input, Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
@inject(stores => ({
  queStore: stores.QuestionStore,
}))
@observer
class QuestionInsertContainer extends Component {
  //파일 미리보기
  readURL = (input, id) => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById(id).src = e.target.result;
      };
      reader.readAsDataURL(input.files[0]);
    } else {
      document.getElementById(id).src = '';
    }
  };
  render() {
    const { queStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '10px',
          }}
        >
          <Button
            positive
            onClick={() => {
              this.props.fnInsertReply();
            }}
          >
            문의사항 작성완료
          </Button>
        </div>
        <div style={{ padding: '20px', paddingTop: '4px' }}>
          <Table style={{ background: '#fff', border: '0px' }}>
            <Table.Header style={{ background: '#fff' }}>
              <Table.Row style={{ background: '#fff' }}>
                <Table.HeaderCell colSpan="2" style={{ background: '#fff' }}>
                  <p style={{ fontSize: '18px', fontWeight: '700' }}>
                    문의사항 작성
                  </p>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>
                  <p style={{ fontSize: '14px', fontWeight: '700' }}>제목</p>
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="제목을 입력해 주세요."
                    value={queStore.questionTitle || ''}
                    onChange={e => {
                      queStore.setQuestionTitle(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <p style={{ fontSize: '14px', fontWeight: '700' }}>내용</p>
                </Table.Cell>
                <Table.Cell>
                  <Input
                    style={{ width: '100%' }}
                    placeholder="내용을 입력해주세요."
                    value={queStore.questionContent || ''}
                    onChange={e => {
                      queStore.setQuestionContent(e.target.value);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    첨부이미지1
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <div style={{ width: '100%' }}>
                    <img
                      id="addfile1Preview"
                      width="150px"
                      // src={
                      //   prodStore.prodThumbNailPath != null
                      //     ? prodStore.prodThumbNailPath
                      //     : null
                      // }
                    />
                  </div>
                  <input
                    id="addfile1Img"
                    type="file"
                    multiple
                    onClick={() => this.props.fnInitFileState()}
                    onChange={e => {
                      this.props.fnSetFileState(e.target.files[0], 0);
                      this.readURL(e.target, 'addfile1Preview');
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <p style={{ fontSize: '14px', fontWeight: '700' }}>
                    첨부이미지2
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <div style={{ width: '100%' }}>
                    <img
                      id="addfile2Preview"
                      width="150px"
                      // src={
                      //   prodStore.prodThumbNailPath != null
                      //     ? prodStore.prodThumbNailPath
                      //     : null
                      // }
                    />
                  </div>
                  <input
                    id="addfile1Img"
                    type="file"
                    multiple
                    onClick={() => this.props.fnInitFileState()}
                    onChange={e => {
                      this.props.fnSetFileState(e.target.files[0], 1);
                      this.readURL(e.target, 'addfile2Preview');
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default QuestionInsertContainer;
