import React, { Component, Fragment } from 'react';
import { Table, TextArea, Button, Form, Select } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import {
  isCustomer,
  isCustomerMaster,
  isEmp,
  isLogisManager,
} from '../../../Util/CommonUtil';
import CommonAlert from '../../../Component/Common/CommonAlert';
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR07,
} from '../../../common/Color';
@inject(stores => ({
  commonStore: stores.CommonStore,
  orderStore: stores.OrderStore,
}))
@observer
class FreightManagementDetailContainer extends Component {
  render() {
    const { orderStore, commonStore } = this.props;
    return (
      <div
        style={{
          paddingTop: '20px',
          paddingLeft: '37px',
          marginTop: '40px',
          boxShadow: '4px 4px 10px rgba(5,5,5,0.05)',
          marginRight: '44px',
          marginLeft: '36px',
          borderRadius: '10px',
        }}
      >
        {orderStore.selectedFreight != null &&
        orderStore.selectedFreight.freightCd == 'IFRST001' ? (
          <Fragment>
            <div
              style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                color="red"
                onClick={() => {
                  this.props.fnDeleteFreightModalOpen();
                }}
              >
                운송취소
              </Button>
              <Button
                color="yellow"
                onClick={() => {
                  if (orderStore.selectedFreight.paymentTo == 'IPYTO003') {
                    this.props.fnUpdateViewOpen();
                  } else {
                    commonStore.commonAlertOpen(
                      '인수증으로 결제한 운송건만 수정이 가능합니다.',
                    );
                  }
                }}
              >
                운송내역 변경
              </Button>
              {isCustomerMaster() ||
              isLogisManager() ||
              isCustomer() ||
              isEmp() ? (
                <Button
                  onClick={e => {
                    this.props.fnUpdateLoadingDateModalOpen();
                  }}
                  color="teal"
                >
                  상단으로 끌어올리기
                </Button>
              ) : null}
            </div>
          </Fragment>
        ) : null}
        {orderStore.selectedFreight == null ? null : (
          <React.Fragment>
            <p
              style={{
                fontSize: '18px',
                fontWeight: '700',
                color: COLOR_BLACK,
              }}
            >
              운송번호 : {orderStore.selectedFreight.freightNo}
            </p>
            <p
              style={{
                fontSize: '14px',
                fontWeight: '500',
                color: COLOR_GRAY_GR05,
              }}
            >
              등록일시 :{' '}
              {moment(orderStore.selectedFreight.registDt).format(
                'YYYY-MM-DD HH:mm',
              )}
            </p>
            <table border="0px" style={{ marginTop: '50px', width: '70%' }}>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  운송상태
                </td>
                <td style={{ fontWeight: '400' }}>
                  {orderStore.selectedFreight.freightCdNm}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  운송료
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.logisFee
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  상차지주소
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.loadingDetailAdd == null ||
                  orderStore.selectedFreight.loadingDetailAdd == undefined
                    ? orderStore.selectedFreight.loadingBasicAdd
                    : orderStore.selectedFreight.loadingBasicAdd +
                      ' ' +
                      orderStore.selectedFreight.loadingDetailAdd}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  상차일시
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.loadingDate == null
                    ? '미지정'
                    : moment(orderStore.selectedFreight.loadingDate).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                </td>
              </tr>
              {orderStore.selectedFreight.waypoint1BasicAdd != null ? (
                <tr
                  style={{
                    height: '35px',
                    color: COLOR_GRAY_GR07,
                    fontSize: '14px',
                  }}
                >
                  <td
                    style={{
                      fontWeight: '700',
                      width: '20%',
                    }}
                  >
                    경유지1주소
                  </td>
                  <td
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {orderStore.selectedFreight.waypoint1DetailAdd == null ||
                    orderStore.selectedFreight.waypoint1DetailAdd == undefined
                      ? orderStore.selectedFreight.waypoint1BasicAdd
                      : orderStore.selectedFreight.waypoint1BasicAdd +
                        ' ' +
                        orderStore.selectedFreight.waypoint1DetailAdd}
                  </td>
                </tr>
              ) : null}

              {orderStore.selectedFreight.waypoint2BasicAdd != null ? (
                <tr
                  style={{
                    height: '35px',
                    color: COLOR_GRAY_GR07,
                    fontSize: '14px',
                  }}
                >
                  <td
                    style={{
                      fontWeight: '700',
                      width: '20%',
                    }}
                  >
                    경유지2주소
                  </td>
                  <td
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {orderStore.selectedFreight.waypoint2DetailAdd == null ||
                    orderStore.selectedFreight.waypoint2DetailAdd == undefined
                      ? orderStore.selectedFreight.waypoint2BasicAdd
                      : orderStore.selectedFreight.waypoint2BasicAdd +
                        ' ' +
                        orderStore.selectedFreight.waypoint2DetailAdd}
                  </td>
                </tr>
              ) : null}

              {orderStore.selectedFreight.waypoint3BasicAdd != null ? (
                <tr
                  style={{
                    height: '35px',
                    color: COLOR_GRAY_GR07,
                    fontSize: '14px',
                  }}
                >
                  <td
                    style={{
                      fontWeight: '700',
                      width: '20%',
                    }}
                  >
                    경유지3주소
                  </td>
                  <td
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {orderStore.selectedFreight.waypoint3DetailAdd == null ||
                    orderStore.selectedFreight.waypoint3DetailAdd == undefined
                      ? orderStore.selectedFreight.waypoint3BasicAdd
                      : orderStore.selectedFreight.waypoint3BasicAdd +
                        ' ' +
                        orderStore.selectedFreight.waypoint3DetailAdd}
                  </td>
                </tr>
              ) : null}

              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  하차지 주소
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.unloadingDetailAdd == null ||
                  orderStore.selectedFreight.unloadingDetailAdd == undefined
                    ? orderStore.selectedFreight.unloadingBasicAdd
                    : orderStore.selectedFreight.unloadingBasicAdd +
                      ' ' +
                      orderStore.selectedFreight.unloadingDetailAdd}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  하차일시
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.unloadingDate == null
                    ? '미지정'
                    : moment(orderStore.selectedFreight.unloadingDate).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  화물구분
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.logisCdNm}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  거리
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {' '}
                  {Number(
                    orderStore.selectedFreight.distance.replace('km', ''),
                  ).toFixed(2) + 'Km'}
                </td>
              </tr>
              {orderStore.selectedFreight.freightCd == 'IFRST001' ||
              orderStore.selectedFreight.freightCd == 'IFRST002' ||
              orderStore.selectedFreight.freightCd == 'IFRST003' ||
              orderStore.selectedFreight.freightCd == 'IFRST004' ? (
                <tr
                  style={{
                    height: '35px',
                    color: COLOR_GRAY_GR07,
                    fontSize: '14px',
                  }}
                >
                  <td
                    style={{
                      fontWeight: '700',
                      width: '20%',
                    }}
                  >
                    예상 소요 시간
                  </td>
                  <td
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {orderStore.selectedFreight.duration}
                    {/* {orderStore.selectedFreight.duration} */}
                    {/* {moment
                      .duration(
                        moment()
                          .add(80, 'minutes')
                          .diff(
                            moment(orderStore.selectedFreight.registDt).format(
                              'YYYY-MM-DD HH:mm',
                            ),
                          ),
                      )
                      .asMinutes()
                      .toFixed(2)} */}
                  </td>
                </tr>
              ) : null}

              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  화물정보
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.freightInfo}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  차량 중량 구분
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.vcWeightCdNm}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  차량 종류
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.vcTypCdNm}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  화물중량
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.freightWeight}Kg
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  상/하차 방법
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {' '}
                  {orderStore.selectedFreight.loadingCdNm} /{' '}
                  {orderStore.selectedFreight.unloadingCdNm}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  트럭커 연락처
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.driverHpNo}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  결제수단
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.paymentToNm}
                </td>
              </tr>
              <tr
                style={{
                  height: '35px',
                  color: COLOR_GRAY_GR07,
                  fontSize: '14px',
                }}
              >
                <td
                  style={{
                    fontWeight: '700',
                    width: '20%',
                  }}
                >
                  결제방법
                </td>
                <td
                  style={{
                    fontWeight: '400',
                  }}
                >
                  {orderStore.selectedFreight.paymentTyNm}
                </td>
              </tr>
            </table>

            <CommonAlert />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FreightManagementDetailContainer;
