import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from 'react-router';
const someHTML = `<!DOCTYPE html>
<html lang="ko">

<head>
	<meta charset="UTF-8" />
	<meta name="viewport" content="width=device-width" />
	<title>팀트럭커 이용약관</title>
</head>

<body style="padding:20px; color: rgb(0,51,102);">
	<h2><span>팀트럭커 이용약관</span></h2>
	<br />
	<h4><span>제1조(목적)</span></h4>
	<p> <span>이 약관은 (주)Recreate(이하 &quot;회사&quot;라 함)가 운영하는 “팀트럭커” (이하 “팀트럭커”이라 한다) 에서 제공되는 모든 서비스(이하 &quot;서비스&quot;라 한다)를 이용함에 있어 이용자의
                권리 및 의무, 기타 부수 사항에 관하여 규정함을 목적으로 합니다.</span
            >
        </p>
        <h4><span>제2조(정의)</span>
		</h4>
		<ol>
			<li> <span>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</span>
				<ol>
					<li> <span>&quot;회사&quot;가 운영하는 &quot;사이트&quot;(2023. 03. 현재)는 아래와 같습니다.</span>
						<ul class="depth3">
							<li><span>https://teamtrucekr.co.kr</span>
							</li>
							<li><span>추후 &quot;회사&quot;에서 공지하고 제공하는 기타 웹사이트</span>
							</li>
						</ul>
					</li>
					<li><span>이용자 : 팀트럭커의 서비스를 이용하고자 하는자</span>
					</li>
					<li><span>회원 : 본 약관에 따라 팀트럭커가 제공하는 서비스를 이용하는 자</span>
					</li>
					<li><span>가입 : 팀트럭커에서 제공하는 회원가입 양식에 따라 해당정보를 기입하고 이용약관에 동의하여 서비스 이용 계약이 완료되는 행위</span>
					</li>
					<li><span>이용계약 : 서비스 이용과 관련하여 팀트럭커과 회원간에 체결하는 모든 계약</span>
					</li>
					<li><span>아이디(ID) : 회원의 식별과 서비스 이용을 위한 회원 고유의 정보</span>
					</li>
					<li><span>비밀번호 : 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 회사에 등록한 영문과 숫자의 조합을 말합니다.</span>
					</li>
					<li><span>운영자 : 팀트럭커가 제공하는 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 자를 말합니다.</span>
					</li>
					<li><span>구매안전서비스 : 팀트럭커의 서비스를 이용하기 위해 회원이 결제한 결제대금의 보호를 위하여 일정기간 동안 결제대금을 예치하는 서비스</span>
					</li>
				</ol>
			</li>
			<li><span>이 약관에서 정의되지 않은 용어는 관련법령이 정하는 바에 따릅니다.</span>
			</li>
		</ol>
		<h4><span>제3조 (약관의 명시, 효력과 개정)</span></h4>
		<ol>
			<li><span>회사는 이 약관의 내용을 회사의 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등과 함께 회원이 확인할 수 있도록 “팀트럭커” 서비스 화면에 게시합니다.</span>
			</li>
			<li><span>회사는 약관의 규제에 관한 법률, 전기통신사업법 및 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</span>
			</li>
			<li><span>약관은 이용자가 “팀트럭커” 서비스에 회원 가입하는 경우 본 약관에 명시된 조건에 동의하는 것으로 간주하며, 그 즉시 효력이 발생합니다.</span>
			</li>
			<li> <span>회사는 필요한 사유가 발생했을 때 사전 고지 없이 약관을 변경할 수 있습니다. 변경된 약관은 시행일로부터 7일간 “팀트럭커” 서비스 화면에 공지하고, 명시적으로 회원이 반대하지 않는 한
                    개정된 약관에 동의한 것으로 간주합니다.</span
                >
            </li>
            <li><span>제4항의 방법으로 변경 고지된 약관은 기존의 회원에게도 유효하게 적용됩니다.</span>
			</li>
			<li> <span>회원은 변경된 약관에 동의하지 않는 경우 서비스 이용을 중단하고 본인의 회원등록을 취소할 수 있으며, 계속 사용하는 경우에는 약관 변경에 동의한 것으로 간주되며 효력이
                    발생됩니다.</span
                >
            </li>
        </ol>
        <h4><span>제4조 (약관의 해석)</span>
				</h4>
				<ol>
					<li><span>약관의 해석은 한국어로 작성된 내용을 기준으로 하며, 영어를 비롯한 다른 언어로 번역되어 한국어 내용과 상이한 해석이 발생할 경우, 한국어 해석에 따릅니다.</span>
					</li>
					<li><span>이 약관에 명시되지 않은 사항과 이 약관의 해석에 관하여는 관계법령 및 상관례에 따릅니다.</span>
					</li>
				</ol>
				<h4><span>제5조 (회원 가입 및 자격)</span></h4>
				<ol>
					<li> <span>회사가 정한 양식에 따라 회원이 모든 정보를 정확히 기재해 회원가입을 신청하거나 정보제공을 동의하는 경우 회사는 회원가입을 승낙합니다. 회사는 회원 가입 신청을 통해 서비스 이용을
                    승인합니다.</span
                >
            </li>
            <li><span>타인의 정보를 도용하여 회원정보를 기재하는 경우, 서비스 이용이 제한되거나 관련법령에 의거 처벌받을 수 있습니다.</span>
					</li>
					<li><span>회사는 다음과 같은 사유가 발생한 경우 회원 가입 신청에 대한 승낙을 거부, 유보하거나 회원자격 박탈 등의 조치를 취할 수 있습니다.</span>
					</li>
					<ol class="depth2">
						<li><span>다른 사람의 명의를 사용하여 가입 신청한 경우</span>
						</li>
						<li><span>신청시 필수 작성 사항을 허위로 기재한 경우</span>
						</li>
						<li><span>회사에 의하여 이용계약이 해지된 날로부터 3개월 이내에 재이용신청을 하는 경우</span>
						</li>
						<li><span>회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우</span>
						</li>
						<li><span>관계법령의 위반을 목적으로 신청하거나 그러한 행위를 하는 경우</span>
						</li>
						<li><span>사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청하거나 그러한 행위를 하는 경우</span>
						</li>
						<li><span>다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우</span>
						</li>
						<li><span>회원의 의무를 지키지 않는 경우</span>
						</li>
						<li><span>기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</span>
						</li>
					</ol>
				</ol>
				<h4><span>제6조 (계약사항의 변경)</span></h4>
				<p><span>회원은 이용신청시 기재한 사항이 변경되었을 경우에는 수정하여야 하며, 수정하지 아니하여 발생하는 문제의 책임은 회원에게 있습니다.</span>
				</p>
				<h4><span>제7조 (회원 탈퇴)</span></h4>
				<ol>
					<li><span>회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다.</span>
					</li>
					<li><span>회원은 탈퇴 요청하기 7일 전에 모든 거래를 완결시키는데 필요한 조치를 취하여야만 합니다.</span>
					</li>
					<li> <span>전 호의 기간내에 회원이 발한 의사표시로 인해 발생한 불이익에 대한 책임은 회원 본인이 부담하여야 하며, 회원 탈퇴가 되면 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할 수
                    있습니다.</span
                >
            </li>
        </ol>
        <h4><span>제8조 (서비스의 제공)</span>
						</h4>
						<p><span>회사가 제공하는 서비스는 다음과 같습니다.</span>
						</p>
						<ol>
							<li><span>화물배차 서비스</span>
							</li>
							<li><span>이용자 푸쉬알림 및 메일링 서비스</span>
							</li>
							<li><span>전자상거래 관련 서비스</span>
							</li>
							<li><span>기타 회사가 자체 개발하거나 타 회사와의 협력계약 등을 통해 회원들에게 제공하는 일체의 서비스</span>
							</li>
						</ol>
						<h4><span>제9조 (서비스 이용)</span></h4>
						<ol>
							<li><span>이용자는 회원가입 즉시 제8조에서 열거한 서비스를 이용할 수 있습니다.</span>
							</li>
							<li><span>회원은 서비스 이용 시 불편사항에 관하여 언제든지 회사에 문제제기를 할 수 있습니다. 회사는 회원의 문제 제기가 정당하다고 인정되는 경우 이를 해소하기 위해 노력합니다.</span>
							</li>
							<li> <span>회원의 아이디와 비밀번호에 대한 모든 관리책임은 회원에게 있고, 회원은 자신의 아이디를 타인에게 양도, 증여할 수 없습니다. 회원이 등록한 아이디 및 비밀번호를 이용하여 발생한 회원,
                    회사 또는 제3자에 대한 손해 및 손실에 관한 모든 책임은 해당 회원에게 있습니다.
                </span>
							</li>
						</ol>
						<h4><span>제10조 (회사의 의무)</span></h4>
						<p> <span>회사는 서비스 제공과 관련해서 알고 있는 회원의 신상 정보를 본인의 승낙 없이 제3자에게 누설하거나 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는
                경우, 범죄에 대한 수사상의 목적이 있거나 또는 기타 관계법령에서 정한 절차에 의한 요청이 있을 경우에는 그러하지 아니합니다.</span
            >
        </p>
        <h4><span>제11조 (회원의 의무)</span>
							</h4>
							<ol>
								<li><span>회원은 자신이 게시한 정보에 대한 무한 책임을 집니다.</span>
								</li>
								<li><span>아이디와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.</span>
								</li>
								<li><span>회원은 아이디와 비밀번호를 제3자가 알수 있도록 해서는 안 됩니다.</span>
								</li>
								<li><span>회원은 다음 행위를 하여서는 안 됩니다.</span>
								</li>
								<li><span> 회원가입 신청 또는 변경 시 허위 내용의 등록</span>
								</li>
								<li><span> 타인의 정보 도용</span>
								</li>
								<li><span> 회사가 게시한 정보의 변경</span>
								</li>
								<li><span> 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</span>
								</li>
								<li><span> 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</span>
								</li>
								<li><span> 회사 및 기타 제3자의 명예를 손상 시키거나 업무를 방해하는 행위</span>
								</li>
								<li><span> 외설 또는 폭력적인 메시지, 영상, 음성, 기타 미풍양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위</span>
								</li>
								<li><span> 기타 불법적이거나 부당한 행위</span>
								</li>
								<li><span>회원은 본 약관 및 관계법령에서 규정한 사항을 준수합니다.</span>
								</li>
								<li><span>회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판 및 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</span>
								</li>
								<li><span>회원은 회사 서비스를 해킹하거나 불법 프로그램을 업로드 해서는 안됩니다. </span>
								</li>
							</ol>
							<h4><span>제12조 (서비스 이용제한)</span></h4>
							<p><span>회원이 다음에 해당하는 행위를 했을 경우 회사는 사전 통지 없이 이용 계약을 해지하거나 서비스 이용을 제한 할 수 있습니다.</span>
							</p>
							<ol>
								<li><span>제11조 회원의 의무를 지키지 않은 경우</span>
								</li>
								<li><span>불량게시물 규정에 유배되는 행위를 한 경우</span>
								</li>
								<li><span>회사에서 제공하는 서비스에 위해를 가하는 등 회사의 업무를 방해하거나 타인의 회사 서비스 이용을 저해하는 경우</span>
								</li>
								<li><span>기타 회사에서 더 이상 서비스 제공이 어렵다고 판단할 만한 상당한 이유가 있는 경우</span>
								</li>
							</ol>
							<h4><span>제13조 (서비스 이용시간)</span></h4>
							<p><span>서비스의 이용은 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 사유 발생시는 그러하지 않습니다.</span>
							</p>
							<h4><span>제14조 (서비스 제공의 중지)</span></h4>
							<p><span>다음 각 호에 해당하는 경우에는 서비스 제공을 중지할 수 있습니다.</span>
							</p>
							<ol>
								<li><span>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</span>
								</li>
								<li><span>전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</span>
								</li>
								<li><span>시스템 점검이 필요한 경우</span>
								</li>
								<li><span>기타천재지변 또는 이에 준하는 불가항력적 사유가 있는 경우</span>
								</li>
							</ol>
							<h4><span>제15조 (양도금지)</span></h4>
							<p><span>회원은 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</span>
							</p>
							<h4><span>제16조 (손해배상)</span></h4>
							<ol>
								<li><span>회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</span>
								</li>
								<li> <span>회원이 서비스를 이용함에 있어 행한 불법행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상청구, 소송을 비롯한 각종의 이의제기를 받는 경우 당해 회원은 회사의 면책을 위하여
                    노력하여야 하며, 회사가 면책되지 못한 경우는 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</span
                >
            </li>
        </ol>
        <h4><span>제17조 (면책범위)</span>
									</h4>
									<ol>
										<li><span>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제 됩니다.</span>
										</li>
										<li><span>회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다. </span>
										</li>
										<li><span>회사 이외의 타 사업자가 제공하는 정보에 의해 발생하는 피해나 서비스의 장애로 인한 경우에 대하여는 회사는 책임을 지지 않습니다. </span>
										</li>
										<li><span>회사는 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 법률적인 책임을 지지 않습니다.</span>
										</li>
										<li> <span>회사가 제공한 서비스를 통해 취득한 정보나 서비스에 게시된 다른 회원의 게시물을 통해 법률적 또는 의학적, 기타 재정적 사항에 관하여 필요한 결정을 하고 할 때에는 반드시 사전에
                    전문가와 상의할 것을 권고합니다.</span
                >
            </li>
            <li><span>본 약관의 적용은 이용계약을 체결한 회원에 한하며 제3자에게 어떠한 배상, 소송 등에 대하여 회사는 책임을 면합니다.</span>
										</li>
									</ol>
									<h4><span>제18조 (고충처리)</span></h4>
									<p><span>회사는 전화,서면, 전자우편 또는 인터넷 홈페이지 등을 통하여 개인정보와 관련한 회원의 의견을 수렴하고 불만사항을 접수하여 이를 처리합니다.</span>
									</p>
									<h4><span>제19조 (법 준수)</span></h4>
									<p><span>회원은 서비스를 이용함에 있어 국내법이나 규정 정책 및 서비스를 이용함에 있어서 필요한 국제법 및 관련 당사자국의 법을 준수할 책임이 있습니다.</span>
									</p>
									<h4><span>제20조 (분쟁해결 및 관할법원)</span></h4>
									<ol>
										<li><span>1. 회사 및 회원은 개인정보에 관한 분쟁이 있는 경우 신속하고 효과적인 분쟁해결을 위하여 개인정보분쟁조정 위원회에 분쟁의 조정을 신청할 수 있습니다.</span>
										</li>
										<li><span>2. 서비스 이용으로 발생한 분쟁에 대한 소송은 회사의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.</span>
										</li>
									</ol>
									<p><span>본 약관은 2022년 3월 1일부터 시행됩니다.</span>
									</p>
</body>

</html>
`;
class Agreements extends Component {
  render() {
    return <React.Fragment>{ReactHtmlParser(someHTML)}</React.Fragment>;
  }
}
export default withRouter(Agreements);
