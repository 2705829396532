import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { STR_ALERT, STR_YES, STR_NO } from '../../common/Terms';
@inject(stores => ({
  commonStore: stores.CommonStore,
}))
@observer
class CommonCorfirmModal extends Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={commonStore.commonConfirm.isOpen}
        onClose={() => commonStore.commonConfirmClose()}
        style={{ height: '250px' }}
      >
        <Modal.Header>{STR_ALERT}</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: '16pt' }}>
            {commonStore.commonConfirm.content}
          </p>
        </Modal.Content>
        <div style={{ float: 'right', marginTop: '20px', marginRight: '20px' }}>
          <Button negative onClick={() => this.props.fnConfirmNo()}>
            {STR_NO}
          </Button>
          <Button positive onClick={() => this.props.fnConfirmYes()}>
            {STR_YES}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default CommonCorfirmModal;
