import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
class OnClickInput extends Component {
  render() {
    return (
      <Form.Field>
        <input
          readOnly={this.props.readOnly}
          placeholder={this.props.placeholder}
          onClick={() => this.props.onClick()}
          // defaultValue={
          //   this.props.defaultValue == null ? null : this.props.defaultValue
          // }
          value={this.props.defaultValue || ''}
        />
      </Form.Field>
    );
  }
}

export default OnClickInput;
