import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import MessageTemplateLIst from '../../../../Component/Management/Message/MessageTemplate/MessageTemplateList';
import {
  API_SELECT_MESSAGE_TEMPLATE_LIST,
  API_INSERT_MESSAGE_TEMPLATE,
  API_UPDATE_MESSAGE_TEMPLATE,
} from '../../../../common/ApiAddress/MessageAPI';
import MessageTemplateUpsertContainer from './MessageTemplateUpsertContainer';
//import NotiDetailContainer from './NotiDetailContainer';
import { isEmp } from '../../../../Util/CommonUtil';
@inject(stores => ({
  mgsStore: stores.MessageStore,
}))
@observer
class MessageTamplateManagementContainer extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
  };

  componentDidMount() {
    this.fnSelectTemplateiList();
  }

  //메세지 템플릿 리스트 불러오기
  fnSelectTemplateiList = () => {
    const { mgsStore } = this.props;
    let params = {};
    let that = this;

    axios({
      url: API_SELECT_MESSAGE_TEMPLATE_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          mgsStore.setMsgTemplateList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnViewOpen = value => {
    const { mgsStore } = this.props;
    console.log({ ...value });
    mgsStore.setSelectedTemplate(value);
    mgsStore.setTitle(value.title);
    mgsStore.setText(value.text);
    mgsStore.setMergeKey(value.mergeKeys);
    mgsStore.setSysCdNm(value.sysCdNm);
    if (value.useYn == 'Y') {
      mgsStore.setUseYnTrue();
    } else {
      mgsStore.setUseYnFalse();
    }
    this.setState({
      upsertView: true,
      upsertMode: 'U',
    });
  };

  //msg템플릿 저장
  fnUpsertMessageTemplate = () => {
    const { mgsStore } = this.props;
    let params = {};
    params.title = mgsStore.title;
    params.text = mgsStore.text;
    params.mergeKeys = mgsStore.mergeKey;
    params.registId = sessionStorage.getItem('empId');

    if (this.state.upsertMode == 'U') {
      params.groupKey = mgsStore.selectedTemplate.groupKey;
      if (mgsStore.useYn == true) {
        params.useYn = 'Y';
      } else {
        params.useYn = 'N';
      }
    }

    let that = this;
    axios({
      url:
        this.state.upsertMode == 'I'
          ? API_INSERT_MESSAGE_TEMPLATE
          : API_UPDATE_MESSAGE_TEMPLATE,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          mgsStore.initTemplate();
          that.fnSelectTemplateiList();
          that.setState({
            upsertView: false,
            upsertMode: 'I',
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { mgsStore } = this.props;
    return isEmp() ? (
      <React.Fragment>
        <div
          style={{
            width: '30%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          <Button
            positive
            onClick={() => {
              mgsStore.initTemplate();
              this.setState({
                upsertView: true,
                upsertMode: 'I',
              });
            }}
            style={{ float: 'right' }}
          >
            새 템플릿 작성
          </Button>
          <MessageTemplateLIst fnViewOpen={this.fnViewOpen} />
        </div>
        <div
          style={{
            width: '70%',
            height: '100vh',
            float: 'left',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '10px',
          }}
        >
          {this.state.upsertView == true ? (
            <MessageTemplateUpsertContainer
              fnUpsertMessageTemplate={this.fnUpsertMessageTemplate}
            />
          ) : null}
        </div>
      </React.Fragment>
    ) : null;
  }
}

export default withRouter(MessageTamplateManagementContainer);
