import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class QuoteStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  //기업 신용 거래 문의
  @observable companyNm = null;
  @observable companyEmail = null;
  @observable requestNm = null;
  @observable requestHpNo = null;

  @observable localeList = [];
  @observable vcWeightList = [];
  @observable vcTypList = [];
  @observable monthCountValue = null;

  @observable feightInfo = null;
  @observable requestContent = null;

  @action
  setCompanyNm = value => {
    this.companyNm = value;
  };

  @action
  initCompanyNm = () => {
    this.companyNm = null;
  };

  @action
  setCompanyEmail = value => {
    this.companyEmail = value;
  };

  @action
  initCompanyEmail = () => {
    this.companyEmail = null;
  };

  @action
  setRequestNm = value => {
    this.requestNm = value;
  };

  @action
  initRequestNm = () => {
    this.requestNm = null;
  };

  @action
  setRequestHpNo = value => {
    this.requestHpNo = value;
  };

  @action
  initRequestHpNo = () => {
    this.requestHpNo = null;
  };

  @action
  setLocaleList = value => {
    let idx = this.localeList.findIndex(item => item == value);
    if (idx == -1) {
      this.localeList.push(value);
    } else {
      this.localeList.splice(idx, 1);
    }
  };

  @action
  initLocaleList = () => {
    this.localeList = [];
  };

  @action
  setVcWeightList = value => {
    let idx = this.vcWeightList.findIndex(item => item == value);
    if (idx == -1) {
      this.vcWeightList.push(value);
    } else {
      this.vcWeightList.splice(idx, 1);
    }
  };

  @action
  initVcWeightList = () => {
    this.vcWeightList = [];
  };

  @action
  setVcTypList = value => {
    let idx = this.vcTypList.findIndex(item => item == value);
    if (idx == -1) {
      this.vcTypList.push(value);
    } else {
      this.vcTypList.splice(idx, 1);
    }
  };

  @action
  initVcTypList = () => {
    this.vcTypList = [];
  };

  @action
  setMonthCountValue = value => {
    this.monthCountValue = value;
  };

  @action
  initMonthCountValue = () => {
    this.monthCountValue = null;
  };

  @action
  setFeightInfo = value => {
    this.feightInfo = value;
  };

  @action
  initFeightInfo = () => {
    this.feightInfo = null;
  };

  @action
  setRequestContent = value => {
    this.requestContent = value;
  };

  @action
  initRequestContent = () => {
    this.requestContent = null;
  };

  @action
  initB2BContactForm = () => {
    this.initCompanyNm();
    this.initCompanyEmail();
    this.initRequestNm();
    this.initRequestHpNo();
    this.initLocaleList();
    this.initVcWeightList();
    this.initVcTypList();
    this.initMonthCountValue();
    this.initFeightInfo();
    this.initRequestContent();
  };

  //배차신청
  @observable loadingBasicAdd = null;
  @observable loadingDetailAdd = null;
  @observable departureCoord = null;

  @observable unloadingBasicAdd = null;
  @observable unloadingDetailAdd = null;
  @observable arriveCoord = null;

  @observable cargoDispathInfo = null;
  @observable ordererNm = null;
  @observable ordererHpNo = null;
  @observable loadingDate = null;
  @observable freightWeight = null;

  @observable orderList = [];

  @observable mobileFreightModal = false;
  @observable mobileCombinedFreightModal = false;

  @observable distance = null;

  @observable freightMiniList = [];
  @observable selectedFreightMini = null;

  @observable logisFee = null;
  @observable commission = null;
  @observable totalFee = null;

  @observable unloadingStaffNm = null;
  @observable unloadingStaffHpNo = null;

  @action
  setLoadingBasicAdd = value => {
    this.loadingBasicAdd = value;
  };

  @action
  initLoadingBasicAdd = () => {
    this.loadingBasicAdd = null;
  };

  @action
  setLoadingDetailAdd = value => {
    this.loadingDetailAdd = value;
  };

  @action
  initLoadingDetailAdd = () => {
    this.loadingDetailAdd = null;
  };

  @action
  setUnloadingBasicAdd = value => {
    this.unloadingBasicAdd = value;
  };

  @action
  initUnloadingBasicAdd = () => {
    this.unloadingBasicAdd = null;
  };

  @action
  setUnloadingDetailAdd = value => {
    this.unloadingDetailAdd = value;
  };

  @action
  initUnloadingDetailAdd = () => {
    this.unloadingDetailAdd = null;
  };

  @action
  setCargoDispathInfo = value => {
    this.cargoDispathInfo = value;
  };

  @action
  initCargoDispathInfo = () => {
    this.cargoDispathInfo = null;
  };

  @action
  setOrdererNm = value => {
    this.ordererNm = value;
  };

  @action
  initOrdererNm = () => {
    this.ordererNm = null;
  };

  @action
  setOrdererHpNo = value => {
    this.ordererHpNo = value;
  };

  @action
  initOrdererHpNo = () => {
    this.ordererHpNo = null;
  };

  @action
  setLoadingDate = value => {
    this.loadingDate = value;
  };

  @action
  initLoadingDate = () => {
    this.loadingDate = null;
  };

  @action
  setOrderList = value => {
    this.orderList = value;
  };

  @action
  initOrderList = () => {
    this.orderList = [];
  };

  @action
  setMobileFreightModalOpen = () => {
    this.mobileFreightModal = true;
  };

  @action
  setMobileFreightModalClose = () => {
    this.mobileFreightModal = false;
  };

  @action
  setMobileCombinedFreightModalOpen = () => {
    this.mobileCombinedFreightModal = true;
  };

  @action
  setMobileCombinedFreightModalClose = () => {
    this.mobileCombinedFreightModal = false;
  };

  @observable freightModalForPC = false;
  @observable combinedFreightModalPC = false;

  @action
  setFreightModalForPCOpen = () => {
    this.freightModalForPC = true;
  };

  @action
  setFreightModalForPCClose = () => {
    this.freightModalForPC = false;
  };

  @action
  setCombinedFreightModalPCOpen = () => {
    this.combinedFreightModalPC = true;
  };

  @action
  setCombinedFreightModalPCClose = () => {
    this.combinedFreightModalPC = false;
  };

  @action
  setFreightWeight = value => {
    this.freightWeight = value;
  };

  @action
  initFreightWeight = () => {
    this.freightWeight = null;
  };

  @action
  setDepartureCoord = value => {
    this.departureCoord = value;
  };

  @action
  initDepartureCoord = () => {
    this.departureCoord = null;
  };

  @action
  setArriveCoord = value => {
    this.arriveCoord = value;
  };

  @action
  initArriveCoord = () => {
    this.arriveCoord = null;
  };

  @action
  setDistance = value => {
    this.distance = value;
  };

  @action
  initDistance = () => {
    this.distance = null;
  };

  @action
  setFreightMiniList = value => {
    this.freightMiniList = value;
  };

  @action
  initFreightMiniList = () => {
    this.freightMiniList = [];
  };

  @action
  setSelectedFreightMini = value => {
    this.selectedFreightMini = value;
  };

  @action
  initSelectedFreightMini = () => {
    this.selectedFreightMini = null;
  };

  @action
  setLogisFee = value => {
    this.logisFee = value;
  };

  @action
  initLogisFee = () => {
    this.logisFee = null;
  };

  @action
  setCommission = value => {
    this.commission = value;
  };

  @action
  initCommission = () => {
    this.commission = null;
  };

  @action
  setTotalFee = value => {
    this.totalFee = value;
  };

  @action
  initTotalFee = () => {
    this.totalFee = null;
  };

  @action
  setUnloadingStaffNm = value => {
    this.unloadingStaffNm = value;
  };

  @action
  initUnloadingStaffNm = () => {
    this.unloadingStaffNm = null;
  };

  @action
  setUnloadingStaffHpNo = value => {
    this.unloadingStaffHpNo = value;
  };

  @action
  initUnloadingStaffHpNo = () => {
    this.unloadingStaffHpNo = null;
  };

  @action
  initOrderForm = () => {
    this.initLoadingBasicAdd();
    this.initLoadingDetailAdd();
    this.initUnloadingBasicAdd();
    this.initUnloadingDetailAdd();
    this.initCargoDispathInfo();
    this.initOrdererNm();
    this.initOrdererHpNo();
    this.initLoadingDate();
    this.initFreightWeight();
    this.initDepartureCoord();
    this.initArriveCoord();
    this.initDistance();
    this.initUnloadingStaffNm();
    this.initUnloadingStaffHpNo();
  };
}

export default QuoteStore;
