import React, { Component } from 'react';
import { NaverMap, Polyline, Marker } from 'react-naver-maps';
import { inject, observer } from 'mobx-react';
import { COLOR_GREEN_PRIMARY } from '../../common/Color';

@inject(stores => ({
  commonStore: stores.CommonStore,
  vcStore: stores.VcStore,
  orderStore: stores.OrderStore,
}))
@observer
class Map extends Component {
  render() {
    const { orderStore } = this.props;
    const navermaps = window.naver.maps;
    return (
      <NaverMap
        id="map"
        style={{
          width: '100%',
          height:
            this.props.updateView == undefined ? '100vh' : 'calc(100vh - 84px)',
        }}
        zoom={this.props.defaultZoom}
        center={
          this.props.departureX != null &&
          this.props.departureY != null &&
          this.props.arrivalX != null &&
          this.props.arrivalY != null
            ? new navermaps.LatLng(
                (Number(this.props.departureY) + Number(this.props.arrivalY)) /
                  2,
                (Number(this.props.departureX) + Number(this.props.arrivalX)) /
                  2,
              )
            : null
        }
      >
        {this.props.departureX == null ||
        this.props.departureY == null ? null : (
          <Marker
            position={
              new navermaps.LatLng(this.props.departureY, this.props.departureX)
            }
          />
        )}

        {this.props.arrivalX == null || this.props.arrivalY == null ? null : (
          <Marker
            position={
              new navermaps.LatLng(this.props.arrivalY, this.props.arrivalX)
            }
          />
        )}

        {this.props.coordsWaypoints[0] != undefined ? (
          <Marker
            position={
              new navermaps.LatLng(
                this.props.coordsWaypoints[0].wayPointY,
                this.props.coordsWaypoints[0].wayPointX,
              )
            }
          />
        ) : null}
        {this.props.coordsWaypoints[1] != undefined ? (
          <Marker
            position={
              new navermaps.LatLng(
                this.props.coordsWaypoints[1].wayPointY,
                this.props.coordsWaypoints[1].wayPointX,
              )
            }
          />
        ) : null}

        {this.props.coordsWaypoints[2] != undefined ? (
          <Marker
            position={
              new navermaps.LatLng(
                this.props.coordsWaypoints[2].wayPointY,
                this.props.coordsWaypoints[2].wayPointX,
              )
            }
          />
        ) : null}

        {this.props.orderPath == null ? null : (
          <Polyline
            path={this.props.orderPath}
            strokeColor={COLOR_GREEN_PRIMARY}
            strokeStyle={'solid'}
            strokeOpacity={1}
            strokeWeight={4}
          />
        )}
      </NaverMap>
    );
  }
}

export default Map;
