import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import { isLogin } from '../../Util/CommonUtil';

const PublicRoute = ({
  component: Component,
  restricted,
  sideBar,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLogin() && restricted ? (
          <Redirect to="/main" />
        ) : (
          <React.Fragment>
            {sideBar === true ? <SideBar /> : null}
            <Component {...props} />
          </React.Fragment>
        )
      }
    />
  );
};
export default PublicRoute;
