import { observable, action, reaction, makeObservable, toJS } from 'mobx';

class CompStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }
  @observable compNm = null;
  @observable compBsNo = null;
  @observable compHpNo = null;
  @observable compFaxNo = null;
  @observable representative = null;
  @observable bsTyp = null;
  @observable bsNm = null;
  @observable compAdd = null;
  @observable compCd = null;
  @observable email = null;

  @observable compList = [];
  @observable compListCurrentPageNum = 1;
  @observable compListTotalPageNum = 0;
  @observable compListTotalCount = null;

  @observable selectedComp = null;

  @action
  setCompNm = value => {
    this.compNm = value;
  };

  @action
  initCompNm = () => {
    this.compNm = null;
  };
  @action
  setCompBsNo = value => {
    this.compBsNo = value;
  };

  @action
  initCompBsNo = () => {
    this.compBsNo = null;
  };

  @action
  setCompHpNo = value => {
    this.compHpNo = value;
  };

  @action
  initCompHpNo = () => {
    this.compHpNo = null;
  };

  @action
  setCompFaxNo = value => {
    this.compFaxNo = value;
  };

  @action
  initCompFaxNo = () => {
    this.compFaxNo = null;
  };

  @action
  setRepresentative = value => {
    this.representative = value;
  };

  @action
  initRepresentative = () => {
    this.representative = null;
  };

  @action
  setBsTyp = value => {
    this.bsTyp = value;
  };

  @action
  initBsTyp = () => {
    this.bsTyp = null;
  };

  @action
  setBsNm = value => {
    this.bsNm = value;
  };

  @action
  initBsNm = () => {
    this.bsNm = null;
  };

  @action
  setCompAdd = value => {
    this.compAdd = value;
  };

  @action
  initCompAdd = () => {
    this.compAdd = null;
  };

  @action
  setCompCd = value => {
    this.compCd = value;
  };

  @action
  initCompCd = () => {
    this.compCd = null;
  };

  @action
  setCompListCurrentPageNum = value => {
    this.compListCurrentPageNum = value;
  };

  @action
  initCompListCurrentPageNum = () => {
    this.compListCurrentPageNum = 1;
  };

  @action
  setCompListTotalPageNum = value => {
    this.compListTotalPageNum = value;
  };

  @action
  initCompListTotalPageNum = () => {
    this.compListTotalPageNum = 0;
  };

  @action
  setCompListTotalCount = value => {
    this.compListTotalCount = value;
  };

  @action
  initCompListTotalCount = () => {
    this.compListTotalCount = null;
  };

  @action
  setCompList = value => {
    this.compList = value;
  };

  @action
  initCompList = () => {
    this.compList = [];
  };

  @action
  setSelectedComp = value => {
    this.selectedComp = value;
  };

  @action
  initSelectedComp = () => {
    this.selectedComp = null;
  };

  @action
  setEmail = value => {
    this.email = value;
  };

  @action
  initEmail = () => {
    this.email = null;
  };

  @action
  initForm = () => {
    this.initCompNm();
    this.initCompHpNo();
    this.initCompFaxNo();
    this.initCompBsNo();
    this.initBsTyp();
    this.initBsNm();
    this.initRepresentative();
    this.initCompAdd();
    this.initCompCd();
    this.initEmail();
    this.initCompListCurrentPageNum();
    this.initCompListTotalPageNum();
    this.initCompListTotalCount();
  };
}

export default CompStore;
