import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { STR_ALERT, STR_OK } from '../../common/Terms';
@inject(stores => ({
  commonStore: stores.CommonStore,
}))
@observer
class CommonAlertWithCallback extends Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Modal
        size="tiny"
        open={commonStore.commonAlertWithCallBack.isOpen}
        onClose={() => commonStore.commonAlertWithCallbackClose()}
        style={{ height: '210px' }}
      >
        <Modal.Header>{STR_ALERT}</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: '16pt' }}>
            {commonStore.commonAlertWithCallBack.content}
          </p>
        </Modal.Content>
        <div style={{ float: 'right', marginTop: '20px', marginRight: '20px' }}>
          <Button
            positive
            onClick={() => {
              if (this.props.callback != undefined) {
                commonStore.commonAlertWithCallbackClose();
                this.props.callback();
              } else {
                commonStore.commonAlertWithCallbackClose();
              }
            }}
          >
            {STR_OK}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default CommonAlertWithCallback;
