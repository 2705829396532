import React, { Component } from 'react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Table,
  Input,
  Form,
  TextArea,
  Select,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';

import {
  API_INSERT_ALARMTALK,
  API_SELECT_ALARMTALK_TEMPLATE_LIST,
} from '../../../../common/ApiAddress/AlarmTalkAPI';
import CommonFindUserModal from '../../../../Component/Common/FindUserModal/CommonFindUserModal';
import CommonAlert from '../../../../Component/Common/CommonAlert';
@inject(stores => ({
  armStore: stores.AlarmTalkStore,
  commonStore: stores.CommonStore,
  accStore: stores.AccStore,
}))
@observer
class AlarmTalkTamplateManagementContainer extends Component {
  state = {
    upsertView: false,
    upsertMode: 'I',
    to: null,
    content: null,
    toNm: null,
    templateList: null,
    searchConditon: null,
    searchTyp: null,
    searchKeyword: null,
    tmpCode: null,
  };

  componentDidMount() {
    this.fnSelectTemplateiList();
  }

  //알림톡 템플릿 리스트 불러오기
  fnSelectTemplateiList = () => {
    const { armStore } = this.props;
    let params = {};
    let that = this;

    axios({
      url: API_SELECT_ALARMTALK_TEMPLATE_LIST,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          if (response.data.data != null) {
            let templateList = [];
            for (let i = 0; i < response.data.data.length; i++) {
              let params = {};
              params.value = {
                content: response.data.data[i].tmpContent,
                tmpCode: response.data.data[i].tmpCode,
              };
              params.text = response.data.data[i].tmpTitle;
              //params.tmpcode = ;
              templateList.push(params);
            }
            that.setState({
              templateList: templateList,
            });
          }
          armStore.setAlarmTalkTemplateList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnOpenFindUserModal = () => {
    const { commonStore, accStore } = this.props;
    commonStore.openFindUserModal();
    accStore.initCustomerList();
    accStore.initDriverList();
    this.setState({
      searchConditon: null,
      searchTyp: null,
      searchKeyword: null,
    });
  };

  fnCloseFindUserModal = () => {
    const { commonStore, accStore } = this.props;
    commonStore.closeFindUserModal();
    commonStore.initSelectedUser();
    accStore.initCustomerList();
    accStore.initDriverList();
    this.setState({
      searchConditon: null,
      searchTyp: null,
      searchKeyword: null,
    });
  };

  fnOnSelect = () => {
    const { commonStore, accStore } = this.props;
    if (this.state.searchConditon == 'C') {
      this.setState({
        to: commonStore.selectedUser.hpNo,
        toNm:
          commonStore.selectedUser.customerId +
          '(' +
          commonStore.selectedUser.customerNm +
          ')',
      });
    } else if (this.state.searchConditon == 'T') {
      this.setState({
        to: commonStore.selectedUser.hpNo,
        toNm:
          commonStore.selectedUser.driverId +
          '(' +
          commonStore.selectedUser.driverNm +
          ')',
      });
    }
    accStore.initCustomerList();
    accStore.initDriverList();
    commonStore.closeFindUserModal();
  };

  fnOnchangeSearchCondition = value => {
    this.setState({
      searchConditon: value,
    });
  };

  fnOnchangeSearchTyp = value => {
    this.setState({
      searchTyp: value,
    });
  };

  fnGetAlarmAuth = () => {
    const { commonStore } = this.props;
    let that = this;
    if (this.state.to == null || this.state.to == '') {
      commonStore.commonAlertOpen('연락처를 기입해주세요.');
      return false;
    }

    if (this.state.tmpCode == null || this.state.tmpCode == '') {
      commonStore.commonAlertOpen('템플릿을 선택해주세요.');
      return false;
    }

    if (this.state.content == null || this.state.content == '') {
      commonStore.commonAlertOpen('내용을 입력해주세요.');
      return false;
    }

    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'alarmAuth',
      method: 'post',
    })
      .then(response => {
        that.fnSendTalk(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //알림톡 전송
  fnSendTalk = authData => {
    let params = {};
    let that = this;
    let to = this.state.to.slice(1);
    params.accessToken = authData.accessToken;
    params.to = '82' + to;
    params.content = this.state.content;
    params.templateCode = this.state.tmpCode;
    axios({
      url: process.env.REACT_APP_API_EXPRESS_ADDRESS + 'alarmSend',
      method: 'get',
      params: params,
    })
      .then(function (response) {
        console.log(response.data)
        if (response.data.status == 'A000') {
          that.fnInsertAlarmTalk(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //알림톡 내역 저장
  fnInsertAlarmTalk = data => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.messageId = data.messageId;
    params.rcvHpNo = this.state.to;
    if (commonStore.selectedUser != null) {
      if (this.state.searchConditon == 'C') {
        params.rcvId = commonStore.selectedUser.customerId;
      } else if (this.state.searchConditon == 'T') {
        params.rcvId = commonStore.selectedUser.driverId;
      }
    }
    params.tmpContent = this.state.content;
    params.senderId = sessionStorage.getItem('empId');
    params.tmpCode = this.state.tmpCode;
    axios({
      url: API_INSERT_ALARMTALK,
      method: 'post',
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          //window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { armStore, commonStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: '100%',
            height: '100vh',
            padding: '10px',
          }}
        >
          <h1>알림톡 발송</h1>
          <div
            style={{
              width: '70%',
            }}
          >
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>발송대상</Table.Cell>
                  <Table.Cell>
                    <Form style={{ width: '100%' }}>
                      <Form.Field style={{ width: '100%' }}>
                        <Input
                          disabled={true}
                          style={{ width: '30%', opacity: 1 }}
                          placeholder="발송대상 검색"
                          value={this.state.toNm || ''}
                          onChange={e => {
                            this.setState({
                              toNm: e.target.value,
                            });
                          }}
                        />
                        <Button
                          positive
                          onClick={() => {
                            this.fnOpenFindUserModal();
                          }}
                          style={{ marginLeft: '10px' }}
                        >
                          검색
                        </Button>
                      </Form.Field>
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>연락처</Table.Cell>
                  <Table.Cell>
                    <Input
                      style={{ width: '30%' }}
                      placeholder="수신자 번호"
                      value={this.state.to || ''}
                      onChange={e => {
                        this.setState({
                          to: e.target.value,
                        });
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell collapsing>내용</Table.Cell>
                  <Table.Cell>
                    <Form>
                      {this.state.templateList != null ? (
                        <Select
                          style={{
                            minWidth: '200px',
                            maxWidth: '200px',
                            marginBottom: '10px',
                          }}
                          placeholder={'템플릿 선택'}
                          options={this.state.templateList}
                          onChange={(e, { value }) => {
                            this.setState({
                              tmpCode: value.tmpCode,
                              content: value.content,
                            });
                          }}
                        />
                      ) : null}

                      <TextArea
                        value={this.state.content || ''}
                        style={{ resize: 'none' }}
                        placeholder={'내용 작성'}
                        rows={5}
                        onChange={e => {
                          this.setState({
                            content: e.target.value,
                          });
                        }}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Button
              positive
              onClick={() => {
                this.fnGetAlarmAuth();
              }}
              style={{ float: 'right' }}
            >
              알림톡 발송
            </Button>
          </div>
        </div>
        <CommonFindUserModal
          open={commonStore.findUserModal}
          onClose={this.fnCloseFindUserModal}
          onSelect={this.fnOnSelect}
          searchConditon={this.state.searchConditon}
          searchTyp={this.state.searchTyp}
          searchKeyword={this.state.searchKeyword}
          fnOnchangeSearchCondition={this.fnOnchangeSearchCondition}
          fnOnchangeSearchTyp={this.fnOnchangeSearchTyp}
        />
        <CommonAlert />
      </React.Fragment>
    );
  }
}

export default withRouter(AlarmTalkTamplateManagementContainer);
